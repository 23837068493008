// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function FeedbackDelayed(props) {
  var __delay = props.delay;
  var delay = __delay !== undefined ? __delay : 600;
  var match = React.useState(function () {
        return "Init";
      });
  var setState = match[1];
  var state = match[0];
  React.useEffect((function () {
          if (typeof state !== "object" && state === "Init") {
            var timeout = setTimeout((function () {
                    setState(function (param) {
                          return "Showing";
                        });
                  }), delay);
            setState(function (param) {
                  return {
                          TAG: "Waiting",
                          _0: timeout
                        };
                });
          }
          
        }), [state]);
  console.log("FeedbackDelayed", state);
  if (typeof state !== "object" && state !== "Init") {
    return props.children;
  }
  return Prelude.default(props.fallback, JsxRuntime.jsx(Backdrop, {
                  open: true,
                  children: Caml_option.some(JsxRuntime.jsx(CircularProgress, {}))
                }));
}

var make = FeedbackDelayed;

export {
  make ,
}
/* react Not a pure module */
