// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as FolderId from "../../catalog/models/FolderId.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FolderMatch from "./FolderMatch.js";
import * as CollectionId from "../../collections/models/CollectionId.js";
import * as AttributeMatch from "./AttributeMatch.js";

function toString(value) {
  if (typeof value !== "object") {
    return "";
  } else {
    return value._0.trim();
  }
}

function make(mode, value) {
  var value$1 = value.trim();
  if (value$1 === "") {
    return "Empty";
  }
  switch (mode) {
    case "ModeFts" :
        return {
                TAG: "FullTextSearch",
                _0: value$1
              };
    case "ModeSubstr" :
        return {
                TAG: "Substr",
                _0: value$1
              };
    case "ModeTitle" :
        return {
                TAG: "Title",
                _0: value$1
              };
    
  }
}

var $$Text = {
  toString: toString,
  make: make
};

var default_folders = [];

var default_authors = [];

var default_cities = [];

var default_tags = [];

var default_collections = [];

var $$default = {
  folders: default_folders,
  authors: default_authors,
  cities: default_cities,
  tags: default_tags,
  collections: default_collections,
  text: "Empty"
};

function merge(a, b) {
  var root = Prelude.$$Array.first(Prelude.$$Array.keepSome([
            b.root,
            a.root
          ]));
  var folders = FolderMatch.group(Curry._2(Prelude.$$Array.concat, a.folders, b.folders));
  var authors = AttributeMatch.group(Curry._2(Prelude.$$Array.concat, a.authors, b.authors));
  var cities = AttributeMatch.group(Curry._2(Prelude.$$Array.concat, a.cities, b.cities));
  var tags = Curry._2(Prelude.$$Array.concat, a.tags, b.tags);
  var collections = Curry._2(Prelude.$$Array.concat, a.collections, b.collections);
  var untagged = Prelude.$$Array.first(Prelude.$$Array.keepSome([
            b.untagged,
            a.untagged
          ]));
  var text = b.text;
  return {
          root: root,
          folders: folders,
          authors: authors,
          cities: cities,
          tags: tags,
          collections: collections,
          untagged: untagged,
          text: text
        };
}

var Filters = {
  $$Text: $$Text,
  $$default: $$default,
  merge: merge
};

function joinOr(query) {
  var needed = query.length - 1 | 0;
  if (needed > 0) {
    return Curry._2(Prelude.$$Array.concat, Curry._2(Prelude.$$Array.range, 1, needed).map(function (param) {
                    return "|";
                  }), query);
  } else {
    return query;
  }
}

function joinAnd(query) {
  var query$1 = Curry._2(Prelude.$$Array.keep, query, (function (q) {
          return Prelude.$$Array.isNotEmpty(q);
        }));
  var needed = query$1.length - 1 | 0;
  if (needed > 0) {
    return Curry._2(Prelude.$$Array.concat, Curry._2(Prelude.$$Array.range, 1, needed).map(function (param) {
                    return "&";
                  }), Prelude.$$Array.flatMap(query$1, (function (q) {
                      return q;
                    })));
  } else {
    return Prelude.default(Prelude.$$Array.first(query$1), []);
  }
}

function fromFilters(query) {
  var folders = joinOr(Prelude.$$Array.flatMap(query.folders, (function (m) {
                return m.matches;
              })).map(function (id) {
            return [
                    "folder_id",
                    FolderId.toString(id)
                  ];
          }));
  var cities = joinOr(query.cities.map(function (m) {
              return m.value;
            }).map(function (v) {
            return [
                    "city=",
                    v
                  ];
          }));
  var authors = joinOr(query.authors.map(function (m) {
              return m.value;
            }).map(function (v) {
            return [
                    "author=",
                    v
                  ];
          }));
  var collections = joinOr(Prelude.$$Array.flatMap(query.collections, (function (m) {
                return m.matches;
              })).map(function (v) {
            return [
                    "collection_id",
                    CollectionId.toString(v)
                  ];
          }));
  var untagged = query.untagged;
  var tags = untagged !== undefined ? [[
        "untagged",
        untagged
      ]] : joinOr(query.tags.map(function (t) {
                return t.id;
              }).map(function (v) {
              return [
                      "anytag_id",
                      v
                    ];
            }));
  var text = query.text;
  var text$1;
  if (typeof text !== "object") {
    text$1 = [];
  } else {
    switch (text.TAG) {
      case "FullTextSearch" :
          var text$2 = text._0;
          text$1 = text$2.length > 0 ? [[
                "text",
                text$2
              ]] : [];
          break;
      case "Substr" :
          var text$3 = text._0;
          text$1 = text$3.length > 0 ? [[
                "substr",
                text$3
              ]] : [];
          break;
      case "Title" :
          var text$4 = text._0;
          text$1 = text$4.length > 0 ? [[
                "title",
                text$4
              ]] : [];
          break;
      
    }
  }
  var f = query.root;
  var root = f !== undefined ? [[
        "folder_id",
        FolderId.toString(Caml_option.valFromOption(f))
      ]] : [];
  return joinAnd([
              root,
              tags,
              folders,
              collections,
              authors,
              cities,
              text$1
            ]);
}

var isEmpty = Prelude.$$Array.isEmpty;

function clone(domain) {
  return Curry._2(Prelude.$$Array.concat, domain, []);
}

var Domain = {
  joinOr: joinOr,
  joinAnd: joinAnd,
  fromFilters: fromFilters,
  isEmpty: isEmpty,
  clone: clone
};

function makeWithNs(namespace) {
  return {
          namespace_id: Caml_option.some(namespace)
        };
}

function makeWithDomain(domain) {
  return {
          query: domain
        };
}

var Params = {
  makeWithNs: makeWithNs,
  makeWithDomain: makeWithDomain
};

export {
  Filters ,
  Domain ,
  Params ,
}
/* FolderId Not a pure module */
