// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Units from "../../../utils/Units.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as FolderId from "../models/FolderId.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../../libs/i18n/ReactI18Next.js";
import * as ReactDOMStyle from "@rescript/react/lib/es6/src/ReactDOMStyle.js";
import * as FolderDropdown from "./affordances/FolderDropdown.js";
import * as CatalogNavigator from "../context/CatalogNavigator.js";
import * as ClipboardManager from "../context/ClipboardManager.js";
import * as SelectionManager from "../context/SelectionManager.js";
import * as ReactRouterDom from "react-router-dom";
import * as JsxRuntime from "react/jsx-runtime";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Tooltip from "@mui/material/Tooltip";
import * as ClipboardToggleButton from "./ClipboardToggleButton.js";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import Typography from "@mui/material/Typography";
import * as CatalogBrowserSortContext from "../context/CatalogBrowserSortContext.js";
import TableContainer from "@mui/material/TableContainer";
import ContentPaste from "@mui/icons-material/ContentPaste";
import ArrowUpwardRounded from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRounded from "@mui/icons-material/ArrowDownwardRounded";

function fieldLabel(field) {
  if (field === "size") {
    return JsxRuntime.jsx(ReactI18Next.Message.make, {
                msg: {
                  NAME: "str",
                  VAL: "Images (estimated)"
                }
              });
  } else if (field === "created_at") {
    return JsxRuntime.jsx(ReactI18Next.Message.make, {
                msg: {
                  NAME: "str",
                  VAL: "Created at"
                }
              });
  } else if (field === "updated_at") {
    return JsxRuntime.jsx(ReactI18Next.Message.make, {
                msg: {
                  NAME: "str",
                  VAL: "Updated at"
                }
              });
  } else {
    return JsxRuntime.jsx(ReactI18Next.Message.make, {
                msg: {
                  NAME: "str",
                  VAL: "Name"
                }
              });
  }
}

function FoldersTable$FolderRow(props) {
  var folder = props.folder;
  var isSelected = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.Folders.useIsSelected());
  var selected = React.useMemo((function () {
          return isSelected(folder);
        }), [
        folder,
        isSelected
      ]);
  var toggle = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.Folders.useToggle());
  var getLink = CatalogNavigator.useLinkBuilder();
  var href = getLink(folder);
  var onDoubleClick = ReactRouterDom.useLinkClickHandler(href);
  var size = folder.items > 0 ? " (" + Units.toString(Units.toBest({
              NAME: "bytes",
              VAL: folder.size
            })) + ")" : "";
  var isClipped = ClipboardManager.Contents.useIsClipped();
  var clipped = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, isClipped, (function (fn) {
              return fn({
                          NAME: "folder",
                          VAL: folder
                        });
            })), false);
  return React.useMemo((function () {
                var style = ReactDOMStyle.unsafeAddProp({
                      cursor: "default",
                      userSelect: "none"
                    }, "WebkitUserSelect", "none");
                return JsxRuntime.jsxs(TableRow, {
                            className: "selectable-item",
                            style: style,
                            id: FolderId.toString(folder.id),
                            onDoubleClick: onDoubleClick,
                            children: [
                              JsxRuntime.jsx(TableCell, {
                                    children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                              alignItems: "center",
                                              children: [
                                                JsxRuntime.jsx(Checkbox, {
                                                      checked: selected,
                                                      onChange: (function (param, param$1) {
                                                          toggle([folder]);
                                                        }),
                                                      size: "small"
                                                    }),
                                                JsxRuntime.jsx(ClipboardToggleButton.make, {
                                                      item: {
                                                        NAME: "folder",
                                                        VAL: folder
                                                      },
                                                      fontSize: "small",
                                                      sx: {
                                                        color: "primary.main"
                                                      }
                                                    })
                                              ],
                                              direction: ["row"],
                                              spacing: 1.0
                                            }))
                                  }),
                              JsxRuntime.jsx(TableCell, {
                                    children: Caml_option.some(folder.name)
                                  }),
                              JsxRuntime.jsx(TableCell, {
                                    children: Caml_option.some(folder.created_at.toLocaleDateString())
                                  }),
                              JsxRuntime.jsx(TableCell, {
                                    children: Caml_option.some(folder.updated_at.toLocaleDateString())
                                  }),
                              JsxRuntime.jsxs(TableCell, {
                                    children: [
                                      String(folder.items),
                                      size
                                    ]
                                  }),
                              JsxRuntime.jsx(TableCell, {
                                    children: Caml_option.some(JsxRuntime.jsx(FolderDropdown.make, {
                                              folder: folder
                                            }))
                                  })
                            ]
                          });
              }), [
              selected,
              folder,
              toggle,
              onDoubleClick,
              clipped
            ]);
}

function FoldersTable$Column(props) {
  var column = props.column;
  var label = fieldLabel(column);
  var sortContext = CatalogBrowserSortContext.Folder.useCurrent();
  var direction = Curry._2(Prelude.OptionExported.$$Option.map, sortContext, (function (param) {
          return param[0];
        }));
  var sortfield = Curry._2(Prelude.OptionExported.$$Option.map, sortContext, (function (param) {
          return param[1];
        }));
  var setField = CatalogBrowserSortContext.Folder.useSetField();
  var toggleDirection = CatalogBrowserSortContext.Folder.useToggleDirection();
  if (sortfield !== undefined && direction !== undefined && setField !== undefined && toggleDirection !== undefined) {
    if (column === sortfield) {
      return JsxRuntime.jsxs(Stack, {
                  children: [
                    JsxRuntime.jsx(Tooltip, {
                          children: JsxRuntime.jsx("span", {
                                children: label,
                                style: {
                                  cursor: "pointer"
                                },
                                onClick: (function (param) {
                                    toggleDirection();
                                  })
                              }),
                          arrow: true,
                          title: JsxRuntime.jsx(ReactI18Next.Message.make, {
                                msg: {
                                  NAME: "str",
                                  VAL: "Reverse sort direction"
                                }
                              })
                        }),
                    direction === "asc" ? JsxRuntime.jsx(ArrowUpwardRounded, {
                            fontSize: "small"
                          }) : JsxRuntime.jsx(ArrowDownwardRounded, {
                            fontSize: "small"
                          })
                  ],
                  direction: ["row"],
                  spacing: "1rem",
                  sx: {
                    alignItems: "center"
                  }
                });
    } else {
      return JsxRuntime.jsxs(Stack, {
                  children: [
                    JsxRuntime.jsx(Tooltip, {
                          children: JsxRuntime.jsx("span", {
                                children: label,
                                style: {
                                  cursor: "pointer"
                                },
                                onClick: (function (param) {
                                    setField(column);
                                  })
                              }),
                          arrow: true,
                          title: JsxRuntime.jsx(ReactI18Next.Message.make, {
                                msg: {
                                  NAME: "str",
                                  VAL: "Sort field"
                                }
                              })
                        }),
                    JsxRuntime.jsx(ArrowUpwardRounded, {
                          fontSize: "small",
                          style: {
                            opacity: "0"
                          }
                        })
                  ],
                  direction: ["row"],
                  spacing: "1rem",
                  sx: {
                    alignItems: "center"
                  }
                });
    }
  }
  return JsxRuntime.jsx(Stack, {
              children: Caml_option.some(JsxRuntime.jsx("span", {
                        children: label,
                        style: {
                          cursor: "default"
                        }
                      })),
              direction: ["row"],
              spacing: "1rem",
              sx: {
                alignItems: "center"
              }
            });
}

function FoldersTable(props) {
  var subfolders = props.subfolders;
  var hasSelection = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.Folders.useHasSelection());
  var select = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.Folders.useSelect());
  var reset = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.Folders.useReset());
  var areAllSelected = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.Folders.useAreAllSelected());
  var toggleSelection = function (e, param) {
    var exit = 0;
    var target;
    try {
      target = e.target;
      exit = 1;
    }
    catch (exn){
      return ;
    }
    if (exit === 1) {
      var exit$1 = 0;
      var val;
      try {
        val = target.checked;
        exit$1 = 2;
      }
      catch (exn$1){
        return ;
      }
      if (exit$1 === 2) {
        if (val) {
          return select(subfolders);
        } else {
          return reset();
        }
      }
      
    }
    
  };
  var indeterminate = React.useMemo((function () {
          if (areAllSelected(subfolders)) {
            return false;
          } else {
            return hasSelection;
          }
        }), [
        areAllSelected,
        subfolders,
        hasSelection
      ]);
  var checked = React.useMemo((function () {
          if (indeterminate) {
            return false;
          } else {
            return areAllSelected(subfolders);
          }
        }), [
        indeterminate,
        areAllSelected,
        subfolders
      ]);
  return JsxRuntime.jsxs(Stack, {
              children: [
                JsxRuntime.jsx(Stack, {
                      children: Caml_option.some(JsxRuntime.jsx(Grid, {
                                children: Caml_option.some(JsxRuntime.jsx(Grid, {
                                          children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                    variant: "h6",
                                                    children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                              msg: {
                                                                NAME: "msg",
                                                                VAL: [
                                                                  "catalog.browser.folders.title",
                                                                  "Folders"
                                                                ]
                                                              }
                                                            }))
                                                  })),
                                          item: true
                                        })),
                                container: true,
                                spacing: 2,
                                sx: {
                                  alignItems: "center"
                                }
                              })),
                      direction: ["row"],
                      spacing: "0.5rem"
                    }),
                JsxRuntime.jsx(TableContainer, {
                      children: Caml_option.some(JsxRuntime.jsxs(Table, {
                                children: [
                                  JsxRuntime.jsx(TableHead, {
                                        children: Caml_option.some(JsxRuntime.jsxs(TableRow, {
                                                  children: [
                                                    JsxRuntime.jsxs(TableCell, {
                                                          children: [
                                                            JsxRuntime.jsx(Checkbox, {
                                                                  checked: checked,
                                                                  indeterminate: indeterminate,
                                                                  onChange: toggleSelection,
                                                                  size: "small"
                                                                }),
                                                            JsxRuntime.jsx(ContentPaste, {
                                                                  fontSize: "small",
                                                                  style: {
                                                                    opacity: "0"
                                                                  }
                                                                })
                                                          ]
                                                        }),
                                                    JsxRuntime.jsx(TableCell, {
                                                          children: Caml_option.some(JsxRuntime.jsx(FoldersTable$Column, {
                                                                    column: "name"
                                                                  }))
                                                        }),
                                                    JsxRuntime.jsx(TableCell, {
                                                          children: Caml_option.some(JsxRuntime.jsx(FoldersTable$Column, {
                                                                    column: "created_at"
                                                                  }))
                                                        }),
                                                    JsxRuntime.jsx(TableCell, {
                                                          children: Caml_option.some(JsxRuntime.jsx(FoldersTable$Column, {
                                                                    column: "updated_at"
                                                                  }))
                                                        }),
                                                    JsxRuntime.jsx(TableCell, {
                                                          children: Caml_option.some(JsxRuntime.jsx(FoldersTable$Column, {
                                                                    column: "size"
                                                                  }))
                                                        }),
                                                    JsxRuntime.jsx(TableCell, {})
                                                  ]
                                                }))
                                      }),
                                  JsxRuntime.jsx(TableBody, {
                                        children: Caml_option.some(subfolders.map(function (folder) {
                                                  return JsxRuntime.jsx(FoldersTable$FolderRow, {
                                                              folder: folder
                                                            }, FolderId.toString(folder.id));
                                                }))
                                      })
                                ],
                                size: "small"
                              }))
                    })
              ],
              direction: ["column"],
              spacing: "0.5rem"
            });
}

var make = FoldersTable;

export {
  make ,
}
/* react Not a pure module */
