// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FailurePage from "../components/pages/FailurePage.js";
import * as UserContext from "../context/UserContext.js";
import * as GoBackButton from "../components/GoBackButton.js";
import * as ReloadButton from "../components/ReloadButton.js";
import * as React from "@sentry/react";
import * as ForbiddenPage from "../components/pages/ForbiddenPage.js";
import * as FlexGrowSpacer from "../components/FlexGrowSpacer.js";
import * as UserPermission from "../models/UserPermission.js";
import * as PhotocubaAppNav from "../components/navigation/PhotocubaAppNav.js";
import * as JsxRuntime from "react/jsx-runtime";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import * as ActiveApplicationService from "../context/ActiveApplicationService.js";

function MakeStandard(M) {
  var Manifest = M.Manifest;
  var Services = M.Services;
  var Affordances = M.Affordances;
  var Main = M.Main;
  var allowed = UserPermission.Query.compile(Manifest.permissions);
  var ApplicationMaker$MakeStandard = function (props) {
    var permissions = UserContext.useUserPermissionsClaim();
    var failureFallback = JsxRuntime.jsx(FailurePage.make, {
          title: {
            NAME: "msg",
            VAL: [
              "errors.app-boundary-title",
              "Application Error"
            ]
          },
          reasons: [
            {
              NAME: "msg",
              VAL: [
                "errors.app-boundary-message.l1",
                "There was a fatal error in the app and it crashed."
              ]
            },
            {
              NAME: "msg",
              VAL: [
                "errors.app-boundary-message.l2",
                "This event is automatically reported to the development team, so\n             that they can work on it.  However, you can also contact the\n             team and explain what you were doing, when the application crashed."
              ]
            }
          ],
          children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                    children: [
                      JsxRuntime.jsx(FlexGrowSpacer.make, {}),
                      JsxRuntime.jsx(GoBackButton.make, {}),
                      JsxRuntime.jsx(ReloadButton.make, {})
                    ],
                    direction: ["row"],
                    spacing: 2.0
                  }))
        });
    if (allowed(permissions)) {
      return JsxRuntime.jsx(ActiveApplicationService.make, {
                  app: Manifest,
                  children: JsxRuntime.jsx(React.ErrorBoundary, {
                        fallback: Caml_option.some(failureFallback),
                        children: JsxRuntime.jsx(Services.make, {
                              children: JsxRuntime.jsx(Affordances.make, {
                                    children: JsxRuntime.jsx(Paper, {
                                          children: Caml_option.some(JsxRuntime.jsx(Container, {
                                                    children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                                              className: "selection-lasso-scroll-container",
                                                              style: {
                                                                overflow: "scroll"
                                                              },
                                                              children: [
                                                                JsxRuntime.jsx(PhotocubaAppNav.make, {}),
                                                                JsxRuntime.jsx(Container, {
                                                                      children: Caml_option.some(JsxRuntime.jsx(Main.make, {})),
                                                                      maxWidth: false,
                                                                      sx: {
                                                                        height: "100%",
                                                                        width: "100vw"
                                                                      }
                                                                    })
                                                              ],
                                                              spacing: "0.5rem",
                                                              sx: {
                                                                height: "100vh"
                                                              }
                                                            })),
                                                    disableGutters: true,
                                                    maxWidth: false,
                                                    sx: {
                                                      height: "100vh",
                                                      width: "100vw"
                                                    }
                                                  }))
                                        })
                                  })
                            })
                      })
                });
    } else {
      return JsxRuntime.jsx(ForbiddenPage.make, {});
    }
  };
  return {
          Manifest: Manifest,
          Services: Services,
          Affordances: Affordances,
          Main: Main,
          make: ApplicationMaker$MakeStandard
        };
}

function ApplicationMaker$NoOp(props) {
  return props.children;
}

var NoOp = {
  make: ApplicationMaker$NoOp
};

function MakeServiceless(M) {
  var Manifest = M.Manifest;
  var Main = M.Main;
  var allowed = UserPermission.Query.compile(Manifest.permissions);
  var ApplicationMaker$MakeStandard = function (props) {
    var permissions = UserContext.useUserPermissionsClaim();
    var failureFallback = JsxRuntime.jsx(FailurePage.make, {
          title: {
            NAME: "msg",
            VAL: [
              "errors.app-boundary-title",
              "Application Error"
            ]
          },
          reasons: [
            {
              NAME: "msg",
              VAL: [
                "errors.app-boundary-message.l1",
                "There was a fatal error in the app and it crashed."
              ]
            },
            {
              NAME: "msg",
              VAL: [
                "errors.app-boundary-message.l2",
                "This event is automatically reported to the development team, so\n             that they can work on it.  However, you can also contact the\n             team and explain what you were doing, when the application crashed."
              ]
            }
          ],
          children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                    children: [
                      JsxRuntime.jsx(FlexGrowSpacer.make, {}),
                      JsxRuntime.jsx(GoBackButton.make, {}),
                      JsxRuntime.jsx(ReloadButton.make, {})
                    ],
                    direction: ["row"],
                    spacing: 2.0
                  }))
        });
    if (allowed(permissions)) {
      return JsxRuntime.jsx(ActiveApplicationService.make, {
                  app: Manifest,
                  children: JsxRuntime.jsx(React.ErrorBoundary, {
                        fallback: Caml_option.some(failureFallback),
                        children: JsxRuntime.jsx(ApplicationMaker$NoOp, {
                              children: JsxRuntime.jsx(ApplicationMaker$NoOp, {
                                    children: JsxRuntime.jsx(Paper, {
                                          children: Caml_option.some(JsxRuntime.jsx(Container, {
                                                    children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                                              className: "selection-lasso-scroll-container",
                                                              style: {
                                                                overflow: "scroll"
                                                              },
                                                              children: [
                                                                JsxRuntime.jsx(PhotocubaAppNav.make, {}),
                                                                JsxRuntime.jsx(Container, {
                                                                      children: Caml_option.some(JsxRuntime.jsx(Main.make, {})),
                                                                      maxWidth: false,
                                                                      sx: {
                                                                        height: "100%",
                                                                        width: "100vw"
                                                                      }
                                                                    })
                                                              ],
                                                              spacing: "0.5rem",
                                                              sx: {
                                                                height: "100vh"
                                                              }
                                                            })),
                                                    disableGutters: true,
                                                    maxWidth: false,
                                                    sx: {
                                                      height: "100vh",
                                                      width: "100vw"
                                                    }
                                                  }))
                                        })
                                  })
                            })
                      })
                });
    } else {
      return JsxRuntime.jsx(ForbiddenPage.make, {});
    }
  };
  return {
          Manifest: Manifest,
          Services: NoOp,
          Affordances: NoOp,
          Main: Main,
          make: ApplicationMaker$MakeStandard
        };
}

export {
  MakeStandard ,
  NoOp ,
  MakeServiceless ,
}
/* FailurePage Not a pure module */
