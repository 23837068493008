// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";

var fields = {
  TAG: "Object",
  _0: [
    [
      "name",
      "String"
    ],
    [
      "version",
      "String"
    ]
  ]
};

var Deserializer = $$JSON.MakeDeserializer({
      fields: fields
    });

export {
  Deserializer ,
}
/* Deserializer Not a pure module */
