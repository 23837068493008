// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Namespace from "../../../../global/db/models/Namespace.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../../../libs/i18n/ReactI18Next.js";
import * as ActionsManager from "../../../../global/context/ActionsManager.js";
import * as FlexGrowSpacer from "../../../../global/components/FlexGrowSpacer.js";
import * as NamespaceContext from "../../../../global/context/NamespaceContext.js";
import * as JsxRuntime from "react/jsx-runtime";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Slider from "@mui/material/Slider";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Settings from "@mui/icons-material/Settings";

var min = Namespace.TrashResidencyDays.min;

var max = Namespace.TrashResidencyDays.max;

var marks = [{
    value: min,
    label: String(min)
  }];

var marks$1 = Curry._2(Prelude.$$Array.concat, marks, Curry._2(Prelude.$$Array.keep, Curry._2(Prelude.$$Array.range, min, max), (function (v) {
              return v % 30 === 0;
            })).map(function (v) {
          return {
                  value: v,
                  label: String(v)
                };
        }));

function TrashResidencyConfigurationAffordance$NamespaceConfigurationDialog(props) {
  var onClose = props.onClose;
  var onUpdate = props.onUpdate;
  var namespace = props.namespace;
  var match = React.useState(function () {
        return namespace;
      });
  var setNs = match[1];
  var ns = match[0];
  var duration = Namespace.getTrashResidencyDays(ns);
  var match$1 = React.useState(function () {
        return duration;
      });
  var setValue = match$1[1];
  return JsxRuntime.jsxs(Dialog, {
              open: true,
              children: [
                JsxRuntime.jsx(DialogTitle, {
                      children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                msg: {
                                  NAME: "msg",
                                  VAL: [
                                    "trash.dialog-settings-title",
                                    "Trash Settings"
                                  ]
                                }
                              }))
                    }),
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                children: [
                                  JsxRuntime.jsx(Typography, {
                                        variant: "body1",
                                        children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                  msg: {
                                                    NAME: "msg",
                                                    VAL: [
                                                      "trash.residency-days-label",
                                                      "Days to remove"
                                                    ]
                                                  }
                                                }))
                                      }),
                                  JsxRuntime.jsx(Slider, {
                                        marks: marks$1,
                                        max: max,
                                        min: min,
                                        onChange: (function (param, value, param$1) {
                                            setValue(function (param) {
                                                  return value | 0;
                                                });
                                          }),
                                        onChangeCommitted: (function (param, value) {
                                            setValue(function (param) {
                                                  return value | 0;
                                                });
                                            setNs(function (ns) {
                                                  return Namespace.setTrashResidencyDays(ns, value | 0);
                                                });
                                          }),
                                        sx: {
                                          maxWidth: "100%"
                                        },
                                        value: match$1[0],
                                        valueLabelDisplay: "auto",
                                        valueLabelFormat: (function (value, param) {
                                            return value | 0;
                                          })
                                      }),
                                  JsxRuntime.jsx(Typography, {
                                        variant: "body2",
                                        children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                  msg: {
                                                    NAME: "msg",
                                                    VAL: [
                                                      "trash.residency-days-help",
                                                      "The amount of days items can remain in the Trash before disposal.  Items in the trash will be deleted after this amount of days has happened since they were trashed."
                                                    ]
                                                  }
                                                }))
                                      })
                                ],
                                direction: ["column"],
                                spacing: 2.0
                              }))
                    }),
                JsxRuntime.jsxs(DialogActions, {
                      children: [
                        JsxRuntime.jsx(Button, {
                              children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                        msg: {
                                          NAME: "msg",
                                          VAL: [
                                            "buttons.cancel",
                                            "Cancel"
                                          ]
                                        }
                                      })),
                              onClick: (function (param) {
                                  onClose();
                                })
                            }),
                        JsxRuntime.jsx(Button, {
                              children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                        msg: {
                                          NAME: "msg",
                                          VAL: [
                                            "buttons.save",
                                            "Save"
                                          ]
                                        }
                                      })),
                              onClick: (function (param) {
                                  onUpdate(ns);
                                }),
                              disabled: Caml_obj.equal(ns, namespace)
                            })
                      ]
                    })
              ],
              fullWidth: true,
              maxWidth: "sm",
              onClose: (function (param, param$1) {
                  onClose();
                })
            });
}

var NamespaceConfigurationDialog = {
  min: min,
  max: max,
  marks: marks$1,
  make: TrashResidencyConfigurationAffordance$NamespaceConfigurationDialog
};

function TrashResidencyConfigurationAffordance$ResidencyButton(props) {
  var namespace = NamespaceContext.useNamespace();
  var updateNamespace = NamespaceContext.useUpdateNamespace();
  var match = React.useState(function () {
        return {
                TAG: "Idle",
                _0: namespace
              };
      });
  var setState = match[1];
  var state = match[0];
  if (state.TAG === "Idle") {
    if (updateNamespace === undefined) {
      return null;
    }
    var ns = state._0;
    return JsxRuntime.jsx(Tooltip, {
                children: JsxRuntime.jsx(IconButton, {
                      children: Caml_option.some(JsxRuntime.jsx(Settings, {
                                color: "primary"
                              })),
                      onClick: (function (param) {
                          setState(function (param) {
                                return {
                                        TAG: "Dialog",
                                        _0: ns
                                      };
                              });
                        })
                    }),
                title: JsxRuntime.jsx(ReactI18Next.Message.make, {
                      msg: {
                        NAME: "msg",
                        VAL: [
                          "trash.settings-tooltip",
                          "Trash Settings"
                        ]
                      }
                    })
              });
  }
  if (updateNamespace === undefined) {
    return null;
  }
  var ns$1 = state._0;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(IconButton, {
                      children: Caml_option.some(JsxRuntime.jsx(Settings, {})),
                      disabled: true
                    }),
                JsxRuntime.jsx(TrashResidencyConfigurationAffordance$NamespaceConfigurationDialog, {
                      namespace: ns$1,
                      onUpdate: (function (namespace) {
                          Prelude.thenDo(updateNamespace(namespace), (function (param) {
                                  setState(function (param) {
                                        return {
                                                TAG: "Idle",
                                                _0: namespace
                                              };
                                      });
                                }));
                        }),
                      onClose: (function () {
                          setState(function (param) {
                                return {
                                        TAG: "Idle",
                                        _0: ns$1
                                      };
                              });
                        })
                    })
              ]
            });
}

var ResidencyButton = {
  make: TrashResidencyConfigurationAffordance$ResidencyButton
};

function TrashResidencyConfigurationAffordance(props) {
  return JsxRuntime.jsx(ActionsManager.Register.make, {
              name: "trash-settings-affordance",
              widget: (function (key) {
                  return JsxRuntime.jsxs(React.Fragment, {
                              children: [
                                JsxRuntime.jsx(FlexGrowSpacer.make, {}),
                                JsxRuntime.jsx(TrashResidencyConfigurationAffordance$ResidencyButton, {})
                              ]
                            }, key);
                }),
              sequence: 5000,
              children: props.children
            });
}

var make = TrashResidencyConfigurationAffordance;

export {
  NamespaceConfigurationDialog ,
  ResidencyButton ,
  make ,
}
/* marks Not a pure module */
