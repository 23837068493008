// Generated by ReScript, PLEASE EDIT WITH CARE

import * as TagsManagerView from "./TagsManagerView.js";
import * as TagsExplorerView from "./TagsExplorerView.js";
import * as ReactRouterDom from "react-router-dom";
import * as JsxRuntime from "react/jsx-runtime";
import * as CatalogBrowserViewContext from "../catalog/context/CatalogBrowserViewContext.js";

function TagsManagerRouter(props) {
  return JsxRuntime.jsxs(ReactRouterDom.Routes, {
              children: [
                JsxRuntime.jsx(ReactRouterDom.Route, {
                      path: "/",
                      element: JsxRuntime.jsx(TagsManagerView.make, {})
                    }),
                JsxRuntime.jsx(ReactRouterDom.Route, {
                      path: "/explore/:tagdata",
                      element: JsxRuntime.jsx(CatalogBrowserViewContext.make, {
                            children: JsxRuntime.jsx(TagsExplorerView.make, {})
                          })
                    })
              ]
            });
}

var make = TagsManagerRouter;

export {
  make ,
}
/* TagsManagerView Not a pure module */
