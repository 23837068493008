// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as IDB__Utils from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB__Utils.js";
import * as AuthManager from "../context/AuthManager.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../libs/i18n/ReactI18Next.js";
import * as FlexGrowSpacer from "./FlexGrowSpacer.js";
import * as JsxRuntime from "react/jsx-runtime";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

function ResetAppButton(props) {
  var label = props.label;
  var dbnames = props.dbnames;
  var match = React.useState(function () {
        return "Init";
      });
  var setState = match[1];
  var state = match[0];
  var logout = AuthManager.useLogout();
  var dbnames$1 = React.useMemo((function () {
          return Curry._2(Prelude.$$Array.keep, dbnames, (function (s) {
                        return s !== "";
                      }));
        }), [dbnames]);
  var disabled = React.useMemo((function () {
          if (Prelude.$$Array.isEmpty(dbnames$1)) {
            return true;
          } else {
            return state === "Resetting";
          }
        }), [
        state,
        dbnames$1
      ]);
  var loading = React.useMemo((function () {
          return state === "Resetting";
        }), [state]);
  var label$1 = React.useMemo((function () {
          return Prelude.default(label, JsxRuntime.jsx(ReactI18Next.Message.make, {
                          msg: {
                            NAME: "msg",
                            VAL: [
                              "buttons.reset-app",
                              "Reset app!"
                            ]
                          }
                        }));
        }), [label]);
  var match$1 = React.useTransition();
  var startTransition = match$1[1];
  React.useEffect((function () {
          switch (state) {
            case "Init" :
                break;
            case "Resetting" :
                setState(function (param) {
                      return "Resetting";
                    });
                Prelude.thenDo(Promise.race([
                          Prelude.Promises.ellapsed(2500),
                          Prelude.thenResolve(Promise.all(dbnames$1.map(function (dbname) {
                                        return IDB__Utils.dropdb(dbname);
                                      })), (function (param) {
                                  
                                }))
                        ]), (function () {
                        setState(function (param) {
                              return "Done";
                            });
                      }));
                break;
            case "Done" :
                startTransition(function () {
                      logout();
                      window.location = "/";
                    });
                break;
            
          }
        }), [state]);
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(FlexGrowSpacer.make, {}),
                JsxRuntime.jsx(Button, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                alignItems: "center",
                                children: [
                                  loading ? JsxRuntime.jsx(CircularProgress, {
                                          size: "1rem"
                                        }) : null,
                                  label$1
                                ],
                                direction: ["row"],
                                spacing: 1.0
                              })),
                      onClick: (function (param) {
                          setState(function (param) {
                                return "Resetting";
                              });
                        }),
                      disabled: disabled
                    })
              ],
              style: {
                display: "flex"
              }
            });
}

var make = ResetAppButton;

export {
  make ,
}
/* react Not a pure module */
