// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import JwtDecode from "jwt-decode";

function fromJs(value) {
  if (value === "access") {
    return "access";
  }
  
}

var T = {
  fromJs: fromJs
};

function toJSON(value) {
  return {
          token_type: value.token_type,
          exp: value.exp.valueOf(),
          iat: value.iat.valueOf(),
          jti: value.jti,
          username: value.username
        };
}

var Serializer = {
  toJSON: toJSON
};

var fields = {
  TAG: "Object",
  _0: [
    [
      "token_type",
      $$JSON.Field.variadicString("token_type", (function (s) {
              return fromJs(s);
            }))
    ],
    [
      "exp",
      "Date"
    ],
    [
      "iat",
      "Date"
    ],
    [
      "jti",
      "String"
    ],
    [
      "username",
      "String"
    ]
  ]
};

var Deserializer = $$JSON.MakeDeserializer({
      fields: fields
    });

var Parsed = {
  Serializer: Serializer,
  Deserializer: Deserializer
};

var fields$1 = {
  TAG: "Object",
  _0: [
    [
      "token_type",
      $$JSON.Field.variadicString("token_type", (function (s) {
              return fromJs(s);
            }))
    ],
    [
      "exp",
      "Float"
    ],
    [
      "iat",
      "Float"
    ],
    [
      "jti",
      "String"
    ],
    [
      "username",
      "String"
    ]
  ]
};

var Parser = $$JSON.MakeDeserializer({
      fields: fields$1
    });

function parse(token) {
  return Curry._2(Prelude.Result.map, Parser.fromJSON(JwtDecode(token)), (function (raw) {
                return {
                        token_type: raw.token_type,
                        exp: new Date(raw.exp * 1000.0),
                        iat: new Date(raw.iat * 1000.0),
                        jti: raw.jti,
                        username: raw.username
                      };
              }));
}

function fromJs$1(value) {
  if (value === "refresh") {
    return "refresh";
  }
  
}

var T$1 = {
  fromJs: fromJs$1
};

function toJSON$1(value) {
  return {
          token_type: value.token_type,
          exp: value.exp.valueOf(),
          iat: value.iat.valueOf(),
          jti: value.jti,
          username: value.username
        };
}

var Serializer$1 = {
  toJSON: toJSON$1
};

var fields$2 = {
  TAG: "Object",
  _0: [
    [
      "token_type",
      $$JSON.Field.variadicString("token_type", (function (s) {
              return fromJs$1(s);
            }))
    ],
    [
      "exp",
      "Date"
    ],
    [
      "iat",
      "Date"
    ],
    [
      "jti",
      "String"
    ],
    [
      "username",
      "String"
    ]
  ]
};

var Deserializer$1 = $$JSON.MakeDeserializer({
      fields: fields$2
    });

var Parsed$1 = {
  Serializer: Serializer$1,
  Deserializer: Deserializer$1
};

var fields$3 = {
  TAG: "Object",
  _0: [
    [
      "token_type",
      $$JSON.Field.variadicString("token_type", (function (s) {
              return fromJs$1(s);
            }))
    ],
    [
      "exp",
      "Float"
    ],
    [
      "iat",
      "Float"
    ],
    [
      "jti",
      "String"
    ],
    [
      "username",
      "String"
    ]
  ]
};

var Parser$1 = $$JSON.MakeDeserializer({
      fields: fields$3
    });

function parse$1(token) {
  return Curry._2(Prelude.Result.map, Parser$1.fromJSON(JwtDecode(token)), (function (raw) {
                return {
                        token_type: raw.token_type,
                        exp: new Date(raw.exp * 1000.0),
                        iat: new Date(raw.iat * 1000.0),
                        jti: raw.jti,
                        username: raw.username
                      };
              }));
}

function Access_fromString(prim) {
  return prim;
}

function Access_toString(prim) {
  return prim;
}

var Access = {
  fromString: Access_fromString,
  toString: Access_toString,
  $$null: "",
  TokenType: T,
  Parsed: Parsed,
  parse: parse
};

function Refresh_fromString(prim) {
  return prim;
}

function Refresh_toString(prim) {
  return prim;
}

var Refresh = {
  fromString: Refresh_fromString,
  toString: Refresh_toString,
  TokenType: T$1,
  Parsed: Parsed$1,
  parse: parse$1
};

export {
  Access ,
  Refresh ,
}
/* Deserializer Not a pure module */
