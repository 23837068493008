// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$URL from "../../../utils/URL.js";
import * as LocalFile from "../../../utils/LocalFile.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function makeLocal(id, url) {
  return {
          NAME: "local",
          VAL: {
            id: id,
            url: url
          }
        };
}

function makeRemote(id, url) {
  return {
          NAME: "remote",
          VAL: {
            id: id,
            url: url
          }
        };
}

function makeCached(id, url) {
  return {
          NAME: "cached",
          VAL: {
            id: id,
            url: url
          }
        };
}

function source(file) {
  var variant = file.NAME;
  if (variant === "cached" || variant === "remote") {
    return {
            NAME: "cloud",
            VAL: file.VAL.id
          };
  } else {
    return {
            NAME: "device",
            VAL: file.VAL.id
          };
  }
}

function id(file) {
  return file.VAL.id;
}

async function $$fetch(id, url) {
  var prepUrl = $$URL.fromString(url);
  if (prepUrl === undefined) {
    return ;
  }
  var res = await LocalFile.download(id, Caml_option.valFromOption(prepUrl), true);
  if (res !== undefined) {
    return {
            NAME: "cached",
            VAL: {
              id: res.id,
              url: url
            }
          };
  } else {
    return {
            NAME: "local",
            VAL: {
              id: id,
              url: undefined
            }
          };
  }
}

function isLocal(file) {
  if (typeof file === "object") {
    return file.NAME === "local";
  } else {
    return false;
  }
}

function isCached(file) {
  if (typeof file === "object") {
    return file.NAME === "cached";
  } else {
    return false;
  }
}

function isRemote(file) {
  if (typeof file === "object") {
    return file.NAME === "remote";
  } else {
    return false;
  }
}

async function resource(file) {
  return await LocalFile.getResource(file.VAL.id);
}

async function blob(file) {
  var match = await LocalFile.getResource(file.VAL.id);
  if (match !== undefined) {
    return Caml_option.some(match.file);
  }
  
}

function equal(file, id) {
  if (typeof file === "object" && file.NAME === "remote") {
    return file.VAL.id === id;
  } else {
    return false;
  }
}

function fromBackend(param) {
  return makeRemote(param.id, param.file);
}

export {
  makeLocal ,
  makeRemote ,
  makeCached ,
  source ,
  id ,
  $$fetch ,
  isLocal ,
  isCached ,
  isRemote ,
  resource ,
  blob ,
  equal ,
  fromBackend ,
}
/* URL Not a pure module */
