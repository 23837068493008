// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Tag from "../models/Tag.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TagDropdown from "./TagDropdown.js";
import * as ReactI18Next from "../../../../libs/i18n/ReactI18Next.js";
import * as FlexGrowSpacer from "../../../global/components/FlexGrowSpacer.js";
import * as TagsListService from "../context/TagsListService.js";
import * as JsxRuntime from "react/jsx-runtime";
import Grid from "@mui/material/Grid";
import * as TagsFiltersContext from "../context/TagsFiltersContext.js";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import * as TagsSelectionManager from "../context/TagsSelectionManager.js";
import Tooltip from "@mui/material/Tooltip";
import * as WithTagsLassoSelector from "./WithTagsLassoSelector.js";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import Typography from "@mui/material/Typography";
import PaginationItem from "@mui/material/PaginationItem";
import TableContainer from "@mui/material/TableContainer";
import ImageSearch from "@mui/icons-material/ImageSearch";
import ArrowUpwardRounded from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRounded from "@mui/icons-material/ArrowDownwardRounded";

var letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

function TagsBrowser$TagsFilters$Letters(props) {
  var active = Prelude.default(props.filters.startswith, "");
  var setCurrentPage = TagsListService.useSetCurrentPage();
  var setStartsWith = TagsFiltersContext.useSetStartsWith();
  var removeStartsWith = TagsFiltersContext.useRemoveStartsWith();
  return JsxRuntime.jsx(Stack, {
              children: Caml_option.some(letters.map(function (letter) {
                        return JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx(PaginationItem, {
                                          page: Caml_option.some(letter),
                                          selected: letter === active,
                                          size: "small"
                                        }),
                                    onClick: (function (param) {
                                        if (letter !== active) {
                                          setCurrentPage(1);
                                          return setStartsWith(letter);
                                        } else {
                                          setCurrentPage(1);
                                          return removeStartsWith();
                                        }
                                      })
                                  }, letter);
                      })),
              direction: ["row"],
              spacing: 0.5,
              sx: {
                flexWrap: "wrap"
              },
              useFlexGap: true
            });
}

function TagsBrowser$TagsFilters$Filter(props) {
  var contains = Prelude.default(props.filters.contains, "");
  var setCurrentPage = TagsListService.useSetCurrentPage();
  var setContains = TagsFiltersContext.useSetContains();
  var removeContains = TagsFiltersContext.useRemoveContains();
  return JsxRuntime.jsx(TextField, {
              fullWidth: true,
              label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                        msg: {
                          NAME: "str",
                          VAL: "Filter by"
                        }
                      })),
              onChange: (function (ev) {
                  var target = ev.target;
                  var value = target.value;
                  if (value === "") {
                    if (contains !== "") {
                      setCurrentPage(1);
                      return removeContains();
                    } else {
                      return ;
                    }
                  } else if (contains !== value) {
                    setCurrentPage(1);
                    return setContains(value);
                  } else {
                    return ;
                  }
                }),
              value: contains,
              variant: "standard"
            });
}

function TagsBrowser$TagsFilters(props) {
  var filters = props.filters;
  return JsxRuntime.jsxs(Grid, {
              children: [
                JsxRuntime.jsx(Grid, {
                      children: Caml_option.some(JsxRuntime.jsx(TagsBrowser$TagsFilters$Filter, {
                                filters: filters
                              })),
                      item: true,
                      xs: 3
                    }),
                JsxRuntime.jsx(Grid, {
                      children: Caml_option.some(JsxRuntime.jsx(TagsBrowser$TagsFilters$Letters, {
                                filters: filters
                              })),
                      item: true,
                      xs: 9
                    })
              ],
              container: true,
              sx: {
                alignItems: "center"
              }
            });
}

function TagsBrowser$TagsTable$TagRow(props) {
  var setCurrentBrowsingTag = props.setCurrentBrowsingTag;
  var tag = props.tag;
  var isSelected = Curry._1(Prelude.OptionExported.$$Option.getExn, TagsSelectionManager.useIsSelected());
  var selected = React.useMemo((function () {
          return isSelected(tag);
        }), [
        tag,
        isSelected
      ]);
  var toggle = Curry._1(Prelude.OptionExported.$$Option.getExn, TagsSelectionManager.useToggle());
  return React.useMemo((function () {
                return JsxRuntime.jsxs(TableRow, {
                            className: "selectable-item",
                            style: {
                              cursor: "default",
                              userSelect: "none"
                            },
                            id: String(tag.id),
                            children: [
                              JsxRuntime.jsx(TableCell, {
                                    children: Caml_option.some(JsxRuntime.jsx(Checkbox, {
                                              checked: selected,
                                              onChange: (function (param, param$1) {
                                                  toggle([tag]);
                                                }),
                                              size: "small"
                                            }))
                                  }),
                              JsxRuntime.jsx(TableCell, {
                                    children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                              variant: "body1",
                                              children: Caml_option.some(tag.name)
                                            }))
                                  }),
                              JsxRuntime.jsx(TableCell, {
                                    children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                              variant: "body1",
                                              children: Caml_option.some(tag.approved ? JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                          msg: {
                                                            NAME: "msg",
                                                            VAL: [
                                                              "tags.approved",
                                                              "Approved"
                                                            ]
                                                          }
                                                        }) : JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                          msg: {
                                                            NAME: "msg",
                                                            VAL: [
                                                              "tags.unapproved",
                                                              "Not approved"
                                                            ]
                                                          }
                                                        }))
                                            }))
                                  }),
                              JsxRuntime.jsx(TableCell, {
                                    children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                              children: [
                                                JsxRuntime.jsx(FlexGrowSpacer.make, {}),
                                                JsxRuntime.jsx(IconButton, {
                                                      children: Caml_option.some(JsxRuntime.jsx(ImageSearch, {})),
                                                      onClick: (function (param) {
                                                          setCurrentBrowsingTag(tag);
                                                        })
                                                    }),
                                                JsxRuntime.jsx(TagDropdown.make, {
                                                      tag: tag
                                                    })
                                              ],
                                              direction: ["row"],
                                              spacing: 2.0
                                            }))
                                  })
                            ]
                          });
              }), [
              tag,
              selected
            ]);
}

function TagsBrowser$TagsTable$Column(props) {
  var toggleDirection = props.toggleDirection;
  var setField = props.setField;
  var column = props.column;
  var label;
  label = column === "tokenized" ? JsxRuntime.jsx(ReactI18Next.Message.make, {
          msg: {
            NAME: "msg",
            VAL: [
              "tags-browser-table.name-header",
              "Name"
            ]
          }
        }) : JsxRuntime.jsx(ReactI18Next.Message.make, {
          msg: {
            NAME: "msg",
            VAL: [
              "tags-browser-table.approval-header",
              "Approval"
            ]
          }
        });
  if (column !== props.sortfield) {
    return JsxRuntime.jsxs(Stack, {
                children: [
                  JsxRuntime.jsx(Tooltip, {
                        children: JsxRuntime.jsx("span", {
                              children: label,
                              style: {
                                cursor: "pointer"
                              },
                              onClick: (function (param) {
                                  setField(column);
                                })
                            }),
                        arrow: true,
                        title: JsxRuntime.jsx(ReactI18Next.Message.make, {
                              msg: {
                                NAME: "str",
                                VAL: "Sort field"
                              }
                            })
                      }),
                  JsxRuntime.jsx(ArrowUpwardRounded, {
                        fontSize: "small",
                        style: {
                          opacity: "0"
                        }
                      })
                ],
                direction: ["row"],
                spacing: "1rem",
                sx: {
                  alignItems: "center"
                }
              });
  }
  var tmp;
  tmp = props.direction === "" ? JsxRuntime.jsx(ArrowUpwardRounded, {
          fontSize: "small"
        }) : JsxRuntime.jsx(ArrowDownwardRounded, {
          fontSize: "small"
        });
  return JsxRuntime.jsxs(Stack, {
              children: [
                JsxRuntime.jsx(Tooltip, {
                      children: JsxRuntime.jsx("span", {
                            children: label,
                            style: {
                              cursor: "pointer"
                            },
                            onClick: (function (param) {
                                toggleDirection();
                              })
                          }),
                      arrow: true,
                      title: JsxRuntime.jsx(ReactI18Next.Message.make, {
                            msg: {
                              NAME: "str",
                              VAL: "Reverse sort direction"
                            }
                          })
                    }),
                tmp
              ],
              direction: ["row"],
              spacing: "1rem",
              sx: {
                alignItems: "center"
              }
            });
}

function TagsBrowser$TagsTable(props) {
  var setCurrentBrowsingTag = props.setCurrentBrowsingTag;
  var tags = props.tags;
  var hasSelection = Curry._1(Prelude.OptionExported.$$Option.getExn, TagsSelectionManager.useHasSelection());
  var select = Curry._1(Prelude.OptionExported.$$Option.getExn, TagsSelectionManager.useSelect());
  var unselect = Curry._1(Prelude.OptionExported.$$Option.getExn, TagsSelectionManager.useUnselect());
  var areAllSelected = Curry._1(Prelude.OptionExported.$$Option.getExn, TagsSelectionManager.useAreAllSelected());
  var sortfield = TagsFiltersContext.useOrderColumn();
  var direction = TagsFiltersContext.useOrderDirection();
  var setOrderColumn = TagsFiltersContext.useSetOrderColumn();
  var setOrderDirection = TagsFiltersContext.useSetOrderDirection();
  var toggleDirection = React.useCallback((function () {
          setOrderDirection(Tag.Order.toggleDirection(direction));
        }), [
        direction,
        setOrderDirection
      ]);
  var toggleSelection = function (e, param) {
    var exit = 0;
    var target;
    try {
      target = e.target;
      exit = 1;
    }
    catch (exn){
      return ;
    }
    if (exit === 1) {
      var exit$1 = 0;
      var val;
      try {
        val = target.checked;
        exit$1 = 2;
      }
      catch (exn$1){
        return ;
      }
      if (exit$1 === 2) {
        if (val) {
          return select(tags);
        } else {
          return unselect(tags);
        }
      }
      
    }
    
  };
  var indeterminate = React.useMemo((function () {
          if (areAllSelected(tags)) {
            return false;
          } else {
            return hasSelection;
          }
        }), [
        areAllSelected,
        tags,
        hasSelection
      ]);
  var checked = React.useMemo((function () {
          if (indeterminate) {
            return false;
          } else {
            return areAllSelected(tags);
          }
        }), [
        indeterminate,
        areAllSelected,
        tags
      ]);
  return JsxRuntime.jsx(TableContainer, {
              children: Caml_option.some(JsxRuntime.jsxs(Table, {
                        children: [
                          JsxRuntime.jsx(TableHead, {
                                children: Caml_option.some(JsxRuntime.jsxs(TableRow, {
                                          children: [
                                            JsxRuntime.jsx(TableCell, {
                                                  children: Caml_option.some(JsxRuntime.jsx(Checkbox, {
                                                            checked: checked,
                                                            indeterminate: indeterminate,
                                                            onChange: toggleSelection,
                                                            size: "small"
                                                          }))
                                                }),
                                            JsxRuntime.jsx(TableCell, {
                                                  children: Caml_option.some(JsxRuntime.jsx(TagsBrowser$TagsTable$Column, {
                                                            column: "tokenized",
                                                            sortfield: sortfield,
                                                            direction: direction,
                                                            setField: (function (param) {
                                                                setOrderColumn("tokenized");
                                                              }),
                                                            toggleDirection: toggleDirection
                                                          }))
                                                }),
                                            JsxRuntime.jsx(TableCell, {
                                                  children: Caml_option.some(JsxRuntime.jsx(TagsBrowser$TagsTable$Column, {
                                                            column: "approved",
                                                            sortfield: sortfield,
                                                            direction: direction,
                                                            setField: (function (param) {
                                                                setOrderColumn("approved");
                                                              }),
                                                            toggleDirection: toggleDirection
                                                          }))
                                                }),
                                            JsxRuntime.jsx(TableCell, {})
                                          ]
                                        }))
                              }),
                          JsxRuntime.jsx(TableBody, {
                                children: Caml_option.some(tags.map(function (tag) {
                                          return JsxRuntime.jsx(TagsBrowser$TagsTable$TagRow, {
                                                      tag: tag,
                                                      setCurrentBrowsingTag: setCurrentBrowsingTag
                                                    }, String(tag.id));
                                        }))
                              })
                        ],
                        size: "small"
                      }))
            });
}

function TagsBrowser(props) {
  var pages = props.pages;
  var filters = props.filters;
  var tags = props.tags;
  var page = TagsListService.useCurrentPage();
  var setPage = TagsListService.useSetCurrentPage();
  var removeStartsWith = TagsFiltersContext.useRemoveStartsWith();
  var removeContains = TagsFiltersContext.useRemoveContains();
  if (Prelude.$$Array.isEmpty(tags)) {
    return JsxRuntime.jsxs(Stack, {
                children: [
                  JsxRuntime.jsx(TagsBrowser$TagsFilters, {
                        filters: filters
                      }),
                  JsxRuntime.jsxs(Stack, {
                        children: [
                          JsxRuntime.jsx(Typography, {
                                variant: "h6",
                                children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                          msg: {
                                            NAME: "msg",
                                            VAL: [
                                              "tags-browser-table.not-found-heading",
                                              "Nothing found"
                                            ]
                                          }
                                        }))
                              }),
                          JsxRuntime.jsx(Typography, {
                                variant: "body1",
                                children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                          msg: {
                                            NAME: "msg",
                                            VAL: [
                                              "tags-browser-table.not-found-explanation",
                                              "There are no tags that match your current query.\n               Please, remove some filters to get some results here."
                                            ]
                                          }
                                        }))
                              }),
                          JsxRuntime.jsx(Button, {
                                children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                          msg: {
                                            NAME: "str",
                                            VAL: "Clear filters"
                                          }
                                        })),
                                onClick: (function (param) {
                                    removeStartsWith();
                                    removeContains();
                                  }),
                                fullWidth: false
                              })
                        ],
                        direction: ["column"],
                        spacing: 4.0
                      })
                ],
                direction: ["column"],
                spacing: 2.0,
                sx: {
                  alignItems: "center",
                  width: "100%",
                  padding: "1rem"
                },
                useFlexGap: true
              });
  } else {
    return JsxRuntime.jsx(WithTagsLassoSelector.make, {
                tags: tags,
                children: JsxRuntime.jsxs(Stack, {
                      children: [
                        JsxRuntime.jsx(TagsBrowser$TagsFilters, {
                              filters: filters
                            }),
                        JsxRuntime.jsx(Stack, {
                              children: Caml_option.some(JsxRuntime.jsx(Pagination, {
                                        count: pages,
                                        onChange: (function (param, page) {
                                            setPage(page);
                                          }),
                                        page: page,
                                        showFirstButton: true,
                                        showLastButton: true
                                      })),
                              spacing: 2.0,
                              sx: {
                                display: {
                                  md: "none"
                                }
                              },
                              useFlexGap: true
                            }),
                        JsxRuntime.jsx(TagsBrowser$TagsTable, {
                              tags: tags,
                              setCurrentBrowsingTag: props.setCurrentBrowsingTag
                            }),
                        JsxRuntime.jsx(Stack, {
                              children: Caml_option.some(JsxRuntime.jsx(Pagination, {
                                        count: pages,
                                        onChange: (function (param, page) {
                                            setPage(page);
                                          }),
                                        page: page,
                                        showFirstButton: true,
                                        showLastButton: true
                                      })),
                              spacing: 2.0,
                              useFlexGap: true
                            })
                      ],
                      direction: ["column"],
                      spacing: 2.0,
                      sx: {
                        alignItems: "center",
                        width: "100%",
                        padding: "1rem"
                      },
                      useFlexGap: true
                    })
              });
  }
}

var make = TagsBrowser;

export {
  make ,
}
/* letters Not a pure module */
