// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UserContext from "../../../../global/context/UserContext.js";
import * as ReactI18Next from "../../../../../libs/i18n/ReactI18Next.js";
import * as PasswordInput from "../../../../global/components/PasswordInput.js";
import * as ProtectedArea from "../../../../utils/ProtectedArea.js";
import * as ActionsManager from "../../../../global/context/ActionsManager.js";
import Fab from "@mui/material/Fab";
import * as JsxRuntime from "react/jsx-runtime";
import * as GroupsAutocomplete from "../GroupsAutocomplete.js";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import * as AccountsAPIEndpoint from "../../api/AccountsAPIEndpoint.js";
import * as AccountsListService from "../../context/AccountsListService.js";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import * as CreateAccountService from "../../context/CreateAccountService.js";
import Add from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

var usernameRegex = /^[A-Za-z_\-\.0-9@]{3,}$/;

var emailRegex = /^(([^<>()\[\]\\.,;:\s@\"]+(\.[^<>()\[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function CreateAccountAffordance$CreateAccountDialog(props) {
  var onDone = props.onDone;
  var onClose = props.onClose;
  var create = props.create;
  var match = React.useState(function () {
        
      });
  var setUsernameAvailable = match[1];
  var usernameAvailable = match[0];
  var match$1 = React.useState(function () {
        return {
                username: "",
                password: "",
                password_confirmation: "",
                first_name: "",
                last_name: "",
                email: "",
                groups: []
              };
      });
  var setForm = match$1[1];
  var form = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setError = match$2[1];
  var error = match$2[0];
  var usernameValid = React.useMemo((function () {
          if (Prelude.default(usernameAvailable, false)) {
            return usernameRegex.test(form.username);
          } else {
            return false;
          }
        }), [
        form.username,
        usernameAvailable
      ]);
  var emailValid = React.useMemo((function () {
          var email = form.email.trim();
          if (email.length === 0) {
            return true;
          } else {
            return emailRegex.test(email);
          }
        }), [form.email]);
  var passwordValid = React.useMemo((function () {
          if (form.password.length > 6) {
            return form.password === form.password_confirmation;
          } else {
            return false;
          }
        }), [
        form.password,
        form.password_confirmation
      ]);
  var valid = usernameValid && emailValid && passwordValid;
  var token = Curry._1(Prelude.OptionExported.$$Option.getExn, UserContext.useAccessToken());
  var match$3 = React.useState(function () {
        
      });
  var setTimer = match$3[1];
  var timer = match$3[0];
  var clearTimer = React.useCallback((function () {
          if (timer !== undefined) {
            clearTimeout(Caml_option.valFromOption(timer));
            return ;
          }
          
        }), [
        timer,
        setTimer
      ]);
  React.useEffect((function () {
          clearTimer();
          setUsernameAvailable(function (param) {
                
              });
          var tid = setTimeout((function () {
                  Prelude.thenDo(AccountsAPIEndpoint.Search.$$fetch(token, {
                            username: form.username
                          }, undefined, 1, undefined), (function (result) {
                          if (result.TAG !== "Ok") {
                            return setUsernameAvailable(function (param) {
                                        return true;
                                      });
                          }
                          var count = result._0.count;
                          setUsernameAvailable(function (param) {
                                return count === 0;
                              });
                        }));
                }), 200);
          setTimer(function (param) {
                return Caml_option.some(tid);
              });
          return clearTimer;
        }), [
        form.username,
        token
      ]);
  var onSave = React.useCallback((function () {
          var username = form.username;
          var password = form.password;
          var email = form.email.trim();
          var email$1 = email !== "" ? email : undefined;
          var first_name = form.first_name.trim();
          var first_name$1 = first_name !== "" ? first_name : undefined;
          var last_name = form.last_name.trim();
          var last_name$1 = last_name !== "" ? last_name : undefined;
          Prelude.thenDo(create({
                    username: username,
                    password: password,
                    first_name: first_name$1,
                    last_name: last_name$1,
                    email: email$1,
                    groups: form.groups.map(function (g) {
                          return g.id;
                        })
                  }), (function (result) {
                  if (result.TAG === "Ok") {
                    return onDone();
                  } else {
                    return setError(function (param) {
                                return Caml_option.some(JsxRuntime.jsx(Alert, {
                                                children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                          msg: {
                                                            NAME: "msg",
                                                            VAL: [
                                                              "accounts.create-user-error",
                                                              "The was an error creating the user account.  Try again in a few minutes.\n                       If the error persists, contact us."
                                                            ]
                                                          }
                                                        })),
                                                severity: "error"
                                              }));
                              });
                  }
                }));
        }), [
        form,
        create,
        onDone
      ]);
  var match$4 = React.useState(function () {
        return false;
      });
  var setShowPassword = match$4[1];
  var showPassword = match$4[0];
  var groupsHelperText = React.useMemo((function () {
          if (Prelude.$$Array.isEmpty(form.groups)) {
            return Caml_option.some(JsxRuntime.jsx(Typography, {
                            color: "error",
                            variant: "body2",
                            children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                      msg: {
                                        NAME: "msg",
                                        VAL: [
                                          "accounts.no-groups-warning",
                                          "A user without groups might log in, but won't have access to any module"
                                        ]
                                      }
                                    }))
                          }));
          }
          
        }), [form.groups]);
  var match$5 = form.username;
  var tmp = match$5 === "" || !(usernameAvailable !== undefined && !usernameAvailable) ? null : JsxRuntime.jsx(ReactI18Next.Message.make, {
          msg: {
            NAME: "msg",
            VAL: [
              "accounts.username-unavailable",
              "The username is already in use"
            ]
          }
        });
  return JsxRuntime.jsxs(Dialog, {
              open: true,
              children: [
                JsxRuntime.jsx(DialogTitle, {
                      children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                msg: {
                                  NAME: "msg",
                                  VAL: [
                                    "accounts.create-title",
                                    "Create account"
                                  ]
                                }
                              }))
                    }),
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                children: [
                                  JsxRuntime.jsx(Typography, {
                                        variant: "h6",
                                        children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                  msg: {
                                                    NAME: "msg",
                                                    VAL: [
                                                      "accounts.access-section-heading",
                                                      "Access"
                                                    ]
                                                  }
                                                }))
                                      }),
                                  JsxRuntime.jsx(TextField, {
                                        error: form.username !== "" && !usernameValid,
                                        fullWidth: true,
                                        helperText: Caml_option.some(tmp),
                                        label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                  msg: {
                                                    NAME: "msg",
                                                    VAL: [
                                                      "accounts.account-field-username",
                                                      "Username"
                                                    ]
                                                  }
                                                })),
                                        onChange: (function (ev) {
                                            var value = ev.target.value.trim();
                                            setForm(function (f) {
                                                  return {
                                                          username: value,
                                                          password: f.password,
                                                          password_confirmation: f.password_confirmation,
                                                          first_name: f.first_name,
                                                          last_name: f.last_name,
                                                          email: f.email,
                                                          groups: f.groups
                                                        };
                                                });
                                          }),
                                        required: true,
                                        value: form.username
                                      }),
                                  JsxRuntime.jsxs(Stack, {
                                        children: [
                                          JsxRuntime.jsx(PasswordInput.make, {
                                                value: form.password,
                                                onChange: (function (value) {
                                                    setForm(function (f) {
                                                          return {
                                                                  username: f.username,
                                                                  password: value,
                                                                  password_confirmation: f.password_confirmation,
                                                                  first_name: f.first_name,
                                                                  last_name: f.last_name,
                                                                  email: f.email,
                                                                  groups: f.groups
                                                                };
                                                        });
                                                  }),
                                                label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                          msg: {
                                                            NAME: "msg",
                                                            VAL: [
                                                              "accounts.account-field-password",
                                                              "Password"
                                                            ]
                                                          }
                                                        })),
                                                fullWidth: true,
                                                error: form.password !== "" && !passwordValid,
                                                showPassword: showPassword,
                                                setShowPassword: setShowPassword
                                              }),
                                          JsxRuntime.jsx(PasswordInput.make, {
                                                value: form.password_confirmation,
                                                onChange: (function (value) {
                                                    setForm(function (f) {
                                                          return {
                                                                  username: f.username,
                                                                  password: f.password,
                                                                  password_confirmation: value,
                                                                  first_name: f.first_name,
                                                                  last_name: f.last_name,
                                                                  email: f.email,
                                                                  groups: f.groups
                                                                };
                                                        });
                                                  }),
                                                label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                          msg: {
                                                            NAME: "msg",
                                                            VAL: [
                                                              "accounts.account-field-password-confirmation",
                                                              "Password confirmation"
                                                            ]
                                                          }
                                                        })),
                                                fullWidth: true,
                                                error: form.password_confirmation !== "" && !passwordValid,
                                                showPassword: showPassword,
                                                setShowPassword: setShowPassword
                                              })
                                        ],
                                        direction: ["row"],
                                        spacing: 2.0
                                      }),
                                  JsxRuntime.jsx(Typography, {
                                        variant: "h6",
                                        children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                  msg: {
                                                    NAME: "msg",
                                                    VAL: [
                                                      "accounts.general-section-heading",
                                                      "General"
                                                    ]
                                                  }
                                                }))
                                      }),
                                  JsxRuntime.jsx(TextField, {
                                        error: !emailValid,
                                        fullWidth: true,
                                        label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                  msg: {
                                                    NAME: "msg",
                                                    VAL: [
                                                      "accounts.account-field-email",
                                                      "Email address"
                                                    ]
                                                  }
                                                })),
                                        onChange: (function (ev) {
                                            var email = ev.target.value;
                                            setForm(function (f) {
                                                  return {
                                                          username: f.username,
                                                          password: f.password,
                                                          password_confirmation: f.password_confirmation,
                                                          first_name: f.first_name,
                                                          last_name: f.last_name,
                                                          email: email,
                                                          groups: f.groups
                                                        };
                                                });
                                          }),
                                        required: false,
                                        type: "email",
                                        value: form.email
                                      }),
                                  JsxRuntime.jsxs(Stack, {
                                        children: [
                                          JsxRuntime.jsx(TextField, {
                                                fullWidth: true,
                                                label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                          msg: {
                                                            NAME: "msg",
                                                            VAL: [
                                                              "accounts.account-field-first-name",
                                                              "First Name"
                                                            ]
                                                          }
                                                        })),
                                                onChange: (function (ev) {
                                                    var first_name = ev.target.value;
                                                    setForm(function (f) {
                                                          return {
                                                                  username: f.username,
                                                                  password: f.password,
                                                                  password_confirmation: f.password_confirmation,
                                                                  first_name: first_name,
                                                                  last_name: f.last_name,
                                                                  email: f.email,
                                                                  groups: f.groups
                                                                };
                                                        });
                                                  }),
                                                required: false,
                                                value: form.first_name
                                              }),
                                          JsxRuntime.jsx(TextField, {
                                                fullWidth: true,
                                                label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                          msg: {
                                                            NAME: "msg",
                                                            VAL: [
                                                              "accounts.account-field-last-name",
                                                              "Last Name"
                                                            ]
                                                          }
                                                        })),
                                                onChange: (function (ev) {
                                                    var last_name = ev.target.value;
                                                    setForm(function (f) {
                                                          return {
                                                                  username: f.username,
                                                                  password: f.password,
                                                                  password_confirmation: f.password_confirmation,
                                                                  first_name: f.first_name,
                                                                  last_name: last_name,
                                                                  email: f.email,
                                                                  groups: f.groups
                                                                };
                                                        });
                                                  }),
                                                required: false,
                                                value: form.last_name
                                              })
                                        ],
                                        direction: ["row"],
                                        spacing: 2.0
                                      }),
                                  JsxRuntime.jsx(Typography, {
                                        variant: "h6",
                                        children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                  msg: {
                                                    NAME: "msg",
                                                    VAL: [
                                                      "accounts.groups-section-heading",
                                                      "Groups"
                                                    ]
                                                  }
                                                }))
                                      }),
                                  JsxRuntime.jsx(GroupsAutocomplete.make, {
                                        groups: form.groups,
                                        onChange: (function (groups) {
                                            setForm(function (f) {
                                                  return {
                                                          username: f.username,
                                                          password: f.password,
                                                          password_confirmation: f.password_confirmation,
                                                          first_name: f.first_name,
                                                          last_name: f.last_name,
                                                          email: f.email,
                                                          groups: groups
                                                        };
                                                });
                                          }),
                                        helperText: groupsHelperText
                                      }),
                                  error !== undefined ? Caml_option.valFromOption(error) : null
                                ],
                                direction: ["column"],
                                spacing: 2.0
                              }))
                    }),
                JsxRuntime.jsxs(DialogActions, {
                      children: [
                        JsxRuntime.jsx(Button, {
                              children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                        msg: {
                                          NAME: "msg",
                                          VAL: [
                                            "buttons.cancel",
                                            "Cancel"
                                          ]
                                        }
                                      })),
                              onClick: (function (param) {
                                  onClose();
                                })
                            }),
                        JsxRuntime.jsx(Button, {
                              children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                        msg: {
                                          NAME: "msg",
                                          VAL: [
                                            "buttons.save",
                                            "Save"
                                          ]
                                        }
                                      })),
                              onClick: (function (param) {
                                  onSave();
                                }),
                              disabled: !valid
                            })
                      ]
                    })
              ],
              fullWidth: true,
              maxWidth: "md",
              onClose: (function (param, param$1) {
                  onClose();
                })
            });
}

function CreateAccountAffordance$CreateAccountButton(props) {
  var create = props.create;
  var disabled = Curry._1(Prelude.OptionExported.$$Option.isNone, create);
  var match = React.useState(function () {
        return false;
      });
  var setActive = match[1];
  var refresh = AccountsListService.useRefresh();
  var setCurrentPage = AccountsListService.useSetCurrentPage();
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Fab, {
                            children: Caml_option.some(JsxRuntime.jsx(Add, {})),
                            color: "primary",
                            disabled: disabled,
                            size: "small"
                          }),
                      onClick: (function (param) {
                          setActive(function (param) {
                                return true;
                              });
                        })
                    }),
                JsxRuntime.jsx(ProtectedArea.make, {
                      requirement: {
                        TAG: "Pred",
                        _0: match[0]
                      },
                      children: create !== undefined ? JsxRuntime.jsx(CreateAccountAffordance$CreateAccountDialog, {
                              create: create,
                              onClose: (function () {
                                  setActive(function (param) {
                                        return false;
                                      });
                                }),
                              onDone: (function () {
                                  refresh();
                                  setCurrentPage(1);
                                  setActive(function (param) {
                                        return false;
                                      });
                                })
                            }) : null
                    })
              ]
            });
}

function CreateAccountAffordance(props) {
  var create = CreateAccountService.useCreateAccount();
  return JsxRuntime.jsx(ActionsManager.Register.make, {
              name: "create-account",
              widget: (function (key) {
                  return JsxRuntime.jsx(CreateAccountAffordance$CreateAccountButton, {
                              create: create
                            }, key);
                }),
              sequence: 10,
              children: props.children
            });
}

var make = CreateAccountAffordance;

export {
  make ,
}
/* react Not a pure module */
