// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Tag from "./models/Tag.js";
import * as TagsBrowser from "./components/TagsBrowser.js";
import * as TagsManager from "./context/TagsManager.js";
import * as TagsListService from "./context/TagsListService.js";
import * as ApplicationMaker from "../../global/apps/ApplicationMaker.js";
import * as CreateTagService from "./context/CreateTagService.js";
import * as RemoveTagService from "./context/RemoveTagService.js";
import * as UpdateTagService from "./context/UpdateTagService.js";
import * as ReactRouterDom from "react-router-dom";
import * as JsxRuntime from "react/jsx-runtime";
import * as TagsFiltersContext from "./context/TagsFiltersContext.js";
import * as CreateTagAffordance from "./components/affordances/CreateTagAffordance.js";
import * as TagsCountAffordance from "./components/affordances/TagsCountAffordance.js";
import * as TagsManagerManifest from "./TagsManagerManifest.js";
import * as TagsSelectionManager from "./context/TagsSelectionManager.js";
import * as TagsSelectionAffordance from "./components/affordances/TagsSelectionAffordance.js";

function TagsManagerView$Services(props) {
  return JsxRuntime.jsx(TagsManager.make, {
              children: JsxRuntime.jsx(TagsSelectionManager.make, {
                    children: JsxRuntime.jsx(TagsFiltersContext.make, {
                          children: JsxRuntime.jsx(TagsListService.make, {
                                children: JsxRuntime.jsx(CreateTagService.make, {
                                      children: JsxRuntime.jsx(UpdateTagService.make, {
                                            children: JsxRuntime.jsx(RemoveTagService.make, {
                                                  children: props.children
                                                })
                                          })
                                    })
                              })
                        })
                  })
            });
}

var Services = {
  make: TagsManagerView$Services
};

function TagsManagerView$Affordances(props) {
  return JsxRuntime.jsx(CreateTagAffordance.make, {
              children: JsxRuntime.jsx(TagsCountAffordance.make, {
                    children: JsxRuntime.jsx(TagsSelectionAffordance.make, {
                          children: props.children
                        })
                  })
            });
}

var Affordances = {
  make: TagsManagerView$Affordances
};

function TagsManagerView$Main(props) {
  var tags = TagsListService.useTags();
  var filters = TagsFiltersContext.useFilters();
  var pages = TagsListService.usePagesCount();
  var navigate = ReactRouterDom.useNavigate();
  return JsxRuntime.jsx(TagsBrowser.make, {
              tags: tags,
              filters: filters,
              pages: pages,
              setCurrentBrowsingTag: (function (tag) {
                  navigate("/tags/explore/" + Tag.toURLPayload(tag));
                })
            });
}

var Main = {
  make: TagsManagerView$Main
};

var include = ApplicationMaker.MakeStandard({
      Manifest: TagsManagerManifest,
      Services: Services,
      Affordances: Affordances,
      Main: Main
    });

var Manifest = include.Manifest;

var make = include.make;

export {
  Manifest ,
  make ,
}
/* include Not a pure module */
