// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as FolderId from "../../catalog/models/FolderId.js";
import * as Settings from "../../../Settings.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FolderMatch from "../models/FolderMatch.js";
import * as ReactI18Next from "../../../../libs/i18n/ReactI18Next.js";
import * as SearchMetaMaker from "../utils/SearchMetaMaker.js";
import * as NamespaceContext from "../../../global/context/NamespaceContext.js";
import * as SearchAPIEndpoint from "../api/SearchAPIEndpoint.js";
import * as JsxRuntime from "react/jsx-runtime";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import CheckBox from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";

function getValueName(f) {
  return Prelude.default(Prelude.$$Array.first(f.names), f.label);
}

function getValueKey(f) {
  return FolderId.toString(Prelude.default(Prelude.$$Array.first(Prelude.$$Array.sort(f.matches, (function (x) {
                            return x;
                          }), undefined)), FolderId.$$null));
}

var defaultPageSize = Settings.Autocomplete.maxItems > 50 ? Settings.Autocomplete.maxItems : 50;

function renderOption(props, value, param, param$1) {
  var count = value.matches.length;
  var newrecord = Caml_obj.obj_dup(props);
  return JsxRuntime.jsxs("li", (newrecord.children = [
                JsxRuntime.jsx(Checkbox, {
                      style: {
                        marginRight: "0.75rem"
                      },
                      checked: param.selected,
                      checkedIcon: Caml_option.some(JsxRuntime.jsx(CheckBox, {
                                fontSize: "small"
                              })),
                      icon: Caml_option.some(JsxRuntime.jsx(CheckBoxOutlineBlank, {
                                fontSize: "small"
                              }))
                    }),
                JsxRuntime.jsx("span", {
                      children: getValueName(value),
                      style: {
                        marginRight: "0.5rem"
                      }
                    }),
                count > 1 ? JsxRuntime.jsx(Chip, {
                        color: "secondary",
                        label: Caml_option.some(count),
                        size: "small"
                      }) : null
              ], newrecord), getValueKey(value));
}

var renderOption$1 = renderOption;

var notFoundMessage = Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
          msg: {
            NAME: "msg",
            VAL: [
              "folders-autocomplete.not-found",
              "No matching folders found"
            ]
          }
        }));

var $$fetch = SearchAPIEndpoint.Meta.Folder.$$fetch;

var Impl = SearchMetaMaker.MakeAutocomplete({
      identifier: "FoldersAutocomplete",
      defaultValue: undefined,
      getValueName: getValueName,
      getValueKey: getValueKey,
      makeSolo: undefined,
      notFoundMessage: notFoundMessage,
      promptMessage: undefined,
      defaultPageSize: defaultPageSize,
      groupBy: undefined,
      renderOption: renderOption$1,
      $$fetch: $$fetch
    });

function FoldersAutocomplete(props) {
  var ns = NamespaceContext.useNamespace();
  return JsxRuntime.jsx(Impl.make, {
              items: props.folders,
              onChange: props.onChange,
              getFilter: (function (value) {
                  var newrecord = Caml_obj.obj_dup(FolderMatch.Filters.$$default(ns.id));
                  newrecord.contains = value;
                  return newrecord;
                }),
              label: props.label,
              variant: props.variant,
              sx: props.sx,
              fullWidth: props.fullWidth,
              limitTags: props.limitTags,
              placeholder: props.placeholder
            });
}

var make = FoldersAutocomplete;

export {
  make ,
}
/* Impl Not a pure module */
