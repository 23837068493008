// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AuthToken from "../../../global/models/AuthToken.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UserContext from "../../../global/context/UserContext.js";
import * as ReactI18Next from "../../../../libs/i18n/ReactI18Next.js";
import * as ReactI18next from "react-i18next";
import * as AttributeMatch from "../../search/models/AttributeMatch.js";
import * as FeedbackDelayed from "../../../global/components/FeedbackDelayed.js";
import * as CityAutocomplete from "../../search/components/CityAutocomplete.js";
import * as NamespaceContext from "../../../global/context/NamespaceContext.js";
import * as TagsAutocomplete from "../../search/components/TagsAutocomplete.js";
import * as JsxRuntime from "react/jsx-runtime";
import List from "@mui/material/List";
import * as CatalogAPIEndpoint from "../api/CatalogAPIEndpoint.js";
import Stack from "@mui/material/Stack";
import * as AuthorsAutocomplete from "../../search/components/AuthorsAutocomplete.js";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
import ListItem from "@mui/material/ListItem";
import TextField from "@mui/material/TextField";
import * as CollectionsAutocomplete from "../../search/components/CollectionsAutocomplete.js";
import * as DocumentAttributeLabels from "./document/DocumentAttributeLabels.js";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import ToggleButton from "@mui/material/ToggleButton";
import * as CurrentDocumentListManager from "../context/CurrentDocumentListManager.js";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import LinearProgress from "@mui/material/LinearProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import PlaylistAdd from "@mui/icons-material/PlaylistAdd";
import DialogContentText from "@mui/material/DialogContentText";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import PlaylistAddCheck from "@mui/icons-material/PlaylistAddCheck";

var default_title = {
  selected: false,
  value: ""
};

var default_author = {
  selected: false,
  value: ""
};

var default_city = {
  selected: false,
  value: ""
};

var default_tags = {
  selected: false,
  mode: "set",
  value: []
};

var default_collections = {
  selected: false,
  mode: "add",
  value: []
};

var $$default = {
  title: default_title,
  author: default_author,
  city: default_city,
  tags: default_tags,
  collections: default_collections
};

function hasSelections(form) {
  var collections = form.collections;
  if (form.title.selected || form.author.selected || form.city.selected || form.tags.selected || collections.selected && collections.mode === "set") {
    return true;
  } else {
    return !Prelude.$$Array.isEmpty(collections.value);
  }
}

function toOperation(documents, form) {
  var result_ids = documents.map(function (param) {
        return param.id;
      });
  var result = {
    ids: result_ids
  };
  var result$1;
  if (form.title.selected) {
    var newrecord = Caml_obj.obj_dup(result);
    newrecord.title = form.title.value;
    result$1 = newrecord;
  } else {
    result$1 = result;
  }
  var result$2;
  if (form.author.selected) {
    var newrecord$1 = Caml_obj.obj_dup(result$1);
    newrecord$1.author = form.author.value;
    result$2 = newrecord$1;
  } else {
    result$2 = result$1;
  }
  var result$3;
  if (form.city.selected) {
    var newrecord$2 = Caml_obj.obj_dup(result$2);
    newrecord$2.city = form.city.value;
    result$3 = newrecord$2;
  } else {
    result$3 = result$2;
  }
  var result$4;
  if (form.tags.selected) {
    var newrecord$3 = Caml_obj.obj_dup(result$3);
    newrecord$3.tags = {
      mode: form.tags.mode,
      values: form.tags.value
    };
    result$4 = newrecord$3;
  } else {
    result$4 = result$3;
  }
  if (!form.collections.selected) {
    return result$4;
  }
  var newrecord$4 = Caml_obj.obj_dup(result$4);
  newrecord$4.collections = {
    mode: form.collections.mode,
    values: Prelude.$$Array.flatMap(form.collections.value, (function (param) {
            return param.matches;
          }))
  };
  return newrecord$4;
}

function BulkEditDialog$FormData$TitleHelper(props) {
  var form = props.form;
  return React.useMemo((function () {
                if (!form.title.selected) {
                  return JsxRuntime.jsx(ReactI18Next.Message.make, {
                              msg: {
                                NAME: "msg",
                                VAL: [
                                  "bulk-edit-dialog.title-unchanged",
                                  "The title of the images won't be changed"
                                ]
                              }
                            });
                }
                var value = form.title.value.trim();
                if (value === "") {
                  return JsxRuntime.jsx(ReactI18next.Trans, {
                              i18nKey: "bulk-edit-dialog.title-recounted",
                              children: "The title of the images will be set to a continuous number."
                            });
                } else {
                  return JsxRuntime.jsx(ReactI18next.Trans, {
                              i18nKey: "bulk-edit-dialog.title-replaced",
                              children: "The title of the images will be set to '{{count}}' plus a continuous number.",
                              count: value
                            });
                }
              }), [form.title]);
}

var TitleHelper = {
  make: BulkEditDialog$FormData$TitleHelper
};

function BulkEditDialog$FormData$AuthorHelper(props) {
  var form = props.form;
  return React.useMemo((function () {
                if (!form.author.selected) {
                  return JsxRuntime.jsx(ReactI18Next.Message.make, {
                              msg: {
                                NAME: "msg",
                                VAL: [
                                  "bulk-edit-dialog.author-unchanged",
                                  "The author of the images won't be changed"
                                ]
                              }
                            });
                }
                var value = form.author.value.trim();
                if (value === "") {
                  return JsxRuntime.jsxs(ReactI18next.Trans, {
                              i18nKey: "bulk-edit-dialog.author-removed",
                              children: [
                                "The author of the images will be ",
                                JsxRuntime.jsx(Typography, {
                                      component: "strong",
                                      children: "removed.",
                                      sx: {
                                        color: "error.main",
                                        fontSize: "inherit",
                                        fontWeight: "800"
                                      }
                                    })
                              ]
                            });
                } else {
                  return JsxRuntime.jsx(ReactI18next.Trans, {
                              i18nKey: "bulk-edit-dialog.author-replaced",
                              children: "The author of the images will be set exactly to '{{count}}'.",
                              count: value
                            });
                }
              }), [form.author]);
}

var AuthorHelper = {
  make: BulkEditDialog$FormData$AuthorHelper
};

function BulkEditDialog$FormData$CityHelper(props) {
  var form = props.form;
  return React.useMemo((function () {
                if (!form.city.selected) {
                  return JsxRuntime.jsx(ReactI18Next.Message.make, {
                              msg: {
                                NAME: "msg",
                                VAL: [
                                  "bulk-edit-dialog.city-unchanged",
                                  "The city of the images won't be changed"
                                ]
                              }
                            });
                }
                var value = form.city.value.trim();
                if (value === "") {
                  return JsxRuntime.jsxs(ReactI18next.Trans, {
                              i18nKey: "bulk-edit-dialog.city-removed",
                              children: [
                                "The city of the images will be ",
                                JsxRuntime.jsx(Typography, {
                                      component: "strong",
                                      children: "removed.",
                                      sx: {
                                        color: "error.main",
                                        fontSize: "inherit",
                                        fontWeight: "800"
                                      }
                                    })
                              ]
                            });
                } else {
                  return JsxRuntime.jsx(ReactI18next.Trans, {
                              i18nKey: "bulk-edit-dialog.city-replaced",
                              children: "The city of the images will be set exactly to '{{count}}'.",
                              count: value
                            });
                }
              }), [form.city]);
}

var CityHelper = {
  make: BulkEditDialog$FormData$CityHelper
};

function BulkEditDialog$FormData$TagsHelper(props) {
  var form = props.form;
  return React.useMemo((function () {
                if (!form.tags.selected) {
                  return JsxRuntime.jsx(ReactI18Next.Message.make, {
                              msg: {
                                NAME: "msg",
                                VAL: [
                                  "bulk-edit-dialog.tags-unchanged",
                                  "The tags of the images won't be changed."
                                ]
                              }
                            });
                }
                var match = form.tags.mode;
                var match$1 = form.tags.value;
                if (match === "set") {
                  if (match$1.length !== 0) {
                    return JsxRuntime.jsx(ReactI18next.Trans, {
                                i18nKey: "bulk-edit-dialog.tags-replaced",
                                children: "The tags all images are going to be replaced"
                              });
                  } else {
                    return JsxRuntime.jsxs(ReactI18next.Trans, {
                                i18nKey: "bulk-edit-dialog.all-tags-removed",
                                children: [
                                  "The tags of all images are going to be ",
                                  JsxRuntime.jsx(Typography, {
                                        component: "strong",
                                        children: "removed",
                                        sx: {
                                          color: "error.main",
                                          fontSize: "inherit",
                                          fontWeight: "800"
                                        }
                                      }),
                                  " because no tag was selected."
                                ]
                              });
                  }
                } else if (match === "remove") {
                  return JsxRuntime.jsx(ReactI18next.Trans, {
                              i18nKey: "bulk-edit-dialog.tags-removed",
                              children: "The selected tags are going to be removed in all images. Other existing tags will be kept."
                            });
                } else {
                  return JsxRuntime.jsx(ReactI18next.Trans, {
                              i18nKey: "bulk-edit-dialog.tags-added",
                              children: "The tags are going to be added to all images. Existing tags will be kept."
                            });
                }
              }), [form.tags]);
}

var TagsHelper = {
  make: BulkEditDialog$FormData$TagsHelper
};

function BulkEditDialog$FormData$CollectionsHelper(props) {
  var form = props.form;
  return React.useMemo((function () {
                if (!form.collections.selected) {
                  return JsxRuntime.jsx(ReactI18Next.Message.make, {
                              msg: {
                                NAME: "msg",
                                VAL: [
                                  "bulk-edit-dialog.collections-unchanged",
                                  "The collections of the images won't be changed."
                                ]
                              }
                            });
                }
                var match = form.collections.mode;
                var match$1 = form.collections.value;
                if (match === "set") {
                  if (match$1.length !== 0) {
                    return JsxRuntime.jsx(ReactI18next.Trans, {
                                i18nKey: "bulk-edit-dialog.collections-replaced",
                                children: "The images are to be put exactly in the selected collections, and possibly removed from other collections."
                              });
                  } else {
                    return JsxRuntime.jsxs(ReactI18next.Trans, {
                                i18nKey: "bulk-edit-dialog.all-collections-removed",
                                children: [
                                  "The images are going to be ",
                                  JsxRuntime.jsx(Typography, {
                                        component: "strong",
                                        children: "removed",
                                        sx: {
                                          color: "error.main",
                                          fontSize: "inherit",
                                          fontWeight: "800"
                                        }
                                      }),
                                  " from every collection they might be in, because no collection was selected."
                                ]
                              });
                  }
                } else if (match === "remove") {
                  return JsxRuntime.jsx(ReactI18next.Trans, {
                              i18nKey: "bulk-edit-dialog.collections-removed",
                              children: "The images are going to be removed from the selected collections."
                            });
                } else {
                  return JsxRuntime.jsx(ReactI18next.Trans, {
                              i18nKey: "bulk-edit-dialog.collections-added",
                              children: "The images are going to be added to the selected collections."
                            });
                }
              }), [form.collections]);
}

var CollectionsHelper = {
  make: BulkEditDialog$FormData$CollectionsHelper
};

var $$FormData = {
  $$default: $$default,
  hasSelections: hasSelections,
  toOperation: toOperation,
  TitleHelper: TitleHelper,
  AuthorHelper: AuthorHelper,
  CityHelper: CityHelper,
  TagsHelper: TagsHelper,
  CollectionsHelper: CollectionsHelper
};

function BulkEditDialog$SavingDialog(props) {
  var form = props.form;
  var count = props.documents.length;
  return JsxRuntime.jsx(FeedbackDelayed.make, {
              children: JsxRuntime.jsxs(Dialog, {
                    open: true,
                    children: [
                      JsxRuntime.jsx(DialogTitle, {
                            children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                      msg: {
                                        NAME: "msg",
                                        VAL: [
                                          "bulk-edit-dialog.title",
                                          "Bulk edit"
                                        ]
                                      }
                                    }))
                          }),
                      JsxRuntime.jsxs(DialogContent, {
                            children: [
                              JsxRuntime.jsx(DialogContentText, {
                                    children: Caml_option.some(JsxRuntime.jsx(ReactI18next.Trans, {
                                              i18nKey: "bulk-edit-dialog.progress-message-with-count",
                                              children: "Updating the data of {{count}} images.",
                                              count: count
                                            }))
                                  }),
                              JsxRuntime.jsx(LinearProgress, {})
                            ]
                          }),
                      JsxRuntime.jsxs(DialogContent, {
                            children: [
                              JsxRuntime.jsx(Typography, {
                                    variant: "h6",
                                    children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                              msg: {
                                                NAME: "msg",
                                                VAL: [
                                                  "bulk-edit-dialog.changes-summary-heading",
                                                  "Summary"
                                                ]
                                              }
                                            }))
                                  }),
                              JsxRuntime.jsxs(List, {
                                    children: [
                                      JsxRuntime.jsx(ListItem, {
                                            children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                      variant: "body1",
                                                      children: Caml_option.some(JsxRuntime.jsx(BulkEditDialog$FormData$TitleHelper, {
                                                                form: form
                                                              }))
                                                    }))
                                          }),
                                      JsxRuntime.jsx(ListItem, {
                                            children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                      variant: "body1",
                                                      children: Caml_option.some(JsxRuntime.jsx(BulkEditDialog$FormData$AuthorHelper, {
                                                                form: form
                                                              }))
                                                    }))
                                          }),
                                      JsxRuntime.jsx(ListItem, {
                                            children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                      variant: "body1",
                                                      children: Caml_option.some(JsxRuntime.jsx(BulkEditDialog$FormData$CityHelper, {
                                                                form: form
                                                              }))
                                                    }))
                                          }),
                                      JsxRuntime.jsx(ListItem, {
                                            children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                      variant: "body1",
                                                      children: Caml_option.some(JsxRuntime.jsx(BulkEditDialog$FormData$TagsHelper, {
                                                                form: form
                                                              }))
                                                    }))
                                          }),
                                      JsxRuntime.jsx(ListItem, {
                                            children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                      variant: "body1",
                                                      children: Caml_option.some(JsxRuntime.jsx(BulkEditDialog$FormData$CollectionsHelper, {
                                                                form: form
                                                              }))
                                                    }))
                                          })
                                    ]
                                  })
                            ]
                          })
                    ],
                    fullWidth: true,
                    maxWidth: "md"
                  }),
              fallback: Caml_option.some(null),
              delay: 300
            });
}

var SavingDialog = {
  make: BulkEditDialog$SavingDialog
};

function BulkEditDialog$DoneDialog(props) {
  var onClose = props.onClose;
  var count = props.documents.length;
  return JsxRuntime.jsx(FeedbackDelayed.make, {
              children: JsxRuntime.jsxs(Dialog, {
                    open: true,
                    children: [
                      JsxRuntime.jsx(DialogTitle, {
                            children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                      msg: {
                                        NAME: "msg",
                                        VAL: [
                                          "bulk-edit-dialog.done-title",
                                          "Bulk edit done"
                                        ]
                                      }
                                    }))
                          }),
                      JsxRuntime.jsx(DialogContent, {
                            children: Caml_option.some(JsxRuntime.jsx(DialogContentText, {
                                      children: Caml_option.some(JsxRuntime.jsx(ReactI18next.Trans, {
                                                i18nKey: "bulk-edit-dialog.done-message-with-count",
                                                children: "The data of {{count}} images was updated.",
                                                count: count
                                              }))
                                    }))
                          }),
                      JsxRuntime.jsx(DialogActions, {
                            children: Caml_option.some(JsxRuntime.jsx(Button, {
                                      children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                msg: {
                                                  NAME: "msg",
                                                  VAL: [
                                                    "buttons.close",
                                                    "Close"
                                                  ]
                                                }
                                              })),
                                      onClick: (function (param) {
                                          onClose();
                                        })
                                    }))
                          })
                    ],
                    onClose: (function (param, param$1) {
                        onClose();
                      })
                  })
            });
}

var DoneDialog = {
  make: BulkEditDialog$DoneDialog
};

function BulkEditDialog$FailedDialog(props) {
  var onClose = props.onClose;
  return JsxRuntime.jsxs(Dialog, {
              open: true,
              children: [
                JsxRuntime.jsx(DialogTitle, {
                      children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                msg: {
                                  NAME: "msg",
                                  VAL: [
                                    "bulk-edit-dialog.failure-title",
                                    "Bulk edit failed"
                                  ]
                                }
                              }))
                    }),
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsx(DialogContentText, {
                                children: Caml_option.some(JsxRuntime.jsx(ReactI18next.Trans, {
                                          i18nKey: "bulk-edit-dialog.generic-failure-message",
                                          children: "There was an error during the bulk edit operation.  This might be due network issues, or a server\n            error.  Please, try again in a few minutes."
                                        }))
                              }))
                    }),
                JsxRuntime.jsx(DialogActions, {
                      children: Caml_option.some(JsxRuntime.jsx(Button, {
                                children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                          msg: {
                                            NAME: "msg",
                                            VAL: [
                                              "buttons.close",
                                              "Close"
                                            ]
                                          }
                                        })),
                                onClick: (function (param) {
                                    onClose();
                                  })
                              }))
                    })
              ],
              fullWidth: true,
              maxWidth: "md",
              onClose: (function (param, param$1) {
                  onClose();
                })
            });
}

var FailedDialog = {
  make: BulkEditDialog$FailedDialog
};

function BulkEditDialog$Form(props) {
  var onConfirm = props.onConfirm;
  var onCancel = props.onCancel;
  var count = props.documents.length;
  var match = React.useState(function () {
        return $$default;
      });
  var setForm = match[1];
  var form = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setConfirmed = match$1[1];
  var confirmed = match$1[0];
  var toggleSelected = React.useCallback((function (attr, selected) {
          if (attr === "tags") {
            return setForm(function (f) {
                        return {
                                title: f.title,
                                author: f.author,
                                city: f.city,
                                tags: {
                                  selected: selected,
                                  mode: f.tags.mode,
                                  value: f.tags.value
                                },
                                collections: f.collections
                              };
                      });
          } else if (attr === "collections") {
            return setForm(function (f) {
                        return {
                                title: f.title,
                                author: f.author,
                                city: f.city,
                                tags: f.tags,
                                collections: {
                                  selected: selected,
                                  mode: f.collections.mode,
                                  value: f.collections.value
                                }
                              };
                      });
          } else if (attr === "author") {
            return setForm(function (f) {
                        return {
                                title: f.title,
                                author: {
                                  selected: selected,
                                  value: f.author.value
                                },
                                city: f.city,
                                tags: f.tags,
                                collections: f.collections
                              };
                      });
          } else if (attr === "title") {
            return setForm(function (f) {
                        return {
                                title: {
                                  selected: selected,
                                  value: f.title.value
                                },
                                author: f.author,
                                city: f.city,
                                tags: f.tags,
                                collections: f.collections
                              };
                      });
          } else {
            return setForm(function (f) {
                        return {
                                title: f.title,
                                author: f.author,
                                city: {
                                  selected: selected,
                                  value: f.city.value
                                },
                                tags: f.tags,
                                collections: f.collections
                              };
                      });
          }
        }), [form]);
  var authors = React.useMemo((function () {
          if (form.author.value === "") {
            return [];
          } else {
            return [AttributeMatch.make(form.author.value)];
          }
        }), [form.author.value]);
  var cities = React.useMemo((function () {
          if (form.city.value === "") {
            return [];
          } else {
            return [AttributeMatch.make(form.city.value)];
          }
        }), [form.city.value]);
  var collectionsMode = React.useMemo((function () {
          return form.collections.mode;
        }), [form.collections.mode]);
  var collections = React.useMemo((function () {
          return form.collections.value;
        }), [form.collections.value]);
  var match$2 = form.collections.selected;
  return JsxRuntime.jsxs(Dialog, {
              open: true,
              children: [
                JsxRuntime.jsx(DialogTitle, {
                      children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                msg: {
                                  NAME: "msg",
                                  VAL: [
                                    "bulk-edit-dialog.title",
                                    "Bulk edit"
                                  ]
                                }
                              }))
                    }),
                JsxRuntime.jsxs(DialogContent, {
                      children: [
                        JsxRuntime.jsx(DialogContentText, {
                              children: Caml_option.some(JsxRuntime.jsx(ReactI18next.Trans, {
                                        i18nKey: "bulk-edit-dialog.message-with-count",
                                        children: "You are about to massively update the data of {{count}}\n            images.",
                                        count: count
                                      }))
                            }),
                        JsxRuntime.jsx(DialogContentText, {
                              children: Caml_option.some(JsxRuntime.jsx(ReactI18next.Trans, {
                                        i18nKey: "bulk-edit-dialog.explanation-message",
                                        children: "You can select exactly which fields you want to update.\n             Fields which are not explicitly selected are not updated."
                                      }))
                            }),
                        JsxRuntime.jsxs(Stack, {
                              children: [
                                JsxRuntime.jsx(Typography, {
                                      variant: "h6",
                                      children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                msg: {
                                                  NAME: "msg",
                                                  VAL: [
                                                    "bulk-edit-dialog.general-section-heading",
                                                    "General"
                                                  ]
                                                }
                                              }))
                                    }),
                                JsxRuntime.jsxs(Stack, {
                                      alignItems: "center",
                                      children: [
                                        JsxRuntime.jsx(Checkbox, {
                                              checked: form.title.selected,
                                              onChange: (function (param, selected) {
                                                  toggleSelected("title", selected);
                                                })
                                            }),
                                        JsxRuntime.jsx(TextField, {
                                              disabled: !form.title.selected,
                                              fullWidth: true,
                                              helperText: Caml_option.some(JsxRuntime.jsx(BulkEditDialog$FormData$TitleHelper, {
                                                        form: form
                                                      })),
                                              label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                        msg: DocumentAttributeLabels.get("name")
                                                      })),
                                              onChange: (function (ev) {
                                                  var value = ev.target.value;
                                                  setForm(function (f) {
                                                        var match = f.title;
                                                        return {
                                                                title: {
                                                                  selected: match.selected,
                                                                  value: value
                                                                },
                                                                author: f.author,
                                                                city: f.city,
                                                                tags: f.tags,
                                                                collections: f.collections
                                                              };
                                                      });
                                                }),
                                              value: form.title.value
                                            })
                                      ],
                                      direction: ["row"],
                                      spacing: 4.0
                                    }),
                                JsxRuntime.jsxs(Stack, {
                                      alignItems: "center",
                                      children: [
                                        JsxRuntime.jsx(Checkbox, {
                                              checked: form.author.selected,
                                              onChange: (function (param, selected) {
                                                  toggleSelected("author", selected);
                                                })
                                            }),
                                        JsxRuntime.jsx(AuthorsAutocomplete.make, {
                                              authors: authors,
                                              onChange: (function (authors) {
                                                  var match = Prelude.$$Array.first(authors);
                                                  if (match === undefined) {
                                                    return setForm(function (f) {
                                                                var match = f.author;
                                                                return {
                                                                        title: f.title,
                                                                        author: {
                                                                          selected: match.selected,
                                                                          value: ""
                                                                        },
                                                                        city: f.city,
                                                                        tags: f.tags,
                                                                        collections: f.collections
                                                                      };
                                                              });
                                                  }
                                                  var value = match.value;
                                                  setForm(function (f) {
                                                        var match = f.author;
                                                        return {
                                                                title: f.title,
                                                                author: {
                                                                  selected: match.selected,
                                                                  value: value
                                                                },
                                                                city: f.city,
                                                                tags: f.tags,
                                                                collections: f.collections
                                                              };
                                                      });
                                                }),
                                              label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                        msg: DocumentAttributeLabels.get("author")
                                                      })),
                                              fullWidth: true,
                                              multiple: false,
                                              freeSolo: true,
                                              disabled: !form.author.selected,
                                              helperText: Caml_option.some(JsxRuntime.jsx(BulkEditDialog$FormData$AuthorHelper, {
                                                        form: form
                                                      }))
                                            })
                                      ],
                                      direction: ["row"],
                                      spacing: 4.0
                                    }),
                                JsxRuntime.jsxs(Stack, {
                                      alignItems: "center",
                                      children: [
                                        JsxRuntime.jsx(Checkbox, {
                                              checked: form.city.selected,
                                              onChange: (function (param, selected) {
                                                  toggleSelected("city", selected);
                                                })
                                            }),
                                        JsxRuntime.jsx(CityAutocomplete.make, {
                                              cities: cities,
                                              onChange: (function (cities) {
                                                  var match = Prelude.$$Array.first(cities);
                                                  if (match === undefined) {
                                                    return setForm(function (f) {
                                                                var match = f.city;
                                                                return {
                                                                        title: f.title,
                                                                        author: f.author,
                                                                        city: {
                                                                          selected: match.selected,
                                                                          value: ""
                                                                        },
                                                                        tags: f.tags,
                                                                        collections: f.collections
                                                                      };
                                                              });
                                                  }
                                                  var value = match.value;
                                                  setForm(function (f) {
                                                        var match = f.city;
                                                        return {
                                                                title: f.title,
                                                                author: f.author,
                                                                city: {
                                                                  selected: match.selected,
                                                                  value: value
                                                                },
                                                                tags: f.tags,
                                                                collections: f.collections
                                                              };
                                                      });
                                                }),
                                              label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                        msg: DocumentAttributeLabels.get("city")
                                                      })),
                                              fullWidth: true,
                                              multiple: false,
                                              freeSolo: true,
                                              disabled: !form.city.selected,
                                              helperText: Caml_option.some(JsxRuntime.jsx(BulkEditDialog$FormData$CityHelper, {
                                                        form: form
                                                      }))
                                            })
                                      ],
                                      direction: ["row"],
                                      spacing: 4.0
                                    }),
                                JsxRuntime.jsxs(Stack, {
                                      alignItems: "center",
                                      children: [
                                        JsxRuntime.jsx(Checkbox, {
                                              checked: form.tags.selected,
                                              onChange: (function (param, selected) {
                                                  toggleSelected("tags", selected);
                                                })
                                            }),
                                        JsxRuntime.jsx(TagsAutocomplete.make, {
                                              tags: form.tags.value,
                                              onChange: (function (value) {
                                                  setForm(function (f) {
                                                        var match = f.tags;
                                                        return {
                                                                title: f.title,
                                                                author: f.author,
                                                                city: f.city,
                                                                tags: {
                                                                  selected: match.selected,
                                                                  mode: match.mode,
                                                                  value: value
                                                                },
                                                                collections: f.collections
                                                              };
                                                      });
                                                }),
                                              label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                        msg: DocumentAttributeLabels.get("tags")
                                                      })),
                                              fullWidth: true,
                                              allowUnapproved: true,
                                              freeSolo: true,
                                              disabled: !form.tags.selected,
                                              helperText: Caml_option.some(JsxRuntime.jsx(BulkEditDialog$FormData$TagsHelper, {
                                                        form: form
                                                      }))
                                            })
                                      ],
                                      direction: ["row"],
                                      spacing: 4.0
                                    }),
                                JsxRuntime.jsxs(Stack, {
                                      alignItems: "center",
                                      children: [
                                        JsxRuntime.jsx(Typography, {
                                              variant: "h6",
                                              children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                        msg: {
                                                          NAME: "msg",
                                                          VAL: [
                                                            "bulk-edit-dialog.collection-heading",
                                                            "Collections"
                                                          ]
                                                        }
                                                      }))
                                            }),
                                        JsxRuntime.jsxs(ToggleButtonGroup, {
                                              children: [
                                                JsxRuntime.jsx(ToggleButton, {
                                                      children: Caml_option.some(JsxRuntime.jsx(PlaylistAddCheck, {})),
                                                      value: "set"
                                                    }),
                                                JsxRuntime.jsx(ToggleButton, {
                                                      children: Caml_option.some(JsxRuntime.jsx(PlaylistAdd, {})),
                                                      value: "add"
                                                    })
                                              ],
                                              disabled: !form.collections.selected,
                                              exclusive: true,
                                              onChange: (function (param, mode) {
                                                  setForm(function (f) {
                                                        var match = f.collections;
                                                        return {
                                                                title: f.title,
                                                                author: f.author,
                                                                city: f.city,
                                                                tags: f.tags,
                                                                collections: {
                                                                  selected: match.selected,
                                                                  mode: mode,
                                                                  value: match.value
                                                                }
                                                              };
                                                      });
                                                }),
                                              size: "small",
                                              value: form.collections.selected ? collectionsMode : "none"
                                            }),
                                        match$2 ? (
                                            collectionsMode === "add" ? JsxRuntime.jsx(Typography, {
                                                    variant: "body2",
                                                    children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                              msg: {
                                                                NAME: "msg",
                                                                VAL: [
                                                                  "bulk-edit-dialog.add-toggle",
                                                                  "Only add the values selected"
                                                                ]
                                                              }
                                                            }))
                                                  }) : (
                                                collectionsMode === "set" ? JsxRuntime.jsx(Typography, {
                                                        variant: "body2",
                                                        children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                                  msg: {
                                                                    NAME: "msg",
                                                                    VAL: [
                                                                      "bulk-edit-dialog.replace-toggle",
                                                                      "Replace all collections with values selected"
                                                                    ]
                                                                  }
                                                                }))
                                                      }) : null
                                              )
                                          ) : null
                                      ],
                                      direction: ["row"],
                                      spacing: 4.0
                                    }),
                                JsxRuntime.jsxs(Stack, {
                                      alignItems: "center",
                                      children: [
                                        JsxRuntime.jsx(Checkbox, {
                                              checked: form.collections.selected,
                                              onChange: (function (param, selected) {
                                                  toggleSelected("collections", selected);
                                                })
                                            }),
                                        JsxRuntime.jsx(CollectionsAutocomplete.make, {
                                              collections: collections,
                                              onChange: (function (value) {
                                                  setForm(function (f) {
                                                        var match = f.collections;
                                                        return {
                                                                title: f.title,
                                                                author: f.author,
                                                                city: f.city,
                                                                tags: f.tags,
                                                                collections: {
                                                                  selected: match.selected,
                                                                  mode: match.mode,
                                                                  value: value
                                                                }
                                                              };
                                                      });
                                                }),
                                              label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                        msg: DocumentAttributeLabels.get("collections")
                                                      })),
                                              fullWidth: true,
                                              disabled: !form.collections.selected,
                                              helperText: Caml_option.some(JsxRuntime.jsx(BulkEditDialog$FormData$CollectionsHelper, {
                                                        form: form
                                                      }))
                                            })
                                      ],
                                      direction: ["row"],
                                      spacing: 4.0
                                    })
                              ],
                              direction: ["column"],
                              spacing: 2.0
                            })
                      ]
                    }),
                JsxRuntime.jsxs(DialogActions, {
                      children: [
                        JsxRuntime.jsx(FormControlLabel, {
                              control: JsxRuntime.jsx(Switch, {
                                    checked: confirmed,
                                    onChange: (function (param, v) {
                                        setConfirmed(function (param) {
                                              return v;
                                            });
                                      })
                                  }),
                              disabled: !hasSelections(form),
                              label: JsxRuntime.jsx(ReactI18Next.Message.make, {
                                    msg: {
                                      NAME: "msg",
                                      VAL: [
                                        "bulk-edit-dialog.confirm-switch",
                                        "Confirm to proceed"
                                      ]
                                    }
                                  })
                            }),
                        JsxRuntime.jsx(Button, {
                              children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                        msg: {
                                          NAME: "msg",
                                          VAL: [
                                            "buttons.cancel",
                                            "Cancel"
                                          ]
                                        }
                                      })),
                              onClick: (function (param) {
                                  onCancel();
                                })
                            }),
                        JsxRuntime.jsx(Button, {
                              children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                        msg: {
                                          NAME: "msg",
                                          VAL: [
                                            "buttons.save",
                                            "Save"
                                          ]
                                        }
                                      })),
                              onClick: (function (param) {
                                  onConfirm(form);
                                }),
                              disabled: !confirmed
                            })
                      ]
                    })
              ],
              fullWidth: true,
              maxWidth: "lg",
              onClose: (function (param, param$1) {
                  onCancel();
                })
            });
}

var Form = {
  make: BulkEditDialog$Form
};

function BulkEditDialog(props) {
  var onCancel = props.onCancel;
  var documents = props.documents;
  var token = Prelude.default(UserContext.useAccessToken(), AuthToken.Access.$$null);
  var namespace = NamespaceContext.useNamespace();
  var beginTransaction = CurrentDocumentListManager.useBeginTransaction();
  var commit = CurrentDocumentListManager.useCommit();
  var updateDocument = CurrentDocumentListManager.useUpdateDocument();
  var match = React.useState(function () {
        return "Form";
      });
  var setState = match[1];
  var state = match[0];
  React.useEffect((function () {
          if (typeof state === "object" && state.TAG === "Saving") {
            var form = state._0;
            var operation = toOperation(documents, form);
            Prelude.thenDo(CatalogAPIEndpoint.BulkUpdateDocument.$$do(token, namespace.id, operation), (function (result) {
                    if (result.TAG === "Ok") {
                      beginTransaction();
                      Curry._2(Prelude.$$Array.forEach, result._0, (function (doc) {
                              updateDocument(doc);
                            }));
                      commit();
                      return setState(function (param) {
                                  return {
                                          TAG: "Done",
                                          _0: form
                                        };
                                });
                    }
                    console.error("BulkEditDialog", result._0);
                    setState(function (param) {
                          return "Failed";
                        });
                  }));
          }
          
        }), [
        state,
        documents
      ]);
  if (typeof state !== "object") {
    if (state === "Form") {
      return JsxRuntime.jsx(BulkEditDialog$Form, {
                  documents: documents,
                  onCancel: onCancel,
                  onConfirm: (function (f) {
                      setState(function (param) {
                            return {
                                    TAG: "Saving",
                                    _0: f
                                  };
                          });
                    })
                });
    } else {
      return JsxRuntime.jsx(BulkEditDialog$FailedDialog, {
                  onClose: onCancel
                });
    }
  } else if (state.TAG === "Saving") {
    return JsxRuntime.jsx(BulkEditDialog$SavingDialog, {
                documents: documents,
                form: state._0
              });
  } else {
    return JsxRuntime.jsx(BulkEditDialog$DoneDialog, {
                documents: documents,
                onClose: props.onDone
              });
  }
}

var make = BulkEditDialog;

export {
  $$FormData ,
  SavingDialog ,
  DoneDialog ,
  FailedDialog ,
  Form ,
  make ,
}
/* react Not a pure module */
