// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as TagsManager from "./TagsManager.js";
import * as UserContext from "../../../global/context/UserContext.js";
import * as UserPermission from "../../../global/models/UserPermission.js";
import * as TagsListService from "./TagsListService.js";
import * as JsxRuntime from "react/jsx-runtime";

var context = React.createContext(undefined);

var make = context.Provider;

function useRemoveTag() {
  return React.useContext(context);
}

var canManageTags = UserPermission.Query.compile({
      TAG: "All",
      _0: ["tagging.change_tag"]
    });

function RemoveTagService(props) {
  var remove = TagsManager.useRemoveTag();
  var refresh = TagsListService.useRefresh();
  var perms = UserContext.useUserPermissionsClaim();
  var allowed = React.useMemo((function () {
          return canManageTags(perms);
        }), [perms]);
  var value = React.useMemo((function () {
          if (allowed) {
            return Curry._2(Prelude.OptionExported.$$Option.map, remove, (function (rm) {
                          return function (tag) {
                            return rm(tag).finally(function () {
                                        refresh();
                                      });
                          };
                        }));
          }
          
        }), [
        remove,
        allowed
      ]);
  return JsxRuntime.jsx(make, {
              value: value,
              children: props.children
            });
}

var make$1 = RemoveTagService;

export {
  useRemoveTag ,
  make$1 as make,
}
/* context Not a pure module */
