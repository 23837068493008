// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Exifr from "../../../../libs/Exifr.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as PendingUploadId from "./PendingUploadId.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";

var _fileExtensionRegex = /\.[^\.]*$/;

function fromFile(folder, file) {
  var name = file.name;
  var name$1 = name.replace(_fileExtensionRegex, "");
  return {
          id: PendingUploadId.make(),
          folder: folder,
          name: name$1,
          file: file,
          created_at: new Date(),
          status: "queued"
        };
}

function failed(upload) {
  var match = upload.status;
  return match === "failed";
}

function pending(upload) {
  var match = upload.status;
  return !(match === "uploaded" || match === "failed");
}

function retry(upload) {
  var newrecord = Caml_obj.obj_dup(upload);
  newrecord.status = "queued";
  return newrecord;
}

function uploadSize(upload) {
  return upload.file.size * 1.10;
}

async function extract(upload) {
  var match = upload.metadata;
  if (match !== undefined) {
    return upload;
  }
  var metadata;
  try {
    metadata = await Exifr.Parser.parse({
          TAG: "File",
          _0: upload.file
        });
  }
  catch (raw_error){
    var error = Caml_js_exceptions.internalToOCamlException(raw_error);
    console.error("PendingUpload", "could not extract metadata", error);
    return upload;
  }
  if (metadata === undefined) {
    return upload;
  }
  var newrecord = Caml_obj.obj_dup(upload);
  newrecord.metadata = metadata;
  return newrecord;
}

function getCity(upload) {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, upload.metadata, (function (metadata) {
                return Prelude.$$Array.first(Prelude.$$Array.keepSome([
                                Curry._2(Prelude.OptionExported.$$Option.flatMap, metadata.photoshop, (function (m) {
                                        return m.City;
                                      })),
                                Curry._2(Prelude.OptionExported.$$Option.flatMap, metadata.iptc, (function (m) {
                                        return m.City;
                                      }))
                              ]));
              }));
}

function getAuthor(upload) {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, upload.metadata, (function (metadata) {
                return Prelude.$$Array.first(Prelude.$$Array.keepSome([
                                Curry._2(Prelude.OptionExported.$$Option.flatMap, metadata.ifd0, (function (m) {
                                        return m.Artist;
                                      })),
                                Curry._2(Prelude.OptionExported.$$Option.flatMap, metadata.dc, (function (m) {
                                        return m.creator;
                                      })),
                                Curry._2(Prelude.OptionExported.$$Option.flatMap, metadata.iptc, (function (m) {
                                        return m.Byline;
                                      }))
                              ]));
              }));
}

function getDescription(upload) {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, upload.metadata, (function (metadata) {
                return Prelude.$$Array.first(Prelude.$$Array.keepSome([
                                Curry._2(Prelude.OptionExported.$$Option.flatMap, metadata.ifd0, (function (m) {
                                        return m.ImageDescription;
                                      })),
                                Curry._2(Prelude.OptionExported.$$Option.flatMap, metadata.dc, (function (m) {
                                        return Curry._2(Prelude.OptionExported.$$Option.flatMap, m.description, (function (d) {
                                                      return d.value;
                                                    }));
                                      })),
                                Curry._2(Prelude.OptionExported.$$Option.flatMap, metadata.iptc, (function (m) {
                                        return m.Caption;
                                      }))
                              ]));
              }));
}

function getLatitude(upload) {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, upload.metadata, (function (metadata) {
                return Curry._2(Prelude.OptionExported.$$Option.flatMap, metadata.gps, (function (m) {
                              return m.latitude;
                            }));
              }));
}

function getLongitude(upload) {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, upload.metadata, (function (metadata) {
                return Curry._2(Prelude.OptionExported.$$Option.flatMap, metadata.gps, (function (m) {
                              return m.longitude;
                            }));
              }));
}

function getCreatedAt(upload) {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, upload.metadata, (function (metadata) {
                return Prelude.$$Array.first(Prelude.$$Array.keepSome([
                                Curry._2(Prelude.OptionExported.$$Option.flatMap, metadata.exif, (function (m) {
                                        var match = m.DateTimeOriginal;
                                        var match$1 = m.OffsetTimeOriginal;
                                        if (match !== undefined) {
                                          if (match$1 !== undefined) {
                                            return match + " " + match$1;
                                          } else {
                                            return match;
                                          }
                                        }
                                        
                                      })),
                                Curry._2(Prelude.OptionExported.$$Option.flatMap, metadata.exif, (function (m) {
                                        var match = m.CreateDate;
                                        var match$1 = m.OffsetTime;
                                        if (match !== undefined) {
                                          if (match$1 !== undefined) {
                                            return match + " " + match$1;
                                          } else {
                                            return match;
                                          }
                                        }
                                        
                                      })),
                                Curry._2(Prelude.OptionExported.$$Option.flatMap, metadata.xmp, (function (m) {
                                        return m.CreateDate;
                                      })),
                                Curry._2(Prelude.OptionExported.$$Option.flatMap, metadata.photoshop, (function (m) {
                                        return m.DateCreated;
                                      })),
                                Curry._2(Prelude.OptionExported.$$Option.flatMap, metadata.iptc, (function (m) {
                                        var match = m.DateCreated;
                                        var match$1 = m.TimeCreated;
                                        if (match !== undefined && typeof match === "string") {
                                          if (match$1 !== undefined) {
                                            if (typeof match$1 === "string") {
                                              return match + " " + match$1;
                                            } else {
                                              return ;
                                            }
                                          } else {
                                            return match;
                                          }
                                        }
                                        
                                      })),
                                new Date().toISOString()
                              ]));
              }));
}

function getUpdatedAt(upload) {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, upload.metadata, (function (metadata) {
                return Prelude.$$Array.first(Prelude.$$Array.keepSome([
                                Curry._2(Prelude.OptionExported.$$Option.flatMap, metadata.ifd0, (function (m) {
                                        return m.ModifyDate;
                                      })),
                                Curry._2(Prelude.OptionExported.$$Option.flatMap, metadata.xmp, (function (m) {
                                        return m.ModifyDate;
                                      }))
                              ]));
              }));
}

function getWidth(upload) {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, upload.metadata, (function (metadata) {
                return Prelude.$$Array.first(Prelude.$$Array.keepSome([
                                Curry._2(Prelude.OptionExported.$$Option.flatMap, metadata.ifd0, (function (m) {
                                        return m.ImageWidth;
                                      })),
                                Curry._2(Prelude.OptionExported.$$Option.flatMap, metadata.exif, (function (m) {
                                        return m.ExifImageWidth;
                                      }))
                              ]));
              }));
}

function getHeight(upload) {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, upload.metadata, (function (metadata) {
                return Prelude.$$Array.first(Prelude.$$Array.keepSome([
                                Curry._2(Prelude.OptionExported.$$Option.flatMap, metadata.ifd0, (function (m) {
                                        return m.ImageHeight;
                                      })),
                                Curry._2(Prelude.OptionExported.$$Option.flatMap, metadata.exif, (function (m) {
                                        return m.ExifImageHeight;
                                      }))
                              ]));
              }));
}

function getOrientation(upload) {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, upload.metadata, (function (metadata) {
                return Curry._2(Prelude.OptionExported.$$Option.flatMap, metadata.ifd0, (function (m) {
                              return m.Orientation;
                            }));
              }));
}

function getTags(upload) {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, upload.metadata, (function (metadata) {
                return Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.first(Prelude.$$Array.keepSome([
                                    Curry._2(Prelude.OptionExported.$$Option.flatMap, metadata.dc, (function (m) {
                                            return m.subject;
                                          })),
                                    Curry._2(Prelude.OptionExported.$$Option.flatMap, metadata.iptc, (function (m) {
                                            return m.Keywords;
                                          }))
                                  ])), (function (value) {
                              if (typeof value === "string") {
                                return [value];
                              } else {
                                return value;
                              }
                            }));
              }));
}

var Metadata = {
  extract: extract,
  getCity: getCity,
  getAuthor: getAuthor,
  getDescription: getDescription,
  getLatitude: getLatitude,
  getLongitude: getLongitude,
  getCreatedAt: getCreatedAt,
  getUpdatedAt: getUpdatedAt,
  getWidth: getWidth,
  getHeight: getHeight,
  getOrientation: getOrientation,
  getTags: getTags
};

var Identifier;

export {
  Identifier ,
  _fileExtensionRegex ,
  fromFile ,
  failed ,
  pending ,
  retry ,
  uploadSize ,
  Metadata ,
}
/* Exifr Not a pure module */
