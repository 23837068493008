// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as FolderId from "../../models/FolderId.js";
import * as UserContext from "../../../../global/context/UserContext.js";
import * as SharedDBContext from "../../../../global/context/SharedDBContext.js";
import * as NamespaceContext from "../../../../global/context/NamespaceContext.js";
import * as SelectionManager from "../SelectionManager.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CatalogAPIEndpoint from "../../api/CatalogAPIEndpoint.js";
import * as CurrentFolderManager from "../CurrentFolderManager.js";

var context = React.createContext(undefined);

var make = context.Provider;

function useTrash() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.trash;
              }));
}

function useRemove() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.remove;
              }));
}

function RemoveFolderService(props) {
  CurrentFolderManager.ensureCurrentFolderManager();
  var namespace = NamespaceContext.useNamespace();
  var updateFolder = CurrentFolderManager.useUpdateOrAddFolder();
  var removeFolder = CurrentFolderManager.useRemoveSubFolder();
  var unselectFolders = SelectionManager.Folders.useUnselect();
  var unselectDocuments = SelectionManager.Documents.useUnselect();
  var token = Curry._1(Prelude.OptionExported.$$Option.getExn, UserContext.useAccessToken());
  var match = SharedDBContext.useQuery();
  var write = match.write;
  var read = match.read;
  var makeWrite = match.makeWrite;
  var makeRead = match.makeRead;
  var remove = React.useMemo((function () {
          return async function (folder) {
            var originalFolderPath = folder.path;
            var f = await CatalogAPIEndpoint.RemoveFolder.$$do(folder, token);
            var result;
            if (f.TAG === "Ok") {
              var f$1 = f._0;
              removeFolder(f$1);
              Prelude.OptionExported.$$Option.$$do(unselectFolders, (function (fn) {
                      fn([f$1]);
                    }));
              result = {
                TAG: "Ok",
                _0: f$1
              };
            } else {
              result = {
                TAG: "Error",
                _0: f._0[1]
              };
            }
            if (result.TAG !== "Ok") {
              return {
                      TAG: "Error",
                      _0: result._0
                    };
            }
            var folder$1 = result._0;
            var init = makeRead();
            var match = await read({
                  documents: init.documents,
                  folders: {
                    TAG: "StartsWith",
                    _0: "path",
                    _1: originalFolderPath
                  },
                  bundlers: init.bundlers
                });
            var children = match.folders;
            var init$1 = makeRead();
            var match$1 = await read({
                  documents: {
                    TAG: "Or",
                    _0: {
                      TAG: "Is",
                      _0: "folder",
                      _1: FolderId.toString(folder$1.id)
                    },
                    _1: {
                      TAG: "AnyOf",
                      _0: "folder",
                      _1: FolderId.manyToString(children.map(function (f) {
                                return f.id;
                              }))
                    }
                  },
                  folders: init$1.folders,
                  bundlers: init$1.bundlers
                });
            var documents = match$1.documents;
            Prelude.OptionExported.$$Option.$$do(unselectFolders, (function (fn) {
                    fn(children);
                  }));
            Prelude.OptionExported.$$Option.$$do(unselectDocuments, (function (fn) {
                    fn(documents);
                  }));
            var init$2 = makeWrite();
            await write({
                  documents: documents.map(function (d) {
                        return {
                                TAG: "Delete",
                                _0: d.id
                              };
                      }),
                  folders: Curry._2(Prelude.$$Array.concat, [{
                          TAG: "Delete",
                          _0: folder$1.id
                        }], children.map(function (param) {
                            return {
                                    TAG: "Delete",
                                    _0: param.id
                                  };
                          })),
                  bundlers: init$2.bundlers
                });
            return {
                    TAG: "Ok",
                    _0: undefined
                  };
          };
        }), [
        token,
        makeWrite,
        write,
        makeRead,
        read,
        unselectFolders,
        unselectDocuments
      ]);
  var trash = React.useMemo((function () {
          return async function (folder) {
            var originalFolderPath = folder.path;
            var e = await CatalogAPIEndpoint.UpdateFolder.move(folder, namespace.trash_folder_id, token);
            var result;
            if (e.TAG === "Ok") {
              var f = e._0;
              if (f !== undefined) {
                updateFolder(f);
                Prelude.OptionExported.$$Option.$$do(unselectFolders, (function (fn) {
                        fn([f]);
                      }));
                result = {
                  TAG: "Ok",
                  _0: f
                };
              } else {
                result = {
                  TAG: "Ok",
                  _0: folder
                };
              }
            } else {
              result = {
                TAG: "Error",
                _0: e._0
              };
            }
            if (result.TAG !== "Ok") {
              return {
                      TAG: "Error",
                      _0: result._0
                    };
            }
            var folder$1 = result._0;
            if (folder$1.path === originalFolderPath) {
              console.error("RemoveFolderService", "We're getting the same path as before!!!  This is completely unexpected!!!");
            }
            var init = makeRead();
            var match = await read({
                  documents: init.documents,
                  folders: {
                    TAG: "StartsWith",
                    _0: "path",
                    _1: originalFolderPath
                  },
                  bundlers: init.bundlers
                });
            var children = match.folders;
            var init$1 = makeRead();
            var match$1 = await read({
                  documents: {
                    TAG: "Or",
                    _0: {
                      TAG: "Is",
                      _0: "folder",
                      _1: FolderId.toString(folder$1.id)
                    },
                    _1: {
                      TAG: "AnyOf",
                      _0: "folder",
                      _1: FolderId.manyToString(children.map(function (f) {
                                return f.id;
                              }))
                    }
                  },
                  folders: init$1.folders,
                  bundlers: init$1.bundlers
                });
            var documents = match$1.documents;
            Prelude.OptionExported.$$Option.$$do(unselectFolders, (function (fn) {
                    fn(children);
                  }));
            Prelude.OptionExported.$$Option.$$do(unselectDocuments, (function (fn) {
                    fn(documents);
                  }));
            var init$2 = makeWrite();
            await write({
                  documents: documents.map(function (d) {
                        return {
                                TAG: "Delete",
                                _0: d.id
                              };
                      }),
                  folders: Curry._2(Prelude.$$Array.concat, [{
                          TAG: "Save",
                          _0: folder$1
                        }], children.map(function (param) {
                            return {
                                    TAG: "Delete",
                                    _0: param.id
                                  };
                          })),
                  bundlers: init$2.bundlers
                });
            return {
                    TAG: "Ok",
                    _0: folder$1
                  };
          };
        }), [
        token,
        makeWrite,
        write,
        makeRead,
        read,
        unselectDocuments,
        unselectFolders
      ]);
  return JsxRuntime.jsx(make, {
              value: {
                remove: remove,
                trash: trash
              },
              children: props.children
            });
}

var make$1 = RemoveFolderService;

export {
  useRemove ,
  useTrash ,
  make$1 as make,
}
/* context Not a pure module */
