// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../../libs/i18n/ReactI18Next.js";
import * as JsxRuntime from "react/jsx-runtime";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import * as CatalogBrowserViewContext from "../context/CatalogBrowserViewContext.js";
import GridView from "@mui/icons-material/GridView";
import ViewList from "@mui/icons-material/ViewList";

function FolderBrowserViewSwitcher(props) {
  var view = CatalogBrowserViewContext.useCurrentView();
  var switchView = CatalogBrowserViewContext.useCurrentViewSwitcher();
  var icon = React.useMemo((function () {
          if (view === "icons") {
            return JsxRuntime.jsx(ViewList, {
                        fontSize: "medium"
                      });
          } else {
            return JsxRuntime.jsx(GridView, {
                        fontSize: "medium"
                      });
          }
        }), [view]);
  var title = view === "icons" ? JsxRuntime.jsx(ReactI18Next.Message.make, {
          msg: {
            NAME: "msg",
            VAL: [
              "catalog.browser.switch-to-list",
              "Switch to list view"
            ]
          }
        }) : JsxRuntime.jsx(ReactI18Next.Message.make, {
          msg: {
            NAME: "msg",
            VAL: [
              "catalog.browser.switch-to-icons",
              "Switch to icons view"
            ]
          }
        });
  return JsxRuntime.jsx(Tooltip, {
              children: JsxRuntime.jsx(IconButton, {
                    children: Caml_option.some(icon),
                    onClick: (function (param) {
                        switchView();
                      }),
                    size: "large"
                  }),
              arrow: true,
              title: title
            });
}

var make = FolderBrowserViewSwitcher;

export {
  make ,
}
/* react Not a pure module */
