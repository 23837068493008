// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as APIMaker from "../../../utils/APIMaker.js";
import * as Collection from "../models/Collection.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CollectionMatch from "../../search/models/CollectionMatch.js";

var SearchInAppSearch = APIMaker.MakePaginatedEndpoint({
      url: "search/meta/collections/:namespace_id",
      Deserializer: CollectionMatch.Deserializer
    });

var SearchInAppCollection = APIMaker.MakePaginatedEndpoint({
      url: "collections/:namespace_id",
      Deserializer: Collection.Deserializer
    });

var fields = {
  TAG: "Object",
  _0: [[
      "result",
      {
        TAG: "Collection",
        _0: Collection.Deserializer
      }
    ]]
};

var Deserializer = $$JSON.MakeDeserializer({
      fields: fields
    });

var Impl = APIMaker.MakeBackendAPIEndpoint({
      url: "collections/:namespace_id",
      Deserializer: Deserializer
    });

function $$do(name, namespace_id, token) {
  var operation = {
    operation: {
      kind: "create-collection",
      name: name
    }
  };
  return Prelude.PromisedResult.flatMap(Prelude.PromisedResult.mapError(Impl.postOne({
                      namespace_id: namespace_id
                    }, Caml_option.some(token), operation), (function (param) {
                    
                  })), (function (res) {
                var collection = Curry._2(Prelude.OptionExported.$$Option.flatMap, res, (function (param) {
                        return Prelude.$$Array.first(param.result);
                      }));
                if (collection !== undefined) {
                  return {
                          TAG: "Ok",
                          _0: collection
                        };
                } else {
                  return {
                          TAG: "Error",
                          _0: undefined
                        };
                }
              }));
}

var CreateCollection = {
  Impl: Impl,
  $$do: $$do
};

var fields$1 = {
  TAG: "Object",
  _0: [[
      "result",
      {
        TAG: "Collection",
        _0: Collection.Deserializer
      }
    ]]
};

var Deserializer$1 = $$JSON.MakeDeserializer({
      fields: fields$1
    });

var Impl$1 = APIMaker.MakeBackendAPIEndpoint({
      url: "collections/:namespace_id",
      Deserializer: Deserializer$1
    });

function $$do$1(collection, namespace_id, token) {
  var operation = {
    operation: {
      kind: "update-collection",
      id: collection.id,
      name: collection.name
    }
  };
  return Prelude.PromisedResult.flatMap(Prelude.PromisedResult.mapError(Impl$1.postOne({
                      namespace_id: namespace_id
                    }, Caml_option.some(token), operation), (function (param) {
                    
                  })), (function (res) {
                var collection = Curry._2(Prelude.OptionExported.$$Option.flatMap, res, (function (param) {
                        return Prelude.$$Array.first(param.result);
                      }));
                if (collection !== undefined) {
                  return {
                          TAG: "Ok",
                          _0: collection
                        };
                } else {
                  return {
                          TAG: "Error",
                          _0: undefined
                        };
                }
              }));
}

var UpdateCollection = {
  Impl: Impl$1,
  $$do: $$do$1
};

var fields$2 = {
  TAG: "Object",
  _0: [[
      "result",
      {
        TAG: "Collection",
        _0: Collection.Deserializer
      }
    ]]
};

var Deserializer$2 = $$JSON.MakeDeserializer({
      fields: fields$2
    });

var Impl$2 = APIMaker.MakeBackendAPIEndpoint({
      url: "/collections/:namespace_id",
      Deserializer: Deserializer$2
    });

function $$do$2(collection, namespace_id, token) {
  var operation = {
    operation: {
      kind: "remove-collection",
      id: collection.id
    }
  };
  return Prelude.PromisedResult.mapError(Prelude.PromisedResult.map(Impl$2.postOne({
                      namespace_id: namespace_id
                    }, Caml_option.some(token), operation), (function (param) {
                    
                  })), (function (param) {
                
              }));
}

var RemoveCollection = {
  Impl: Impl$2,
  $$do: $$do$2
};

var fields$3 = {
  TAG: "Object",
  _0: [[
      "result",
      {
        TAG: "Collection",
        _0: Collection.Deserializer
      }
    ]]
};

var Deserializer$3 = $$JSON.MakeDeserializer({
      fields: fields$3
    });

var Impl$3 = APIMaker.MakeBackendAPIEndpoint({
      url: "collections/:namespace_id",
      Deserializer: Deserializer$3
    });

function $$do$3(collections, namespace_id, token) {
  var ids = collections.map(function (param) {
        return param.id;
      });
  var operation = {
    operation: {
      kind: "bulk-remove-collections",
      ids: ids
    }
  };
  return Prelude.PromisedResult.mapError(Prelude.PromisedResult.map(Impl$3.postOne({
                      namespace_id: namespace_id
                    }, Caml_option.some(token), operation), (function (param) {
                    
                  })), (function (param) {
                
              }));
}

var BulkRemoveCollections = {
  Impl: Impl$3,
  $$do: $$do$3
};

export {
  SearchInAppSearch ,
  SearchInAppCollection ,
  CreateCollection ,
  UpdateCollection ,
  RemoveCollection ,
  BulkRemoveCollections ,
}
/* SearchInAppSearch Not a pure module */
