// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UserContext from "../../../global/context/UserContext.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as AccountsAPIEndpoint from "../api/AccountsAPIEndpoint.js";

var NewAccount = {};

var ExistingAccount = {};

var context = React.createContext(undefined);

var make = context.Provider;

function useCreateAccount() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.create;
              }));
}

function useUpdateAccount() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.update;
              }));
}

function useToggleActivation() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.toggleActivation;
              }));
}

function useRemoveAccount() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.remove;
              }));
}

function useSearchAccounts() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.search;
              }));
}

function useBulkDeactivateAccounts() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.bulkDeactivate;
              }));
}

function useBulkActivateAccounts() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.bulkActivate;
              }));
}

function useCanIDeactivate() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.canIDeactivate;
              }));
}

function AccountsManager(props) {
  var token = UserContext.useAccessToken();
  var user = UserContext.useUserClaim();
  var value = React.useMemo((function () {
          if (token === undefined) {
            return ;
          }
          if (user === undefined) {
            return ;
          }
          var token$1 = Caml_option.valFromOption(token);
          var search = function (params, page, pageSize) {
            return AccountsAPIEndpoint.Search.$$fetch(token$1, params, page, pageSize, undefined);
          };
          var create = function (account) {
            var username = account.username;
            var password = account.password;
            var first_name = account.first_name;
            var last_name = account.last_name;
            var email = account.email;
            var groups = account.groups;
            return AccountsAPIEndpoint.CreateAccount.$$do(username, password, first_name, last_name, email, groups, token$1);
          };
          var update = function (account) {
            var id = account.id;
            var first_name = account.first_name;
            var last_name = account.last_name;
            var email = account.email;
            var groups = account.groups;
            var is_active = Caml_obj.equal(account.is_active, false) && !(user.is_superuser || !account.is_superuser) ? undefined : account.is_active;
            var new_password = account.new_password;
            return AccountsAPIEndpoint.UpdateAccount.$$do(id, first_name, last_name, email, new_password, is_active, groups, token$1);
          };
          var toggleActivation = function (account) {
            return AccountsAPIEndpoint.UpdateAccount.toggleActivation(account, token$1);
          };
          var remove = function (account) {
            return AccountsAPIEndpoint.RemoveAccount.$$do(account, token$1);
          };
          var bulkDeactivate = function (accounts) {
            var accounts$1 = user.is_superuser ? accounts : Curry._2(Prelude.$$Array.keep, accounts, (function (a) {
                      return !a.is_superuser;
                    }));
            return Prelude.PromisedResult.map(AccountsAPIEndpoint.Bulk.deactivate(accounts$1, token$1), (function (param) {
                          
                        }));
          };
          var bulkActivate = function (accounts) {
            return Prelude.PromisedResult.map(AccountsAPIEndpoint.Bulk.activate(accounts, token$1), (function (param) {
                          
                        }));
          };
          var canIDeactivate = function (account) {
            if (user.is_superuser) {
              return true;
            } else {
              return !account.is_superuser;
            }
          };
          return {
                  search: search,
                  canIDeactivate: canIDeactivate,
                  create: create,
                  remove: remove,
                  update: update,
                  toggleActivation: toggleActivation,
                  bulkDeactivate: bulkDeactivate,
                  bulkActivate: bulkActivate
                };
        }), [
        token,
        user
      ]);
  return JsxRuntime.jsx(make, {
              value: value,
              children: props.children
            });
}

var make$1 = AccountsManager;

export {
  NewAccount ,
  ExistingAccount ,
  useCreateAccount ,
  useToggleActivation ,
  useUpdateAccount ,
  useRemoveAccount ,
  useSearchAccounts ,
  useBulkDeactivateAccounts ,
  useBulkActivateAccounts ,
  useCanIDeactivate ,
  make$1 as make,
}
/* context Not a pure module */
