// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as GroupId from "./GroupId.js";

function make(name) {
  return {
          id: GroupId.$$null,
          name: name
        };
}

var fields = {
  TAG: "Object",
  _0: [
    [
      "id",
      "Int"
    ],
    [
      "name",
      "String"
    ]
  ]
};

var Deserializer = $$JSON.MakeDeserializer({
      fields: fields
    });

function clone(f) {
  return Object.assign({}, f);
}

var Filters = {
  clone: clone
};

var Identifier;

export {
  Identifier ,
  make ,
  Deserializer ,
  Filters ,
}
/* Deserializer Not a pure module */
