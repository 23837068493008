// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as AuthManager from "../../context/AuthManager.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as MantineForms from "../../../../libs/Mantine/MantineForms.js";
import * as ReactI18Next from "../../../../libs/i18n/ReactI18Next.js";
import * as Form from "@mantine/form";
import * as PasswordInput from "../PasswordInput.js";
import * as CenterBasePage from "./CenterBasePage.js";
import * as FlexGrowSpacer from "../FlexGrowSpacer.js";
import * as JsxRuntime from "react/jsx-runtime";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

MantineForms.MakeForm({});

function LoginPage(props) {
  var msg = props.msg;
  var login = AuthManager.useLogin();
  var form = Form.useForm({
        initialValues: {
          username: "",
          password: ""
        }
      });
  var usernameProps = form.getInputProps("username");
  var passwordProps = form.getInputProps("password");
  var match = React.useState(function () {
        return false;
      });
  var setBusy = match[1];
  var doLogin = React.useCallback((function () {
          setBusy(function (param) {
                return true;
              });
          Prelude.thenDo(login(form.values), (function () {
                  setBusy(function (param) {
                        return false;
                      });
                }));
        }), [form]);
  return JsxRuntime.jsx(CenterBasePage.make, {
              title: {
                NAME: "msg",
                VAL: [
                  "login.title",
                  "Log in"
                ]
              },
              children: JsxRuntime.jsx("form", {
                    children: JsxRuntime.jsxs(Stack, {
                          children: [
                            msg !== undefined ? JsxRuntime.jsx(Typography, {
                                    children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                              msg: msg
                                            })),
                                    sx: {
                                      textAlign: "center"
                                    }
                                  }) : null,
                            JsxRuntime.jsx(TextField, {
                                  autoFocus: usernameProps.value.trim() === "",
                                  label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                            msg: {
                                              NAME: "str",
                                              VAL: "Username"
                                            }
                                          })),
                                  name: "username",
                                  onChange: usernameProps.onChange,
                                  required: true,
                                  sx: {
                                    width: "100%"
                                  },
                                  value: usernameProps.value
                                }),
                            JsxRuntime.jsx(PasswordInput.make, {
                                  value: passwordProps.value,
                                  onChange: passwordProps.onChange,
                                  label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                            msg: {
                                              NAME: "str",
                                              VAL: "Password"
                                            }
                                          })),
                                  sx: {
                                    width: "100%"
                                  },
                                  name: "password",
                                  autoFocus: usernameProps.value.trim() !== "" && passwordProps.value.trim() === "",
                                  required: true
                                }),
                            JsxRuntime.jsxs(Stack, {
                                  children: [
                                    JsxRuntime.jsx(FlexGrowSpacer.make, {}),
                                    JsxRuntime.jsx(Button, {
                                          children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                    msg: {
                                                      NAME: "msg",
                                                      VAL: [
                                                        "login.button",
                                                        "Log in"
                                                      ]
                                                    }
                                                  })),
                                          onClick: (function (param) {
                                              doLogin();
                                            }),
                                          disabled: match[0] || usernameProps.value.trim() === "" || passwordProps.value.trim() === ""
                                        })
                                  ],
                                  direction: ["row"]
                                })
                          ],
                          direction: ["column"],
                          spacing: "1rem",
                          sx: {
                            mx: "1rem",
                            my: "2rem"
                          }
                        }),
                    onKeyPress: (function (e) {
                        var match = e.key;
                        if (match === "Enter") {
                          return doLogin();
                        }
                        
                      }),
                    onSubmit: (function (e) {
                        e.preventDefault();
                        doLogin();
                      })
                  })
            });
}

var make = LoginPage;

export {
  make ,
}
/*  Not a pure module */
