// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Account from "../models/Account.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../../libs/i18n/ReactI18Next.js";
import * as AccountDropdown from "./AccountDropdown.js";
import * as AccountsManager from "../context/AccountsManager.js";
import * as JsxRuntime from "react/jsx-runtime";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import * as AccountsListService from "../context/AccountsListService.js";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import * as AccountsFiltersContext from "../context/AccountsFiltersContext.js";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TextField from "@mui/material/TextField";
import * as ToggleActivationService from "../context/ToggleActivationService.js";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import Typography from "@mui/material/Typography";
import * as AccountsSelectionManager from "../context/AccountsSelectionManager.js";
import * as WithAccountsLassoSelector from "./WithAccountsLassoSelector.js";
import PaginationItem from "@mui/material/PaginationItem";
import TableContainer from "@mui/material/TableContainer";
import ArrowUpwardRounded from "@mui/icons-material/ArrowUpwardRounded";
import AccountCircleRounded from "@mui/icons-material/AccountCircleRounded";
import ArrowDownwardRounded from "@mui/icons-material/ArrowDownwardRounded";

var letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

function AccountsBrowser$AccountsFilters$Letters(props) {
  var active = Prelude.default(props.filters.startswith, "");
  var setCurrentPage = AccountsListService.useSetCurrentPage();
  var setStartsWith = AccountsFiltersContext.useSetStartsWith();
  var removeStartsWith = AccountsFiltersContext.useRemoveStartsWith();
  return JsxRuntime.jsx(Stack, {
              children: Caml_option.some(letters.map(function (letter) {
                        return JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx(PaginationItem, {
                                          page: Caml_option.some(letter),
                                          selected: letter === active,
                                          size: "small"
                                        }),
                                    onClick: (function (param) {
                                        if (letter !== active) {
                                          setCurrentPage(1);
                                          return setStartsWith(letter);
                                        } else {
                                          setCurrentPage(1);
                                          return removeStartsWith();
                                        }
                                      })
                                  }, letter);
                      })),
              direction: ["row"],
              spacing: 0.5,
              sx: {
                flexWrap: "wrap"
              },
              useFlexGap: true
            });
}

function AccountsBrowser$AccountsFilters$Filter(props) {
  var contains = Prelude.default(props.filters.contains, "");
  var setCurrentPage = AccountsListService.useSetCurrentPage();
  var setContains = AccountsFiltersContext.useSetContains();
  var removeContains = AccountsFiltersContext.useRemoveContains();
  return JsxRuntime.jsx(TextField, {
              fullWidth: true,
              label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                        msg: {
                          NAME: "str",
                          VAL: "Filter by"
                        }
                      })),
              onChange: (function (ev) {
                  var target = ev.target;
                  var value = target.value;
                  if (value === "") {
                    if (contains !== "") {
                      setCurrentPage(1);
                      return removeContains();
                    } else {
                      return ;
                    }
                  } else if (contains !== value) {
                    setCurrentPage(1);
                    return setContains(value);
                  } else {
                    return ;
                  }
                }),
              value: contains,
              variant: "standard"
            });
}

function AccountsBrowser$AccountsFilters(props) {
  var filters = props.filters;
  return JsxRuntime.jsxs(Grid, {
              children: [
                JsxRuntime.jsx(Grid, {
                      children: Caml_option.some(JsxRuntime.jsx(AccountsBrowser$AccountsFilters$Filter, {
                                filters: filters
                              })),
                      item: true,
                      xs: 3
                    }),
                JsxRuntime.jsx(Grid, {
                      children: Caml_option.some(JsxRuntime.jsx(AccountsBrowser$AccountsFilters$Letters, {
                                filters: filters
                              })),
                      item: true,
                      xs: 9
                    })
              ],
              container: true,
              sx: {
                alignItems: "center"
              }
            });
}

function AccountsBrowser$AccountsTable$AccountRow(props) {
  var account = props.account;
  var isSelected = Curry._1(Prelude.OptionExported.$$Option.getExn, AccountsSelectionManager.useIsSelected());
  var selected = React.useMemo((function () {
          return isSelected(account);
        }), [
        account,
        isSelected
      ]);
  var toggle = Curry._1(Prelude.OptionExported.$$Option.getExn, AccountsSelectionManager.useToggle());
  var toggleActivation = ToggleActivationService.useToggleActivation();
  var canIDeactivate = Prelude.default(AccountsManager.useCanIDeactivate(), (function (param) {
          return false;
        }));
  var disabledToggleActivation = Curry._1(Prelude.OptionExported.$$Option.isNone, toggleActivation) || account.is_active && !canIDeactivate(account);
  return React.useMemo((function () {
                return JsxRuntime.jsxs(TableRow, {
                            className: "selectable-item",
                            style: {
                              cursor: "default",
                              userSelect: "none"
                            },
                            id: String(account.id),
                            children: [
                              JsxRuntime.jsx(TableCell, {
                                    children: Caml_option.some(JsxRuntime.jsx(Checkbox, {
                                              checked: selected,
                                              onChange: (function (param, param$1) {
                                                  toggle([account]);
                                                }),
                                              size: "small"
                                            }))
                                  }),
                              JsxRuntime.jsx(TableCell, {
                                    children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                              alignItems: "center",
                                              children: [
                                                JsxRuntime.jsx(IconButton, {
                                                      children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                                                children: JsxRuntime.jsx(AccountCircleRounded, {
                                                                      color: disabledToggleActivation ? "default" : (
                                                                          account.is_active ? "success" : "warning"
                                                                        )
                                                                    }),
                                                                title: JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                                      msg: {
                                                                        NAME: "msg",
                                                                        VAL: [
                                                                          "accounts.toggle-activation-status",
                                                                          "Toggle activation"
                                                                        ]
                                                                      }
                                                                    })
                                                              })),
                                                      onClick: (function (param) {
                                                          Prelude.OptionExported.$$Option.$$do(toggleActivation, (function (fn) {
                                                                  fn(account);
                                                                }));
                                                        }),
                                                      disabled: disabledToggleActivation
                                                    }),
                                                JsxRuntime.jsx(Typography, {
                                                      variant: "body1",
                                                      children: Caml_option.some(account.username)
                                                    })
                                              ],
                                              direction: ["row"],
                                              spacing: 1.0
                                            }))
                                  }),
                              JsxRuntime.jsx(TableCell, {
                                    children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                              variant: "body1",
                                              children: Caml_option.some(Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Account.name(account), (function (prim) {
                                                              return prim;
                                                            })), JsxRuntime.jsx("em", {
                                                            children: JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                                  msg: {
                                                                    NAME: "str",
                                                                    VAL: "Not given"
                                                                  }
                                                                })
                                                          })))
                                            }))
                                  }),
                              JsxRuntime.jsx(TableCell, {
                                    children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                              flexWrap: "wrap",
                                              children: Caml_option.some(account.groups.map(function (g) {
                                                        return JsxRuntime.jsx(Chip, {
                                                                    label: Caml_option.some(g.name)
                                                                  }, String(g.id));
                                                      })),
                                              direction: ["row"],
                                              spacing: 1.0
                                            }))
                                  }),
                              JsxRuntime.jsx(TableCell, {
                                    children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                              variant: "body1",
                                              children: Caml_option.some(account.date_joined.toLocaleDateString())
                                            }))
                                  }),
                              JsxRuntime.jsx(TableCell, {
                                    children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                              variant: "body1",
                                              children: Caml_option.some(Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, account.last_login, (function (lg) {
                                                              return lg.toLocaleDateString();
                                                            })), JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                            msg: {
                                                              NAME: "str",
                                                              VAL: "Never"
                                                            }
                                                          })))
                                            }))
                                  }),
                              JsxRuntime.jsx(TableCell, {
                                    children: Caml_option.some(JsxRuntime.jsx(AccountDropdown.make, {
                                              account: account
                                            }))
                                  })
                            ]
                          });
              }), [
              account,
              selected
            ]);
}

function AccountsBrowser$AccountsTable$Column(props) {
  var toggleDirection = props.toggleDirection;
  var setField = props.setField;
  var column = props.column;
  var label;
  switch (column) {
    case "username_normalized" :
        label = JsxRuntime.jsx(ReactI18Next.Message.make, {
              msg: {
                NAME: "msg",
                VAL: [
                  "accounts.username-header",
                  "Username"
                ]
              }
            });
        break;
    case "name_normalized" :
        label = JsxRuntime.jsx(ReactI18Next.Message.make, {
              msg: {
                NAME: "msg",
                VAL: [
                  "accounts.name-header",
                  "Name"
                ]
              }
            });
        break;
    case "email_normalized" :
        label = JsxRuntime.jsx(ReactI18Next.Message.make, {
              msg: {
                NAME: "msg",
                VAL: [
                  "accounts.email-header",
                  "Email"
                ]
              }
            });
        break;
    case "date_joined" :
        label = JsxRuntime.jsx(ReactI18Next.Message.make, {
              msg: {
                NAME: "msg",
                VAL: [
                  "accounts.date-joined-header",
                  "Created at"
                ]
              }
            });
        break;
    case "last_login" :
        label = JsxRuntime.jsx(ReactI18Next.Message.make, {
              msg: {
                NAME: "msg",
                VAL: [
                  "accounts.last-login-header",
                  "Last login"
                ]
              }
            });
        break;
    
  }
  if (column !== props.sortfield) {
    return JsxRuntime.jsxs(Stack, {
                children: [
                  JsxRuntime.jsx(Tooltip, {
                        children: JsxRuntime.jsx("span", {
                              children: label,
                              style: {
                                cursor: "pointer"
                              },
                              onClick: (function (param) {
                                  setField(column);
                                })
                            }),
                        arrow: true,
                        title: JsxRuntime.jsx(ReactI18Next.Message.make, {
                              msg: {
                                NAME: "str",
                                VAL: "Sort field"
                              }
                            })
                      }),
                  JsxRuntime.jsx(ArrowUpwardRounded, {
                        fontSize: "small",
                        style: {
                          opacity: "0"
                        }
                      })
                ],
                direction: ["row"],
                spacing: "1rem",
                sx: {
                  alignItems: "center"
                }
              });
  }
  var tmp;
  tmp = props.direction === "" ? JsxRuntime.jsx(ArrowUpwardRounded, {
          fontSize: "small"
        }) : JsxRuntime.jsx(ArrowDownwardRounded, {
          fontSize: "small"
        });
  return JsxRuntime.jsxs(Stack, {
              children: [
                JsxRuntime.jsx(Tooltip, {
                      children: JsxRuntime.jsx("span", {
                            children: label,
                            style: {
                              cursor: "pointer"
                            },
                            onClick: (function (param) {
                                toggleDirection();
                              })
                          }),
                      arrow: true,
                      title: JsxRuntime.jsx(ReactI18Next.Message.make, {
                            msg: {
                              NAME: "str",
                              VAL: "Reverse sort direction"
                            }
                          })
                    }),
                tmp
              ],
              direction: ["row"],
              spacing: "1rem",
              sx: {
                alignItems: "center"
              }
            });
}

function AccountsBrowser$AccountsTable(props) {
  var accounts = props.accounts;
  var hasSelection = Curry._1(Prelude.OptionExported.$$Option.getExn, AccountsSelectionManager.useHasSelection());
  var select = Curry._1(Prelude.OptionExported.$$Option.getExn, AccountsSelectionManager.useSelect());
  var unselect = Curry._1(Prelude.OptionExported.$$Option.getExn, AccountsSelectionManager.useUnselect());
  var areAllSelected = Curry._1(Prelude.OptionExported.$$Option.getExn, AccountsSelectionManager.useAreAllSelected());
  var sortfield = AccountsFiltersContext.useOrderColumn();
  var direction = AccountsFiltersContext.useOrderDirection();
  var setOrderColumn = AccountsFiltersContext.useSetOrderColumn();
  var setOrderDirection = AccountsFiltersContext.useSetOrderDirection();
  var toggleDirection = React.useCallback((function () {
          setOrderDirection(Account.Order.toggleDirection(direction));
        }), [
        direction,
        setOrderDirection
      ]);
  var toggleSelection = function (e, param) {
    var exit = 0;
    var target;
    try {
      target = e.target;
      exit = 1;
    }
    catch (exn){
      return ;
    }
    if (exit === 1) {
      var exit$1 = 0;
      var val;
      try {
        val = target.checked;
        exit$1 = 2;
      }
      catch (exn$1){
        return ;
      }
      if (exit$1 === 2) {
        if (val) {
          return select(accounts);
        } else {
          return unselect(accounts);
        }
      }
      
    }
    
  };
  var indeterminate = React.useMemo((function () {
          if (areAllSelected(accounts)) {
            return false;
          } else {
            return hasSelection;
          }
        }), [
        areAllSelected,
        accounts,
        hasSelection
      ]);
  var checked = React.useMemo((function () {
          if (indeterminate) {
            return false;
          } else {
            return areAllSelected(accounts);
          }
        }), [
        indeterminate,
        areAllSelected,
        accounts
      ]);
  return JsxRuntime.jsx(TableContainer, {
              children: Caml_option.some(JsxRuntime.jsxs(Table, {
                        children: [
                          JsxRuntime.jsx(TableHead, {
                                children: Caml_option.some(JsxRuntime.jsxs(TableRow, {
                                          children: [
                                            JsxRuntime.jsx(TableCell, {
                                                  children: Caml_option.some(JsxRuntime.jsx(Checkbox, {
                                                            checked: checked,
                                                            indeterminate: indeterminate,
                                                            onChange: toggleSelection,
                                                            size: "small"
                                                          }))
                                                }),
                                            JsxRuntime.jsx(TableCell, {
                                                  children: Caml_option.some(JsxRuntime.jsx(AccountsBrowser$AccountsTable$Column, {
                                                            column: "username_normalized",
                                                            sortfield: sortfield,
                                                            direction: direction,
                                                            setField: (function (param) {
                                                                setOrderColumn("username_normalized");
                                                              }),
                                                            toggleDirection: toggleDirection
                                                          }))
                                                }),
                                            JsxRuntime.jsx(TableCell, {
                                                  children: Caml_option.some(JsxRuntime.jsx(AccountsBrowser$AccountsTable$Column, {
                                                            column: "name_normalized",
                                                            sortfield: sortfield,
                                                            direction: direction,
                                                            setField: (function (param) {
                                                                setOrderColumn("name_normalized");
                                                              }),
                                                            toggleDirection: toggleDirection
                                                          }))
                                                }),
                                            JsxRuntime.jsx(TableCell, {
                                                  children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                                            children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                                      msg: {
                                                                        NAME: "msg",
                                                                        VAL: [
                                                                          "accounts.groups-header",
                                                                          "Groups"
                                                                        ]
                                                                      }
                                                                    })),
                                                            direction: ["row"],
                                                            spacing: "1rem",
                                                            sx: {
                                                              alignItems: "center"
                                                            }
                                                          }))
                                                }),
                                            JsxRuntime.jsx(TableCell, {
                                                  children: Caml_option.some(JsxRuntime.jsx(AccountsBrowser$AccountsTable$Column, {
                                                            column: "date_joined",
                                                            sortfield: sortfield,
                                                            direction: direction,
                                                            setField: (function (param) {
                                                                setOrderColumn("date_joined");
                                                              }),
                                                            toggleDirection: toggleDirection
                                                          }))
                                                }),
                                            JsxRuntime.jsx(TableCell, {
                                                  children: Caml_option.some(JsxRuntime.jsx(AccountsBrowser$AccountsTable$Column, {
                                                            column: "last_login",
                                                            sortfield: sortfield,
                                                            direction: direction,
                                                            setField: (function (param) {
                                                                setOrderColumn("last_login");
                                                              }),
                                                            toggleDirection: toggleDirection
                                                          }))
                                                }),
                                            JsxRuntime.jsx(TableCell, {})
                                          ]
                                        }))
                              }),
                          JsxRuntime.jsx(TableBody, {
                                children: Caml_option.some(accounts.map(function (account) {
                                          return JsxRuntime.jsx(AccountsBrowser$AccountsTable$AccountRow, {
                                                      account: account
                                                    }, String(account.id));
                                        }))
                              })
                        ],
                        size: "small"
                      }))
            });
}

function AccountsBrowser(props) {
  var pages = props.pages;
  var filters = props.filters;
  var accounts = props.accounts;
  var page = AccountsListService.useCurrentPage();
  var setPage = AccountsListService.useSetCurrentPage();
  var removeStartsWith = AccountsFiltersContext.useRemoveStartsWith();
  var removeContains = AccountsFiltersContext.useRemoveContains();
  console.log("AccountsBrowser", {
        accounts: accounts
      });
  if (Prelude.$$Array.isEmpty(accounts)) {
    return JsxRuntime.jsxs(Stack, {
                children: [
                  JsxRuntime.jsx(AccountsBrowser$AccountsFilters, {
                        filters: filters
                      }),
                  JsxRuntime.jsxs(Stack, {
                        children: [
                          JsxRuntime.jsx(Typography, {
                                variant: "h6",
                                children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                          msg: {
                                            NAME: "msg",
                                            VAL: [
                                              "accounts.not-found-heading",
                                              "Nothing found"
                                            ]
                                          }
                                        }))
                              }),
                          JsxRuntime.jsx(Typography, {
                                variant: "body1",
                                children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                          msg: {
                                            NAME: "msg",
                                            VAL: [
                                              "accounts.not-found-explanation",
                                              "There are no accounts that match your current query.\n               Please, remove some filters to get some results here."
                                            ]
                                          }
                                        }))
                              }),
                          JsxRuntime.jsx(Button, {
                                children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                          msg: {
                                            NAME: "str",
                                            VAL: "Clear filters"
                                          }
                                        })),
                                onClick: (function (param) {
                                    removeStartsWith();
                                    removeContains();
                                  }),
                                fullWidth: false
                              })
                        ],
                        direction: ["column"],
                        spacing: 4.0
                      })
                ],
                direction: ["column"],
                spacing: 2.0,
                sx: {
                  alignItems: "center",
                  width: "100%",
                  padding: "1rem"
                },
                useFlexGap: true
              });
  } else {
    return JsxRuntime.jsx(WithAccountsLassoSelector.make, {
                accounts: accounts,
                children: JsxRuntime.jsxs(Stack, {
                      children: [
                        JsxRuntime.jsx(AccountsBrowser$AccountsFilters, {
                              filters: filters
                            }),
                        JsxRuntime.jsx(Stack, {
                              children: Caml_option.some(JsxRuntime.jsx(Pagination, {
                                        count: pages,
                                        onChange: (function (param, page) {
                                            setPage(page);
                                          }),
                                        page: page,
                                        showFirstButton: true,
                                        showLastButton: true
                                      })),
                              spacing: 2.0,
                              sx: {
                                display: {
                                  md: "none"
                                }
                              },
                              useFlexGap: true
                            }),
                        JsxRuntime.jsx(AccountsBrowser$AccountsTable, {
                              accounts: accounts
                            }),
                        JsxRuntime.jsx(Stack, {
                              children: Caml_option.some(JsxRuntime.jsx(Pagination, {
                                        count: pages,
                                        onChange: (function (param, page) {
                                            setPage(page);
                                          }),
                                        page: page,
                                        showFirstButton: true,
                                        showLastButton: true
                                      })),
                              spacing: 2.0,
                              useFlexGap: true
                            })
                      ],
                      direction: ["column"],
                      spacing: 2.0,
                      sx: {
                        alignItems: "center",
                        width: "100%",
                        padding: "1rem"
                      },
                      useFlexGap: true
                    })
              });
  }
}

var make = AccountsBrowser;

export {
  make ,
}
/* letters Not a pure module */
