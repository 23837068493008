// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";

var fields = {
  TAG: "Object",
  _0: [
    [
      "id",
      "String"
    ],
    [
      "name",
      "String"
    ]
  ]
};

var Deserializer = $$JSON.MakeDeserializer({
      fields: fields
    });

function toURLPayload(collection) {
  return btoa(JSON.stringify(collection));
}

function fromURLPayload(payload) {
  var serial;
  try {
    serial = atob(payload);
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    console.error("Collection", e);
    return ;
  }
  try {
    return Prelude.Result.scream(Deserializer.fromJSON(JSON.parse(serial)));
  }
  catch (raw_e$1){
    var e$1 = Caml_js_exceptions.internalToOCamlException(raw_e$1);
    console.error("Collection", e$1);
    return ;
  }
}

function columnToString(column) {
  return column;
}

function columnFromString(param) {
  return "name";
}

function directionToString(column) {
  return column;
}

function directionFromString(value) {
  if (value === "-") {
    return "-";
  } else {
    return "";
  }
}

function toggleDirection(direction) {
  if (direction === "") {
    return "-";
  } else {
    return "";
  }
}

var Order = {
  columnToString: columnToString,
  columnFromString: columnFromString,
  directionToString: directionToString,
  directionFromString: directionFromString,
  toggleDirection: toggleDirection
};

function make(namespace) {
  return {
          namespace_id: namespace
        };
}

function clone(f) {
  return Object.assign({}, f);
}

var Filters = {
  make: make,
  clone: clone
};

function getFiles(param) {
  return [];
}

var Identifier;

var Id;

export {
  Identifier ,
  Id ,
  Deserializer ,
  toURLPayload ,
  fromURLPayload ,
  Order ,
  Filters ,
  getFiles ,
}
/* Deserializer Not a pure module */
