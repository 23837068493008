// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Settings from "../../../Settings.js";
import * as AuthManager from "../../context/AuthManager.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UserContext from "../../context/UserContext.js";
import * as Applications from "../../../apps/Applications.js";
import * as DropdownMenu from "../DropdownMenu.js";
import * as ReactI18Next from "../../../../libs/i18n/ReactI18Next.js";
import * as ActionsManager from "../../context/ActionsManager.js";
import * as ReactRouterDom from "react-router-dom";
import Box from "@mui/material/Box";
import * as JsxRuntime from "react/jsx-runtime";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import * as ActiveApplicationService from "../../context/ActiveApplicationService.js";
import MenuRounded from "@mui/icons-material/MenuRounded";
import LogoutRounded from "@mui/icons-material/LogoutRounded";
import AccountCircleRounded from "@mui/icons-material/AccountCircleRounded";
import AdminPanelSettingsRounded from "@mui/icons-material/AdminPanelSettingsRounded";

function PhotocubaAppNav$UserAvatar(props) {
  var user = UserContext.useUserClaim();
  var logout = AuthManager.useLogout();
  var first_name = Prelude.default(user.first_name, "").trim();
  var last_name = Prelude.default(user.last_name, "").trim();
  var acronym;
  var exit = 0;
  if (first_name === "" && last_name === "") {
    acronym = user.username.charAt(0).toUpperCase();
  } else {
    exit = 1;
  }
  if (exit === 1) {
    acronym = last_name === "" ? first_name.charAt(0).toUpperCase() : (
        first_name === "" ? last_name.charAt(0).toUpperCase() : first_name.charAt(0).toUpperCase() + last_name.charAt(0).toUpperCase()
      );
  }
  var items = [
    {
      TAG: "Item",
      _0: {
        title: {
          NAME: "str",
          VAL: "Settings"
        },
        icon: Caml_option.some(JsxRuntime.jsx(AccountCircleRounded, {})),
        action: {
          TAG: "Route",
          _0: "/user/settings"
        }
      }
    },
    {
      TAG: "Item",
      _0: {
        title: {
          NAME: "str",
          VAL: "Log out"
        },
        icon: Caml_option.some(JsxRuntime.jsx(LogoutRounded, {})),
        action: {
          TAG: "Click",
          _0: (function (param) {
              logout();
            })
        }
      }
    }
  ];
  var items$1 = user.is_superuser ? Curry._2(Prelude.$$Array.concat, items, [
          "Separator",
          {
            TAG: "Item",
            _0: {
              title: {
                NAME: "str",
                VAL: "Admin"
              },
              icon: Caml_option.some(JsxRuntime.jsx(AdminPanelSettingsRounded, {})),
              action: {
                TAG: "ExternalLink",
                _0: Settings.adminURL
              }
            }
          }
        ]) : items;
  return JsxRuntime.jsx(DropdownMenu.make, {
              trigger: {
                TAG: "Icon",
                _0: JsxRuntime.jsx(Avatar, {
                      children: Caml_option.some(acronym),
                      sx: {
                        bgcolor: "text.primary"
                      }
                    })
              },
              items: items$1
            });
}

function PhotocubaAppNav$ModulesMenu(props) {
  var user = UserContext.useUserClaim();
  var permissions = UserContext.useUserPermissionsClaim();
  var modules = React.useMemo((function () {
          return Applications.getUserApplications(permissions, ["app"]);
        }), [
        user,
        permissions
      ]);
  var configModules = React.useMemo((function () {
          return Applications.getUserApplications(permissions, ["config"]);
        }), [
        user,
        permissions
      ]);
  var items = React.useMemo((function () {
          return modules.map(function (mod) {
                      return {
                              TAG: "Item",
                              _0: {
                                title: mod.name,
                                icon: mod.icon,
                                action: {
                                  TAG: "Route",
                                  _0: mod.path
                                }
                              }
                            };
                    });
        }), [
        modules,
        "light"
      ]);
  var configItems = React.useMemo((function () {
          return configModules.map(function (mod) {
                      return {
                              TAG: "Item",
                              _0: {
                                title: mod.name,
                                icon: mod.icon,
                                action: {
                                  TAG: "Route",
                                  _0: mod.path
                                }
                              }
                            };
                    });
        }), [
        configModules,
        "light"
      ]);
  var items$1 = React.useMemo((function () {
          if (Prelude.$$Array.isEmpty(configItems)) {
            return items;
          } else {
            return Curry._2(Prelude.$$Array.concat, Prelude.$$Array.isNotEmpty(items) ? Curry._2(Prelude.$$Array.concat, items, ["Separator"]) : items, configItems);
          }
        }), [
        items,
        configItems
      ]);
  var disabled = items$1.length < 2;
  return JsxRuntime.jsx(DropdownMenu.make, {
              trigger: {
                TAG: "Icon",
                _0: JsxRuntime.jsx(MenuRounded, {})
              },
              items: items$1,
              disabled: disabled
            });
}

function PhotocubaAppNav$ActiveActions(props) {
  var getActiveAffordances = ActionsManager.useGetActiveAffordances();
  var affordances = React.useMemo((function () {
          return getActiveAffordances();
        }), [getActiveAffordances]);
  return React.useMemo((function () {
                return affordances.map(function (param) {
                            return param.widget(param.key);
                          });
              }), [affordances]);
}

function PhotocubaAppNav(props) {
  var appName = ActiveApplicationService.useCurrentApplicationName();
  var appLink = ActiveApplicationService.useCurrentApplicationLink();
  var navigate = ReactRouterDom.useLinkClickHandler(appLink);
  var onClick = function (e) {
    e.preventDefault();
    Prelude.thenDo(Prelude.Promises.ellapsed(220), (function () {
            navigate(e);
          }));
  };
  return JsxRuntime.jsx(AppBar, {
              children: Caml_option.some(JsxRuntime.jsx(Container, {
                        children: Caml_option.some(JsxRuntime.jsxs(Toolbar, {
                                  children: [
                                    JsxRuntime.jsx(PhotocubaAppNav$ModulesMenu, {}),
                                    JsxRuntime.jsx(Button, {
                                          children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                    variant: "h6",
                                                    children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                              msg: appName
                                                            })),
                                                    sx: {
                                                      mx: "1rem"
                                                    }
                                                  })),
                                          onClick: onClick,
                                          variant: "text"
                                        }),
                                    JsxRuntime.jsx(Box, {
                                          children: Caml_option.some(JsxRuntime.jsx(PhotocubaAppNav$ActiveActions, {})),
                                          sx: {
                                            display: "flex",
                                            alignItems: "center",
                                            flexGrow: "1",
                                            gap: "1rem",
                                            marginLeft: "1rem",
                                            marginRight: "1rem"
                                          }
                                        }),
                                    JsxRuntime.jsx(PhotocubaAppNav$UserAvatar, {})
                                  ],
                                  disableGutters: true
                                })),
                        maxWidth: false
                      })),
              color: "default",
              position: "sticky"
            });
}

var make = PhotocubaAppNav;

export {
  make ,
}
/* react Not a pure module */
