// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Mutex from "../../../utils/Mutex.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UserContext from "../../../global/context/UserContext.js";
import * as ReactI18Next from "../../../../libs/i18n/ReactI18Next.js";
import * as ReactI18next from "react-i18next";
import * as SharedDBContext from "../../../global/context/SharedDBContext.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as BundlerAPIEndpoint from "../api/BundlerAPIEndpoint.js";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import LinearProgress from "@mui/material/LinearProgress";
import DialogContentText from "@mui/material/DialogContentText";

var context = React.createContext(undefined);

var make = context.Provider;

function useRequest() {
  return React.useContext(context);
}

async function load(db) {
  var init = db.makeRead();
  var match = await db.read({
        documents: init.documents,
        folders: init.folders,
        bundlers: {
          TAG: "Limit",
          _0: 1,
          _1: "All"
        }
      });
  var bundlers = match.bundlers;
  if (bundlers.length !== 1) {
    return "Idle";
  }
  var current = bundlers[0];
  return {
          TAG: "Waiting",
          _0: current
        };
}

async function createRequest(db, token, folders, documents) {
  var match = await BundlerAPIEndpoint.request(folders, documents, token);
  if (match.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: undefined
          };
  }
  var bundler = match._0;
  if (bundler === undefined) {
    return {
            TAG: "Error",
            _0: undefined
          };
  }
  var init = db.makeWrite();
  await db.write({
        documents: init.documents,
        folders: init.folders,
        bundlers: [
          "Clear",
          {
            TAG: "Save",
            _0: bundler
          }
        ]
      });
  return {
          TAG: "Ok",
          _0: bundler
        };
}

function action(param) {
  return BundlerAPIEndpoint.poll(param[0], param[1]);
}

var Poller = Mutex.MakeThrottle({
      rate: {
        NAME: "Milliseconds",
        VAL: 2000
      },
      name: "Bundler Poller",
      action: action
    });

function BundlesManager$WaitingDialog(props) {
  var onDownload = props.onDownload;
  var onCancel = props.onCancel;
  var bundler = props.bundler;
  console.log("BundlesManager", bundler);
  var match = bundler.status;
  var title = match === "running" || match === "queued" ? JsxRuntime.jsx(ReactI18Next.Message.make, {
          msg: {
            NAME: "str",
            VAL: "Packing"
          }
        }) : (
      match === "failed" ? JsxRuntime.jsx(ReactI18Next.Message.make, {
              msg: {
                NAME: "str",
                VAL: "Error"
              }
            }) : (
          match === "cancelled" ? JsxRuntime.jsx(ReactI18Next.Message.make, {
                  msg: {
                    NAME: "str",
                    VAL: "Cancelled"
                  }
                }) : JsxRuntime.jsx(ReactI18Next.Message.make, {
                  msg: {
                    NAME: "str",
                    VAL: "File ready"
                  }
                })
        )
    );
  var match$1 = bundler.status;
  var content = match$1 === "cancelled" || match$1 === "running" || match$1 === "queued" ? JsxRuntime.jsx(DialogContentText, {
          children: Caml_option.some(JsxRuntime.jsx(LinearProgress, {}))
        }) : (
      match$1 === "failed" ? JsxRuntime.jsxs(Alert, {
              children: [
                JsxRuntime.jsx(ReactI18next.Trans, {
                      i18nKey: "bundler.errors.unknown-title",
                      children: "The file could not be created."
                    }),
                " ",
                JsxRuntime.jsx(ReactI18Next.Message.make, {
                      msg: {
                        NAME: "msg",
                        VAL: [
                          Prelude.default(bundler.failure_code, "bundler.errors.generic-description"),
                          "The server replied with an error."
                        ]
                      }
                    })
              ],
              severity: "error"
            }) : JsxRuntime.jsx(Alert, {
              children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                        msg: {
                          NAME: "msg",
                          VAL: [
                            "bundler.file-ready-description",
                            "Your file is ready to be downloaded. Press the link below to start the download."
                          ]
                        }
                      }))
            })
    );
  var match$2 = bundler.status;
  var download = match$2 === "done" ? JsxRuntime.jsx(Button, {
          children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                    msg: {
                      NAME: "str",
                      VAL: "Download"
                    }
                  })),
          onClick: (function (param) {
              onDownload();
            })
        }) : null;
  return JsxRuntime.jsxs(Dialog, {
              disableEscapeKeyDown: true,
              open: true,
              children: [
                JsxRuntime.jsx(DialogTitle, {
                      children: Caml_option.some(title)
                    }),
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(content)
                    }),
                JsxRuntime.jsxs(DialogActions, {
                      children: [
                        JsxRuntime.jsx(Button, {
                              children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                        msg: {
                                          NAME: "msg",
                                          VAL: [
                                            "buttons.cancel",
                                            "Cancel"
                                          ]
                                        }
                                      })),
                              onClick: (function (param) {
                                  onCancel();
                                })
                            }),
                        download
                      ]
                    })
              ],
              fullWidth: true,
              maxWidth: "sm"
            });
}

var triggerDownload = (function(fileUrl, name) {
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = name;
      link.target = '_blank';
      link.rel = 'noopener noreferrer';
      link.click();
    });

function BundlesManager(props) {
  var children = props.children;
  var match = React.useState(function () {
        return "Init";
      });
  var setState = match[1];
  var state = match[0];
  var token = Curry._1(Prelude.OptionExported.$$Option.getExn, UserContext.useAccessToken());
  var db = SharedDBContext.useQuery();
  var write = db.write;
  var makeWrite = db.makeWrite;
  React.useEffect((function () {
          if (typeof state !== "object") {
            if (state !== "Init") {
              return ;
            }
            Prelude.thenDo(load(db), (function (state) {
                    setState(function (param) {
                          return state;
                        });
                  }));
            return ;
          } else {
            switch (state.TAG) {
              case "Waiting" :
                  var bundler = state._0;
                  var promise = Poller.$$do([
                        bundler.id,
                        token
                      ]);
                  Prelude.thenDo(promise, (function (res) {
                          console.log("BundlesManager", "polling", res);
                          if (res.TAG === "Ok") {
                            var bundler$1 = res._0;
                            if (bundler$1 !== undefined) {
                              var match = bundler$1.status;
                              if (match === "done") {
                                return setState(function (param) {
                                            return {
                                                    TAG: "Done",
                                                    _0: bundler$1
                                                  };
                                          });
                              } else if (match === "failed") {
                                return setState(function (param) {
                                            return {
                                                    TAG: "Failed",
                                                    _0: bundler$1
                                                  };
                                          });
                              } else {
                                return setState(function (previous) {
                                            if (typeof previous !== "object" || previous.TAG !== "Waiting") {
                                              return previous;
                                            } else {
                                              return {
                                                      TAG: "Waiting",
                                                      _0: bundler$1
                                                    };
                                            }
                                          });
                              }
                            }
                            
                          }
                          setState(function (param) {
                                return {
                                        TAG: "Failed",
                                        _0: bundler
                                      };
                              });
                        }));
                  return (function () {
                            console.log("BundlesManager", "stopped polling");
                            Poller.stop();
                          });
              case "Done" :
              case "Failed" :
                  break;
              
            }
          }
          var init = makeWrite();
          write({
                documents: init.documents,
                folders: init.folders,
                bundlers: [{
                    TAG: "Delete",
                    _0: state._0.id
                  }]
              });
        }), [
        state,
        db,
        token,
        makeWrite,
        write
      ]);
  var request = React.useMemo((function () {
          if (typeof state !== "object" && state === "Idle") {
            return (function (folders, documents) {
                      Prelude.thenDo(createRequest(db, token, folders, documents), (function (res) {
                              if (res.TAG !== "Ok") {
                                return ;
                              }
                              var bundler = res._0;
                              setState(function (param) {
                                    return {
                                            TAG: "Waiting",
                                            _0: bundler
                                          };
                                  });
                            }));
                    });
          }
          
        }), [
        state,
        token
      ]);
  var tmp;
  if (typeof state !== "object") {
    tmp = children;
  } else {
    var bundler = state._0;
    tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            children,
            JsxRuntime.jsx(BundlesManager$WaitingDialog, {
                  bundler: bundler,
                  onCancel: (function () {
                      var init = makeWrite();
                      write({
                            documents: init.documents,
                            folders: init.folders,
                            bundlers: [{
                                TAG: "Delete",
                                _0: bundler.id
                              }]
                          });
                      setState(function (param) {
                            return "Idle";
                          });
                      Poller.stop();
                      if (typeof state !== "object") {
                        return ;
                      }
                      switch (state.TAG) {
                        case "Waiting" :
                            BundlerAPIEndpoint.cancel(bundler.id, token);
                            return ;
                        case "Done" :
                        case "Failed" :
                            return ;
                        
                      }
                    }),
                  onDownload: (function () {
                      triggerDownload(Curry._1(Prelude.OptionExported.$$Option.getExn, bundler.url), bundler.name);
                      setState(function (param) {
                            return "Idle";
                          });
                    })
                })
          ]
        });
  }
  return JsxRuntime.jsx(make, {
              value: request,
              children: tmp
            });
}

var make$1 = BundlesManager;

export {
  useRequest ,
  make$1 as make,
}
/* context Not a pure module */
