// Generated by ReScript, PLEASE EDIT WITH CARE

import * as APIMaker from "../../../utils/APIMaker.js";
import * as FolderMatch from "../models/FolderMatch.js";
import * as AttributeMatch from "../models/AttributeMatch.js";
import * as CatalogAPICommon from "../../catalog/api/CatalogAPICommon.js";

var Folder = APIMaker.MakePaginatedEndpoint({
      url: "/search/meta/folders/:namespace_id",
      Deserializer: FolderMatch.Deserializer
    });

var Attributes = APIMaker.MakePaginatedEndpoint({
      url: "/search/meta/attrs/:namespace_id",
      Deserializer: AttributeMatch.Deserializer
    });

var Meta = {
  Folder: Folder,
  Attributes: Attributes
};

var Documents = APIMaker.MakePaginatedEndpoint({
      url: "/search/docs/:namespace_id",
      Deserializer: CatalogAPICommon.DocumentNode.Deserializer
    });

export {
  Meta ,
  Documents ,
}
/* Folder Not a pure module */
