// Generated by ReScript, PLEASE EDIT WITH CARE

import * as IDB__Request from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/IDB__Request.js";

function make(param) {
  return async function (_db, transaction) {
    await IDB__Request.promise(transaction.objectStore("folders").clear());
    await IDB__Request.promise(transaction.objectStore("documents").clear());
    return {
            TAG: "Ok",
            _0: undefined
          };
  };
}

export {
  make ,
}
/* No side effect */
