// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import StyleOutlined from "@mui/icons-material/StyleOutlined";

var permissions = {
  TAG: "All",
  _0: [
    "tagging.change_tag",
    "catalog.view_collection",
    "catalog.view_document",
    "catalog.view_folder",
    "catalog.view_namespace",
    "catalog.view_attribute"
  ]
};

var icon = Caml_option.some(JsxRuntime.jsx(StyleOutlined, {}));

var name = {
  NAME: "msg",
  VAL: [
    "tags.title",
    "Tags"
  ]
};

var path = "/tags";

export {
  name ,
  path ,
  permissions ,
  icon ,
}
/* icon Not a pure module */
