// Generated by ReScript, PLEASE EDIT WITH CARE

import * as APIMaker from "../../../utils/APIMaker.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as InstalledPackage from "../models/InstalledPackage.js";

var ReadEndpoint = APIMaker.MakeBackendReadonlyAPIEndpoint({
      url: "packages",
      Deserializer: InstalledPackage.Deserializer
    });

async function read(token) {
  var pks = await ReadEndpoint.getAll(undefined, Caml_option.some(token));
  if (pks.TAG === "Ok") {
    return pks._0;
  } else {
    return [];
  }
}

export {
  read ,
}
/* ReadEndpoint Not a pure module */
