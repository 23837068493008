// Generated by ReScript, PLEASE EDIT WITH CARE


function encodeCompareHow(x) {
  switch (x) {
    case "StartToStart" :
        return 0;
    case "StartToEnd" :
        return 1;
    case "EndToEnd" :
        return 2;
    case "EndToStart" :
        return 3;
    
  }
}

function decodeCompareResult(x) {
  switch (x) {
    case -1 :
        return "Before";
    case 0 :
        return "Equal";
    case 1 :
        return "After";
    default:
      return "Unknown";
  }
}

function decodeCompatMode(x) {
  switch (x) {
    case "BackCompat" :
        return "BackCompat";
    case "CSS1Compat" :
        return "CSS1Compat";
    default:
      return "Unknown";
  }
}

function encodeContentEditable(x) {
  switch (x) {
    case "True" :
        return "true";
    case "False" :
        return "false";
    case "Inherit" :
        return "inherit";
    case "Unknown" :
        return "";
    
  }
}

function decodeContentEditable(x) {
  switch (x) {
    case "false" :
        return "False";
    case "inherit" :
        return "Inherit";
    case "true" :
        return "True";
    default:
      return "Unknown";
  }
}

function decodeDeltaMode(x) {
  switch (x) {
    case 0 :
        return "Pixel";
    case 1 :
        return "Line";
    case 2 :
        return "Page";
    default:
      throw {
            RE_EXN_ID: "Invalid_argument",
            _1: "invalid deltaMode",
            Error: new Error()
          };
  }
}

function encodeDesignMode(x) {
  switch (x) {
    case "On" :
        return "on";
    case "Off" :
        return "off";
    case "Unknown" :
        return "";
    
  }
}

function decodeDesignMode(x) {
  switch (x) {
    case "off" :
        return "Off";
    case "on" :
        return "On";
    default:
      return "Unknown";
  }
}

function encodeDir(x) {
  switch (x) {
    case "Ltr" :
        return "ltr";
    case "Rtl" :
        return "rtl";
    case "Unknown" :
        return "";
    
  }
}

function decodeDir(x) {
  switch (x) {
    case "ltr" :
        return "Ltr";
    case "rtl" :
        return "Rtl";
    default:
      return "Unknown";
  }
}

function decode(x) {
  switch (x) {
    case 0 :
        return "None";
    case 1 :
        return "CapturingPhase";
    case 2 :
        return "AtTarget";
    case 3 :
        return "BubblingPhase";
    default:
      return "Unknown";
  }
}

var EventPhase = {
  decode: decode
};

function encodeFilterAction(x) {
  switch (x) {
    case "Accept" :
        return 1;
    case "Reject" :
        return 2;
    case "Skip" :
        return 3;
    
  }
}

function encodeInsertPosition(x) {
  switch (x) {
    case "BeforeBegin" :
        return "beforebegin";
    case "AfterBegin" :
        return "afterbegin";
    case "BeforeEnd" :
        return "beforeend";
    case "AfterEnd" :
        return "afterend";
    
  }
}

function encodeModifierKey(x) {
  switch (x) {
    case "Alt" :
        return "Alt";
    case "AltGraph" :
        return "AltGraph";
    case "CapsLock" :
        return "CapsLock";
    case "Control" :
        return "Control";
    case "Fn" :
        return "Fn";
    case "FnLock" :
        return "FnLock";
    case "Hyper" :
        return "Hyper";
    case "Meta" :
        return "Meta";
    case "NumLock" :
        return "NumLock";
    case "ScrollLock" :
        return "ScrollLock";
    case "Shift" :
        return "Shift";
    case "Super" :
        return "Super";
    case "Symbol" :
        return "Symbol";
    case "SymbolLock" :
        return "SymbolLock";
    
  }
}

function decodeNodeType(x) {
  switch (x) {
    case 1 :
        return "Element";
    case 2 :
        return "Attribute";
    case 3 :
        return "Text";
    case 4 :
        return "CDATASection";
    case 5 :
        return "EntityReference";
    case 6 :
        return "Entity";
    case 7 :
        return "ProcessingInstruction";
    case 8 :
        return "Comment";
    case 9 :
        return "Document";
    case 10 :
        return "DocumentType";
    case 11 :
        return "DocumentFragment";
    case 12 :
        return "Notation";
    default:
      return "Unknown";
  }
}

function decodePointerType(x) {
  switch (x) {
    case "mouse" :
        return "Mouse";
    case "pen" :
        return "Pen";
    case "touch|" :
        return "Touch";
    default:
      return "Unknown";
  }
}

function decodeReadyState(x) {
  switch (x) {
    case "complete" :
        return "Complete";
    case "interactive" :
        return "Interactive";
    case "loading" :
        return "Loading";
    default:
      return "Unknown";
  }
}

function decodeShadowRootMode(x) {
  switch (x) {
    case "closed" :
        return "Closed";
    case "open" :
        return "Open";
    default:
      throw {
            RE_EXN_ID: "Invalid_argument",
            _1: "Unknown shadowRootMode",
            Error: new Error()
          };
  }
}

function decodeVisibilityState(x) {
  switch (x) {
    case "hidden" :
        return "Hidden";
    case "prerender" :
        return "Prerender";
    case "unloaded" :
        return "Unloaded";
    case "visible" :
        return "Visible";
    default:
      return "Unknown";
  }
}

function many(x) {
  if (x) {
    return x.hd | many(x.tl);
  } else {
    return 0;
  }
}

var WhatToShow = {
  _All: -1,
  _Element: 1,
  _Attribute: 2,
  _Text: 4,
  _CDATASection: 8,
  _EntityReference: 16,
  _Entity: 32,
  _ProcessingInstruction: 64,
  _Comment: 128,
  _Document: 256,
  _DocumentType: 512,
  _DocumentFragment: 1024,
  _Notation: 2048,
  many: many
};

export {
  encodeCompareHow ,
  decodeCompareResult ,
  decodeCompatMode ,
  encodeContentEditable ,
  decodeContentEditable ,
  decodeDeltaMode ,
  encodeDesignMode ,
  decodeDesignMode ,
  encodeDir ,
  decodeDir ,
  EventPhase ,
  encodeFilterAction ,
  encodeInsertPosition ,
  encodeModifierKey ,
  decodeNodeType ,
  decodePointerType ,
  decodeReadyState ,
  decodeShadowRootMode ,
  decodeVisibilityState ,
  WhatToShow ,
}
/* No side effect */
