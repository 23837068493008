// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CollectionsManager from "./CollectionsManager.js";
import * as CollectionsListService from "./CollectionsListService.js";

var context = React.createContext(undefined);

var make = context.Provider;

var Provider = {
  make: make
};

function useUpdateCollection() {
  return React.useContext(context);
}

function UpdateCollectionService(props) {
  var update = CollectionsManager.useUpdateCollection();
  var refresh = CollectionsListService.useRefresh();
  var value = React.useMemo((function () {
          return Curry._2(Prelude.OptionExported.$$Option.map, update, (function (update) {
                        return function (collection) {
                          return update(collection).finally(function () {
                                      refresh();
                                    });
                        };
                      }));
        }), [
        update,
        refresh
      ]);
  return JsxRuntime.jsx(make, {
              value: value,
              children: props.children
            });
}

var make$1 = UpdateCollectionService;

export {
  context ,
  Provider ,
  useUpdateCollection ,
  make$1 as make,
}
/* context Not a pure module */
