// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as $$Document from "../../models/Document.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CollectionId from "../../../collections/models/CollectionId.js";
import * as ReactI18Next from "../../../../../libs/i18n/ReactI18Next.js";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import * as JsxRuntime from "react/jsx-runtime";
import Tabs from "@mui/material/Tabs";
import * as DocumentBrowserTab from "./DocumentBrowserTab.js";
import Stack from "@mui/material/Stack";
import * as DocumentAttributeLabels from "./DocumentAttributeLabels.js";
import * as DocumentBrowserChipsField from "./DocumentBrowserChipsField.js";
import * as DocumentBrowserInfoDataText from "./DocumentBrowserInfoDataText.js";
import CropFree from "@mui/icons-material/CropFree";
import EditCalendar from "@mui/icons-material/EditCalendar";
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import * as DocumentBrowserInfoDataTextWithIcon from "./DocumentBrowserInfoDataTextWithIcon.js";

function DocumentBrowserReadonlyPanel(props) {
  var setCurrentTab = props.setCurrentTab;
  var currentTab = props.currentTab;
  var $$document = props.document;
  var name = $$Document.name($$document);
  var description = $$document.description;
  var created = $$document.created_at.toLocaleDateString();
  var updated = $$document.updated_at.toLocaleDateString();
  var author = $$Document.author($$document);
  var copyright = $$Document.copyright($$document);
  var country = $$Document.country($$document);
  var city = $$Document.city($$document);
  var height = Curry._2(Prelude.OptionExported.$$Option.map, $$Document.height($$document), (function (prim) {
          return String(prim);
        }));
  var width = Curry._2(Prelude.OptionExported.$$Option.map, $$Document.width($$document), (function (prim) {
          return String(prim);
        }));
  var sources = $$Document.sources($$document);
  var usage_terms = $$Document.usage_terms($$document);
  var author_job_title = $$Document.author_job_title($$document);
  var imgSize = height !== undefined && width !== undefined ? width + "x" + height : undefined;
  return JsxRuntime.jsx(Stack, {
              children: Caml_option.some(JsxRuntime.jsxs(Box, {
                        children: [
                          JsxRuntime.jsx(Box, {
                                children: Caml_option.some(JsxRuntime.jsxs(Tabs, {
                                          children: [
                                            JsxRuntime.jsx(Tab, {
                                                  id: "simple-tab-0",
                                                  label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                            msg: {
                                                              NAME: "msg",
                                                              VAL: [
                                                                "document-browser.tab-description",
                                                                "Description"
                                                              ]
                                                            }
                                                          })),
                                                  value: "description"
                                                }),
                                            JsxRuntime.jsx(Tab, {
                                                  id: "simple-tab-1",
                                                  label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                            msg: {
                                                              NAME: "msg",
                                                              VAL: [
                                                                "document-browser.tab-authoring",
                                                                "Authoring"
                                                              ]
                                                            }
                                                          })),
                                                  value: "authoring"
                                                })
                                          ],
                                          onChange: (function (param, tab) {
                                              setCurrentTab(tab);
                                            }),
                                          value: currentTab
                                        })),
                                sx: {
                                  border: "1",
                                  borderColor: "divider"
                                }
                              }),
                          JsxRuntime.jsx(DocumentBrowserTab.make, {
                                value: currentTab,
                                index: "description",
                                children: JsxRuntime.jsxs(Stack, {
                                      children: [
                                        JsxRuntime.jsx(DocumentBrowserInfoDataText.make, {
                                              name: DocumentAttributeLabels.get("name"),
                                              value: name
                                            }),
                                        JsxRuntime.jsx(DocumentBrowserInfoDataText.make, {
                                              name: DocumentAttributeLabels.get("description"),
                                              value: description
                                            }),
                                        JsxRuntime.jsx(DocumentBrowserChipsField.make, {
                                              name: DocumentAttributeLabels.get("tags"),
                                              items: Curry._2(Prelude.$$Array.keep, $$document.tags, (function (t) {
                                                        return t.approved;
                                                      })).map(function (param) {
                                                    return {
                                                            id: String(param.id),
                                                            name: param.name
                                                          };
                                                  })
                                            }),
                                        JsxRuntime.jsx(DocumentBrowserChipsField.make, {
                                              name: DocumentAttributeLabels.get("collections"),
                                              items: $$document.collections.map(function (param) {
                                                    return {
                                                            id: CollectionId.toString(param.id),
                                                            name: param.name
                                                          };
                                                  })
                                            }),
                                        JsxRuntime.jsx(DocumentBrowserInfoDataText.make, {
                                              name: DocumentAttributeLabels.get("country"),
                                              value: country
                                            }),
                                        JsxRuntime.jsx(DocumentBrowserInfoDataText.make, {
                                              name: DocumentAttributeLabels.get("city"),
                                              value: city
                                            }),
                                        JsxRuntime.jsx(DocumentBrowserInfoDataTextWithIcon.make, {
                                              name: DocumentAttributeLabels.get("size"),
                                              value: imgSize,
                                              icon: JsxRuntime.jsx(CropFree, {})
                                            }),
                                        JsxRuntime.jsx(DocumentBrowserInfoDataTextWithIcon.make, {
                                              name: DocumentAttributeLabels.get("created_at"),
                                              value: created,
                                              icon: JsxRuntime.jsx(CalendarMonth, {})
                                            }),
                                        JsxRuntime.jsx(DocumentBrowserInfoDataTextWithIcon.make, {
                                              name: DocumentAttributeLabels.get("updated_at"),
                                              value: updated,
                                              icon: JsxRuntime.jsx(EditCalendar, {})
                                            })
                                      ],
                                      direction: ["column"],
                                      spacing: "1rem",
                                      useFlexGap: true
                                    })
                              }),
                          JsxRuntime.jsx(DocumentBrowserTab.make, {
                                value: currentTab,
                                index: "authoring",
                                children: JsxRuntime.jsxs(Stack, {
                                      children: [
                                        JsxRuntime.jsx(DocumentBrowserInfoDataText.make, {
                                              name: DocumentAttributeLabels.get("author"),
                                              value: author
                                            }),
                                        JsxRuntime.jsx(DocumentBrowserInfoDataText.make, {
                                              name: DocumentAttributeLabels.get("author_job_title"),
                                              value: author_job_title
                                            }),
                                        JsxRuntime.jsx(DocumentBrowserInfoDataText.make, {
                                              name: DocumentAttributeLabels.get("sources"),
                                              value: sources
                                            }),
                                        JsxRuntime.jsx(DocumentBrowserInfoDataText.make, {
                                              name: DocumentAttributeLabels.get("copyright"),
                                              value: copyright
                                            }),
                                        JsxRuntime.jsx(DocumentBrowserInfoDataText.make, {
                                              name: DocumentAttributeLabels.get("usage_terms"),
                                              value: usage_terms
                                            })
                                      ],
                                      direction: ["column"],
                                      spacing: "1rem",
                                      useFlexGap: true
                                    })
                              })
                        ],
                        sx: {
                          width: "100%"
                        }
                      })),
              direction: ["column"],
              spacing: "0.5rem",
              sx: {
                width: "100%",
                padding: "0.5rem"
              },
              useFlexGap: true
            });
}

var make = DocumentBrowserReadonlyPanel;

export {
  make ,
}
/* Document Not a pure module */
