// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as User from "../models/User.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Luxon from "../../../libs/Luxon.js";
import * as Mutex from "../../utils/Mutex.js";
import * as Luxon$1 from "luxon";
import * as React from "react";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as AuthToken from "../models/AuthToken.js";
import * as LoginPage from "../components/pages/LoginPage.js";
import * as AuthManager from "./AuthManager.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as AuthEndpoint from "../api/AuthEndpoint.js";
import * as Hooks from "@mantine/hooks";
import * as LoginErrorPage from "../components/pages/LoginErrorPage.js";
import * as UserPermission from "../models/UserPermission.js";
import * as AccountEndpoint from "../api/AccountEndpoint.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";

var fields = {
  TAG: "Object",
  _0: [
    [
      "access",
      {
        TAG: "Object",
        _0: [[
            "token",
            "String"
          ]]
      }
    ],
    [
      "refresh",
      {
        TAG: "Object",
        _0: [[
            "token",
            "String"
          ]]
      }
    ]
  ]
};

var include = $$JSON.MakeDeserializer({
      fields: fields
    });

var fromJSON = include.fromJSON;

function fromJSON$1(data) {
  return Curry._2(Prelude.Result.flatMap, fromJSON(data), (function (res) {
                var match = AuthToken.Access.parse(res.access.token);
                var match$1 = AuthToken.Refresh.parse(res.refresh.token);
                if (match.TAG === "Ok" && match$1.TAG === "Ok") {
                  return {
                          TAG: "Ok",
                          _0: {
                            access: {
                              token: res.access.token,
                              parsed: match._0
                            },
                            refresh: {
                              token: res.refresh.token,
                              parsed: match$1._0
                            }
                          }
                        };
                } else {
                  return {
                          TAG: "Error",
                          _0: "Could not parse some token"
                        };
                }
              }));
}

function make(access, refresh) {
  return fromJSON$1({
              access: {
                token: access
              },
              refresh: {
                token: refresh
              }
            });
}

var fields$1 = {
  TAG: "Object",
  _0: [
    [
      "user",
      {
        TAG: "Deserializer",
        _0: User.Deserializer
      }
    ],
    [
      "permissions",
      {
        TAG: "Collection",
        _0: UserPermission.Deserializer
      }
    ]
  ]
};

var Deserializer = $$JSON.MakeDeserializer({
      fields: fields$1
    });

var _map = {"unauthorized":"unauthorized","logged":"logged"};

var fields$2 = {
  TAG: "Object",
  _0: [
    [
      "tag",
      $$JSON.Field.variadicString("tag", (function (x) {
              return _map[x];
            }))
    ],
    [
      "tokens",
      {
        TAG: "Optional",
        _0: {
          TAG: "Deserializer",
          _0: {
            name: include.name,
            fromJSON: fromJSON$1,
            checkFieldsSanity: include.checkFieldsSanity
          }
        }
      }
    ],
    [
      "claims",
      {
        TAG: "Optional",
        _0: {
          TAG: "Deserializer",
          _0: Deserializer
        }
      }
    ]
  ]
};

var Impl = $$JSON.MakeDeserializer({
      fields: fields$2
    });

var fromJSON$2 = Impl.fromJSON;

function toJSON(value) {
  if (typeof value !== "object") {
    return {
            tag: "unauthorized"
          };
  }
  if (value.NAME === "errormsg") {
    return {
            tag: "unauthorized"
          };
  }
  var match = value.VAL;
  return {
          tag: "logged",
          tokens: match[0],
          claims: match[1]
        };
}

function fromString(value) {
  var data;
  try {
    data = JSON.parse(value);
  }
  catch (exn){
    return "unauthorized";
  }
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Prelude.Result.warn(fromJSON$2(data)), (function (parsed) {
                    if (parsed.tag !== "logged") {
                      return "unauthorized";
                    }
                    var tokens = parsed.tokens;
                    if (tokens !== undefined) {
                      var claims = parsed.claims;
                      if (claims !== undefined) {
                        return {
                                NAME: "logged",
                                VAL: [
                                  tokens,
                                  claims
                                ]
                              };
                      } else {
                        console.warn("UserContext", "fromString", parsed);
                        return "unauthorized";
                      }
                    }
                    console.warn("UserContext", "fromString", parsed);
                    return "unauthorized";
                  })), "unauthorized");
}

function toString(value) {
  return Js_json.serializeExn(toJSON(value));
}

var context = React.createContext("unauthorized");

var make$1 = context.Provider;

function useAccessToken() {
  var match = React.useContext(context);
  if (typeof match === "object" && match.NAME === "logged") {
    return Caml_option.some(match.VAL[0].access.token);
  }
  
}

function useUserClaim() {
  var match = React.useContext(context);
  if (typeof match === "object" && match.NAME === "logged") {
    return match.VAL[1].user;
  }
  
}

function useUserPermissionsClaim() {
  var match = React.useContext(context);
  if (typeof match === "object") {
    if (match.NAME === "logged") {
      return match.VAL[1].permissions;
    } else {
      return [];
    }
  } else {
    return [];
  }
}

async function action(arg) {
  var payload;
  try {
    payload = await AuthEndpoint.RefreshToken.$$do(arg);
  }
  catch (raw_error){
    var error = Caml_js_exceptions.internalToOCamlException(raw_error);
    return await Prelude.rejectWithError(error);
  }
  return {
          TAG: "Ok",
          _0: payload
        };
}

var RefreshMutex = Mutex.MakeThrottle({
      rate: {
        NAME: "Hz",
        VAL: 1
      },
      name: "RefreshTokenMutex",
      action: action
    });

function UserContext$AuthRefresher(props) {
  var setAuthContext = props.setAuthContext;
  var claims = props.claims;
  var tokens = props.tokens;
  var visibility = Hooks.useDocumentVisibility();
  var refresh = tokens.refresh;
  var access = tokens.access;
  var accessExpiresAt = React.useMemo((function () {
          return Luxon$1.DateTime.fromJSDate(access.parsed.exp);
        }), [access]);
  var accessExpiresIn = React.useMemo((function () {
          return accessExpiresAt.diffNow().toMillis();
        }), [
        accessExpiresAt,
        visibility
      ]);
  var refreshToken = React.useCallback((function () {
          var expiresIn = Luxon$1.DateTime.fromJSDate(access.parsed.exp).diffNow().toMillis();
          if (expiresIn <= 10000) {
            Prelude.PromisedResult.mapError(Prelude.PromisedResult.map(RefreshMutex.$$do(refresh.token), (function (response) {
                        var refresh = response.refresh;
                        var access = response.access;
                        var tokens = make(access, refresh);
                        if (tokens.TAG === "Ok") {
                          return setAuthContext({
                                      NAME: "logged",
                                      VAL: [
                                        tokens._0,
                                        claims
                                      ]
                                    });
                        }
                        console.warn("Cannot refresh tokens", {
                              access: access,
                              refresh: refresh
                            });
                      })), (function (param) {
                    setAuthContext("expired");
                  }));
            return ;
          }
          
        }), [
        claims,
        setAuthContext,
        refresh,
        access
      ]);
  var refreshIn = accessExpiresIn - 10000 | 0;
  var match = Hooks.useTimeout(refreshToken, refreshIn > 0 ? refreshIn : 1000);
  var clear = match.clear;
  var start = match.start;
  React.useEffect((function () {
          if (visibility === "visible") {
            start();
          } else {
            clear();
          }
          return (function () {
                    clear();
                  });
        }), [
        visibility,
        start,
        clear
      ]);
  if (accessExpiresIn >= 0) {
    return props.children;
  } else {
    return null;
  }
}

function UserContext(props) {
  var match = Hooks.useLocalStorage({
        key: "photocuba-tk",
        defaultValue: "unauthorized",
        deserialize: fromString,
        serialize: toString,
        getInitialValueInEffect: true
      });
  var setAuthContext = match[1];
  var auth = match[0];
  var accessTokenExpired = React.useMemo((function () {
          if (typeof auth === "object" && auth.NAME === "logged") {
            return Luxon.DateTime.isBefore(Luxon$1.DateTime.fromJSDate(auth.VAL[0].access.parsed.exp), Luxon$1.DateTime.now());
          } else {
            return false;
          }
        }), [auth]);
  var refreshTokenExpired = React.useMemo((function () {
          if (typeof auth === "object" && auth.NAME === "logged") {
            return Luxon.DateTime.isBefore(Luxon$1.DateTime.fromJSDate(auth.VAL[0].refresh.parsed.exp), Luxon$1.DateTime.now());
          } else {
            return false;
          }
        }), [auth]);
  var expired = React.useMemo((function () {
          if (accessTokenExpired) {
            return refreshTokenExpired;
          } else {
            return false;
          }
        }), [
        refreshTokenExpired,
        accessTokenExpired
      ]);
  React.useEffect((function () {
          if (expired && auth !== "unauthorized") {
            setAuthContext("expired");
          }
          
        }), [
        expired,
        auth
      ]);
  var login = async function (values) {
    var payload;
    try {
      payload = await AuthEndpoint.ObtainToken.$$do(values.username, values.password);
    }
    catch (raw_error){
      var error = Caml_js_exceptions.internalToOCamlException(raw_error);
      if (error.RE_EXN_ID === AuthEndpoint.InvalidUser) {
        return setAuthContext({
                    NAME: "errormsg",
                    VAL: error.message
                  });
      } else {
        console.warn("UserContext", error);
        return setAuthContext("error");
      }
    }
    var claims_user = payload.user;
    var claims_permissions = payload.permissions;
    var claims = {
      user: claims_user,
      permissions: claims_permissions
    };
    var tokens = Prelude.Result.warn(make(payload.access, payload.refresh));
    if (tokens !== undefined) {
      return setAuthContext({
                  NAME: "logged",
                  VAL: [
                    tokens,
                    claims
                  ]
                });
    } else {
      return setAuthContext("error");
    }
  };
  var changePassword = React.useMemo((function () {
          if (typeof auth !== "object") {
            return ;
          }
          if (auth.NAME !== "logged") {
            return ;
          }
          var match = auth.VAL;
          var claims = match[1];
          var tokens = match[0];
          return (async function (password, new_password) {
                    var e = await AccountEndpoint.ChangePassword.$$do(claims.user.username, password, new_password, tokens.access.token);
                    if (e.TAG === "Ok") {
                      setAuthContext("unauthorized");
                      return {
                              TAG: "Ok",
                              _0: undefined
                            };
                    }
                    console.error("UserContext", e._0);
                    return {
                            TAG: "Error",
                            _0: undefined
                          };
                  });
        }), [auth]);
  var tmp;
  if (typeof auth === "object") {
    if (auth.NAME === "errormsg") {
      tmp = JsxRuntime.jsx(LoginErrorPage.make, {
            msg: auth.VAL
          });
    } else {
      var match$1 = auth.VAL;
      tmp = JsxRuntime.jsx(UserContext$AuthRefresher, {
            tokens: match$1[0],
            claims: match$1[1],
            setAuthContext: setAuthContext,
            children: props.children
          });
    }
  } else {
    tmp = auth === "error" ? JsxRuntime.jsx(LoginErrorPage.make, {}) : (
        auth === "expired" ? JsxRuntime.jsx(LoginPage.make, {
                msg: {
                  NAME: "msg",
                  VAL: [
                    "login.message.expiration",
                    "Your session has expired and you will need to log in again."
                  ]
                }
              }) : JsxRuntime.jsx(LoginPage.make, {})
      );
  }
  return JsxRuntime.jsx(make$1, {
              value: auth,
              children: JsxRuntime.jsx(AuthManager.Provider.make, {
                    value: {
                      login: (function (values) {
                          return login(values);
                        }),
                      logout: (function () {
                          setAuthContext("unauthorized");
                        }),
                      changePassword: changePassword
                    },
                    children: tmp
                  })
            });
}

var make$2 = UserContext;

export {
  make$2 as make,
  useAccessToken ,
  useUserClaim ,
  useUserPermissionsClaim ,
}
/* include Not a pure module */
