// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../../libs/i18n/ReactI18Next.js";
import * as ReactI18next from "react-i18next";
import * as JsxRuntime from "react/jsx-runtime";
import Grid from "@mui/material/Grid";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import * as UpdateFolderInfoService from "../context/services/UpdateFolderInfoService.js";
import Typography from "@mui/material/Typography";
import * as ActiveApplicationService from "../../../global/context/ActiveApplicationService.js";

function FolderInfoPanel(props) {
  var folder = props.folder;
  var appName = ActiveApplicationService.useCurrentApplicationName();
  var name = React.useMemo((function () {
          var match = folder.parent;
          if (match !== undefined) {
            return JsxRuntime.jsx(ReactI18Next.Message.make, {
                        msg: {
                          NAME: "field",
                          VAL: folder.name
                        }
                      });
          } else {
            return JsxRuntime.jsx(ReactI18Next.Message.make, {
                        msg: appName
                      });
          }
        }), [
        folder,
        appName
      ]);
  var initialDesc = React.useMemo((function () {
          return Prelude.default(folder.description, "");
        }), [folder]);
  var match = React.useState(function () {
        return initialDesc;
      });
  var setDescription = match[1];
  var description = match[0];
  var handleDescriptionChange = function (ev) {
    var target = ev.currentTarget;
    var value;
    try {
      value = target.value;
    }
    catch (raw_e){
      var e = Caml_js_exceptions.internalToOCamlException(raw_e);
      console.error(e);
      return ;
    }
    if (value === undefined) {
      return ;
    }
    var value$1 = Caml_option.valFromOption(value);
    setDescription(function (param) {
          return value$1;
        });
  };
  var setFolderDescription = UpdateFolderInfoService.useSetDescription();
  var handleOnBlur = React.useCallback((function () {
          Prelude.OptionExported.$$Option.$$do(setFolderDescription, (function (fn) {
                  fn(folder, description);
                }));
        }), [
        folder,
        setFolderDescription,
        description
      ]);
  var setDescriptionDisabled = React.useMemo((function () {
          return !Curry._1(Prelude.OptionExported.$$Option.isSome, setFolderDescription);
        }), [setFolderDescription]);
  var match$1 = folder.parent;
  return JsxRuntime.jsx(Paper, {
              elevation: 2,
              sx: {
                borderRadius: "0.8rem",
                margin: "0.8rem 0",
                padding: "1rem"
              },
              children: Caml_option.some(JsxRuntime.jsxs(Grid, {
                        children: [
                          JsxRuntime.jsx(Grid, {
                                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                          variant: "h6",
                                          children: Caml_option.some(name),
                                          sx: {
                                            textAlign: "center"
                                          }
                                        })),
                                item: true
                              }),
                          JsxRuntime.jsx(Grid, {
                                children: Caml_option.some(JsxRuntime.jsxs(Grid, {
                                          children: [
                                            JsxRuntime.jsx(Grid, {
                                                  children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                            variant: "body2",
                                                            children: Caml_option.some(JsxRuntime.jsx(ReactI18next.Trans, {
                                                                      children: "Contents"
                                                                    }))
                                                          })),
                                                  item: true,
                                                  xs: 1
                                                }),
                                            JsxRuntime.jsx(Grid, {
                                                  children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                            variant: "body2",
                                                            children: Caml_option.some(JsxRuntime.jsx(ReactI18next.Trans, {
                                                                      i18nKey: "folderImageCount",
                                                                      children: "{{count}} images",
                                                                      count: new (Intl.NumberFormat)().format(folder.items)
                                                                    }))
                                                          })),
                                                  item: true,
                                                  xs: 1
                                                }),
                                            JsxRuntime.jsx(Grid, {
                                                  children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                            variant: "body2",
                                                            children: Caml_option.some(JsxRuntime.jsx(ReactI18next.Trans, {
                                                                      children: "Created at"
                                                                    }))
                                                          })),
                                                  item: true,
                                                  xs: 1
                                                }),
                                            JsxRuntime.jsx(Grid, {
                                                  children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                            variant: "body2",
                                                            children: Caml_option.some(folder.created_at.toLocaleDateString())
                                                          })),
                                                  item: true,
                                                  xs: 1
                                                }),
                                            JsxRuntime.jsx(Grid, {
                                                  children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                            variant: "body2",
                                                            children: Caml_option.some(JsxRuntime.jsx(ReactI18next.Trans, {
                                                                      children: "Updated at"
                                                                    }))
                                                          })),
                                                  item: true,
                                                  xs: 1
                                                }),
                                            JsxRuntime.jsx(Grid, {
                                                  children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                            variant: "body2",
                                                            children: Caml_option.some(folder.updated_at.toLocaleDateString())
                                                          })),
                                                  item: true,
                                                  xs: 1
                                                })
                                          ],
                                          columns: 2,
                                          container: true,
                                          spacing: 2,
                                          wrap: "wrap"
                                        })),
                                item: true
                              }),
                          match$1 !== undefined && !setDescriptionDisabled ? JsxRuntime.jsx(Grid, {
                                  children: Caml_option.some(JsxRuntime.jsx(TextField, {
                                            fullWidth: true,
                                            label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                      msg: {
                                                        NAME: "str",
                                                        VAL: "Description or purpose"
                                                      }
                                                    })),
                                            multiline: true,
                                            onChange: handleDescriptionChange,
                                            onBlur: (function (param) {
                                                handleOnBlur();
                                              }),
                                            maxRows: 15,
                                            minRows: 4,
                                            value: description,
                                            variant: "outlined"
                                          })),
                                  item: true
                                }) : null
                        ],
                        columns: 1,
                        container: true,
                        direction: "column",
                        spacing: 2
                      }))
            });
}

var make = FolderInfoPanel;

export {
  make ,
}
/* react Not a pure module */
