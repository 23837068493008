// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CollectionId from "../../models/CollectionId.js";
import * as ReactI18next from "react-i18next";
import Box from "@mui/material/Box";
import * as JsxRuntime from "react/jsx-runtime";
import * as CollectionsSelectionManager from "../../context/CollectionsSelectionManager.js";

function CollectionSelectionInfo(props) {
  var getCurrentSelectionIds = Curry._1(Prelude.OptionExported.$$Option.getExn, CollectionsSelectionManager.useGetCurrentSelectionIds());
  var count = React.useMemo((function () {
          return CollectionId.$$Set.size(getCurrentSelectionIds());
        }), [getCurrentSelectionIds]);
  if (count < 100) {
    return JsxRuntime.jsx(Box, {
                style: {
                  whiteSpace: "nowrap"
                },
                children: Caml_option.some(JsxRuntime.jsx(ReactI18next.Trans, {
                          i18nKey: "countSelected",
                          children: "{{count}} selected",
                          count: count
                        })),
                component: "span",
                sx: {
                  display: {
                    xs: "none",
                    lg: "inline"
                  }
                }
              });
  } else {
    return JsxRuntime.jsx(Box, {
                style: {
                  whiteSpace: "nowrap"
                },
                children: Caml_option.some(JsxRuntime.jsx(ReactI18next.Trans, {
                          children: "99+ selected"
                        })),
                component: "span",
                sx: {
                  display: {
                    xs: "none",
                    lg: "inline"
                  }
                }
              });
  }
}

var make = CollectionSelectionInfo;

export {
  make ,
}
/* react Not a pure module */
