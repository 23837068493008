// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";

function make(value) {
  return {
          value: value
        };
}

var fields = {
  TAG: "Object",
  _0: [[
      "value",
      "String"
    ]]
};

var Deserializer = $$JSON.MakeDeserializer({
      fields: fields
    });

function group(matches) {
  return Belt_SetString.toArray(Belt_SetString.fromArray(matches.map(function (param) {
                        return param.value;
                      }))).map(function (v) {
              return {
                      value: v
                    };
            });
}

function make$1(namespace, attribute) {
  return {
          namespace_id: namespace,
          attribute: attribute
        };
}

var Filters = {
  make: make$1
};

export {
  make ,
  Deserializer ,
  group ,
  Filters ,
}
/* Deserializer Not a pure module */
