// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../../../libs/i18n/ReactI18Next.js";
import * as JsxRuntime from "react/jsx-runtime";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as DocumentBrowserLabelText from "./DocumentBrowserLabelText.js";

function DocumentBrowserInfoDataText(props) {
  var value = props.value;
  return JsxRuntime.jsxs(Stack, {
              children: [
                JsxRuntime.jsx(DocumentBrowserLabelText.make, {
                      name: props.name
                    }),
                value !== undefined ? JsxRuntime.jsx(Typography, {
                        variant: "body2",
                        children: Caml_option.some(value)
                      }) : JsxRuntime.jsx(Typography, {
                        variant: "body2",
                        children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                  msg: {
                                    NAME: "str",
                                    VAL: "Unknown"
                                  }
                                })),
                        sx: {
                          fontStyle: "italic"
                        }
                      })
              ],
              spacing: "0.25rem",
              useFlexGap: true
            });
}

var make = DocumentBrowserInfoDataText;

export {
  make ,
}
/* ReactI18Next Not a pure module */
