// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$URL from "../../../utils/URL.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as FolderId from "../models/FolderId.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as NamespaceId from "../../../global/db/ids/NamespaceId.js";
import * as ReactI18Next from "../../../../libs/i18n/ReactI18Next.js";
import * as SharedDBContext from "../../../global/context/SharedDBContext.js";
import * as CatalogNavigator from "../context/CatalogNavigator.js";
import * as ReactRouterDom from "react-router-dom";
import * as JsxRuntime from "react/jsx-runtime";
import Link from "@mui/material/Link";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import Typography from "@mui/material/Typography";
import * as ActiveApplicationService from "../../../global/context/ActiveApplicationService.js";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNext from "@mui/icons-material/NavigateNext";

async function readParents(db, folder) {
  var path = folder.path;
  var ns = folder.namespace;
  var paths = Curry._3(Prelude.$$Array.rangeBy, 4, path.length - 4 | 0, 4).map(function (length) {
        return path.substr(0, length);
      });
  var match;
  if (Prelude.$$Array.isNotEmpty(paths)) {
    var init = db.makeRead();
    match = await db.read({
          documents: init.documents,
          folders: {
            TAG: "And",
            _0: {
              TAG: "AnyOf",
              _0: "path",
              _1: paths
            },
            _1: {
              TAG: "Is",
              _0: "namespace",
              _1: NamespaceId.toString(ns)
            }
          },
          bundlers: init.bundlers
        });
  } else {
    match = db.makeResponse();
  }
  return Prelude.$$Array.sort(Curry._2(Prelude.$$Array.keep, match.folders, (function (f) {
                    return Curry._1(Prelude.OptionExported.$$Option.isSome, f.parent);
                  })), (function (f) {
                return f.path;
              }), undefined);
}

function onClick(navigate) {
  return function (ev) {
    var findHref = function (_target, _depth) {
      while(true) {
        var depth = _depth;
        var target = _target;
        var val;
        var val$1;
        try {
          val = target.href;
          val$1 = target["data-norouter"];
        }
        catch (raw_e){
          var e = Caml_js_exceptions.internalToOCamlException(raw_e);
          console.error(e);
          return ;
        }
        if (val !== undefined) {
          if (val$1 !== undefined) {
            return ;
          } else {
            return val;
          }
        }
        if (depth >= 20) {
          return ;
        }
        var node = target.parentNode;
        if (node === undefined) {
          return ;
        }
        _depth = depth + 1 | 0;
        _target = Caml_option.valFromOption(node);
        continue ;
      };
    };
    var href = findHref(ev.target, 0);
    if (href === undefined) {
      return ;
    }
    ev.preventDefault();
    var $$location = window.location;
    var base = $$location.toString();
    var url = $$URL.makeWith(href, $$URL.fromString(base));
    navigate($$URL.pathname(url));
  };
}

function FolderBrowserBreadcrumbs(props) {
  var folder = props.folder;
  var match = React.useState(function () {
        return {
                TAG: "Init",
                _0: folder
              };
      });
  var setState = match[1];
  var state = match[0];
  var db = SharedDBContext.useQuery();
  React.useEffect((function () {
          if (state.TAG === "Init") {
            var folder$1 = state._0;
            Prelude.thenDo(readParents(db, folder$1), (function (parents) {
                    setState(function (param) {
                          return {
                                  TAG: "Loaded",
                                  folder: folder$1,
                                  parents: parents
                                };
                        });
                  }));
          } else if (Caml_obj.notequal(state.folder.id, folder.id)) {
            setState(function (param) {
                  return {
                          TAG: "Init",
                          _0: folder
                        };
                });
          }
          
        }), [
        state,
        folder
      ]);
  var navigate = ReactRouterDom.useNavigate();
  var onClick$1 = onClick(navigate);
  var getLink = CatalogNavigator.useLinkBuilder();
  var appLink = ActiveApplicationService.useCurrentApplicationLink();
  var appName = ActiveApplicationService.useCurrentApplicationName();
  var items = React.useMemo((function () {
          var items = [JsxRuntime.jsx(Link, {
                  color: "text.primary",
                  children: Caml_option.some(JsxRuntime.jsx(Typography, {
                            variant: "h5",
                            children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                      msg: appName
                                    }))
                          })),
                  underline: "hover",
                  href: appLink
                }, "/")];
          var parents;
          if (state.TAG === "Init") {
            parents = [];
          } else {
            var parents$1 = state.parents;
            var parents$2 = parents$1.length > 4 ? Curry._2(Prelude.$$Array.sliceToEnd, parents$1, parents$1.length - 4 | 0) : parents$1;
            parents = parents$2.map(function (f) {
                  return JsxRuntime.jsx(Link, {
                              color: "text.primary",
                              children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                        variant: "h5",
                                        children: Caml_option.some(f.name)
                                      })),
                              underline: "hover",
                              href: getLink(f)
                            }, FolderId.toString(f.id));
                });
          }
          var result = Curry._2(Prelude.$$Array.concat, items, parents);
          if (Curry._1(Prelude.OptionExported.$$Option.isSome, folder.parent)) {
            return Prelude.$$Array.append(result, JsxRuntime.jsx(Typography, {
                            color: "text.primary",
                            variant: "h5",
                            children: Caml_option.some(folder.name)
                          }, FolderId.toString(folder.id)));
          } else {
            return result;
          }
        }), [
        state,
        folder
      ]);
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Breadcrumbs, {
                    children: Caml_option.some(items),
                    itemsAfterCollapse: 2,
                    itemsBeforeCollapse: 2,
                    maxItems: 5,
                    separator: Caml_option.some(JsxRuntime.jsx(NavigateNext, {}))
                  }),
              onClick: onClick$1
            });
}

var make = FolderBrowserBreadcrumbs;

export {
  make ,
}
/* URL Not a pure module */
