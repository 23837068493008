// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../../libs/i18n/ReactI18Next.js";
import DarkPng from "/images/dark.png";
import LightPng from "/images/light.png";
import * as JsxRuntime from "react/jsx-runtime";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import * as UserPreferredColorScheme from "../../context/UserPreferredColorScheme.js";

function CenterBasePage(props) {
  var scheme = UserPreferredColorScheme.useResolvedScheme();
  var imgSrc = scheme === "dark" ? DarkPng : LightPng;
  return JsxRuntime.jsx(Paper, {
              children: Caml_option.some(JsxRuntime.jsxs(Container, {
                        children: [
                          JsxRuntime.jsx("img", {
                                style: {
                                  height: "4rem",
                                  margin: "3.2rem auto"
                                },
                                alt: "Photo Cuba",
                                src: imgSrc
                              }),
                          JsxRuntime.jsxs(Paper, {
                                sx: {
                                  padding: "0.5rem"
                                },
                                children: [
                                  JsxRuntime.jsxs(Stack, {
                                        alignItems: "center",
                                        children: [
                                          Prelude.default(props.icon, null),
                                          JsxRuntime.jsx(Typography, {
                                                variant: "h5",
                                                children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                          msg: props.title
                                                        }))
                                              })
                                        ],
                                        direction: ["row"],
                                        spacing: 1.5
                                      }),
                                  props.children
                                ]
                              })
                        ],
                        maxWidth: "sm",
                        sx: {
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          height: "100vh"
                        }
                      }))
            });
}

var make = CenterBasePage;

export {
  make ,
}
/* ReactI18Next Not a pure module */
