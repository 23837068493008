// Generated by ReScript, PLEASE EDIT WITH CARE

import * as IDB__Migration__Store from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Store.js";
import * as IDB__Migration__Database from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Database.js";

function make(param) {
  return function (db, param) {
    IDB__Migration__Database.createStandardStore(db, "folders", [
          "parent",
          "namespace",
          "created_at",
          "updated_at",
          "path"
        ]);
    var documents = IDB__Migration__Database.createStandardStore(db, "documents", [
          "folder",
          "namespace",
          "created_at",
          "updated_at",
          "name"
        ]);
    IDB__Migration__Store.createMultiEntryIndex(documents, "collections", "collections");
    return Promise.resolve({
                TAG: "Ok",
                _0: undefined
              });
  };
}

export {
  make ,
}
/* IDB__Migration__Store Not a pure module */
