// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as PhotocubaApp from "./global/apps/PhotocubaApp.js";
import * as Client from "react-dom/client";
import * as JsxRuntime from "react/jsx-runtime";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css'
;

Client.createRoot(Belt_Option.getExn(Caml_option.nullable_to_opt(document.querySelector("#app")))).render(JsxRuntime.jsx(PhotocubaApp.make, {}));

export {
  
}
/*  Not a pure module */
