// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";

var _map = {"country":"country","city":"city","event":"event","usage_terms":"usage_terms","country_code":"country_code","copyright":"copyright","sources":"sources","headline":"headline","authors":"authors","authors_job_title":"authors_job_title","height":"height","width":"width","custom_ifd0_orientation":"custom_ifd0_orientation","ifd0_orientation":"ifd0_orientation","gps_latitude":"gps_latitude","gps_longitude":"gps_longitude"};

function nameToJs(param) {
  return param;
}

function nameFromJs(param) {
  return _map[param];
}

function make(name, value) {
  return {
          attribute_name: name,
          value: value
        };
}

var fields = {
  TAG: "Object",
  _0: [
    [
      "attribute_name",
      $$JSON.Field.variadicString("attribute_name", nameFromJs)
    ],
    [
      "value",
      "String"
    ]
  ]
};

var Deserializer = $$JSON.MakeDeserializer({
      fields: fields
    });

var Value = {
  make: make,
  Deserializer: Deserializer
};

var fields$1 = {
  TAG: "Object",
  _0: [
    [
      "name",
      $$JSON.Field.variadicString("name", nameFromJs)
    ],
    [
      "values",
      {
        TAG: "Array",
        _0: "String"
      }
    ]
  ]
};

var Deserializer$1 = $$JSON.MakeDeserializer({
      fields: fields$1
    });

function map(payload) {
  return payload.values.map(function (value) {
              return {
                      attribute_name: payload.name,
                      value: value
                    };
            });
}

function values(__x) {
  return Prelude.$$Array.flatMap(__x, map);
}

var PayloadOutput = {
  Deserializer: Deserializer$1,
  map: map,
  values: values
};

function fromValues(values) {
  var result = {};
  Curry._2(Prelude.$$Array.forEach, values, (function (param) {
          var value = param.value;
          var attribute_name = param.attribute_name;
          var vals = Curry._2(Prelude.Dict.get, result, attribute_name);
          if (vals !== undefined) {
            vals.push(value);
          } else {
            result[attribute_name] = [value];
          }
        }));
  return Curry._2(Prelude.$$Array.keepMap, Curry._1(Prelude.Dict.entries, result), (function (param) {
                var values = param[1];
                return Curry._2(Prelude.OptionExported.$$Option.map, nameFromJs(param[0]), (function (name) {
                              return {
                                      name: name,
                                      values: values
                                    };
                            }));
              }));
}

var PayloadInput = {
  fromValues: fromValues
};

export {
  nameToJs ,
  nameFromJs ,
  Value ,
  PayloadOutput ,
  PayloadInput ,
}
/* Deserializer Not a pure module */
