// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ProtectedArea from "../../../../utils/ProtectedArea.js";
import * as DeleteItemDialog from "../affordances/DeleteItemDialog.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DocumentDownloader from "../../context/services/DocumentDownloader.js";
import * as RemoveDocumentService from "../../context/services/RemoveDocumentService.js";
import Flip from "@mui/icons-material/Flip";
import IconButton from "@mui/material/IconButton";
import ButtonGroup from "@mui/material/ButtonGroup";
import * as CatalogDeleteVariantContext from "../../context/CatalogDeleteVariantContext.js";
import RotateLeft from "@mui/icons-material/RotateLeft";
import RotateRight from "@mui/icons-material/RotateRight";
import DeleteForever from "@mui/icons-material/DeleteForever";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import CloudDownloadOutlined from "@mui/icons-material/CloudDownloadOutlined";

function DocumentBrowserActions(props) {
  var onFlip = props.onFlip;
  var onRotateRight = props.onRotateRight;
  var onRotateLeft = props.onRotateLeft;
  var onDeleteDocument = props.onDeleteDocument;
  var $$document = props.document;
  var getLink = DocumentDownloader.useGetDocumentDownloadLink();
  var getOnClick = DocumentDownloader.useGetDocumentDownloadOnClick();
  var link = Curry._2(Prelude.OptionExported.$$Option.flatMap, getLink, (function (fn) {
          return fn($$document);
        }));
  var onClick = Curry._2(Prelude.OptionExported.$$Option.map, getOnClick, (function (fn) {
          return fn($$document, true);
        }));
  var deleteVariant = CatalogDeleteVariantContext.use();
  var match = React.useState(function () {
        
      });
  var setDeleteDocument = match[1];
  var deleteDocument = match[0];
  var deleteAvailable = RemoveDocumentService.available();
  var tmp;
  switch (deleteVariant) {
    case "RemoveForever" :
        tmp = JsxRuntime.jsx(DeleteForever, {});
        break;
    case "MoveToTrash" :
    case "Disallowed" :
        tmp = JsxRuntime.jsx(DeleteOutlined, {});
        break;
    
  }
  return JsxRuntime.jsxs(ButtonGroup, {
              children: [
                JsxRuntime.jsx(IconButton, {
                      children: Caml_option.some(JsxRuntime.jsx(CloudDownloadOutlined, {})),
                      onClick: (function (ev) {
                          Prelude.OptionExported.$$Option.$$do(onClick, (function (fn) {
                                  fn(ev);
                                }));
                        }),
                      disabled: Curry._1(Prelude.OptionExported.$$Option.isNone, link) || Curry._1(Prelude.OptionExported.$$Option.isNone, onClick)
                    }),
                JsxRuntime.jsxs(ProtectedArea.make, {
                      requirement: {
                        TAG: "Perms",
                        _0: {
                          TAG: "All",
                          _0: ["catalog.change_document"]
                        }
                      },
                      children: [
                        JsxRuntime.jsx(IconButton, {
                              children: Caml_option.some(JsxRuntime.jsx(Flip, {})),
                              onClick: (function (param) {
                                  Prelude.OptionExported.$$Option.$$do(onFlip, (function (fn) {
                                          fn();
                                        }));
                                }),
                              disabled: Curry._1(Prelude.OptionExported.$$Option.isNone, onFlip)
                            }),
                        JsxRuntime.jsx(IconButton, {
                              children: Caml_option.some(JsxRuntime.jsx(RotateLeft, {})),
                              onClick: (function (param) {
                                  Prelude.OptionExported.$$Option.$$do(onRotateLeft, (function (fn) {
                                          fn();
                                        }));
                                }),
                              disabled: Curry._1(Prelude.OptionExported.$$Option.isNone, onRotateLeft)
                            }),
                        JsxRuntime.jsx(IconButton, {
                              children: Caml_option.some(JsxRuntime.jsx(RotateRight, {})),
                              onClick: (function (param) {
                                  Prelude.OptionExported.$$Option.$$do(onRotateRight, (function (fn) {
                                          fn();
                                        }));
                                }),
                              disabled: Curry._1(Prelude.OptionExported.$$Option.isNone, onRotateRight)
                            }),
                        JsxRuntime.jsx(IconButton, {
                              children: Caml_option.some(tmp),
                              onClick: (function (param) {
                                  setDeleteDocument(function (param) {
                                        return $$document;
                                      });
                                }),
                              disabled: !deleteAvailable || deleteVariant === "Disallowed"
                            }),
                        deleteDocument !== undefined ? JsxRuntime.jsx(DeleteItemDialog.make, {
                                item: {
                                  NAME: "document",
                                  VAL: deleteDocument
                                },
                                variant: deleteVariant,
                                onDone: (function () {
                                    Prelude.OptionExported.$$Option.$$do(onDeleteDocument, (function (fn) {
                                            fn();
                                          }));
                                    setDeleteDocument(function (param) {
                                          
                                        });
                                  }),
                                onClose: (function () {
                                    setDeleteDocument(function (param) {
                                          
                                        });
                                  })
                              }) : null
                      ]
                    })
              ]
            });
}

var make = DocumentBrowserActions;

export {
  make ,
}
/* react Not a pure module */
