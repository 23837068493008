// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_MapInt from "rescript/lib/es6/belt_MapInt.js";
import * as Belt_SetInt from "rescript/lib/es6/belt_SetInt.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TagsManager from "../../context/TagsManager.js";
import * as ReactI18Next from "../../../../../libs/i18n/ReactI18Next.js";
import * as ReactDOMStyle from "@rescript/react/lib/es6/src/ReactDOMStyle.js";
import * as ReactI18next from "react-i18next";
import * as ActionsManager from "../../../../global/context/ActionsManager.js";
import * as FeedbackDelayed from "../../../../global/components/FeedbackDelayed.js";
import * as TagsListService from "../../context/TagsListService.js";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import * as JsxRuntime from "react/jsx-runtime";
import Stack from "@mui/material/Stack";
import * as ConfirmDeleteDialog from "../../../../global/components/ConfirmDeleteDialog.js";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import * as TagsSelectionManager from "../../context/TagsSelectionManager.js";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Merge from "@mui/icons-material/Merge";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import LinearProgress from "@mui/material/LinearProgress";
import DialogContentText from "@mui/material/DialogContentText";
import CancelRounded from "@mui/icons-material/CancelRounded";
import DeleteForever from "@mui/icons-material/DeleteForever";

function TagsSelectionAffordance$ClearSelection(props) {
  var reset = Curry._1(Prelude.OptionExported.$$Option.getExn, TagsSelectionManager.useReset());
  return JsxRuntime.jsx(Tooltip, {
              children: JsxRuntime.jsx(IconButton, {
                    children: Caml_option.some(JsxRuntime.jsx(CancelRounded, {})),
                    onClick: (function (param) {
                        reset();
                      })
                  }),
              title: JsxRuntime.jsx(ReactI18next.Trans, {
                    i18nKey: "clearSelection",
                    children: "Clear tags selection"
                  })
            });
}

function TagsSelectionAffordance$SelectionInfo(props) {
  var getCurrentSelectionIds = Curry._1(Prelude.OptionExported.$$Option.getExn, TagsSelectionManager.useGetCurrentSelectionIds());
  var count = React.useMemo((function () {
          return Belt_SetInt.size(getCurrentSelectionIds());
        }), [getCurrentSelectionIds]);
  if (count < 100) {
    return JsxRuntime.jsx(Box, {
                style: {
                  whiteSpace: "nowrap"
                },
                children: Caml_option.some(JsxRuntime.jsx(ReactI18next.Trans, {
                          i18nKey: "countSelected",
                          children: "{{count}} selected",
                          count: count
                        })),
                component: "span",
                sx: {
                  display: {
                    xs: "none",
                    md: "inline"
                  }
                }
              });
  } else {
    return JsxRuntime.jsx(Box, {
                style: {
                  whiteSpace: "nowrap"
                },
                children: Caml_option.some(JsxRuntime.jsx(ReactI18next.Trans, {
                          children: "99+ selected"
                        })),
                component: "span",
                sx: {
                  display: {
                    xs: "none",
                    lg: "inline"
                  }
                }
              });
  }
}

function TagsSelectionAffordance$BulkRemove$ConfirmDialog(props) {
  var count = props.items.length;
  var content = count > 0 ? JsxRuntime.jsx(ReactI18next.Trans, {
          i18nKey: "tags-manager.bulk-remove.confirmation-message",
          children: "Are you sure you want to remove {{count}} tags?",
          count: count
        }) : null;
  var content$1 = count > 1 ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx(DialogContentText, {
                  children: Caml_option.some(content)
                }),
            JsxRuntime.jsx(DialogContentText, {
                  children: Caml_option.some(JsxRuntime.jsxs(ReactI18next.Trans, {
                            i18nKey: "tags-manager.bulk-remove.enter-delete",
                            children: [
                              "To proceed you must enter the word ",
                              JsxRuntime.jsx("strong", {
                                    children: "DELETE"
                                  }),
                              " below."
                            ]
                          }))
                })
          ]
        }) : content;
  var match = ReactI18next.useTranslation();
  var confirmMessage = count > 1 ? match.t("DELETE") : undefined;
  return JsxRuntime.jsx(ConfirmDeleteDialog.make, {
              open_: true,
              content: Caml_option.some(content$1),
              onCancel: props.onCancel,
              onDelete: props.onDelete,
              confirmMessage: confirmMessage,
              fullWidth: true,
              maxWidth: "sm"
            });
}

function TagsSelectionAffordance$BulkRemove(props) {
  var match = React.useState(function () {
        return "Idle";
      });
  var setState = match[1];
  var state = match[0];
  var getSelection = Curry._1(Prelude.OptionExported.$$Option.getExn, TagsSelectionManager.useGetCurrentSelection());
  var reset = Curry._1(Prelude.OptionExported.$$Option.getExn, TagsSelectionManager.useReset());
  var refresh = TagsListService.useRefresh();
  var bulkRemove = Curry._1(Prelude.OptionExported.$$Option.getExn, TagsManager.useBulkRemoveTags());
  React.useEffect((function () {
          if (typeof state === "object" && state.TAG !== "Confirmation") {
            Prelude.PromisedResult.map(bulkRemove(state._0), (function () {
                    reset();
                    refresh();
                    setState(function (param) {
                          return "Idle";
                        });
                  }));
          }
          
        }), [
        state,
        bulkRemove,
        reset,
        refresh
      ]);
  var onCancel = React.useCallback((function () {
          setState(function (param) {
                return "Idle";
              });
        }), [setState]);
  var onDeleteConfirmed = React.useCallback((function () {
          if (typeof state !== "object") {
            return ;
          }
          if (state.TAG !== "Confirmation") {
            return ;
          }
          var items = state._0;
          setState(function (param) {
                return {
                        TAG: "Removing",
                        _0: items
                      };
              });
        }), [
        state,
        setState
      ]);
  var tmp;
  tmp = typeof state !== "object" ? null : (
      state.TAG === "Confirmation" ? JsxRuntime.jsx(TagsSelectionAffordance$BulkRemove$ConfirmDialog, {
              items: state._0,
              onCancel: onCancel,
              onDelete: onDeleteConfirmed
            }) : JsxRuntime.jsx(FeedbackDelayed.make, {
              children: JsxRuntime.jsxs(Dialog, {
                    open: true,
                    children: [
                      JsxRuntime.jsx(DialogTitle, {
                            children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                      msg: {
                                        NAME: "msg",
                                        VAL: [
                                          "tags.bulk-removing",
                                          "Removing tags"
                                        ]
                                      }
                                    }))
                          }),
                      JsxRuntime.jsx(DialogContent, {
                            children: Caml_option.some(JsxRuntime.jsx(LinearProgress, {}))
                          })
                    ],
                    fullWidth: true,
                    maxWidth: "sm"
                  }),
              fallback: Caml_option.some(null),
              delay: 300
            })
    );
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Fab, {
                            children: Caml_option.some(JsxRuntime.jsx(DeleteForever, {})),
                            color: "error",
                            size: "small"
                          }),
                      onClick: (function (param) {
                          var tags = Belt_MapInt.valuesToArray(getSelection());
                          setState(function (param) {
                                return {
                                        TAG: "Confirmation",
                                        _0: tags
                                      };
                              });
                        })
                    }),
                tmp
              ]
            });
}

function TagsSelectionAffordance$BulkMerge$MergeDialog(props) {
  var onMerge = props.onMerge;
  var onCancel = props.onCancel;
  var items = props.items;
  var match = React.useState(function () {
        
      });
  var setSelected = match[1];
  var selected = match[0];
  var match$1 = React.useState(function () {
        return items;
      });
  var setOthers = match$1[1];
  var others = match$1[0];
  return JsxRuntime.jsxs(Dialog, {
              open: true,
              children: [
                JsxRuntime.jsx(DialogTitle, {
                      children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                msg: {
                                  NAME: "str",
                                  VAL: "Merge tags"
                                }
                              }))
                    }),
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                children: [
                                  JsxRuntime.jsx(Typography, {
                                        variant: "body1",
                                        children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                  msg: {
                                                    NAME: "msg",
                                                    VAL: [
                                                      "tags-manager.bulk-merge-description",
                                                      "Select the tag to keep.  Notice that this operation\n                   cannot be reversed."
                                                    ]
                                                  }
                                                }))
                                      }),
                                  JsxRuntime.jsx(Autocomplete, {
                                        options: items,
                                        renderInput: (function (params) {
                                            var newrecord = Caml_obj.obj_dup(params);
                                            return JsxRuntime.jsx(TextField, (newrecord.variant = "outlined", newrecord.label = Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                                  msg: {
                                                                    NAME: "str",
                                                                    VAL: "Tag"
                                                                  }
                                                                })), newrecord));
                                          }),
                                        fullWidth: true,
                                        getOptionLabel: (function (tag) {
                                            return tag.name;
                                          }),
                                        onChange: (function (param, value, reason, param$1) {
                                            switch (reason) {
                                              case "selectOption" :
                                                  setSelected(function (param) {
                                                        return value;
                                                      });
                                                  return setOthers(function (param) {
                                                              return Curry._2(Prelude.$$Array.keep, items, (function (it) {
                                                                            return Caml_obj.notequal(it.id, value.id);
                                                                          }));
                                                            });
                                              case "clear" :
                                                  setSelected(function (param) {
                                                        
                                                      });
                                                  return setOthers(function (param) {
                                                              return items;
                                                            });
                                              default:
                                                return ;
                                            }
                                          })
                                      })
                                ],
                                spacing: 2.0
                              }))
                    }),
                JsxRuntime.jsxs(DialogActions, {
                      children: [
                        JsxRuntime.jsx(Button, {
                              children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                        msg: {
                                          NAME: "msg",
                                          VAL: [
                                            "buttons.cancel",
                                            "Cancel"
                                          ]
                                        }
                                      })),
                              onClick: (function (param) {
                                  onCancel();
                                }),
                              variant: "text"
                            }),
                        JsxRuntime.jsx(Button, {
                              children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                        msg: {
                                          NAME: "str",
                                          VAL: "Merge"
                                        }
                                      })),
                              onClick: (function (param) {
                                  onMerge(Curry._1(Prelude.OptionExported.$$Option.getExn, selected), others);
                                }),
                              color: "error",
                              disabled: Curry._1(Prelude.OptionExported.$$Option.isNone, selected),
                              variant: "text"
                            })
                      ]
                    })
              ],
              fullWidth: true,
              maxWidth: "sm"
            });
}

function TagsSelectionAffordance$BulkMerge$MergingDialog(props) {
  return JsxRuntime.jsxs(Dialog, {
              open: true,
              children: [
                JsxRuntime.jsx(DialogTitle, {
                      children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                msg: {
                                  NAME: "str",
                                  VAL: "Merge tags"
                                }
                              }))
                    }),
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                children: [
                                  JsxRuntime.jsx(Typography, {
                                        variant: "body1",
                                        children: Caml_option.some(JsxRuntime.jsx(ReactI18next.Trans, {
                                                  i18nKey: "tags-manager.bulk-merging",
                                                  children: "Merging {{count}} tags together.",
                                                  count: props.branches.length + 1 | 0
                                                }))
                                      }),
                                  JsxRuntime.jsx(LinearProgress, {})
                                ],
                                spacing: 2.0
                              }))
                    })
              ],
              fullWidth: true,
              maxWidth: "sm"
            });
}

function TagsSelectionAffordance$BulkMerge(props) {
  var match = React.useState(function () {
        return "Idle";
      });
  var setState = match[1];
  var state = match[0];
  var refresh = TagsListService.useRefresh();
  var reset = Curry._1(Prelude.OptionExported.$$Option.getExn, TagsSelectionManager.useReset());
  var getSelection = Curry._1(Prelude.OptionExported.$$Option.getExn, TagsSelectionManager.useGetCurrentSelection());
  var items = React.useMemo((function () {
          return Belt_MapInt.valuesToArray(getSelection());
        }), [getSelection]);
  var count = React.useMemo((function () {
          return items.length;
        }), [items]);
  var disabled = React.useMemo((function () {
          return count < 2;
        }), [count]);
  var merge = Curry._1(Prelude.OptionExported.$$Option.getExn, TagsManager.useMergeTags());
  var onCancel = React.useCallback((function () {
          setState(function (param) {
                return "Idle";
              });
        }), [setState]);
  var onMerge = React.useCallback((function (selected, others) {
          setState(function (param) {
                return {
                        TAG: "Selected",
                        _0: selected,
                        _1: others
                      };
              });
        }), [setState]);
  React.useEffect((function () {
          if (typeof state === "object" && state.TAG === "Selected") {
            var others = state._1;
            var tag = state._0;
            var promise = Prelude.then(Prelude.Promises.ellapsed(50), (function () {
                    return merge(tag, others);
                  }));
            setState(function (param) {
                  return {
                          TAG: "Merging",
                          _0: others,
                          _1: promise
                        };
                });
          }
          
        }), [state]);
  React.useEffect((function () {
          if (typeof state === "object" && state.TAG === "Merging") {
            Prelude.PromisedResult.map(state._1, (function (param) {
                    refresh();
                    reset();
                    setState(function (param) {
                          return "Idle";
                        });
                  }));
          }
          
        }), [
        state,
        refresh,
        reset
      ]);
  var tmp;
  if (typeof state !== "object") {
    tmp = null;
  } else {
    switch (state.TAG) {
      case "Select" :
          tmp = JsxRuntime.jsx(TagsSelectionAffordance$BulkMerge$MergeDialog, {
                items: state._0,
                onCancel: onCancel,
                onMerge: onMerge
              });
          break;
      case "Selected" :
          tmp = JsxRuntime.jsx(TagsSelectionAffordance$BulkMerge$MergingDialog, {
                branches: state._1
              });
          break;
      case "Merging" :
          tmp = JsxRuntime.jsx(TagsSelectionAffordance$BulkMerge$MergingDialog, {
                branches: state._0
              });
          break;
      
    }
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(IconButton, {
                      children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                children: JsxRuntime.jsx(Merge, {}),
                                title: JsxRuntime.jsx(ReactI18Next.Message.make, {
                                      msg: {
                                        NAME: "str",
                                        VAL: "Merge tags"
                                      }
                                    })
                              })),
                      onClick: (function (param) {
                          setState(function (param) {
                                return {
                                        TAG: "Select",
                                        _0: items
                                      };
                              });
                        }),
                      disabled: disabled
                    }),
                tmp
              ]
            });
}

function TagsSelectionAffordance$SelectionMenu(props) {
  var getCurrentSelectionIds = Curry._1(Prelude.OptionExported.$$Option.getExn, TagsSelectionManager.useGetCurrentSelectionIds());
  var count = React.useMemo((function () {
          return Belt_SetInt.size(getCurrentSelectionIds());
        }), [getCurrentSelectionIds]);
  if (count <= 0) {
    return null;
  }
  var style = ReactDOMStyle.unsafeAddProp({
        cursor: "default",
        userSelect: "none"
      }, "WebkitUserSelect", "none");
  return JsxRuntime.jsxs(Stack, {
              style: style,
              bgcolor: "action.selected",
              children: [
                JsxRuntime.jsx(TagsSelectionAffordance$ClearSelection, {}),
                JsxRuntime.jsx(TagsSelectionAffordance$SelectionInfo, {}),
                JsxRuntime.jsx(TagsSelectionAffordance$BulkMerge, {}),
                JsxRuntime.jsx(TagsSelectionAffordance$BulkRemove, {})
              ],
              direction: ["row"],
              spacing: "0.5rem",
              sx: {
                borderRadius: "9999px",
                alignItems: "center"
              }
            });
}

function TagsSelectionAffordance(props) {
  return JsxRuntime.jsx(ActionsManager.Register.make, {
              name: "tags-selection-menu",
              widget: (function (key) {
                  return JsxRuntime.jsx(TagsSelectionAffordance$SelectionMenu, {}, key);
                }),
              sequence: 500,
              children: props.children
            });
}

var make = TagsSelectionAffordance;

export {
  make ,
}
/* react Not a pure module */
