// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Belt_MutableMapString from "rescript/lib/es6/belt_MutableMapString.js";

function group(matches) {
  var result = Belt_MutableMapString.make();
  Curry._2(Prelude.$$Array.forEach, matches, (function (match) {
          var group = Belt_MutableMapString.getWithDefault(result, match.label, {
                label: match.label,
                matches: [],
                names: []
              });
          var group_label = match.label;
          var group_matches = Curry._2(Prelude.$$Array.concat, group.matches, match.matches);
          var group_names = Curry._2(Prelude.$$Array.concat, group.names, match.names);
          var group$1 = {
            label: group_label,
            matches: group_matches,
            names: group_names
          };
          Belt_MutableMapString.set(result, match.label, group$1);
        }));
  return Belt_MutableMapString.valuesToArray(result);
}

var fields = {
  TAG: "Object",
  _0: [
    [
      "label",
      "String"
    ],
    [
      "matches",
      {
        TAG: "Array",
        _0: "String"
      }
    ],
    [
      "names",
      {
        TAG: "Array",
        _0: "String"
      }
    ]
  ]
};

var Deserializer = $$JSON.MakeDeserializer({
      fields: fields
    });

function $$default(namespace) {
  return {
          namespace_id: namespace,
          allow_trash: false
        };
}

var Filters = {
  $$default: $$default
};

export {
  group ,
  Deserializer ,
  Filters ,
}
/* Deserializer Not a pure module */
