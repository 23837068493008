// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_MapInt from "rescript/lib/es6/belt_MapInt.js";
import * as DragSelection from "../../catalog/components/DragSelection.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as AccountsSelectionManager from "../context/AccountsSelectionManager.js";

function WithAccountsLassoSelector(props) {
  var children = props.children;
  var accounts = props.accounts;
  var accounts$1 = React.useMemo((function () {
          return Prelude.default(accounts, []);
        }), [accounts]);
  var getCurrentSelection = AccountsSelectionManager.useGetCurrentSelection();
  var getCurrentSelection$1 = React.useMemo((function () {
          return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, getCurrentSelection, (function (fn) {
                            return function () {
                              return Belt_SetString.fromArray(Belt_MapInt.keysToArray(fn()).map(function (x) {
                                              return String(x);
                                            }));
                            };
                          })), (function () {
                        
                      }));
        }), [getCurrentSelection]);
  var toggle = AccountsSelectionManager.useToggle();
  var reset = AccountsSelectionManager.useReset();
  var toggleId = React.useCallback((function (id) {
          Prelude.OptionExported.$$Option.$$do(accounts$1.find(function (d) {
                    return Caml_obj.equal(d.id, id);
                  }), (function (obj) {
                  Prelude.OptionExported.$$Option.$$do(toggle, (function (fn) {
                          fn([obj]);
                        }));
                }));
        }), [
        toggle,
        accounts$1
      ]);
  var select = React.useCallback((function (ids) {
          Prelude.OptionExported.$$Option.$$do(reset, (function (fn) {
                  fn();
                }));
          Belt_SetString.forEach(ids, (function (id) {
                  Prelude.OptionExported.$$Option.$$do(Curry._2(Prelude.OptionExported.$$Option.map, Curry._1(Prelude.Int.fromString, id), (function (prim) {
                              return prim;
                            })), (function (id) {
                          toggleId(id);
                        }));
                }));
        }), [
        reset,
        toggleId
      ]);
  var enabled = Prelude.$$Array.isNotEmpty(accounts$1) && Curry._1(Prelude.OptionExported.$$Option.isSome, toggle);
  if (enabled) {
    return JsxRuntime.jsx(DragSelection.make, {
                children: children,
                onDragSelection: select,
                getCurrentSelection: getCurrentSelection$1,
                appContainer: ".selection-lasso-scroll-container",
                itemSelector: [".selectable-item[id]"]
              });
  } else {
    return children;
  }
}

var make = WithAccountsLassoSelector;

export {
  make ,
}
/* react Not a pure module */
