// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as APIMaker from "../../utils/APIMaker.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as NamespaceId from "../db/ids/NamespaceId.js";

var fields = {
  TAG: "Object",
  _0: [
    [
      "id",
      "String"
    ],
    [
      "name",
      "String"
    ],
    [
      "root_folder_id",
      "String"
    ],
    [
      "trash_folder_id",
      "String"
    ],
    [
      "trash_residency_days",
      "Int"
    ]
  ]
};

var D = $$JSON.MakeDeserializer({
      fields: fields
    });

var url = "ns";

var Definition = {
  url: url,
  Deserializer: undefined
};

var include = APIMaker.MakeBackendReadonlyAPIEndpoint({
      url: url,
      Deserializer: D
    });

var Deserializer = include.Deserializer;

var Get_UnexpectedAPIPayload = include.UnexpectedAPIPayload;

var Get_getMany = include.getMany;

var Get_getAll = include.getAll;

var Get_getAllWithPost = include.getAllWithPost;

var Get_get = include.get;

var Get_options = include.options;

var Get = {
  D: D,
  Definition: Definition,
  Deserializer: Deserializer,
  UnexpectedAPIPayload: Get_UnexpectedAPIPayload,
  getMany: Get_getMany,
  getAll: Get_getAll,
  getAllWithPost: Get_getAllWithPost,
  get: Get_get,
  options: Get_options
};

var Impl = APIMaker.MakeBackendAPIEndpoint({
      url: "/ns",
      Deserializer: Deserializer
    });

function $$do(ns, token) {
  var duration = ns.trash_residency_days;
  if (duration === undefined) {
    return Promise.resolve({
                TAG: "Ok",
                _0: ns
              });
  }
  var operation_id = NamespaceId.toString(ns.id);
  var operation = {
    id: operation_id,
    trash_residency_days: duration
  };
  return Impl.postOne(undefined, Caml_option.some(token), operation);
}

var Update = {
  Impl: Impl,
  $$do: $$do
};

export {
  Get ,
  Update ,
}
/* D Not a pure module */
