// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../libs/i18n/ReactI18Next.js";
import * as JsxRuntime from "react/jsx-runtime";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function PasswordInput(props) {
  var setShowPassword = props.setShowPassword;
  var showPassword = props.showPassword;
  var disabled = props.disabled;
  var onChange = props.onChange;
  var match = React.useState(function () {
        return false;
      });
  var setShowPasswordInternal = match[1];
  var showPasswordInternal = match[0];
  return JsxRuntime.jsx(TextField, {
              autoFocus: props.autoFocus,
              disabled: disabled,
              error: props.error,
              fullWidth: props.fullWidth,
              InputProps: {
                endAdornment: Caml_option.some(JsxRuntime.jsx(InputAdornment, {
                          position: "end",
                          children: Caml_option.some(JsxRuntime.jsx(IconButton, {
                                    children: Caml_option.some(Prelude.default(showPassword, showPasswordInternal) ? JsxRuntime.jsx(VisibilityOff, {}) : JsxRuntime.jsx(Visibility, {})),
                                    onClick: (function (param) {
                                        Prelude.default(setShowPassword, setShowPasswordInternal)(function (v) {
                                              return !v;
                                            });
                                      }),
                                    disabled: disabled
                                  }))
                        }))
              },
              label: Caml_option.some(Prelude.default(props.label, JsxRuntime.jsx(ReactI18Next.Message.make, {
                            msg: {
                              NAME: "msg",
                              VAL: [
                                "inputs.password",
                                "Password"
                              ]
                            }
                          }))),
              name: props.name,
              onChange: (function (ev) {
                  var value = ev.target.value;
                  onChange(value);
                }),
              required: props.required,
              size: props.size,
              sx: props.sx,
              type: Prelude.default(showPassword, showPasswordInternal) ? "text" : "password",
              value: props.value,
              variant: props.variant
            });
}

var make = PasswordInput;

export {
  make ,
}
/* react Not a pure module */
