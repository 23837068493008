// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as FolderId from "../../models/FolderId.js";
import * as UserContext from "../../../../global/context/UserContext.js";
import * as UserPermission from "../../../../global/models/UserPermission.js";
import * as SharedDBContext from "../../../../global/context/SharedDBContext.js";
import * as SelectionManager from "../SelectionManager.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CatalogAPIEndpoint from "../../api/CatalogAPIEndpoint.js";
import * as CurrentFolderManager from "../CurrentFolderManager.js";

var context = React.createContext(undefined);

var make = context.Provider;

function useClearFolder() {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, React.useContext(context), (function (param) {
                return param.clear;
              }));
}

function EmptyFolderService(props) {
  var permissions = UserContext.useUserPermissionsClaim();
  var allowEmpty = React.useMemo((function () {
          var query = {
            TAG: "Any",
            _0: ["catalog.change_folder"]
          };
          var allowEmpty = UserPermission.Query.matches(query, permissions);
          console.log("EmptyFolderService", "allow empty", allowEmpty);
          return allowEmpty;
        }), [permissions]);
  var currentFolder = CurrentFolderManager.useCurrentFolder();
  var clearCurrentFolder = CurrentFolderManager.useClear();
  var unselectFolders = SelectionManager.Folders.useUnselect();
  var unselectDocuments = SelectionManager.Documents.useUnselect();
  var token = Curry._1(Prelude.OptionExported.$$Option.getExn, UserContext.useAccessToken());
  var match = SharedDBContext.useQuery();
  var write = match.write;
  var read = match.read;
  var makeWrite = match.makeWrite;
  var makeRead = match.makeRead;
  var clear = React.useMemo((function () {
          if (allowEmpty) {
            return (async function (folder) {
                      var originalFolderPath = folder.path;
                      var folder$1 = await CatalogAPIEndpoint.ClearFolder.$$do(folder, token);
                      if (folder$1.TAG !== "Ok") {
                        return {
                                TAG: "Error",
                                _0: folder$1._0[1]
                              };
                      }
                      var folder$2 = folder$1._0;
                      if (Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, currentFolder, (function (f) {
                                    return Caml_obj.equal(f.id, folder$2.id);
                                  })), false)) {
                        clearCurrentFolder();
                      }
                      var init = makeRead();
                      var match = await read({
                            documents: init.documents,
                            folders: {
                              TAG: "StartsWith",
                              _0: "path",
                              _1: originalFolderPath
                            },
                            bundlers: init.bundlers
                          });
                      var children = match.folders;
                      var init$1 = makeRead();
                      var match$1 = await read({
                            documents: {
                              TAG: "Or",
                              _0: {
                                TAG: "Is",
                                _0: "folder",
                                _1: FolderId.toString(folder$2.id)
                              },
                              _1: {
                                TAG: "AnyOf",
                                _0: "folder",
                                _1: FolderId.manyToString(children.map(function (f) {
                                          return f.id;
                                        }))
                              }
                            },
                            folders: init$1.folders,
                            bundlers: init$1.bundlers
                          });
                      var documents = match$1.documents;
                      Prelude.OptionExported.$$Option.$$do(unselectFolders, (function (fn) {
                              fn(children);
                            }));
                      Prelude.OptionExported.$$Option.$$do(unselectDocuments, (function (fn) {
                              fn(documents);
                            }));
                      var init$2 = makeWrite();
                      await write({
                            documents: documents.map(function (param) {
                                  return {
                                          TAG: "Delete",
                                          _0: param.id
                                        };
                                }),
                            folders: children.map(function (param) {
                                  return {
                                          TAG: "Delete",
                                          _0: param.id
                                        };
                                }),
                            bundlers: init$2.bundlers
                          });
                      return {
                              TAG: "Ok",
                              _0: undefined
                            };
                    });
          }
          
        }), [
        token,
        makeWrite,
        write,
        makeRead,
        read,
        unselectFolders,
        unselectDocuments,
        allowEmpty,
        currentFolder,
        clearCurrentFolder
      ]);
  return JsxRuntime.jsx(make, {
              value: {
                clear: clear
              },
              children: props.children
            });
}

var make$1 = EmptyFolderService;

export {
  useClearFolder ,
  make$1 as make,
}
/* context Not a pure module */
