// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as FolderId from "../models/FolderId.js";
import * as FolderCard from "./FolderCard.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DropdownMenu from "../../../global/components/DropdownMenu.js";
import * as ReactI18Next from "../../../../libs/i18n/ReactI18Next.js";
import * as FlexGrowSpacer from "../../../global/components/FlexGrowSpacer.js";
import * as SelectionManager from "../context/SelectionManager.js";
import * as JsxRuntime from "react/jsx-runtime";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import * as CatalogBrowserSortContext from "../context/CatalogBrowserSortContext.js";
import ArrowUpwardRounded from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRounded from "@mui/icons-material/ArrowDownwardRounded";

function fieldLabel(field) {
  if (field === "size") {
    return JsxRuntime.jsx(ReactI18Next.Message.make, {
                msg: {
                  NAME: "str",
                  VAL: "Size"
                }
              });
  } else if (field === "created_at") {
    return JsxRuntime.jsx(ReactI18Next.Message.make, {
                msg: {
                  NAME: "str",
                  VAL: "Created at"
                }
              });
  } else if (field === "updated_at") {
    return JsxRuntime.jsx(ReactI18Next.Message.make, {
                msg: {
                  NAME: "str",
                  VAL: "Updated at"
                }
              });
  } else {
    return JsxRuntime.jsx(ReactI18Next.Message.make, {
                msg: {
                  NAME: "str",
                  VAL: "Name"
                }
              });
  }
}

function FoldersGrid$Header(props) {
  var subfolders = props.subfolders;
  var sortContext = CatalogBrowserSortContext.Folder.useCurrent();
  var setField = CatalogBrowserSortContext.Folder.useSetField();
  var toggleDirection = CatalogBrowserSortContext.Folder.useToggleDirection();
  var areAllSelected = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.Folders.useAreAllSelected());
  var hasSelection = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.Folders.useHasSelection());
  var select = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.Folders.useSelect());
  var reset = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.Folders.useReset());
  var toggleSelection = React.useCallback((function (e, param) {
          var exit = 0;
          var target;
          try {
            target = e.target;
            exit = 1;
          }
          catch (exn){
            return ;
          }
          if (exit === 1) {
            var exit$1 = 0;
            var val;
            try {
              val = target.checked;
              exit$1 = 2;
            }
            catch (exn$1){
              return ;
            }
            if (exit$1 === 2) {
              if (val) {
                return select(subfolders);
              } else {
                return reset();
              }
            }
            
          }
          
        }), [
        select,
        reset
      ]);
  var indeterminate = React.useMemo((function () {
          if (areAllSelected(subfolders)) {
            return false;
          } else {
            return hasSelection;
          }
        }), [
        hasSelection,
        subfolders,
        areAllSelected
      ]);
  var checked = React.useMemo((function () {
          if (indeterminate) {
            return false;
          } else {
            return areAllSelected(subfolders);
          }
        }), [
        indeterminate,
        subfolders,
        areAllSelected
      ]);
  var tooltip = JsxRuntime.jsx(ReactI18Next.Message.make, {
        msg: {
          NAME: "str",
          VAL: "Toggle selection"
        }
      });
  return JsxRuntime.jsxs(Stack, {
              children: [
                JsxRuntime.jsxs(Grid, {
                      children: [
                        JsxRuntime.jsx(Grid, {
                              children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                        children: JsxRuntime.jsx(Checkbox, {
                                              checked: checked,
                                              indeterminate: indeterminate,
                                              onChange: toggleSelection
                                            }),
                                        arrow: true,
                                        title: tooltip
                                      })),
                              item: true
                            }),
                        JsxRuntime.jsx(Grid, {
                              children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                        variant: "h5",
                                        children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                  msg: {
                                                    NAME: "msg",
                                                    VAL: [
                                                      "catalog.browser.folders.title",
                                                      "Folders"
                                                    ]
                                                  }
                                                }))
                                      })),
                              item: true
                            })
                      ],
                      container: true,
                      spacing: 2,
                      sx: {
                        alignItems: "center"
                      }
                    }),
                JsxRuntime.jsx(FlexGrowSpacer.make, {}),
                setField !== undefined && toggleDirection !== undefined && sortContext !== undefined ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                        children: [
                          JsxRuntime.jsx(DropdownMenu.make, {
                                trigger: {
                                  TAG: "Button",
                                  _0: fieldLabel(sortContext[1])
                                },
                                items: [
                                  {
                                    TAG: "Item",
                                    _0: {
                                      title: {
                                        NAME: "str",
                                        VAL: "Name"
                                      },
                                      action: {
                                        TAG: "Click",
                                        _0: (function (param) {
                                            setField("name");
                                          })
                                      }
                                    }
                                  },
                                  {
                                    TAG: "Item",
                                    _0: {
                                      title: {
                                        NAME: "str",
                                        VAL: "Created at"
                                      },
                                      action: {
                                        TAG: "Click",
                                        _0: (function (param) {
                                            setField("created_at");
                                          })
                                      }
                                    }
                                  },
                                  {
                                    TAG: "Item",
                                    _0: {
                                      title: {
                                        NAME: "str",
                                        VAL: "Updated at"
                                      },
                                      action: {
                                        TAG: "Click",
                                        _0: (function (param) {
                                            setField("updated_at");
                                          })
                                      }
                                    }
                                  },
                                  {
                                    TAG: "Item",
                                    _0: {
                                      title: {
                                        NAME: "str",
                                        VAL: "Size"
                                      },
                                      action: {
                                        TAG: "Click",
                                        _0: (function (param) {
                                            setField("size");
                                          })
                                      }
                                    }
                                  }
                                ],
                                tooltip: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                          msg: {
                                            NAME: "str",
                                            VAL: "Sort field"
                                          }
                                        })),
                                color: "inherit",
                                delay: 0
                              }),
                          JsxRuntime.jsx(Tooltip, {
                                children: JsxRuntime.jsx(IconButton, {
                                      children: Caml_option.some(sortContext[0] === "asc" ? JsxRuntime.jsx(ArrowUpwardRounded, {}) : JsxRuntime.jsx(ArrowDownwardRounded, {})),
                                      onClick: (function (param) {
                                          toggleDirection();
                                        })
                                    }),
                                title: JsxRuntime.jsx(ReactI18Next.Message.make, {
                                      msg: {
                                        NAME: "str",
                                        VAL: "Reverse sort direction"
                                      }
                                    })
                              })
                        ]
                      }) : null
              ],
              direction: ["row"],
              spacing: "0.5rem"
            });
}

function FoldersGrid(props) {
  var subfolders = props.subfolders;
  return JsxRuntime.jsxs(Stack, {
              children: [
                JsxRuntime.jsx(FoldersGrid$Header, {
                      subfolders: subfolders
                    }),
                JsxRuntime.jsx(Stack, {
                      children: Caml_option.some(subfolders.map(function (folder) {
                                return JsxRuntime.jsx(FolderCard.make, {
                                            folder: folder
                                          }, FolderId.toString(folder.id));
                              })),
                      direction: ["row"],
                      spacing: "1rem",
                      sx: {
                        flexWrap: "wrap"
                      }
                    })
              ],
              direction: ["column"],
              spacing: "0.5rem"
            });
}

var make = FoldersGrid;

export {
  make ,
}
/* react Not a pure module */
