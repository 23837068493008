// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../../libs/i18n/ReactI18Next.js";
import * as PasswordInput from "../../../global/components/PasswordInput.js";
import * as AccountsManager from "../context/AccountsManager.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as GroupsAutocomplete from "./GroupsAutocomplete.js";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Switch from "@mui/material/Switch";
import * as UpdateAccountService from "../context/UpdateAccountService.js";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

var emailRegex = /^(([^<>()\[\]\\.,;:\s@\"]+(\.[^<>()\[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function EditAccountDialog(props) {
  var onDone = props.onDone;
  var onClose = props.onClose;
  var account = props.account;
  var update = Curry._1(Prelude.OptionExported.$$Option.getExn, UpdateAccountService.useUpdateAccount());
  var canIDeactivate = Curry._1(Prelude.OptionExported.$$Option.getExn, AccountsManager.useCanIDeactivate());
  var match = React.useState(function () {
        return {
                first_name: account.first_name,
                last_name: account.last_name,
                email: account.email,
                groups: account.groups,
                is_active: account.is_active
              };
      });
  var setForm = match[1];
  var form = match[0];
  var emailValid = React.useMemo((function () {
          var email = form.email.trim();
          if (email.length === 0) {
            return true;
          } else {
            return emailRegex.test(email);
          }
        }), [form.email]);
  var passwordValid = React.useMemo((function () {
          var match = form.password;
          var match$1 = form.password_confirmation;
          if (match !== undefined) {
            if (match$1 !== undefined && match.length > 6) {
              return match === match$1;
            } else {
              return false;
            }
          } else {
            return match$1 === undefined;
          }
        }), [
        form.password,
        form.password_confirmation
      ]);
  var valid = emailValid && passwordValid;
  var groupsHelperText = React.useMemo((function () {
          if (Prelude.$$Array.isEmpty(form.groups)) {
            return Caml_option.some(JsxRuntime.jsx(Typography, {
                            color: "error",
                            variant: "body2",
                            children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                      msg: {
                                        NAME: "msg",
                                        VAL: [
                                          "accounts.no-groups-warning",
                                          "A user without groups might log in, but won't have access to any module"
                                        ]
                                      }
                                    }))
                          }));
          }
          
        }), [form.groups]);
  var onSave = React.useCallback((function () {
          var first_name = form.first_name.trim();
          var first_name$1 = first_name !== account.first_name ? first_name : undefined;
          var last_name = form.last_name.trim();
          var last_name$1 = last_name !== account.last_name ? last_name : undefined;
          var email = form.email.trim();
          var email$1 = email !== account.email ? email : undefined;
          var is_active = form.is_active || canIDeactivate(account) ? form.is_active : undefined;
          var groups = form.groups.map(function (g) {
                return g.id;
              });
          var match = form.password;
          var match$1 = form.password_confirmation;
          var new_password = match !== undefined && match$1 !== undefined && match === match$1 ? match : undefined;
          Prelude.thenDo(update({
                    id: account.id,
                    is_superuser: account.is_superuser,
                    new_password: new_password,
                    first_name: first_name$1,
                    last_name: last_name$1,
                    email: email$1,
                    groups: groups,
                    is_active: is_active
                  }), (function (param) {
                  onDone();
                }));
        }), [
        form,
        update,
        onDone
      ]);
  var match$1 = React.useState(function () {
        return false;
      });
  var setShowPassword = match$1[1];
  var showPassword = match$1[0];
  return JsxRuntime.jsxs(Dialog, {
              open: true,
              children: [
                JsxRuntime.jsx(DialogTitle, {
                      children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                msg: {
                                  NAME: "msg",
                                  VAL: [
                                    "accounts.edit-title",
                                    "Edit account"
                                  ]
                                }
                              }))
                    }),
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                children: [
                                  JsxRuntime.jsx(Typography, {
                                        variant: "h6",
                                        children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                  msg: {
                                                    NAME: "msg",
                                                    VAL: [
                                                      "accounts.access-section-heading",
                                                      "Access"
                                                    ]
                                                  }
                                                }))
                                      }),
                                  JsxRuntime.jsxs(Stack, {
                                        alignItems: "baseline",
                                        children: [
                                          JsxRuntime.jsx(Switch, {
                                                checked: form.is_active,
                                                disabled: form.is_active && !canIDeactivate(account),
                                                onChange: (function (param, is_active) {
                                                    setForm(function (f) {
                                                          var newrecord = Caml_obj.obj_dup(f);
                                                          newrecord.is_active = is_active;
                                                          return newrecord;
                                                        });
                                                  })
                                              }),
                                          JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                msg: {
                                                  NAME: "msg",
                                                  VAL: [
                                                    "accounts.account-field-is-active",
                                                    "Active"
                                                  ]
                                                }
                                              }),
                                          JsxRuntime.jsx(Typography, {
                                                variant: "body2",
                                                children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                          msg: {
                                                            NAME: "msg",
                                                            VAL: [
                                                              "accounts.account-active-explanation",
                                                              "Only active users can login.  Deactivating a user will immediately log the user out."
                                                            ]
                                                          }
                                                        }))
                                              })
                                        ],
                                        direction: ["row"],
                                        spacing: 2.0
                                      }),
                                  JsxRuntime.jsxs(Stack, {
                                        children: [
                                          JsxRuntime.jsx(PasswordInput.make, {
                                                value: Prelude.default(form.password, ""),
                                                onChange: (function (password) {
                                                    setForm(function (f) {
                                                          var newrecord = Caml_obj.obj_dup(f);
                                                          newrecord.password = password;
                                                          return newrecord;
                                                        });
                                                  }),
                                                label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                          msg: {
                                                            NAME: "msg",
                                                            VAL: [
                                                              "accounts.account-field-password",
                                                              "Password"
                                                            ]
                                                          }
                                                        })),
                                                fullWidth: true,
                                                disabled: !form.is_active,
                                                showPassword: showPassword,
                                                setShowPassword: setShowPassword
                                              }),
                                          JsxRuntime.jsx(PasswordInput.make, {
                                                value: Prelude.default(form.password_confirmation, ""),
                                                onChange: (function (password_confirmation) {
                                                    setForm(function (f) {
                                                          var newrecord = Caml_obj.obj_dup(f);
                                                          newrecord.password_confirmation = password_confirmation;
                                                          return newrecord;
                                                        });
                                                  }),
                                                label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                          msg: {
                                                            NAME: "msg",
                                                            VAL: [
                                                              "accounts.account-field-password-confirmation",
                                                              "Password confirmation"
                                                            ]
                                                          }
                                                        })),
                                                fullWidth: true,
                                                disabled: !form.is_active,
                                                showPassword: showPassword,
                                                setShowPassword: setShowPassword
                                              })
                                        ],
                                        direction: ["row"],
                                        spacing: 2.0
                                      }),
                                  JsxRuntime.jsx(Typography, {
                                        variant: "h6",
                                        children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                  msg: {
                                                    NAME: "msg",
                                                    VAL: [
                                                      "accounts.general-section-heading",
                                                      "General"
                                                    ]
                                                  }
                                                }))
                                      }),
                                  JsxRuntime.jsx(TextField, {
                                        error: !emailValid,
                                        fullWidth: true,
                                        label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                  msg: {
                                                    NAME: "msg",
                                                    VAL: [
                                                      "accounts.account-field-email",
                                                      "Email address"
                                                    ]
                                                  }
                                                })),
                                        onChange: (function (ev) {
                                            var email = ev.target.value;
                                            setForm(function (f) {
                                                  var newrecord = Caml_obj.obj_dup(f);
                                                  newrecord.email = email;
                                                  return newrecord;
                                                });
                                          }),
                                        required: false,
                                        type: "email",
                                        value: form.email
                                      }),
                                  JsxRuntime.jsxs(Stack, {
                                        children: [
                                          JsxRuntime.jsx(TextField, {
                                                fullWidth: true,
                                                label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                          msg: {
                                                            NAME: "msg",
                                                            VAL: [
                                                              "accounts.account-field-first-name",
                                                              "First Name"
                                                            ]
                                                          }
                                                        })),
                                                onChange: (function (ev) {
                                                    var first_name = ev.target.value;
                                                    setForm(function (f) {
                                                          var newrecord = Caml_obj.obj_dup(f);
                                                          newrecord.first_name = first_name;
                                                          return newrecord;
                                                        });
                                                  }),
                                                required: false,
                                                value: form.first_name
                                              }),
                                          JsxRuntime.jsx(TextField, {
                                                fullWidth: true,
                                                label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                          msg: {
                                                            NAME: "msg",
                                                            VAL: [
                                                              "accounts.account-field-last-name",
                                                              "Last Name"
                                                            ]
                                                          }
                                                        })),
                                                onChange: (function (ev) {
                                                    var last_name = ev.target.value;
                                                    setForm(function (f) {
                                                          var newrecord = Caml_obj.obj_dup(f);
                                                          newrecord.last_name = last_name;
                                                          return newrecord;
                                                        });
                                                  }),
                                                required: false,
                                                value: form.last_name
                                              })
                                        ],
                                        direction: ["row"],
                                        spacing: 2.0
                                      }),
                                  JsxRuntime.jsx(Typography, {
                                        variant: "h6",
                                        children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                  msg: {
                                                    NAME: "msg",
                                                    VAL: [
                                                      "accounts.groups-section-heading",
                                                      "Groups"
                                                    ]
                                                  }
                                                }))
                                      }),
                                  JsxRuntime.jsx(GroupsAutocomplete.make, {
                                        groups: form.groups,
                                        onChange: (function (groups) {
                                            setForm(function (f) {
                                                  var newrecord = Caml_obj.obj_dup(f);
                                                  newrecord.groups = groups;
                                                  return newrecord;
                                                });
                                          }),
                                        disabled: !form.is_active,
                                        helperText: groupsHelperText
                                      })
                                ],
                                direction: ["column"],
                                spacing: 2.0
                              }))
                    }),
                JsxRuntime.jsxs(DialogActions, {
                      children: [
                        JsxRuntime.jsx(Button, {
                              children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                        msg: {
                                          NAME: "msg",
                                          VAL: [
                                            "buttons.cancel",
                                            "Cancel"
                                          ]
                                        }
                                      })),
                              onClick: (function (param) {
                                  onClose();
                                })
                            }),
                        JsxRuntime.jsx(Button, {
                              children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                        msg: {
                                          NAME: "msg",
                                          VAL: [
                                            "buttons.save",
                                            "Save"
                                          ]
                                        }
                                      })),
                              onClick: (function (param) {
                                  onSave();
                                }),
                              disabled: !valid
                            })
                      ]
                    })
              ],
              fullWidth: true,
              maxWidth: "md",
              onClose: (function (param, param$1) {
                  onClose();
                })
            });
}

var make = EditAccountDialog;

export {
  emailRegex ,
  make ,
}
/* react Not a pure module */
