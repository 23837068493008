// Generated by ReScript, PLEASE EDIT WITH CARE

import * as AuthManager from "../../context/AuthManager.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FailurePage from "./FailurePage.js";
import * as ReactI18Next from "../../../../libs/i18n/ReactI18Next.js";
import * as JsxRuntime from "react/jsx-runtime";
import Button from "@mui/material/Button";

function LoginErrorPage(props) {
  var msg = props.msg;
  var logout = AuthManager.useLogout();
  var reasons = msg !== undefined ? [msg] : [{
        NAME: "msg",
        VAL: [
          "login.errors.verification-failed",
          "We could not verify your login the server.\n         Make sure to type your username and password correctly."
        ]
      }];
  return JsxRuntime.jsx(FailurePage.make, {
              title: {
                NAME: "msg",
                VAL: [
                  "login.errors.title",
                  "Login error"
                ]
              },
              reasons: reasons,
              children: Caml_option.some(JsxRuntime.jsx(Button, {
                        children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                  msg: {
                                    NAME: "msg",
                                    VAL: [
                                      "login.title",
                                      "Log in"
                                    ]
                                  }
                                })),
                        onClick: (function (param) {
                            logout();
                          })
                      }))
            });
}

var make = LoginErrorPage;

export {
  make ,
}
/* AuthManager Not a pure module */
