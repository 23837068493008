// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as $$Document from "../models/Document.js";
import * as DocumentId from "../models/DocumentId.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Orientation from "../models/Orientation.js";
import * as ClipboardManager from "../context/ClipboardManager.js";
import * as DocumentDropdown from "./affordances/DocumentDropdown.js";
import * as SelectionManager from "../context/SelectionManager.js";
import Box from "@mui/material/Box";
import * as JsxRuntime from "react/jsx-runtime";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import * as ClipboardToggleButton from "./ClipboardToggleButton.js";
import Checkbox from "@mui/material/Checkbox";
import Skeleton from "@mui/material/Skeleton";
import Room from "@mui/icons-material/Room";
import Typography from "@mui/material/Typography";
import Face2 from "@mui/icons-material/Face2";
import * as CurrentDocumentListManager from "../context/CurrentDocumentListManager.js";

function DocumentCard$UnknownPreview(props) {
  return JsxRuntime.jsxs(Box, {
              children: [
                JsxRuntime.jsx(Skeleton, {
                      sx: {
                        fontSize: "1rem"
                      },
                      variant: "text"
                    }),
                JsxRuntime.jsx(Skeleton, {
                      variant: "circular"
                    }),
                JsxRuntime.jsx(Skeleton, {
                      variant: "rectangular"
                    }),
                JsxRuntime.jsx(Skeleton, {
                      variant: "rounded"
                    })
              ]
            });
}

function DocumentCard$Preview(props) {
  var $$document = props.document;
  var preview = Curry._2(Prelude.OptionExported.$$Option.flatMap, $$document.previews, (function (p) {
          return p.small;
        }));
  var size = Orientation.Ifd0.flipsWidthHeight(Prelude.default($$Document.Orientation.get($$document), 1)) ? ({
        maxHeight: "20rem",
        maxWidth: "17rem"
      }) : ({
        maxHeight: "17rem",
        maxWidth: "20rem"
      });
  var style = Orientation.Ifd0.getCSSTransform(Prelude.default($$Document.Orientation.get($$document), 1), size);
  if (preview !== undefined) {
    if (typeof preview !== "object") {
      return JsxRuntime.jsx(DocumentCard$UnknownPreview, {});
    }
    if (preview.NAME !== "remote") {
      return JsxRuntime.jsx(DocumentCard$UnknownPreview, {});
    }
    var newrecord = Caml_obj.obj_dup(size);
    return JsxRuntime.jsx("div", {
                children: JsxRuntime.jsx("img", {
                      style: style,
                      loading: "lazy",
                      src: preview.VAL.url
                    }),
                style: (newrecord.margin = "0 auto", newrecord.display = "block", newrecord)
              });
  }
  var match = $$document.attachment;
  if (typeof match !== "object") {
    return JsxRuntime.jsx(DocumentCard$UnknownPreview, {});
  }
  if (match.NAME !== "remote") {
    return JsxRuntime.jsx(DocumentCard$UnknownPreview, {});
  }
  var newrecord$1 = Caml_obj.obj_dup(size);
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("img", {
                    style: style,
                    loading: "lazy",
                    src: match.VAL.url
                  }),
              style: (newrecord$1.margin = "0 auto", newrecord$1.display = "block", newrecord$1)
            });
}

function DocumentCard$Info(props) {
  var $$document = props.document;
  var author = React.useMemo((function () {
          return $$Document.author($$document);
        }), [$$document]);
  var country = React.useMemo((function () {
          return $$Document.country($$document);
        }), [$$document]);
  var city = React.useMemo((function () {
          return $$Document.city($$document);
        }), [$$document]);
  var $$location = React.useMemo((function () {
          if (country === undefined) {
            if (city !== undefined && city !== "") {
              return city;
            } else {
              return ;
            }
          }
          if (country === "") {
            if (city === undefined) {
              return ;
            }
            if (city === "") {
              return ;
            }
            
          }
          if (city !== undefined) {
            return country + " / " + city;
          } else {
            return country;
          }
        }), [
        country,
        city
      ]);
  return JsxRuntime.jsxs(Stack, {
              alignItems: "center",
              bgcolor: "rgba(0, 0, 0, 0.7)",
              bottom: "0",
              position: "absolute",
              height: "20%",
              width: "100%",
              children: [
                JsxRuntime.jsx(ClipboardToggleButton.make, {
                      item: {
                        NAME: "document",
                        VAL: $$document
                      },
                      fontSize: "small",
                      sx: {
                        color: "primary.main",
                        position: "absolute",
                        right: "0.4rem",
                        top: "0.4rem"
                      }
                    }),
                JsxRuntime.jsxs(Stack, {
                      width: "90%",
                      padding: "1rem",
                      children: [
                        Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, author, (function (author) {
                                    return JsxRuntime.jsxs(Typography, {
                                                style: {
                                                  overflow: "hidden",
                                                  whiteSpace: "nowrap",
                                                  textOverflow: "ellipsis"
                                                },
                                                children: [
                                                  JsxRuntime.jsx(Face2, {
                                                        sx: {
                                                          marginRight: "0.5rem",
                                                          fontSize: "1rem"
                                                        }
                                                      }),
                                                  author
                                                ],
                                                sx: {
                                                  color: "#fff"
                                                }
                                              });
                                  })), JsxRuntime.jsx(Box, {
                                  sx: {
                                    minHeight: "1.5rem"
                                  }
                                })),
                        Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, $$location, (function ($$location) {
                                    return JsxRuntime.jsxs(Typography, {
                                                style: {
                                                  overflow: "hidden",
                                                  whiteSpace: "nowrap",
                                                  textOverflow: "ellipsis"
                                                },
                                                children: [
                                                  JsxRuntime.jsx(Room, {
                                                        sx: {
                                                          marginRight: "0.5rem",
                                                          fontSize: "1rem"
                                                        }
                                                      }),
                                                  $$location
                                                ],
                                                sx: {
                                                  color: "#fff"
                                                }
                                              });
                                  })), JsxRuntime.jsx(Box, {
                                  sx: {
                                    minHeight: "1.5rem"
                                  }
                                }))
                      ],
                      direction: ["column"],
                      spacing: 0.25
                    })
              ],
              direction: ["row"],
              useFlexGap: true
            });
}

function DocumentCard$DocumentCardHeader(props) {
  var unselect = props.unselect;
  var select = props.select;
  var $$document = props.document;
  return JsxRuntime.jsxs(Grid, {
              children: [
                JsxRuntime.jsx(Grid, {
                      children: Caml_option.some(JsxRuntime.jsx(Checkbox, {
                                checked: props.selected,
                                onChange: (function (param, selected) {
                                    if (selected) {
                                      return select([$$document]);
                                    } else {
                                      return unselect([$$document]);
                                    }
                                  })
                              })),
                      item: true,
                      xs: 2
                    }),
                JsxRuntime.jsx(Grid, {
                      children: Caml_option.some(JsxRuntime.jsx("div", {
                                children: $$Document.name($$document),
                                style: {
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis"
                                }
                              })),
                      item: true,
                      xs: 8
                    }),
                JsxRuntime.jsx(Grid, {
                      children: Caml_option.some(JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsx(DocumentDropdown.make, {
                                      document: $$document
                                    }),
                                onDoubleClick: (function (ev) {
                                    ev.stopPropagation();
                                  })
                              })),
                      item: true,
                      xs: 2
                    })
              ],
              container: true,
              sx: {
                alignItems: "center",
                height: "3rem",
                width: "20rem",
                padding: "0 0.1rem"
              }
            });
}

function DocumentCard(props) {
  var $$document = props.document;
  var isSelected = Prelude.default(SelectionManager.Documents.useIsSelected(), (function (param) {
          return false;
        }));
  var selected = React.useMemo((function () {
          return isSelected($$document);
        }), [
        $$document,
        isSelected
      ]);
  var select = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.Documents.useSelect());
  var unselect = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.Documents.useUnselect());
  var isClipped = ClipboardManager.Contents.useIsClipped();
  var clipped = React.useMemo((function () {
          return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, isClipped, (function (fn) {
                            return fn({
                                        NAME: "document",
                                        VAL: $$document
                                      });
                          })), false);
        }), [
        $$document,
        isClipped
      ]);
  var elevation = React.useMemo((function () {
          if (clipped) {
            return 14;
          } else {
            return 2;
          }
        }), [clipped]);
  var setActiveDocument = CurrentDocumentListManager.useSetActiveDocument();
  return React.useMemo((function () {
                return JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(Paper, {
                                  elevation: elevation,
                                  sx: {
                                    borderColor: selected ? "primary.main" : "initial",
                                    bgcolor: selected ? "action.focus" : "background.default",
                                    position: "relative",
                                    height: "20rem",
                                    width: "20rem"
                                  },
                                  children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                            height: "100%",
                                            children: [
                                              JsxRuntime.jsx(DocumentCard$DocumentCardHeader, {
                                                    document: $$document,
                                                    selected: selected,
                                                    select: select,
                                                    unselect: unselect
                                                  }),
                                              JsxRuntime.jsxs("div", {
                                                    children: [
                                                      JsxRuntime.jsx(DocumentCard$Preview, {
                                                            document: $$document
                                                          }),
                                                      JsxRuntime.jsx(DocumentCard$Info, {
                                                            document: $$document
                                                          })
                                                    ],
                                                    style: {
                                                      display: "flex",
                                                      height: "100%",
                                                      flexDirection: "column",
                                                      justifyContent: "center"
                                                    }
                                                  })
                                            ],
                                            direction: ["column"]
                                          }))
                                }),
                            className: "selectable-item",
                            id: DocumentId.toString($$document.id),
                            onMouseDown: (function (e) {
                                if (e.detail === 2) {
                                  return setActiveDocument($$document);
                                }
                                
                              })
                          });
              }), [
              isClipped,
              clipped,
              elevation,
              $$document,
              selected,
              select,
              unselect,
              setActiveDocument
            ]);
}

var make = DocumentCard;

export {
  make ,
}
/* react Not a pure module */
