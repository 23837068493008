// Generated by ReScript, PLEASE EDIT WITH CARE


var name = {
  NAME: "msg",
  VAL: [
    "user-settings.title",
    "Settings"
  ]
};

var path = "/user/settings";

var permissions = "Public";

var icon;

export {
  name ,
  path ,
  permissions ,
  icon ,
}
/* No side effect */
