// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";

var TrashResidencyDays = {
  $$default: 30,
  min: 15,
  max: 120
};

function getFiles(param) {
  return [];
}

function getTrashResidencyDays(ns) {
  return Prelude.default(ns.trash_residency_days, 30);
}

function setTrashResidencyDays(ns, duration) {
  var newrecord = Caml_obj.obj_dup(ns);
  newrecord.trash_residency_days = 15 <= duration && duration <= 120 ? duration : Prelude.default(ns.trash_residency_days, 30);
  return newrecord;
}

var Identifier;

export {
  Identifier ,
  TrashResidencyDays ,
  getFiles ,
  getTrashResidencyDays ,
  setTrashResidencyDays ,
}
/* No side effect */
