// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Collection from "../models/Collection.js";
import * as CollectionId from "../models/CollectionId.js";
import * as SelectionManagerMaker from "../../../utils/SelectionManagerMaker.js";

function getItemId(collection) {
  return collection.id;
}

function canBeSelected(param) {
  return true;
}

var include = SelectionManagerMaker.UUID.Make({
      Model: {
        Id: CollectionId
      },
      Id: CollectionId,
      scope: "CollectionManager",
      getItemId: getItemId,
      canBeSelected: canBeSelected
    });

var Model = include.Model;

var Id = include.Id;

var scope = include.scope;

var getItemId$1 = include.getItemId;

var canBeSelected$1 = include.canBeSelected;

var State = include.State;

var Dispatchers = include.Dispatchers;

var useGetCurrentSelection = include.useGetCurrentSelection;

var useGetCurrentSelectionIds = include.useGetCurrentSelectionIds;

var useIsSelected = include.useIsSelected;

var useAreAllSelected = include.useAreAllSelected;

var useHasSelection = include.useHasSelection;

var useSelect = include.useSelect;

var useUnselect = include.useUnselect;

var useToggle = include.useToggle;

var useReset = include.useReset;

var useBegin = include.useBegin;

var useCommit = include.useCommit;

var useRollback = include.useRollback;

var make = include.make;

export {
  Model ,
  Id ,
  scope ,
  getItemId$1 as getItemId,
  canBeSelected$1 as canBeSelected,
  State ,
  Dispatchers ,
  useGetCurrentSelection ,
  useGetCurrentSelectionIds ,
  useIsSelected ,
  useAreAllSelected ,
  useHasSelection ,
  useSelect ,
  useUnselect ,
  useToggle ,
  useReset ,
  useBegin ,
  useCommit ,
  useRollback ,
  make ,
}
/* include Not a pure module */
