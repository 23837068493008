// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as UserContext from "../../../../global/context/UserContext.js";
import * as SharedDBContext from "../../../../global/context/SharedDBContext.js";
import * as NamespaceContext from "../../../../global/context/NamespaceContext.js";
import * as SelectionManager from "../SelectionManager.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CatalogAPIEndpoint from "../../api/CatalogAPIEndpoint.js";
import * as CurrentDocumentListManager from "../CurrentDocumentListManager.js";

var context = React.createContext(undefined);

var make = context.Provider;

function available() {
  return Curry._1(Prelude.OptionExported.$$Option.isSome, React.useContext(context));
}

function useTrash() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.trash;
              }));
}

function useRemove() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.remove;
              }));
}

function RemoveDocumentService(props) {
  var namespace = NamespaceContext.useNamespace();
  var removeDocument = CurrentDocumentListManager.useRemoveDocument();
  var token = Curry._1(Prelude.OptionExported.$$Option.getExn, UserContext.useAccessToken());
  var match = SharedDBContext.useQuery();
  var write = match.write;
  var makeWrite = match.makeWrite;
  var unselect = SelectionManager.Documents.useUnselect();
  var remove = React.useMemo((function () {
          return async function ($$document) {
            var f = await CatalogAPIEndpoint.RemoveDocument.$$do($$document, token);
            var result;
            if (f.TAG === "Ok") {
              var f$1 = f._0;
              removeDocument(f$1);
              Prelude.OptionExported.$$Option.$$do(unselect, (function (fn) {
                      fn([f$1]);
                    }));
              result = {
                TAG: "Ok",
                _0: f$1
              };
            } else {
              result = {
                TAG: "Error",
                _0: f._0[1]
              };
            }
            if (result.TAG !== "Ok") {
              return {
                      TAG: "Error",
                      _0: result._0
                    };
            }
            var init = makeWrite();
            await write({
                  documents: [{
                      TAG: "Delete",
                      _0: result._0.id
                    }],
                  folders: init.folders,
                  bundlers: init.bundlers
                });
            return {
                    TAG: "Ok",
                    _0: undefined
                  };
          };
        }), [
        token,
        makeWrite,
        write
      ]);
  var trash = React.useMemo((function () {
          return async function ($$document) {
            var e = await CatalogAPIEndpoint.UpdateDocument.move($$document, namespace.trash_folder_id, token);
            var result;
            if (e.TAG === "Ok") {
              var f = e._0;
              if (f !== undefined) {
                removeDocument(f);
                Prelude.OptionExported.$$Option.$$do(unselect, (function (fn) {
                        fn([f]);
                      }));
                result = {
                  TAG: "Ok",
                  _0: f
                };
              } else {
                result = {
                  TAG: "Ok",
                  _0: $$document
                };
              }
            } else {
              result = {
                TAG: "Error",
                _0: e._0
              };
            }
            if (result.TAG !== "Ok") {
              return {
                      TAG: "Error",
                      _0: result._0
                    };
            }
            var $$document$1 = result._0;
            var init = makeWrite();
            await write({
                  documents: [{
                      TAG: "Save",
                      _0: $$document$1
                    }],
                  folders: init.folders,
                  bundlers: init.bundlers
                });
            return {
                    TAG: "Ok",
                    _0: $$document$1
                  };
          };
        }), [
        token,
        makeWrite,
        write
      ]);
  return JsxRuntime.jsx(make, {
              value: {
                remove: remove,
                trash: trash
              },
              children: props.children
            });
}

var make$1 = RemoveDocumentService;

export {
  available ,
  useRemove ,
  useTrash ,
  make$1 as make,
}
/* context Not a pure module */
