// Generated by ReScript, PLEASE EDIT WITH CARE

import * as User from "../../global/models/User.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import I18next from "i18next";
import * as Settings from "../../Settings.js";
import * as AuthManager from "../../global/context/AuthManager.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UserContext from "../../global/context/UserContext.js";
import * as ReactI18Next from "../../../libs/i18n/ReactI18Next.js";
import * as PasswordInput from "../../global/components/PasswordInput.js";
import * as ProtectedArea from "../../utils/ProtectedArea.js";
import * as FlexGrowSpacer from "../../global/components/FlexGrowSpacer.js";
import * as ResetAppButton from "../../global/components/ResetAppButton.js";
import * as SharedDBContext from "../../global/context/SharedDBContext.js";
import * as ApplicationMaker from "../../global/apps/ApplicationMaker.js";
import * as JsxRuntime from "react/jsx-runtime";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import * as TagsManagerManifest from "../tags/TagsManagerManifest.js";
import Button from "@mui/material/Button";
import * as UserSettingsManifest from "./UserSettingsManifest.js";
import Snackbar from "@mui/material/Snackbar";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import * as AccountsManagerManifest from "../accounts/AccountsManagerManifest.js";
import Code from "@mui/icons-material/Code";
import List from "@mui/icons-material/List";
import Typography from "@mui/material/Typography";
import * as UserPreferredColorScheme from "../../global/context/UserPreferredColorScheme.js";
import Person from "@mui/icons-material/Person";
import ToggleButton from "@mui/material/ToggleButton";
import * as CollectionsManagerManifest from "../collections/CollectionsManagerManifest.js";
import Palette from "@mui/icons-material/Palette";
import DarkMode from "@mui/icons-material/DarkMode";
import Password from "@mui/icons-material/Password";
import TableContainer from "@mui/material/TableContainer";
import LightMode from "@mui/icons-material/LightMode";
import Translate from "@mui/icons-material/Translate";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

function UserSettingsView$UserInfo(props) {
  var user = Curry._1(Prelude.OptionExported.$$Option.getExn, UserContext.useUserClaim());
  return JsxRuntime.jsxs(Stack, {
              children: [
                JsxRuntime.jsxs(Stack, {
                      alignItems: "center",
                      children: [
                        JsxRuntime.jsx(Person, {}),
                        JsxRuntime.jsx(Typography, {
                              variant: "h6",
                              children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                        msg: {
                                          NAME: "msg",
                                          VAL: [
                                            "user-settings.user-info-heading",
                                            "User info"
                                          ]
                                        }
                                      }))
                            })
                      ],
                      direction: ["row"],
                      spacing: 2.0
                    }),
                JsxRuntime.jsx(TableContainer, {
                      children: Caml_option.some(JsxRuntime.jsx(Table, {
                                children: Caml_option.some(JsxRuntime.jsxs(TableBody, {
                                          children: [
                                            JsxRuntime.jsxs(TableRow, {
                                                  children: [
                                                    JsxRuntime.jsx(TableCell, {
                                                          variant: "head",
                                                          children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                                    msg: {
                                                                      NAME: "str",
                                                                      VAL: "Username"
                                                                    }
                                                                  }))
                                                        }),
                                                    JsxRuntime.jsx(TableCell, {
                                                          children: Caml_option.some(user.username)
                                                        })
                                                  ]
                                                }),
                                            JsxRuntime.jsxs(TableRow, {
                                                  children: [
                                                    JsxRuntime.jsx(TableCell, {
                                                          variant: "head",
                                                          children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                                    msg: {
                                                                      NAME: "str",
                                                                      VAL: "Name"
                                                                    }
                                                                  }))
                                                        }),
                                                    JsxRuntime.jsx(TableCell, {
                                                          children: Caml_option.some(Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, User.fullname(user), (function (prim) {
                                                                          return prim;
                                                                        })), JsxRuntime.jsx("em", {
                                                                        children: JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                                              msg: {
                                                                                NAME: "msg",
                                                                                VAL: [
                                                                                  "user-settings.not-given-username",
                                                                                  "Not given"
                                                                                ]
                                                                              }
                                                                            })
                                                                      })))
                                                        })
                                                  ]
                                                })
                                          ]
                                        })),
                                size: "medium"
                              }))
                    })
              ],
              direction: ["column"],
              spacing: 2.0
            });
}

function UserSettingsView$PaginationSizes(props) {
  var match = Settings.Pagination.SearchResults.use();
  var setSearchResultPageSize = match[1];
  var searchResultPageSize = match[0];
  var match$1 = Settings.Pagination.TagsManager.use();
  var setTagsManagerPageSize = match$1[1];
  var tagsManagerPageSize = match$1[0];
  var match$2 = Settings.Pagination.AccountsManager.use();
  var setAccountsManagerPageSize = match$2[1];
  var accountsManagerPageSize = match$2[0];
  var match$3 = Settings.Pagination.CollectionsManager.use();
  var setCollectionsManagerPageSize = match$3[1];
  var collectionsManagerPageSize = match$3[0];
  var match$4 = React.useState(function () {
        return {
                search: String(searchResultPageSize),
                tags: String(tagsManagerPageSize),
                collections: String(collectionsManagerPageSize),
                accounts: String(accountsManagerPageSize)
              };
      });
  var setForm = match$4[1];
  var form = match$4[0];
  React.useEffect((function () {
          setForm(function (param) {
                return {
                        search: String(searchResultPageSize),
                        tags: String(tagsManagerPageSize),
                        collections: String(collectionsManagerPageSize),
                        accounts: String(accountsManagerPageSize)
                      };
              });
        }), [
        searchResultPageSize,
        tagsManagerPageSize,
        collectionsManagerPageSize,
        accountsManagerPageSize
      ]);
  var handleOnChange = function (attr) {
    return function (ev) {
      var value = ev.target.value;
      if (attr === "collections") {
        return setForm(function (f) {
                    return {
                            search: f.search,
                            tags: f.tags,
                            collections: value,
                            accounts: f.accounts
                          };
                  });
      } else if (attr === "search") {
        return setForm(function (f) {
                    return {
                            search: value,
                            tags: f.tags,
                            collections: f.collections,
                            accounts: f.accounts
                          };
                  });
      } else if (attr === "accounts") {
        return setForm(function (f) {
                    return {
                            search: f.search,
                            tags: f.tags,
                            collections: f.collections,
                            accounts: value
                          };
                  });
      } else {
        return setForm(function (f) {
                    return {
                            search: f.search,
                            tags: value,
                            collections: f.collections,
                            accounts: f.accounts
                          };
                  });
      }
    };
  };
  var numberRegex = /^[0-9][0-9]*$/;
  var inputProps = {
    step: 1,
    min: 15,
    max: 100,
    pattern: "[0-9]*",
    inputMode: "numeric"
  };
  var isValid = function (v) {
    if (!numberRegex.test(v)) {
      return false;
    }
    var val = Prelude.default(Curry._1(Prelude.Int.fromString, v), -1);
    if (val >= 15) {
      return val <= 100;
    } else {
      return false;
    }
  };
  var valid = !isValid(form.tags) || !isValid(form.search) || !isValid(form.collections) || !isValid(form.accounts);
  var match$5 = React.useState(function () {
        return false;
      });
  var setSaved = match$5[1];
  var saved = match$5[0];
  var save = function () {
    var newSearchSize = Prelude.default(Curry._1(Prelude.Int.fromString, form.search), searchResultPageSize);
    setSearchResultPageSize(newSearchSize);
    var newTagsSize = Prelude.default(Curry._1(Prelude.Int.fromString, form.tags), tagsManagerPageSize);
    setTagsManagerPageSize(newTagsSize);
    var newCollSize = Prelude.default(Curry._1(Prelude.Int.fromString, form.collections), collectionsManagerPageSize);
    setCollectionsManagerPageSize(newCollSize);
    var newAccountsSize = Prelude.default(Curry._1(Prelude.Int.fromString, form.accounts), accountsManagerPageSize);
    setAccountsManagerPageSize(newAccountsSize);
    setSaved(function (param) {
          return true;
        });
  };
  React.useEffect((function () {
          if (saved) {
            Prelude.thenDo(Prelude.Promises.ellapsed(6000), (function () {
                    setSaved(function (param) {
                          return false;
                        });
                  }));
          }
          
        }), [saved]);
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Stack, {
                      children: [
                        JsxRuntime.jsxs(Stack, {
                              alignItems: "center",
                              children: [
                                JsxRuntime.jsx(List, {}),
                                JsxRuntime.jsx(Typography, {
                                      variant: "h6",
                                      children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                msg: {
                                                  NAME: "msg",
                                                  VAL: [
                                                    "user-settings.page-sizes-form",
                                                    "Page sizes"
                                                  ]
                                                }
                                              }))
                                    })
                              ],
                              direction: ["row"],
                              spacing: 2.0
                            }),
                        JsxRuntime.jsxs(Stack, {
                              children: [
                                JsxRuntime.jsx(TextField, {
                                      color: isValid(form.search) ? "primary" : "error",
                                      error: !isValid(form.search),
                                      inputProps: inputProps,
                                      label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                msg: {
                                                  NAME: "msg",
                                                  VAL: [
                                                    "user-settings.search-page-size-label",
                                                    "Images in search"
                                                  ]
                                                }
                                              })),
                                      onChange: handleOnChange("search"),
                                      size: "small",
                                      value: form.search,
                                      variant: "outlined"
                                    }),
                                JsxRuntime.jsx(ProtectedArea.make, {
                                      requirement: {
                                        TAG: "Perms",
                                        _0: TagsManagerManifest.permissions
                                      },
                                      children: JsxRuntime.jsx(TextField, {
                                            color: isValid(form.tags) ? "primary" : "error",
                                            error: !isValid(form.tags),
                                            inputProps: inputProps,
                                            label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                      msg: {
                                                        NAME: "msg",
                                                        VAL: [
                                                          "user-settings.tags-page-size-label",
                                                          "Tags manager"
                                                        ]
                                                      }
                                                    })),
                                            onChange: handleOnChange("tags"),
                                            size: "small",
                                            value: form.tags,
                                            variant: "outlined"
                                          })
                                    }),
                                JsxRuntime.jsx(ProtectedArea.make, {
                                      requirement: {
                                        TAG: "Perms",
                                        _0: CollectionsManagerManifest.permissions
                                      },
                                      children: JsxRuntime.jsx(TextField, {
                                            color: isValid(form.collections) ? "primary" : "error",
                                            error: !isValid(form.collections),
                                            inputProps: inputProps,
                                            label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                      msg: {
                                                        NAME: "msg",
                                                        VAL: [
                                                          "user-settings.collections-page-size-label",
                                                          "Collections"
                                                        ]
                                                      }
                                                    })),
                                            onChange: handleOnChange("collections"),
                                            size: "small",
                                            value: form.collections,
                                            variant: "outlined"
                                          })
                                    }),
                                JsxRuntime.jsx(ProtectedArea.make, {
                                      requirement: {
                                        TAG: "Perms",
                                        _0: AccountsManagerManifest.permissions
                                      },
                                      children: JsxRuntime.jsx(TextField, {
                                            color: isValid(form.accounts) ? "primary" : "error",
                                            error: !isValid(form.accounts),
                                            inputProps: inputProps,
                                            label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                      msg: {
                                                        NAME: "msg",
                                                        VAL: [
                                                          "user-settings.accounts-page-size-label",
                                                          "Accounts"
                                                        ]
                                                      }
                                                    })),
                                            onChange: handleOnChange("accounts"),
                                            size: "small",
                                            value: form.accounts,
                                            variant: "outlined"
                                          })
                                    }),
                                JsxRuntime.jsxs(Stack, {
                                      children: [
                                        JsxRuntime.jsx(FlexGrowSpacer.make, {}),
                                        JsxRuntime.jsx(Button, {
                                              children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                        msg: {
                                                          NAME: "msg",
                                                          VAL: [
                                                            "buttons.reset",
                                                            "Reset"
                                                          ]
                                                        }
                                                      })),
                                              onClick: (function (param) {
                                                  setForm(function (param) {
                                                        return {
                                                                search: String(searchResultPageSize),
                                                                tags: String(tagsManagerPageSize),
                                                                collections: String(collectionsManagerPageSize),
                                                                accounts: String(accountsManagerPageSize)
                                                              };
                                                      });
                                                })
                                            }),
                                        JsxRuntime.jsx(Button, {
                                              children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                        msg: {
                                                          NAME: "msg",
                                                          VAL: [
                                                            "buttons.change",
                                                            "Change"
                                                          ]
                                                        }
                                                      })),
                                              onClick: (function (param) {
                                                  save();
                                                }),
                                              disabled: valid
                                            })
                                      ],
                                      direction: ["row"],
                                      spacing: "1rem"
                                    })
                              ],
                              spacing: "1rem"
                            })
                      ],
                      direction: ["column"],
                      spacing: 2.0
                    }),
                JsxRuntime.jsx(Snackbar, {
                      children: Caml_option.some(JsxRuntime.jsx(Alert, {
                                sx: {
                                  width: "100%"
                                },
                                children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                          msg: {
                                            NAME: "str",
                                            VAL: "Settings saved"
                                          }
                                        })),
                                severity: "success"
                              })),
                      onClose: (function (param, param$1) {
                          setSaved(function (param) {
                                return false;
                              });
                        }),
                      open: saved
                    })
              ]
            });
}

function UserSettingsView$ChangePassword$Form(props) {
  var changePassword = AuthManager.useChangePassword();
  var match = React.useState(function () {
        return false;
      });
  var setShowPassword = match[1];
  var showPassword = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setChanged = match$1[1];
  var changed = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setError = match$2[1];
  var error = match$2[0];
  React.useEffect((function () {
          if (changed) {
            Prelude.thenDo(Prelude.Promises.ellapsed(6000), (function () {
                    setChanged(function (param) {
                          return false;
                        });
                  }));
          }
          
        }), [changed]);
  React.useEffect((function () {
          if (error) {
            Prelude.thenDo(Prelude.Promises.ellapsed(6000), (function () {
                    setError(function (param) {
                          return false;
                        });
                  }));
          }
          
        }), [error]);
  var match$3 = React.useState(function () {
        return {
                current: "",
                new: "",
                confirm: ""
              };
      });
  var setForm = match$3[1];
  var form = match$3[0];
  var handleCurrentChange = function (current) {
    setForm(function (form) {
          return {
                  current: current,
                  new: form.new,
                  confirm: form.confirm
                };
        });
  };
  var handleNewChange = function ($$new) {
    setForm(function (form) {
          return {
                  current: form.current,
                  new: $$new,
                  confirm: form.confirm
                };
        });
  };
  var handleConfirmChange = function (confirm) {
    setForm(function (form) {
          return {
                  current: form.current,
                  new: form.new,
                  confirm: confirm
                };
        });
  };
  var valid = React.useMemo((function () {
          var match = form.current;
          var match$1 = form.new;
          var match$2 = form.confirm;
          if (match === "" || match$1 === "" || match$2 === "") {
            return false;
          } else {
            return match$1 === match$2;
          }
        }), [form]);
  var disabled = React.useMemo((function () {
          return !Curry._1(Prelude.OptionExported.$$Option.isSome, changePassword);
        }), [changePassword]);
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Stack, {
                      children: [
                        JsxRuntime.jsx(PasswordInput.make, {
                              value: form.current,
                              onChange: handleCurrentChange,
                              label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                        msg: {
                                          NAME: "msg",
                                          VAL: [
                                            "user-settings.current-password-label",
                                            "Current password"
                                          ]
                                        }
                                      })),
                              required: true,
                              disabled: disabled,
                              variant: "outlined",
                              size: "small",
                              showPassword: showPassword,
                              setShowPassword: setShowPassword
                            }),
                        JsxRuntime.jsx(PasswordInput.make, {
                              value: form.new,
                              onChange: handleNewChange,
                              label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                        msg: {
                                          NAME: "msg",
                                          VAL: [
                                            "user-settings.new-password-label",
                                            "New password"
                                          ]
                                        }
                                      })),
                              required: true,
                              disabled: disabled,
                              variant: "outlined",
                              size: "small",
                              showPassword: showPassword,
                              setShowPassword: setShowPassword
                            }),
                        JsxRuntime.jsx(PasswordInput.make, {
                              value: form.confirm,
                              onChange: handleConfirmChange,
                              label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                        msg: {
                                          NAME: "msg",
                                          VAL: [
                                            "user-settings.confirm-password-label",
                                            "Confirm new password"
                                          ]
                                        }
                                      })),
                              required: true,
                              disabled: disabled,
                              variant: "outlined",
                              size: "small",
                              showPassword: showPassword,
                              setShowPassword: setShowPassword
                            }),
                        JsxRuntime.jsxs(Stack, {
                              children: [
                                JsxRuntime.jsx(FlexGrowSpacer.make, {}),
                                JsxRuntime.jsx(Button, {
                                      children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                msg: {
                                                  NAME: "msg",
                                                  VAL: [
                                                    "buttons.reset",
                                                    "Reset"
                                                  ]
                                                }
                                              })),
                                      onClick: (function (param) {
                                          setForm(function (param) {
                                                return {
                                                        current: "",
                                                        new: "",
                                                        confirm: ""
                                                      };
                                              });
                                        }),
                                      disabled: disabled
                                    }),
                                JsxRuntime.jsx(Button, {
                                      children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                msg: {
                                                  NAME: "msg",
                                                  VAL: [
                                                    "buttons.change",
                                                    "Change"
                                                  ]
                                                }
                                              })),
                                      onClick: (function (param) {
                                          Prelude.OptionExported.$$Option.$$do(changePassword, (function (fn) {
                                                  Prelude.PromisedResult.mapError(Prelude.PromisedResult.map(fn(form.current, form.new), (function () {
                                                              setChanged(function (param) {
                                                                    return true;
                                                                  });
                                                            })), (function () {
                                                          setError(function (param) {
                                                                return true;
                                                              });
                                                        }));
                                                }));
                                        }),
                                      disabled: !valid || disabled
                                    })
                              ],
                              direction: ["row"],
                              spacing: "1rem"
                            })
                      ],
                      spacing: "1rem"
                    }),
                JsxRuntime.jsx(Snackbar, {
                      children: Caml_option.some(JsxRuntime.jsx(Alert, {
                                sx: {
                                  width: "100%"
                                },
                                children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                          msg: {
                                            NAME: "str",
                                            VAL: "Password changed"
                                          }
                                        })),
                                severity: "success"
                              })),
                      onClose: (function (param, param$1) {
                          setChanged(function (param) {
                                return false;
                              });
                        }),
                      open: changed
                    }),
                JsxRuntime.jsx(Snackbar, {
                      children: Caml_option.some(JsxRuntime.jsx(Alert, {
                                sx: {
                                  width: "100%"
                                },
                                children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                          msg: {
                                            NAME: "str",
                                            VAL: "Password change failed"
                                          }
                                        })),
                                severity: "error"
                              })),
                      onClose: (function (param, param$1) {
                          setError(function (param) {
                                return false;
                              });
                        }),
                      open: changed
                    })
              ]
            });
}

function UserSettingsView$ChangePassword(props) {
  return JsxRuntime.jsxs(Stack, {
              children: [
                JsxRuntime.jsxs(Stack, {
                      alignItems: "center",
                      children: [
                        JsxRuntime.jsx(Password, {}),
                        JsxRuntime.jsx(Typography, {
                              variant: "h6",
                              children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                        msg: {
                                          NAME: "msg",
                                          VAL: [
                                            "user-settings.change-password",
                                            "Change password"
                                          ]
                                        }
                                      }))
                            })
                      ],
                      direction: ["row"],
                      spacing: 2.0
                    }),
                JsxRuntime.jsx(UserSettingsView$ChangePassword$Form, {})
              ],
              direction: ["column"],
              spacing: 2.0
            });
}

function UserSettingsView$ColorSchemeSwitcher(props) {
  var scheme = UserPreferredColorScheme.usePreferredScheme();
  var setScheme = UserPreferredColorScheme.useSetPreferredScheme();
  var icon = scheme === "system" ? JsxRuntime.jsx(Palette, {}) : (
      scheme === "light" ? JsxRuntime.jsx(LightMode, {}) : JsxRuntime.jsx(DarkMode, {})
    );
  return JsxRuntime.jsxs(Stack, {
              children: [
                JsxRuntime.jsxs(Stack, {
                      alignItems: "center",
                      children: [
                        icon,
                        JsxRuntime.jsx(Typography, {
                              variant: "h6",
                              children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                        msg: {
                                          NAME: "msg",
                                          VAL: [
                                            "user-settings.color-scheme-title",
                                            "Preferred color scheme"
                                          ]
                                        }
                                      }))
                            })
                      ],
                      direction: ["row"],
                      spacing: 2.0
                    }),
                JsxRuntime.jsxs(ToggleButtonGroup, {
                      children: [
                        JsxRuntime.jsx(ToggleButton, {
                              children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                        msg: {
                                          NAME: "msg",
                                          VAL: [
                                            "user-settings.color-scheme-dark",
                                            "Dark"
                                          ]
                                        }
                                      })),
                              selected: scheme === "dark",
                              value: "dark"
                            }),
                        JsxRuntime.jsx(ToggleButton, {
                              children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                        msg: {
                                          NAME: "msg",
                                          VAL: [
                                            "user-settings.color-scheme-light",
                                            "Light"
                                          ]
                                        }
                                      })),
                              selected: scheme === "light",
                              value: "light"
                            }),
                        JsxRuntime.jsx(ToggleButton, {
                              children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                        msg: {
                                          NAME: "msg",
                                          VAL: [
                                            "user-settings.color-scheme-auto",
                                            "Automatic"
                                          ]
                                        }
                                      })),
                              selected: scheme === "system",
                              value: "system"
                            })
                      ],
                      exclusive: true,
                      onChange: (function (param, scheme) {
                          setScheme(scheme);
                        })
                    })
              ],
              direction: ["column"],
              spacing: 2.0
            });
}

function UserSettingsView$LanguageSelector(props) {
  var match = React.useState(function () {
        return I18next.resolvedLanguage;
      });
  var setCurrentLang = match[1];
  var lang = match[0];
  return JsxRuntime.jsxs(Stack, {
              gap: 2.0,
              children: [
                JsxRuntime.jsxs(Stack, {
                      alignItems: "center",
                      gap: 2.0,
                      children: [
                        JsxRuntime.jsx(Translate, {}),
                        JsxRuntime.jsx(Typography, {
                              variant: "h6",
                              children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                        msg: {
                                          NAME: "msg",
                                          VAL: [
                                            "user-settings.language-title",
                                            "Language"
                                          ]
                                        }
                                      }))
                            })
                      ],
                      direction: ["row"]
                    }),
                JsxRuntime.jsxs(ToggleButtonGroup, {
                      children: [
                        JsxRuntime.jsx(ToggleButton, {
                              children: "Español",
                              selected: lang === "es",
                              value: "es"
                            }),
                        JsxRuntime.jsx(ToggleButton, {
                              children: "English",
                              selected: lang === "en",
                              value: "en"
                            })
                      ],
                      exclusive: true,
                      onChange: (function (param, lang$1) {
                          if (lang$1 !== lang) {
                            return Prelude.thenDo(I18next.changeLanguage(lang$1), (function () {
                                          setCurrentLang(function (param) {
                                                return I18next.resolvedLanguage;
                                              });
                                        }));
                          }
                          
                        })
                    })
              ],
              direction: ["column"]
            });
}

function UserSettingsView$ResetApplication(props) {
  var match = SharedDBContext.useQuery();
  return JsxRuntime.jsxs(Stack, {
              children: [
                JsxRuntime.jsxs(Stack, {
                      alignItems: "center",
                      children: [
                        JsxRuntime.jsx(Code, {}),
                        JsxRuntime.jsx(Typography, {
                              variant: "h6",
                              children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                        msg: {
                                          NAME: "msg",
                                          VAL: [
                                            "user-settings.reset-app-heading",
                                            "Reset Application Local DB (development only)"
                                          ]
                                        }
                                      }))
                            })
                      ],
                      direction: ["row"],
                      spacing: 2.0
                    }),
                JsxRuntime.jsx(Typography, {
                      variant: "body1",
                      children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                msg: {
                                  NAME: "msg",
                                  VAL: [
                                    "user-settings.reset-app-message.l1",
                                    "This button deletes the Indexed DB we keep in your browser.\n               It's meant to be used mostly during development, so this\n               section won't appear in the final release."
                                  ]
                                }
                              }))
                    }),
                JsxRuntime.jsx(Typography, {
                      variant: "body1",
                      children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                msg: {
                                  NAME: "msg",
                                  VAL: [
                                    "user-settings.reset-app-message.l2",
                                    "You need to ensure there are no other open tabs of Photocuba,\n               because that might withhold the deletion of the DB and the\n               screen might go blank even after refreshing."
                                  ]
                                }
                              }))
                    }),
                JsxRuntime.jsx(ResetAppButton.make, {
                      dbnames: [
                        match.dbname,
                        "photocuba-fs"
                      ]
                    })
              ],
              direction: ["column"],
              spacing: 2.0
            });
}

function UserSettingsView$Main(props) {
  return JsxRuntime.jsxs(Stack, {
              children: [
                JsxRuntime.jsx(UserSettingsView$UserInfo, {}),
                JsxRuntime.jsx(ProtectedArea.make, {
                      requirement: {
                        TAG: "Feature",
                        _0: Settings.FeatureFlags.manualLanguageSwitch
                      },
                      children: JsxRuntime.jsx(UserSettingsView$LanguageSelector, {})
                    }),
                JsxRuntime.jsx(UserSettingsView$ChangePassword, {}),
                JsxRuntime.jsx(UserSettingsView$ColorSchemeSwitcher, {}),
                JsxRuntime.jsx(UserSettingsView$PaginationSizes, {}),
                JsxRuntime.jsx(ProtectedArea.make, {
                      requirement: {
                        TAG: "Feature",
                        _0: Settings.FeatureFlags.allowAppReset
                      },
                      children: JsxRuntime.jsx(UserSettingsView$ResetApplication, {})
                    }),
                JsxRuntime.jsx(FlexGrowSpacer.make, {})
              ],
              direction: ["column"],
              spacing: "3rem",
              sx: {
                height: "100%",
                maxWidth: {
                  xs: "90vw",
                  lg: "50vw",
                  xl: "35vw"
                },
                margin: "0 auto"
              }
            });
}

var Main = {
  make: UserSettingsView$Main
};

var include = ApplicationMaker.MakeServiceless({
      Manifest: UserSettingsManifest,
      Main: Main
    });

var make = include.make;

export {
  make ,
}
/* include Not a pure module */
