// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$URL from "./URL.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Hashtbl from "rescript/lib/es6/hashtbl.js";
import * as Js_math from "rescript/lib/es6/js_math.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as QueueManager from "./QueueManager.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

var QueueManager$1 = QueueManager.MakeSimpleQueueManager({
      concurrency: 3,
      name: "Network Queue Manager"
    });

function cmp(a, b) {
  return Caml_obj.compare(a.url, b.url);
}

var Comparator = Belt_Id.MakeComparableU({
      cmp: cmp
    });

function hash(x) {
  return Hashtbl.hash(x.url);
}

function eq(a, b) {
  return Caml_obj.equal(a.url, b.url);
}

var Hasher = Belt_Id.MakeHashableU({
      hash: hash,
      eq: eq
    });

function make(url) {
  return {
          url: url,
          alternatives: Belt_Set.make($$URL.Comparator),
          jitter: true
        };
}

function add(r, url) {
  return {
          url: r.url,
          alternatives: Belt_Set.add(r.alternatives, url),
          jitter: r.jitter
        };
}

function jitter(r) {
  return {
          url: r.url,
          alternatives: r.alternatives,
          jitter: true
        };
}

function noJitter(r) {
  return {
          url: r.url,
          alternatives: r.alternatives,
          jitter: false
        };
}

var JitterError = /* @__PURE__ */Caml_exceptions.create("Network.Request.JitterError");

function buildAction(p) {
  if (typeof p !== "object") {
    return function () {
      var jitter = Js_math.random_int(500, 2000);
      return Prelude.catchResolve(Prelude.thenResolve(Prelude.Promises.ellapsed(jitter), (function () {
                        throw {
                              RE_EXN_ID: JitterError,
                              Error: new Error()
                            };
                      })), (function (e) {
                    return {
                            TAG: "Error",
                            _0: e
                          };
                  }));
    };
  } else {
    return $$URL.fetcher(p._0);
  }
}

function fetcher(request) {
  return function () {
    var urls = Curry._2(Prelude.$$Array.concat, [request.url], Belt_Set.toArray(request.alternatives));
    var actions;
    if (request.jitter) {
      var fetches = urls.map(function (u) {
            return {
                    TAG: "FetchURL",
                    _0: u
                  };
          });
      var jitters = urls.map(function (param) {
            return "Jitter";
          });
      var mixed = Prelude.$$Array.flatMap(Curry._2(Prelude.$$Array.zip, fetches, jitters), (function (param) {
              return [
                      param[0],
                      param[1]
                    ];
            }));
      var mixed$1 = Curry._3(Prelude.$$Array.slice, mixed, 0, mixed.length - 1 | 0);
      actions = mixed$1.map(buildAction);
    } else {
      actions = urls.map($$URL.fetcher);
    }
    return Prelude.PromisedResult.map(Prelude.PromisedResult.untilOk(actions), (function (r) {
                  return r;
                }));
  };
}

function $$fetch(request) {
  return fetcher(request)();
}

var $$Request = {
  Comparator: Comparator,
  Hasher: Hasher,
  make: make,
  add: add,
  jitter: jitter,
  noJitter: noJitter,
  JitterError: JitterError,
  fetcher: fetcher,
  $$fetch: $$fetch
};

function downloadRequest(request) {
  var identifier = request.url;
  return QueueManager$1.append(fetcher(request), identifier);
}

function download(url) {
  return downloadRequest(make(url));
}

export {
  QueueManager$1 as QueueManager,
  $$Request ,
  downloadRequest ,
  download ,
}
/* QueueManager Not a pure module */
