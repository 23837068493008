// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Folder from "../models/Folder.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as $$Document from "../models/Document.js";
import * as Hooks from "@mantine/hooks";
import * as JsxRuntime from "react/jsx-runtime";

var context = React.createContext(undefined);

var make = context.Provider;

function useCurrent() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return [
                        param.direction,
                        param.field
                      ];
              }));
}

function useToggleDirection() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                var setDirection = param.setDirection;
                var direction = param.direction;
                return function () {
                  if (direction === "asc") {
                    return setDirection("desc");
                  } else {
                    return setDirection("asc");
                  }
                };
              }));
}

function useSetField() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                var setField = param.setField;
                var field = param.field;
                return function (newfield) {
                  if (newfield !== field) {
                    return setField(newfield);
                  }
                  
                };
              }));
}

function useSort() {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, useCurrent(), (function (param) {
                    return Folder.Order.sort(param[0], param[1]);
                  })), (function (prim) {
                return prim;
              }));
}

function CatalogBrowserSortContext$Folder(props) {
  var match = Hooks.useLocalStorage({
        key: "photocuba-folder-sort-direction",
        defaultValue: "asc",
        deserialize: (function (v) {
            return Prelude.default(Folder.Order.directionFromJs(v), "asc");
          }),
        serialize: (function (v) {
            return v;
          })
      });
  var match$1 = Hooks.useLocalStorage({
        key: "photocuba-folder-sort-field",
        defaultValue: "name",
        deserialize: (function (v) {
            return Prelude.default(Folder.Order.fieldFromJs(v), "name");
          }),
        serialize: (function (v) {
            return v;
          })
      });
  return JsxRuntime.jsx(make, {
              value: {
                direction: match[0],
                field: match$1[0],
                setDirection: match[1],
                setField: match$1[1]
              },
              children: props.children
            });
}

var context$1 = React.createContext(undefined);

var make$1 = context$1.Provider;

function useCurrent$1() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context$1), (function (param) {
                return [
                        param.direction,
                        param.field
                      ];
              }));
}

function useToggleDirection$1() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context$1), (function (param) {
                var setDirection = param.setDirection;
                var direction = param.direction;
                return function () {
                  if (direction === "asc") {
                    return setDirection("desc");
                  } else {
                    return setDirection("asc");
                  }
                };
              }));
}

function useSetField$1() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context$1), (function (param) {
                var setField = param.setField;
                var field = param.field;
                return function (newfield) {
                  if (newfield !== field) {
                    return setField(newfield);
                  }
                  
                };
              }));
}

function useSort$1() {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, useCurrent$1(), (function (param) {
                    return $$Document.Order.sort(param[0], param[1]);
                  })), (function (prim) {
                return prim;
              }));
}

function CatalogBrowserSortContext$Document(props) {
  var match = Hooks.useLocalStorage({
        key: "photocuba-doc-sort-direction",
        defaultValue: "asc",
        deserialize: (function (v) {
            return Prelude.default($$Document.Order.directionFromJs(v), "asc");
          }),
        serialize: (function (v) {
            return v;
          }),
        getInitialValueInEffect: true
      });
  var match$1 = Hooks.useLocalStorage({
        key: "photocuba-doc-sort-field",
        defaultValue: "name",
        deserialize: (function (v) {
            return Prelude.default($$Document.Order.fieldFromJs(v), "name");
          }),
        serialize: (function (v) {
            return v;
          }),
        getInitialValueInEffect: true
      });
  return JsxRuntime.jsx(make$1, {
              value: {
                direction: match[0],
                field: match$1[0],
                setDirection: match[1],
                setField: match$1[1]
              },
              children: props.children
            });
}

function CatalogBrowserSortContext(props) {
  return JsxRuntime.jsx(CatalogBrowserSortContext$Folder, {
              children: JsxRuntime.jsx(CatalogBrowserSortContext$Document, {
                    children: props.children
                  })
            });
}

var Folder$1 = {
  useCurrent: useCurrent,
  useToggleDirection: useToggleDirection,
  useSetField: useSetField,
  useSort: useSort
};

var $$Document$1 = {
  useCurrent: useCurrent$1,
  useToggleDirection: useToggleDirection$1,
  useSetField: useSetField$1,
  useSort: useSort$1
};

var make$2 = CatalogBrowserSortContext;

export {
  Folder$1 as Folder,
  $$Document$1 as $$Document,
  make$2 as make,
}
/* context Not a pure module */
