// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../libs/i18n/ReactI18Next.js";
import * as AskNameDialog from "./AskNameDialog.js";
import * as JsxRuntime from "react/jsx-runtime";
import DialogContentText from "@mui/material/DialogContentText";

function RenameDialog(props) {
  var title = Prelude.default(props.title, {
        NAME: "str",
        VAL: "Rename"
      });
  var content = Prelude.default(props.content, JsxRuntime.jsx(DialogContentText, {
            children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                      msg: {
                        NAME: "str",
                        VAL: "Enter the new name"
                      }
                    }))
          }));
  var label = Prelude.default(props.label, {
        NAME: "str",
        VAL: "New name"
      });
  return JsxRuntime.jsx(AskNameDialog.make, {
              name: props.name,
              onChange: props.onChange,
              onClose: props.onClose,
              open_: props.open_,
              title: title,
              content: content,
              fieldLabel: label,
              buttonLabel: {
                NAME: "str",
                VAL: "Rename"
              }
            });
}

var make = RenameDialog;

export {
  make ,
}
/* ReactI18Next Not a pure module */
