// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SearchQuery from "../models/SearchQuery.js";
import * as ReactI18Next from "../../../../libs/i18n/ReactI18Next.js";
import * as ProtectedArea from "../../../utils/ProtectedArea.js";
import * as CityAutocomplete from "./CityAutocomplete.js";
import * as TagsAutocomplete from "./TagsAutocomplete.js";
import * as JsxRuntime from "react/jsx-runtime";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import * as AuthorsAutocomplete from "./AuthorsAutocomplete.js";
import * as SearchFiltersContext from "../context/SearchFiltersContext.js";
import Tooltip from "@mui/material/Tooltip";
import Abc from "@mui/icons-material/Abc";
import TextField from "@mui/material/TextField";
import * as CollectionsAutocomplete from "./CollectionsAutocomplete.js";
import * as DocumentAttributeLabels from "../../catalog/components/document/DocumentAttributeLabels.js";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import ToggleButton from "@mui/material/ToggleButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextSnippet from "@mui/icons-material/TextSnippet";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

function SearchFiltersPanel(props) {
  var filters = SearchFiltersContext.useResolvedFilters();
  var setAuthors = SearchFiltersContext.useSetAuthors();
  var setCities = SearchFiltersContext.useSetCities();
  var setTags = SearchFiltersContext.useSetTags();
  var setCollections = SearchFiltersContext.useSetCollections();
  var setText = SearchFiltersContext.useSetText();
  var match = React.useState(function () {
        return "";
      });
  var setValueText = match[1];
  var valueText = match[0];
  var match$1 = React.useState(function () {
        return "ModeSubstr";
      });
  var setTextMode = match$1[1];
  var textMode = match$1[0];
  var handleOnChangeText = function (ev) {
    setValueText(function (param) {
          return ev.target.value;
        });
  };
  var match$2 = React.useState(function () {
        
      });
  var setTimer = match$2[1];
  var timer = match$2[0];
  var clearTimer = React.useCallback((function () {
          if (timer !== undefined) {
            clearTimeout(Caml_option.valFromOption(timer));
            return ;
          }
          
        }), [
        timer,
        setTimer
      ]);
  React.useEffect((function () {
          clearTimer();
          var tid = setTimeout((function () {
                  setText(SearchQuery.Filters.$$Text.make(textMode, valueText));
                }), 200);
          setTimer(function (param) {
                return Caml_option.some(tid);
              });
          return clearTimer;
        }), [
        valueText,
        textMode
      ]);
  var inputProps_ = React.useMemo((function () {
          if (valueText.length === 0) {
            return ;
          } else {
            return {
                    endAdornment: Caml_option.some(JsxRuntime.jsxs(InputAdornment, {
                              position: "end",
                              children: [
                                JsxRuntime.jsx(Tooltip, {
                                      children: JsxRuntime.jsxs(ToggleButtonGroup, {
                                            children: [
                                              JsxRuntime.jsx(ToggleButton, {
                                                    children: Caml_option.some(JsxRuntime.jsx(Abc, {
                                                              fontSize: "small"
                                                            })),
                                                    value: "ModeSubstr"
                                                  }, "words"),
                                              JsxRuntime.jsx(ToggleButton, {
                                                    children: Caml_option.some(JsxRuntime.jsx(TextSnippet, {
                                                              fontSize: "small"
                                                            })),
                                                    value: "ModeFts"
                                                  }, "fts")
                                            ],
                                            exclusive: true,
                                            onChange: (function (param, mode) {
                                                if (!(mode == null)) {
                                                  return setTextMode(function (param) {
                                                              return mode;
                                                            });
                                                }
                                                
                                              }),
                                            size: "small",
                                            sx: {
                                              marginX: "0.5rem"
                                            },
                                            value: textMode
                                          }),
                                      title: JsxRuntime.jsx("p", {
                                            children: JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                  msg: {
                                                    NAME: "msg",
                                                    VAL: [
                                                      "search.text-search-mode-tooltip",
                                                      "Match by any sub-string (ABC), or by a similarity score"
                                                    ]
                                                  }
                                                })
                                          })
                                    }),
                                JsxRuntime.jsx(IconButton, {
                                      children: Caml_option.some(JsxRuntime.jsx(Close, {
                                                fontSize: "small"
                                              })),
                                      onClick: (function (param) {
                                          setValueText(function (param) {
                                                return "";
                                              });
                                        })
                                    })
                              ]
                            }))
                  };
          }
        }), [
        valueText,
        textMode
      ]);
  return JsxRuntime.jsx(Stack, {
              children: Caml_option.some(JsxRuntime.jsxs(Grid, {
                        children: [
                          JsxRuntime.jsx(Grid, {
                                children: Caml_option.some(JsxRuntime.jsx(TextField, {
                                          fullWidth: true,
                                          InputProps: inputProps_,
                                          label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                    msg: DocumentAttributeLabels.get("text")
                                                  })),
                                          onChange: handleOnChangeText,
                                          value: valueText
                                        })),
                                item: true,
                                lg: 4,
                                xs: 7
                              }),
                          JsxRuntime.jsx(ProtectedArea.make, {
                                requirement: {
                                  TAG: "Pred",
                                  _0: Curry._1(Prelude.OptionExported.$$Option.isNone, filters.untagged)
                                },
                                children: JsxRuntime.jsx(Grid, {
                                      children: Caml_option.some(JsxRuntime.jsx(TagsAutocomplete.make, {
                                                tags: filters.tags,
                                                onChange: setTags,
                                                label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                          msg: DocumentAttributeLabels.get("tags")
                                                        })),
                                                fullWidth: true,
                                                limitTags: 2,
                                                allowUnapproved: true
                                              })),
                                      item: true,
                                      lg: 2,
                                      xs: 5
                                    })
                              }),
                          JsxRuntime.jsx(Grid, {
                                children: Caml_option.some(JsxRuntime.jsx(CollectionsAutocomplete.make, {
                                          collections: filters.collections,
                                          onChange: setCollections,
                                          label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                    msg: DocumentAttributeLabels.get("collections")
                                                  })),
                                          fullWidth: true,
                                          limitTags: 1
                                        })),
                                item: true,
                                lg: Curry._1(Prelude.OptionExported.$$Option.isNone, filters.untagged) ? 2 : 4,
                                xs: Curry._1(Prelude.OptionExported.$$Option.isNone, filters.untagged) ? 4 : 5
                              }),
                          JsxRuntime.jsx(Grid, {
                                children: Caml_option.some(JsxRuntime.jsx(AuthorsAutocomplete.make, {
                                          authors: filters.authors,
                                          onChange: setAuthors,
                                          label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                    msg: DocumentAttributeLabels.get("author")
                                                  })),
                                          fullWidth: true,
                                          limitTags: 1,
                                          multiple: true
                                        })),
                                item: true,
                                lg: 2,
                                xs: Curry._1(Prelude.OptionExported.$$Option.isNone, filters.untagged) ? 4 : 6
                              }),
                          JsxRuntime.jsx(Grid, {
                                children: Caml_option.some(JsxRuntime.jsx(CityAutocomplete.make, {
                                          cities: filters.cities,
                                          onChange: setCities,
                                          label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                    msg: DocumentAttributeLabels.get("city")
                                                  })),
                                          fullWidth: true,
                                          limitTags: 1,
                                          multiple: true
                                        })),
                                item: true,
                                lg: 2,
                                xs: Curry._1(Prelude.OptionExported.$$Option.isNone, filters.untagged) ? 4 : 6
                              })
                        ],
                        container: true,
                        spacing: 2
                      })),
              direction: ["column"],
              spacing: 2.0,
              sx: {
                padding: "1rem"
              },
              useFlexGap: true
            });
}

var make = SearchFiltersPanel;

export {
  make ,
}
/* react Not a pure module */
