// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../../../libs/i18n/ReactI18Next.js";
import * as JsxRuntime from "react/jsx-runtime";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

function DocumentBrowserSaveDialog(props) {
  var onCancel = props.onCancel;
  var onSave = props.onSave;
  var $$document = props.document;
  var match = React.useState(function () {
        return {
                TAG: "Init",
                _0: $$document
              };
      });
  var setState = match[1];
  var state = match[0];
  var onClickSave = React.useCallback((function (param) {
          if (typeof state !== "object") {
            return ;
          }
          if (state.TAG !== "Init") {
            return ;
          }
          var $$document = state._0;
          setState(function (param) {
                return {
                        TAG: "Updating",
                        _0: $$document
                      };
              });
        }), [state]);
  React.useEffect((function () {
          if (typeof state === "object" && state.TAG !== "Init") {
            Prelude.thenDo(onSave(state._0), (function () {
                    setState(function (param) {
                          return "Done";
                        });
                  }));
          }
          
        }), [
        state,
        onSave
      ]);
  var tmp;
  tmp = typeof state !== "object" || state.TAG !== "Init" ? true : false;
  return JsxRuntime.jsxs(Dialog, {
              open: true,
              children: [
                JsxRuntime.jsx(DialogTitle, {
                      id: "alert-dialog-title",
                      children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                msg: {
                                  NAME: "msg",
                                  VAL: [
                                    "document-browser.confirmation-dialog-header",
                                    "Confirm the changes"
                                  ]
                                }
                              }))
                    }),
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsx(DialogContentText, {
                                id: "alert-dialog-description",
                                children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                          msg: {
                                            NAME: "msg",
                                            VAL: [
                                              "document-browser.confirmation-dialog-body",
                                              "Please, confirm you really want to save the changes.  This operation cannot be undone."
                                            ]
                                          }
                                        }))
                              }))
                    }),
                JsxRuntime.jsxs(DialogActions, {
                      children: [
                        JsxRuntime.jsx(Button, {
                              children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                        msg: {
                                          NAME: "msg",
                                          VAL: [
                                            "buttons.cancel",
                                            "Cancel"
                                          ]
                                        }
                                      })),
                              onClick: (function (param) {
                                  onCancel();
                                })
                            }),
                        JsxRuntime.jsx(Button, {
                              children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                        msg: {
                                          NAME: "msg",
                                          VAL: [
                                            "buttons.save",
                                            "Save"
                                          ]
                                        }
                                      })),
                              onClick: onClickSave,
                              disabled: tmp
                            })
                      ]
                    })
              ]
            });
}

var make = DocumentBrowserSaveDialog;

export {
  make ,
}
/* react Not a pure module */
