// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Collection from "../models/Collection.js";
import * as Hooks from "@mantine/hooks";
import * as NamespaceContext from "../../../global/context/NamespaceContext.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CollectionsSelectionManager from "./CollectionsSelectionManager.js";

var context = React.createContext(undefined);

var make = context.Provider;

var Provider = {
  make: make
};

function dispatch(filters, action) {
  if (typeof action !== "object") {
    if (action === "RemoveStartsWith") {
      var newrecord = Caml_obj.obj_dup(filters);
      newrecord.startswith = undefined;
      return newrecord;
    }
    var newrecord$1 = Caml_obj.obj_dup(filters);
    newrecord$1.contains = undefined;
    return newrecord$1;
  } else {
    switch (action.TAG) {
      case "Reset" :
          return Collection.Filters.clone(action._0);
      case "StartsWith" :
          var newrecord$2 = Caml_obj.obj_dup(filters);
          newrecord$2.startswith = action._0;
          return newrecord$2;
      case "Contains" :
          var newrecord$3 = Caml_obj.obj_dup(filters);
          newrecord$3.contains = action._0;
          return newrecord$3;
      case "SetOrderColumn" :
          var newrecord$4 = Caml_obj.obj_dup(filters);
          newrecord$4.order_by = action._0;
          return newrecord$4;
      case "SetOrderDirection" :
          var newrecord$5 = Caml_obj.obj_dup(filters);
          newrecord$5.order_direction = action._0;
          return newrecord$5;
      
    }
  }
}

var Filters = {
  context: context,
  Provider: Provider,
  dispatch: dispatch
};

var context$1 = React.createContext({
      setFilters: (function (param) {
          
        }),
      setStartsWith: (function (param) {
          
        }),
      removeStartsWith: (function () {
          
        }),
      setContains: (function (param) {
          
        }),
      removeContains: (function () {
          
        }),
      setOrderColumn: (function (param) {
          
        }),
      setOrderDirection: (function (param) {
          
        })
    });

var make$1 = context$1.Provider;

var Provider$1 = {
  make: make$1
};

var Dispatcher = {
  context: context$1,
  Provider: Provider$1
};

function useFilters() {
  var ns = NamespaceContext.useNamespace();
  var defaultFilter = React.useMemo((function () {
          return Collection.Filters.make(ns.id);
        }), [ns]);
  return Prelude.default(React.useContext(context), defaultFilter);
}

function useStartsWith() {
  var match = React.useContext(context);
  if (match === undefined) {
    return "";
  }
  var startswith = match.startswith;
  if (startswith !== undefined) {
    return startswith.trim();
  } else {
    return "";
  }
}

function useContains() {
  var match = React.useContext(context);
  if (match === undefined) {
    return "";
  }
  var contains = match.contains;
  if (contains !== undefined) {
    return contains.trim();
  } else {
    return "";
  }
}

function useOrderColumn() {
  var match = React.useContext(context);
  if (match === undefined) {
    return "name";
  }
  var orderBy = match.order_by;
  if (orderBy !== undefined) {
    return orderBy;
  } else {
    return "name";
  }
}

function useOrderDirection() {
  var match = React.useContext(context);
  if (match === undefined) {
    return "";
  }
  var orderDirection = match.order_direction;
  if (orderDirection !== undefined) {
    return orderDirection;
  } else {
    return "";
  }
}

function useSetFilters() {
  return React.useContext(context$1).setFilters;
}

function useSetStartsWith() {
  return React.useContext(context$1).setStartsWith;
}

function useSetContains() {
  return React.useContext(context$1).setContains;
}

function useRemoveStartsWith() {
  return React.useContext(context$1).removeStartsWith;
}

function useRemoveContains() {
  return React.useContext(context$1).removeContains;
}

function useSetOrderColumn() {
  return React.useContext(context$1).setOrderColumn;
}

function useSetOrderDirection() {
  return React.useContext(context$1).setOrderDirection;
}

function CollectionsFiltersContext(props) {
  var ns = NamespaceContext.useNamespace();
  var reset = CollectionsSelectionManager.useReset();
  var match = Hooks.useLocalStorage({
        key: "photocuba-collections-startswith",
        getInitialValueInEffect: false
      });
  var removeStartsWith = match[2];
  var setStartsWith = match[1];
  var match$1 = Hooks.useLocalStorage({
        key: "photocuba-collections-contains",
        getInitialValueInEffect: false
      });
  var removeContains = match$1[2];
  var setContains = match$1[1];
  var match$2 = Hooks.useLocalStorage({
        key: "photocuba-collections-order-by",
        defaultValue: "name",
        deserialize: Collection.Order.columnFromString,
        serialize: Collection.Order.columnToString,
        getInitialValueInEffect: false
      });
  var setOrderBy = match$2[1];
  var match$3 = Hooks.useLocalStorage({
        key: "photocuba-collections-order-direction",
        defaultValue: "",
        deserialize: Collection.Order.directionFromString,
        serialize: Collection.Order.directionToString,
        getInitialValueInEffect: false
      });
  var setOrderDirection = match$3[1];
  var newrecord = Caml_obj.obj_dup(Collection.Filters.make(ns.id));
  var match$4 = React.useReducer(dispatch, (newrecord.order_direction = match$3[0], newrecord.order_by = match$2[0], newrecord.contains = match$1[0], newrecord.startswith = match[0], newrecord));
  var dispatch$1 = match$4[1];
  var filters = match$4[0];
  var setFilters = function (filters) {
    var s = filters.startswith;
    if (s !== undefined) {
      setStartsWith(s);
    } else {
      removeStartsWith();
    }
    var s$1 = filters.contains;
    if (s$1 !== undefined) {
      setContains(s$1);
    } else {
      removeContains();
    }
    Prelude.OptionExported.$$Option.$$do(reset, (function (fn) {
            fn();
          }));
    dispatch$1({
          TAG: "Reset",
          _0: filters
        });
  };
  var setStartsWith$1 = function (start) {
    setStartsWith(start);
    Prelude.OptionExported.$$Option.$$do(reset, (function (fn) {
            fn();
          }));
    dispatch$1({
          TAG: "StartsWith",
          _0: start
        });
  };
  var removeStartsWith$1 = function () {
    removeStartsWith();
    Prelude.OptionExported.$$Option.$$do(reset, (function (fn) {
            fn();
          }));
    dispatch$1("RemoveStartsWith");
  };
  var setContains$1 = function (value) {
    setContains(value);
    Prelude.OptionExported.$$Option.$$do(reset, (function (fn) {
            fn();
          }));
    dispatch$1({
          TAG: "Contains",
          _0: value
        });
  };
  var removeContains$1 = function () {
    removeContains();
    Prelude.OptionExported.$$Option.$$do(reset, (function (fn) {
            fn();
          }));
    dispatch$1("RemoveContains");
  };
  var setOrderColumn = function (column) {
    setOrderBy(column);
    dispatch$1({
          TAG: "SetOrderColumn",
          _0: column
        });
  };
  var setOrderDirection$1 = function (dir) {
    setOrderDirection(dir);
    dispatch$1({
          TAG: "SetOrderDirection",
          _0: dir
        });
  };
  console.log("CollectionsFiltersContext", filters);
  return JsxRuntime.jsx(make$1, {
              value: {
                setFilters: setFilters,
                setStartsWith: setStartsWith$1,
                removeStartsWith: removeStartsWith$1,
                setContains: setContains$1,
                removeContains: removeContains$1,
                setOrderColumn: setOrderColumn,
                setOrderDirection: setOrderDirection$1
              },
              children: JsxRuntime.jsx(make, {
                    value: filters,
                    children: props.children
                  })
            });
}

var make$2 = CollectionsFiltersContext;

export {
  Filters ,
  Dispatcher ,
  useFilters ,
  useStartsWith ,
  useContains ,
  useOrderColumn ,
  useOrderDirection ,
  useSetFilters ,
  useSetStartsWith ,
  useSetContains ,
  useRemoveStartsWith ,
  useRemoveContains ,
  useSetOrderColumn ,
  useSetOrderDirection ,
  make$2 as make,
}
/* context Not a pure module */
