// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_math from "rescript/lib/es6/js_math.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Settings from "../../../Settings.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as AccountsManager from "./AccountsManager.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as AccountsFiltersContext from "./AccountsFiltersContext.js";

var context = React.createContext(undefined);

var make = context.Provider;

function useCount() {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                    return param.count;
                  })), 0);
}

function useAccounts() {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                    return param.results;
                  })), []);
}

var context$1 = React.createContext({
      page: 1,
      pageSize: Settings.Pagination.defaultSize,
      setPage: (function (param) {
          
        }),
      setPageSize: (function (param) {
          
        }),
      refresh: (function () {
          
        })
    });

var make$1 = context$1.Provider;

function useCurrentPage() {
  return React.useContext(context$1).page;
}

function useCurrentPageSize() {
  return React.useContext(context$1).pageSize;
}

function useSetCurrentPage() {
  return React.useContext(context$1).setPage;
}

function useSetCurrentPageSize() {
  return React.useContext(context$1).setPageSize;
}

function usePagesCount() {
  var count = useCount();
  var pageSize = useCurrentPageSize();
  return Js_math.ceil_int(count / pageSize);
}

function useRefresh() {
  return React.useContext(context$1).refresh;
}

function AccountsListService(props) {
  var match = React.useState(function () {
        
      });
  var setResult = match[1];
  var match$1 = React.useState(function () {
        return 1;
      });
  var setPage = match$1[1];
  var page = match$1[0];
  var match$2 = Settings.Pagination.AccountsManager.use();
  var setPageSize = match$2[1];
  var pageSize = match$2[0];
  var filters = AccountsFiltersContext.useFilters();
  var setFilters = AccountsFiltersContext.useSetFilters();
  var search = AccountsManager.useSearchAccounts();
  var match$3 = React.useState(function () {
        
      });
  var setTimer = match$3[1];
  var timer = match$3[0];
  var clearTimer = React.useCallback((function () {
          if (timer !== undefined) {
            clearTimeout(Caml_option.valFromOption(timer));
          }
          setTimer(function (param) {
                
              });
        }), [timer]);
  var performSearch = React.useMemo((function () {
          if (search !== undefined) {
            return function (filters, page, pageSize) {
              Prelude.thenDo(search(filters, page, pageSize), (function (res) {
                      if (res.TAG !== "Ok") {
                        return ;
                      }
                      var result = res._0;
                      setResult(function (param) {
                            return result;
                          });
                    }));
            };
          } else {
            return function (param, param$1, param$2) {
              
            };
          }
        }), [search]);
  React.useEffect((function () {
          clearTimer();
          var timer = setTimeout((function () {
                  performSearch(filters, page, pageSize);
                }), 500);
          setTimer(function (param) {
                return Caml_option.some(timer);
              });
        }), [
        filters,
        page,
        pageSize
      ]);
  return JsxRuntime.jsx(make$1, {
              value: {
                page: page,
                pageSize: pageSize,
                setPage: (function (page) {
                    setPage(function (param) {
                          return page;
                        });
                  }),
                setPageSize: (function (pageSize) {
                    setPageSize(pageSize);
                  }),
                refresh: React.useCallback((function () {
                        setFilters(filters);
                      }), [filters])
              },
              children: JsxRuntime.jsx(make, {
                    value: match[0],
                    children: props.children
                  })
            });
}

var make$2 = AccountsListService;

export {
  useCount ,
  useAccounts ,
  usePagesCount ,
  useCurrentPage ,
  useCurrentPageSize ,
  useSetCurrentPage ,
  useSetCurrentPageSize ,
  useRefresh ,
  make$2 as make,
}
/* context Not a pure module */
