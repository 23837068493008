// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Units from "../../../utils/Units.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../../libs/i18n/ReactI18Next.js";
import * as PendingUpload from "../models/PendingUpload.js";
import * as ReactI18next from "react-i18next";
import * as JsxRuntime from "react/jsx-runtime";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import * as UploadDocumentService from "./services/UploadDocumentService.js";
import DialogTitle from "@mui/material/DialogTitle";
import * as CurrentDocumentListManager from "./CurrentDocumentListManager.js";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import LinearProgress from "@mui/material/LinearProgress";
import DialogContentText from "@mui/material/DialogContentText";

var context = React.createContext(undefined);

var make = context.Provider;

function useUpload() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.upload;
              }));
}

function useClear() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.clear;
              }));
}

function usePause() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.pause;
              }));
}

function useResume() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.resume;
              }));
}

function useGetUploads() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.get;
              }));
}

function notify(queue, item) {
  return queue.map(function (p) {
              if (Caml_obj.equal(p.id, item.id)) {
                return item;
              } else {
                return p;
              }
            });
}

function dispatcher(state, action) {
  var queue;
  if (state.TAG === "Paused") {
    var queue$1 = state._0;
    if (typeof action === "object") {
      if (action.TAG === "Add") {
        return {
                TAG: "Paused",
                _0: Curry._2(Prelude.$$Array.concat, queue$1, action._0)
              };
      } else {
        return {
                TAG: "Paused",
                _0: notify(queue$1, action._0)
              };
      }
    }
    switch (action) {
      case "Clear" :
          return {
                  TAG: "Working",
                  startTime: window.performance.now(),
                  startSize: 0.0,
                  queue: []
                };
      case "Pause" :
          return state;
      case "Resume" :
          return {
                  TAG: "Working",
                  startTime: window.performance.now(),
                  startSize: Curry._2(Prelude.$$Array.keep, queue$1, (function (p) {
                            return !PendingUpload.pending(p);
                          })).reduce((function (r, p) {
                          return r + PendingUpload.uploadSize(p);
                        }), 0.0),
                  queue: queue$1
                };
      case "Retry" :
          queue = queue$1;
          break;
      
    }
  } else {
    var queue$2 = state.queue;
    var startSize = state.startSize;
    var startTime = state.startTime;
    if (typeof action !== "object") {
      switch (action) {
        case "Clear" :
            return {
                    TAG: "Working",
                    startTime: 0.0,
                    startSize: 0.0,
                    queue: []
                  };
        case "Pause" :
            return {
                    TAG: "Paused",
                    _0: queue$2
                  };
        case "Resume" :
            return state;
        case "Retry" :
            queue = queue$2;
            break;
        
      }
    } else {
      if (action.TAG !== "Add") {
        return {
                TAG: "Working",
                startTime: startTime,
                startSize: startSize,
                queue: notify(queue$2, action._0)
              };
      }
      if (queue$2.length !== 0) {
        return {
                TAG: "Working",
                startTime: startTime,
                startSize: startSize,
                queue: Curry._2(Prelude.$$Array.concat, queue$2, action._0)
              };
      }
      var queue$3 = Curry._2(Prelude.$$Array.concat, action._0, []);
      return {
              TAG: "Working",
              startTime: window.performance.now(),
              startSize: Curry._2(Prelude.$$Array.keep, queue$3, (function (p) {
                        return !PendingUpload.pending(p);
                      })).reduce((function (r, p) {
                      return r + PendingUpload.uploadSize(p);
                    }), 0.0),
              queue: queue$3
            };
    }
  }
  var queue$4 = Curry._2(Prelude.$$Array.keep, queue, PendingUpload.failed).map(PendingUpload.retry);
  return {
          TAG: "Working",
          startTime: window.performance.now(),
          startSize: Curry._2(Prelude.$$Array.keep, queue$4, (function (p) {
                    return !PendingUpload.pending(p);
                  })).reduce((function (r, p) {
                  return r + PendingUpload.uploadSize(p);
                }), 0.0),
          queue: queue$4
        };
}

function UploadManager$UploadDialog(props) {
  var onRetry = props.onRetry;
  var onCancel = props.onCancel;
  var onResume = props.onResume;
  var onPause = props.onPause;
  var state = props.state;
  var begin = CurrentDocumentListManager.useBeginTransaction();
  var commit = CurrentDocumentListManager.useCommit();
  var items = React.useMemo((function () {
          if (state.TAG === "Paused") {
            return state._0;
          } else {
            return state.queue;
          }
        }), [state]);
  var speed = React.useMemo((function () {
          if (state.TAG === "Paused") {
            return ;
          }
          var now = window.performance.now();
          var elapsed = (now - state.startTime) / 1000.0;
          if (elapsed <= 0.0) {
            return ;
          }
          var uploaded = Curry._2(Prelude.$$Array.keep, state.queue, (function (p) {
                    return !PendingUpload.pending(p);
                  })).reduce((function (r, p) {
                  return r + PendingUpload.uploadSize(p);
                }), 0.0);
          var uploaded$1 = Units.toBest({
                NAME: "bytes",
                VAL: uploaded - state.startSize
              });
          var speed = (Units.extractUnsafe(uploaded$1) / elapsed).toFixed();
          var variant = uploaded$1.NAME;
          if (variant === "kilo") {
            return speed + " kB/s";
          } else if (variant === "mega") {
            return speed + " MB/s";
          } else if (variant === "bytes") {
            return speed + " B/s";
          } else {
            return speed + " GB/s";
          }
        }), [state]);
  var hasFailures = React.useMemo((function () {
          return Curry._2(Prelude.$$Array.keep, items, PendingUpload.failed).length !== 0;
        }), [items]);
  var hasPending = React.useMemo((function () {
          return Curry._2(Prelude.$$Array.keep, items, PendingUpload.pending).length !== 0;
        }), [items]);
  var pauseButton = React.useMemo((function () {
          if (state.TAG === "Paused" || !hasPending) {
            return null;
          } else {
            return JsxRuntime.jsx(Button, {
                        children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                  msg: {
                                    NAME: "msg",
                                    VAL: [
                                      "upload.pause-button",
                                      "Pause"
                                    ]
                                  }
                                })),
                        onClick: (function (param) {
                            onPause();
                          })
                      });
          }
        }), [
        state,
        hasPending
      ]);
  var resumeButton = React.useMemo((function () {
          if (state.TAG === "Paused" && hasPending) {
            return JsxRuntime.jsx(Button, {
                        children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                  msg: {
                                    NAME: "msg",
                                    VAL: [
                                      "upload.resume-button",
                                      "Resume"
                                    ]
                                  }
                                })),
                        onClick: (function (param) {
                            onResume();
                          })
                      });
          } else {
            return null;
          }
        }), [
        state,
        hasPending
      ]);
  var retryButton = React.useMemo((function () {
          if (hasFailures && !hasPending) {
            return JsxRuntime.jsx(Button, {
                        children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                  msg: {
                                    NAME: "msg",
                                    VAL: [
                                      "upload.retry-button",
                                      "Retry"
                                    ]
                                  }
                                })),
                        onClick: (function (param) {
                            onRetry();
                          })
                      });
          } else {
            return null;
          }
        }), [
        state,
        hasPending,
        hasFailures
      ]);
  var total = React.useMemo((function () {
          return items.length;
        }), [items]);
  var progress = React.useMemo((function () {
          var done = Curry._2(Prelude.$$Array.keep, items, (function (p) {
                  if (p.status === "uploaded") {
                    return true;
                  } else {
                    return p.status === "failed";
                  }
                })).length;
          if (total > 0) {
            return Caml_int32.div(Math.imul(done, 100), total);
          } else {
            return 0;
          }
        }), [
        items,
        total
      ]);
  var isWorking = React.useMemo((function () {
          if (state.TAG === "Paused") {
            return false;
          } else {
            return Curry._1(Prelude.OptionExported.$$Option.isSome, state.queue.find(function (q) {
                            var match = q.status;
                            return !(match === "uploaded" || match === "failed");
                          }));
          }
        }), [state]);
  var match = React.useState(function () {
        return false;
      });
  var setInTransaction = match[1];
  var inTransaction = match[0];
  React.useEffect((function () {
          if (isWorking && total > 0 && !inTransaction) {
            begin();
            setInTransaction(function (param) {
                  return true;
                });
          }
          if (!isWorking && inTransaction) {
            commit();
            setInTransaction(function (param) {
                  return false;
                });
          }
          
        }), [
        isWorking,
        total,
        inTransaction
      ]);
  return JsxRuntime.jsxs(Dialog, {
              open: hasPending || hasFailures,
              children: [
                JsxRuntime.jsx(DialogTitle, {
                      children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                msg: {
                                  NAME: "msg",
                                  VAL: [
                                    "upload.title",
                                    "Uploading"
                                  ]
                                }
                              }))
                    }),
                JsxRuntime.jsxs(DialogContent, {
                      children: [
                        hasPending ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                children: [
                                  JsxRuntime.jsx(DialogContentText, {
                                        children: Caml_option.some(JsxRuntime.jsx(LinearProgress, {
                                                  value: progress,
                                                  variant: "determinate"
                                                }))
                                      }),
                                  JsxRuntime.jsxs(DialogContentText, {
                                        children: [
                                          String(progress) + "%",
                                          isWorking ? (
                                              speed !== undefined ? " (" + speed + ")" : " (? kB/s)"
                                            ) : null
                                        ],
                                        sx: {
                                          textAlign: "center"
                                        }
                                      })
                                ]
                              }) : null,
                        hasFailures && !hasPending ? JsxRuntime.jsx(Alert, {
                                children: Caml_option.some(JsxRuntime.jsx(ReactI18next.Trans, {
                                          i18nKey: "upload.error-message",
                                          children: "Some files could not be uploaded.  Use the button 'Retry', to retry the failed files."
                                        })),
                                severity: "error"
                              }) : null
                      ]
                    }),
                JsxRuntime.jsxs(DialogActions, {
                      children: [
                        retryButton,
                        pauseButton,
                        resumeButton,
                        JsxRuntime.jsx(Button, {
                              children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                        msg: {
                                          NAME: "msg",
                                          VAL: [
                                            "upload.cancel-button",
                                            "Cancel"
                                          ]
                                        }
                                      })),
                              onClick: (function (param) {
                                  commit();
                                  onCancel();
                                })
                            })
                      ]
                    })
              ],
              fullWidth: true,
              maxWidth: "md"
            });
}

function UploadManager$Manager(props) {
  var uploader = UploadDocumentService.useUploader();
  var match = React.useReducer(dispatcher, {
        TAG: "Working",
        startTime: 0.0,
        startSize: 0.0,
        queue: []
      });
  var dispatch = match[1];
  var state = match[0];
  var get = React.useCallback((function () {
          if (state.TAG === "Paused") {
            return Curry._2(Prelude.$$Array.concat, state._0, []);
          } else {
            return Curry._2(Prelude.$$Array.concat, state.queue, []);
          }
        }), [state]);
  var upload = React.useCallback((function (items) {
          dispatch({
                TAG: "Add",
                _0: items
              });
        }), [dispatch]);
  var clear = React.useCallback((function () {
          dispatch("Clear");
        }), [dispatch]);
  var pause = React.useCallback((function () {
          dispatch("Pause");
        }), [dispatch]);
  var resume = React.useCallback((function () {
          dispatch("Resume");
        }), [dispatch]);
  var match$1 = React.useState(function () {
        
      });
  var setWorking = match$1[1];
  var working = match$1[0];
  React.useEffect((function () {
          if (uploader === undefined) {
            return ;
          }
          if (working !== undefined) {
            return ;
          }
          if (state.TAG === "Paused") {
            return ;
          }
          var queue = state.queue;
          var queued = Curry._2(Prelude.$$Array.keep, queue, (function (p) {
                  return p.status === "queued";
                }));
          var item = Prelude.$$Array.first(queued);
          if (item !== undefined) {
            var newrecord = Caml_obj.obj_dup(item);
            newrecord.status = "uploading";
            dispatch({
                  TAG: "Notify",
                  _0: newrecord
                });
            var promise = Prelude.PromisedResult.mapError(Prelude.PromisedResult.map(uploader(newrecord), (function (param) {
                        var newrecord$1 = Caml_obj.obj_dup(newrecord);
                        dispatch({
                              TAG: "Notify",
                              _0: (newrecord$1.status = "uploaded", newrecord$1)
                            });
                        setWorking(function (param) {
                              
                            });
                      })), (function (param) {
                    console.error("UploadManager", "failed", newrecord);
                    var newrecord$1 = Caml_obj.obj_dup(newrecord);
                    dispatch({
                          TAG: "Notify",
                          _0: (newrecord$1.status = "failed", newrecord$1)
                        });
                    setWorking(function (param) {
                          
                        });
                  }));
            setWorking(function (param) {
                  return Caml_option.some(promise);
                });
          } else if (!Prelude.$$Array.isEmpty(queue) && Curry._1(Prelude.OptionExported.$$Option.isNone, queue.find(function (p) {
                      return p.status === "failed";
                    }))) {
            dispatch("Clear");
          }
          
        }), [
        uploader,
        state,
        working,
        dispatch
      ]);
  var value = Curry._2(Prelude.OptionExported.$$Option.map, uploader, (function (param) {
          return {
                  upload: upload,
                  clear: clear,
                  pause: pause,
                  resume: resume,
                  get: get
                };
        }));
  return JsxRuntime.jsxs(make, {
              value: value,
              children: [
                props.children,
                JsxRuntime.jsx(UploadManager$UploadDialog, {
                      state: state,
                      onPause: pause,
                      onResume: resume,
                      onCancel: clear,
                      onRetry: (function () {
                          dispatch("Retry");
                        })
                    })
              ]
            });
}

function UploadManager(props) {
  return JsxRuntime.jsx(UploadDocumentService.make, {
              children: JsxRuntime.jsx(UploadManager$Manager, {
                    children: props.children
                  })
            });
}

var make$1 = UploadManager;

export {
  useUpload ,
  useClear ,
  usePause ,
  useResume ,
  useGetUploads ,
  make$1 as make,
}
/* context Not a pure module */
