// Generated by ReScript, PLEASE EDIT WITH CARE


var isBefore = (function(a, b){return a < b});

var isBeforeOrAt = (function(a, b){return a <= b});

var isAfter = (function(a, b){return a > b});

var isAfterOrAt = (function(a, b){return a >= b});

var DateTime = {
  isBefore: isBefore,
  isBeforeOrAt: isBeforeOrAt,
  isAfter: isAfter,
  isAfterOrAt: isAfterOrAt
};

var Duration = {};

export {
  DateTime ,
  Duration ,
}
/* No side effect */
