// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as HtmlAnchor from "../../../libs/HtmlAnchor.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../libs/i18n/ReactI18Next.js";
import * as ReactRouterDom from "react-router-dom";
import * as JsxRuntime from "react/jsx-runtime";
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";

function DropdownMenu$Item(props) {
  var delay = props.delay;
  var onClose = props.onClose;
  var item = props.item;
  var navigate = ReactRouterDom.useNavigate();
  var onClick = function (e) {
    e.preventDefault();
    Prelude.thenDo(Prelude.Promises.ellapsed(Prelude.default(delay, 200)), (function () {
            onClose();
            var href = item.action;
            switch (href.TAG) {
              case "ExternalLink" :
                  return HtmlAnchor.clickLink(href._0, true);
              case "Link" :
                  return HtmlAnchor.clickLink(href._0, false);
              case "Route" :
                  return navigate(href._0);
              case "Click" :
              case "JsxClick" :
                  return href._0(e);
              
            }
          }));
  };
  var icon = item.icon;
  return JsxRuntime.jsx(MenuItem, {
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        children: [
                          icon !== undefined ? JsxRuntime.jsx(ListItemIcon, {
                                  children: Caml_option.some(Caml_option.valFromOption(icon))
                                }) : JsxRuntime.jsx("span", {}),
                          JsxRuntime.jsx(ReactI18Next.Message.make, {
                                msg: item.title
                              })
                        ],
                        direction: ["row"],
                        spacing: "1rem",
                        sx: {
                          alignItems: "center",
                          px: "0.5rem"
                        }
                      })),
              onClick: onClick,
              disabled: props.disabled
            });
}

function DropdownMenu(props) {
  var delay = props.delay;
  var tooltip = props.tooltip;
  var items = props.items;
  var trigger = props.trigger;
  var match = React.useState(function () {
        
      });
  var setAnchorEl = match[1];
  var anchorEl = match[0];
  var isOpen = React.useMemo((function () {
          return Curry._1(Prelude.OptionExported.$$Option.isSome, anchorEl);
        }), [anchorEl]);
  var onClose = function () {
    setAnchorEl(function (param) {
          
        });
  };
  var onClick = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(function (param) {
          return e.target;
        });
  };
  var disabled = Prelude.default(props.disabled, Prelude.$$Array.isEmpty(items));
  var triggerEl;
  switch (trigger.TAG) {
    case "Button" :
        triggerEl = JsxRuntime.jsx(Button, {
              children: Caml_option.some(trigger._0),
              onClick: onClick,
              color: props.color,
              disabled: disabled
            });
        break;
    case "Icon" :
        triggerEl = JsxRuntime.jsx(IconButton, {
              children: Caml_option.some(trigger._0),
              onClick: onClick,
              disabled: disabled,
              size: props.size
            });
        break;
    case "El" :
        triggerEl = JsxRuntime.jsx("div", {
              children: trigger._0,
              onClick: onClick
            });
        break;
    
  }
  var triggerEl$1 = tooltip !== undefined ? JsxRuntime.jsx(Tooltip, {
          children: triggerEl,
          title: Caml_option.valFromOption(tooltip)
        }) : triggerEl;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                triggerEl$1,
                JsxRuntime.jsx(Menu, {
                      open: isOpen,
                      anchorEl: anchorEl,
                      children: Caml_option.some(items.map(function (item, index) {
                                if (typeof item !== "object") {
                                  return JsxRuntime.jsx(Divider, {}, String(index));
                                }
                                if (item.TAG !== "Item") {
                                  return JsxRuntime.jsx(Divider, {}, String(index));
                                }
                                var item$1 = item._0;
                                var disabled = item$1.disabled;
                                return JsxRuntime.jsx(DropdownMenu$Item, {
                                            item: item$1,
                                            onClose: onClose,
                                            disabled: disabled,
                                            delay: delay
                                          }, String(index));
                              })),
                      onClose: (function (param, param$1) {
                          setAnchorEl(function (param) {
                                
                              });
                        })
                    })
              ]
            });
}

var Item = {};

var make = DropdownMenu;

export {
  Item ,
  make ,
}
/* react Not a pure module */
