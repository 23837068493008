// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Tag from "../models/Tag.js";
import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as APIMaker from "../../../utils/APIMaker.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var Search = APIMaker.MakePaginatedEndpoint({
      url: "tags",
      Deserializer: Tag.Deserializer
    });

var fields = {
  TAG: "Object",
  _0: [[
      "result",
      {
        TAG: "Collection",
        _0: Tag.Deserializer
      }
    ]]
};

var Deserializer = $$JSON.MakeDeserializer({
      fields: fields
    });

var Impl = APIMaker.MakeBackendAPIEndpoint({
      url: "/tags",
      Deserializer: Deserializer
    });

function $$do(name, approvedOpt, token) {
  var approved = approvedOpt !== undefined ? approvedOpt : false;
  var operation = {
    operation: {
      kind: "create-tag",
      name: name,
      approved: approved
    }
  };
  return Prelude.PromisedResult.flatMap(Prelude.PromisedResult.mapError(Impl.postOne(undefined, Caml_option.some(token), operation), (function (param) {
                    
                  })), (function (res) {
                var tag = Curry._2(Prelude.OptionExported.$$Option.flatMap, res, (function (param) {
                        return Prelude.$$Array.first(param.result);
                      }));
                if (tag !== undefined) {
                  return {
                          TAG: "Ok",
                          _0: tag
                        };
                } else {
                  return {
                          TAG: "Error",
                          _0: undefined
                        };
                }
              }));
}

var fields$1 = {
  TAG: "Object",
  _0: [[
      "result",
      {
        TAG: "Collection",
        _0: Tag.Deserializer
      }
    ]]
};

var Deserializer$1 = $$JSON.MakeDeserializer({
      fields: fields$1
    });

var Impl$1 = APIMaker.MakeBackendAPIEndpoint({
      url: "/tags",
      Deserializer: Deserializer$1
    });

function $$do$1(tag, token) {
  var operation = {
    operation: {
      kind: "update-tag",
      id: tag.id,
      name: tag.name,
      approved: tag.approved
    }
  };
  return Prelude.PromisedResult.flatMap(Prelude.PromisedResult.mapError(Impl$1.postOne(undefined, Caml_option.some(token), operation), (function (param) {
                    
                  })), (function (res) {
                var tag = Curry._2(Prelude.OptionExported.$$Option.flatMap, res, (function (param) {
                        return Prelude.$$Array.first(param.result);
                      }));
                if (tag !== undefined) {
                  return {
                          TAG: "Ok",
                          _0: tag
                        };
                } else {
                  return {
                          TAG: "Error",
                          _0: undefined
                        };
                }
              }));
}

var fields$2 = {
  TAG: "Object",
  _0: [[
      "result",
      {
        TAG: "Collection",
        _0: Tag.Deserializer
      }
    ]]
};

var Deserializer$2 = $$JSON.MakeDeserializer({
      fields: fields$2
    });

var Impl$2 = APIMaker.MakeBackendAPIEndpoint({
      url: "/tags",
      Deserializer: Deserializer$2
    });

function $$do$2(tag, token) {
  var operation = {
    operation: {
      kind: "remove-tag",
      id: tag.id
    }
  };
  return Prelude.PromisedResult.mapError(Prelude.PromisedResult.map(Impl$2.postOne(undefined, Caml_option.some(token), operation), (function (param) {
                    
                  })), (function (param) {
                
              }));
}

var fields$3 = {
  TAG: "Object",
  _0: [[
      "result",
      {
        TAG: "Collection",
        _0: Tag.Deserializer
      }
    ]]
};

var Deserializer$3 = $$JSON.MakeDeserializer({
      fields: fields$3
    });

var Impl$3 = APIMaker.MakeBackendAPIEndpoint({
      url: "/tags",
      Deserializer: Deserializer$3
    });

function $$do$3(tags, token) {
  var ids = tags.map(function (param) {
        return param.id;
      });
  var operation = {
    operation: {
      kind: "bulk-remove-tag",
      ids: ids
    }
  };
  return Prelude.PromisedResult.mapError(Prelude.PromisedResult.map(Impl$3.postOne(undefined, Caml_option.some(token), operation), (function (param) {
                    
                  })), (function (param) {
                
              }));
}

var fields$4 = {
  TAG: "Object",
  _0: [[
      "result",
      {
        TAG: "Collection",
        _0: Tag.Deserializer
      }
    ]]
};

var Deserializer$4 = $$JSON.MakeDeserializer({
      fields: fields$4
    });

var Impl$4 = APIMaker.MakeBackendAPIEndpoint({
      url: "/tags",
      Deserializer: Deserializer$4
    });

function $$do$4(tag, branches, token) {
  var ids = branches.map(function (param) {
        return param.id;
      });
  var operation = {
    operation: {
      kind: "merge-tags",
      id: tag.id,
      ids: ids
    }
  };
  return Prelude.PromisedResult.flatMap(Prelude.PromisedResult.mapError(Impl$4.postOne(undefined, Caml_option.some(token), operation), (function (param) {
                    
                  })), (function (res) {
                var tag = Curry._2(Prelude.OptionExported.$$Option.flatMap, res, (function (param) {
                        return Prelude.$$Array.first(param.result);
                      }));
                if (tag !== undefined) {
                  return {
                          TAG: "Ok",
                          _0: tag
                        };
                } else {
                  return {
                          TAG: "Error",
                          _0: undefined
                        };
                }
              }));
}

var Search$1 = {
  $$fetch: Search.$$fetch
};

var CreateTag = {
  $$do: $$do
};

var UpdateTag = {
  $$do: $$do$1
};

var RemoveTag = {
  $$do: $$do$2
};

var BulkRemoveTags = {
  $$do: $$do$3
};

var MergeTags = {
  $$do: $$do$4
};

export {
  Search$1 as Search,
  CreateTag ,
  UpdateTag ,
  RemoveTag ,
  BulkRemoveTags ,
  MergeTags ,
}
/* Search Not a pure module */
