// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DropdownMenu from "../../../global/components/DropdownMenu.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ConfirmDeleteDialog from "../../../global/components/ConfirmDeleteDialog.js";
import * as CollectionsListService from "../context/CollectionsListService.js";
import * as RenameCollectionDialog from "./affordances/RenameCollectionDialog.js";
import * as RemoveCollectionService from "../context/RemoveCollectionService.js";
import * as UpdateCollectionService from "../context/UpdateCollectionService.js";
import MoreVert from "@mui/icons-material/MoreVert";
import EditRounded from "@mui/icons-material/EditRounded";
import DeleteForever from "@mui/icons-material/DeleteForever";

function CollectionDropdown(props) {
  var collection = props.collection;
  var match = React.useState(function () {
        
      });
  var setRenameCollection = match[1];
  var renameCollection = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setDeleteCollection = match$1[1];
  var deleteCollection = match$1[0];
  var refresh = CollectionsListService.useRefresh();
  var updater = UpdateCollectionService.useUpdateCollection();
  var deleter = RemoveCollectionService.useRemoveCollection();
  var items = [
    {
      TAG: "Item",
      _0: {
        title: {
          NAME: "str",
          VAL: "Rename"
        },
        icon: Caml_option.some(JsxRuntime.jsx(EditRounded, {})),
        action: {
          TAG: "Click",
          _0: (function (param) {
              setRenameCollection(function (param) {
                    return collection;
                  });
            })
        },
        disabled: Curry._1(Prelude.OptionExported.$$Option.isNone, updater)
      }
    },
    {
      TAG: "Item",
      _0: {
        title: {
          NAME: "str",
          VAL: "Remove"
        },
        icon: Caml_option.some(JsxRuntime.jsx(DeleteForever, {})),
        action: {
          TAG: "Click",
          _0: (function (param) {
              setDeleteCollection(function (param) {
                    return collection;
                  });
            })
        },
        disabled: Curry._1(Prelude.OptionExported.$$Option.isNone, deleter)
      }
    }
  ];
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(DropdownMenu.make, {
                      trigger: {
                        TAG: "Icon",
                        _0: JsxRuntime.jsx(MoreVert, {})
                      },
                      items: items,
                      disabled: Curry._1(Prelude.OptionExported.$$Option.isNone, deleter) && Curry._1(Prelude.OptionExported.$$Option.isNone, updater)
                    }),
                renameCollection !== undefined ? JsxRuntime.jsx(RenameCollectionDialog.make, {
                        collection: renameCollection,
                        onDone: (function () {
                            refresh();
                            setRenameCollection(function (param) {
                                  
                                });
                          }),
                        onClose: (function () {
                            setRenameCollection(function (param) {
                                  
                                });
                          })
                      }) : null,
                deleteCollection !== undefined ? JsxRuntime.jsx(ConfirmDeleteDialog.make, {
                        open_: true,
                        title: {
                          NAME: "str",
                          VAL: "Confirm deletion of collection"
                        },
                        onCancel: (function () {
                            setDeleteCollection(function (param) {
                                  
                                });
                          }),
                        onDelete: (function () {
                            Prelude.OptionExported.$$Option.$$do(deleter, (function (fn) {
                                    Prelude.thenDo(fn(deleteCollection).finally(function () {
                                              refresh();
                                            }), (function (param) {
                                            setDeleteCollection(function (param) {
                                                  
                                                });
                                          }));
                                  }));
                          })
                      }) : null
              ]
            });
}

var make = CollectionDropdown;

export {
  make ,
}
/* react Not a pure module */
