// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as SearchQuery from "../models/SearchQuery.js";
import * as JsxRuntime from "react/jsx-runtime";

var context = React.createContext(undefined);

var make = context.Provider;

function dispatch(state, action) {
  var fixtures = state.fixtures;
  var filters = state.filters;
  var filters$1;
  switch (action.TAG) {
    case "SetUntagged" :
        var newrecord = Caml_obj.obj_dup(filters);
        newrecord.untagged = action._0;
        filters$1 = newrecord;
        break;
    case "SetFolders" :
        var newrecord$1 = Caml_obj.obj_dup(filters);
        newrecord$1.folders = action._0;
        filters$1 = newrecord$1;
        break;
    case "SetAuthors" :
        var newrecord$2 = Caml_obj.obj_dup(filters);
        newrecord$2.authors = action._0;
        filters$1 = newrecord$2;
        break;
    case "SetCities" :
        var newrecord$3 = Caml_obj.obj_dup(filters);
        newrecord$3.cities = action._0;
        filters$1 = newrecord$3;
        break;
    case "SetTags" :
        var newrecord$4 = Caml_obj.obj_dup(filters);
        newrecord$4.tags = action._0;
        filters$1 = newrecord$4;
        break;
    case "SetCollections" :
        var newrecord$5 = Caml_obj.obj_dup(filters);
        newrecord$5.collections = action._0;
        filters$1 = newrecord$5;
        break;
    case "SetText" :
        var newrecord$6 = Caml_obj.obj_dup(filters);
        newrecord$6.text = action._0;
        filters$1 = newrecord$6;
        break;
    
  }
  return {
          filters: filters$1,
          fixtures: fixtures
        };
}

function useFilters() {
  var context$1 = React.useContext(context);
  return React.useMemo((function () {
                return Curry._2(Prelude.OptionExported.$$Option.map, context$1, (function (s) {
                              return s.filters;
                            }));
              }), [context$1]);
}

function useFixtures() {
  var context$1 = React.useContext(context);
  return React.useMemo((function () {
                return Curry._2(Prelude.OptionExported.$$Option.flatMap, context$1, (function (s) {
                              return s.fixtures;
                            }));
              }), [context$1]);
}

function useFiltersOrDefault() {
  var context$1 = React.useContext(context);
  return React.useMemo((function () {
                return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, context$1, (function (s) {
                                  return s.filters;
                                })), SearchQuery.Filters.$$default);
              }), [context$1]);
}

function useResolvedFilters() {
  var fixtures = useFixtures();
  var filters = useFiltersOrDefault();
  return React.useMemo((function () {
                return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, fixtures, (function (f) {
                                  return SearchQuery.Filters.merge(f, filters);
                                })), filters);
              }), [
              fixtures,
              filters
            ]);
}

function useFolderFilters() {
  return useFiltersOrDefault().folders;
}

function useAuthorsFilters() {
  return useFiltersOrDefault().authors;
}

function useCitiesFilters() {
  return useFiltersOrDefault().cities;
}

function useTagsFilters() {
  return useFiltersOrDefault().tags;
}

function useCollectionsFilters() {
  return useFiltersOrDefault().collections;
}

function useTextFilter() {
  return useFiltersOrDefault().text;
}

function useUntaggedFilter() {
  return useFiltersOrDefault().untagged;
}

function default_setFolders(param) {
  
}

function default_setAuthors(param) {
  
}

function default_setCities(param) {
  
}

function default_setTags(param) {
  
}

function default_setCollections(param) {
  
}

function default_setText(param) {
  
}

function default_setUntagged(param) {
  
}

var $$default = {
  setFolders: default_setFolders,
  setAuthors: default_setAuthors,
  setCities: default_setCities,
  setTags: default_setTags,
  setCollections: default_setCollections,
  setText: default_setText,
  setUntagged: default_setUntagged
};

var context$1 = React.createContext($$default);

var make$1 = context$1.Provider;

function useSetFolder() {
  return React.useContext(context$1).setFolders;
}

function useSetAuthors() {
  return React.useContext(context$1).setAuthors;
}

function useSetCities() {
  return React.useContext(context$1).setCities;
}

function useSetTags() {
  return React.useContext(context$1).setTags;
}

function useSetCollections() {
  return React.useContext(context$1).setCollections;
}

function useSetText() {
  return React.useContext(context$1).setText;
}

function useSetUntagged() {
  return React.useContext(context$1).setUntagged;
}

function SearchFiltersContext(props) {
  var match = React.useReducer(dispatch, {
        filters: Prelude.default(props.defaults, SearchQuery.Filters.$$default),
        fixtures: props.fixtures
      });
  var dispatch$1 = match[1];
  var setFolders = function (folders) {
    dispatch$1({
          TAG: "SetFolders",
          _0: folders
        });
  };
  var setAuthors = function (authors) {
    dispatch$1({
          TAG: "SetAuthors",
          _0: authors
        });
  };
  var setCities = function (cities) {
    dispatch$1({
          TAG: "SetCities",
          _0: cities
        });
  };
  var setTags = function (tags) {
    dispatch$1({
          TAG: "SetTags",
          _0: tags
        });
  };
  var setCollections = function (collections) {
    dispatch$1({
          TAG: "SetCollections",
          _0: collections
        });
  };
  var setText = function (text) {
    dispatch$1({
          TAG: "SetText",
          _0: text
        });
  };
  var setUntagged = function (arg) {
    dispatch$1({
          TAG: "SetUntagged",
          _0: arg
        });
  };
  return JsxRuntime.jsx(make, {
              value: match[0],
              children: JsxRuntime.jsx(make$1, {
                    value: {
                      setFolders: setFolders,
                      setAuthors: setAuthors,
                      setCities: setCities,
                      setTags: setTags,
                      setCollections: setCollections,
                      setText: setText,
                      setUntagged: setUntagged
                    },
                    children: props.children
                  })
            });
}

var make$2 = SearchFiltersContext;

export {
  useFilters ,
  useFixtures ,
  useResolvedFilters ,
  useFolderFilters ,
  useAuthorsFilters ,
  useCitiesFilters ,
  useTagsFilters ,
  useCollectionsFilters ,
  useTextFilter ,
  useUntaggedFilter ,
  useSetFolder ,
  useSetAuthors ,
  useSetCities ,
  useSetTags ,
  useSetCollections ,
  useSetText ,
  useSetUntagged ,
  make$2 as make,
}
/* context Not a pure module */
