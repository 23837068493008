// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as $$Document from "../../models/Document.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Orientation from "../../models/Orientation.js";
import * as ReactI18Next from "../../../../../libs/i18n/ReactI18Next.js";
import * as AttributeMatch from "../../../search/models/AttributeMatch.js";
import * as FlexGrowSpacer from "../../../../global/components/FlexGrowSpacer.js";
import * as CollectionMatch from "../../../search/models/CollectionMatch.js";
import * as CityAutocomplete from "../../../search/components/CityAutocomplete.js";
import * as TagsAutocomplete from "../../../search/components/TagsAutocomplete.js";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import * as JsxRuntime from "react/jsx-runtime";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import * as DocumentBrowserTab from "./DocumentBrowserTab.js";
import Stack from "@mui/material/Stack";
import * as AuthorsAutocomplete from "../../../search/components/AuthorsAutocomplete.js";
import * as CountryAutocomplete from "../../../search/components/CountryAutocomplete.js";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import * as CollectionsAutocomplete from "../../../search/components/CollectionsAutocomplete.js";
import * as DocumentAttributeLabels from "./DocumentAttributeLabels.js";
import Save from "@mui/icons-material/Save";
import CropFree from "@mui/icons-material/CropFree";
import EditCalendar from "@mui/icons-material/EditCalendar";
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import * as DocumentBrowserInfoDataTextWithIcon from "./DocumentBrowserInfoDataTextWithIcon.js";

function DocumentBrowserFormPanel(props) {
  var isModified = props.isModified;
  var onSave = props.onSave;
  var onReset = props.onReset;
  var onChangeDocument = props.onChangeDocument;
  var setCurrentTab = props.setCurrentTab;
  var currentTab = props.currentTab;
  var $$document = props.document;
  var created = $$document.created_at.toLocaleDateString();
  var updated = $$document.updated_at.toLocaleDateString();
  var height = Curry._2(Prelude.OptionExported.$$Option.map, $$Document.height($$document), (function (prim) {
          return String(prim);
        }));
  var width = Curry._2(Prelude.OptionExported.$$Option.map, $$Document.width($$document), (function (prim) {
          return String(prim);
        }));
  var match = Orientation.Ifd0.flipsWidthHeight(Prelude.default($$Document.Orientation.get($$document), 1));
  var imgSize = width !== undefined && height !== undefined ? (
      match ? height + "x" + width : width + "x" + height
    ) : undefined;
  var setName = React.useCallback((function (name) {
          onChangeDocument($$Document.setName($$document, name));
        }), [
        $$document,
        onChangeDocument
      ]);
  var name = $$document.name;
  var placeholder = name.trim() === "" ? $$Document.name($$document) : undefined;
  var setDescription = React.useCallback((function (description) {
          onChangeDocument($$Document.setDescription($$document, description));
        }), [
        $$document,
        onChangeDocument
      ]);
  var description = $$document.description;
  var setAuthor = React.useCallback((function (author) {
          onChangeDocument($$Document.setAuthor($$document, author));
        }), [
        $$document,
        onChangeDocument
      ]);
  var removeAuthor = React.useCallback((function () {
          onChangeDocument($$Document.removeAuthor($$document));
        }), [
        $$document,
        onChangeDocument
      ]);
  var author = React.useMemo((function () {
          return $$Document.author($$document);
        }), [$$document]);
  var authors = React.useMemo((function () {
          return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, author, (function (v) {
                            return [AttributeMatch.make(v)];
                          })), []);
        }), [author]);
  var setCopyright = React.useCallback((function (copyright) {
          onChangeDocument($$Document.setCopyright($$document, copyright));
        }), [
        $$document,
        onChangeDocument
      ]);
  var copyright = React.useMemo((function () {
          return $$Document.copyright($$document);
        }), [$$document]);
  var setCountry = React.useCallback((function (country) {
          onChangeDocument($$Document.setCountry($$document, country));
        }), [
        $$document,
        onChangeDocument
      ]);
  var removeCountry = React.useCallback((function () {
          onChangeDocument($$Document.removeCountry($$document));
        }), [
        $$document,
        onChangeDocument
      ]);
  var country = React.useMemo((function () {
          return $$Document.country($$document);
        }), [$$document]);
  var countries = React.useMemo((function () {
          return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, country, (function (v) {
                            return [AttributeMatch.make(v)];
                          })), []);
        }), [country]);
  var collections = React.useMemo((function () {
          return CollectionMatch.group($$document.collections.map(CollectionMatch.fromCollection));
        }), [$$document]);
  var setCity = React.useCallback((function (city) {
          onChangeDocument($$Document.setCity($$document, city));
        }), [
        $$document,
        onChangeDocument
      ]);
  var removeCity = React.useCallback((function () {
          onChangeDocument($$Document.removeCity($$document));
        }), [onChangeDocument]);
  var city = React.useMemo((function () {
          return $$Document.city($$document);
        }), [$$document]);
  var cities = React.useMemo((function () {
          return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, city, (function (v) {
                            return [AttributeMatch.make(v)];
                          })), []);
        }), [city]);
  var setSources = React.useCallback((function (sources) {
          onChangeDocument($$Document.setSources($$document, sources));
        }), [
        $$document,
        onChangeDocument
      ]);
  var sources = React.useMemo((function () {
          return $$Document.sources($$document);
        }), [$$document]);
  var setUsageTerms = React.useCallback((function (usage_terms) {
          onChangeDocument($$Document.setUsageTerms($$document, usage_terms));
        }), [
        $$document,
        onChangeDocument
      ]);
  var usage_terms = React.useMemo((function () {
          return $$Document.usage_terms($$document);
        }), [$$document]);
  var setAuthorJobTitle = React.useCallback((function (author_job_title) {
          onChangeDocument($$Document.setAuthorJobTitle($$document, author_job_title));
        }), [
        $$document,
        onChangeDocument
      ]);
  var author_job_title = React.useMemo((function () {
          return $$Document.author_job_title($$document);
        }), [$$document]);
  var handleNameChange = function (ev) {
    setName(ev.currentTarget.value);
  };
  var handleDescriptionChange = function (ev) {
    setDescription(ev.currentTarget.value);
  };
  var handleCountryChange = function (countries) {
    console.log("DocumentBrowserFormPanel", "handleCountryChange", countries);
    var match = Prelude.$$Array.first(countries);
    if (match !== undefined) {
      return setCountry(match.value);
    } else {
      return removeCountry();
    }
  };
  var handleCityChange = function (cities) {
    var match = Prelude.$$Array.first(cities);
    if (match !== undefined) {
      return setCity(match.value);
    } else {
      return removeCity();
    }
  };
  var handleAuthorChange = function (authors) {
    var match = Prelude.$$Array.first(authors);
    if (match !== undefined) {
      return setAuthor(match.value);
    } else {
      return removeAuthor();
    }
  };
  var handleAuthorJobTitlChange = function (ev) {
    setAuthorJobTitle(ev.currentTarget.value);
  };
  var handleSourcesChange = function (ev) {
    setSources(ev.currentTarget.value);
  };
  var handleCopyrightChange = function (ev) {
    setCopyright(ev.currentTarget.value);
  };
  var handleUsageTermsChange = function (ev) {
    setUsageTerms(ev.currentTarget.value);
  };
  return JsxRuntime.jsx(Stack, {
              children: Caml_option.some(JsxRuntime.jsxs(Box, {
                        children: [
                          JsxRuntime.jsx(Box, {
                                children: Caml_option.some(JsxRuntime.jsxs(Tabs, {
                                          children: [
                                            JsxRuntime.jsx(Tab, {
                                                  id: "simple-tab-0",
                                                  iconPosition: "end",
                                                  label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                            msg: {
                                                              NAME: "msg",
                                                              VAL: [
                                                                "document-browser.tab-description",
                                                                "Description"
                                                              ]
                                                            }
                                                          })),
                                                  value: "description"
                                                }),
                                            JsxRuntime.jsx(Tab, {
                                                  id: "simple-tab-1",
                                                  label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                            msg: {
                                                              NAME: "msg",
                                                              VAL: [
                                                                "document-browser.tab-authoring",
                                                                "Authoring"
                                                              ]
                                                            }
                                                          })),
                                                  value: "authoring"
                                                }),
                                            isModified ? JsxRuntime.jsx(Tab, {
                                                    id: "simple-tab-2",
                                                    disabled: true,
                                                    icon: Caml_option.some(JsxRuntime.jsx(Save, {
                                                              fontSize: "small"
                                                            })),
                                                    sx: {
                                                      minHeight: "unset",
                                                      minWidth: "1rem",
                                                      padding: "0"
                                                    }
                                                  }) : null
                                          ],
                                          onChange: (function (param, tab) {
                                              setCurrentTab(tab);
                                            }),
                                          value: currentTab
                                        })),
                                sx: {
                                  border: "1",
                                  borderColor: "divider"
                                }
                              }),
                          JsxRuntime.jsx(DocumentBrowserTab.make, {
                                value: currentTab,
                                index: "description",
                                children: JsxRuntime.jsxs(Stack, {
                                      children: [
                                        JsxRuntime.jsx(TextField, {
                                              label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                        msg: DocumentAttributeLabels.get("name")
                                                      })),
                                              onChange: handleNameChange,
                                              placeholder: placeholder,
                                              value: name
                                            }),
                                        JsxRuntime.jsx(TextField, {
                                              label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                        msg: DocumentAttributeLabels.get("description")
                                                      })),
                                              multiline: true,
                                              name: "description",
                                              onChange: handleDescriptionChange,
                                              rows: 4,
                                              value: description
                                            }),
                                        JsxRuntime.jsx(TagsAutocomplete.make, {
                                              tags: $$document.tags,
                                              onChange: (function (tags) {
                                                  var newrecord = Caml_obj.obj_dup($$document);
                                                  onChangeDocument((newrecord.tags = tags, newrecord));
                                                }),
                                              label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                        msg: DocumentAttributeLabels.get("tags")
                                                      })),
                                              allowUnapproved: true,
                                              freeSolo: true
                                            }),
                                        JsxRuntime.jsx(CollectionsAutocomplete.make, {
                                              collections: collections,
                                              onChange: (function (matches) {
                                                  var newrecord = Caml_obj.obj_dup($$document);
                                                  onChangeDocument((newrecord.collections = Prelude.$$Array.flatMap(matches, CollectionMatch.toCollections), newrecord));
                                                }),
                                              label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                        msg: DocumentAttributeLabels.get("collections")
                                                      }))
                                            }),
                                        JsxRuntime.jsx(CityAutocomplete.make, {
                                              cities: cities,
                                              onChange: handleCityChange,
                                              label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                        msg: DocumentAttributeLabels.get("city")
                                                      })),
                                              multiple: false,
                                              freeSolo: true
                                            }),
                                        JsxRuntime.jsx(CountryAutocomplete.make, {
                                              countries: countries,
                                              onChange: handleCountryChange,
                                              label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                        msg: DocumentAttributeLabels.get("country")
                                                      })),
                                              multiple: false,
                                              freeSolo: true
                                            }),
                                        JsxRuntime.jsxs(Grid, {
                                              children: [
                                                JsxRuntime.jsx(Grid, {
                                                      children: Caml_option.some(JsxRuntime.jsx(DocumentBrowserInfoDataTextWithIcon.make, {
                                                                name: DocumentAttributeLabels.get("size"),
                                                                value: imgSize,
                                                                icon: JsxRuntime.jsx(CropFree, {})
                                                              })),
                                                      item: true,
                                                      lg: 12,
                                                      md: 12,
                                                      xl: 4,
                                                      xs: 4
                                                    }),
                                                JsxRuntime.jsx(Grid, {
                                                      children: Caml_option.some(JsxRuntime.jsx(DocumentBrowserInfoDataTextWithIcon.make, {
                                                                name: DocumentAttributeLabels.get("created_at"),
                                                                value: created,
                                                                icon: JsxRuntime.jsx(CalendarMonth, {})
                                                              })),
                                                      item: true,
                                                      lg: 12,
                                                      xl: 4,
                                                      xs: 4
                                                    }),
                                                JsxRuntime.jsx(Grid, {
                                                      children: Caml_option.some(JsxRuntime.jsx(DocumentBrowserInfoDataTextWithIcon.make, {
                                                                name: DocumentAttributeLabels.get("updated_at"),
                                                                value: updated,
                                                                icon: JsxRuntime.jsx(EditCalendar, {})
                                                              })),
                                                      item: true,
                                                      lg: 12,
                                                      xl: 4,
                                                      xs: 4
                                                    })
                                              ],
                                              container: true,
                                              spacing: 2
                                            })
                                      ],
                                      direction: ["column"],
                                      spacing: "1rem",
                                      sx: {
                                        paddingTop: "1em"
                                      },
                                      useFlexGap: true
                                    })
                              }),
                          JsxRuntime.jsx(DocumentBrowserTab.make, {
                                value: currentTab,
                                index: "authoring",
                                children: JsxRuntime.jsxs(Stack, {
                                      children: [
                                        JsxRuntime.jsx(AuthorsAutocomplete.make, {
                                              authors: authors,
                                              onChange: handleAuthorChange,
                                              label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                        msg: DocumentAttributeLabels.get("author")
                                                      })),
                                              multiple: false,
                                              freeSolo: true
                                            }),
                                        JsxRuntime.jsx(TextField, {
                                              label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                        msg: DocumentAttributeLabels.get("author_job_title")
                                                      })),
                                              onChange: handleAuthorJobTitlChange,
                                              value: author_job_title
                                            }),
                                        JsxRuntime.jsx(TextField, {
                                              label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                        msg: DocumentAttributeLabels.get("sources")
                                                      })),
                                              onChange: handleSourcesChange,
                                              value: sources
                                            }),
                                        JsxRuntime.jsx(TextField, {
                                              label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                        msg: DocumentAttributeLabels.get("copyright")
                                                      })),
                                              onChange: handleCopyrightChange,
                                              value: copyright
                                            }),
                                        JsxRuntime.jsx(TextField, {
                                              label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                        msg: DocumentAttributeLabels.get("usage_terms")
                                                      })),
                                              multiline: true,
                                              onChange: handleUsageTermsChange,
                                              rows: 6,
                                              value: usage_terms
                                            })
                                      ],
                                      direction: ["column"],
                                      spacing: "1rem",
                                      sx: {
                                        paddingTop: "1em"
                                      },
                                      useFlexGap: true
                                    })
                              }),
                          JsxRuntime.jsxs(Stack, {
                                children: [
                                  JsxRuntime.jsx(FlexGrowSpacer.make, {}),
                                  JsxRuntime.jsx(Button, {
                                        children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                  msg: {
                                                    NAME: "msg",
                                                    VAL: [
                                                      "buttons.reset",
                                                      "Reset"
                                                    ]
                                                  }
                                                })),
                                        onClick: (function (param) {
                                            onReset();
                                          }),
                                        color: "error",
                                        disabled: !isModified
                                      }),
                                  JsxRuntime.jsx(Button, {
                                        children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                  msg: {
                                                    NAME: "msg",
                                                    VAL: [
                                                      "buttons.save",
                                                      "Save"
                                                    ]
                                                  }
                                                })),
                                        onClick: (function (param) {
                                            onSave();
                                          }),
                                        disabled: !isModified
                                      })
                                ],
                                direction: ["row"]
                              })
                        ],
                        sx: {
                          width: "100%"
                        }
                      })),
              direction: ["column"],
              spacing: "0.5rem",
              sx: {
                width: "100%",
                padding: "0.5rem"
              },
              useFlexGap: true
            });
}

var make = DocumentBrowserFormPanel;

export {
  make ,
}
/* react Not a pure module */
