// Generated by ReScript, PLEASE EDIT WITH CARE

import * as AuthManager from "../context/AuthManager.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../libs/i18n/ReactI18Next.js";
import * as JsxRuntime from "react/jsx-runtime";
import Button from "@mui/material/Button";

function LogoutButton(props) {
  var logout = AuthManager.useLogout();
  return JsxRuntime.jsx(Button, {
              children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                        msg: {
                          NAME: "msg",
                          VAL: [
                            "buttons.logout",
                            "Log out"
                          ]
                        }
                      })),
              onClick: (function (param) {
                  logout();
                })
            });
}

var make = LogoutButton;

export {
  make ,
}
/* AuthManager Not a pure module */
