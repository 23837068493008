// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Hooks from "@mantine/hooks";
import * as JsxRuntime from "react/jsx-runtime";

var _map = {"list":"list","icons":"icons"};

var context = React.createContext({
      view: "icons",
      setView: (function (param) {
          
        })
    });

var make = context.Provider;

function useCurrentView() {
  return React.useContext(context).view;
}

function useCurrentViewSwitcher() {
  var match = React.useContext(context);
  var setView = match.setView;
  var view = match.view;
  return function () {
    if (view === "icons") {
      return setView("list");
    } else {
      return setView("icons");
    }
  };
}

function CatalogBrowserViewContext(props) {
  var match = Hooks.useLocalStorage({
        key: "photocuba-folder-view",
        defaultValue: "icons",
        deserialize: (function (v) {
            return Prelude.default(_map[v], "icons");
          }),
        serialize: (function (v) {
            return v;
          })
      });
  return JsxRuntime.jsx(make, {
              value: {
                view: match[0],
                setView: match[1]
              },
              children: props.children
            });
}

var make$1 = CatalogBrowserViewContext;

export {
  useCurrentView ,
  useCurrentViewSwitcher ,
  make$1 as make,
}
/* context Not a pure module */
