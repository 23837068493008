// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Folder from "../models/Folder.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as FolderId from "../models/FolderId.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as DocumentId from "../models/DocumentId.js";
import * as FolderItem from "../models/FolderItem.js";
import * as UserContext from "../../../global/context/UserContext.js";
import * as UserPermission from "../../../global/models/UserPermission.js";
import * as SharedDBContext from "../../../global/context/SharedDBContext.js";
import * as SelectionManager from "./SelectionManager.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CatalogAPIEndpoint from "../api/CatalogAPIEndpoint.js";
import * as CurrentFolderManager from "./CurrentFolderManager.js";
import * as CurrentDocumentListManager from "./CurrentDocumentListManager.js";

var context = React.createContext(undefined);

var make = context.Provider;

function isActive() {
  return Curry._1(Prelude.OptionExported.$$Option.isSome, React.useContext(context));
}

function useIsClipped() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.clipped;
              }));
}

function usePut() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.put;
              }));
}

function useRemove() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.remove;
              }));
}

function useReplace() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.replace;
              }));
}

function useClear() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.clear;
              }));
}

function useCount() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.count;
              }));
}

function ClipboardManager$Contents$Clipboard(props) {
  var getDocuments = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.DocumentsClipboard.useGetCurrentSelectionIds());
  var putDocuments = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.DocumentsClipboard.useSelect());
  var removeDocuments = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.DocumentsClipboard.useUnselect());
  var resetDocuments = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.DocumentsClipboard.useReset());
  var getFolders = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.FoldersClipboard.useGetCurrentSelectionIds());
  var putFolders = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.FoldersClipboard.useSelect());
  var removeFolders = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.FoldersClipboard.useUnselect());
  var resetFolders = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.FoldersClipboard.useReset());
  var clipped = React.useCallback((function (item) {
          if (item.NAME === "document") {
            return DocumentId.$$Set.has(getDocuments(), item.VAL.id);
          } else {
            return FolderId.$$Set.has(getFolders(), item.VAL.id);
          }
        }), [
        getDocuments,
        getFolders
      ]);
  var put = React.useCallback((function (items) {
          var folders = Curry._2(Prelude.$$Array.keepMap, items, FolderItem.getFolder);
          var documents = Curry._2(Prelude.$$Array.keepMap, items, FolderItem.getDocument);
          putFolders(folders);
          putDocuments(documents);
        }), [
        putDocuments,
        putFolders
      ]);
  var remove = React.useCallback((function (items) {
          var folders = Curry._2(Prelude.$$Array.keepMap, items, FolderItem.getFolder);
          var documents = Curry._2(Prelude.$$Array.keepMap, items, FolderItem.getDocument);
          removeFolders(folders);
          removeDocuments(documents);
        }), [
        removeDocuments,
        removeFolders
      ]);
  var clear = React.useCallback((function () {
          resetDocuments();
          resetFolders();
        }), [
        resetDocuments,
        resetFolders
      ]);
  var replace = React.useCallback((function (items) {
          clear();
          put(items);
        }), [
        put,
        clear
      ]);
  var count = React.useCallback((function () {
          return FolderId.$$Set.size(getFolders()) + DocumentId.$$Set.size(getDocuments()) | 0;
        }), [
        getFolders,
        getDocuments
      ]);
  return JsxRuntime.jsx(make, {
              value: {
                put: put,
                remove: remove,
                replace: replace,
                clear: clear,
                count: count,
                clipped: clipped
              },
              children: props.children
            });
}

function ClipboardManager$Contents(props) {
  var children = props.children;
  var permissions = props.permissions;
  var userPerms = UserContext.useUserPermissionsClaim();
  var allowed = React.useMemo((function () {
          if (permissions !== undefined) {
            return UserPermission.Query.matches(permissions, userPerms);
          } else {
            return true;
          }
        }), [
        permissions,
        userPerms
      ]);
  if (allowed) {
    return JsxRuntime.jsx(SelectionManager.DocumentsClipboard.make, {
                children: JsxRuntime.jsx(SelectionManager.FoldersClipboard.make, {
                      children: JsxRuntime.jsx(ClipboardManager$Contents$Clipboard, {
                            children: children
                          })
                    })
              });
  } else {
    return children;
  }
}

var context$1 = React.createContext(undefined);

var make$1 = context$1.Provider;

function usePaste() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context$1), (function (param) {
                return param.paste;
              }));
}

function useAllowed() {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context$1), (function (param) {
                    return param.allowed;
                  })), (function () {
                return false;
              }));
}

function ClipboardManager$Paster(props) {
  var target = CurrentFolderManager.useCurrentFolder();
  var updateFolder = CurrentFolderManager.useUpdateOrAddFolder();
  var updateDocument = CurrentDocumentListManager.useUpdateOrAddDocument();
  var token = Curry._1(Prelude.OptionExported.$$Option.getExn, UserContext.useAccessToken());
  var match = SharedDBContext.useQuery();
  var write = match.write;
  var makeWrite = match.makeWrite;
  var getFolders = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.FoldersClipboard.useGetCurrentSelection());
  var clearFolders = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.FoldersClipboard.useReset());
  var getDocuments = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.DocumentsClipboard.useGetCurrentSelection());
  var clearDocuments = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.DocumentsClipboard.useReset());
  var moveDocument = React.useMemo((function () {
          return async function ($$document, target) {
            var e = await CatalogAPIEndpoint.UpdateDocument.move($$document, target, token);
            var result;
            if (e.TAG === "Ok") {
              var d = e._0;
              if (d !== undefined) {
                updateDocument(d);
                result = {
                  TAG: "Ok",
                  _0: d
                };
              } else {
                result = {
                  TAG: "Ok",
                  _0: $$document
                };
              }
            } else {
              result = {
                TAG: "Error",
                _0: e._0
              };
            }
            if (result.TAG !== "Ok") {
              return {
                      TAG: "Error",
                      _0: result._0
                    };
            }
            var init = makeWrite();
            await write({
                  documents: [{
                      TAG: "Save",
                      _0: result._0
                    }],
                  folders: init.folders,
                  bundlers: init.bundlers
                });
            return {
                    TAG: "Ok",
                    _0: undefined
                  };
          };
        }), [
        token,
        makeWrite,
        write,
        updateDocument
      ]);
  var moveFolder = React.useMemo((function () {
          return async function (folder, target) {
            var e = await CatalogAPIEndpoint.UpdateFolder.move(folder, target, token);
            var result;
            if (e.TAG === "Ok") {
              var f = e._0;
              if (f !== undefined) {
                updateFolder(f);
                result = {
                  TAG: "Ok",
                  _0: f
                };
              } else {
                result = {
                  TAG: "Ok",
                  _0: folder
                };
              }
            } else {
              result = {
                TAG: "Error",
                _0: e._0
              };
            }
            if (result.TAG !== "Ok") {
              return {
                      TAG: "Error",
                      _0: result._0
                    };
            }
            var init = makeWrite();
            await write({
                  documents: init.documents,
                  folders: [{
                      TAG: "Save",
                      _0: result._0
                    }],
                  bundlers: init.bundlers
                });
            return {
                    TAG: "Ok",
                    _0: undefined
                  };
          };
        }), [
        token,
        makeWrite,
        write,
        updateFolder
      ]);
  var begin = CurrentFolderManager.useBeginTransaction();
  var commit = CurrentFolderManager.useCommit();
  var paste = React.useMemo((function () {
          return Curry._2(Prelude.OptionExported.$$Option.map, target, (function (target) {
                        return function (onProgress, onDone) {
                          var perform = async function (items, results) {
                            if (!items) {
                              return Prelude.OptionExported.$$Option.$$do(onDone, (function (fn) {
                                            fn();
                                          }));
                            }
                            var match = items.hd;
                            if (match.NAME === "document") {
                              var d = match.VAL;
                              var res = await moveDocument(d, target.id);
                              var results$1;
                              if (res.TAG === "Ok") {
                                Prelude.OptionExported.$$Option.$$do(onProgress, (function (fn) {
                                        fn({
                                              TAG: "Ok",
                                              _0: undefined
                                            });
                                      }));
                                results$1 = results;
                              } else {
                                Prelude.OptionExported.$$Option.$$do(onProgress, (function (fn) {
                                        fn({
                                              TAG: "Error",
                                              _0: {
                                                NAME: "document",
                                                VAL: d
                                              }
                                            });
                                      }));
                                results$1 = Prelude.$$Array.append(results, {
                                      NAME: "document",
                                      VAL: d
                                    });
                              }
                              return await perform(items.tl, results$1);
                            }
                            var f = match.VAL;
                            var res$1 = await moveFolder(f, target.id);
                            var results$2;
                            if (res$1.TAG === "Ok") {
                              Prelude.OptionExported.$$Option.$$do(onProgress, (function (fn) {
                                      fn({
                                            TAG: "Ok",
                                            _0: undefined
                                          });
                                    }));
                              results$2 = results;
                            } else {
                              Prelude.OptionExported.$$Option.$$do(onProgress, (function (fn) {
                                      fn({
                                            TAG: "Error",
                                            _0: {
                                              NAME: "folder",
                                              VAL: f
                                            }
                                          });
                                    }));
                              results$2 = Prelude.$$Array.append(results, {
                                    NAME: "folder",
                                    VAL: f
                                  });
                            }
                            return await perform(items.tl, results$2);
                          };
                          var documents = DocumentId.$$Map.valuesToArray(getDocuments()).map(FolderItem.fromDocument);
                          var folders = FolderId.$$Map.valuesToArray(getFolders()).map(FolderItem.fromFolder);
                          var items = Belt_List.fromArray(Curry._2(Prelude.$$Array.concat, documents, folders));
                          begin();
                          Prelude.thenDo(perform(items, []).finally(function () {
                                    commit();
                                  }), (function () {
                                  clearDocuments();
                                  clearFolders();
                                }));
                        };
                      }));
        }), [
        target,
        getFolders,
        getDocuments,
        moveFolder,
        moveDocument,
        begin,
        commit
      ]);
  var allowed = React.useCallback((function () {
          if (target !== undefined) {
            return FolderId.$$Map.valuesToArray(getFolders()).reduce((function (result, folder) {
                          if (result) {
                            return !Folder.isDescendantOf(target, folder);
                          } else {
                            return false;
                          }
                        }), true);
          } else {
            return false;
          }
        }), [
        target,
        getFolders
      ]);
  var value = Curry._2(Prelude.OptionExported.$$Option.map, paste, (function (paste) {
          return {
                  paste: paste,
                  allowed: allowed
                };
        }));
  return JsxRuntime.jsx(make$1, {
              value: value,
              children: props.children
            });
}

var Contents = {
  isActive: isActive,
  useIsClipped: useIsClipped,
  usePut: usePut,
  useRemove: useRemove,
  useReplace: useReplace,
  useClear: useClear,
  useCount: useCount,
  make: ClipboardManager$Contents
};

var Paster = {
  usePaste: usePaste,
  useAllowed: useAllowed,
  make: ClipboardManager$Paster
};

export {
  Contents ,
  Paster ,
}
/* context Not a pure module */
