// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Belt_MapInt from "rescript/lib/es6/belt_MapInt.js";
import * as Belt_SetInt from "rescript/lib/es6/belt_SetInt.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../../../libs/i18n/ReactI18Next.js";
import * as ReactDOMStyle from "@rescript/react/lib/es6/src/ReactDOMStyle.js";
import * as ReactI18next from "react-i18next";
import * as ActionsManager from "../../../../global/context/ActionsManager.js";
import * as AccountsManager from "../../context/AccountsManager.js";
import * as FeedbackDelayed from "../../../../global/components/FeedbackDelayed.js";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import * as JsxRuntime from "react/jsx-runtime";
import Stack from "@mui/material/Stack";
import * as AccountsListService from "../../context/AccountsListService.js";
import * as ConfirmDeleteDialog from "../../../../global/components/ConfirmDeleteDialog.js";
import Dialog from "@mui/material/Dialog";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import * as AccountsSelectionManager from "../../context/AccountsSelectionManager.js";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import LinearProgress from "@mui/material/LinearProgress";
import DialogContentText from "@mui/material/DialogContentText";
import CancelRounded from "@mui/icons-material/CancelRounded";
import AccountCircleRounded from "@mui/icons-material/AccountCircleRounded";

function AccountsSelectionAffordance$ClearSelection(props) {
  var reset = Curry._1(Prelude.OptionExported.$$Option.getExn, AccountsSelectionManager.useReset());
  return JsxRuntime.jsx(Tooltip, {
              children: JsxRuntime.jsx(IconButton, {
                    children: Caml_option.some(JsxRuntime.jsx(CancelRounded, {})),
                    onClick: (function (param) {
                        reset();
                      })
                  }),
              title: JsxRuntime.jsx(ReactI18next.Trans, {
                    i18nKey: "clearSelection",
                    children: "Clear accounts selection"
                  })
            });
}

function AccountsSelectionAffordance$SelectionInfo(props) {
  var getCurrentSelectionIds = Curry._1(Prelude.OptionExported.$$Option.getExn, AccountsSelectionManager.useGetCurrentSelectionIds());
  var count = React.useMemo((function () {
          return Belt_SetInt.size(getCurrentSelectionIds());
        }), [getCurrentSelectionIds]);
  if (count < 100) {
    return JsxRuntime.jsx(Box, {
                style: {
                  whiteSpace: "nowrap"
                },
                children: Caml_option.some(JsxRuntime.jsx(ReactI18next.Trans, {
                          i18nKey: "countSelected",
                          children: "{{count}} selected",
                          count: count
                        })),
                component: "span",
                sx: {
                  display: {
                    xs: "none",
                    md: "inline"
                  }
                }
              });
  } else {
    return JsxRuntime.jsx(Box, {
                style: {
                  whiteSpace: "nowrap"
                },
                children: Caml_option.some(JsxRuntime.jsx(ReactI18next.Trans, {
                          children: "99+ selected"
                        })),
                component: "span",
                sx: {
                  display: {
                    xs: "none",
                    lg: "inline"
                  }
                }
              });
  }
}

function AccountsSelectionAffordance$BulkDeactivation$ConfirmDialog(props) {
  var count = props.items.length;
  var content = count > 0 ? JsxRuntime.jsx(ReactI18next.Trans, {
          i18nKey: "accounts.bulk-deactivation.confirmation-message",
          children: "Are you sure you want to deactivate {{count}} accounts?",
          count: count
        }) : null;
  var content$1 = count > 1 ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx(DialogContentText, {
                  children: Caml_option.some(content)
                }),
            JsxRuntime.jsx(DialogContentText, {
                  children: Caml_option.some(JsxRuntime.jsxs(ReactI18next.Trans, {
                            i18nKey: "accounts.bulk-deactivation.enter-deactivate",
                            children: [
                              "To proceed you must enter the word ",
                              JsxRuntime.jsx("strong", {
                                    children: "DEACTIVATE"
                                  }),
                              " below."
                            ]
                          }))
                })
          ]
        }) : content;
  var match = ReactI18next.useTranslation();
  var confirmMessage = count > 1 ? match.t("DEACTIVATE") : undefined;
  return JsxRuntime.jsx(ConfirmDeleteDialog.make, {
              open_: true,
              title: {
                NAME: "msg",
                VAL: [
                  "accounts.bulk-deactivation.confirm-dialog-title",
                  "Confirm deactivation"
                ]
              },
              content: Caml_option.some(content$1),
              confirmLabel: {
                NAME: "str",
                VAL: "Deactivate"
              },
              onCancel: props.onCancel,
              onDelete: props.onDelete,
              confirmMessage: confirmMessage,
              fullWidth: true,
              maxWidth: "sm"
            });
}

function AccountsSelectionAffordance$BulkDeactivation(props) {
  var match = React.useState(function () {
        return "Idle";
      });
  var setState = match[1];
  var state = match[0];
  var getSelection = Curry._1(Prelude.OptionExported.$$Option.getExn, AccountsSelectionManager.useGetCurrentSelection());
  var reset = Curry._1(Prelude.OptionExported.$$Option.getExn, AccountsSelectionManager.useReset());
  var refresh = AccountsListService.useRefresh();
  var bulkDeactivate = Curry._1(Prelude.OptionExported.$$Option.getExn, AccountsManager.useBulkDeactivateAccounts());
  React.useEffect((function () {
          if (typeof state === "object" && state.TAG !== "Confirmation") {
            Prelude.PromisedResult.map(bulkDeactivate(state._0), (function () {
                    reset();
                    refresh();
                    setState(function (param) {
                          return "Idle";
                        });
                  }));
          }
          
        }), [
        state,
        bulkDeactivate,
        reset,
        refresh
      ]);
  var onCancel = React.useCallback((function () {
          setState(function (param) {
                return "Idle";
              });
        }), [setState]);
  var onDeleteConfirmed = React.useCallback((function () {
          if (typeof state !== "object") {
            return ;
          }
          if (state.TAG !== "Confirmation") {
            return ;
          }
          var items = state._0;
          setState(function (param) {
                return {
                        TAG: "Deactivating",
                        _0: items
                      };
              });
        }), [
        state,
        setState
      ]);
  var tmp;
  tmp = typeof state !== "object" ? null : (
      state.TAG === "Confirmation" ? JsxRuntime.jsx(AccountsSelectionAffordance$BulkDeactivation$ConfirmDialog, {
              items: state._0,
              onCancel: onCancel,
              onDelete: onDeleteConfirmed
            }) : JsxRuntime.jsx(FeedbackDelayed.make, {
              children: JsxRuntime.jsxs(Dialog, {
                    open: true,
                    children: [
                      JsxRuntime.jsx(DialogTitle, {
                            children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                      msg: {
                                        NAME: "msg",
                                        VAL: [
                                          "accounts.bulk-deactivating",
                                          "Deactivating accounts"
                                        ]
                                      }
                                    }))
                          }),
                      JsxRuntime.jsx(DialogContent, {
                            children: Caml_option.some(JsxRuntime.jsx(LinearProgress, {}))
                          })
                    ],
                    fullWidth: true,
                    maxWidth: "sm"
                  }),
              fallback: Caml_option.some(null),
              delay: 300
            })
    );
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Fab, {
                            children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                      children: JsxRuntime.jsx(AccountCircleRounded, {}),
                                      title: JsxRuntime.jsx(ReactI18Next.Message.make, {
                                            msg: {
                                              NAME: "msg",
                                              VAL: [
                                                "accounts.bulk-deactivate-selected",
                                                "Deactivate"
                                              ]
                                            }
                                          })
                                    })),
                            color: "warning",
                            size: "small"
                          }),
                      onClick: (function (param) {
                          var accounts = Belt_MapInt.valuesToArray(getSelection());
                          setState(function (param) {
                                return {
                                        TAG: "Confirmation",
                                        _0: accounts
                                      };
                              });
                        })
                    }),
                tmp
              ]
            });
}

function AccountsSelectionAffordance$BulkActivation(props) {
  var match = React.useState(function () {
        return "Idle";
      });
  var setState = match[1];
  var state = match[0];
  var getSelection = Curry._1(Prelude.OptionExported.$$Option.getExn, AccountsSelectionManager.useGetCurrentSelection());
  var reset = Curry._1(Prelude.OptionExported.$$Option.getExn, AccountsSelectionManager.useReset());
  var refresh = AccountsListService.useRefresh();
  var bulkActivate = Curry._1(Prelude.OptionExported.$$Option.getExn, AccountsManager.useBulkActivateAccounts());
  React.useEffect((function () {
          if (typeof state === "object") {
            Prelude.PromisedResult.map(bulkActivate(state._0), (function () {
                    reset();
                    refresh();
                    setState(function (param) {
                          return "Idle";
                        });
                  }));
          }
          
        }), [
        state,
        bulkActivate,
        reset,
        refresh
      ]);
  var tmp;
  tmp = typeof state !== "object" ? null : JsxRuntime.jsx(FeedbackDelayed.make, {
          children: JsxRuntime.jsxs(Dialog, {
                open: true,
                children: [
                  JsxRuntime.jsx(DialogTitle, {
                        children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                  msg: {
                                    NAME: "msg",
                                    VAL: [
                                      "accounts.bulk-activating",
                                      "Activating accounts"
                                    ]
                                  }
                                }))
                      }),
                  JsxRuntime.jsx(DialogContent, {
                        children: Caml_option.some(JsxRuntime.jsx(LinearProgress, {}))
                      })
                ],
                fullWidth: true,
                maxWidth: "sm"
              }),
          fallback: Caml_option.some(null),
          delay: 300
        });
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(IconButton, {
                            children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                      children: JsxRuntime.jsx(AccountCircleRounded, {}),
                                      title: JsxRuntime.jsx(ReactI18Next.Message.make, {
                                            msg: {
                                              NAME: "msg",
                                              VAL: [
                                                "accounts.bulk-activate-selected",
                                                "Activate"
                                              ]
                                            }
                                          })
                                    })),
                            color: "success",
                            size: "small"
                          }),
                      onClick: (function (param) {
                          var accounts = Belt_MapInt.valuesToArray(getSelection());
                          setState(function (param) {
                                return {
                                        TAG: "Activating",
                                        _0: accounts
                                      };
                              });
                        })
                    }),
                tmp
              ]
            });
}

function AccountsSelectionAffordance$SelectionMenu(props) {
  var getCurrentSelectionIds = Curry._1(Prelude.OptionExported.$$Option.getExn, AccountsSelectionManager.useGetCurrentSelectionIds());
  var count = React.useMemo((function () {
          return Belt_SetInt.size(getCurrentSelectionIds());
        }), [getCurrentSelectionIds]);
  if (count <= 0) {
    return null;
  }
  var style = ReactDOMStyle.unsafeAddProp({
        cursor: "default",
        userSelect: "none"
      }, "WebkitUserSelect", "none");
  return JsxRuntime.jsxs(Stack, {
              style: style,
              bgcolor: "action.selected",
              children: [
                JsxRuntime.jsx(AccountsSelectionAffordance$ClearSelection, {}),
                JsxRuntime.jsx(AccountsSelectionAffordance$SelectionInfo, {}),
                JsxRuntime.jsx(AccountsSelectionAffordance$BulkActivation, {}),
                JsxRuntime.jsx(AccountsSelectionAffordance$BulkDeactivation, {})
              ],
              direction: ["row"],
              spacing: "0.5rem",
              sx: {
                borderRadius: "9999px",
                alignItems: "center"
              }
            });
}

function AccountsSelectionAffordance(props) {
  return JsxRuntime.jsx(ActionsManager.Register.make, {
              name: "accounts-selection-menu",
              widget: (function (key) {
                  return JsxRuntime.jsx(AccountsSelectionAffordance$SelectionMenu, {}, key);
                }),
              sequence: 500,
              children: props.children
            });
}

var make = AccountsSelectionAffordance;

export {
  make ,
}
/* react Not a pure module */
