// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Tag from "../../tags/models/Tag.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Settings from "../../../Settings.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../../libs/i18n/ReactI18Next.js";
import * as TagAPIEndpoint from "../../tags/api/TagAPIEndpoint.js";
import * as SearchMetaMaker from "../utils/SearchMetaMaker.js";
import * as JsxRuntime from "react/jsx-runtime";

function getValueName(f) {
  return f.name;
}

function getValueKey(f) {
  return String(f.id);
}

var makeSolo = Tag.make;

var defaultPageSize = Settings.Autocomplete.maxItems;

var notFoundMessage = Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
          msg: {
            NAME: "msg",
            VAL: [
              "autocomplete.tags-not-found",
              "No matching tags found"
            ]
          }
        }));

var $$fetch = TagAPIEndpoint.Search.$$fetch;

var Impl = SearchMetaMaker.MakeAutocomplete({
      identifier: "TagsAutocomplete",
      defaultValue: undefined,
      getValueName: getValueName,
      getValueKey: getValueKey,
      makeSolo: makeSolo,
      notFoundMessage: notFoundMessage,
      promptMessage: undefined,
      defaultPageSize: defaultPageSize,
      groupBy: undefined,
      renderOption: undefined,
      $$fetch: $$fetch
    });

function TagsAutocomplete(props) {
  var __freeSolo = props.freeSolo;
  var __allowUnapproved = props.allowUnapproved;
  var allowUnapproved = __allowUnapproved !== undefined ? __allowUnapproved : false;
  var freeSolo = __freeSolo !== undefined ? __freeSolo : false;
  var getFilter = React.useMemo((function () {
          if (allowUnapproved) {
            return function (inputValue) {
              if (inputValue.length < 3) {
                var newrecord = Caml_obj.obj_dup(Tag.Filters.any);
                newrecord.startswith = inputValue;
                return newrecord;
              }
              var newrecord$1 = Caml_obj.obj_dup(Tag.Filters.any);
              newrecord$1.contains = inputValue;
              return newrecord$1;
            };
          } else {
            return function (inputValue) {
              if (inputValue.length < 3) {
                var newrecord = Caml_obj.obj_dup(Tag.Filters.approved);
                newrecord.startswith = inputValue;
                return newrecord;
              }
              var newrecord$1 = Caml_obj.obj_dup(Tag.Filters.approved);
              newrecord$1.contains = inputValue;
              return newrecord$1;
            };
          }
        }), [allowUnapproved]);
  var renderTag = React.useMemo((function () {
          if (allowUnapproved || freeSolo) {
            return (function (tag, props) {
                      var newrecord = Caml_obj.obj_dup(props);
                      newrecord.color = tag.approved ? "success" : "warning";
                      return newrecord;
                    });
          }
          
        }), [
        allowUnapproved,
        freeSolo
      ]);
  return JsxRuntime.jsx(Impl.make, {
              items: props.tags,
              onChange: props.onChange,
              getFilter: getFilter,
              renderTag: renderTag,
              label: props.label,
              variant: props.variant,
              sx: props.sx,
              fullWidth: props.fullWidth,
              limitTags: props.limitTags,
              freeSolo: freeSolo,
              disabled: props.disabled,
              helperText: props.helperText
            });
}

var make = TagsAutocomplete;

export {
  make ,
}
/* Impl Not a pure module */
