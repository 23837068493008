// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Tag from "../../tags/models/Tag.js";
import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Attribute from "../models/Attribute.js";
import * as Collection from "../../collections/models/Collection.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

var fields = {
  TAG: "Object",
  _0: [
    [
      "id",
      "String"
    ],
    [
      "file",
      "String"
    ]
  ]
};

var Deserializer = $$JSON.MakeDeserializer({
      fields: fields
    });

var $$File = {
  Deserializer: Deserializer
};

var fields$1 = {
  TAG: "Object",
  _0: [
    [
      "id",
      "String"
    ],
    [
      "file",
      "String"
    ],
    [
      "previews",
      {
        TAG: "Optional",
        _0: {
          TAG: "Object",
          _0: [
            [
              "small",
              {
                TAG: "Optional",
                _0: {
                  TAG: "Deserializer",
                  _0: Deserializer
                }
              }
            ],
            [
              "large",
              {
                TAG: "Optional",
                _0: {
                  TAG: "Deserializer",
                  _0: Deserializer
                }
              }
            ]
          ]
        }
      }
    ]
  ]
};

var Deserializer$1 = $$JSON.MakeDeserializer({
      fields: fields$1
    });

var Attachment = {
  Deserializer: Deserializer$1
};

var fields$2 = {
  TAG: "Object",
  _0: [
    [
      "kind",
      {
        TAG: "Literal",
        _0: "document"
      }
    ],
    [
      "id",
      "String"
    ],
    [
      "name",
      "String"
    ],
    [
      "namespace",
      "String"
    ],
    [
      "attachment",
      {
        TAG: "Deserializer",
        _0: Deserializer$1
      }
    ],
    [
      "parent",
      "String"
    ],
    [
      "created_at",
      "Datetime"
    ],
    [
      "updated_at",
      "Datetime"
    ],
    [
      "attributes",
      {
        TAG: "Collection",
        _0: Attribute.PayloadOutput.Deserializer
      }
    ],
    [
      "collections",
      {
        TAG: "Collection",
        _0: Collection.Deserializer
      }
    ],
    [
      "description",
      {
        TAG: "Optional",
        _0: "String"
      }
    ],
    [
      "tags",
      {
        TAG: "Collection",
        _0: Tag.Deserializer
      }
    ]
  ]
};

var Deserializer$2 = $$JSON.MakeDeserializer({
      fields: fields$2
    });

var DocumentNode = {
  Deserializer: Deserializer$2
};

var fields$3 = {
  TAG: "Object",
  _0: [
    [
      "kind",
      {
        TAG: "Literal",
        _0: "folder"
      }
    ],
    [
      "id",
      "String"
    ],
    [
      "name",
      "String"
    ],
    [
      "namespace",
      "String"
    ],
    [
      "path",
      "String"
    ],
    [
      "parent",
      {
        TAG: "Optional",
        _0: "String"
      }
    ],
    [
      "created_at",
      "Datetime"
    ],
    [
      "updated_at",
      "Datetime"
    ],
    [
      "attributes",
      {
        TAG: "Collection",
        _0: Attribute.PayloadOutput.Deserializer
      }
    ],
    [
      "size",
      "Float"
    ],
    [
      "items",
      "Int"
    ],
    [
      "locked",
      {
        TAG: "OptionalWithDefault",
        _0: "Boolean",
        _1: false
      }
    ],
    [
      "last_time_estimated",
      {
        TAG: "Optional",
        _0: "Date"
      }
    ],
    [
      "description",
      {
        TAG: "Optional",
        _0: "String"
      }
    ]
  ]
};

var Deserializer$3 = $$JSON.MakeDeserializer({
      fields: fields$3
    });

var FolderNode = {
  Deserializer: Deserializer$3
};

var $$TypeError = /* @__PURE__ */Caml_exceptions.create("CatalogAPICommon.TypeError");

function fromJSON(data) {
  var items = Js_json.classify(data);
  if (typeof items !== "object") {
    return {
            TAG: "Error",
            _0: "Cannot deserialize non-object"
          };
  }
  if (items.TAG !== "JSONObject") {
    return {
            TAG: "Error",
            _0: "Cannot deserialize non-object"
          };
  }
  var kind = Js_dict.get(items._0, "kind");
  if (kind === undefined) {
    return {
            TAG: "Error",
            _0: "Missing 'kind' in payload"
          };
  }
  var match = Js_json.classify(kind);
  if (typeof match !== "object") {
    return {
            TAG: "Error",
            _0: "Invalid 'kind' in payload"
          };
  }
  if (match.TAG !== "JSONString") {
    return {
            TAG: "Error",
            _0: "Invalid 'kind' in payload"
          };
  }
  switch (match._0) {
    case "document" :
        return Curry._2(Prelude.Result.map, Deserializer$2.fromJSON(data), (function (d) {
                      return {
                              NAME: "document",
                              VAL: d
                            };
                    }));
    case "folder" :
        return Curry._2(Prelude.Result.map, Deserializer$3.fromJSON(data), (function (f) {
                      return {
                              NAME: "folder",
                              VAL: f
                            };
                    }));
    default:
      return {
              TAG: "Error",
              _0: "Invalid 'kind' in payload"
            };
  }
}

var NodeDeserializer = {
  fromJSON: fromJSON
};

export {
  $$File ,
  Attachment ,
  DocumentNode ,
  FolderNode ,
  $$TypeError ,
  NodeDeserializer ,
}
/* Deserializer Not a pure module */
