// Generated by ReScript, PLEASE EDIT WITH CARE


function period(v) {
  if (v.NAME === "Milliseconds") {
    return v.VAL;
  } else {
    return 1.0 / v.VAL;
  }
}

function freq(v) {
  if (v.NAME === "Milliseconds") {
    return 1.0 / v.VAL;
  } else {
    return v.VAL;
  }
}

export {
  period ,
  freq ,
}
/* No side effect */
