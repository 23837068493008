// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ReactI18next from "react-i18next";
import * as JsxRuntime from "react/jsx-runtime";

var Trans = {};

function ReactI18Next$Message(props) {
  var msg = props.msg;
  var tr = ReactI18next.useTranslation();
  var t = Prelude.default(props.t, tr.t);
  var variant = msg.NAME;
  if (variant === "str") {
    return JsxRuntime.jsx(ReactI18next.Trans, {
                children: msg.VAL
              });
  }
  if (variant === "field") {
    return msg.VAL;
  }
  if (variant === "trans") {
    var match = msg.VAL;
    return JsxRuntime.jsx(ReactI18next.Trans, {
                i18nKey: match[0],
                t: t,
                children: match[1]
              });
  }
  var match$1 = msg.VAL;
  return JsxRuntime.jsx(ReactI18next.Trans, {
              i18nKey: match$1[0],
              t: t,
              children: match$1[1]
            });
}

var Message = {
  make: ReactI18Next$Message
};

export {
  Trans ,
  Message ,
}
/* react-i18next Not a pure module */
