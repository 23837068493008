// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as UserContext from "../../../global/context/UserContext.js";
import * as UserPermission from "../../../global/models/UserPermission.js";
import * as NamespaceContext from "../../../global/context/NamespaceContext.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CollectionsAPIEndpoint from "../api/CollectionsAPIEndpoint.js";

var canChangeCollections = UserPermission.Query.compile({
      TAG: "All",
      _0: ["catalog.change_collection"]
    });

var context = React.createContext(undefined);

var make = context.Provider;

var Provider = {
  make: make
};

function useCreateCollection() {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, React.useContext(context), (function (param) {
                return param.create;
              }));
}

function useUpdateCollection() {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, React.useContext(context), (function (param) {
                return param.update;
              }));
}

function useRemoveCollection() {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, React.useContext(context), (function (param) {
                return param.remove;
              }));
}

function useBulkRemoveCollections() {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, React.useContext(context), (function (param) {
                return param.bulkRemove;
              }));
}

function useSearchCollections() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.search;
              }));
}

function CollectionsManager(props) {
  var token = UserContext.useAccessToken();
  var ns = NamespaceContext.useNamespace();
  var namespace_id = ns.id;
  var permissions = UserContext.useUserPermissionsClaim();
  var allowed = React.useMemo((function () {
          return canChangeCollections(permissions);
        }), [permissions]);
  var value = React.useMemo((function () {
          return Curry._2(Prelude.OptionExported.$$Option.map, token, (function (token) {
                        var search = function (params, page, pageSize) {
                          return CollectionsAPIEndpoint.SearchInAppCollection.$$fetch(token, params, page, pageSize, undefined);
                        };
                        var create = allowed ? (function (name) {
                              return CollectionsAPIEndpoint.CreateCollection.$$do(name, namespace_id, token);
                            }) : undefined;
                        var update = allowed ? (function (collection) {
                              return CollectionsAPIEndpoint.UpdateCollection.$$do(collection, namespace_id, token);
                            }) : undefined;
                        var remove = allowed ? (function (collection) {
                              return CollectionsAPIEndpoint.RemoveCollection.$$do(collection, namespace_id, token);
                            }) : undefined;
                        var bulkRemove = allowed ? (function (collections) {
                              return CollectionsAPIEndpoint.BulkRemoveCollections.$$do(collections, namespace_id, token);
                            }) : undefined;
                        return {
                                search: search,
                                create: create,
                                remove: remove,
                                bulkRemove: bulkRemove,
                                update: update
                              };
                      }));
        }), [
        token,
        allowed
      ]);
  return JsxRuntime.jsx(make, {
              value: value,
              children: props.children
            });
}

var make$1 = CollectionsManager;

export {
  canChangeCollections ,
  context ,
  Provider ,
  useCreateCollection ,
  useUpdateCollection ,
  useRemoveCollection ,
  useBulkRemoveCollections ,
  useSearchCollections ,
  make$1 as make,
}
/* canChangeCollections Not a pure module */
