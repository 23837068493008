// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../../../libs/i18n/ReactI18Next.js";
import * as ActionsManager from "../../../../global/context/ActionsManager.js";
import * as FlexGrowSpacer from "../../../../global/components/FlexGrowSpacer.js";
import * as JsxRuntime from "react/jsx-runtime";
import Chip from "@mui/material/Chip";
import * as AccountsListService from "../../context/AccountsListService.js";
import Tooltip from "@mui/material/Tooltip";
import * as AccountsFiltersContext from "../../context/AccountsFiltersContext.js";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import AccountCircleRounded from "@mui/icons-material/AccountCircleRounded";

function AccountsCountAffordance$AccountsChip(props) {
  var activationStatus = props.activationStatus;
  var count = props.count;
  var label = count > 0 ? new (Intl.NumberFormat)().format(count) : JsxRuntime.jsx(ReactI18Next.Message.make, {
          msg: {
            NAME: "msg",
            VAL: [
              "accounts.no-accounts",
              "No accounts"
            ]
          }
        });
  var color = activationStatus === "any" ? "default" : (
      activationStatus === "active" ? "success" : "warning"
    );
  return React.useMemo((function () {
                return JsxRuntime.jsx(Chip, {
                            color: color,
                            label: Caml_option.some(label)
                          });
              }), [
              color,
              label
            ]);
}

function AccountsCountAffordance$ClearFilters(props) {
  var setFilters = props.setFilters;
  var filters = props.filters;
  var activationStatus = AccountsFiltersContext.useActivationStatus();
  var hidden = filters.contains === undefined && filters.startswith === undefined;
  return React.useMemo((function () {
                if (hidden) {
                  return null;
                } else {
                  return JsxRuntime.jsx(Tooltip, {
                              children: JsxRuntime.jsx(IconButton, {
                                    children: Caml_option.some(JsxRuntime.jsx(Close, {})),
                                    onClick: (function (param) {
                                        setFilters({
                                              activation_status: activationStatus
                                            });
                                      })
                                  }),
                              title: JsxRuntime.jsx(ReactI18Next.Message.make, {
                                    msg: {
                                      NAME: "str",
                                      VAL: "Clear filters"
                                    }
                                  })
                            });
                }
              }), [
              hidden,
              activationStatus
            ]);
}

function AccountsCountAffordance(props) {
  var filters = AccountsFiltersContext.useFilters();
  var setFilters = AccountsFiltersContext.useSetFilters();
  var count = AccountsListService.useCount();
  var activationStatus = AccountsFiltersContext.useActivationStatus();
  var setActivationStatus = AccountsFiltersContext.useSetActivationStatus();
  var setCurrentPage = AccountsListService.useSetCurrentPage();
  return JsxRuntime.jsx(ActionsManager.Register.make, {
              name: "accounts-activation-status",
              widget: (function (key) {
                  return JsxRuntime.jsxs(React.Fragment, {
                              children: [
                                JsxRuntime.jsx(FlexGrowSpacer.make, {}),
                                JsxRuntime.jsx(AccountsCountAffordance$ClearFilters, {
                                      filters: filters,
                                      setFilters: setFilters
                                    }),
                                JsxRuntime.jsx(AccountsCountAffordance$AccountsChip, {
                                      count: count,
                                      activationStatus: activationStatus
                                    }),
                                JsxRuntime.jsxs(ToggleButtonGroup, {
                                      children: [
                                        JsxRuntime.jsx(ToggleButton, {
                                              children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                                        children: JsxRuntime.jsx(AccountCircleRounded, {}),
                                                        title: JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                              msg: {
                                                                NAME: "msg",
                                                                VAL: [
                                                                  "accounts.toggle-show-all",
                                                                  "Show all"
                                                                ]
                                                              }
                                                            })
                                                      })),
                                              value: "any"
                                            }),
                                        JsxRuntime.jsx(ToggleButton, {
                                              children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                                        children: JsxRuntime.jsx(AccountCircleRounded, {
                                                              color: "success"
                                                            }),
                                                        title: JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                              msg: {
                                                                NAME: "msg",
                                                                VAL: [
                                                                  "accounts.toggle-show-active",
                                                                  "Show active"
                                                                ]
                                                              }
                                                            })
                                                      })),
                                              value: "active"
                                            }),
                                        JsxRuntime.jsx(ToggleButton, {
                                              children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                                        children: JsxRuntime.jsx(AccountCircleRounded, {
                                                              color: "warning"
                                                            }),
                                                        title: JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                              msg: {
                                                                NAME: "msg",
                                                                VAL: [
                                                                  "accounts.toggle-show-inactive",
                                                                  "Show inactive"
                                                                ]
                                                              }
                                                            })
                                                      })),
                                              value: "inactive"
                                            })
                                      ],
                                      exclusive: true,
                                      onChange: (function (param, v) {
                                          setCurrentPage(1);
                                          console.log("AccountsCountAffordance", "approval", v);
                                          setActivationStatus(v);
                                        }),
                                      size: "small",
                                      value: activationStatus
                                    })
                              ]
                            }, key);
                }),
              sequence: 5000,
              children: props.children
            });
}

var make = AccountsCountAffordance;

export {
  make ,
}
/* react Not a pure module */
