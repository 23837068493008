// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Folder from "../models/Folder.js";
import * as $$Document from "../models/Document.js";
import * as FolderId from "../models/FolderId.js";
import * as DocumentId from "../models/DocumentId.js";
import * as SelectionManagerMaker from "../../../utils/SelectionManagerMaker.js";

function getItemId($$document) {
  return $$document.id;
}

function canBeSelected(param) {
  return true;
}

var Documents = SelectionManagerMaker.UUID.Make({
      Model: {
        Id: $$Document.Id
      },
      Id: DocumentId,
      scope: "Documents",
      getItemId: getItemId,
      canBeSelected: canBeSelected
    });

function getItemId$1(folder) {
  return folder.id;
}

function canBeSelected$1(f) {
  return !Folder.locked(f);
}

var Folders = SelectionManagerMaker.UUID.Make({
      Model: {
        Id: Folder.Id
      },
      Id: FolderId,
      scope: "Folders",
      getItemId: getItemId$1,
      canBeSelected: canBeSelected$1
    });

function getItemId$2($$document) {
  return $$document.id;
}

function canBeSelected$2(param) {
  return true;
}

var DocumentsClipboard = SelectionManagerMaker.UUID.Make({
      Model: {
        Id: $$Document.Id
      },
      Id: DocumentId,
      scope: "DocumentsClipboard",
      getItemId: getItemId$2,
      canBeSelected: canBeSelected$2
    });

function getItemId$3(folder) {
  return folder.id;
}

function canBeSelected$3(param) {
  return true;
}

var FoldersClipboard = SelectionManagerMaker.UUID.Make({
      Model: {
        Id: Folder.Id
      },
      Id: FolderId,
      scope: "FoldersClipboard",
      getItemId: getItemId$3,
      canBeSelected: canBeSelected$3
    });

var Documents_useGetCurrentSelection = Documents.useGetCurrentSelection;

var Documents_useGetCurrentSelectionIds = Documents.useGetCurrentSelectionIds;

var Documents_useIsSelected = Documents.useIsSelected;

var Documents_useAreAllSelected = Documents.useAreAllSelected;

var Documents_useHasSelection = Documents.useHasSelection;

var Documents_useSelect = Documents.useSelect;

var Documents_useUnselect = Documents.useUnselect;

var Documents_useToggle = Documents.useToggle;

var Documents_useReset = Documents.useReset;

var Documents_useBegin = Documents.useBegin;

var Documents_useCommit = Documents.useCommit;

var Documents_useRollback = Documents.useRollback;

var Documents_make = Documents.make;

var Documents$1 = {
  useGetCurrentSelection: Documents_useGetCurrentSelection,
  useGetCurrentSelectionIds: Documents_useGetCurrentSelectionIds,
  useIsSelected: Documents_useIsSelected,
  useAreAllSelected: Documents_useAreAllSelected,
  useHasSelection: Documents_useHasSelection,
  useSelect: Documents_useSelect,
  useUnselect: Documents_useUnselect,
  useToggle: Documents_useToggle,
  useReset: Documents_useReset,
  useBegin: Documents_useBegin,
  useCommit: Documents_useCommit,
  useRollback: Documents_useRollback,
  make: Documents_make
};

var Folders_useGetCurrentSelection = Folders.useGetCurrentSelection;

var Folders_useGetCurrentSelectionIds = Folders.useGetCurrentSelectionIds;

var Folders_useIsSelected = Folders.useIsSelected;

var Folders_useAreAllSelected = Folders.useAreAllSelected;

var Folders_useHasSelection = Folders.useHasSelection;

var Folders_useSelect = Folders.useSelect;

var Folders_useUnselect = Folders.useUnselect;

var Folders_useToggle = Folders.useToggle;

var Folders_useReset = Folders.useReset;

var Folders_useBegin = Folders.useBegin;

var Folders_useCommit = Folders.useCommit;

var Folders_useRollback = Folders.useRollback;

var Folders_make = Folders.make;

var Folders$1 = {
  useGetCurrentSelection: Folders_useGetCurrentSelection,
  useGetCurrentSelectionIds: Folders_useGetCurrentSelectionIds,
  useIsSelected: Folders_useIsSelected,
  useAreAllSelected: Folders_useAreAllSelected,
  useHasSelection: Folders_useHasSelection,
  useSelect: Folders_useSelect,
  useUnselect: Folders_useUnselect,
  useToggle: Folders_useToggle,
  useReset: Folders_useReset,
  useBegin: Folders_useBegin,
  useCommit: Folders_useCommit,
  useRollback: Folders_useRollback,
  make: Folders_make
};

var DocumentsClipboard_useGetCurrentSelection = DocumentsClipboard.useGetCurrentSelection;

var DocumentsClipboard_useGetCurrentSelectionIds = DocumentsClipboard.useGetCurrentSelectionIds;

var DocumentsClipboard_useIsSelected = DocumentsClipboard.useIsSelected;

var DocumentsClipboard_useAreAllSelected = DocumentsClipboard.useAreAllSelected;

var DocumentsClipboard_useHasSelection = DocumentsClipboard.useHasSelection;

var DocumentsClipboard_useSelect = DocumentsClipboard.useSelect;

var DocumentsClipboard_useUnselect = DocumentsClipboard.useUnselect;

var DocumentsClipboard_useToggle = DocumentsClipboard.useToggle;

var DocumentsClipboard_useReset = DocumentsClipboard.useReset;

var DocumentsClipboard_useBegin = DocumentsClipboard.useBegin;

var DocumentsClipboard_useCommit = DocumentsClipboard.useCommit;

var DocumentsClipboard_useRollback = DocumentsClipboard.useRollback;

var DocumentsClipboard_make = DocumentsClipboard.make;

var DocumentsClipboard$1 = {
  useGetCurrentSelection: DocumentsClipboard_useGetCurrentSelection,
  useGetCurrentSelectionIds: DocumentsClipboard_useGetCurrentSelectionIds,
  useIsSelected: DocumentsClipboard_useIsSelected,
  useAreAllSelected: DocumentsClipboard_useAreAllSelected,
  useHasSelection: DocumentsClipboard_useHasSelection,
  useSelect: DocumentsClipboard_useSelect,
  useUnselect: DocumentsClipboard_useUnselect,
  useToggle: DocumentsClipboard_useToggle,
  useReset: DocumentsClipboard_useReset,
  useBegin: DocumentsClipboard_useBegin,
  useCommit: DocumentsClipboard_useCommit,
  useRollback: DocumentsClipboard_useRollback,
  make: DocumentsClipboard_make
};

var FoldersClipboard_useGetCurrentSelection = FoldersClipboard.useGetCurrentSelection;

var FoldersClipboard_useGetCurrentSelectionIds = FoldersClipboard.useGetCurrentSelectionIds;

var FoldersClipboard_useIsSelected = FoldersClipboard.useIsSelected;

var FoldersClipboard_useAreAllSelected = FoldersClipboard.useAreAllSelected;

var FoldersClipboard_useHasSelection = FoldersClipboard.useHasSelection;

var FoldersClipboard_useSelect = FoldersClipboard.useSelect;

var FoldersClipboard_useUnselect = FoldersClipboard.useUnselect;

var FoldersClipboard_useToggle = FoldersClipboard.useToggle;

var FoldersClipboard_useReset = FoldersClipboard.useReset;

var FoldersClipboard_useBegin = FoldersClipboard.useBegin;

var FoldersClipboard_useCommit = FoldersClipboard.useCommit;

var FoldersClipboard_useRollback = FoldersClipboard.useRollback;

var FoldersClipboard_make = FoldersClipboard.make;

var FoldersClipboard$1 = {
  useGetCurrentSelection: FoldersClipboard_useGetCurrentSelection,
  useGetCurrentSelectionIds: FoldersClipboard_useGetCurrentSelectionIds,
  useIsSelected: FoldersClipboard_useIsSelected,
  useAreAllSelected: FoldersClipboard_useAreAllSelected,
  useHasSelection: FoldersClipboard_useHasSelection,
  useSelect: FoldersClipboard_useSelect,
  useUnselect: FoldersClipboard_useUnselect,
  useToggle: FoldersClipboard_useToggle,
  useReset: FoldersClipboard_useReset,
  useBegin: FoldersClipboard_useBegin,
  useCommit: FoldersClipboard_useCommit,
  useRollback: FoldersClipboard_useRollback,
  make: FoldersClipboard_make
};

export {
  Documents$1 as Documents,
  Folders$1 as Folders,
  DocumentsClipboard$1 as DocumentsClipboard,
  FoldersClipboard$1 as FoldersClipboard,
}
/* Documents Not a pure module */
