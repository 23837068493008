// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FailurePage from "./FailurePage.js";
import * as GoBackButton from "../GoBackButton.js";
import * as FlexGrowSpacer from "../FlexGrowSpacer.js";
import * as JsxRuntime from "react/jsx-runtime";
import Stack from "@mui/material/Stack";

function NotFoundPage(props) {
  return JsxRuntime.jsx(FailurePage.make, {
              title: {
                NAME: "msg",
                VAL: [
                  "404.title",
                  "Not found"
                ]
              },
              reasons: [{
                  NAME: "msg",
                  VAL: [
                    "404.description",
                    "Hum! We know nothing about the thing you are looking for here."
                  ]
                }],
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        children: [
                          JsxRuntime.jsx(FlexGrowSpacer.make, {}),
                          JsxRuntime.jsx(GoBackButton.make, {})
                        ],
                        direction: ["row"],
                        spacing: 2.0
                      }))
            });
}

var make = NotFoundPage;

export {
  make ,
}
/* FailurePage Not a pure module */
