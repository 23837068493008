// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Folder from "../models/Folder.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril from "mithril";
import * as APIMaker from "../../../utils/APIMaker.js";
import * as $$Document from "../models/Document.js";
import * as Settings from "../../../Settings.js";
import * as Attribute from "../models/Attribute.js";
import * as LocalFile from "../../../utils/LocalFile.js";
import * as DocumentId from "../models/DocumentId.js";
import * as Thumbnails from "../../../utils/Thumbnails.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as PendingUpload from "../models/PendingUpload.js";
import * as MithrilRequest from "../../../utils/MithrilRequest.js";
import * as CatalogAPICommon from "./CatalogAPICommon.js";

async function _read(namespace, folder, token) {
  var url = Settings.BackendAPI.apiBaseUrl + "/folder/:namespace/:folder";
  var request = MithrilRequest.get(url);
  var request$1 = APIMaker.BearerAuthenticator.withAuth(request, Caml_option.some(token));
  var request$2 = MithrilRequest.payload(request$1, {
        namespace: namespace,
        folder: folder
      });
  var data = await Mithril.request(request$2);
  var items = Js_json.classify(data);
  if (typeof items !== "object") {
    throw {
          RE_EXN_ID: CatalogAPICommon.$$TypeError,
          message: "Unexpected non-array",
          payload: data,
          Error: new Error()
        };
  }
  if (items.TAG === "JSONArray") {
    var items$1 = items._0;
    var parsed = items$1.map(function (item) {
          return [
                  item,
                  CatalogAPICommon.NodeDeserializer.fromJSON(item)
                ];
        });
    console.log("CatalogAPIEndpoint", "read folder", {
          items: items$1,
          parsed: parsed
        });
    var invalid = Curry._2(Prelude.$$Array.keep, parsed, (function (param) {
            return Curry._1(Prelude.Result.isError, param[1]);
          }));
    if (!Prelude.$$Array.isEmpty(invalid)) {
      console.error("Could not parse some nodes", invalid);
    }
    return Curry._2(Prelude.$$Array.keepMap, parsed, (function (param) {
                  return Prelude.Result.ok(param[1]);
                }));
  }
  throw {
        RE_EXN_ID: CatalogAPICommon.$$TypeError,
        message: "Unexpected non-array",
        payload: data,
        Error: new Error()
      };
}

function read(namespace, folder, token) {
  return Prelude.catchResolve(Prelude.thenResolve(_read(namespace, folder, token), (function (items) {
                    return {
                            TAG: "Ok",
                            _0: items
                          };
                  })), (function (e) {
                return {
                        TAG: "Error",
                        _0: e
                      };
              }));
}

var fields = {
  TAG: "Object",
  _0: [[
      "result",
      {
        TAG: "Deserializer",
        _0: CatalogAPICommon.FolderNode.Deserializer
      }
    ]]
};

var Deserializer = $$JSON.MakeDeserializer({
      fields: fields
    });

var Impl = APIMaker.MakeBackendAPIEndpoint({
      url: "/folder/:namespace/:folder",
      Deserializer: Deserializer
    });

function $$do(parent, id, name, token) {
  var operation = {
    operation: {
      kind: "create-folder",
      id: id,
      name: name
    }
  };
  return Prelude.PromisedResult.map(Impl.postOne({
                  namespace: parent.namespace,
                  folder: parent.id
                }, Caml_option.some(token), operation), (function (res) {
                return Curry._2(Prelude.OptionExported.$$Option.map, res, (function (param) {
                              return Folder.fromBackend(param.result);
                            }));
              }));
}

var fields$1 = {
  TAG: "Object",
  _0: [[
      "result",
      {
        TAG: "Optional",
        _0: {
          TAG: "Deserializer",
          _0: CatalogAPICommon.FolderNode.Deserializer
        }
      }
    ]]
};

var Deserializer$1 = $$JSON.MakeDeserializer({
      fields: fields$1
    });

var Impl$1 = APIMaker.MakeBackendAPIEndpoint({
      url: "/folder/:namespace/:folder",
      Deserializer: Deserializer$1
    });

function rename(folder, name, token) {
  var operation = {
    operation: {
      kind: "update-folder",
      name: name
    }
  };
  return Prelude.PromisedResult.map(Impl$1.postOne({
                  namespace: folder.namespace,
                  folder: folder.id
                }, Caml_option.some(token), operation), (function (res) {
                return Curry._2(Prelude.OptionExported.$$Option.map, res, (function (param) {
                              return Folder.fromBackend(param.result);
                            }));
              }));
}

function setDescription(folder, description, token) {
  var operation = {
    operation: {
      kind: "update-folder",
      description: description
    }
  };
  return Prelude.PromisedResult.map(Impl$1.postOne({
                  namespace: folder.namespace,
                  folder: folder.id
                }, Caml_option.some(token), operation), (function (res) {
                return Curry._2(Prelude.OptionExported.$$Option.map, res, (function (param) {
                              return Folder.fromBackend(param.result);
                            }));
              }));
}

function setLocked(folder, locked, token) {
  var operation = {
    operation: {
      kind: "update-folder",
      locked: locked
    }
  };
  return Prelude.PromisedResult.map(Impl$1.postOne({
                  namespace: folder.namespace,
                  folder: folder.id
                }, Caml_option.some(token), operation), (function (res) {
                return Curry._2(Prelude.OptionExported.$$Option.map, res, (function (param) {
                              return Folder.fromBackend(param.result);
                            }));
              }));
}

function move(folder, parent, token) {
  var operation = {
    operation: {
      kind: "update-folder",
      parent: Caml_option.some(parent)
    }
  };
  return Prelude.PromisedResult.map(Impl$1.postOne({
                  namespace: folder.namespace,
                  folder: folder.id
                }, Caml_option.some(token), operation), (function (res) {
                return Curry._2(Prelude.OptionExported.$$Option.map, res, (function (param) {
                              return Folder.fromBackend(param.result);
                            }));
              }));
}

var fields$2 = {
  TAG: "Object",
  _0: [[
      "result",
      {
        TAG: "Optional",
        _0: "Any"
      }
    ]]
};

var Deserializer$2 = $$JSON.MakeDeserializer({
      fields: fields$2
    });

var Impl$2 = APIMaker.MakeBackendAPIEndpoint({
      url: "/folder/:namespace/:folder",
      Deserializer: Deserializer$2
    });

function $$do$1(folder, token) {
  return Prelude.PromisedResult.mapError(Prelude.PromisedResult.map(Impl$2.postOne({
                      namespace: folder.namespace,
                      folder: folder.id
                    }, Caml_option.some(token), {
                      operation: {
                        kind: "remove-folder"
                      }
                    }), (function (param) {
                    return folder;
                  })), (function (e) {
                return [
                        folder,
                        e
                      ];
              }));
}

var fields$3 = {
  TAG: "Object",
  _0: [[
      "result",
      {
        TAG: "Optional",
        _0: "Any"
      }
    ]]
};

var Deserializer$3 = $$JSON.MakeDeserializer({
      fields: fields$3
    });

var Impl$3 = APIMaker.MakeBackendAPIEndpoint({
      url: "/folder/:namespace/:folder",
      Deserializer: Deserializer$3
    });

function $$do$2(folder, token) {
  return Prelude.PromisedResult.mapError(Prelude.PromisedResult.map(Impl$3.postOne({
                      namespace: folder.namespace,
                      folder: folder.id
                    }, Caml_option.some(token), {
                      operation: {
                        kind: "clear-folder"
                      }
                    }), (function (param) {
                    return folder;
                  })), (function (e) {
                return [
                        folder,
                        e
                      ];
              }));
}

var fields$4 = {
  TAG: "Object",
  _0: [[
      "result",
      {
        TAG: "Deserializer",
        _0: CatalogAPICommon.DocumentNode.Deserializer
      }
    ]]
};

var Deserializer$4 = $$JSON.MakeDeserializer({
      fields: fields$4
    });

var Impl$4 = APIMaker.MakeBackendAPIEndpoint({
      url: "/doc/:namespace/:folder",
      Deserializer: Deserializer$4
    });

async function $$do$3(namespace, folder, id, name, token, upload) {
  var upload$1 = await PendingUpload.Metadata.extract(upload);
  console.log("CatalogAPIEndpoint", "metadata", upload$1.metadata);
  var form = new FormData();
  form.set("id", DocumentId.toString(id));
  form.set("name", name);
  form.append("file", upload$1.file, name);
  var createdAt = PendingUpload.Metadata.getCreatedAt(upload$1);
  Prelude.OptionExported.$$Option.$$do(createdAt, (function (value) {
          form.append("created_at", value);
        }));
  var updatedAt = PendingUpload.Metadata.getUpdatedAt(upload$1);
  Prelude.OptionExported.$$Option.$$do(updatedAt, (function (value) {
          form.append("updated_at", value);
        }));
  var description = PendingUpload.Metadata.getDescription(upload$1);
  Prelude.OptionExported.$$Option.$$do(description, (function (value) {
          form.append("description", value);
        }));
  var city = PendingUpload.Metadata.getCity(upload$1);
  Prelude.OptionExported.$$Option.$$do(city, (function (value) {
          form.append("city", value);
        }));
  var author = PendingUpload.Metadata.getAuthor(upload$1);
  Prelude.OptionExported.$$Option.$$do(author, (function (value) {
          form.append("author", value);
        }));
  var width = PendingUpload.Metadata.getWidth(upload$1);
  var height = PendingUpload.Metadata.getHeight(upload$1);
  var match;
  var exit = 0;
  if (width !== undefined && width !== 0 && height !== undefined && height !== 0) {
    match = [
      width,
      height
    ];
  } else {
    exit = 1;
  }
  if (exit === 1) {
    var bitmap = await createImageBitmap(upload$1.file);
    console.log("CatalogAPIEndpoint", {
          bitmap: bitmap,
          file: upload$1.file
        });
    var width$1 = bitmap.width;
    var height$1 = bitmap.height;
    bitmap.close();
    match = [
      width$1,
      height$1
    ];
  }
  var height$2 = match[1];
  var width$2 = match[0];
  if (width$2 !== 0 && height$2 !== 0) {
    form.append("width", String(width$2));
    form.append("height", String(height$2));
  }
  var latitude = PendingUpload.Metadata.getLatitude(upload$1);
  Prelude.OptionExported.$$Option.$$do(latitude, (function (value) {
          form.append("latitude", String(value));
        }));
  var longitude = PendingUpload.Metadata.getLongitude(upload$1);
  Prelude.OptionExported.$$Option.$$do(longitude, (function (value) {
          form.append("longitude", String(value));
        }));
  var orientation = PendingUpload.Metadata.getOrientation(upload$1);
  Prelude.OptionExported.$$Option.$$do(orientation, (function (value) {
          form.append("orientation", String(value));
        }));
  var tags = PendingUpload.Metadata.getTags(upload$1);
  Prelude.OptionExported.$$Option.$$do(tags, (function (values) {
          form.append("tags", JSON.stringify(values));
        }));
  var attachmentId = LocalFile.UUID.toString(LocalFile.UUID.make());
  var small_preview = await Thumbnails.createFromFile(upload$1.file, "small");
  Prelude.OptionExported.$$Option.$$do(small_preview, (function (preview) {
          form.append("small_preview", preview, attachmentId + "-small.jpg");
        }));
  var large_preview = await Thumbnails.createFromFile(upload$1.file, "large");
  Prelude.OptionExported.$$Option.$$do(large_preview, (function (preview) {
          form.append("large_preview", preview, attachmentId + "-large.jpg");
        }));
  return await Prelude.PromisedResult.map(Impl$4.postForm({
                  namespace: namespace,
                  folder: folder
                }, Caml_option.some(token), form), (function (param) {
                return $$Document.fromBackend(param.result);
              }));
}

var fields$5 = {
  TAG: "Object",
  _0: [[
      "result",
      {
        TAG: "Optional",
        _0: "Any"
      }
    ]]
};

var Deserializer$5 = $$JSON.MakeDeserializer({
      fields: fields$5
    });

var Impl$5 = APIMaker.MakeBackendAPIEndpoint({
      url: "/folder/:namespace/:folder/:document",
      Deserializer: Deserializer$5
    });

function $$do$4($$document, token) {
  return Prelude.PromisedResult.mapError(Prelude.PromisedResult.map(Impl$5.postOne({
                      namespace: $$document.namespace,
                      folder: $$document.folder,
                      document: $$document.id
                    }, Caml_option.some(token), {
                      operation: {
                        kind: "remove-document"
                      }
                    }), (function (param) {
                    return $$document;
                  })), (function (e) {
                return [
                        $$document,
                        e
                      ];
              }));
}

var fields$6 = {
  TAG: "Object",
  _0: [[
      "result",
      {
        TAG: "Optional",
        _0: {
          TAG: "Deserializer",
          _0: CatalogAPICommon.DocumentNode.Deserializer
        }
      }
    ]]
};

var Deserializer$6 = $$JSON.MakeDeserializer({
      fields: fields$6
    });

var Impl$6 = APIMaker.MakeBackendAPIEndpoint({
      url: "/folder/:namespace/:folder/:document",
      Deserializer: Deserializer$6
    });

function rename$1($$document, name, token) {
  var operation = {
    operation: {
      kind: "update-document",
      name: name
    }
  };
  return Prelude.PromisedResult.map(Impl$6.postOne({
                  namespace: $$document.namespace,
                  folder: $$document.folder,
                  document: $$document.id
                }, Caml_option.some(token), operation), (function (res) {
                return Curry._2(Prelude.OptionExported.$$Option.map, res, (function (param) {
                              return $$Document.merge($$document, $$Document.fromBackend(param.result));
                            }));
              }));
}

function setDescription$1($$document, description, token) {
  var operation = {
    operation: {
      kind: "update-document",
      description: description
    }
  };
  return Prelude.PromisedResult.map(Impl$6.postOne({
                  namespace: $$document.namespace,
                  folder: $$document.folder,
                  document: $$document.id
                }, Caml_option.some(token), operation), (function (res) {
                return Curry._2(Prelude.OptionExported.$$Option.map, res, (function (param) {
                              return $$Document.merge($$document, $$Document.fromBackend(param.result));
                            }));
              }));
}

function move$1($$document, parent, token) {
  var operation = {
    operation: {
      kind: "update-document",
      parent: Caml_option.some(parent)
    }
  };
  return Prelude.PromisedResult.map(Impl$6.postOne({
                  namespace: $$document.namespace,
                  folder: $$document.folder,
                  document: $$document.id
                }, Caml_option.some(token), operation), (function (res) {
                return Curry._2(Prelude.OptionExported.$$Option.map, res, (function (param) {
                              return $$Document.merge($$document, $$Document.fromBackend(param.result));
                            }));
              }));
}

function $$do$5($$document, token) {
  var operation = {
    operation: {
      kind: "update-document",
      name: $$Document.name($$document),
      description: Prelude.default($$document.description, ""),
      attributes: Attribute.PayloadInput.fromValues($$document.attributes),
      tags: $$document.tags,
      collections: $$document.collections
    }
  };
  return Prelude.PromisedResult.map(Impl$6.postOne({
                  namespace: $$document.namespace,
                  folder: $$document.folder,
                  document: $$document.id
                }, Caml_option.some(token), operation), (function (res) {
                return Curry._2(Prelude.OptionExported.$$Option.map, res, (function (param) {
                              return $$Document.merge($$document, $$Document.fromBackend(param.result));
                            }));
              }));
}

var fields$7 = {
  TAG: "Collection",
  _0: CatalogAPICommon.DocumentNode.Deserializer
};

var Deserializer$7 = $$JSON.MakeDeserializer({
      fields: fields$7
    });

var Impl$7 = APIMaker.MakeBackendAPIEndpoint({
      url: "/bulk/edit/:namespace",
      Deserializer: Deserializer$7
    });

function $$do$6(token, namespace, operation) {
  return Prelude.PromisedResult.map(Impl$7.postOne({
                  namespace: namespace
                }, Caml_option.some(token), operation), (function (res) {
                return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, res, (function (docs) {
                                  return docs.map($$Document.fromBackend);
                                })), []);
              }));
}

var CreateFolder = {
  $$do: $$do
};

var RemoveFolder = {
  $$do: $$do$1
};

var ClearFolder = {
  $$do: $$do$2
};

var UpdateFolder = {
  rename: rename,
  setLocked: setLocked,
  setDescription: setDescription,
  move: move
};

var RemoveDocument = {
  $$do: $$do$4
};

var CreateDocument = {
  $$do: $$do$3
};

var UpdateDocument = {
  rename: rename$1,
  setDescription: setDescription$1,
  move: move$1,
  $$do: $$do$5
};

var BulkUpdateDocument = {
  $$do: $$do$6
};

export {
  read ,
  CreateFolder ,
  RemoveFolder ,
  ClearFolder ,
  UpdateFolder ,
  RemoveDocument ,
  CreateDocument ,
  UpdateDocument ,
  BulkUpdateDocument ,
}
/* Impl Not a pure module */
