// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ClipboardManager from "../context/ClipboardManager.js";
import * as JsxRuntime from "react/jsx-runtime";
import IconButton from "@mui/material/IconButton";
import ContentPaste from "@mui/icons-material/ContentPaste";

function ClipboardToggleButton(props) {
  var fontSize = props.fontSize;
  var item = props.item;
  var isClipped = ClipboardManager.Contents.useIsClipped();
  var unclip = ClipboardManager.Contents.useRemove();
  var clipped = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, isClipped, (function (fn) {
              return fn(item);
            })), false);
  var disabled = clipped ? undefined : true;
  var size = Curry._2(Prelude.OptionExported.$$Option.map, fontSize, (function (size) {
          switch (size) {
            case "large" :
                return "large";
            case "medium" :
                return "medium";
            case "small" :
                return "small";
            default:
              return size;
          }
        }));
  return JsxRuntime.jsx(IconButton, {
              children: Caml_option.some(JsxRuntime.jsx(ContentPaste, {
                        color: props.color,
                        className: props.className,
                        fontSize: fontSize,
                        style: {
                          opacity: clipped ? "100" : "0"
                        }
                      })),
              onClick: (function (param) {
                  Prelude.OptionExported.$$Option.$$do(unclip, (function (fn) {
                          fn([item]);
                        }));
                }),
              disabled: disabled,
              size: size,
              sx: props.sx
            });
}

var make = ClipboardToggleButton;

export {
  make ,
}
/* ClipboardManager Not a pure module */
