// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Exifr from "exifr";

var DC = {};

var Photoshop = {};

var IPTC = {};

var IFD0 = {};

var GPS = {};

var Exif = {};

var Xmp = {};

var defaultOptions = {
  tiff: true,
  exif: false,
  ifd0: true,
  ifd1: false,
  gps: true,
  xmp: true,
  iptc: true,
  mergeOutput: false,
  translateValues: false,
  reviveValues: false
};

function parse(input) {
  return Exifr.parse(input._0, defaultOptions);
}

var Parser = {
  DC: DC,
  Photoshop: Photoshop,
  IPTC: IPTC,
  IFD0: IFD0,
  GPS: GPS,
  Exif: Exif,
  Xmp: Xmp,
  defaultOptions: defaultOptions,
  parse: parse
};

export {
  Parser ,
}
/* exifr Not a pure module */
