// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SearchQuery from "../search/models/SearchQuery.js";
import * as TagsManager from "../tags/context/TagsManager.js";
import * as ReactI18Next from "../../../libs/i18n/ReactI18Next.js";
import * as ActionsManager from "../../global/context/ActionsManager.js";
import * as BundlesManager from "../catalog/context/BundlesManager.js";
import * as WithBulkEditor from "../catalog/components/WithBulkEditor.js";
import * as BulkEditContext from "../catalog/context/BulkEditContext.js";
import * as ApplicationMaker from "../../global/apps/ApplicationMaker.js";
import * as NamespaceContext from "../../global/context/NamespaceContext.js";
import * as SearchAffordance from "../search/components/affordances/SearchAffordance.js";
import * as SearchResultArea from "../search/components/SearchResultArea.js";
import * as SelectionManager from "../catalog/context/SelectionManager.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DocumentDownloader from "../catalog/context/services/DocumentDownloader.js";
import * as SearchFiltersPanel from "../search/components/SearchFiltersPanel.js";
import Stack from "@mui/material/Stack";
import * as SelectionAffordance from "../catalog/components/affordances/SelectionAffordance.js";
import * as WithDocumentBrowser from "../catalog/components/WithDocumentBrowser.js";
import * as SearchFiltersContext from "../search/context/SearchFiltersContext.js";
import Tooltip from "@mui/material/Tooltip";
import * as RemoveDocumentService from "../catalog/context/services/RemoveDocumentService.js";
import * as UpdateDocumentService from "../catalog/context/services/UpdateDocumentService.js";
import * as CatalogBrowserManifest from "../catalog/CatalogBrowserManifest.js";
import * as DocumentFinderNavigator from "../catalog/context/DocumentFinderNavigator.js";
import * as UntaggedBrowserManifest from "./UntaggedBrowserManifest.js";
import * as CatalogBrowserViewContext from "../catalog/context/CatalogBrowserViewContext.js";
import ToggleButton from "@mui/material/ToggleButton";
import * as CurrentDocumentListManager from "../catalog/context/CurrentDocumentListManager.js";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import StyleTwoTone from "@mui/icons-material/StyleTwoTone";

function UntaggedBrowserView$UntaggedFilterSelector(props) {
  var value = Prelude.default(SearchFiltersContext.useUntaggedFilter(), "both");
  var setValue = SearchFiltersContext.useSetUntagged();
  return JsxRuntime.jsxs(ToggleButtonGroup, {
              children: [
                JsxRuntime.jsx(ToggleButton, {
                      children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                children: JsxRuntime.jsx(StyleTwoTone, {}),
                                title: JsxRuntime.jsx(ReactI18Next.Message.make, {
                                      msg: {
                                        NAME: "msg",
                                        VAL: [
                                          "untagged.toggle-show-all",
                                          "Show all"
                                        ]
                                      }
                                    })
                              })),
                      value: "both"
                    }),
                JsxRuntime.jsx(ToggleButton, {
                      children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                children: JsxRuntime.jsx(StyleTwoTone, {
                                      color: "error"
                                    }),
                                title: JsxRuntime.jsx(ReactI18Next.Message.make, {
                                      msg: {
                                        NAME: "msg",
                                        VAL: [
                                          "untagged.toggle-without-any-tags",
                                          "Without any tag"
                                        ]
                                      }
                                    })
                              })),
                      value: "without-tags"
                    }),
                JsxRuntime.jsx(ToggleButton, {
                      children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                children: JsxRuntime.jsx(StyleTwoTone, {
                                      color: "warning"
                                    }),
                                title: JsxRuntime.jsx(ReactI18Next.Message.make, {
                                      msg: {
                                        NAME: "msg",
                                        VAL: [
                                          "untagged.toggle-unapproved-only",
                                          "Only unapproved tags"
                                        ]
                                      }
                                    })
                              })),
                      value: "unapproved-tags-only"
                    })
              ],
              exclusive: true,
              onChange: (function (param, v) {
                  setValue(v);
                }),
              value: value
            });
}

function UntaggedBrowserView$UntaggedFilterAffordance(props) {
  return JsxRuntime.jsx(ActionsManager.Register.make, {
              name: "toggle-untagged-filter",
              widget: (function (key) {
                  return JsxRuntime.jsx(UntaggedBrowserView$UntaggedFilterSelector, {}, key);
                }),
              sequence: 3000,
              children: props.children
            });
}

function UntaggedBrowserView$Services(props) {
  var ns = NamespaceContext.useNamespace();
  var newrecord = Caml_obj.obj_dup(SearchQuery.Filters.$$default);
  newrecord.untagged = "both";
  newrecord.root = Caml_option.some(ns.root_folder_id);
  return JsxRuntime.jsx(CatalogBrowserViewContext.make, {
              children: JsxRuntime.jsx(CurrentDocumentListManager.make, {
                    children: JsxRuntime.jsx(DocumentFinderNavigator.make, {
                          path: CatalogBrowserManifest.path,
                          children: JsxRuntime.jsx(BulkEditContext.make, {
                                children: JsxRuntime.jsx(TagsManager.make, {
                                      children: JsxRuntime.jsx(BundlesManager.make, {
                                            children: JsxRuntime.jsx(DocumentDownloader.make, {
                                                  children: JsxRuntime.jsx(UpdateDocumentService.make, {
                                                        children: JsxRuntime.jsx(RemoveDocumentService.make, {
                                                              children: JsxRuntime.jsx(SelectionManager.Documents.make, {
                                                                    children: JsxRuntime.jsx(SearchFiltersContext.make, {
                                                                          children: props.children,
                                                                          fixtures: newrecord
                                                                        })
                                                                  })
                                                            })
                                                      })
                                                })
                                          })
                                    })
                              })
                        })
                  })
            });
}

var Services = {
  make: UntaggedBrowserView$Services
};

function UntaggedBrowserView$Affordances(props) {
  return JsxRuntime.jsx(SelectionAffordance.make, {
              children: JsxRuntime.jsx(UntaggedBrowserView$UntaggedFilterAffordance, {
                    children: JsxRuntime.jsx(SearchAffordance.make, {
                          children: props.children,
                          filters: "folders"
                        })
                  })
            });
}

var Affordances = {
  make: UntaggedBrowserView$Affordances
};

function UntaggedBrowserView$Main(props) {
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Stack, {
                      children: [
                        JsxRuntime.jsx(SearchFiltersPanel.make, {}),
                        JsxRuntime.jsx(SearchResultArea.make, {
                              allowEmptyFilters: true
                            })
                      ],
                      direction: ["column"],
                      spacing: 2.0
                    }),
                JsxRuntime.jsx(WithBulkEditor.make, {}),
                JsxRuntime.jsx(WithDocumentBrowser.make, {})
              ]
            });
}

var Main = {
  make: UntaggedBrowserView$Main
};

var include = ApplicationMaker.MakeStandard({
      Manifest: UntaggedBrowserManifest,
      Services: Services,
      Affordances: Affordances,
      Main: Main
    });

var Manifest = include.Manifest;

var make = include.make;

export {
  Manifest ,
  make ,
}
/* include Not a pure module */
