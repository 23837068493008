// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Collection from "../../models/Collection.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../../../libs/i18n/ReactI18Next.js";
import * as ActionsManager from "../../../../global/context/ActionsManager.js";
import * as FlexGrowSpacer from "../../../../global/components/FlexGrowSpacer.js";
import * as NamespaceContext from "../../../../global/context/NamespaceContext.js";
import * as JsxRuntime from "react/jsx-runtime";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import * as CollectionsListService from "../../context/CollectionsListService.js";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import * as CollectionsFiltersContext from "../../context/CollectionsFiltersContext.js";

function CollectionsCountAffordance$CollectionsChip(props) {
  var count = props.count;
  var label = count > 0 ? new (Intl.NumberFormat)().format(count) : JsxRuntime.jsx(ReactI18Next.Message.make, {
          msg: {
            NAME: "msg",
            VAL: [
              "collections-counter-chip.no-collections",
              "No collections"
            ]
          }
        });
  return React.useMemo((function () {
                return JsxRuntime.jsx(Chip, {
                            label: Caml_option.some(label)
                          });
              }), [label]);
}

var CollectionsChip = {
  make: CollectionsCountAffordance$CollectionsChip
};

function CollectionsCountAffordance$ClearFilters(props) {
  var setFilters = props.setFilters;
  var filters = props.filters;
  var hidden = filters.contains === undefined && filters.startswith === undefined;
  var ns = NamespaceContext.useNamespace();
  return React.useMemo((function () {
                if (hidden) {
                  return null;
                } else {
                  return JsxRuntime.jsx(Tooltip, {
                              children: JsxRuntime.jsx(IconButton, {
                                    children: Caml_option.some(JsxRuntime.jsx(Close, {})),
                                    onClick: (function (param) {
                                        setFilters(Collection.Filters.make(ns.id));
                                      })
                                  }),
                              title: JsxRuntime.jsx(ReactI18Next.Message.make, {
                                    msg: {
                                      NAME: "str",
                                      VAL: "Clear filters"
                                    }
                                  })
                            });
                }
              }), [hidden]);
}

var ClearFilters = {
  make: CollectionsCountAffordance$ClearFilters
};

function CollectionsCountAffordance(props) {
  var count = CollectionsListService.useCount();
  var filters = CollectionsFiltersContext.useFilters();
  var setFilters = CollectionsFiltersContext.useSetFilters();
  return JsxRuntime.jsx(ActionsManager.Register.make, {
              name: "unapproved-tags",
              widget: (function (key) {
                  return JsxRuntime.jsxs(React.Fragment, {
                              children: [
                                JsxRuntime.jsx(FlexGrowSpacer.make, {}),
                                JsxRuntime.jsx(CollectionsCountAffordance$ClearFilters, {
                                      filters: filters,
                                      setFilters: setFilters
                                    }),
                                JsxRuntime.jsx(CollectionsCountAffordance$CollectionsChip, {
                                      count: count
                                    })
                              ]
                            }, key);
                }),
              sequence: 5000,
              children: props.children
            });
}

var make = CollectionsCountAffordance;

export {
  CollectionsChip ,
  ClearFilters ,
  make ,
}
/* react Not a pure module */
