// Generated by ReScript, PLEASE EDIT WITH CARE

import * as AccountsBrowser from "./components/AccountsBrowser.js";
import * as AccountsManager from "./context/AccountsManager.js";
import * as ApplicationMaker from "../../global/apps/ApplicationMaker.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as AccountsListService from "./context/AccountsListService.js";
import * as CreateAccountService from "./context/CreateAccountService.js";
import * as RemoveAccountService from "./context/RemoveAccountService.js";
import * as UpdateAccountService from "./context/UpdateAccountService.js";
import * as AccountsFiltersContext from "./context/AccountsFiltersContext.js";
import * as AccountsCountAffordance from "./components/affordances/AccountsCountAffordance.js";
import * as AccountsManagerManifest from "./AccountsManagerManifest.js";
import * as CreateAccountAffordance from "./components/affordances/CreateAccountAffordance.js";
import * as ToggleActivationService from "./context/ToggleActivationService.js";
import * as AccountsSelectionManager from "./context/AccountsSelectionManager.js";
import * as AccountsSelectionAffordance from "./components/affordances/AccountsSelectionAffordance.js";

function AccountsManagerView$Services(props) {
  return JsxRuntime.jsx(AccountsManager.make, {
              children: JsxRuntime.jsx(AccountsSelectionManager.make, {
                    children: JsxRuntime.jsx(AccountsFiltersContext.make, {
                          children: JsxRuntime.jsx(AccountsListService.make, {
                                children: JsxRuntime.jsx(CreateAccountService.make, {
                                      children: JsxRuntime.jsx(UpdateAccountService.make, {
                                            children: JsxRuntime.jsx(RemoveAccountService.make, {
                                                  children: JsxRuntime.jsx(ToggleActivationService.make, {
                                                        children: props.children
                                                      })
                                                })
                                          })
                                    })
                              })
                        })
                  })
            });
}

var Services = {
  make: AccountsManagerView$Services
};

function AccountsManagerView$Affordances(props) {
  return JsxRuntime.jsx(AccountsCountAffordance.make, {
              children: JsxRuntime.jsx(AccountsSelectionAffordance.make, {
                    children: JsxRuntime.jsx(CreateAccountAffordance.make, {
                          children: props.children
                        })
                  })
            });
}

var Affordances = {
  make: AccountsManagerView$Affordances
};

function AccountsManagerView$Main(props) {
  var accounts = AccountsListService.useAccounts();
  var filters = AccountsFiltersContext.useFilters();
  var pages = AccountsListService.usePagesCount();
  return JsxRuntime.jsx(AccountsBrowser.make, {
              accounts: accounts,
              filters: filters,
              pages: pages
            });
}

var Main = {
  make: AccountsManagerView$Main
};

var include = ApplicationMaker.MakeStandard({
      Manifest: AccountsManagerManifest,
      Services: Services,
      Affordances: Affordances,
      Main: Main
    });

var Manifest = include.Manifest;

var make = include.make;

export {
  Manifest ,
  make ,
}
/* include Not a pure module */
