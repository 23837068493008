// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as UserContext from "../../../global/context/UserContext.js";
import * as TagAPIEndpoint from "../api/TagAPIEndpoint.js";
import * as JsxRuntime from "react/jsx-runtime";

var context = React.createContext(undefined);

var make = context.Provider;

function useCreateTag() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.create;
              }));
}

function useUpdateTag() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.update;
              }));
}

function useRemoveTag() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.remove;
              }));
}

function useBulkRemoveTags() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.bulkRemove;
              }));
}

function useMergeTags() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.merge;
              }));
}

function useSearchTags() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.search;
              }));
}

function TagsManager(props) {
  var token = UserContext.useAccessToken();
  var value = React.useMemo((function () {
          return Curry._2(Prelude.OptionExported.$$Option.map, token, (function (token) {
                        var search = function (params, page, pageSize) {
                          return TagAPIEndpoint.Search.$$fetch(token, params, page, pageSize, undefined);
                        };
                        var create = function (name) {
                          return TagAPIEndpoint.CreateTag.$$do(name, undefined, token);
                        };
                        var update = function (tag) {
                          return TagAPIEndpoint.UpdateTag.$$do(tag, token);
                        };
                        var remove = function (tag) {
                          return TagAPIEndpoint.RemoveTag.$$do(tag, token);
                        };
                        var bulkRemove = function (tags) {
                          return TagAPIEndpoint.BulkRemoveTags.$$do(tags, token);
                        };
                        var merge = function (tag, branches) {
                          return TagAPIEndpoint.MergeTags.$$do(tag, branches, token);
                        };
                        return {
                                search: search,
                                create: create,
                                remove: remove,
                                bulkRemove: bulkRemove,
                                update: update,
                                merge: merge
                              };
                      }));
        }), [token]);
  return JsxRuntime.jsx(make, {
              value: value,
              children: props.children
            });
}

var make$1 = TagsManager;

export {
  useCreateTag ,
  useRemoveTag ,
  useBulkRemoveTags ,
  useMergeTags ,
  useUpdateTag ,
  useSearchTags ,
  make$1 as make,
}
/* context Not a pure module */
