// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../../../libs/i18n/ReactI18Next.js";
import * as ActionsManager from "../../../../global/context/ActionsManager.js";
import * as FeedbackDelayed from "../../../../global/components/FeedbackDelayed.js";
import Fab from "@mui/material/Fab";
import * as JsxRuntime from "react/jsx-runtime";
import * as EmptyFolderService from "../../context/services/EmptyFolderService.js";
import * as ConfirmDeleteDialog from "../../../../global/components/ConfirmDeleteDialog.js";
import Dialog from "@mui/material/Dialog";
import Tooltip from "@mui/material/Tooltip";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import * as CatalogDeleteVariantContext from "../../context/CatalogDeleteVariantContext.js";
import LinearProgress from "@mui/material/LinearProgress";
import DeleteForever from "@mui/icons-material/DeleteForever";

function EmptyFolderAffordance(props) {
  var folder = props.folder;
  var title = Curry._2(Prelude.OptionExported.$$Option.getWithDefault, props.title, JsxRuntime.jsx(ReactI18Next.Message.make, {
            msg: {
              NAME: "str",
              VAL: "Empty"
            }
          }));
  var match = React.useState(function () {
        return "Idle";
      });
  var setState = match[1];
  var state = match[0];
  var empty = EmptyFolderService.useClearFolder();
  React.useEffect((function () {
          if (typeof state === "object" && state.TAG !== "Confirmation") {
            if (empty !== undefined) {
              Prelude.thenDo(empty(state._0), (function (param) {
                      setState(function (param) {
                            return "Idle";
                          });
                    }));
            }
            
          }
          
        }), [
        state,
        empty
      ]);
  var variant = CatalogDeleteVariantContext.use();
  var disabled = React.useMemo((function () {
          if (variant !== "RemoveForever") {
            return true;
          } else {
            return Curry._1(Prelude.OptionExported.$$Option.isNone, empty);
          }
        }), [
        variant,
        empty
      ]);
  if (disabled) {
    return null;
  }
  var onClick = function (param) {
    setState(function (param) {
          return {
                  TAG: "Confirmation",
                  _0: folder
                };
        });
  };
  var tmp;
  tmp = typeof state !== "object" ? null : (
      state.TAG === "Confirmation" ? JsxRuntime.jsx(ConfirmDeleteDialog.make, {
              open_: true,
              title: props.confirmTitle,
              content: props.confirmMessage,
              confirmLabel: {
                NAME: "msg",
                VAL: [
                  "buttons.clear-folder",
                  "Clear"
                ]
              },
              onCancel: (function () {
                  setState(function (param) {
                        return "Idle";
                      });
                }),
              onDelete: (function () {
                  setState(function (param) {
                        return {
                                TAG: "Removing",
                                _0: folder
                              };
                      });
                })
            }) : JsxRuntime.jsx(FeedbackDelayed.make, {
              children: JsxRuntime.jsxs(Dialog, {
                    open: true,
                    children: [
                      JsxRuntime.jsx(DialogTitle, {
                            children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                      msg: {
                                        NAME: "str",
                                        VAL: "Clearing folder"
                                      }
                                    }))
                          }),
                      JsxRuntime.jsx(DialogContent, {
                            children: Caml_option.some(JsxRuntime.jsx(LinearProgress, {}))
                          })
                    ],
                    fullWidth: true,
                    maxWidth: "sm"
                  }),
              fallback: Caml_option.some(null),
              delay: 300
            })
    );
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(ActionsManager.Register.make, {
                      name: "empty-trash",
                      widget: (function (key) {
                          return JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(Tooltip, {
                                            children: JsxRuntime.jsx(Fab, {
                                                  children: Caml_option.some(JsxRuntime.jsx(DeleteForever, {})),
                                                  color: "error",
                                                  disabled: disabled,
                                                  size: "small"
                                                }, key),
                                            title: title
                                          }),
                                      onClick: onClick
                                    });
                        }),
                      sequence: 0,
                      children: props.children
                    }),
                tmp
              ]
            });
}

var make = EmptyFolderAffordance;

export {
  make ,
}
/* react Not a pure module */
