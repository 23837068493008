// Generated by ReScript, PLEASE EDIT WITH CARE


var _map = {"queued":"queued","running":"running","done":"done","failed":"failed","cancelled":"cancelled"};

function tToJs(param) {
  return param;
}

function tFromJs(param) {
  return _map[param];
}

export {
  tToJs ,
  tFromJs ,
}
/* No side effect */
