// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UserContext from "../../../../global/context/UserContext.js";
import * as DropdownMenu from "../../../../global/components/DropdownMenu.js";
import * as UserPermission from "../../../../global/models/UserPermission.js";
import * as ClipboardManager from "../../context/ClipboardManager.js";
import * as DeleteItemDialog from "./DeleteItemDialog.js";
import * as ReactRouterDom from "react-router-dom";
import * as JsxRuntime from "react/jsx-runtime";
import * as DocumentDownloader from "../../context/services/DocumentDownloader.js";
import * as DocumentFinderNavigator from "../../context/DocumentFinderNavigator.js";
import * as CurrentDocumentListManager from "../../context/CurrentDocumentListManager.js";
import * as CatalogDeleteVariantContext from "../../context/CatalogDeleteVariantContext.js";
import MoreVert from "@mui/icons-material/MoreVert";
import ContentCut from "@mui/icons-material/ContentCut";
import EditRounded from "@mui/icons-material/EditRounded";
import DeleteForever from "@mui/icons-material/DeleteForever";
import PhotoOutlined from "@mui/icons-material/PhotoOutlined";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import FolderOutlined from "@mui/icons-material/FolderOutlined";
import CloudDownloadOutlined from "@mui/icons-material/CloudDownloadOutlined";

function DocumentDropdown(props) {
  var $$document = props.document;
  var user = UserContext.useUserClaim();
  var permissions = UserContext.useUserPermissionsClaim();
  var manager = React.useMemo((function () {
          var query = {
            TAG: "Any",
            _0: ["catalog.change_document"]
          };
          return UserPermission.Query.matches(query, permissions);
        }), [
        user,
        permissions
      ]);
  var deleteVariant = CatalogDeleteVariantContext.use();
  var match = React.useState(function () {
        
      });
  var setDeleteDocument = match[1];
  var deleteDocument = match[0];
  var getLink = DocumentDownloader.useGetDocumentDownloadLink();
  var items;
  if (getLink !== undefined) {
    var link = getLink($$document);
    items = link !== undefined ? [{
          TAG: "Item",
          _0: {
            title: {
              NAME: "str",
              VAL: "Download"
            },
            icon: Caml_option.some(JsxRuntime.jsx(CloudDownloadOutlined, {})),
            action: {
              TAG: "ExternalLink",
              _0: link
            }
          }
        }] : [];
  } else {
    items = [];
  }
  var finder = DocumentFinderNavigator.useLinkBuilder();
  var items$1;
  if (finder !== undefined) {
    var href = finder($$document);
    var onClick = ReactRouterDom.useLinkClickHandler(href);
    items$1 = Prelude.$$Array.append(items, {
          TAG: "Item",
          _0: {
            title: {
              NAME: "str",
              VAL: "Open folder"
            },
            icon: Caml_option.some(JsxRuntime.jsx(FolderOutlined, {})),
            action: {
              TAG: "Click",
              _0: onClick
            }
          }
        });
  } else {
    items$1 = items;
  }
  var setActiveDocument = CurrentDocumentListManager.useSetActiveDocument();
  var hasClipboard = ClipboardManager.Contents.isActive();
  var putInClipboard = ClipboardManager.Contents.usePut();
  var putInClipboard$1 = React.useMemo((function () {
          return Prelude.default(putInClipboard, (function (param) {
                        
                      }));
        }), [putInClipboard]);
  var items$2;
  if (manager) {
    var items$3 = Prelude.$$Array.isEmpty(items$1) ? items$1 : Curry._2(Prelude.$$Array.concat, items$1, ["Separator"]);
    var items$4 = Curry._2(Prelude.$$Array.concat, items$3, [
          {
            TAG: "Item",
            _0: {
              title: {
                NAME: "str",
                VAL: "Edit"
              },
              icon: Caml_option.some(JsxRuntime.jsx(EditRounded, {})),
              action: {
                TAG: "Click",
                _0: (function (param) {
                    setActiveDocument($$document);
                  })
              }
            }
          },
          {
            TAG: "Item",
            _0: {
              title: {
                NAME: "str",
                VAL: "Cut"
              },
              icon: Caml_option.some(JsxRuntime.jsx(ContentCut, {})),
              action: {
                TAG: "Click",
                _0: (function (param) {
                    putInClipboard$1([{
                            NAME: "document",
                            VAL: $$document
                          }]);
                  })
              },
              disabled: !hasClipboard
            }
          },
          "Separator"
        ]);
    switch (deleteVariant) {
      case "MoveToTrash" :
          items$2 = Prelude.$$Array.append(items$4, {
                TAG: "Item",
                _0: {
                  title: {
                    NAME: "str",
                    VAL: "Trash"
                  },
                  icon: Caml_option.some(JsxRuntime.jsx(DeleteOutlined, {})),
                  action: {
                    TAG: "Click",
                    _0: (function (param) {
                        setDeleteDocument(function (param) {
                              return $$document;
                            });
                      })
                  }
                }
              });
          break;
      case "RemoveForever" :
          items$2 = Prelude.$$Array.append(items$4, {
                TAG: "Item",
                _0: {
                  title: {
                    NAME: "str",
                    VAL: "Remove"
                  },
                  icon: Caml_option.some(JsxRuntime.jsx(DeleteForever, {})),
                  action: {
                    TAG: "Click",
                    _0: (function (param) {
                        setDeleteDocument(function (param) {
                              return $$document;
                            });
                      })
                  }
                }
              });
          break;
      case "Disallowed" :
          items$2 = items$4;
          break;
      
    }
  } else {
    items$2 = Prelude.$$Array.append(items$1, {
          TAG: "Item",
          _0: {
            title: {
              NAME: "str",
              VAL: "View"
            },
            icon: Caml_option.some(JsxRuntime.jsx(PhotoOutlined, {})),
            action: {
              TAG: "Click",
              _0: (function (param) {
                  setActiveDocument($$document);
                })
            }
          }
        });
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(DropdownMenu.make, {
                      trigger: {
                        TAG: "Icon",
                        _0: JsxRuntime.jsx(MoreVert, {})
                      },
                      items: items$2,
                      disabled: props.disabled
                    }),
                deleteDocument !== undefined ? JsxRuntime.jsx(DeleteItemDialog.make, {
                        item: {
                          NAME: "document",
                          VAL: deleteDocument
                        },
                        variant: deleteVariant,
                        onDone: (function () {
                            setDeleteDocument(function (param) {
                                  
                                });
                          }),
                        onClose: (function () {
                            setDeleteDocument(function (param) {
                                  
                                });
                          })
                      }) : null
              ]
            });
}

var make = DocumentDropdown;

export {
  make ,
}
/* react Not a pure module */
