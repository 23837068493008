// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as APIMaker from "../../../utils/APIMaker.js";
import * as BundlerId from "../models/BundlerId.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as BundlerStatus from "../models/BundlerStatus.js";

var fields = {
  TAG: "Object",
  _0: [
    [
      "id",
      "String"
    ],
    [
      "name",
      "String"
    ],
    [
      "status",
      $$JSON.Field.variadicString("status", BundlerStatus.tFromJs)
    ],
    [
      "failure_code",
      {
        TAG: "Optional",
        _0: "String"
      }
    ],
    [
      "url",
      {
        TAG: "Optional",
        _0: "String"
      }
    ],
    [
      "created_at",
      "Datetime"
    ],
    [
      "updated_at",
      "Datetime"
    ]
  ]
};

var Deserializer = $$JSON.MakeDeserializer({
      fields: fields
    });

var ReadEndpoint = APIMaker.MakeBackendReadonlyAPIEndpoint({
      url: "bundle/:id",
      Deserializer: Deserializer
    });

var fields$1 = {
  TAG: "Object",
  _0: [
    [
      "id",
      "String"
    ],
    [
      "name",
      "String"
    ],
    [
      "status",
      $$JSON.Field.variadicString("status", BundlerStatus.tFromJs)
    ],
    [
      "failure_code",
      {
        TAG: "Optional",
        _0: "String"
      }
    ],
    [
      "url",
      {
        TAG: "Optional",
        _0: "String"
      }
    ],
    [
      "created_at",
      "Datetime"
    ],
    [
      "updated_at",
      "Datetime"
    ]
  ]
};

var Deserializer$1 = $$JSON.MakeDeserializer({
      fields: fields$1
    });

var WriteEndpoint = APIMaker.MakeBackendAPIEndpoint({
      url: "bundle/",
      Deserializer: Deserializer$1
    });

function poll(id, token) {
  return ReadEndpoint.get(undefined, Caml_option.some(token), BundlerId.toString(id));
}

function request(folders, documents, token) {
  var post = {
    folders: folders,
    documents: documents
  };
  return WriteEndpoint.postOne(undefined, Caml_option.some(token), post);
}

var fields$2 = {
  TAG: "Object",
  _0: [
    [
      "id",
      "String"
    ],
    [
      "name",
      "String"
    ],
    [
      "status",
      $$JSON.Field.variadicString("status", BundlerStatus.tFromJs)
    ],
    [
      "failure_code",
      {
        TAG: "Optional",
        _0: "String"
      }
    ],
    [
      "url",
      {
        TAG: "Optional",
        _0: "String"
      }
    ],
    [
      "created_at",
      "Datetime"
    ],
    [
      "updated_at",
      "Datetime"
    ]
  ]
};

var Deserializer$2 = $$JSON.MakeDeserializer({
      fields: fields$2
    });

var Endpoint = APIMaker.MakeBackendAPIEndpoint({
      url: "bundle/:id/cancel",
      Deserializer: Deserializer$2
    });

function cancel(id, token) {
  return Endpoint.postOne({
              id: id
            }, Caml_option.some(token), undefined);
}

export {
  poll ,
  request ,
  cancel ,
}
/* ReadEndpoint Not a pure module */
