// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as DocumentBrowser from "./document/DocumentBrowser.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CurrentDocumentListManager from "../context/CurrentDocumentListManager.js";

function WithDocumentBrowser(props) {
  var activeDocument = CurrentDocumentListManager.useActiveDocument();
  var setActiveDocument = CurrentDocumentListManager.useSetActiveDocument();
  var documents = CurrentDocumentListManager.useDocuments();
  var count = CurrentDocumentListManager.useCount();
  var pageSize = CurrentDocumentListManager.usePageSize();
  var page = CurrentDocumentListManager.usePage();
  var setPage = CurrentDocumentListManager.useSetPage();
  var match = React.useState(function () {
        return {
                document: activeDocument,
                documents: documents,
                count: count,
                page: page,
                pageSize: pageSize
              };
      });
  var setState = match[1];
  var state = match[0];
  React.useEffect((function () {
          if (Caml_obj.notequal(state.document, activeDocument)) {
            setState(function (param) {
                  return {
                          document: activeDocument,
                          documents: documents,
                          count: count,
                          page: page,
                          pageSize: pageSize
                        };
                });
          }
          
        }), [
        state,
        activeDocument,
        page,
        pageSize,
        count,
        documents
      ]);
  var getFirstPosInPage = function (page) {
    return Math.imul(page - 1 | 0, pageSize);
  };
  var getLastPosInPage = function (page) {
    return Math.imul(page, pageSize) - 1 | 0;
  };
  var pks = documents.map(function (d) {
        return d.id;
      });
  var posInPage = Curry._2(Prelude.OptionExported.$$Option.flatMap, activeDocument, (function (d) {
          var pos = pks.indexOf(d.id);
          if (pos > -1) {
            return pos;
          }
          
        }));
  var posInResults = Curry._2(Prelude.OptionExported.$$Option.map, posInPage, (function (pos) {
          return getFirstPosInPage(page) + pos | 0;
        }));
  var firstPosInPage = getFirstPosInPage(page);
  var lastPosInPage = getLastPosInPage(page);
  var navigateToNext = Curry._2(Prelude.OptionExported.$$Option.flatMap, posInResults, (function (pos) {
          if (pos >= (count - 1 | 0)) {
            return ;
          }
          if (pos === lastPosInPage) {
            return (function () {
                      setPage(page + 1 | 0);
                    });
          }
          var posInPage$1 = Curry._1(Prelude.OptionExported.$$Option.getExn, posInPage);
          return (function () {
                    Prelude.OptionExported.$$Option.$$do(Curry._2(Prelude.$$Array.get, documents, posInPage$1 + 1 | 0), (function (d) {
                            setActiveDocument(d);
                          }));
                  });
        }));
  var navigateToPrev = Curry._2(Prelude.OptionExported.$$Option.flatMap, posInResults, (function (pos) {
          if (pos <= 0) {
            return ;
          }
          if (pos === firstPosInPage) {
            return (function () {
                      setPage(page - 1 | 0);
                    });
          }
          var posInPage$1 = Curry._1(Prelude.OptionExported.$$Option.getExn, posInPage);
          return (function () {
                    Prelude.OptionExported.$$Option.$$do(Curry._2(Prelude.$$Array.get, documents, posInPage$1 - 1 | 0), (function (d) {
                            setActiveDocument(d);
                          }));
                  });
        }));
  var $$document = state.document;
  if ($$document !== undefined) {
    return JsxRuntime.jsx(DocumentBrowser.make, {
                document: $$document,
                navigateToNext: navigateToNext,
                navigateToPrev: navigateToPrev
              });
  } else {
    return null;
  }
}

var make = WithDocumentBrowser;

export {
  make ,
}
/* react Not a pure module */
