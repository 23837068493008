// Generated by ReScript, PLEASE EDIT WITH CARE


function get(attr) {
  if (attr === "name") {
    return {
            NAME: "msg",
            VAL: [
              "document.title-label",
              "Title"
            ]
          };
  } else if (attr === "dimensions" || attr === "size") {
    return {
            NAME: "msg",
            VAL: [
              "document.size-label",
              "Dimensions"
            ]
          };
  } else if (attr === "tags") {
    return {
            NAME: "msg",
            VAL: [
              "document.tags-label",
              "Tags"
            ]
          };
  } else if (attr === "text") {
    return {
            NAME: "msg",
            VAL: [
              "document.text-label",
              "Text"
            ]
          };
  } else if (attr === "copyright") {
    return {
            NAME: "msg",
            VAL: [
              "document.copyright-label",
              "Copyright"
            ]
          };
  } else if (attr === "description") {
    return {
            NAME: "msg",
            VAL: [
              "document.description-label",
              "Description"
            ]
          };
  } else if (attr === "collections") {
    return {
            NAME: "msg",
            VAL: [
              "document.collections-label",
              "Collections"
            ]
          };
  } else if (attr === "created_at") {
    return {
            NAME: "msg",
            VAL: [
              "document.created-at-label",
              "Created"
            ]
          };
  } else if (attr === "author") {
    return {
            NAME: "msg",
            VAL: [
              "document.author-label",
              "Author"
            ]
          };
  } else if (attr === "author_job_title") {
    return {
            NAME: "msg",
            VAL: [
              "document.author-job-title-label",
              "Author's Job Title"
            ]
          };
  } else if (attr === "updated_at") {
    return {
            NAME: "msg",
            VAL: [
              "document.updated-at-label",
              "Updated"
            ]
          };
  } else if (attr === "country") {
    return {
            NAME: "msg",
            VAL: [
              "document.country-label",
              "Country"
            ]
          };
  } else if (attr === "usage_terms") {
    return {
            NAME: "msg",
            VAL: [
              "document.usage-terms-label",
              "Terms and Conditions"
            ]
          };
  } else if (attr === "sources") {
    return {
            NAME: "msg",
            VAL: [
              "document.sources-label",
              "Sources"
            ]
          };
  } else {
    return {
            NAME: "msg",
            VAL: [
              "document.city-label",
              "City"
            ]
          };
  }
}

export {
  get ,
}
/* No side effect */
