// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as SearchWidget from "./SearchWidget.js";
import * as ReactI18next from "react-i18next";
import * as ActionsManager from "../../../../global/context/ActionsManager.js";
import * as FlexGrowSpacer from "../../../../global/components/FlexGrowSpacer.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as FoldersAutocomplete from "../FoldersAutocomplete.js";
import * as SearchFiltersContext from "../../context/SearchFiltersContext.js";

function SearchAffordance(props) {
  var __filters = props.filters;
  var filters = __filters !== undefined ? __filters : "nothing";
  var folders = SearchFiltersContext.useFolderFilters();
  var setFolders = SearchFiltersContext.useSetFolder();
  var match = ReactI18next.useTranslation();
  var t = match.t;
  var widget = React.useMemo((function () {
          if (filters === "nothing") {
            return null;
          } else if (filters === "folders") {
            return JsxRuntime.jsx(FoldersAutocomplete.make, {
                        folders: folders,
                        onChange: setFolders,
                        variant: "standard",
                        sx: {
                          width: {
                            xs: "80%",
                            md: "60%",
                            lg: "40%"
                          }
                        },
                        limitTags: 2,
                        placeholder: t("Folders")
                      });
          } else {
            return JsxRuntime.jsx(SearchWidget.make, {});
          }
        }), [
        filters,
        folders,
        setFolders
      ]);
  return JsxRuntime.jsx(ActionsManager.Register.make, {
              name: "search-widget",
              widget: (function (key) {
                  return JsxRuntime.jsxs(React.Fragment, {
                              children: [
                                JsxRuntime.jsx(FlexGrowSpacer.make, {}),
                                widget
                              ]
                            }, key);
                }),
              sequence: 2000,
              children: props.children
            });
}

var make = SearchAffordance;

export {
  make ,
}
/* react Not a pure module */
