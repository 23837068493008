// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../../../libs/i18n/ReactI18Next.js";
import * as AskNameDialog from "../../../../global/components/AskNameDialog.js";
import * as ProtectedArea from "../../../../utils/ProtectedArea.js";
import * as ActionsManager from "../../../../global/context/ActionsManager.js";
import * as TagsListService from "../../context/TagsListService.js";
import * as CreateTagService from "../../context/CreateTagService.js";
import Fab from "@mui/material/Fab";
import * as JsxRuntime from "react/jsx-runtime";
import Add from "@mui/icons-material/Add";
import * as DocumentAttributeLabels from "../../../catalog/components/document/DocumentAttributeLabels.js";

function CreateTagAffordance$CreateTagDialog(props) {
  var onDone = props.onDone;
  var onClose = props.onClose;
  var create = props.create;
  var match = React.useState(function () {
        
      });
  var setState = match[1];
  var state = match[0];
  var onChange = function (name) {
    setState(function (param) {
          return name;
        });
  };
  React.useEffect((function () {
          if (state !== undefined && create !== undefined) {
            var tags = Curry._2(Prelude.$$Array.keep, state.split("\n").map(function (s) {
                      return s.trim();
                    }), (function (tag) {
                    return tag !== "";
                  }));
            Prelude.thenDo(Promise.all(tags.map(function (tag) {
                          return create(tag);
                        })), (function (param) {
                    onDone();
                  }));
          }
          
        }), [
        state,
        create
      ]);
  return JsxRuntime.jsx(AskNameDialog.make, {
              name: "",
              onChange: onChange,
              onClose: (function () {
                  onClose();
                  setState(function (param) {
                        
                      });
                }),
              open_: true,
              title: {
                NAME: "msg",
                VAL: [
                  "create-tag-affordance.dialog-title",
                  "Create tags"
                ]
              },
              content: JsxRuntime.jsx(ReactI18Next.Message.make, {
                    msg: {
                      NAME: "msg",
                      VAL: [
                        "create-tag-affordance.dialog-message",
                        "Enter the names of the new tags.  You can enter several tags,\n           each on a single line."
                      ]
                    }
                  }),
              fieldLabel: DocumentAttributeLabels.get("tags"),
              buttonLabel: {
                NAME: "str",
                VAL: "Create"
              },
              multiline: true
            });
}

function CreateTagAffordance$CreateTagButton(props) {
  var create = props.create;
  var disabled = Curry._1(Prelude.OptionExported.$$Option.isNone, create);
  var match = React.useState(function () {
        return false;
      });
  var setActive = match[1];
  var refresh = TagsListService.useRefresh();
  var setCurrentPage = TagsListService.useSetCurrentPage();
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Fab, {
                            children: Caml_option.some(JsxRuntime.jsx(Add, {})),
                            color: "primary",
                            disabled: disabled,
                            size: "small"
                          }),
                      onClick: (function (param) {
                          setActive(function (param) {
                                return true;
                              });
                        })
                    }),
                JsxRuntime.jsx(ProtectedArea.make, {
                      requirement: {
                        TAG: "Pred",
                        _0: match[0]
                      },
                      children: JsxRuntime.jsx(CreateTagAffordance$CreateTagDialog, {
                            create: create,
                            onClose: (function () {
                                setActive(function (param) {
                                      return false;
                                    });
                              }),
                            onDone: (function () {
                                refresh();
                                setCurrentPage(1);
                                setActive(function (param) {
                                      return false;
                                    });
                              })
                          })
                    })
              ]
            });
}

function CreateTagAffordance(props) {
  var create = CreateTagService.useCreateTag();
  return JsxRuntime.jsx(ActionsManager.Register.make, {
              name: "create-tags",
              widget: (function (key) {
                  return JsxRuntime.jsx(CreateTagAffordance$CreateTagButton, {
                              create: create
                            }, key);
                }),
              sequence: 10,
              children: props.children
            });
}

var make = CreateTagAffordance;

export {
  make ,
}
/* react Not a pure module */
