// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Collection from "./models/Collection.js";
import * as ApplicationMaker from "../../global/apps/ApplicationMaker.js";
import * as ReactRouterDom from "react-router-dom";
import * as JsxRuntime from "react/jsx-runtime";
import * as CollectionsBrowser from "./components/CollectionsBrowser.js";
import * as CollectionsManager from "./context/CollectionsManager.js";
import * as CollectionsListService from "./context/CollectionsListService.js";
import * as CreateCollectionService from "./context/CreateCollectionService.js";
import * as RemoveCollectionService from "./context/RemoveCollectionService.js";
import * as UpdateCollectionService from "./context/UpdateCollectionService.js";
import * as CollectionsFiltersContext from "./context/CollectionsFiltersContext.js";
import * as CollectionsCountAffordance from "./components/affordances/CollectionsCountAffordance.js";
import * as CollectionsManagerManifest from "./CollectionsManagerManifest.js";
import * as CreateCollectionAffordance from "./components/affordances/CreateCollectionAffordance.js";
import * as CollectionsSelectionManager from "./context/CollectionsSelectionManager.js";
import * as CollectionsSelectionAffordance from "./components/affordances/CollectionsSelectionAffordance.js";

function CollectionsManagerView$Services(props) {
  return JsxRuntime.jsx(CollectionsManager.make, {
              children: JsxRuntime.jsx(CollectionsSelectionManager.make, {
                    children: JsxRuntime.jsx(CollectionsFiltersContext.make, {
                          children: JsxRuntime.jsx(CollectionsListService.make, {
                                children: JsxRuntime.jsx(CreateCollectionService.make, {
                                      children: JsxRuntime.jsx(UpdateCollectionService.make, {
                                            children: JsxRuntime.jsx(RemoveCollectionService.make, {
                                                  children: props.children
                                                })
                                          })
                                    })
                              })
                        })
                  })
            });
}

var Services = {
  make: CollectionsManagerView$Services
};

function CollectionsManagerView$Affordances(props) {
  return JsxRuntime.jsx(CreateCollectionAffordance.make, {
              children: JsxRuntime.jsx(CollectionsCountAffordance.make, {
                    children: JsxRuntime.jsx(CollectionsSelectionAffordance.make, {
                          children: props.children
                        })
                  })
            });
}

var Affordances = {
  make: CollectionsManagerView$Affordances
};

function CollectionsManagerView$Main(props) {
  var collections = CollectionsListService.useCollections();
  var filters = CollectionsFiltersContext.useFilters();
  var pages = CollectionsListService.usePagesCount();
  var navigate = ReactRouterDom.useNavigate();
  var setCurrentBrowsingCollection = function (collection) {
    navigate("/collections/explore/" + Collection.toURLPayload(collection));
  };
  return JsxRuntime.jsx(CollectionsBrowser.make, {
              collections: collections,
              filters: filters,
              pages: pages,
              setCurrentBrowsingCollection: setCurrentBrowsingCollection
            });
}

var Main = {
  make: CollectionsManagerView$Main
};

var include = ApplicationMaker.MakeStandard({
      Manifest: CollectionsManagerManifest,
      Services: Services,
      Affordances: Affordances,
      Main: Main
    });

var Manifest = include.Manifest;

var Services$1 = include.Services;

var Affordances$1 = include.Affordances;

var Main$1 = include.Main;

var make = include.make;

export {
  Manifest ,
  Services$1 as Services,
  Affordances$1 as Affordances,
  Main$1 as Main,
  make ,
}
/* include Not a pure module */
