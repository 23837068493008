// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as FolderId from "../../models/FolderId.js";
import * as BulkRemove from "./BulkRemove.js";
import * as DocumentId from "../../models/DocumentId.js";
import * as FolderItem from "../../models/FolderItem.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UserContext from "../../../../global/context/UserContext.js";
import * as ReactI18Next from "../../../../../libs/i18n/ReactI18Next.js";
import * as ProtectedArea from "../../../../utils/ProtectedArea.js";
import * as ReactDOMStyle from "@rescript/react/lib/es6/src/ReactDOMStyle.js";
import * as ReactI18next from "react-i18next";
import * as ActionsManager from "../../../../global/context/ActionsManager.js";
import * as BundlesManager from "../../context/BundlesManager.js";
import * as UserPermission from "../../../../global/models/UserPermission.js";
import * as BulkEditContext from "../../context/BulkEditContext.js";
import * as ClipboardManager from "../../context/ClipboardManager.js";
import * as SelectionManager from "../../context/SelectionManager.js";
import Box from "@mui/material/Box";
import * as JsxRuntime from "react/jsx-runtime";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ContentCut from "@mui/icons-material/ContentCut";
import EditRounded from "@mui/icons-material/EditRounded";
import CancelRounded from "@mui/icons-material/CancelRounded";
import CloudDownloadOutlined from "@mui/icons-material/CloudDownloadOutlined";

function SelectionAffordance$ClearSelection(props) {
  var resetDocumentsSelection = SelectionManager.Documents.useReset();
  var resetFoldersSelection = SelectionManager.Folders.useReset();
  var reset = React.useCallback((function () {
          Prelude.OptionExported.$$Option.$$do(resetDocumentsSelection, (function (fn) {
                  fn();
                }));
          Prelude.OptionExported.$$Option.$$do(resetFoldersSelection, (function (fn) {
                  fn();
                }));
        }), [
        resetDocumentsSelection,
        resetFoldersSelection
      ]);
  return JsxRuntime.jsx(Tooltip, {
              children: JsxRuntime.jsx(IconButton, {
                    children: Caml_option.some(JsxRuntime.jsx(CancelRounded, {})),
                    onClick: (function (param) {
                        reset();
                      })
                  }),
              title: JsxRuntime.jsx(ReactI18next.Trans, {
                    i18nKey: "clearSelection",
                    children: "Clear"
                  })
            });
}

function SelectionAffordance$SelectionInfo(props) {
  var getCurrentDocumentSelectionIds = Prelude.default(SelectionManager.Documents.useGetCurrentSelectionIds(), (function () {
          return DocumentId.$$Set.make();
        }));
  var getCurrentFolderSelectionIds = Prelude.default(SelectionManager.Folders.useGetCurrentSelectionIds(), (function () {
          return FolderId.$$Set.make();
        }));
  var count = React.useMemo((function () {
          return DocumentId.$$Set.size(getCurrentDocumentSelectionIds()) + FolderId.$$Set.size(getCurrentFolderSelectionIds()) | 0;
        }), [
        getCurrentDocumentSelectionIds,
        getCurrentFolderSelectionIds
      ]);
  if (count < 100) {
    return JsxRuntime.jsx(Box, {
                style: {
                  whiteSpace: "nowrap"
                },
                children: Caml_option.some(JsxRuntime.jsx(ReactI18next.Trans, {
                          i18nKey: "countSelected",
                          children: "{{count}} selected",
                          count: count
                        })),
                component: "span",
                sx: {
                  display: {
                    xs: "none",
                    md: "inline"
                  }
                }
              });
  } else {
    return JsxRuntime.jsx(Box, {
                style: {
                  whiteSpace: "nowrap"
                },
                children: Caml_option.some(JsxRuntime.jsx(ReactI18next.Trans, {
                          children: "99+ selected"
                        })),
                component: "span",
                sx: {
                  display: {
                    xs: "none",
                    lg: "inline"
                  }
                }
              });
  }
}

var canChangeDocuments = UserPermission.Query.compile({
      TAG: "All",
      _0: ["catalog.change_document"]
    });

var canChangeFolders = UserPermission.Query.compile({
      TAG: "All",
      _0: ["catalog.change_folder"]
    });

function SelectionAffordance$BulkMove(props) {
  var perms = UserContext.useUserPermissionsClaim();
  var clearClipboard = ClipboardManager.Contents.useClear();
  var putInClipboard = ClipboardManager.Contents.usePut();
  var getCurrentFolderSelection = SelectionManager.Folders.useGetCurrentSelection();
  var getCurrentFolderSelection$1 = React.useMemo((function () {
          return Prelude.default(getCurrentFolderSelection, (function () {
                        return FolderId.$$Map.make();
                      }));
        }), [getCurrentFolderSelection]);
  var getCurrentDocumentSelection = SelectionManager.Documents.useGetCurrentSelection();
  var getCurrentDocumentSelection$1 = React.useMemo((function () {
          return Prelude.default(getCurrentDocumentSelection, (function () {
                        return DocumentId.$$Map.make();
                      }));
        }), [getCurrentDocumentSelection]);
  var resetFolderSelection = SelectionManager.Folders.useReset();
  var resetFolderSelection$1 = React.useMemo((function () {
          return Prelude.default(resetFolderSelection, (function () {
                        
                      }));
        }), [resetFolderSelection]);
  var resetDocumentSelection = SelectionManager.Documents.useReset();
  var resetDocumentSelection$1 = React.useMemo((function () {
          return Prelude.default(resetDocumentSelection, (function () {
                        
                      }));
        }), [resetDocumentSelection]);
  var disabled = !ClipboardManager.Contents.isActive() || !canChangeFolders(perms) && !FolderId.$$Map.isEmpty(getCurrentFolderSelection$1()) || !canChangeDocuments(perms) && !DocumentId.$$Map.isEmpty(getCurrentDocumentSelection$1());
  var onClick = React.useCallback((function (param) {
          Prelude.OptionExported.$$Option.$$do(clearClipboard, (function (fn) {
                  fn();
                }));
          var items = FolderId.$$Map.valuesToArray(getCurrentFolderSelection$1()).map(FolderItem.fromFolder);
          var items$1 = Curry._2(Prelude.$$Array.concat, items, DocumentId.$$Map.valuesToArray(getCurrentDocumentSelection$1()).map(FolderItem.fromDocument));
          Prelude.OptionExported.$$Option.$$do(putInClipboard, (function (fn) {
                  fn(items$1);
                }));
          resetFolderSelection$1();
          resetDocumentSelection$1();
        }), [
        getCurrentDocumentSelection$1,
        getCurrentFolderSelection$1,
        resetDocumentSelection$1,
        resetFolderSelection$1,
        putInClipboard,
        clearClipboard
      ]);
  if (disabled) {
    return null;
  } else {
    return JsxRuntime.jsx(Tooltip, {
                children: JsxRuntime.jsx(IconButton, {
                      children: Caml_option.some(JsxRuntime.jsx(ContentCut, {})),
                      onClick: onClick
                    }),
                title: JsxRuntime.jsx(ReactI18Next.Message.make, {
                      msg: {
                        NAME: "str",
                        VAL: "Cut"
                      }
                    })
              });
  }
}

function SelectionAffordance$BulkDownload(props) {
  var getCurrentDocumentSelectionIds = Prelude.default(SelectionManager.Documents.useGetCurrentSelectionIds(), (function () {
          return DocumentId.$$Set.make();
        }));
  var getCurrentFolderSelectionIds = Prelude.default(SelectionManager.Folders.useGetCurrentSelectionIds(), (function () {
          return FolderId.$$Set.make();
        }));
  var request = BundlesManager.useRequest();
  var disabled = React.useMemo((function () {
          return !Curry._1(Prelude.OptionExported.$$Option.isSome, request);
        }), [request]);
  var createRequest = React.useCallback((function () {
          if (request === undefined) {
            return ;
          }
          var folders = FolderId.$$Set.toArray(getCurrentFolderSelectionIds());
          var documents = DocumentId.$$Set.toArray(getCurrentDocumentSelectionIds());
          request(folders, documents);
        }), [
        request,
        getCurrentDocumentSelectionIds,
        getCurrentFolderSelectionIds
      ]);
  return React.useMemo((function () {
                return JsxRuntime.jsx(ProtectedArea.make, {
                            requirement: {
                              TAG: "Pred",
                              _0: !disabled
                            },
                            children: JsxRuntime.jsx(IconButton, {
                                  children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                            children: JsxRuntime.jsx(CloudDownloadOutlined, {}),
                                            title: JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                  msg: {
                                                    NAME: "str",
                                                    VAL: "Download"
                                                  }
                                                })
                                          })),
                                  onClick: (function (param) {
                                      createRequest();
                                    })
                                })
                          });
              }), [
              createRequest,
              disabled
            ]);
}

function SelectionAffordance$BulkEdit(props) {
  var getCurrentDocumentSelection = Prelude.default(SelectionManager.Documents.useGetCurrentSelection(), (function () {
          return DocumentId.$$Map.make();
        }));
  var getCurrentFolderSelectionIds = Prelude.default(SelectionManager.Folders.useGetCurrentSelectionIds(), (function () {
          return FolderId.$$Set.make();
        }));
  var documents = React.useMemo((function () {
          return DocumentId.$$Map.valuesToArray(getCurrentDocumentSelection());
        }), [getCurrentDocumentSelection]);
  var docCount = React.useMemo((function () {
          return documents.length;
        }), [documents]);
  var folderCount = React.useMemo((function () {
          return FolderId.$$Set.size(getCurrentFolderSelectionIds());
        }), [getCurrentFolderSelectionIds]);
  var handleOnEdit = BulkEditContext.useSelect();
  var disabled = React.useMemo((function () {
          if (Curry._1(Prelude.OptionExported.$$Option.isNone, handleOnEdit) || docCount === 0) {
            return true;
          } else {
            return folderCount > 0;
          }
        }), [
        docCount,
        folderCount,
        handleOnEdit
      ]);
  var button = JsxRuntime.jsx(IconButton, {
        children: Caml_option.some(JsxRuntime.jsx(EditRounded, {})),
        onClick: (function (param) {
            Prelude.OptionExported.$$Option.$$do(handleOnEdit, (function (select) {
                    select(documents);
                  }));
          }),
        disabled: disabled
      });
  if (disabled) {
    return button;
  } else {
    return JsxRuntime.jsx(Tooltip, {
                children: button,
                title: JsxRuntime.jsx(ReactI18Next.Message.make, {
                      msg: {
                        NAME: "str",
                        VAL: "Edit"
                      }
                    })
              });
  }
}

function SelectionAffordance$SelectionMenu(props) {
  var __delete = props.delete;
  var $$delete = __delete !== undefined ? __delete : "trash";
  var getCurrentDocumentSelectionIds = SelectionManager.Documents.useGetCurrentSelectionIds();
  var getCurrentFolderSelectionIds = SelectionManager.Folders.useGetCurrentSelectionIds();
  var docCount = React.useMemo((function () {
          return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, getCurrentDocumentSelectionIds, (function (ids) {
                            return DocumentId.$$Set.size(ids());
                          })), 0);
        }), [getCurrentDocumentSelectionIds]);
  var folderCount = React.useMemo((function () {
          return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, getCurrentFolderSelectionIds, (function (ids) {
                            return FolderId.$$Set.size(ids());
                          })), 0);
        }), [getCurrentFolderSelectionIds]);
  var count = React.useMemo((function () {
          return docCount + folderCount | 0;
        }), [
        docCount,
        folderCount
      ]);
  if (count <= 0) {
    return null;
  }
  var style = ReactDOMStyle.unsafeAddProp({
        cursor: "default",
        userSelect: "none"
      }, "WebkitUserSelect", "none");
  return JsxRuntime.jsxs(Stack, {
              style: style,
              bgcolor: "action.selected",
              children: [
                JsxRuntime.jsx(SelectionAffordance$ClearSelection, {}),
                JsxRuntime.jsx(SelectionAffordance$SelectionInfo, {}),
                JsxRuntime.jsx(SelectionAffordance$BulkDownload, {}),
                JsxRuntime.jsx(ProtectedArea.make, {
                      requirement: {
                        TAG: "Perms",
                        _0: {
                          TAG: "All",
                          _0: ["catalog.change_document"]
                        }
                      },
                      children: JsxRuntime.jsx(SelectionAffordance$BulkEdit, {})
                    }),
                JsxRuntime.jsx(SelectionAffordance$BulkMove, {}),
                JsxRuntime.jsx(BulkRemove.make, {
                      delete: $$delete
                    })
              ],
              direction: ["row"],
              spacing: "0.5rem",
              sx: {
                borderRadius: "9999px",
                alignItems: "center"
              }
            });
}

function SelectionAffordance(props) {
  var __delete = props.delete;
  var $$delete = __delete !== undefined ? __delete : "trash";
  return JsxRuntime.jsx(ActionsManager.Register.make, {
              name: "selection-menu",
              widget: (function (key) {
                  return JsxRuntime.jsx(SelectionAffordance$SelectionMenu, {
                              delete: $$delete
                            }, key);
                }),
              sequence: 500,
              children: props.children
            });
}

var make = SelectionAffordance;

export {
  make ,
}
/* canChangeDocuments Not a pure module */
