// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as APIMaker from "../../utils/APIMaker.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var fields = {
  TAG: "Object",
  _0: []
};

var Deserializer = $$JSON.MakeDeserializer({
      fields: fields
    });

var Impl = APIMaker.MakeBackendAPIEndpoint({
      url: "user/change_password",
      Deserializer: Deserializer
    });

function $$do(username, password, new_password, token) {
  return Prelude.PromisedResult.map(Impl.postOne(undefined, Caml_option.some(token), {
                  username: username,
                  password: password,
                  new_password: new_password
                }), (function (param) {
                
              }));
}

var ChangePassword = {
  $$do: $$do
};

export {
  ChangePassword ,
}
/* Impl Not a pure module */
