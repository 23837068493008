// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../../../libs/i18n/ReactI18Next.js";
import * as AskNameDialog from "../../../../global/components/AskNameDialog.js";
import * as ProtectedArea from "../../../../utils/ProtectedArea.js";
import * as ActionsManager from "../../../../global/context/ActionsManager.js";
import Fab from "@mui/material/Fab";
import * as JsxRuntime from "react/jsx-runtime";
import * as CollectionsListService from "../../context/CollectionsListService.js";
import Add from "@mui/icons-material/Add";
import * as CreateCollectionService from "../../context/CreateCollectionService.js";
import * as DocumentAttributeLabels from "../../../catalog/components/document/DocumentAttributeLabels.js";

function CreateCollectionAffordance$CreateCollectionDialog(props) {
  var onDone = props.onDone;
  var onClose = props.onClose;
  var create = props.create;
  var match = React.useState(function () {
        
      });
  var setState = match[1];
  var state = match[0];
  var onChange = function (name) {
    setState(function (param) {
          return name;
        });
  };
  React.useEffect((function () {
          if (state !== undefined && create !== undefined) {
            var collections = Curry._2(Prelude.$$Array.keep, state.split("\n").map(function (s) {
                      return s.trim();
                    }), (function (collection) {
                    return collection !== "";
                  }));
            Prelude.thenDo(Promise.all(collections.map(function (collection) {
                          return create(collection);
                        })), (function (param) {
                    onDone();
                  }));
          }
          
        }), [
        state,
        create
      ]);
  return JsxRuntime.jsx(AskNameDialog.make, {
              name: "",
              onChange: onChange,
              onClose: (function () {
                  onClose();
                  setState(function (param) {
                        
                      });
                }),
              open_: true,
              title: {
                NAME: "msg",
                VAL: [
                  "create-collection-affordance.dialog-title",
                  "Create collections"
                ]
              },
              content: JsxRuntime.jsx(ReactI18Next.Message.make, {
                    msg: {
                      NAME: "msg",
                      VAL: [
                        "create-collection-affordance.dialog-message",
                        "Enter the names of the new collections.  You can enter several collections,\n           each on a single line."
                      ]
                    }
                  }),
              fieldLabel: DocumentAttributeLabels.get("collections"),
              buttonLabel: {
                NAME: "str",
                VAL: "Create"
              },
              multiline: true
            });
}

var CreateCollectionDialog = {
  make: CreateCollectionAffordance$CreateCollectionDialog
};

function CreateCollectionAffordance$CreateCollectionButton(props) {
  var create = CreateCollectionService.useCreateCollection();
  var disabled = Curry._1(Prelude.OptionExported.$$Option.isNone, create);
  var match = React.useState(function () {
        return false;
      });
  var setActive = match[1];
  var setCurrentPage = CollectionsListService.useSetCurrentPage();
  var refresh = CollectionsListService.useRefresh();
  return JsxRuntime.jsxs(ProtectedArea.make, {
              requirement: {
                TAG: "Pred",
                _0: !disabled
              },
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Fab, {
                            children: Caml_option.some(JsxRuntime.jsx(Add, {})),
                            color: "primary",
                            disabled: disabled,
                            size: "small"
                          }),
                      onClick: (function (param) {
                          if (!disabled) {
                            return setActive(function (param) {
                                        return true;
                                      });
                          }
                          
                        })
                    }),
                JsxRuntime.jsx(ProtectedArea.make, {
                      requirement: {
                        TAG: "Pred",
                        _0: match[0]
                      },
                      children: JsxRuntime.jsx(CreateCollectionAffordance$CreateCollectionDialog, {
                            create: create,
                            onClose: (function () {
                                setActive(function (param) {
                                      return false;
                                    });
                              }),
                            onDone: (function () {
                                refresh();
                                setCurrentPage(1);
                                setActive(function (param) {
                                      return false;
                                    });
                              })
                          })
                    })
              ]
            });
}

var CreateCollectionButton = {
  make: CreateCollectionAffordance$CreateCollectionButton
};

function CreateCollectionAffordance(props) {
  var children = props.children;
  var create = CreateCollectionService.useCreateCollection();
  return React.useMemo((function () {
                return JsxRuntime.jsx(ActionsManager.Register.make, {
                            name: "create-collection",
                            widget: (function (key) {
                                return JsxRuntime.jsx(CreateCollectionAffordance$CreateCollectionButton, {}, key);
                              }),
                            sequence: 10,
                            children: children
                          });
              }), [create]);
}

var make = CreateCollectionAffordance;

export {
  CreateCollectionDialog ,
  CreateCollectionButton ,
  make ,
}
/* react Not a pure module */
