// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";

function fromInt(value) {
  switch (value) {
    case 1 :
        return 1;
    case 2 :
        return 2;
    case 3 :
        return 3;
    case 4 :
        return 4;
    case 5 :
        return 5;
    case 6 :
        return 6;
    case 7 :
        return 7;
    case 8 :
        return 8;
    default:
      return ;
  }
}

function fromString(value) {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, Curry._1(Prelude.Int.fromString, value), fromInt);
}

function getCSSTransform(orientation, style) {
  var style$1 = Prelude.default(style, {});
  switch (orientation) {
    case 1 :
        return style$1;
    case 2 :
        var newrecord = Caml_obj.obj_dup(style$1);
        newrecord.transform = "scaleX(-1)";
        return newrecord;
    case 3 :
        var newrecord$1 = Caml_obj.obj_dup(style$1);
        newrecord$1.transform = "rotate(180deg)";
        return newrecord$1;
    case 4 :
        var newrecord$2 = Caml_obj.obj_dup(style$1);
        newrecord$2.transform = "scaleY(-1)";
        return newrecord$2;
    case 5 :
        var newrecord$3 = Caml_obj.obj_dup(style$1);
        newrecord$3.transform = "rotate(270deg) scaleX(-1)";
        return newrecord$3;
    case 6 :
        var newrecord$4 = Caml_obj.obj_dup(style$1);
        newrecord$4.transform = "rotate(90deg) ";
        return newrecord$4;
    case 7 :
        var newrecord$5 = Caml_obj.obj_dup(style$1);
        newrecord$5.transform = "rotate(90deg) scaleX(-1)";
        return newrecord$5;
    case 8 :
        var newrecord$6 = Caml_obj.obj_dup(style$1);
        newrecord$6.transform = "rotate(270deg)";
        return newrecord$6;
    
  }
}

function flipsWidthHeight(orientation) {
  switch (orientation) {
    case 5 :
    case 6 :
    case 7 :
    case 8 :
        return true;
    default:
      return false;
  }
}

var Ifd0 = {
  fromInt: fromInt,
  fromString: fromString,
  getCSSTransform: getCSSTransform,
  flipsWidthHeight: flipsWidthHeight
};

function transform(orientation, transformation) {
  switch (transformation) {
    case "RotateCW" :
        switch (orientation) {
          case 1 :
              return 6;
          case 2 :
              return 7;
          case 3 :
              return 8;
          case 4 :
              return 5;
          case 5 :
              return 2;
          case 6 :
              return 3;
          case 7 :
              return 4;
          case 8 :
              return 1;
          
        }
    case "RotateCCW" :
        switch (orientation) {
          case 1 :
              return 8;
          case 2 :
              return 5;
          case 3 :
              return 6;
          case 4 :
              return 7;
          case 5 :
              return 4;
          case 6 :
              return 1;
          case 7 :
              return 2;
          case 8 :
              return 3;
          
        }
    case "MirrorHorizontally" :
        switch (orientation) {
          case 1 :
              return 2;
          case 2 :
              return 1;
          case 3 :
              return 4;
          case 4 :
              return 3;
          case 5 :
              return 6;
          case 6 :
              return 5;
          case 7 :
              return 8;
          case 8 :
              return 7;
          
        }
    
  }
}

function negate(transformation) {
  switch (transformation) {
    case "RotateCW" :
        return "RotateCCW";
    case "RotateCCW" :
        return "RotateCW";
    case "MirrorHorizontally" :
        return "MirrorHorizontally";
    
  }
}

function encode(transformations) {
  return transformations.reduce(transform, 1);
}

function decode(orientation) {
  switch (orientation) {
    case 1 :
        return [];
    case 2 :
        return ["MirrorHorizontally"];
    case 3 :
        return [
                "RotateCW",
                "RotateCW"
              ];
    case 4 :
        return [
                "RotateCW",
                "RotateCW",
                "MirrorHorizontally"
              ];
    case 5 :
        return [
                "MirrorHorizontally",
                "RotateCW",
                "RotateCW",
                "RotateCW"
              ];
    case 6 :
        return ["RotateCW"];
    case 7 :
        return [
                "MirrorHorizontally",
                "RotateCW"
              ];
    case 8 :
        return [
                "RotateCW",
                "RotateCW",
                "RotateCW"
              ];
    
  }
}

var Transformations = {
  transform: transform,
  negate: negate,
  encode: encode,
  decode: decode
};

export {
  Ifd0 ,
  Transformations ,
}
/* No side effect */
