// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as AboutManifest from "./about/AboutManifest.js";
import * as UserPermission from "../global/models/UserPermission.js";
import * as TagsManagerManifest from "./tags/TagsManagerManifest.js";
import * as TrashBrowserManifest from "./catalog/TrashBrowserManifest.js";
import * as UserSettingsManifest from "./usersettings/UserSettingsManifest.js";
import * as SearchCatalogManifest from "./search/SearchCatalogManifest.js";
import * as CatalogBrowserManifest from "./catalog/CatalogBrowserManifest.js";
import * as AccountsManagerManifest from "./accounts/AccountsManagerManifest.js";
import * as UntaggedBrowserManifest from "./untagged/UntaggedBrowserManifest.js";
import * as CollectionsManagerManifest from "./collections/CollectionsManagerManifest.js";

var app = [
  CatalogBrowserManifest,
  CollectionsManagerManifest,
  TrashBrowserManifest,
  UntaggedBrowserManifest,
  SearchCatalogManifest
];

var config = [
  TagsManagerManifest,
  AccountsManagerManifest,
  AboutManifest
];

var ondemand = [UserSettingsManifest];

var other = [];

function getUserApplications(permissions, kinds) {
  return Prelude.$$Array.flatMap(kinds, (function (extra) {
                var allModules = extra === "any" ? Curry._2(Prelude.$$Array.concat, Curry._2(Prelude.$$Array.concat, Curry._2(Prelude.$$Array.concat, app, config), ondemand), other) : (
                    extra === "app" ? Curry._2(Prelude.$$Array.concat, app, []) : (
                        extra === "sidebar" ? Curry._2(Prelude.$$Array.concat, app, config) : (
                            extra === "ondemand" ? Curry._2(Prelude.$$Array.concat, ondemand, []) : (
                                extra === "other" ? Curry._2(Prelude.$$Array.concat, other, []) : Curry._2(Prelude.$$Array.concat, config, [])
                              )
                          )
                      )
                  );
                return Curry._2(Prelude.$$Array.keep, allModules, (function (mod) {
                              return UserPermission.Query.matches(mod.permissions, permissions);
                            }));
              }));
}

export {
  getUserApplications ,
}
/* AboutManifest Not a pure module */
