// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as $$Document from "../../models/Document.js";
import * as DocumentId from "../../models/DocumentId.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Orientation from "../../models/Orientation.js";
import * as ReactI18Next from "../../../../../libs/i18n/ReactI18Next.js";
import * as ProtectedArea from "../../../../utils/ProtectedArea.js";
import Box from "@mui/material/Box";
import * as JsxRuntime from "react/jsx-runtime";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import * as DocumentBrowserImage from "./DocumentBrowserImage.js";
import Toolbar from "@mui/material/Toolbar";
import * as UpdateDocumentService from "../../context/services/UpdateDocumentService.js";
import Snackbar from "@mui/material/Snackbar";
import * as DocumentBrowserActions from "./DocumentBrowserActions.js";
import IconButton from "@mui/material/IconButton";
import * as DocumentBrowserFormPanel from "./DocumentBrowserFormPanel.js";
import Close from "@mui/icons-material/Close";
import * as DocumentBrowserSaveDialog from "./DocumentBrowserSaveDialog.js";
import * as CurrentDocumentListManager from "../../context/CurrentDocumentListManager.js";
import DialogContent from "@mui/material/DialogContent";
import * as DocumentBrowserReadonlyPanel from "./DocumentBrowserReadonlyPanel.js";
import NavigateNext from "@mui/icons-material/NavigateNext";
import NavigateBefore from "@mui/icons-material/NavigateBefore";

function DocumentBrowser(props) {
  var navigateToPrev = props.navigateToPrev;
  var navigateToNext = props.navigateToNext;
  var $$document = props.document;
  var match = React.useState(function () {
        
      });
  var setError = match[1];
  var match$1 = React.useState(function () {
        return $$document;
      });
  var setDoc = match$1[1];
  var $$document$1 = match$1[0];
  React.useEffect((function () {
          if (Caml_obj.notequal($$document$1.id, $$document.id)) {
            setDoc(function (param) {
                  return $$document;
                });
          }
          
        }), [$$document]);
  var match$2 = React.useState(function () {
        
      });
  var setDocumentInSaveDialog = match$2[1];
  var documentInSaveDialog = match$2[0];
  var clearActiveDocument = CurrentDocumentListManager.useClearActiveDocument();
  var match$3 = React.useState(function () {
        return "description";
      });
  var setCurrentTab = match$3[1];
  var currentTab = match$3[0];
  var clearActiveDocument$1 = function () {
    clearActiveDocument();
    setCurrentTab(function (param) {
          return "description";
        });
  };
  var onDeleteDocument = function () {
    Prelude.OptionExported.$$Option.$$do(navigateToNext, (function (fn) {
            fn();
          }));
  };
  var updateDocument = UpdateDocumentService.useUpdateDocument();
  var onRotateLeft = Curry._2(Prelude.OptionExported.$$Option.map, updateDocument, (function (param) {
          return function () {
            setDoc(function (d) {
                  return $$Document.Orientation.set(d, Orientation.Transformations.transform(Prelude.default($$Document.Orientation.get(d), 1), "RotateCCW"));
                });
          };
        }));
  var onRotateRight = Curry._2(Prelude.OptionExported.$$Option.map, updateDocument, (function (param) {
          return function () {
            setDoc(function (d) {
                  return $$Document.Orientation.set(d, Orientation.Transformations.transform(Prelude.default($$Document.Orientation.get(d), 1), "RotateCW"));
                });
          };
        }));
  var onFlip = Curry._2(Prelude.OptionExported.$$Option.map, updateDocument, (function (param) {
          return function () {
            setDoc(function (d) {
                  return $$Document.Orientation.set(d, Orientation.Transformations.transform(Prelude.default($$Document.Orientation.get(d), 1), "MirrorHorizontally"));
                });
          };
        }));
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Dialog, {
                      open: true,
                      children: [
                        JsxRuntime.jsx(Paper, {
                              children: Caml_option.some(JsxRuntime.jsx(Toolbar, {
                                        children: Caml_option.some(JsxRuntime.jsxs(Box, {
                                                  children: [
                                                    JsxRuntime.jsxs("div", {
                                                          children: [
                                                            JsxRuntime.jsx(IconButton, {
                                                                  children: Caml_option.some(JsxRuntime.jsx(Close, {})),
                                                                  onClick: (function (param) {
                                                                      clearActiveDocument$1();
                                                                      var $$location = window.location;
                                                                      $$location.hash = DocumentId.toString($$document$1.id);
                                                                    })
                                                                }),
                                                            JsxRuntime.jsx("span", {
                                                                  children: $$document$1.name,
                                                                  style: {
                                                                    whiteSpace: "nowrap"
                                                                  }
                                                                })
                                                          ]
                                                        }),
                                                    JsxRuntime.jsx(DocumentBrowserActions.make, {
                                                          document: $$document$1,
                                                          onDeleteDocument: onDeleteDocument,
                                                          onRotateLeft: onRotateLeft,
                                                          onRotateRight: onRotateRight,
                                                          onFlip: onFlip
                                                        })
                                                  ],
                                                  sx: {
                                                    display: "flex",
                                                    flexDirection: "Row",
                                                    flexWrap: {
                                                      xs: "wrap",
                                                      sm: "wrap"
                                                    },
                                                    justifyContent: "space-between",
                                                    width: "100vw"
                                                  }
                                                }))
                                      }))
                            }),
                        JsxRuntime.jsx(DialogContent, {
                              children: Caml_option.some(JsxRuntime.jsxs(Box, {
                                        children: [
                                          JsxRuntime.jsxs(Grid, {
                                                children: [
                                                  JsxRuntime.jsx(Grid, {
                                                        children: Caml_option.some(JsxRuntime.jsx(IconButton, {
                                                                  children: Caml_option.some(JsxRuntime.jsx(NavigateBefore, {
                                                                            fontSize: "large"
                                                                          })),
                                                                  onClick: (function (param) {
                                                                      Prelude.OptionExported.$$Option.$$do(navigateToPrev, (function (fn) {
                                                                              fn();
                                                                            }));
                                                                    }),
                                                                  disabled: Curry._1(Prelude.OptionExported.$$Option.isNone, navigateToPrev)
                                                                })),
                                                        item: true
                                                      }),
                                                  JsxRuntime.jsx(Grid, {
                                                        children: Caml_option.some(JsxRuntime.jsx(DocumentBrowserImage.make, {
                                                                  document: $$document$1
                                                                }, DocumentId.toString($$document$1.id))),
                                                        item: true
                                                      }),
                                                  JsxRuntime.jsx(Grid, {
                                                        children: Caml_option.some(JsxRuntime.jsx(IconButton, {
                                                                  children: Caml_option.some(JsxRuntime.jsx(NavigateNext, {
                                                                            fontSize: "large"
                                                                          })),
                                                                  onClick: (function (param) {
                                                                      Prelude.OptionExported.$$Option.$$do(navigateToNext, (function (fn) {
                                                                              fn();
                                                                            }));
                                                                    }),
                                                                  disabled: Curry._1(Prelude.OptionExported.$$Option.isNone, navigateToNext)
                                                                })),
                                                        item: true
                                                      })
                                                ],
                                                container: true,
                                                spacing: 1,
                                                sx: {
                                                  alignItems: "center",
                                                  flexWrap: "nowrap",
                                                  justifyContent: "space-between",
                                                  height: "100%",
                                                  maxHeight: {
                                                    xs: "50vh",
                                                    lg: "100vh"
                                                  },
                                                  maxWidth: {
                                                    xs: "100vw",
                                                    lg: "65vw"
                                                  },
                                                  width: "100%"
                                                }
                                              }),
                                          JsxRuntime.jsx(Box, {
                                                children: Caml_option.some(JsxRuntime.jsx(ProtectedArea.Conditional.make, {
                                                          requirement: {
                                                            TAG: "Pred",
                                                            _0: Curry._1(Prelude.OptionExported.$$Option.isSome, updateDocument)
                                                          },
                                                          children: (function (canEdit) {
                                                              if (canEdit) {
                                                                return JsxRuntime.jsx(DocumentBrowserFormPanel.make, {
                                                                            document: $$document$1,
                                                                            currentTab: currentTab,
                                                                            setCurrentTab: (function (tab) {
                                                                                setCurrentTab(function (param) {
                                                                                      return tab;
                                                                                    });
                                                                              }),
                                                                            onChangeDocument: (function (d) {
                                                                                setDoc(function (param) {
                                                                                      return d;
                                                                                    });
                                                                              }),
                                                                            onReset: (function () {
                                                                                setDoc(function (param) {
                                                                                      return $$document;
                                                                                    });
                                                                              }),
                                                                            onSave: (function () {
                                                                                setDocumentInSaveDialog(function (param) {
                                                                                      return $$document$1;
                                                                                    });
                                                                              }),
                                                                            isModified: Caml_obj.notequal($$document, $$document$1)
                                                                          });
                                                              } else {
                                                                return JsxRuntime.jsx(DocumentBrowserReadonlyPanel.make, {
                                                                            document: $$document$1,
                                                                            currentTab: currentTab,
                                                                            setCurrentTab: (function (tab) {
                                                                                setCurrentTab(function (param) {
                                                                                      return tab;
                                                                                    });
                                                                              })
                                                                          });
                                                              }
                                                            })
                                                        })),
                                                sx: {
                                                  maxWidth: {
                                                    xs: "100vw",
                                                    lg: "35vw"
                                                  },
                                                  width: "100%",
                                                  paddingRight: "1.75rem"
                                                }
                                              })
                                        ],
                                        sx: {
                                          display: "flex",
                                          flexWrap: {
                                            xs: "wrap",
                                            lg: "nowrap"
                                          },
                                          height: "100%"
                                        }
                                      })),
                              sx: {
                                padding: "0"
                              }
                            })
                      ],
                      fullScreen: true
                    }),
                documentInSaveDialog !== undefined ? JsxRuntime.jsx(DocumentBrowserSaveDialog.make, {
                        document: documentInSaveDialog,
                        onSave: (function ($$document) {
                            var update = Curry._1(Prelude.OptionExported.$$Option.getExn, updateDocument);
                            return Prelude.thenResolve(update($$document), (function (res) {
                                          if (res.TAG === "Ok") {
                                            var d = res._0;
                                            setDoc(function (param) {
                                                  return d;
                                                });
                                          } else {
                                            var msg = res._0;
                                            setError(function (param) {
                                                  return Caml_option.some(msg);
                                                });
                                          }
                                          setDocumentInSaveDialog(function (param) {
                                                
                                              });
                                        }));
                          }),
                        onCancel: (function () {
                            setDocumentInSaveDialog(function (param) {
                                  
                                });
                          })
                      }) : null,
                match[0] !== undefined ? JsxRuntime.jsx(Snackbar, {
                        autoHideDuration: 6000.0,
                        children: Caml_option.some(JsxRuntime.jsx(Alert, {
                                  sx: {
                                    width: "100%"
                                  },
                                  children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                            msg: {
                                              NAME: "msg",
                                              VAL: [
                                                "catalog.browser.error-could-not-save-doc",
                                                "The was an error saving the document."
                                              ]
                                            }
                                          })),
                                  severity: "error"
                                })),
                        onClose: (function (param, param$1) {
                            setError(function (param) {
                                  
                                });
                          }),
                        open: true
                      }) : null
              ]
            });
}

var make = DocumentBrowser;

export {
  make ,
}
/* react Not a pure module */
