// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as DocumentId from "../models/DocumentId.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DocumentCard from "./DocumentCard.js";
import * as DropdownMenu from "../../../global/components/DropdownMenu.js";
import * as ReactI18Next from "../../../../libs/i18n/ReactI18Next.js";
import * as FlexGrowSpacer from "../../../global/components/FlexGrowSpacer.js";
import * as SelectionManager from "../context/SelectionManager.js";
import * as ReactRouterDom from "react-router-dom";
import * as JsxRuntime from "react/jsx-runtime";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import * as CatalogBrowserSortContext from "../context/CatalogBrowserSortContext.js";
import ArrowUpwardRounded from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRounded from "@mui/icons-material/ArrowDownwardRounded";

function fieldLabel(field) {
  if (field === "name") {
    return JsxRuntime.jsx(ReactI18Next.Message.make, {
                msg: {
                  NAME: "str",
                  VAL: "Name"
                }
              });
  } else if (field === "dimensions") {
    return JsxRuntime.jsx(ReactI18Next.Message.make, {
                msg: {
                  NAME: "str",
                  VAL: "Dimensions"
                }
              });
  } else if (field === "created_at") {
    return JsxRuntime.jsx(ReactI18Next.Message.make, {
                msg: {
                  NAME: "str",
                  VAL: "Created at"
                }
              });
  } else if (field === "author") {
    return JsxRuntime.jsx(ReactI18Next.Message.make, {
                msg: {
                  NAME: "str",
                  VAL: "Author"
                }
              });
  } else if (field === "country") {
    return JsxRuntime.jsx(ReactI18Next.Message.make, {
                msg: {
                  NAME: "str",
                  VAL: "Country"
                }
              });
  } else {
    return JsxRuntime.jsx(ReactI18Next.Message.make, {
                msg: {
                  NAME: "str",
                  VAL: "City"
                }
              });
  }
}

function DocumentsGrid$Header(props) {
  var paginator = props.paginator;
  var documents = props.documents;
  var sortContext = CatalogBrowserSortContext.$$Document.useCurrent();
  var setField = CatalogBrowserSortContext.$$Document.useSetField();
  var toggleDirection = CatalogBrowserSortContext.$$Document.useToggleDirection();
  var areAllSelected = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.Documents.useAreAllSelected());
  var hasSelection = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.Documents.useHasSelection());
  var select = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.Documents.useSelect());
  var reset = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.Documents.useReset());
  var toggleSelection = function (e, param) {
    var exit = 0;
    var target;
    try {
      target = e.target;
      exit = 1;
    }
    catch (exn){
      return ;
    }
    if (exit === 1) {
      var exit$1 = 0;
      var val;
      try {
        val = target.checked;
        exit$1 = 2;
      }
      catch (exn$1){
        return ;
      }
      if (exit$1 === 2) {
        if (val) {
          return select(documents);
        } else {
          return reset();
        }
      }
      
    }
    
  };
  var indeterminate = React.useMemo((function () {
          if (areAllSelected(documents)) {
            return false;
          } else {
            return hasSelection;
          }
        }), [
        hasSelection,
        areAllSelected,
        documents
      ]);
  var checked = React.useMemo((function () {
          if (indeterminate) {
            return false;
          } else {
            return areAllSelected(documents);
          }
        }), [
        indeterminate,
        areAllSelected,
        documents
      ]);
  var tooltip = JsxRuntime.jsx(ReactI18Next.Message.make, {
        msg: {
          NAME: "str",
          VAL: "Toggle selection"
        }
      });
  return JsxRuntime.jsxs(Stack, {
              children: [
                JsxRuntime.jsxs(Grid, {
                      children: [
                        JsxRuntime.jsx(Grid, {
                              children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                        children: JsxRuntime.jsx(Checkbox, {
                                              checked: checked,
                                              indeterminate: indeterminate,
                                              onChange: toggleSelection
                                            }),
                                        arrow: true,
                                        title: tooltip
                                      })),
                              item: true
                            }),
                        JsxRuntime.jsx(Grid, {
                              children: Caml_option.some(Prelude.default(props.label, JsxRuntime.jsx(Typography, {
                                            variant: "h5",
                                            children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                      msg: {
                                                        NAME: "msg",
                                                        VAL: [
                                                          "catalog.browser.images.title",
                                                          "Images"
                                                        ]
                                                      }
                                                    }))
                                          }))),
                              item: true
                            }),
                        paginator !== undefined ? JsxRuntime.jsx(Grid, {
                                children: Caml_option.some(Caml_option.valFromOption(paginator)),
                                item: true
                              }) : null
                      ],
                      container: true,
                      spacing: 2,
                      sx: {
                        alignItems: "center"
                      }
                    }),
                JsxRuntime.jsx(FlexGrowSpacer.make, {}),
                setField !== undefined && toggleDirection !== undefined && sortContext !== undefined ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                        children: [
                          JsxRuntime.jsx(DropdownMenu.make, {
                                trigger: {
                                  TAG: "Button",
                                  _0: fieldLabel(sortContext[1])
                                },
                                items: [
                                  {
                                    TAG: "Item",
                                    _0: {
                                      title: {
                                        NAME: "str",
                                        VAL: "Name"
                                      },
                                      action: {
                                        TAG: "Click",
                                        _0: (function (param) {
                                            setField("name");
                                          })
                                      }
                                    }
                                  },
                                  {
                                    TAG: "Item",
                                    _0: {
                                      title: {
                                        NAME: "str",
                                        VAL: "Created at"
                                      },
                                      action: {
                                        TAG: "Click",
                                        _0: (function (param) {
                                            setField("created_at");
                                          })
                                      }
                                    }
                                  },
                                  {
                                    TAG: "Item",
                                    _0: {
                                      title: {
                                        NAME: "str",
                                        VAL: "Author"
                                      },
                                      action: {
                                        TAG: "Click",
                                        _0: (function (param) {
                                            setField("author");
                                          })
                                      }
                                    }
                                  },
                                  {
                                    TAG: "Item",
                                    _0: {
                                      title: {
                                        NAME: "str",
                                        VAL: "Dimensions"
                                      },
                                      action: {
                                        TAG: "Click",
                                        _0: (function (param) {
                                            setField("dimensions");
                                          })
                                      }
                                    }
                                  },
                                  {
                                    TAG: "Item",
                                    _0: {
                                      title: {
                                        NAME: "str",
                                        VAL: "City"
                                      },
                                      action: {
                                        TAG: "Click",
                                        _0: (function (param) {
                                            setField("city");
                                          })
                                      }
                                    }
                                  },
                                  {
                                    TAG: "Item",
                                    _0: {
                                      title: {
                                        NAME: "str",
                                        VAL: "Country"
                                      },
                                      action: {
                                        TAG: "Click",
                                        _0: (function (param) {
                                            setField("country");
                                          })
                                      }
                                    }
                                  }
                                ],
                                tooltip: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                          msg: {
                                            NAME: "str",
                                            VAL: "Sort field"
                                          }
                                        })),
                                color: "inherit",
                                delay: 0
                              }),
                          JsxRuntime.jsx(Tooltip, {
                                children: JsxRuntime.jsx(IconButton, {
                                      children: Caml_option.some(sortContext[0] === "asc" ? JsxRuntime.jsx(ArrowUpwardRounded, {}) : JsxRuntime.jsx(ArrowDownwardRounded, {})),
                                      onClick: (function (param) {
                                          toggleDirection();
                                        })
                                    }),
                                title: JsxRuntime.jsx(ReactI18Next.Message.make, {
                                      msg: {
                                        NAME: "str",
                                        VAL: "Reverse sort direction"
                                      }
                                    })
                              })
                        ]
                      }) : null
              ],
              direction: ["row"],
              spacing: "0.5rem"
            });
}

function DocumentsGrid(props) {
  var documents = props.documents;
  var $$location = ReactRouterDom.useLocation();
  React.useEffect((function () {
          var hash = $$location.hash;
          if (hash !== undefined) {
            var hash$1 = hash.substring(1);
            var el = document.getElementById(hash$1);
            if (!(el == null)) {
              el.scrollIntoView({
                    behavior: "instant",
                    block: "end"
                  });
            }
            
          }
          
        }), [$$location]);
  return JsxRuntime.jsxs(Stack, {
              children: [
                JsxRuntime.jsx(DocumentsGrid$Header, {
                      documents: documents,
                      paginator: props.paginator,
                      label: props.label
                    }),
                JsxRuntime.jsx(Stack, {
                      children: Caml_option.some(documents.map(function ($$document) {
                                return JsxRuntime.jsx(DocumentCard.make, {
                                            document: $$document
                                          }, DocumentId.toString($$document.id));
                              })),
                      direction: ["row"],
                      spacing: "1rem",
                      sx: {
                        flexWrap: "wrap"
                      }
                    })
              ],
              direction: ["column"],
              spacing: "0.5rem"
            });
}

var make = DocumentsGrid;

export {
  make ,
}
/* react Not a pure module */
