// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import HelpOutlined from "@mui/icons-material/HelpOutlined";

var icon = Caml_option.some(JsxRuntime.jsx(HelpOutlined, {}));

var name = {
  NAME: "msg",
  VAL: [
    "about.title",
    "About"
  ]
};

var path = "/software/about";

var permissions = "Public";

export {
  name ,
  path ,
  permissions ,
  icon ,
}
/* icon Not a pure module */
