// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as $$Document from "../models/Document.js";
import * as DocumentId from "../models/DocumentId.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Orientation from "../models/Orientation.js";
import * as ReactI18Next from "../../../../libs/i18n/ReactI18Next.js";
import * as ReactDOMStyle from "@rescript/react/lib/es6/src/ReactDOMStyle.js";
import * as ClipboardManager from "../context/ClipboardManager.js";
import * as DocumentDropdown from "./affordances/DocumentDropdown.js";
import * as SelectionManager from "../context/SelectionManager.js";
import * as JsxRuntime from "react/jsx-runtime";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Tooltip from "@mui/material/Tooltip";
import * as ClipboardToggleButton from "./ClipboardToggleButton.js";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import * as DocumentAttributeLabels from "./document/DocumentAttributeLabels.js";
import Typography from "@mui/material/Typography";
import * as CatalogBrowserSortContext from "../context/CatalogBrowserSortContext.js";
import * as CurrentDocumentListManager from "../context/CurrentDocumentListManager.js";
import TableContainer from "@mui/material/TableContainer";
import ContentPaste from "@mui/icons-material/ContentPaste";
import ArrowUpwardRounded from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRounded from "@mui/icons-material/ArrowDownwardRounded";

function fieldLabel(field) {
  var msg = DocumentAttributeLabels.get(field);
  return JsxRuntime.jsx(ReactI18Next.Message.make, {
              msg: msg
            });
}

function DocumentsTable$DocumentRow(props) {
  var $$document = props.document;
  var preview = Curry._2(Prelude.OptionExported.$$Option.flatMap, $$document.previews, (function (p) {
          return p.small;
        }));
  var isSelected = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.Documents.useIsSelected());
  var toggle = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.Documents.useToggle());
  var selected = React.useMemo((function () {
          return isSelected($$document);
        }), [
        $$document,
        isSelected
      ]);
  var setActiveDocument = CurrentDocumentListManager.useSetActiveDocument();
  var isClipped = ClipboardManager.Contents.useIsClipped();
  var clipped = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, isClipped, (function (fn) {
              return fn({
                          NAME: "document",
                          VAL: $$document
                        });
            })), false);
  var style = ReactDOMStyle.unsafeAddProp({
        cursor: "default",
        userSelect: "none"
      }, "WebkitUserSelect", "none");
  return React.useMemo((function () {
                var tmp;
                if (preview !== undefined && typeof preview === "object" && preview.NAME === "remote") {
                  var content = JsxRuntime.jsx("img", {
                        style: {
                          width: "20rem"
                        },
                        loading: "lazy",
                        src: preview.VAL.url
                      });
                  tmp = JsxRuntime.jsx(Tooltip, {
                        children: JsxRuntime.jsx("span", {
                              children: $$Document.name($$document)
                            }),
                        arrow: true,
                        placement: "right",
                        title: content
                      });
                } else {
                  tmp = $$Document.name($$document);
                }
                var match = Curry._2(Prelude.OptionExported.$$Option.map, $$Document.width($$document), (function (prim) {
                        return String(prim);
                      }));
                var match$1 = Curry._2(Prelude.OptionExported.$$Option.map, $$Document.height($$document), (function (prim) {
                        return String(prim);
                      }));
                var match$2 = Orientation.Ifd0.flipsWidthHeight(Prelude.default($$Document.Orientation.get($$document), 1));
                return JsxRuntime.jsxs(TableRow, {
                            className: "selectable-item",
                            style: style,
                            id: DocumentId.toString($$document.id),
                            onMouseDown: (function (e) {
                                if (e.detail === 2) {
                                  return setActiveDocument($$document);
                                }
                                
                              }),
                            children: [
                              JsxRuntime.jsx(TableCell, {
                                    children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                              alignItems: "center",
                                              children: [
                                                JsxRuntime.jsx(Checkbox, {
                                                      checked: selected,
                                                      onChange: (function (param, param$1) {
                                                          toggle([$$document]);
                                                        }),
                                                      size: "small"
                                                    }),
                                                JsxRuntime.jsx(ClipboardToggleButton.make, {
                                                      item: {
                                                        NAME: "document",
                                                        VAL: $$document
                                                      },
                                                      fontSize: "small",
                                                      sx: {
                                                        color: "primary.main"
                                                      }
                                                    })
                                              ],
                                              direction: ["row"],
                                              spacing: 1.0
                                            }))
                                  }),
                              JsxRuntime.jsx(TableCell, {
                                    children: Caml_option.some(tmp)
                                  }),
                              JsxRuntime.jsx(TableCell, {
                                    children: Caml_option.some($$document.created_at.toLocaleDateString())
                                  }),
                              JsxRuntime.jsx(TableCell, {
                                    children: Caml_option.some(Prelude.default($$Document.author($$document), ""))
                                  }),
                              JsxRuntime.jsx(TableCell, {
                                    children: Caml_option.some(match !== undefined && match$1 !== undefined ? (
                                            match$2 ? match$1 + " x " + match : match + " x " + match$1
                                          ) : null)
                                  }),
                              JsxRuntime.jsx(TableCell, {
                                    children: Caml_option.some(Prelude.default($$Document.city($$document), ""))
                                  }),
                              JsxRuntime.jsx(TableCell, {
                                    children: Caml_option.some(Prelude.default($$Document.country($$document), ""))
                                  }),
                              JsxRuntime.jsx(TableCell, {
                                    children: Caml_option.some(JsxRuntime.jsx(DocumentDropdown.make, {
                                              document: $$document
                                            }))
                                  })
                            ]
                          });
              }), [
              $$document,
              selected,
              toggle,
              clipped
            ]);
}

function DocumentsTable$Column(props) {
  var column = props.column;
  var label = fieldLabel(column);
  var sortContext = CatalogBrowserSortContext.$$Document.useCurrent();
  var direction = Curry._2(Prelude.OptionExported.$$Option.map, sortContext, (function (param) {
          return param[0];
        }));
  var sortfield = Curry._2(Prelude.OptionExported.$$Option.map, sortContext, (function (param) {
          return param[1];
        }));
  var setField = CatalogBrowserSortContext.$$Document.useSetField();
  var toggleDirection = CatalogBrowserSortContext.$$Document.useToggleDirection();
  if (sortfield !== undefined && direction !== undefined && setField !== undefined && toggleDirection !== undefined) {
    if (column === sortfield) {
      return JsxRuntime.jsxs(Stack, {
                  children: [
                    JsxRuntime.jsx(Tooltip, {
                          children: JsxRuntime.jsx("span", {
                                children: label,
                                style: {
                                  cursor: "pointer"
                                },
                                onClick: (function (param) {
                                    toggleDirection();
                                  })
                              }),
                          arrow: true,
                          title: JsxRuntime.jsx(ReactI18Next.Message.make, {
                                msg: {
                                  NAME: "str",
                                  VAL: "Reverse sort direction"
                                }
                              })
                        }),
                    direction === "asc" ? JsxRuntime.jsx(ArrowUpwardRounded, {
                            fontSize: "small"
                          }) : JsxRuntime.jsx(ArrowDownwardRounded, {
                            fontSize: "small"
                          })
                  ],
                  direction: ["row"],
                  spacing: "1rem",
                  sx: {
                    alignItems: "center"
                  }
                });
    } else {
      return JsxRuntime.jsxs(Stack, {
                  children: [
                    JsxRuntime.jsx(Tooltip, {
                          children: JsxRuntime.jsx("span", {
                                children: label,
                                style: {
                                  cursor: "pointer"
                                },
                                onClick: (function (param) {
                                    setField(column);
                                  })
                              }),
                          arrow: true,
                          title: JsxRuntime.jsx(ReactI18Next.Message.make, {
                                msg: {
                                  NAME: "str",
                                  VAL: "Sort field"
                                }
                              })
                        }),
                    JsxRuntime.jsx(ArrowUpwardRounded, {
                          fontSize: "small",
                          style: {
                            opacity: "0"
                          }
                        })
                  ],
                  direction: ["row"],
                  spacing: "1rem",
                  sx: {
                    alignItems: "center"
                  }
                });
    }
  }
  return JsxRuntime.jsx(Stack, {
              children: Caml_option.some(JsxRuntime.jsx("span", {
                        children: label,
                        style: {
                          cursor: "default"
                        }
                      })),
              direction: ["row"],
              spacing: "1rem",
              sx: {
                alignItems: "center"
              }
            });
}

function DocumentsTable(props) {
  var paginator = props.paginator;
  var documents = props.documents;
  var hasSelection = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.Documents.useHasSelection());
  var select = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.Documents.useSelect());
  var reset = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.Documents.useReset());
  var areAllSelected = Curry._1(Prelude.OptionExported.$$Option.getExn, SelectionManager.Documents.useAreAllSelected());
  var toggleSelection = function (e, param) {
    var exit = 0;
    var target;
    try {
      target = e.target;
      exit = 1;
    }
    catch (exn){
      return ;
    }
    if (exit === 1) {
      var exit$1 = 0;
      var val;
      try {
        val = target.checked;
        exit$1 = 2;
      }
      catch (exn$1){
        return ;
      }
      if (exit$1 === 2) {
        if (val) {
          return select(documents);
        } else {
          return reset();
        }
      }
      
    }
    
  };
  var indeterminate = React.useMemo((function () {
          if (areAllSelected(documents)) {
            return false;
          } else {
            return hasSelection;
          }
        }), [
        hasSelection,
        areAllSelected,
        documents
      ]);
  var checked = React.useMemo((function () {
          if (indeterminate) {
            return false;
          } else {
            return areAllSelected(documents);
          }
        }), [
        indeterminate,
        areAllSelected,
        documents
      ]);
  return JsxRuntime.jsxs(Stack, {
              children: [
                JsxRuntime.jsxs(Grid, {
                      children: [
                        JsxRuntime.jsx(Grid, {
                              children: Caml_option.some(Prelude.default(props.label, JsxRuntime.jsx(Typography, {
                                            variant: "h6",
                                            children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                      msg: {
                                                        NAME: "msg",
                                                        VAL: [
                                                          "catalog.browser.images.title",
                                                          "Images"
                                                        ]
                                                      }
                                                    }))
                                          }))),
                              item: true
                            }),
                        paginator !== undefined ? JsxRuntime.jsx(Grid, {
                                children: Caml_option.some(Caml_option.valFromOption(paginator)),
                                item: true
                              }) : null
                      ],
                      container: true,
                      spacing: 2,
                      sx: {
                        alignItems: "center"
                      }
                    }),
                JsxRuntime.jsx(TableContainer, {
                      children: Caml_option.some(JsxRuntime.jsxs(Table, {
                                children: [
                                  JsxRuntime.jsx(TableHead, {
                                        children: Caml_option.some(JsxRuntime.jsxs(TableRow, {
                                                  children: [
                                                    JsxRuntime.jsxs(TableCell, {
                                                          children: [
                                                            JsxRuntime.jsx(Checkbox, {
                                                                  checked: checked,
                                                                  indeterminate: indeterminate,
                                                                  onChange: toggleSelection,
                                                                  size: "small"
                                                                }),
                                                            JsxRuntime.jsx(ContentPaste, {
                                                                  fontSize: "small",
                                                                  style: {
                                                                    opacity: "0"
                                                                  }
                                                                })
                                                          ]
                                                        }),
                                                    JsxRuntime.jsx(TableCell, {
                                                          children: Caml_option.some(JsxRuntime.jsx(DocumentsTable$Column, {
                                                                    column: "name"
                                                                  }))
                                                        }),
                                                    JsxRuntime.jsx(TableCell, {
                                                          children: Caml_option.some(JsxRuntime.jsx(DocumentsTable$Column, {
                                                                    column: "created_at"
                                                                  }))
                                                        }),
                                                    JsxRuntime.jsx(TableCell, {
                                                          children: Caml_option.some(JsxRuntime.jsx(DocumentsTable$Column, {
                                                                    column: "author"
                                                                  }))
                                                        }),
                                                    JsxRuntime.jsx(TableCell, {
                                                          children: Caml_option.some(JsxRuntime.jsx(DocumentsTable$Column, {
                                                                    column: "dimensions"
                                                                  }))
                                                        }),
                                                    JsxRuntime.jsx(TableCell, {
                                                          children: Caml_option.some(JsxRuntime.jsx(DocumentsTable$Column, {
                                                                    column: "city"
                                                                  }))
                                                        }),
                                                    JsxRuntime.jsx(TableCell, {
                                                          children: Caml_option.some(JsxRuntime.jsx(DocumentsTable$Column, {
                                                                    column: "country"
                                                                  }))
                                                        }),
                                                    JsxRuntime.jsx(TableCell, {})
                                                  ]
                                                }))
                                      }),
                                  JsxRuntime.jsx(TableBody, {
                                        children: Caml_option.some(documents.map(function ($$document) {
                                                  return JsxRuntime.jsx(DocumentsTable$DocumentRow, {
                                                              document: $$document
                                                            }, DocumentId.toString($$document.id));
                                                }))
                                      })
                                ],
                                size: "small"
                              }))
                    })
              ],
              direction: ["column"],
              spacing: "0.5rem"
            });
}

var make = DocumentsTable;

export {
  make ,
}
/* react Not a pure module */
