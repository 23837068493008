// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as User from "../models/User.js";
import * as Sentry from "../../utils/Sentry.js";
import * as APIMaker from "../../utils/APIMaker.js";
import * as UserPermission from "../models/UserPermission.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

var InvalidUser = /* @__PURE__ */Caml_exceptions.create("AuthEndpoint.InvalidUser");

var fields = {
  TAG: "Object",
  _0: [
    [
      "access",
      "String"
    ],
    [
      "refresh",
      "String"
    ],
    [
      "user",
      {
        TAG: "Deserializer",
        _0: User.Deserializer
      }
    ],
    [
      "permissions",
      {
        TAG: "Collection",
        _0: UserPermission.Deserializer
      }
    ]
  ]
};

var Deserializer = $$JSON.MakeDeserializer({
      fields: fields
    });

var Impl = APIMaker.MakeBackendAPIEndpoint({
      url: "token/pair",
      Deserializer: Deserializer
    });

async function $$do(username, password) {
  var e = await Impl.postOne(undefined, undefined, {
        username: username,
        password: password
      });
  if (e.TAG === "Ok") {
    var response = e._0;
    if (response !== undefined) {
      Sentry.setUser({
            id: response.user.id,
            username: response.user.username,
            email: response.user.email
          });
      return response;
    }
    throw {
          RE_EXN_ID: InvalidUser,
          code: 400,
          message: {
            NAME: "msg",
            VAL: [
              "errors.auth-user-not-found",
              "The username and password didn't match any known user."
            ]
          },
          Error: new Error()
        };
  }
  console.error("AuthEndpoint", e._0);
  throw {
        RE_EXN_ID: InvalidUser,
        code: 400,
        message: {
          NAME: "msg",
          VAL: [
            "errors.auth-user-not-found",
            "The username and password didn't match any known user."
          ]
        },
        Error: new Error()
      };
}

var fields$1 = {
  TAG: "Object",
  _0: [
    [
      "access",
      "String"
    ],
    [
      "refresh",
      "String"
    ]
  ]
};

var Deserializer$1 = $$JSON.MakeDeserializer({
      fields: fields$1
    });

var Impl$1 = APIMaker.MakeBackendAPIEndpoint({
      url: "token/refresh",
      Deserializer: Deserializer$1
    });

async function $$do$1(refresh) {
  var e = await Impl$1.postOne(undefined, undefined, {
        refresh: refresh
      });
  if (e.TAG === "Ok") {
    var response = e._0;
    if (response !== undefined) {
      return response;
    }
    throw {
          RE_EXN_ID: InvalidUser,
          code: 400,
          message: {
            NAME: "msg",
            VAL: [
              "errors.auth-user-not-found",
              "The username and password didn't match any known user"
            ]
          },
          Error: new Error()
        };
  }
  console.error("AuthEndpoint", e._0);
  throw {
        RE_EXN_ID: InvalidUser,
        code: 400,
        message: {
          NAME: "msg",
          VAL: [
            "errors.auth-user-not-found",
            "The username and password didn't match any known user"
          ]
        },
        Error: new Error()
      };
}

var ObtainToken = {
  $$do: $$do
};

var RefreshToken = {
  $$do: $$do$1
};

export {
  InvalidUser ,
  ObtainToken ,
  RefreshToken ,
}
/* Impl Not a pure module */
