// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../libs/i18n/ReactI18Next.js";
import * as FolderBrowser from "./components/FolderBrowser.js";
import * as WithBulkEditor from "./components/WithBulkEditor.js";
import * as ApplicationMaker from "../../global/apps/ApplicationMaker.js";
import * as CatalogNavigator from "./context/CatalogNavigator.js";
import * as NamespaceContext from "../../global/context/NamespaceContext.js";
import * as SelectionManager from "./context/SelectionManager.js";
import * as ReactRouterDom from "react-router-dom";
import * as JsxRuntime from "react/jsx-runtime";
import * as EmptyFolderService from "./context/services/EmptyFolderService.js";
import * as CatalogFreeDropzone from "./components/CatalogFreeDropzone.js";
import * as ClipboardAffordance from "./components/affordances/ClipboardAffordance.js";
import * as RemoveFolderService from "./context/services/RemoveFolderService.js";
import * as SelectionAffordance from "./components/affordances/SelectionAffordance.js";
import * as WithDocumentBrowser from "./components/WithDocumentBrowser.js";
import * as CurrentFolderManager from "./context/CurrentFolderManager.js";
import * as TrashBrowserManifest from "./TrashBrowserManifest.js";
import * as EmptyFolderAffordance from "./components/affordances/EmptyFolderAffordance.js";
import * as RemoveDocumentService from "./context/services/RemoveDocumentService.js";
import Backdrop from "@mui/material/Backdrop";
import * as CatalogBrowserSortContext from "./context/CatalogBrowserSortContext.js";
import * as CatalogBrowserViewContext from "./context/CatalogBrowserViewContext.js";
import * as CurrentDocumentListManager from "./context/CurrentDocumentListManager.js";
import * as CatalogDeleteVariantContext from "./context/CatalogDeleteVariantContext.js";
import CircularProgress from "@mui/material/CircularProgress";
import * as TrashResidencyConfigurationAffordance from "./components/affordances/TrashResidencyConfigurationAffordance.js";

function TrashBrowserView$State(props) {
  var params = ReactRouterDom.useParams();
  var ns = NamespaceContext.useNamespace();
  var folderId = params.folderId;
  var folderId$1 = folderId !== undefined ? Caml_option.valFromOption(folderId) : ns.trash_folder_id;
  return JsxRuntime.jsx(CatalogDeleteVariantContext.make, {
              variant: "RemoveForever",
              children: JsxRuntime.jsx(CatalogNavigator.make, {
                    path: TrashBrowserManifest.path,
                    children: JsxRuntime.jsx(CatalogBrowserSortContext.make, {
                          children: JsxRuntime.jsx(CurrentDocumentListManager.make, {
                                children: JsxRuntime.jsx(CurrentFolderManager.make, {
                                      folderId: folderId$1,
                                      namespace: ns,
                                      children: JsxRuntime.jsx(SelectionManager.Documents.make, {
                                            children: JsxRuntime.jsx(SelectionManager.Folders.make, {
                                                  children: props.children
                                                })
                                          })
                                    })
                              })
                        })
                  })
            });
}

function TrashBrowserView$Services(props) {
  return JsxRuntime.jsx(TrashBrowserView$State, {
              children: JsxRuntime.jsx(CatalogBrowserViewContext.make, {
                    children: JsxRuntime.jsx(RemoveFolderService.make, {
                          children: JsxRuntime.jsx(RemoveDocumentService.make, {
                                children: JsxRuntime.jsx(EmptyFolderService.make, {
                                      children: props.children
                                    })
                              })
                        })
                  })
            });
}

var Services = {
  make: TrashBrowserView$Services
};

function TrashBrowserView$Affordances(props) {
  var children = props.children;
  var namespace = NamespaceContext.useNamespace();
  var folder = CurrentFolderManager.useCurrentFolder();
  var subfolders = CurrentFolderManager.useCurrentSubfolders();
  var docs = CurrentDocumentListManager.useDocuments();
  var isEmpty = React.useMemo((function () {
          if (Prelude.$$Array.isEmpty(subfolders)) {
            return Prelude.$$Array.isEmpty(docs);
          } else {
            return false;
          }
        }), [
        subfolders,
        docs
      ]);
  if (folder !== undefined && Caml_obj.equal(folder.id, namespace.trash_folder_id)) {
    return JsxRuntime.jsx(SelectionAffordance.make, {
                children: JsxRuntime.jsx(ClipboardAffordance.make, {
                      children: JsxRuntime.jsx(TrashResidencyConfigurationAffordance.make, {
                            children: isEmpty ? children : JsxRuntime.jsx(EmptyFolderAffordance.make, {
                                    folder: folder,
                                    title: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                              msg: {
                                                NAME: "msg",
                                                VAL: [
                                                  "trash.empty-trash",
                                                  "Empty trash"
                                                ]
                                              }
                                            })),
                                    confirmTitle: {
                                      NAME: "msg",
                                      VAL: [
                                        "trash.confirm-empty-title",
                                        "Confirm empty trash"
                                      ]
                                    },
                                    confirmMessage: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                              msg: {
                                                NAME: "msg",
                                                VAL: [
                                                  "trash.confirm-empty-message",
                                                  "This action cannot be undone.  Are you sure you want to empty the trash?"
                                                ]
                                              }
                                            })),
                                    children: children
                                  })
                          })
                    }),
                delete: "remove"
              });
  } else {
    return children;
  }
}

var Affordances = {
  make: TrashBrowserView$Affordances
};

function TrashBrowserView$Browser(props) {
  var params = ReactRouterDom.useParams();
  var ns = NamespaceContext.useNamespace();
  var folder = CurrentFolderManager.useCurrentFolder();
  var subfolders = CurrentFolderManager.useCurrentSubfolders();
  var documents = CurrentDocumentListManager.useDocuments();
  var stage = CurrentFolderManager.useCurrentStage();
  var folderId = params.folderId;
  var folderId$1 = folderId !== undefined ? Caml_option.valFromOption(folderId) : ns.trash_folder_id;
  var reset = Prelude.default(SelectionManager.Documents.useReset(), (function () {
          
        }));
  React.useEffect((function () {
          reset();
        }), [folderId$1]);
  var reset$1 = Prelude.default(SelectionManager.Folders.useReset(), (function () {
          
        }));
  React.useEffect((function () {
          reset$1();
        }), [folderId$1]);
  if (folder === undefined) {
    return null;
  }
  switch (stage) {
    case "LoadedCached" :
        return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                    children: [
                      JsxRuntime.jsx(FolderBrowser.make, {
                            folder: folder,
                            subfolders: subfolders,
                            documents: documents
                          }),
                      JsxRuntime.jsx(Backdrop, {
                            open: true,
                            children: Caml_option.some(JsxRuntime.jsx(CircularProgress, {}))
                          })
                    ]
                  });
    case "LoadedReady" :
    case "LoadedOffline" :
        break;
    case "Init" :
    case "Error" :
        return null;
    
  }
  return JsxRuntime.jsx(CatalogFreeDropzone.make, {
              children: Caml_option.some(JsxRuntime.jsx(FolderBrowser.make, {
                        folder: folder,
                        subfolders: subfolders,
                        documents: documents
                      })),
              allowed: "forbid"
            });
}

function TrashBrowserView$Main(props) {
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(TrashBrowserView$Browser, {}),
                JsxRuntime.jsx(WithBulkEditor.make, {}),
                JsxRuntime.jsx(WithDocumentBrowser.make, {})
              ]
            });
}

var Main = {
  make: TrashBrowserView$Main
};

var include = ApplicationMaker.MakeStandard({
      Manifest: TrashBrowserManifest,
      Services: Services,
      Affordances: Affordances,
      Main: Main
    });

var Manifest = include.Manifest;

var make = include.make;

export {
  Manifest ,
  make ,
}
/* include Not a pure module */
