// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Collection from "./models/Collection.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SearchQuery from "../search/models/SearchQuery.js";
import * as BundlesManager from "../catalog/context/BundlesManager.js";
import * as WithBulkEditor from "../catalog/components/WithBulkEditor.js";
import * as BulkEditContext from "../catalog/context/BulkEditContext.js";
import * as CollectionMatch from "../search/models/CollectionMatch.js";
import * as ApplicationMaker from "../../global/apps/ApplicationMaker.js";
import * as NamespaceContext from "../../global/context/NamespaceContext.js";
import * as SearchResultArea from "../search/components/SearchResultArea.js";
import * as SelectionManager from "../catalog/context/SelectionManager.js";
import * as ReactRouterDom from "react-router-dom";
import * as JsxRuntime from "react/jsx-runtime";
import * as DocumentDownloader from "../catalog/context/services/DocumentDownloader.js";
import * as SearchFiltersPanel from "../search/components/SearchFiltersPanel.js";
import Stack from "@mui/material/Stack";
import * as ClipboardAffordance from "../catalog/components/affordances/ClipboardAffordance.js";
import * as SelectionAffordance from "../catalog/components/affordances/SelectionAffordance.js";
import * as WithDocumentBrowser from "../catalog/components/WithDocumentBrowser.js";
import * as SearchFiltersContext from "../search/context/SearchFiltersContext.js";
import * as RemoveDocumentService from "../catalog/context/services/RemoveDocumentService.js";
import * as UpdateDocumentService from "../catalog/context/services/UpdateDocumentService.js";
import * as CatalogBrowserManifest from "../catalog/CatalogBrowserManifest.js";
import * as DocumentFinderNavigator from "../catalog/context/DocumentFinderNavigator.js";
import * as CollectionsManagerManifest from "./CollectionsManagerManifest.js";
import * as CurrentDocumentListManager from "../catalog/context/CurrentDocumentListManager.js";

function CollectionsExplorerView$Services(props) {
  return JsxRuntime.jsx(CurrentDocumentListManager.make, {
              children: JsxRuntime.jsx(DocumentFinderNavigator.make, {
                    path: CatalogBrowserManifest.path,
                    children: JsxRuntime.jsx(BulkEditContext.make, {
                          children: JsxRuntime.jsx(BundlesManager.make, {
                                children: JsxRuntime.jsx(DocumentDownloader.make, {
                                      children: JsxRuntime.jsx(UpdateDocumentService.make, {
                                            children: JsxRuntime.jsx(RemoveDocumentService.make, {
                                                  children: JsxRuntime.jsx(SelectionManager.Documents.make, {
                                                        children: props.children
                                                      })
                                                })
                                          })
                                    })
                              })
                        })
                  })
            });
}

var Services = {
  make: CollectionsExplorerView$Services
};

function CollectionsExplorerView$Affordances(props) {
  return JsxRuntime.jsx(SelectionAffordance.make, {
              children: JsxRuntime.jsx(ClipboardAffordance.make, {
                    children: props.children
                  })
            });
}

var Affordances = {
  make: CollectionsExplorerView$Affordances
};

function CollectionsExplorerView$Explorer(props) {
  return JsxRuntime.jsxs(Stack, {
              children: [
                JsxRuntime.jsx(SearchFiltersPanel.make, {}),
                JsxRuntime.jsx(SearchResultArea.make, {})
              ],
              direction: ["column"],
              spacing: 2.0
            });
}

function CollectionsExplorerView$Main(props) {
  var params = ReactRouterDom.useParams();
  var match = React.useState(function () {
        return {
                TAG: "Init",
                _0: params
              };
      });
  var setState = match[1];
  var state = match[0];
  var ns = NamespaceContext.useNamespace();
  var newrecord = Caml_obj.obj_dup(SearchQuery.Filters.$$default);
  newrecord.root = Caml_option.some(ns.root_folder_id);
  React.useEffect((function () {
          console.log(state);
          if (typeof state === "object" && state.TAG === "Init") {
            var collection_data = state._0.collection_data;
            if (collection_data !== undefined) {
              var collection = Collection.fromURLPayload(collection_data);
              if (collection !== undefined) {
                setState(function (param) {
                      return {
                              TAG: "Loaded",
                              _0: CollectionMatch.fromCollection(collection)
                            };
                    });
              } else {
                setState(function (param) {
                      return "NotFound";
                    });
              }
            }
            
          }
          
        }), [state]);
  if (typeof state !== "object") {
    return null;
  }
  if (state.TAG === "Init") {
    return null;
  }
  var newrecord$1 = Caml_obj.obj_dup(SearchQuery.Filters.$$default);
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(SearchFiltersContext.make, {
                      children: JsxRuntime.jsx(CollectionsExplorerView$Explorer, {}),
                      fixtures: newrecord,
                      defaults: (newrecord$1.collections = [state._0], newrecord$1)
                    }),
                JsxRuntime.jsx(WithDocumentBrowser.make, {}),
                JsxRuntime.jsx(WithBulkEditor.make, {})
              ]
            });
}

var Main = {
  make: CollectionsExplorerView$Main
};

var include = ApplicationMaker.MakeStandard({
      Manifest: CollectionsManagerManifest,
      Services: Services,
      Affordances: Affordances,
      Main: Main
    });

var Manifest = include.Manifest;

var Services$1 = include.Services;

var Affordances$1 = include.Affordances;

var Main$1 = include.Main;

var make = include.make;

export {
  Manifest ,
  Services$1 as Services,
  Affordances$1 as Affordances,
  Main$1 as Main,
  make ,
}
/* include Not a pure module */
