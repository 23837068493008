// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Folder from "../models/Folder.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as $$Document from "../models/Document.js";
import * as FolderId from "../models/FolderId.js";
import * as AppSchema from "../../../global/db/AppSchema.js";
import * as AuthToken from "../../../global/models/AuthToken.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as NamespaceId from "../../../global/db/ids/NamespaceId.js";
import * as UserContext from "../../../global/context/UserContext.js";
import * as SharedDBQuery from "../../../global/db/SharedDBQuery.js";
import * as SharedDBContext from "../../../global/context/SharedDBContext.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CatalogAPIEndpoint from "../api/CatalogAPIEndpoint.js";
import * as CatalogBrowserSortContext from "./CatalogBrowserSortContext.js";
import * as CurrentDocumentListManager from "./CurrentDocumentListManager.js";

var context = React.createContext(undefined);

var make = context.Provider;

function dispatch(state, action) {
  if (typeof action !== "object") {
    switch (action) {
      case "BeginTransaction" :
          if (state.TAG === "Ready") {
            var ready = state._0;
            return {
                    TAG: "Transaction",
                    previous: ready,
                    working: ready
                  };
          }
          console.error("CurrentFolderManager", "Cannot begin transaction", {
                state: state
              });
          return state;
      case "Commit" :
          if (state.TAG === "Transaction") {
            return {
                    TAG: "Ready",
                    _0: state.working
                  };
          }
          console.error("CurrentFolderManager", "Cannot commit transaction", {
                state: state
              });
          return state;
      case "Rollback" :
          if (state.TAG === "Transaction") {
            return {
                    TAG: "Ready",
                    _0: state.previous
                  };
          }
          console.error("CurrentFolderManager", "Cannot rollback transaction", {
                state: state
              });
          return state;
      case "Clear" :
          switch (state.TAG) {
            case "Ready" :
                var data = state._0;
                return {
                        TAG: "Ready",
                        _0: {
                          folderId: data.folderId,
                          namespace: data.namespace,
                          folder: data.folder,
                          subfolders: [],
                          documents: []
                        }
                      };
            case "Transaction" :
                var working = state.working;
                return {
                        TAG: "Transaction",
                        previous: state.previous,
                        working: {
                          folderId: working.folderId,
                          namespace: working.namespace,
                          folder: working.folder,
                          subfolders: [],
                          documents: []
                        }
                      };
            default:
              return state;
          }
      case "Reload" :
          switch (state.TAG) {
            case "Transaction" :
                var match = state.previous;
                return {
                        TAG: "Init",
                        folderId: match.folderId,
                        namespace: match.namespace
                      };
            case "Init" :
            case "NotFound" :
                return state;
            default:
              var match$1 = state._0;
              return {
                      TAG: "Init",
                      folderId: match$1.folderId,
                      namespace: match$1.namespace
                    };
          }
      
    }
  } else {
    switch (action.TAG) {
      case "UpdateCurrentFolder" :
          var folder = action._0;
          switch (state.TAG) {
            case "Ready" :
                var ready$1 = state._0;
                var current = ready$1.folder;
                if (Caml_obj.equal(current.id, folder.id)) {
                  console.log("CurrentFolderManager", "updateCurrent", {
                        folder: folder
                      });
                  return {
                          TAG: "Ready",
                          _0: {
                            folderId: ready$1.folderId,
                            namespace: ready$1.namespace,
                            folder: folder,
                            subfolders: ready$1.subfolders,
                            documents: ready$1.documents
                          }
                        };
                } else {
                  console.warn("CurrentFolderManager", "updateCurrent (no effect)", {
                        folder: current
                      });
                  return state;
                }
            case "Transaction" :
                var working$1 = state.working;
                var current$1 = working$1.folder;
                if (Caml_obj.equal(current$1.id, folder.id)) {
                  console.log("CurrentFolderManager", "updateCurrent", {
                        folder: folder
                      });
                  return {
                          TAG: "Transaction",
                          previous: state.previous,
                          working: {
                            folderId: working$1.folderId,
                            namespace: working$1.namespace,
                            folder: folder,
                            subfolders: working$1.subfolders,
                            documents: working$1.documents
                          }
                        };
                } else {
                  console.warn("CurrentFolderManager", "updateCurrent (no effect)", {
                        folder: current$1
                      });
                  return state;
                }
            default:
              console.warn("CurrentFolderManager", "updateCurrent (no effect, not in Ready or Transaction)", {
                    state: state
                  });
              return state;
          }
      case "UpdateOrAddFolder" :
          var newfolder = action._0;
          switch (state.TAG) {
            case "Ready" :
                var ready$2 = state._0;
                var newfolder$1 = Folder.clone(newfolder);
                var subfolders = ready$2.subfolders;
                var folder$1 = ready$2.folder;
                var subfolders$1 = Caml_obj.notequal(newfolder$1.id, folder$1.id) ? Curry._2(Prelude.$$Array.keep, Prelude.$$Array.append(Curry._2(Prelude.$$Array.keep, subfolders, (function (f) {
                                  return Caml_obj.notequal(f.id, newfolder$1.id);
                                })), newfolder$1), (function (f) {
                          return Caml_obj.equal(f.parent, Caml_option.some(folder$1.id));
                        })) : (console.warn("CurrentFolderManager", "updateOrAddFolder called on root"), subfolders);
                console.log("CurrentFolderManager", "updateOrAddFolder", {
                      folder: newfolder$1,
                      subfolders: subfolders$1
                    });
                return {
                        TAG: "Ready",
                        _0: {
                          folderId: ready$2.folderId,
                          namespace: ready$2.namespace,
                          folder: ready$2.folder,
                          subfolders: subfolders$1,
                          documents: ready$2.documents
                        }
                      };
            case "Transaction" :
                var working$2 = state.working;
                var newfolder$2 = Folder.clone(newfolder);
                var subfolders$2 = working$2.subfolders;
                var folder$2 = working$2.folder;
                var subfolders$3 = Caml_obj.notequal(newfolder$2.id, folder$2.id) ? Curry._2(Prelude.$$Array.keep, Prelude.$$Array.append(Curry._2(Prelude.$$Array.keep, subfolders$2, (function (f) {
                                  return Caml_obj.notequal(f.id, newfolder$2.id);
                                })), newfolder$2), (function (f) {
                          return Caml_obj.equal(f.parent, Caml_option.some(folder$2.id));
                        })) : (console.warn("CurrentFolderManager", "updateOrAddFolder called on root"), subfolders$2);
                console.log("CurrentFolderManager", "updateOrAddFolder", {
                      folder: newfolder$2,
                      subfolders: subfolders$3
                    });
                return {
                        TAG: "Transaction",
                        previous: state.previous,
                        working: {
                          folderId: working$2.folderId,
                          namespace: working$2.namespace,
                          folder: working$2.folder,
                          subfolders: subfolders$3,
                          documents: working$2.documents
                        }
                      };
            default:
              console.warn("CurrentFolderManager", "updateOrAddFolder (no effect)", {
                    folder: newfolder,
                    subfolders: []
                  });
              return state;
          }
      case "RemoveSubfolder" :
          var folder$3 = action._0;
          switch (state.TAG) {
            case "Ready" :
                var ready$3 = state._0;
                var folder$4 = Folder.clone(folder$3);
                var subfolders$4 = Curry._2(Prelude.$$Array.keep, ready$3.subfolders, (function (f) {
                        return Caml_obj.notequal(f.id, folder$4.id);
                      }));
                console.log("CurrentFolderManager", "removeSubFolder", {
                      folder: folder$4,
                      subfolders: subfolders$4
                    });
                return {
                        TAG: "Ready",
                        _0: {
                          folderId: ready$3.folderId,
                          namespace: ready$3.namespace,
                          folder: ready$3.folder,
                          subfolders: subfolders$4,
                          documents: ready$3.documents
                        }
                      };
            case "Transaction" :
                var working$3 = state.working;
                var folder$5 = Folder.clone(folder$3);
                var subfolders$5 = Curry._2(Prelude.$$Array.keep, working$3.subfolders, (function (f) {
                        return Caml_obj.notequal(f.id, folder$5.id);
                      }));
                console.log("CurrentFolderManager", "removeSubFolder", {
                      folder: folder$5,
                      subfolders: subfolders$5
                    });
                return {
                        TAG: "Transaction",
                        previous: state.previous,
                        working: {
                          folderId: working$3.folderId,
                          namespace: working$3.namespace,
                          folder: working$3.folder,
                          subfolders: subfolders$5,
                          documents: working$3.documents
                        }
                      };
            default:
              console.warn("CurrentFolderManager", "removeSubFolder (no effect)", {
                    folder: folder$3,
                    subfolders: []
                  });
              return state;
          }
      case "Reset" :
          return action._0;
      
    }
  }
}

var context$1 = React.createContext({
      updateCurrentFolder: (function (param) {
          
        }),
      updateOrAddFolder: (function (param) {
          
        }),
      removeSubfolder: (function (param) {
          
        }),
      clear: (function () {
          
        }),
      reload: (function () {
          
        }),
      beginTransaction: (function () {
          
        }),
      commit: (function () {
          
        }),
      rollback: (function () {
          
        })
    });

var make$1 = context$1.Provider;

async function load(db, namespace, folderId) {
  var init = db.makeRead();
  var match = await db.read({
        documents: {
          TAG: "Is",
          _0: "folder",
          _1: FolderId.toString(folderId)
        },
        folders: {
          TAG: "Or",
          _0: {
            TAG: "Get",
            _0: folderId
          },
          _1: {
            TAG: "Is",
            _0: "parent",
            _1: FolderId.toString(folderId)
          }
        },
        bundlers: init.bundlers
      });
  var match$1 = Curry._2(Prelude.$$Array.partition, match.folders, (function (f) {
          return Caml_obj.equal(f.id, folderId);
        }));
  return {
          folderId: folderId,
          namespace: namespace,
          folder: Prelude.$$Array.first(match$1[0]),
          subfolders: match$1[1],
          documents: match.documents
        };
}

async function $$fetch(namespace, folderId, token) {
  var nodes;
  try {
    nodes = await CatalogAPIEndpoint.read(namespace.id, folderId, token);
  }
  catch (exn){
    return {
            TAG: "Error",
            _0: undefined
          };
  }
  if (nodes.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: undefined
          };
  }
  var nodes$1 = nodes._0;
  if (nodes$1.length === 0) {
    return {
            TAG: "Error",
            _0: undefined
          };
  }
  var folders = Curry._2(Prelude.$$Array.keepMap, nodes$1, (function (node) {
          if (node.NAME === "document") {
            return ;
          } else {
            return Folder.fromBackend(node.VAL);
          }
        }));
  var match = Curry._2(Prelude.$$Array.partition, folders, (function (f) {
          return Caml_obj.equal(f.id, folderId);
        }));
  var folder = Prelude.$$Array.first(match[0]);
  if (folder === undefined) {
    return {
            TAG: "Error",
            _0: undefined
          };
  }
  var documents = Curry._2(Prelude.$$Array.keepMap, nodes$1, (function (node) {
          if (node.NAME === "document") {
            return $$Document.fromBackend(node.VAL);
          }
          
        }));
  var result_folderId = folder.id;
  var result_subfolders = match[1];
  var result = {
    folderId: result_folderId,
    namespace: namespace,
    folder: folder,
    subfolders: result_subfolders,
    documents: documents
  };
  return {
          TAG: "Ok",
          _0: result
        };
}

async function sync(cached, db, token) {
  var folder = cached.folder;
  var namespace = cached.namespace;
  var initial = AppSchema.Documents.updateMany(AppSchema.Folders.updateMany(AppSchema.make(), cached.subfolders), cached.documents);
  var initial$1 = folder !== undefined ? (AppSchema.Folders.update(initial, folder), initial) : initial;
  var $$final = AppSchema.make();
  var val;
  try {
    val = await $$fetch(namespace, cached.folderId, token);
  }
  catch (exn){
    return {
            TAG: "Error",
            _0: undefined
          };
  }
  if (val.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: undefined
          };
  }
  var match = val._0;
  var documents = match.documents;
  var subfolders = match.subfolders;
  var folder$1 = match.folder;
  AppSchema.Folders.update($$final, folder$1);
  var $$final$1 = AppSchema.Documents.updateMany(AppSchema.Folders.updateMany($$final, subfolders), documents);
  var init = db.makeRead();
  var match$1 = await db.read({
        documents: init.documents,
        folders: {
          TAG: "And",
          _0: {
            TAG: "AnyOf",
            _0: "path",
            _1: subfolders.map(function (f) {
                  return f.path;
                })
          },
          _1: {
            TAG: "Is",
            _0: "namespace",
            _1: NamespaceId.toString(folder$1.namespace)
          }
        },
        bundlers: init.bundlers
      });
  var initial$2 = AppSchema.Folders.updateMany(initial$1, match$1.folders);
  var init$1 = db.makeWrite();
  var actions_documents = AppSchema.Documents.getActions(initial$2, $$final$1);
  var actions_folders = AppSchema.Folders.getActions(initial$2, $$final$1);
  var actions_bundlers = init$1.bundlers;
  var actions = {
    documents: actions_documents,
    folders: actions_folders,
    bundlers: actions_bundlers
  };
  SharedDBQuery.ActionLogging.debug("Synced folder from the server", actions);
  await db.write(actions);
  var r_folderId = folder$1.id;
  var r_folder = Folder.clone(folder$1);
  var r_subfolders = Curry._2(Prelude.$$Array.concat, subfolders, []);
  var r_documents = Curry._2(Prelude.$$Array.concat, documents, []);
  var r = {
    folderId: r_folderId,
    namespace: namespace,
    folder: r_folder,
    subfolders: r_subfolders,
    documents: r_documents
  };
  return {
          TAG: "Ok",
          _0: r
        };
}

function ensureCurrentFolderManager() {
  var match = React.useContext(context);
  if (match !== undefined) {
    return ;
  }
  throw {
        RE_EXN_ID: Prelude.AssertionError,
        _1: "Expected to be enclosed inside <CurrentFolderManager>",
        Error: new Error()
      };
}

function ensureNotCurrentFolderManager() {
  var match = React.useContext(context);
  if (match === undefined) {
    return ;
  }
  throw {
        RE_EXN_ID: Prelude.AssertionError,
        _1: "Expected not to be enclosed inside <CurrentFolderManager>",
        Error: new Error()
      };
}

function useCurrentStage() {
  var match = React.useContext(context);
  if (match === undefined) {
    return "Init";
  }
  switch (match.TAG) {
    case "Init" :
        return "Init";
    case "Cached" :
        return "LoadedCached";
    case "Ready" :
    case "Transaction" :
        return "LoadedReady";
    case "Offline" :
        return "LoadedOffline";
    case "NotFound" :
        return "Error";
    
  }
}

function useCurrentFolderId() {
  var match = React.useContext(context);
  if (match === undefined) {
    return FolderId.$$null;
  }
  switch (match.TAG) {
    case "Init" :
        return match.folderId;
    case "Transaction" :
        return match.previous.folderId;
    case "NotFound" :
        return match._0;
    default:
      return match._0.folderId;
  }
}

function useCurrentFolder() {
  var match = React.useContext(context);
  if (match === undefined) {
    return ;
  }
  switch (match.TAG) {
    case "Ready" :
        return match._0.folder;
    case "Transaction" :
        return match.previous.folder;
    case "Cached" :
    case "Offline" :
        return match._0.folder;
    case "Init" :
    case "NotFound" :
        return ;
    
  }
}

function useCurrentSubfolders() {
  var match = React.useContext(context);
  var subfolders;
  if (match !== undefined) {
    switch (match.TAG) {
      case "Transaction" :
          subfolders = match.previous.subfolders;
          break;
      case "Init" :
      case "NotFound" :
          subfolders = [];
          break;
      default:
        subfolders = match._0.subfolders;
    }
  } else {
    subfolders = [];
  }
  var sortFolders = CatalogBrowserSortContext.Folder.useSort();
  return sortFolders(subfolders);
}

function useUpdateCurrentFolder() {
  return React.useContext(context$1).updateCurrentFolder;
}

function useUpdateOrAddFolder() {
  return React.useContext(context$1).updateOrAddFolder;
}

function useRemoveSubFolder() {
  return React.useContext(context$1).removeSubfolder;
}

function useClear() {
  return React.useContext(context$1).clear;
}

function useReload() {
  return React.useContext(context$1).reload;
}

function useBeginTransaction() {
  return React.useContext(context$1).beginTransaction;
}

function useCommit() {
  return React.useContext(context$1).commit;
}

function useRollback() {
  return React.useContext(context$1).rollback;
}

function CurrentFolderManager(props) {
  var namespace = props.namespace;
  var folderId = props.folderId;
  var match = React.useReducer(dispatch, {
        TAG: "Init",
        folderId: folderId,
        namespace: namespace
      });
  var dispatch$1 = match[1];
  var state = match[0];
  var db = SharedDBContext.useQuery();
  var token = Prelude.default(UserContext.useAccessToken(), AuthToken.Access.fromString(""));
  var resetDocumentList = CurrentDocumentListManager.useReset();
  React.useEffect((function () {
          var exit = 0;
          var loaded;
          switch (state.TAG) {
            case "Init" :
                Prelude.thenDo(load(db, state.namespace, state.folderId), (function (data) {
                        var documents = data.documents;
                        resetDocumentList({
                              documents: documents,
                              active: undefined,
                              count: documents.length,
                              page: 1,
                              pageSize: documents.length
                            });
                        dispatch$1({
                              TAG: "Reset",
                              _0: {
                                TAG: "Cached",
                                _0: data
                              }
                            });
                      }));
                break;
            case "Cached" :
                var data = state._0;
                Prelude.thenDo(sync(data, db, token), (function (result) {
                        if (result.TAG === "Ok") {
                          var synced = result._0;
                          dispatch$1({
                                TAG: "Reset",
                                _0: {
                                  TAG: "Ready",
                                  _0: synced
                                }
                              });
                          var documents = synced.documents;
                          return resetDocumentList({
                                      documents: documents,
                                      active: undefined,
                                      count: documents.length,
                                      page: 1,
                                      pageSize: documents.length
                                    });
                        }
                        var folder = data.folder;
                        if (folder !== undefined) {
                          dispatch$1({
                                TAG: "Reset",
                                _0: {
                                  TAG: "Offline",
                                  _0: {
                                    folderId: folderId,
                                    namespace: namespace,
                                    folder: folder,
                                    subfolders: data.subfolders,
                                    documents: data.documents
                                  }
                                }
                              });
                          var documents$1 = data.documents;
                          return resetDocumentList({
                                      documents: documents$1,
                                      active: undefined,
                                      count: documents$1.length,
                                      page: 1,
                                      pageSize: documents$1.length
                                    });
                        }
                        dispatch$1({
                              TAG: "Reset",
                              _0: {
                                TAG: "NotFound",
                                _0: data.folderId
                              }
                            });
                        var documents$2 = [];
                        resetDocumentList({
                              documents: documents$2,
                              active: undefined,
                              count: documents$2.length,
                              page: 1,
                              pageSize: documents$2.length
                            });
                      }));
                break;
            case "Transaction" :
                loaded = state.previous.folderId;
                exit = 1;
                break;
            case "Ready" :
            case "Offline" :
                loaded = state._0.folderId;
                exit = 1;
                break;
            case "NotFound" :
                if (Caml_obj.notequal(state._0, folderId)) {
                  dispatch$1({
                        TAG: "Reset",
                        _0: {
                          TAG: "Init",
                          folderId: folderId,
                          namespace: namespace
                        }
                      });
                  var documents = [];
                  resetDocumentList({
                        documents: documents,
                        active: undefined,
                        count: documents.length,
                        page: 1,
                        pageSize: documents.length
                      });
                }
                break;
            
          }
          if (exit === 1 && Caml_obj.notequal(loaded, folderId)) {
            dispatch$1({
                  TAG: "Reset",
                  _0: {
                    TAG: "Init",
                    folderId: folderId,
                    namespace: namespace
                  }
                });
            var documents$1 = [];
            resetDocumentList({
                  documents: documents$1,
                  active: undefined,
                  count: documents$1.length,
                  page: 1,
                  pageSize: documents$1.length
                });
          }
          
        }), [
        folderId,
        state
      ]);
  var beginDocumentListTransaction = CurrentDocumentListManager.useBeginTransaction();
  var commitDocumentListTransaction = CurrentDocumentListManager.useCommit();
  var rollbackDocumentListTransaction = CurrentDocumentListManager.useRollback();
  var clearDocuments = CurrentDocumentListManager.useClear();
  var updateCurrentFolder = function (f) {
    dispatch$1({
          TAG: "UpdateCurrentFolder",
          _0: f
        });
  };
  var updateOrAddFolder = function (f) {
    dispatch$1({
          TAG: "UpdateOrAddFolder",
          _0: f
        });
  };
  var removeSubfolder = function (f) {
    dispatch$1({
          TAG: "RemoveSubfolder",
          _0: f
        });
  };
  var clear = function () {
    dispatch$1("Clear");
    clearDocuments();
  };
  var reload = function () {
    dispatch$1("Reload");
  };
  var beginTransaction = function () {
    dispatch$1("BeginTransaction");
    beginDocumentListTransaction();
  };
  var commit = function () {
    dispatch$1("Commit");
    commitDocumentListTransaction();
  };
  var rollback = function () {
    dispatch$1("Rollback");
    rollbackDocumentListTransaction();
  };
  return JsxRuntime.jsx(make, {
              value: state,
              children: JsxRuntime.jsx(make$1, {
                    value: {
                      updateCurrentFolder: updateCurrentFolder,
                      updateOrAddFolder: updateOrAddFolder,
                      removeSubfolder: removeSubfolder,
                      clear: clear,
                      reload: reload,
                      beginTransaction: beginTransaction,
                      commit: commit,
                      rollback: rollback
                    },
                    children: props.children
                  })
            });
}

var make$2 = CurrentFolderManager;

export {
  useCurrentStage ,
  ensureCurrentFolderManager ,
  ensureNotCurrentFolderManager ,
  useCurrentFolderId ,
  useCurrentFolder ,
  useCurrentSubfolders ,
  useUpdateCurrentFolder ,
  useUpdateOrAddFolder ,
  useRemoveSubFolder ,
  useClear ,
  useReload ,
  useBeginTransaction ,
  useCommit ,
  useRollback ,
  make$2 as make,
}
/* context Not a pure module */
