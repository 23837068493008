// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as DocumentId from "../../models/DocumentId.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UserContext from "../../../../global/context/UserContext.js";
import * as UserPermission from "../../../../global/models/UserPermission.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CatalogAPIEndpoint from "../../api/CatalogAPIEndpoint.js";
import * as CurrentDocumentListManager from "../CurrentDocumentListManager.js";

var context = React.createContext(undefined);

var make = context.Provider;

function useUploader() {
  return React.useContext(context);
}

async function upload(add, request, token) {
  return await Prelude.PromisedResult.map(CatalogAPIEndpoint.CreateDocument.$$do(request.folder.namespace, request.folder.id, DocumentId.make(), request.name, token, request), (function ($$document) {
                add($$document);
                return $$document;
              }));
}

function UploadDocumentService(props) {
  var token = UserContext.useAccessToken();
  var addDocument = CurrentDocumentListManager.useUpdateOrAddDocument();
  var perms = UserContext.useUserPermissionsClaim();
  var allowed = React.useMemo((function () {
          return UserPermission.Query.matches({
                      TAG: "All",
                      _0: ["catalog.change_document"]
                    }, perms);
        }), [perms]);
  var value = React.useMemo((function () {
          if (!allowed) {
            return ;
          }
          if (token === undefined) {
            return ;
          }
          var token$1 = Caml_option.valFromOption(token);
          return (function (request) {
                    return upload(addDocument, request, token$1);
                  });
        }), [
        allowed,
        token,
        addDocument
      ]);
  return JsxRuntime.jsx(make, {
              value: value,
              children: props.children
            });
}

var make$1 = UploadDocumentService;

export {
  useUploader ,
  make$1 as make,
}
/* context Not a pure module */
