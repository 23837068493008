// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../../../libs/i18n/ReactI18Next.js";
import * as JsxRuntime from "react/jsx-runtime";
import Typography from "@mui/material/Typography";

function DocumentBrowserLabelText(props) {
  var name = props.name;
  if (name !== undefined) {
    return JsxRuntime.jsx(Typography, {
                variant: "body1",
                children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                          msg: name
                        })),
                sx: {
                  fontWeight: "800"
                }
              });
  } else {
    return null;
  }
}

var make = DocumentBrowserLabelText;

export {
  make ,
}
/* ReactI18Next Not a pure module */
