// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Group from "../models/Group.js";
import * as Account from "../models/Account.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as APIMaker from "../../../utils/APIMaker.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var Search = APIMaker.MakePaginatedEndpoint({
      url: "users",
      Deserializer: Account.Deserializer
    });

var fields = {
  TAG: "Object",
  _0: [[
      "result",
      {
        TAG: "Collection",
        _0: Account.Deserializer
      }
    ]]
};

var Deserializer = $$JSON.MakeDeserializer({
      fields: fields
    });

var Impl = APIMaker.MakeBackendAPIEndpoint({
      url: "/users",
      Deserializer: Deserializer
    });

function $$do(username, password, first_name, last_name, email, groups, token) {
  var operation = {
    operation: {
      kind: "create-user",
      username: username,
      password: password,
      first_name: first_name,
      last_name: last_name,
      email: email,
      groups: groups
    }
  };
  return Prelude.PromisedResult.flatMap(Prelude.PromisedResult.mapError(Impl.postOne(undefined, Caml_option.some(token), operation), (function (param) {
                    
                  })), (function (res) {
                var user = Curry._2(Prelude.OptionExported.$$Option.flatMap, res, (function (param) {
                        return Prelude.$$Array.first(param.result);
                      }));
                if (user !== undefined) {
                  return {
                          TAG: "Ok",
                          _0: user
                        };
                } else {
                  return {
                          TAG: "Error",
                          _0: undefined
                        };
                }
              }));
}

var fields$1 = {
  TAG: "Object",
  _0: [[
      "result",
      {
        TAG: "Collection",
        _0: Account.Deserializer
      }
    ]]
};

var Deserializer$1 = $$JSON.MakeDeserializer({
      fields: fields$1
    });

var Impl$1 = APIMaker.MakeBackendAPIEndpoint({
      url: "/users",
      Deserializer: Deserializer$1
    });

function $$do$1(account, token) {
  var operation = {
    operation: {
      kind: "remove-user",
      id: account.id
    }
  };
  return Prelude.PromisedResult.mapError(Prelude.PromisedResult.map(Impl$1.postOne(undefined, Caml_option.some(token), operation), (function (param) {
                    
                  })), (function (param) {
                
              }));
}

var fields$2 = {
  TAG: "Object",
  _0: [[
      "result",
      {
        TAG: "Collection",
        _0: Account.Deserializer
      }
    ]]
};

var Deserializer$2 = $$JSON.MakeDeserializer({
      fields: fields$2
    });

var Impl$2 = APIMaker.MakeBackendAPIEndpoint({
      url: "/users",
      Deserializer: Deserializer$2
    });

function $$do$2(id, first_name, last_name, email, new_password, is_active, groups, token) {
  var operation = {
    operation: {
      kind: "update-user",
      id: id,
      first_name: first_name,
      last_name: last_name,
      email: email,
      groups: groups,
      is_active: is_active,
      new_password: new_password
    }
  };
  return Prelude.PromisedResult.flatMap(Prelude.PromisedResult.mapError(Impl$2.postOne(undefined, Caml_option.some(token), operation), (function (param) {
                    
                  })), (function (res) {
                var user = Curry._2(Prelude.OptionExported.$$Option.flatMap, res, (function (param) {
                        return Prelude.$$Array.first(param.result);
                      }));
                if (user !== undefined) {
                  return {
                          TAG: "Ok",
                          _0: user
                        };
                } else {
                  return {
                          TAG: "Error",
                          _0: undefined
                        };
                }
              }));
}

function toggleActivation(account, token) {
  var operation = {
    operation: {
      kind: "update-user",
      id: account.id,
      is_active: !account.is_active
    }
  };
  return Prelude.PromisedResult.flatMap(Prelude.PromisedResult.mapError(Impl$2.postOne(undefined, Caml_option.some(token), operation), (function (param) {
                    
                  })), (function (res) {
                var user = Curry._2(Prelude.OptionExported.$$Option.flatMap, res, (function (param) {
                        return Prelude.$$Array.first(param.result);
                      }));
                if (user !== undefined) {
                  return {
                          TAG: "Ok",
                          _0: user
                        };
                } else {
                  return {
                          TAG: "Error",
                          _0: undefined
                        };
                }
              }));
}

var fields$3 = {
  TAG: "Object",
  _0: [[
      "result",
      {
        TAG: "Collection",
        _0: Account.Deserializer
      }
    ]]
};

var Deserializer$3 = $$JSON.MakeDeserializer({
      fields: fields$3
    });

var Impl$3 = APIMaker.MakeBackendAPIEndpoint({
      url: "/users",
      Deserializer: Deserializer$3
    });

function deactivate(accounts, token) {
  var ids = accounts.map(function (a) {
        return a.id;
      });
  var operation = {
    operation: {
      kind: "bulk-update-user",
      ids: ids,
      is_active: false
    }
  };
  return Prelude.PromisedResult.flatMap(Prelude.PromisedResult.mapError(Impl$3.postOne(undefined, Caml_option.some(token), operation), (function (param) {
                    
                  })), (function (res) {
                var users = Curry._2(Prelude.OptionExported.$$Option.map, res, (function (param) {
                        return param.result;
                      }));
                if (users !== undefined) {
                  return {
                          TAG: "Ok",
                          _0: users
                        };
                } else {
                  return {
                          TAG: "Error",
                          _0: undefined
                        };
                }
              }));
}

function activate(accounts, token) {
  var ids = accounts.map(function (a) {
        return a.id;
      });
  var operation = {
    operation: {
      kind: "bulk-update-user",
      ids: ids,
      is_active: true
    }
  };
  return Prelude.PromisedResult.flatMap(Prelude.PromisedResult.mapError(Impl$3.postOne(undefined, Caml_option.some(token), operation), (function (param) {
                    
                  })), (function (res) {
                var users = Curry._2(Prelude.OptionExported.$$Option.map, res, (function (param) {
                        return param.result;
                      }));
                if (users !== undefined) {
                  return {
                          TAG: "Ok",
                          _0: users
                        };
                } else {
                  return {
                          TAG: "Error",
                          _0: undefined
                        };
                }
              }));
}

var Groups = APIMaker.MakePaginatedEndpoint({
      url: "groups",
      Deserializer: Group.Deserializer
    });

var Search$1 = {
  $$fetch: Search.$$fetch
};

var CreateAccount = {
  $$do: $$do
};

var RemoveAccount = {
  $$do: $$do$1
};

var UpdateAccount = {
  $$do: $$do$2,
  toggleActivation: toggleActivation
};

var Bulk = {
  deactivate: deactivate,
  activate: activate
};

var Groups$1 = {
  $$fetch: Groups.$$fetch
};

export {
  Search$1 as Search,
  CreateAccount ,
  RemoveAccount ,
  UpdateAccount ,
  Bulk ,
  Groups$1 as Groups,
}
/* Search Not a pure module */
