// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DropdownMenu from "../../../global/components/DropdownMenu.js";
import * as RenameTagDialog from "./affordances/RenameTagDialog.js";
import * as RemoveTagService from "../context/RemoveTagService.js";
import * as UpdateTagService from "../context/UpdateTagService.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ConfirmDeleteDialog from "../../../global/components/ConfirmDeleteDialog.js";
import Loyalty from "@mui/icons-material/Loyalty";
import MoreVert from "@mui/icons-material/MoreVert";
import EditRounded from "@mui/icons-material/EditRounded";
import DeleteForever from "@mui/icons-material/DeleteForever";

function TagDropdown(props) {
  var tag = props.tag;
  var match = React.useState(function () {
        
      });
  var setRenameTag = match[1];
  var renameTag = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setDeleteTag = match$1[1];
  var deleteTag = match$1[0];
  var updater = UpdateTagService.useUpdateTag();
  var deleter = RemoveTagService.useRemoveTag();
  var items = [
    {
      TAG: "Item",
      _0: {
        title: {
          NAME: "str",
          VAL: "Rename"
        },
        icon: Caml_option.some(JsxRuntime.jsx(EditRounded, {})),
        action: {
          TAG: "Click",
          _0: (function (param) {
              setRenameTag(function (param) {
                    return tag;
                  });
            })
        },
        disabled: Curry._1(Prelude.OptionExported.$$Option.isNone, updater)
      }
    },
    {
      TAG: "Item",
      _0: {
        title: {
          NAME: "str",
          VAL: "Toggle approval"
        },
        icon: Caml_option.some(JsxRuntime.jsx(Loyalty, {})),
        action: {
          TAG: "Click",
          _0: (function (param) {
              Prelude.OptionExported.$$Option.$$do(updater, (function (fn) {
                      fn({
                            id: tag.id,
                            name: tag.name,
                            approved: !tag.approved
                          });
                    }));
            })
        },
        disabled: Curry._1(Prelude.OptionExported.$$Option.isNone, updater)
      }
    },
    "Separator",
    {
      TAG: "Item",
      _0: {
        title: {
          NAME: "str",
          VAL: "Remove"
        },
        icon: Caml_option.some(JsxRuntime.jsx(DeleteForever, {})),
        action: {
          TAG: "Click",
          _0: (function (param) {
              setDeleteTag(function (param) {
                    return tag;
                  });
            })
        },
        disabled: Curry._1(Prelude.OptionExported.$$Option.isNone, deleter)
      }
    }
  ];
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(DropdownMenu.make, {
                      trigger: {
                        TAG: "Icon",
                        _0: JsxRuntime.jsx(MoreVert, {})
                      },
                      items: items
                    }),
                renameTag !== undefined ? JsxRuntime.jsx(RenameTagDialog.make, {
                        tag: renameTag,
                        onDone: (function () {
                            setRenameTag(function (param) {
                                  
                                });
                          }),
                        onClose: (function () {
                            setRenameTag(function (param) {
                                  
                                });
                          })
                      }) : null,
                deleteTag !== undefined ? JsxRuntime.jsx(ConfirmDeleteDialog.make, {
                        open_: true,
                        title: {
                          NAME: "str",
                          VAL: "Confirm deletion of tag"
                        },
                        onCancel: (function () {
                            setDeleteTag(function (param) {
                                  
                                });
                          }),
                        onDelete: (function () {
                            Prelude.OptionExported.$$Option.$$do(deleter, (function (fn) {
                                    Prelude.thenDo(fn(deleteTag), (function (param) {
                                            setDeleteTag(function (param) {
                                                  
                                                });
                                          }));
                                  }));
                          })
                      }) : null
              ]
            });
}

var make = TagDropdown;

export {
  make ,
}
/* react Not a pure module */
