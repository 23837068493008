// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as FolderId from "../../models/FolderId.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as DocumentId from "../../models/DocumentId.js";
import * as FolderItem from "../../models/FolderItem.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UserContext from "../../../../global/context/UserContext.js";
import * as ReactI18Next from "../../../../../libs/i18n/ReactI18Next.js";
import * as ReactI18next from "react-i18next";
import * as UserPermission from "../../../../global/models/UserPermission.js";
import * as SelectionManager from "../../context/SelectionManager.js";
import Fab from "@mui/material/Fab";
import * as JsxRuntime from "react/jsx-runtime";
import * as ConfirmDeleteDialog from "../../../../global/components/ConfirmDeleteDialog.js";
import * as RemoveFolderService from "../../context/services/RemoveFolderService.js";
import Dialog from "@mui/material/Dialog";
import * as CurrentFolderManager from "../../context/CurrentFolderManager.js";
import * as RemoveDocumentService from "../../context/services/RemoveDocumentService.js";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import LinearProgress from "@mui/material/LinearProgress";
import DialogContentText from "@mui/material/DialogContentText";
import DeleteForever from "@mui/icons-material/DeleteForever";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";

function BulkRemove$ConfirmDialog(props) {
  var count = props.items.length;
  var content = count > 0 ? JsxRuntime.jsx(ReactI18next.Trans, {
          i18nKey: "catalog.selection.delete.sure",
          children: "Are you sure you want to remove {{count}} images and/or folders?",
          count: count
        }) : null;
  var content$1 = count > 1 ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx(DialogContentText, {
                  children: Caml_option.some(content)
                }),
            JsxRuntime.jsx(DialogContentText, {
                  children: Caml_option.some(JsxRuntime.jsxs(ReactI18next.Trans, {
                            i18nKey: "catalog.selection.delete.enter-delete",
                            children: [
                              "To proceed you must enter the word ",
                              JsxRuntime.jsx("strong", {
                                    children: "DELETE"
                                  }),
                              " below."
                            ]
                          }))
                })
          ]
        }) : content;
  var match = ReactI18next.useTranslation();
  var confirmMessage = count > 1 ? match.t("DELETE") : undefined;
  return JsxRuntime.jsx(ConfirmDeleteDialog.make, {
              open_: true,
              content: Caml_option.some(content$1),
              onCancel: props.onCancel,
              onDelete: props.onDelete,
              confirmMessage: confirmMessage,
              fullWidth: true,
              maxWidth: "sm"
            });
}

function BulkRemove$RemovingDialog(props) {
  var $$delete = props.delete;
  var onDone = props.onDone;
  var items = props.items;
  var total = items.length;
  var match = React.useState(function () {
        
      });
  var setWorking = match[1];
  var working = match[0];
  var match$1 = React.useState(function () {
        return Belt_List.fromArray(items);
      });
  var setPending = match$1[1];
  var pending = match$1[0];
  var match$2 = React.useState(function () {
        return [];
      });
  var setResults = match$2[1];
  var results = match$2[0];
  var trashFolder = RemoveFolderService.useTrash();
  var removeFolder = RemoveFolderService.useRemove();
  var trashDocument = RemoveDocumentService.useTrash();
  var removeDocument = RemoveDocumentService.useRemove();
  React.useEffect((function () {
          if (working === undefined && pending) {
            var match = pending.hd;
            if (match.NAME === "document") {
              var others = pending.tl;
              var d = match.VAL;
              if ($$delete === "trash") {
                Prelude.OptionExported.$$Option.$$do(trashDocument, (function (trashDocument) {
                        var promise = Prelude.PromisedResult.mapError(Prelude.PromisedResult.map(trashDocument(d), FolderItem.fromDocument), (function (e) {
                                return [
                                        {
                                          NAME: "document",
                                          VAL: d
                                        },
                                        e
                                      ];
                              }));
                        setWorking(function (param) {
                              return Caml_option.some(promise);
                            });
                        setPending(function (param) {
                              return others;
                            });
                      }));
              } else {
                Prelude.OptionExported.$$Option.$$do(removeDocument, (function (removeDocument) {
                        var promise = Prelude.PromisedResult.mapError(Prelude.PromisedResult.map(removeDocument(d), (function () {
                                    return {
                                            NAME: "document",
                                            VAL: d
                                          };
                                  })), (function (e) {
                                return [
                                        {
                                          NAME: "document",
                                          VAL: d
                                        },
                                        e
                                      ];
                              }));
                        setWorking(function (param) {
                              return Caml_option.some(promise);
                            });
                        setPending(function (param) {
                              return others;
                            });
                      }));
              }
            } else {
              var others$1 = pending.tl;
              var f = match.VAL;
              if ($$delete === "trash") {
                Prelude.OptionExported.$$Option.$$do(trashFolder, (function (trashFolder) {
                        var promise = Prelude.PromisedResult.mapError(Prelude.PromisedResult.map(trashFolder(f), FolderItem.fromFolder), (function (e) {
                                return [
                                        {
                                          NAME: "folder",
                                          VAL: f
                                        },
                                        e
                                      ];
                              }));
                        setWorking(function (param) {
                              return Caml_option.some(promise);
                            });
                        setPending(function (param) {
                              return others$1;
                            });
                      }));
              } else {
                Prelude.OptionExported.$$Option.$$do(removeFolder, (function (removeFolder) {
                        var promise = Prelude.PromisedResult.mapError(Prelude.PromisedResult.map(removeFolder(f), (function () {
                                    return {
                                            NAME: "folder",
                                            VAL: f
                                          };
                                  })), (function (e) {
                                return [
                                        {
                                          NAME: "folder",
                                          VAL: f
                                        },
                                        e
                                      ];
                              }));
                        setWorking(function (param) {
                              return Caml_option.some(promise);
                            });
                        setPending(function (param) {
                              return others$1;
                            });
                      }));
              }
            }
          }
          
        }), [
        working,
        pending
      ]);
  React.useEffect((function () {
          if (working !== undefined) {
            Prelude.thenDo(Prelude.thenResolve(Caml_option.valFromOption(working), (function (res) {
                        if (res.TAG === "Ok") {
                          var item = res._0;
                          return setResults(function (param) {
                                      return Prelude.$$Array.append(results, [
                                                  item,
                                                  "removed"
                                                ]);
                                    });
                        }
                        var item$1 = res._0[0];
                        setResults(function (param) {
                              return Prelude.$$Array.append(results, [
                                          item$1,
                                          "failed"
                                        ]);
                            });
                      })), (function () {
                    setWorking(function (param) {
                          
                        });
                  }));
          }
          
        }), [working]);
  React.useEffect((function () {
          if (working !== undefined || pending) {
            
          } else {
            onDone();
          }
        }), [
        working,
        pending
      ]);
  var progress = React.useMemo((function () {
          var done = results.length;
          if (total > 0) {
            return Caml_int32.div(Math.imul(done, 100), total);
          } else {
            return 0;
          }
        }), [results]);
  var onClose = function (param, param$1) {
    
  };
  return JsxRuntime.jsxs(Dialog, {
              disableEscapeKeyDown: true,
              open: true,
              children: [
                JsxRuntime.jsx(DialogTitle, {
                      children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                msg: {
                                  NAME: "str",
                                  VAL: "Removing..."
                                }
                              }))
                    }),
                JsxRuntime.jsxs(DialogContent, {
                      children: [
                        JsxRuntime.jsx(DialogContentText, {
                              children: Caml_option.some(JsxRuntime.jsx(LinearProgress, {
                                        value: progress,
                                        variant: "determinate"
                                      }))
                            }),
                        JsxRuntime.jsx(DialogContentText, {
                              children: Caml_option.some(String(progress) + "%"),
                              sx: {
                                textAlign: "center"
                              }
                            })
                      ]
                    })
              ],
              fullWidth: true,
              maxWidth: "sm",
              onClose: onClose
            });
}

var canChangeDocuments = UserPermission.Query.compile({
      TAG: "All",
      _0: ["catalog.change_document"]
    });

var canChangeFolders = UserPermission.Query.compile({
      TAG: "All",
      _0: ["catalog.change_folder"]
    });

function BulkRemove(props) {
  var __delete = props.delete;
  var $$delete = __delete !== undefined ? __delete : "trash";
  var match = React.useState(function () {
        return "Idle";
      });
  var setState = match[1];
  var state = match[0];
  var begin = CurrentFolderManager.useBeginTransaction();
  var commit = CurrentFolderManager.useCommit();
  var getCurrentFolderSelection = SelectionManager.Folders.useGetCurrentSelection();
  var getCurrentFolderSelection$1 = React.useMemo((function () {
          return Prelude.default(getCurrentFolderSelection, (function () {
                        return FolderId.$$Map.make();
                      }));
        }), [getCurrentFolderSelection]);
  var getCurrentDocumentSelection = SelectionManager.Documents.useGetCurrentSelection();
  var getCurrentDocumentSelection$1 = React.useMemo((function () {
          return Prelude.default(getCurrentDocumentSelection, (function () {
                        return DocumentId.$$Map.make();
                      }));
        }), [getCurrentDocumentSelection]);
  var perms = UserContext.useUserPermissionsClaim();
  var removeEnabled = RemoveDocumentService.available() && (canChangeDocuments(perms) || DocumentId.$$Map.isEmpty(getCurrentDocumentSelection$1())) && (canChangeFolders(perms) || FolderId.$$Map.isEmpty(getCurrentFolderSelection$1()));
  React.useEffect((function () {
          if (typeof state !== "object" && state === "Triggered") {
            var items = Curry._2(Prelude.$$Array.concat, DocumentId.$$Map.valuesToArray(getCurrentDocumentSelection$1()).map(FolderItem.fromDocument), FolderId.$$Map.valuesToArray(getCurrentFolderSelection$1()).map(FolderItem.fromFolder));
            setState(function (param) {
                  return {
                          TAG: "Confirmation",
                          _0: items
                        };
                });
          }
          
        }), [
        state,
        getCurrentDocumentSelection$1,
        getCurrentFolderSelection$1
      ]);
  var beginDocumentSelection = SelectionManager.Documents.useBegin();
  var commitDocumentSelection = SelectionManager.Documents.useCommit();
  var beginFolderSelection = SelectionManager.Folders.useBegin();
  var commitFolderSelection = SelectionManager.Folders.useCommit();
  var onCancel = function () {
    setState(function (param) {
          return "Idle";
        });
  };
  var onDoneRemoving = React.useCallback((function () {
          commit();
          Prelude.OptionExported.$$Option.$$do(commitDocumentSelection, (function (fn) {
                  fn();
                }));
          Prelude.OptionExported.$$Option.$$do(commitFolderSelection, (function (fn) {
                  fn();
                }));
          setState(function (param) {
                return "Idle";
              });
        }), [
        commit,
        commitDocumentSelection,
        commitFolderSelection
      ]);
  var onDeleteConfirmed = React.useCallback((function () {
          if (typeof state !== "object") {
            return ;
          }
          if (state.TAG !== "Confirmation") {
            return ;
          }
          var items = state._0;
          begin();
          Prelude.OptionExported.$$Option.$$do(beginDocumentSelection, (function (fn) {
                  fn();
                }));
          Prelude.OptionExported.$$Option.$$do(beginFolderSelection, (function (fn) {
                  fn();
                }));
          setState(function (param) {
                return {
                        TAG: "Removing",
                        _0: items
                      };
              });
        }), [
        state,
        begin,
        beginDocumentSelection,
        beginFolderSelection
      ]);
  if (!removeEnabled) {
    return null;
  }
  var tmp;
  tmp = typeof state !== "object" ? null : (
      state.TAG === "Confirmation" ? JsxRuntime.jsx(BulkRemove$ConfirmDialog, {
              items: state._0,
              onCancel: onCancel,
              onDelete: onDeleteConfirmed
            }) : JsxRuntime.jsx(BulkRemove$RemovingDialog, {
              items: state._0,
              onDone: onDoneRemoving,
              delete: $$delete
            })
    );
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Fab, {
                            children: Caml_option.some($$delete === "trash" ? JsxRuntime.jsx(DeleteOutlined, {}) : JsxRuntime.jsx(DeleteForever, {})),
                            color: "error",
                            size: "small"
                          }),
                      onClick: (function (param) {
                          setState(function (param) {
                                return "Triggered";
                              });
                        })
                    }),
                tmp
              ]
            });
}

var make = BulkRemove;

export {
  make ,
}
/* canChangeDocuments Not a pure module */
