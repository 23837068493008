// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FoldersGrid from "./FoldersGrid.js";
import * as ReactI18Next from "../../../../libs/i18n/ReactI18Next.js";
import * as DocumentsGrid from "./DocumentsGrid.js";
import * as JsxRuntime from "react/jsx-runtime";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

function FolderBrowserCardsView(props) {
  var subfolders = Prelude.default(props.subfolders, []);
  var documents = Prelude.default(props.documents, []);
  var empty = (subfolders.length + documents.length | 0) === 0;
  if (empty) {
    return JsxRuntime.jsx(Stack, {
                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                          align: "center",
                          variant: "body1",
                          children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                    msg: {
                                      NAME: "msg",
                                      VAL: [
                                        "catalog.empty",
                                        "This folder is empty"
                                      ]
                                    }
                                  }))
                        })),
                direction: ["column"],
                spacing: "1.5rem"
              });
  } else {
    return JsxRuntime.jsxs(Stack, {
                children: [
                  subfolders.length !== 0 ? JsxRuntime.jsx(FoldersGrid.make, {
                          subfolders: subfolders
                        }) : null,
                  documents.length !== 0 ? JsxRuntime.jsx(DocumentsGrid.make, {
                          documents: documents
                        }) : null
                ],
                direction: ["column"],
                spacing: "1.5rem"
              });
  }
}

var make = FolderBrowserCardsView;

export {
  make ,
}
/* FoldersGrid Not a pure module */
