// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as FolderId from "../models/FolderId.js";
import * as DocumentId from "../models/DocumentId.js";
import * as JsxRuntime from "react/jsx-runtime";

var context = React.createContext(undefined);

var make = context.Provider;

function useLinkBuilder() {
  return React.useContext(context);
}

function DocumentFinderNavigator(props) {
  var path = props.path;
  var value = function (d) {
    return path + "/" + FolderId.toString(d.folder) + "#" + DocumentId.toString(d.id);
  };
  return JsxRuntime.jsx(make, {
              value: value,
              children: props.children
            });
}

var make$1 = DocumentFinderNavigator;

export {
  useLinkBuilder ,
  make$1 as make,
}
/* context Not a pure module */
