// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Settings from "../../../Settings.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../../libs/i18n/ReactI18Next.js";
import * as JsxRuntime from "react/jsx-runtime";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";

function PhotocubaInfoTable(props) {
  var hash = Settings.releaseHash.trim();
  var tmp = hash === "" ? null : " (" + hash + ")";
  return JsxRuntime.jsx(TableContainer, {
              children: Caml_option.some(JsxRuntime.jsx(Table, {
                        children: Caml_option.some(JsxRuntime.jsxs(TableBody, {
                                  children: [
                                    JsxRuntime.jsxs(TableRow, {
                                          children: [
                                            JsxRuntime.jsx(TableCell, {
                                                  variant: "head",
                                                  children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                            msg: {
                                                              NAME: "str",
                                                              VAL: "Name"
                                                            }
                                                          }))
                                                }),
                                            JsxRuntime.jsx(TableCell, {
                                                  children: "Photocuba"
                                                })
                                          ]
                                        }),
                                    JsxRuntime.jsxs(TableRow, {
                                          children: [
                                            JsxRuntime.jsx(TableCell, {
                                                  variant: "head",
                                                  children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                            msg: {
                                                              NAME: "str",
                                                              VAL: "Version"
                                                            }
                                                          }))
                                                }),
                                            JsxRuntime.jsxs(TableCell, {
                                                  children: [
                                                    Settings.releaseVersion,
                                                    tmp
                                                  ]
                                                })
                                          ]
                                        }),
                                    JsxRuntime.jsxs(TableRow, {
                                          children: [
                                            JsxRuntime.jsx(TableCell, {
                                                  variant: "head",
                                                  children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                            msg: {
                                                              NAME: "str",
                                                              VAL: "Date"
                                                            }
                                                          }))
                                                }),
                                            JsxRuntime.jsx(TableCell, {
                                                  children: Caml_option.some(Settings.releaseDate.toLocaleString())
                                                })
                                          ]
                                        })
                                  ]
                                })),
                        size: "medium"
                      }))
            });
}

var make = PhotocubaInfoTable;

export {
  make ,
}
/* Settings Not a pure module */
