// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as FolderId from "../../models/FolderId.js";
import * as UserContext from "../../../../global/context/UserContext.js";
import * as UserPermission from "../../../../global/models/UserPermission.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as SharedDBContext from "../../../../global/context/SharedDBContext.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CatalogAPIEndpoint from "../../api/CatalogAPIEndpoint.js";
import * as CurrentFolderManager from "../CurrentFolderManager.js";

var context = React.createContext(undefined);

var make = context.Provider;

var NotFound = /* @__PURE__ */Caml_exceptions.create("CreateFolderService.NotFound");

function useCreator() {
  return React.useContext(context);
}

function useCanCreate() {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                    return true;
                  })), false);
}

var canCreateFolder = UserPermission.Query.compile({
      TAG: "All",
      _0: ["catalog.change_folder"]
    });

function CreateFolderService(props) {
  CurrentFolderManager.ensureCurrentFolderManager();
  var addFolder = CurrentFolderManager.useUpdateOrAddFolder();
  var token = Curry._1(Prelude.OptionExported.$$Option.getExn, UserContext.useAccessToken());
  var perms = UserContext.useUserPermissionsClaim();
  var match = SharedDBContext.useQuery();
  var write = match.write;
  var makeWrite = match.makeWrite;
  var create = React.useMemo((function () {
          if (canCreateFolder(perms)) {
            return (async function (folder, name) {
                      var e = await CatalogAPIEndpoint.CreateFolder.$$do(folder, FolderId.make(), name, token);
                      var result;
                      if (e.TAG === "Ok") {
                        var f = e._0;
                        if (f !== undefined) {
                          addFolder(f);
                          result = {
                            TAG: "Ok",
                            _0: f
                          };
                        } else {
                          result = await Prelude.rejectWithError({
                                RE_EXN_ID: NotFound,
                                code: 404,
                                message: "Not found"
                              });
                        }
                      } else {
                        result = {
                          TAG: "Error",
                          _0: e._0
                        };
                      }
                      if (result.TAG !== "Ok") {
                        return {
                                TAG: "Error",
                                _0: result._0
                              };
                      }
                      var folder$1 = result._0;
                      var init = makeWrite();
                      await write({
                            documents: init.documents,
                            folders: [{
                                TAG: "Save",
                                _0: folder$1
                              }],
                            bundlers: init.bundlers
                          });
                      return {
                              TAG: "Ok",
                              _0: folder$1
                            };
                    });
          }
          
        }), [
        token,
        makeWrite,
        write,
        addFolder,
        perms
      ]);
  return JsxRuntime.jsx(make, {
              value: create,
              children: props.children
            });
}

var make$1 = CreateFolderService;

export {
  useCreator ,
  useCanCreate ,
  make$1 as make,
}
/* context Not a pure module */
