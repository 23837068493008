// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$URL from "./URL.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Utils from "./Utils.js";
import * as Network from "./Network.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IDB__Migration__Database from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Database.js";

var UUID = Utils.MakeOpaqueIdentifier({});

function migrations() {
  return [
          (function (param) {
              return function (db, transaction) {
                IDB__Migration__Database.createObjectStore(db, "metadatas");
                IDB__Migration__Database.createObjectStore(db, "resources");
                transaction.objectStore("metadatas").createIndex("file", "file");
                transaction.objectStore("resources").createIndex("url", "url");
                return Promise.resolve({
                            TAG: "Ok",
                            _0: undefined
                          });
              };
            }),
          (function (param) {
              return function (_db, transaction) {
                transaction.objectStore("metadatas").createIndex("access_time", "access_time");
                return Promise.resolve({
                            TAG: "Ok",
                            _0: undefined
                          });
              };
            }),
          (function (param) {
              return function (_db, transaction) {
                transaction.objectStore("resources").deleteIndex("url");
                return Promise.resolve({
                            TAG: "Ok",
                            _0: undefined
                          });
              };
            })
        ];
}

var Database = ReIndexed.MakeDatabase({
      migrations: migrations
    });

var MetadataDef = {};

ReIndexed.MakeModel(MetadataDef);

var ResourceDef = {};

ReIndexed.MakeModel(ResourceDef);

function makeRead() {
  return {
          metadatas: "NoOp",
          resources: "NoOp"
        };
}

function makeWrite() {
  return {
          metadatas: [],
          resources: []
        };
}

function makeResponse() {
  return {
          metadatas: [],
          resources: []
        };
}

var QueryDef = {
  makeRead: makeRead,
  makeWrite: makeWrite,
  makeResponse: makeResponse
};

var Query = Curry._1(Database.MakeQuery, QueryDef);

function deleteMany(ids) {
  console.log("LocalFile", "DELETE", ids);
  return Query.write({
              metadatas: ids.map(function (id) {
                    return {
                            TAG: "Delete",
                            _0: UUID.toString(id)
                          };
                  }),
              resources: ids.map(function (id) {
                    return {
                            TAG: "Delete",
                            _0: UUID.toString(id)
                          };
                  })
            });
}

async function allIds() {
  var init = Query.makeRead();
  var match = await Query.read({
        metadatas: "All",
        resources: init.resources
      });
  return match.metadatas.map(function (meta) {
              return meta.id;
            });
}

async function clean(sureOpt, nonlocalOpt, param) {
  var sure = sureOpt !== undefined ? sureOpt : false;
  var nonlocal = nonlocalOpt !== undefined ? nonlocalOpt : true;
  if (!sure) {
    return ;
  }
  if (nonlocal) {
    var init = Query.makeRead();
    var match = await Query.read({
          metadatas: {
            TAG: "NotNull",
            _0: "file"
          },
          resources: init.resources
        });
    await deleteMany(match.metadatas.map(function (meta) {
              return meta.id;
            }));
    return ;
  }
  await Query.write({
        metadatas: ["Clear"],
        resources: ["Clear"]
      });
}

async function getWithMetadata(id) {
  var access_time = new Date();
  var match = await Query.read({
        metadatas: {
          TAG: "Get",
          _0: UUID.toString(id)
        },
        resources: {
          TAG: "Get",
          _0: UUID.toString(id)
        }
      });
  var metadatas = match.metadatas;
  var init = Query.makeWrite();
  await Query.write({
        metadatas: metadatas.map(function (m) {
              var newrecord = Caml_obj.obj_dup(m);
              return {
                      TAG: "Save",
                      _0: (newrecord.access_time = Caml_option.some(access_time), newrecord)
                    };
            }),
        resources: init.resources
      });
  return [
          Prelude.$$Array.first(metadatas),
          Prelude.$$Array.first(match.resources)
        ];
}

async function getManyResources(ids) {
  var match = await Query.read({
        metadatas: {
          TAG: "In",
          _0: UUID.manyToString(ids)
        },
        resources: {
          TAG: "In",
          _0: UUID.manyToString(ids)
        }
      });
  var access_time = new Date();
  var updates = match.metadatas.map(function (m) {
        var newrecord = Caml_obj.obj_dup(m);
        return {
                TAG: "Save",
                _0: (newrecord.access_time = Caml_option.some(access_time), newrecord)
              };
      });
  var init = Query.makeWrite();
  await Query.write({
        metadatas: updates,
        resources: init.resources
      });
  return match.resources;
}

async function getResource(id) {
  var resources = await getManyResources([id]);
  return Prelude.$$Array.first(resources);
}

async function store(id, url, compressedOpt, blob) {
  var compressed = compressedOpt !== undefined ? compressedOpt : false;
  var url$1 = Curry._2(Prelude.OptionExported.$$Option.map, url, $$URL.Utils.withoutSearch);
  var id$1 = id !== undefined ? Caml_option.valFromOption(id) : UUID.make();
  console.log("LocalFile", "STORE", {
        id: id$1,
        size: blob.size,
        url: url$1,
        compressed: compressed
      });
  var resource = {
    id: id$1,
    file: blob
  };
  var access_time = new Date();
  var match = await Query.write({
        metadatas: [{
            TAG: "Save",
            _0: {
              id: id$1,
              size: blob.size,
              compressed: compressed,
              file: url$1,
              access_time: Caml_option.some(access_time)
            }
          }],
        resources: [{
            TAG: "Save",
            _0: resource
          }]
      });
  var metadatas = match.metadatas;
  if (metadatas.length === 1) {
    var metadata = metadatas[0];
    var $$window$1 = window;
    var $$event = new Event("local-file:store", {
          id: metadata.id,
          size: metadata.size,
          compressed: metadata.compressed,
          file: metadata.file
        });
    $$window$1.dispatchEvent($$event);
  }
  return resource;
}

async function clone(key) {
  var match = await getWithMetadata(key);
  var resource = match[1];
  var metadata = match[0];
  if (metadata === undefined) {
    return ;
  }
  if (resource === undefined) {
    return ;
  }
  var url = metadata.file;
  var compressed = metadata.compressed;
  var resource$1 = await store(undefined, url, compressed, resource.file);
  return Caml_option.some(resource$1.id);
}

async function updateFileURL(id, file) {
  var url = $$URL.Utils.withoutSearch(file);
  console.log("LocalFile: Updated URL", id, "->", url);
  var init = Query.makeRead();
  var match = await Query.read({
        metadatas: {
          TAG: "Get",
          _0: UUID.toString(id)
        },
        resources: init.resources
      });
  var metadatas = match.metadatas;
  var access_time = new Date();
  if (metadatas.length !== 1) {
    return ;
  }
  var metadata = metadatas[0];
  var init$1 = Query.makeWrite();
  var newrecord = Caml_obj.obj_dup(metadata);
  await Query.write({
        metadatas: [{
            TAG: "Save",
            _0: (newrecord.access_time = Caml_option.some(access_time), newrecord.file = Caml_option.some(file), newrecord)
          }],
        resources: init$1.resources
      });
  return Caml_option.some(id);
}

async function downloadRequest(id, request, compressed) {
  var url = $$URL.Utils.withoutSearch(request.url);
  var found = await getResource(id);
  if (found !== undefined) {
    return found;
  }
  var payload = await Prelude.PromisedResult.warn(Network.downloadRequest(request));
  if (payload !== undefined) {
    return await store(Caml_option.some(id), Caml_option.some(url), compressed, Caml_option.valFromOption(payload));
  }
  
}

function download(id, url, compressed) {
  return downloadRequest(id, Network.$$Request.make(url), compressed);
}

async function compressWith(key, fn) {
  var match = await getWithMetadata(key);
  var resource = match[1];
  var meta = match[0];
  if (resource === undefined) {
    return ;
  }
  if (meta !== undefined) {
    if (meta.compressed) {
      return resource;
    }
    var blob = await Prelude.PromisedResult.mapWithDefault(fn(resource.file), resource.file, (function (prim) {
            return prim;
          }));
    await store(Caml_option.some(key), meta.file, true, blob);
    return {
            id: key,
            file: blob
          };
  }
  var blob$1 = await Prelude.PromisedResult.mapWithDefault(fn(resource.file), resource.file, (function (prim) {
          return prim;
        }));
  await store(Caml_option.some(key), undefined, true, blob$1);
  return {
          id: key,
          file: blob$1
        };
}

var Database_disconnect = Database.disconnect;

var Database_drop = Database.drop;

var Database_connect = Database.connect;

var Database$1 = {
  disconnect: Database_disconnect,
  drop: Database_drop,
  connect: Database_connect
};

var Metadata = {};

var Resource = {};

var Query$1 = {};

export {
  UUID ,
  Database$1 as Database,
  MetadataDef ,
  Metadata ,
  ResourceDef ,
  Resource ,
  Query$1 as Query,
  deleteMany ,
  allIds ,
  clean ,
  getResource ,
  store ,
  updateFileURL ,
  downloadRequest ,
  download ,
  compressWith ,
  clone ,
}
/* UUID Not a pure module */
