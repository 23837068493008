// Generated by ReScript, PLEASE EDIT WITH CARE


function clickLink(src, newtab) {
  var anchor = document.createElement("a");
  anchor.href = src;
  if (newtab) {
    anchor.target = "_blank";
  }
  anchor.click();
}

export {
  clickLink ,
}
/* No side effect */
