// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as FolderId from "./FolderId.js";
import * as Attribute from "./Attribute.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var Definition = {};

var include = ReIndexed.MakeIdModel(Definition, {});

function getFiles(param) {
  return [];
}

function locked(folder) {
  var l = folder._locked;
  if (l !== undefined) {
    return l;
  } else if (Curry._1(Prelude.OptionExported.$$Option.isNone, folder.parent)) {
    return true;
  } else {
    return false;
  }
}

function fromBackend(node) {
  var res_id = node.id;
  var res_namespace = node.namespace;
  var res_name = node.name;
  var res_path = node.path;
  var res_parent = node.parent;
  var res_created_at = node.created_at;
  var res_updated_at = node.updated_at;
  var res_attributes = Prelude.$$Array.flatMap(node.attributes, Attribute.PayloadOutput.map);
  var res_size = node.size;
  var res_items = node.items;
  var res__locked = node.locked;
  var res_description = node.description;
  var res = {
    id: res_id,
    namespace: res_namespace,
    name: res_name,
    path: res_path,
    parent: res_parent,
    created_at: res_created_at,
    updated_at: res_updated_at,
    attributes: res_attributes,
    size: res_size,
    items: res_items,
    _locked: res__locked,
    description: res_description
  };
  var last_time_estimated = node.last_time_estimated;
  if (last_time_estimated === undefined) {
    return res;
  }
  var newrecord = Caml_obj.obj_dup(res);
  newrecord.last_time_estimated = Caml_option.some(Caml_option.valFromOption(last_time_estimated));
  return newrecord;
}

function isDescendantOf(which, parent) {
  return which.path.substr(0, parent.path.length) === parent.path;
}

function clone(folder) {
  return Object.assign({}, folder);
}

var _map = {"name":"name","created_at":"created_at","updated_at":"updated_at","size":"size"};

function fieldToJs(param) {
  return param;
}

function fieldFromJs(param) {
  return _map[param];
}

var _map$1 = {"asc":"asc","desc":"desc"};

function directionToJs(param) {
  return param;
}

function directionFromJs(param) {
  return _map$1[param];
}

function sort(direction, field) {
  return function (folders) {
    var result = field === "size" ? Prelude.$$Array.sort(folders, (function (folder) {
              return folder.items;
            }), undefined) : (
        field === "created_at" ? Prelude.$$Array.sort(folders, (function (folder) {
                  return folder.created_at.valueOf();
                }), undefined) : (
            field === "updated_at" ? Prelude.$$Array.sort(folders, (function (folder) {
                      return folder.updated_at.valueOf();
                    }), undefined) : Prelude.$$Array.sort(folders, (function (folder) {
                      return folder.name.toLocaleLowerCase();
                    }), undefined)
          )
      );
    if (direction === "asc") {
      return result;
    } else {
      return Curry._1(Prelude.$$Array.reverse, result);
    }
  };
}

var Order = {
  fieldToJs: fieldToJs,
  fieldFromJs: fieldFromJs,
  directionToJs: directionToJs,
  directionFromJs: directionFromJs,
  sort: sort
};

var Identifier;

var IdComparator = include.IdComparator;

var Id = include.Id;

export {
  Identifier ,
  Definition ,
  IdComparator ,
  Id ,
  getFiles ,
  locked ,
  fromBackend ,
  isDescendantOf ,
  clone ,
  Order ,
}
/* include Not a pure module */
