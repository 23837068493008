// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ProtectedArea from "../../../../utils/ProtectedArea.js";
import * as JsxRuntime from "react/jsx-runtime";

var _map = {"description":"description","authoring":"authoring"};

function tabToJs(param) {
  return param;
}

function tabFromJs(param) {
  return _map[param];
}

function DocumentBrowserTab(props) {
  var index = props.index;
  var value = props.value;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(ProtectedArea.make, {
                    requirement: {
                      TAG: "Pred",
                      _0: value === index
                    },
                    children: props.children
                  }),
              "aria-labelledby": "simple-tab-" + index,
              hidden: value !== index,
              id: "simple-tabpanel-" + index,
              role: "tabpanel",
              style: {
                padding: "0.75rem 0"
              }
            });
}

var make = DocumentBrowserTab;

export {
  tabToJs ,
  tabFromJs ,
  make ,
}
/* ProtectedArea Not a pure module */
