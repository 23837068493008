// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReactRouterDom from "react-router-dom";
import * as JsxRuntime from "react/jsx-runtime";
import * as CollectionsManagerView from "./CollectionsManagerView.js";
import * as CollectionsExplorerView from "./CollectionsExplorerView.js";
import * as CatalogBrowserViewContext from "../catalog/context/CatalogBrowserViewContext.js";

function CollectionsManagerRouter(props) {
  return JsxRuntime.jsxs(ReactRouterDom.Routes, {
              children: [
                JsxRuntime.jsx(ReactRouterDom.Route, {
                      path: "/",
                      element: JsxRuntime.jsx(CollectionsManagerView.make, {})
                    }),
                JsxRuntime.jsx(ReactRouterDom.Route, {
                      path: "/explore/:collection_data",
                      element: JsxRuntime.jsx(CatalogBrowserViewContext.make, {
                            children: JsxRuntime.jsx(CollectionsExplorerView.make, {})
                          })
                    })
              ]
            });
}

var make = CollectionsManagerRouter;

export {
  make ,
}
/* react-router-dom Not a pure module */
