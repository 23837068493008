// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as FolderId from "../../models/FolderId.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RenameDialog from "../../../../global/components/RenameDialog.js";
import * as ReactI18next from "react-i18next";
import * as JsxRuntime from "react/jsx-runtime";
import * as UpdateFolderInfoService from "../../context/services/UpdateFolderInfoService.js";

function RenameFolderDialog(props) {
  var onClose = props.onClose;
  var onDone = props.onDone;
  var folder = props.folder;
  var match = React.useState(function () {
        return {
                TAG: "Init",
                _0: folder
              };
      });
  var setState = match[1];
  var state = match[0];
  var rename = UpdateFolderInfoService.useRename();
  var onChange = React.useCallback((function (name) {
          if (rename === undefined) {
            return ;
          }
          if (typeof state !== "object") {
            return ;
          }
          if (state.TAG !== "Init") {
            return ;
          }
          var folder = state._0;
          setState(function (param) {
                return {
                        TAG: "Renaming",
                        _0: folder,
                        _1: name
                      };
              });
        }), [
        state,
        rename
      ]);
  React.useEffect((function () {
          if (rename !== undefined && typeof state === "object" && state.TAG !== "Init") {
            Prelude.thenDo(rename(state._0, state._1), (function (param) {
                    onDone();
                    setState(function (param) {
                          return "Done";
                        });
                  }));
          }
          
        }), [
        rename,
        state
      ]);
  if (typeof state !== "object") {
    return null;
  }
  if (state.TAG !== "Init") {
    return null;
  }
  var folder$1 = state._0;
  return JsxRuntime.jsx(RenameDialog.make, {
              name: folder$1.name,
              onChange: onChange,
              onClose: (function () {
                  onClose();
                  setState(function (param) {
                        return "Done";
                      });
                }),
              open_: true,
              title: {
                NAME: "str",
                VAL: "Rename folder"
              },
              content: Caml_option.some(JsxRuntime.jsx(ReactI18next.Trans, {
                        children: "Enter the new name of the folder"
                      })),
              label: {
                NAME: "str",
                VAL: "Folder name"
              }
            }, FolderId.toString(folder$1.id));
}

var make = RenameFolderDialog;

export {
  make ,
}
/* react Not a pure module */
