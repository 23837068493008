// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../../../libs/i18n/ReactI18Next.js";
import * as JsxRuntime from "react/jsx-runtime";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as DocumentBrowserLabelText from "./DocumentBrowserLabelText.js";

function DocumentBrowserChipsField(props) {
  var items = props.items;
  var name = props.name;
  return React.useMemo((function () {
                if (items.length !== 0) {
                  return JsxRuntime.jsxs(Stack, {
                              children: [
                                JsxRuntime.jsx(DocumentBrowserLabelText.make, {
                                      name: name
                                    }),
                                JsxRuntime.jsx(Stack, {
                                      children: Caml_option.some(items.map(function (item) {
                                                var label = item.name;
                                                var key = item.id;
                                                return JsxRuntime.jsx(Chip, {
                                                            label: Caml_option.some(label),
                                                            size: "small"
                                                          }, key);
                                              })),
                                      direction: ["row"],
                                      spacing: "0.5rem",
                                      sx: {
                                        alignItems: "center",
                                        flexWrap: "wrap"
                                      },
                                      useFlexGap: true
                                    })
                              ],
                              spacing: "0.25rem",
                              useFlexGap: true
                            });
                } else {
                  return JsxRuntime.jsxs(Stack, {
                              children: [
                                JsxRuntime.jsx(DocumentBrowserLabelText.make, {
                                      name: name
                                    }),
                                JsxRuntime.jsx(Typography, {
                                      variant: "body2",
                                      children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                msg: {
                                                  NAME: "str",
                                                  VAL: "None"
                                                }
                                              })),
                                      sx: {
                                        fontStyle: "italic"
                                      }
                                    })
                              ],
                              spacing: "0.25rem",
                              useFlexGap: true
                            });
                }
              }), [items]);
}

var make = DocumentBrowserChipsField;

export {
  make ,
}
/* react Not a pure module */
