// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

function up(space) {
  var variant = space.NAME;
  if (variant === "kilo") {
    return {
            NAME: "mega",
            VAL: space.VAL / 1024.0
          };
  } else if (variant === "mega") {
    return {
            NAME: "giga",
            VAL: space.VAL / 1024.0
          };
  } else if (variant === "bytes") {
    return {
            NAME: "kilo",
            VAL: space.VAL / 1024.0
          };
  } else {
    return {
            NAME: "giga",
            VAL: space.VAL
          };
  }
}

function down(space) {
  var variant = space.NAME;
  if (variant === "kilo") {
    return {
            NAME: "bytes",
            VAL: space.VAL * 1024.0
          };
  } else if (variant === "mega") {
    return {
            NAME: "kilo",
            VAL: space.VAL * 1024.0
          };
  } else if (variant === "bytes") {
    return {
            NAME: "bytes",
            VAL: space.VAL
          };
  } else {
    return {
            NAME: "mega",
            VAL: space.VAL * 1024.0
          };
  }
}

var CannotExtract = /* @__PURE__ */Caml_exceptions.create("Units.CannotExtract");

function uppable(a) {
  var variant = a.NAME;
  if (variant === "bytes" || variant === "mega") {
    return true;
  } else {
    return variant === "kilo";
  }
}

function extract(b) {
  if (typeof b === "object") {
    if (b.NAME === "bytes") {
      return b.VAL;
    }
    throw {
          RE_EXN_ID: CannotExtract,
          _1: b,
          Error: new Error()
        };
  }
  throw {
        RE_EXN_ID: CannotExtract,
        _1: b,
        Error: new Error()
      };
}

function extractUnsafe(b) {
  return b.VAL;
}

function toBytes(a) {
  return down(down(down(a)));
}

function toBest(a) {
  var a$1 = toBytes(a);
  while(a$1.VAL >= 1024.0 && uppable(a$1)) {
    a$1 = up(a$1);
  };
  return a$1;
}

function syncUp(a, b) {
  var a$1 = toBytes(a);
  var b$1 = toBytes(b);
  if (extract(a$1) <= extract(b$1)) {
    while(a$1.VAL >= 1024.0 && uppable(a$1)) {
      a$1 = up(a$1);
      b$1 = up(b$1);
    };
  } else {
    while(b$1.VAL >= 1024.0 && uppable(b$1)) {
      a$1 = up(a$1);
      b$1 = up(b$1);
    };
  }
  var match = a$1;
  var match$1 = b$1;
  var variant = match.NAME;
  if (variant === "kilo") {
    if (typeof match$1 === "object") {
      if (match$1.NAME !== "kilo") {
        throw {
              RE_EXN_ID: Prelude.AssertionError,
              _1: "Should be the same unit",
              Error: new Error()
            };
      }
      
    } else {
      throw {
            RE_EXN_ID: Prelude.AssertionError,
            _1: "Should be the same unit",
            Error: new Error()
          };
    }
  } else if (variant === "mega") {
    if (typeof match$1 === "object") {
      if (match$1.NAME !== "mega") {
        throw {
              RE_EXN_ID: Prelude.AssertionError,
              _1: "Should be the same unit",
              Error: new Error()
            };
      }
      
    } else {
      throw {
            RE_EXN_ID: Prelude.AssertionError,
            _1: "Should be the same unit",
            Error: new Error()
          };
    }
  } else if (variant === "bytes") {
    if (typeof match$1 === "object") {
      if (match$1.NAME !== "bytes") {
        throw {
              RE_EXN_ID: Prelude.AssertionError,
              _1: "Should be the same unit",
              Error: new Error()
            };
      }
      
    } else {
      throw {
            RE_EXN_ID: Prelude.AssertionError,
            _1: "Should be the same unit",
            Error: new Error()
          };
    }
  } else if (typeof match$1 === "object") {
    if (match$1.NAME !== "giga") {
      throw {
            RE_EXN_ID: Prelude.AssertionError,
            _1: "Should be the same unit",
            Error: new Error()
          };
    }
    
  } else {
    throw {
          RE_EXN_ID: Prelude.AssertionError,
          _1: "Should be the same unit",
          Error: new Error()
        };
  }
  return [
          a$1,
          b$1
        ];
}

function toString(space) {
  var variant = space.NAME;
  if (variant === "kilo") {
    return space.VAL.toFixed() + " kB";
  } else if (variant === "mega") {
    return space.VAL.toFixed() + " MB";
  } else if (variant === "bytes") {
    return space.VAL.toFixed() + " B";
  } else {
    return space.VAL.toFixed() + " GB";
  }
}

function fractionToString(a, b) {
  var match = syncUp(a, b);
  return match[0].VAL.toFixed() + " / " + toString(match[1]);
}

function percent(a, b) {
  var a$1 = extract(toBytes(a));
  var b$1 = extract(toBytes(b));
  return a$1 / b$1 * 100.0;
}

function sub(a, b) {
  var a$1 = toBytes(a);
  var b$1 = toBytes(b);
  return toBest({
              NAME: "bytes",
              VAL: a$1.VAL - b$1.VAL
            });
}

export {
  up ,
  down ,
  CannotExtract ,
  extractUnsafe ,
  toBytes ,
  toBest ,
  syncUp ,
  toString ,
  fractionToString ,
  percent ,
  sub ,
}
/* No side effect */
