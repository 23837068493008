// Generated by ReScript, PLEASE EDIT WITH CARE

import * as BulkEditDialog from "./BulkEditDialog.js";
import * as BulkEditContext from "../context/BulkEditContext.js";
import * as JsxRuntime from "react/jsx-runtime";

function WithBulkEditor(props) {
  var documents = BulkEditContext.useSelection();
  var clear = BulkEditContext.useClear();
  if (documents !== undefined && clear !== undefined) {
    return JsxRuntime.jsx(BulkEditDialog.make, {
                documents: documents,
                onCancel: clear,
                onDone: clear
              });
  } else {
    return null;
  }
}

var make = WithBulkEditor;

export {
  make ,
}
/* BulkEditDialog Not a pure module */
