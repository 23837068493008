// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Collection from "../models/Collection.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CollectionId from "../models/CollectionId.js";
import * as ReactI18Next from "../../../../libs/i18n/ReactI18Next.js";
import * as ReactDOMStyle from "@rescript/react/lib/es6/src/ReactDOMStyle.js";
import * as FlexGrowSpacer from "../../../global/components/FlexGrowSpacer.js";
import * as JsxRuntime from "react/jsx-runtime";
import Grid from "@mui/material/Grid";
import * as CollectionDropdown from "./CollectionDropdown.js";
import * as CollectionsManager from "../context/CollectionsManager.js";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import * as CollectionsListService from "../context/CollectionsListService.js";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import Typography from "@mui/material/Typography";
import * as CollectionsFiltersContext from "../context/CollectionsFiltersContext.js";
import * as CollectionsSelectionManager from "../context/CollectionsSelectionManager.js";
import PaginationItem from "@mui/material/PaginationItem";
import TableContainer from "@mui/material/TableContainer";
import * as WithCollectionsLassoSelector from "./WithCollectionsLassoSelector.js";
import ImageSearch from "@mui/icons-material/ImageSearch";
import ArrowUpwardRounded from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRounded from "@mui/icons-material/ArrowDownwardRounded";

var letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

function CollectionsBrowser$CollectionsFilters$Letters(props) {
  var active = Prelude.default(props.filters.startswith, "");
  var setCurrentPage = CollectionsListService.useSetCurrentPage();
  var setStartsWith = CollectionsFiltersContext.useSetStartsWith();
  var removeStartsWith = CollectionsFiltersContext.useRemoveStartsWith();
  return JsxRuntime.jsx(Stack, {
              children: Caml_option.some(letters.map(function (letter) {
                        return JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx(PaginationItem, {
                                          page: Caml_option.some(letter),
                                          selected: letter === active,
                                          size: "small"
                                        }),
                                    onClick: (function (param) {
                                        if (letter !== active) {
                                          setCurrentPage(1);
                                          return setStartsWith(letter);
                                        } else {
                                          setCurrentPage(1);
                                          return removeStartsWith();
                                        }
                                      })
                                  }, letter);
                      })),
              direction: ["row"],
              spacing: 0.5,
              sx: {
                flexWrap: "wrap"
              },
              useFlexGap: true
            });
}

var Letters = {
  letters: letters,
  make: CollectionsBrowser$CollectionsFilters$Letters
};

function CollectionsBrowser$CollectionsFilters$Filter(props) {
  var contains = Prelude.default(props.filters.contains, "");
  var setCurrentPage = CollectionsListService.useSetCurrentPage();
  var setContains = CollectionsFiltersContext.useSetContains();
  var removeContains = CollectionsFiltersContext.useRemoveContains();
  return JsxRuntime.jsx(TextField, {
              fullWidth: true,
              label: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                        msg: {
                          NAME: "str",
                          VAL: "Filter by"
                        }
                      })),
              onChange: (function (ev) {
                  var target = ev.target;
                  var value = target.value;
                  if (value === "") {
                    if (contains !== "") {
                      setCurrentPage(1);
                      return removeContains();
                    } else {
                      return ;
                    }
                  } else if (contains !== value) {
                    setCurrentPage(1);
                    return setContains(value);
                  } else {
                    return ;
                  }
                }),
              value: contains,
              variant: "standard"
            });
}

var Filter = {
  make: CollectionsBrowser$CollectionsFilters$Filter
};

function CollectionsBrowser$CollectionsFilters(props) {
  var filters = props.filters;
  return JsxRuntime.jsxs(Grid, {
              children: [
                JsxRuntime.jsx(Grid, {
                      children: Caml_option.some(JsxRuntime.jsx(CollectionsBrowser$CollectionsFilters$Filter, {
                                filters: filters
                              })),
                      item: true,
                      xs: 3
                    }),
                JsxRuntime.jsx(Grid, {
                      children: Caml_option.some(JsxRuntime.jsx(CollectionsBrowser$CollectionsFilters$Letters, {
                                filters: filters
                              })),
                      item: true,
                      xs: 9
                    })
              ],
              container: true,
              sx: {
                alignItems: "center"
              }
            });
}

var CollectionsFilters = {
  Letters: Letters,
  Filter: Filter,
  make: CollectionsBrowser$CollectionsFilters
};

function CollectionsBrowser$CollectionsTable$CollectionRow(props) {
  var setCurrentBrowsingCollection = props.setCurrentBrowsingCollection;
  var collection = props.collection;
  var bulkRemove = CollectionsManager.useBulkRemoveCollections();
  var selectionDisabled = React.useMemo((function () {
          return Curry._1(Prelude.OptionExported.$$Option.isNone, bulkRemove);
        }), [bulkRemove]);
  var isSelected = CollectionsSelectionManager.useIsSelected();
  var isSelected$1 = React.useMemo((function () {
          return Prelude.default(isSelected, (function (param) {
                        return false;
                      }));
        }), [isSelected]);
  var selected = React.useMemo((function () {
          return isSelected$1(collection);
        }), [
        collection,
        isSelected$1
      ]);
  var toggle = CollectionsSelectionManager.useToggle();
  var toggle$1 = React.useMemo((function () {
          return Prelude.default(toggle, (function (param) {
                        
                      }));
        }), [toggle]);
  return React.useMemo((function () {
                var style = ReactDOMStyle.unsafeAddProp({
                      cursor: "default",
                      userSelect: "none"
                    }, "WebkitUserSelect", "none");
                return JsxRuntime.jsxs(TableRow, {
                            className: "selectable-item",
                            style: style,
                            id: CollectionId.toString(collection.id),
                            children: [
                              JsxRuntime.jsx(TableCell, {
                                    children: Caml_option.some(JsxRuntime.jsx(Checkbox, {
                                              checked: selected,
                                              disabled: selectionDisabled,
                                              onChange: (function (param, param$1) {
                                                  toggle$1([collection]);
                                                }),
                                              size: "small"
                                            }))
                                  }),
                              JsxRuntime.jsx(TableCell, {
                                    children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                              variant: "body1",
                                              children: Caml_option.some(collection.name)
                                            }))
                                  }),
                              JsxRuntime.jsx(TableCell, {
                                    children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                              children: [
                                                JsxRuntime.jsx(FlexGrowSpacer.make, {}),
                                                JsxRuntime.jsx(IconButton, {
                                                      children: Caml_option.some(JsxRuntime.jsx(ImageSearch, {})),
                                                      onClick: (function (param) {
                                                          setCurrentBrowsingCollection(collection);
                                                        })
                                                    }),
                                                JsxRuntime.jsx(CollectionDropdown.make, {
                                                      collection: collection
                                                    })
                                              ],
                                              direction: ["row"],
                                              spacing: 2.0
                                            }))
                                  })
                            ]
                          });
              }), [
              collection,
              selected,
              selectionDisabled
            ]);
}

var CollectionRow = {
  make: CollectionsBrowser$CollectionsTable$CollectionRow
};

function CollectionsBrowser$CollectionsTable$Column(props) {
  var toggleDirection = props.toggleDirection;
  var setField = props.setField;
  var column = props.column;
  var label = JsxRuntime.jsx(ReactI18Next.Message.make, {
        msg: {
          NAME: "msg",
          VAL: [
            "collections-browser-table.name-header",
            "Name"
          ]
        }
      });
  if (column !== props.sortfield) {
    return JsxRuntime.jsxs(Stack, {
                children: [
                  JsxRuntime.jsx(Tooltip, {
                        children: JsxRuntime.jsx("span", {
                              children: label,
                              style: {
                                cursor: "pointer"
                              },
                              onClick: (function (param) {
                                  setField(column);
                                })
                            }),
                        arrow: true,
                        title: JsxRuntime.jsx(ReactI18Next.Message.make, {
                              msg: {
                                NAME: "str",
                                VAL: "Sort field"
                              }
                            })
                      }),
                  JsxRuntime.jsx(ArrowUpwardRounded, {
                        fontSize: "small",
                        style: {
                          opacity: "0"
                        }
                      })
                ],
                direction: ["row"],
                spacing: "1rem",
                sx: {
                  alignItems: "center"
                }
              });
  }
  var tmp;
  tmp = props.direction === "" ? JsxRuntime.jsx(ArrowUpwardRounded, {
          fontSize: "small"
        }) : JsxRuntime.jsx(ArrowDownwardRounded, {
          fontSize: "small"
        });
  return JsxRuntime.jsxs(Stack, {
              children: [
                JsxRuntime.jsx(Tooltip, {
                      children: JsxRuntime.jsx("span", {
                            children: label,
                            style: {
                              cursor: "pointer"
                            },
                            onClick: (function (param) {
                                toggleDirection();
                              })
                          }),
                      arrow: true,
                      title: JsxRuntime.jsx(ReactI18Next.Message.make, {
                            msg: {
                              NAME: "str",
                              VAL: "Reverse sort direction"
                            }
                          })
                    }),
                tmp
              ],
              direction: ["row"],
              spacing: "1rem",
              sx: {
                alignItems: "center"
              }
            });
}

var Column = {
  make: CollectionsBrowser$CollectionsTable$Column
};

function CollectionsBrowser$CollectionsTable(props) {
  var setCurrentBrowsingCollection = props.setCurrentBrowsingCollection;
  var collections = props.collections;
  var hasSelection = Curry._1(Prelude.OptionExported.$$Option.getExn, CollectionsSelectionManager.useHasSelection());
  var select = Curry._1(Prelude.OptionExported.$$Option.getExn, CollectionsSelectionManager.useSelect());
  var unselect = Curry._1(Prelude.OptionExported.$$Option.getExn, CollectionsSelectionManager.useUnselect());
  var areAllSelected = Curry._1(Prelude.OptionExported.$$Option.getExn, CollectionsSelectionManager.useAreAllSelected());
  var sortfield = CollectionsFiltersContext.useOrderColumn();
  var direction = CollectionsFiltersContext.useOrderDirection();
  var setOrderColumn = CollectionsFiltersContext.useSetOrderColumn();
  var setOrderDirection = CollectionsFiltersContext.useSetOrderDirection();
  var toggleDirection = React.useCallback((function () {
          setOrderDirection(Collection.Order.toggleDirection(direction));
        }), [
        direction,
        setOrderDirection
      ]);
  var toggleSelection = function (e, param) {
    var exit = 0;
    var target;
    try {
      target = e.target;
      exit = 1;
    }
    catch (exn){
      return ;
    }
    if (exit === 1) {
      var exit$1 = 0;
      var val;
      try {
        val = target.checked;
        exit$1 = 2;
      }
      catch (exn$1){
        return ;
      }
      if (exit$1 === 2) {
        if (val) {
          return select(collections);
        } else {
          return unselect(collections);
        }
      }
      
    }
    
  };
  var indeterminate = React.useMemo((function () {
          if (areAllSelected(collections)) {
            return false;
          } else {
            return hasSelection;
          }
        }), [
        areAllSelected,
        collections,
        hasSelection
      ]);
  var checked = React.useMemo((function () {
          if (indeterminate) {
            return false;
          } else {
            return areAllSelected(collections);
          }
        }), [
        indeterminate,
        areAllSelected,
        collections
      ]);
  var bulkRemove = CollectionsManager.useBulkRemoveCollections();
  var selectionDisabled = React.useMemo((function () {
          return Curry._1(Prelude.OptionExported.$$Option.isNone, bulkRemove);
        }), [bulkRemove]);
  return JsxRuntime.jsx(TableContainer, {
              children: Caml_option.some(JsxRuntime.jsxs(Table, {
                        children: [
                          JsxRuntime.jsx(TableHead, {
                                children: Caml_option.some(JsxRuntime.jsxs(TableRow, {
                                          children: [
                                            JsxRuntime.jsx(TableCell, {
                                                  children: Caml_option.some(JsxRuntime.jsx(Checkbox, {
                                                            checked: checked,
                                                            disabled: selectionDisabled,
                                                            indeterminate: indeterminate,
                                                            onChange: toggleSelection,
                                                            size: "small"
                                                          })),
                                                  sx: {
                                                    width: "2rem"
                                                  }
                                                }),
                                            JsxRuntime.jsx(TableCell, {
                                                  children: Caml_option.some(JsxRuntime.jsx(CollectionsBrowser$CollectionsTable$Column, {
                                                            column: "name",
                                                            sortfield: sortfield,
                                                            direction: direction,
                                                            setField: (function (param) {
                                                                setOrderColumn("name");
                                                              }),
                                                            toggleDirection: toggleDirection
                                                          }))
                                                }),
                                            JsxRuntime.jsx(TableCell, {})
                                          ]
                                        }))
                              }),
                          JsxRuntime.jsx(TableBody, {
                                children: Caml_option.some(collections.map(function (collection) {
                                          return JsxRuntime.jsx(CollectionsBrowser$CollectionsTable$CollectionRow, {
                                                      collection: collection,
                                                      setCurrentBrowsingCollection: setCurrentBrowsingCollection
                                                    }, CollectionId.toString(collection.id));
                                        }))
                              })
                        ],
                        size: "small"
                      }))
            });
}

var CollectionsTable = {
  CollectionRow: CollectionRow,
  Column: Column,
  make: CollectionsBrowser$CollectionsTable
};

function CollectionsBrowser(props) {
  var pages = props.pages;
  var filters = props.filters;
  var collections = props.collections;
  var page = CollectionsListService.useCurrentPage();
  var setPage = CollectionsListService.useSetCurrentPage();
  var removeStartsWith = CollectionsFiltersContext.useRemoveStartsWith();
  var removeContains = CollectionsFiltersContext.useRemoveContains();
  if (Prelude.$$Array.isEmpty(collections)) {
    return JsxRuntime.jsxs(Stack, {
                children: [
                  JsxRuntime.jsx(CollectionsBrowser$CollectionsFilters, {
                        filters: filters
                      }),
                  JsxRuntime.jsxs(Stack, {
                        children: [
                          JsxRuntime.jsx(Typography, {
                                variant: "h6",
                                children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                          msg: {
                                            NAME: "msg",
                                            VAL: [
                                              "collections-browser-table.not-found-heading",
                                              "Nothing found"
                                            ]
                                          }
                                        }))
                              }),
                          JsxRuntime.jsx(Typography, {
                                variant: "body1",
                                children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                          msg: {
                                            NAME: "msg",
                                            VAL: [
                                              "collections-browser-table.not-found-explanation",
                                              "There are no collections that match your current query.\n               Please, remove some filters to get some results here."
                                            ]
                                          }
                                        }))
                              }),
                          JsxRuntime.jsx(Button, {
                                children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                          msg: {
                                            NAME: "str",
                                            VAL: "Clear filters"
                                          }
                                        })),
                                onClick: (function (param) {
                                    removeStartsWith();
                                    removeContains();
                                  }),
                                fullWidth: false
                              })
                        ],
                        direction: ["column"],
                        spacing: 4.0
                      })
                ],
                direction: ["column"],
                spacing: 2.0,
                sx: {
                  alignItems: "center",
                  width: "100%",
                  padding: "1rem"
                },
                useFlexGap: true
              });
  } else {
    return JsxRuntime.jsx(WithCollectionsLassoSelector.make, {
                collections: collections,
                children: JsxRuntime.jsxs(Stack, {
                      children: [
                        JsxRuntime.jsx(CollectionsBrowser$CollectionsFilters, {
                              filters: filters
                            }),
                        JsxRuntime.jsx(Stack, {
                              children: Caml_option.some(JsxRuntime.jsx(Pagination, {
                                        count: pages,
                                        onChange: (function (param, page) {
                                            setPage(page);
                                          }),
                                        page: page,
                                        showFirstButton: true,
                                        showLastButton: true
                                      })),
                              spacing: 2.0,
                              sx: {
                                display: {
                                  md: "none"
                                }
                              },
                              useFlexGap: true
                            }),
                        JsxRuntime.jsx(CollectionsBrowser$CollectionsTable, {
                              collections: collections,
                              setCurrentBrowsingCollection: props.setCurrentBrowsingCollection
                            }),
                        JsxRuntime.jsx(Stack, {
                              children: Caml_option.some(JsxRuntime.jsx(Pagination, {
                                        count: pages,
                                        onChange: (function (param, page) {
                                            setPage(page);
                                          }),
                                        page: page,
                                        showFirstButton: true,
                                        showLastButton: true
                                      })),
                              spacing: 2.0,
                              useFlexGap: true
                            })
                      ],
                      direction: ["column"],
                      spacing: 2.0,
                      sx: {
                        alignItems: "center",
                        width: "100%",
                        padding: "1rem"
                      },
                      useFlexGap: true
                    })
              });
  }
}

var make = CollectionsBrowser;

export {
  CollectionsFilters ,
  CollectionsTable ,
  make ,
}
/* letters Not a pure module */
