// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Settings from "../../../Settings.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../../libs/i18n/ReactI18Next.js";
import * as PendingUpload from "../models/PendingUpload.js";
import * as UploadManager from "../context/UploadManager.js";
import * as ReactI18next from "react-i18next";
import * as ReactDropzone from "react-dropzone";
import * as JsxRuntime from "react/jsx-runtime";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import * as CurrentFolderManager from "../context/CurrentFolderManager.js";
import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";

function CatalogFreeDropzone$DefaultMessages$Idle(props) {
  return JsxRuntime.jsx(Typography, {
              children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                        msg: {
                          NAME: "msg",
                          VAL: [
                            "upload.description-idle",
                            "Drag and drop the files you want to upload or click here to open the file selector."
                          ]
                        }
                      }))
            });
}

var Idle = {
  make: CatalogFreeDropzone$DefaultMessages$Idle
};

function CatalogFreeDropzone$DefaultMessages$Accept(props) {
  return JsxRuntime.jsx(Typography, {
              variant: "body1",
              children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                        msg: {
                          NAME: "msg",
                          VAL: [
                            "upload.description-drop",
                            "Drop the files to start the upload."
                          ]
                        }
                      }))
            });
}

var Accept = {
  make: CatalogFreeDropzone$DefaultMessages$Accept
};

function CatalogFreeDropzone$DefaultMessages$Reject(props) {
  return JsxRuntime.jsxs(Stack, {
              children: [
                JsxRuntime.jsx(Typography, {
                      color: "error.main",
                      variant: "h6",
                      children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                msg: {
                                  NAME: "msg",
                                  VAL: [
                                    "upload.invalid-files",
                                    "Invalid files"
                                  ]
                                }
                              }))
                    }),
                JsxRuntime.jsx(Typography, {
                      variant: "body1",
                      children: Caml_option.some(JsxRuntime.jsxs(ReactI18next.Trans, {
                                i18nKey: "upload.description-reject",
                                children: [
                                  "Either you have selected too many files (limit is {{count}}) ",
                                  "or some files in your selection are not valid or too large."
                                ],
                                count: Settings.Upload.maxFiles
                              }))
                    })
              ],
              direction: ["column"]
            });
}

var Reject = {
  make: CatalogFreeDropzone$DefaultMessages$Reject
};

function CatalogFreeDropzone$DefaultMessages$InvalidZone(props) {
  return JsxRuntime.jsxs(Stack, {
              children: [
                JsxRuntime.jsx(Typography, {
                      color: "error.main",
                      variant: "h6",
                      children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                msg: {
                                  NAME: "msg",
                                  VAL: [
                                    "upload.invalid-dropzone-heading",
                                    "Cannot upload files here"
                                  ]
                                }
                              }))
                    }),
                JsxRuntime.jsx(Typography, {
                      variant: "body1",
                      children: Caml_option.some(JsxRuntime.jsx(ReactI18next.Trans, {
                                i18nKey: "upload.invalid-dropzone-message",
                                children: "You cannot upload files here.  Maybe you are navigating the Trash, or your are using a user that cannot upload."
                              }))
                    })
              ],
              direction: ["column"]
            });
}

var InvalidZone = {
  make: CatalogFreeDropzone$DefaultMessages$InvalidZone
};

var DefaultMessages = {
  Idle: Idle,
  Accept: Accept,
  Reject: Reject,
  InvalidZone: InvalidZone
};

function CatalogFreeDropzone(props) {
  var __allowOnClick = props.allowOnClick;
  var onDone = props.onDone;
  var childrenRender = props.childrenRender;
  var __allowed = props.allowed;
  var children = props.children;
  var allowed = __allowed !== undefined ? __allowed : "allow";
  var allowOnClick = __allowOnClick !== undefined ? __allowOnClick : false;
  var folder = CurrentFolderManager.useCurrentFolder();
  var uploader = UploadManager.useUpload();
  var onDropAccepted;
  var exit = 0;
  if (allowed === "allow" && uploader !== undefined && folder !== undefined) {
    onDropAccepted = (function (files) {
        uploader(files.map(function (f) {
                  return PendingUpload.fromFile(folder, f);
                }));
        Prelude.OptionExported.$$Option.$$do(onDone, (function (fn) {
                fn();
              }));
      });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    onDropAccepted = (function (param) {
        
      });
  }
  var dropzoneProps = ReactDropzone.useDropzone({
        onDropAccepted: onDropAccepted,
        onDropRejected: (function (args) {
            console.warn("CatalogFreeDropzone", "onDropRejected", args);
          }),
        accept: {
          "image/png": [],
          "image/jpeg": [],
          "image/gif": [],
          "image/webp": [],
          "image/tiff": []
        },
        maxFiles: Settings.Upload.maxFiles,
        maxSize: Settings.Upload.maxSize
      });
  var childrenRender$1 = React.useMemo((function () {
          return Prelude.default(childrenRender, (function (props) {
                        if (props.isDragActive) {
                          return JsxRuntime.jsx(Backdrop, {
                                      open: true,
                                      children: Caml_option.some(JsxRuntime.jsx(Paper, {
                                                sx: {
                                                  width: "70%",
                                                  padding: "1rem"
                                                },
                                                children: Caml_option.some(allowed === "allow" ? (
                                                        props.isDragAccept && Curry._1(Prelude.OptionExported.$$Option.isSome, uploader) ? JsxRuntime.jsx(CatalogFreeDropzone$DefaultMessages$Accept, {}) : JsxRuntime.jsx(CatalogFreeDropzone$DefaultMessages$Reject, {})
                                                      ) : JsxRuntime.jsx(CatalogFreeDropzone$DefaultMessages$InvalidZone, {}))
                                              }))
                                    });
                        } else {
                          return null;
                        }
                      }));
        }), [childrenRender]);
  var style = Prelude.default(props.style, {
        height: "100%"
      });
  var props$1 = dropzoneProps.getRootProps();
  var onClick;
  try {
    onClick = allowOnClick ? Caml_option.some(props$1.onClick) : undefined;
  }
  catch (exn){
    onClick = undefined;
  }
  var children$1 = React.useMemo((function () {
          return Prelude.default(children, null);
        }), [children]);
  var newrecord = Caml_obj.obj_dup(props$1);
  return JsxRuntime.jsxs("div", (newrecord.onClick = onClick, newrecord.style = style, newrecord.children = [
                children$1,
                childrenRender$1(dropzoneProps)
              ], newrecord));
}

var make = CatalogFreeDropzone;

export {
  DefaultMessages ,
  make ,
}
/* react Not a pure module */
