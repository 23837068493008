// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Settings from "../../../Settings.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../../libs/i18n/ReactI18Next.js";
import * as AttributeMatch from "../models/AttributeMatch.js";
import * as SearchMetaMaker from "../utils/SearchMetaMaker.js";
import * as NamespaceContext from "../../../global/context/NamespaceContext.js";
import * as SearchAPIEndpoint from "../api/SearchAPIEndpoint.js";
import * as JsxRuntime from "react/jsx-runtime";

var defaultValue = AttributeMatch.make("");

function getValueName(f) {
  return f.value;
}

function getValueKey(f) {
  return f.value;
}

var defaultPageSize = Settings.Autocomplete.maxItems;

var makeSolo = AttributeMatch.make;

var groupBy = (function (v) {
    return v.value.substr(0, 1).toLocaleUpperCase();
  });

var notFoundMessage = Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
          msg: {
            NAME: "msg",
            VAL: [
              "country-autocomplete.not-found",
              "No matching countries found"
            ]
          }
        }));

var $$fetch = SearchAPIEndpoint.Meta.Attributes.$$fetch;

var Impl = SearchMetaMaker.MakeAutocomplete({
      identifier: "CountryAutocomplete",
      defaultValue: defaultValue,
      getValueName: getValueName,
      getValueKey: getValueKey,
      makeSolo: makeSolo,
      notFoundMessage: notFoundMessage,
      promptMessage: undefined,
      defaultPageSize: defaultPageSize,
      groupBy: groupBy,
      renderOption: undefined,
      $$fetch: $$fetch
    });

function CountryAutocomplete(props) {
  var freeSolo = props.freeSolo;
  var multiple = props.multiple;
  var onChange = props.onChange;
  var ns = NamespaceContext.useNamespace();
  var match = React.useState(function () {
        return "";
      });
  var setInputValue = match[1];
  var inputValue = match[0];
  var freeSolo$1 = React.useMemo((function () {
          return Prelude.default(freeSolo, false);
        }), [freeSolo]);
  var multiple$1 = React.useMemo((function () {
          return Prelude.default(multiple, false);
        }), [multiple]);
  var onInputChange = React.useMemo((function () {
          if (freeSolo$1 && !multiple$1) {
            return (function (param, value, reason) {
                      console.log("CountryAutocomplete", {
                            value: value,
                            reason: reason
                          });
                      switch (reason) {
                        case "input" :
                            return setInputValue(function (param) {
                                        return value;
                                      });
                        case "reset" :
                            if (inputValue !== "") {
                              return onChange([AttributeMatch.make(inputValue)]);
                            } else {
                              return ;
                            }
                        case "clear" :
                            return ;
                        
                      }
                    });
          }
          
        }), [
        freeSolo$1,
        multiple$1,
        onChange,
        inputValue,
        setInputValue
      ]);
  return JsxRuntime.jsx(Impl.make, {
              items: props.countries,
              onChange: onChange,
              onInputChange: onInputChange,
              getFilter: (function (value) {
                  var newrecord = Caml_obj.obj_dup(AttributeMatch.Filters.make(ns.id, "country"));
                  newrecord.contains = value;
                  return newrecord;
                }),
              label: props.label,
              variant: props.variant,
              sx: props.sx,
              fullWidth: props.fullWidth,
              limitTags: props.limitTags,
              disableCloseOnSelect: props.disableCloseOnSelect,
              multiple: multiple$1,
              freeSolo: freeSolo$1,
              autoSelect: freeSolo$1,
              helperText: props.helperText
            });
}

var make = CountryAutocomplete;

export {
  make ,
}
/* Impl Not a pure module */
