// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SearchQuery from "./models/SearchQuery.js";
import * as TagsManager from "../tags/context/TagsManager.js";
import * as BundlesManager from "../catalog/context/BundlesManager.js";
import * as WithBulkEditor from "../catalog/components/WithBulkEditor.js";
import * as BulkEditContext from "../catalog/context/BulkEditContext.js";
import * as ApplicationMaker from "../../global/apps/ApplicationMaker.js";
import * as NamespaceContext from "../../global/context/NamespaceContext.js";
import * as SearchAffordance from "./components/affordances/SearchAffordance.js";
import * as SearchResultArea from "./components/SearchResultArea.js";
import * as SelectionManager from "../catalog/context/SelectionManager.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DocumentDownloader from "../catalog/context/services/DocumentDownloader.js";
import * as SearchFiltersPanel from "./components/SearchFiltersPanel.js";
import Stack from "@mui/material/Stack";
import * as SelectionAffordance from "../catalog/components/affordances/SelectionAffordance.js";
import * as WithDocumentBrowser from "../catalog/components/WithDocumentBrowser.js";
import * as SearchFiltersContext from "./context/SearchFiltersContext.js";
import * as RemoveDocumentService from "../catalog/context/services/RemoveDocumentService.js";
import * as SearchCatalogManifest from "./SearchCatalogManifest.js";
import * as UpdateDocumentService from "../catalog/context/services/UpdateDocumentService.js";
import * as CatalogBrowserManifest from "../catalog/CatalogBrowserManifest.js";
import * as DocumentFinderNavigator from "../catalog/context/DocumentFinderNavigator.js";
import * as CatalogBrowserViewContext from "../catalog/context/CatalogBrowserViewContext.js";
import * as CurrentDocumentListManager from "../catalog/context/CurrentDocumentListManager.js";

function SearchCatalogView$Services(props) {
  var ns = NamespaceContext.useNamespace();
  var newrecord = Caml_obj.obj_dup(SearchQuery.Filters.$$default);
  newrecord.root = Caml_option.some(ns.root_folder_id);
  return JsxRuntime.jsx(CatalogBrowserViewContext.make, {
              children: JsxRuntime.jsx(CurrentDocumentListManager.make, {
                    children: JsxRuntime.jsx(DocumentFinderNavigator.make, {
                          path: CatalogBrowserManifest.path,
                          children: JsxRuntime.jsx(BulkEditContext.make, {
                                children: JsxRuntime.jsx(TagsManager.make, {
                                      children: JsxRuntime.jsx(BundlesManager.make, {
                                            children: JsxRuntime.jsx(DocumentDownloader.make, {
                                                  children: JsxRuntime.jsx(UpdateDocumentService.make, {
                                                        children: JsxRuntime.jsx(RemoveDocumentService.make, {
                                                              children: JsxRuntime.jsx(SelectionManager.Documents.make, {
                                                                    children: JsxRuntime.jsx(SearchFiltersContext.make, {
                                                                          children: props.children,
                                                                          fixtures: newrecord
                                                                        })
                                                                  })
                                                            })
                                                      })
                                                })
                                          })
                                    })
                              })
                        })
                  })
            });
}

var Services = {
  make: SearchCatalogView$Services
};

function SearchCatalogView$Affordances(props) {
  return JsxRuntime.jsx(SelectionAffordance.make, {
              children: JsxRuntime.jsx(SearchAffordance.make, {
                    children: props.children,
                    filters: "folders"
                  })
            });
}

var Affordances = {
  make: SearchCatalogView$Affordances
};

function SearchCatalogView$Main(props) {
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Stack, {
                      children: [
                        JsxRuntime.jsx(SearchFiltersPanel.make, {}),
                        JsxRuntime.jsx(SearchResultArea.make, {})
                      ],
                      direction: ["column"],
                      spacing: 2.0
                    }),
                JsxRuntime.jsx(WithDocumentBrowser.make, {}),
                JsxRuntime.jsx(WithBulkEditor.make, {})
              ]
            });
}

var Main = {
  make: SearchCatalogView$Main
};

var include = ApplicationMaker.MakeStandard({
      Manifest: SearchCatalogManifest,
      Services: Services,
      Affordances: Affordances,
      Main: Main
    });

var Manifest = include.Manifest;

var make = include.make;

export {
  Manifest ,
  make ,
}
/* include Not a pure module */
