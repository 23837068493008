// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import I18next from "i18next";
import * as Settings from "../../Settings.js";
import * as ReactI18next from "react-i18next";
import I18nextHttpBackend from "i18next-http-backend";
import I18nextBrowserLanguagedetector from "i18next-browser-languagedetector";

var i18n = I18next;

function init() {
  return i18n.use(I18nextHttpBackend).use(I18nextBrowserLanguagedetector).use(ReactI18next.initReactI18next).init({
              load: "languageOnly",
              debug: !Settings.Mode.prod,
              interpolation: {
                escapeValue: false
              },
              backend: {
                queryStringParams: {
                  v: Settings.releaseVersion,
                  h: Settings.releaseHash
                }
              }
            });
}

function PhotocubaI18N(props) {
  var match = React.useState(function () {
        return "Init";
      });
  var setState = match[1];
  var state = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setListener = match$1[1];
  var listener = match$1[0];
  var $$window$1 = window;
  var storage = localStorage;
  React.useEffect((function () {
          if (state === "Init") {
            Prelude.thenDo(init(), (function () {
                    setState(function (param) {
                          return "Loaded";
                        });
                  }));
          } else {
            if (listener !== undefined) {
              $$window$1.removeEventListener("languagechange", listener);
            }
            var fn = function (param) {
              storage.removeItem("i18nextLng");
              setState(function (param) {
                    return "Init";
                  });
            };
            $$window$1.addEventListener("languagechange", fn);
            setListener(function (param) {
                  return fn;
                });
          }
        }), [state]);
  if (state === "Init") {
    return null;
  } else {
    return props.children;
  }
}

var make = PhotocubaI18N;

export {
  make ,
}
/* i18n Not a pure module */
