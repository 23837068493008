// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../libs/i18n/ReactI18Next.js";
import * as JsxRuntime from "react/jsx-runtime";
import Button from "@mui/material/Button";

function ReloadButton(props) {
  return JsxRuntime.jsx(Button, {
              children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                        msg: {
                          NAME: "msg",
                          VAL: [
                            "buttons.reload",
                            "Reload"
                          ]
                        }
                      })),
              onClick: (function (param) {
                  window.location.reload();
                })
            });
}

var make = ReloadButton;

export {
  make ,
}
/* ReactI18Next Not a pure module */
