// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Webapi__Dom__Element from "../../../../libs/rescript-webapi/src/Webapi/Dom/Webapi__Dom__Element.js";

function getScroll(container) {
  return {
          x: container.scrollLeft,
          y: container.scrollTop
        };
}

function getContainer(elementQuery) {
  var el = document.querySelector(elementQuery);
  if (el == null) {
    return document.documentElement;
  } else {
    return el;
  }
}

function getMousePosition(e) {
  return {
          x: e.clientX,
          y: e.clientY
        };
}

function DragSelection$Lasso(props) {
  var __style = props.style;
  var end = props.end;
  var start = props.start;
  var style = __style !== undefined ? __style : ({});
  var left = Math.min(start.x, end.x);
  var top = Math.min(start.y, end.y);
  var width = Math.abs(start.x - end.x);
  var height = Math.abs(start.y - end.y);
  var newrecord = Caml_obj.obj_dup(style);
  newrecord.zIndex = "1000";
  newrecord.width = String(width) + "px";
  newrecord.top = String(top) + "px";
  newrecord.position = "absolute";
  newrecord.left = String(left) + "px";
  newrecord.height = String(height) + "px";
  return JsxRuntime.jsx("div", {
              className: "drag-selection-lasso",
              style: newrecord
            });
}

function DragSelection(props) {
  var __itemSelector = props.itemSelector;
  var __appContainer = props.appContainer;
  var getCurrentSelection = props.getCurrentSelection;
  var onDragSelection = props.onDragSelection;
  var children = props.children;
  var appContainer = __appContainer !== undefined ? __appContainer : "body";
  var itemSelector = __itemSelector !== undefined ? __itemSelector : [".selectable-item[id]"];
  var ref = React.useRef(null);
  var appContainer$1 = getContainer(appContainer);
  var lassoStyle = Curry._2(Prelude.OptionExported.$$Option.getWithDefault, props.lassoStyle, {
        backgroundColor: "rgba(6, 66, 115, 0.7)",
        border: "1px dashed #000"
      });
  var match = React.useState(function () {
        return false;
      });
  var setDragStart = match[1];
  var dragStart = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setIsDragging = match$1[1];
  var isDragging = match$1[0];
  var match$2 = React.useState(function () {
        return {
                x: 0.0,
                y: 0.0
              };
      });
  var setStartPoint = match$2[1];
  var startPoint = match$2[0];
  var match$3 = React.useState(function () {
        return {
                x: 0.0,
                y: 0.0
              };
      });
  var setEndPoint = match$3[1];
  var endPoint = match$3[0];
  var match$4 = React.useState(function () {
        return false;
      });
  var setCtrlKeyDown = match$4[1];
  var isCtrlKeyDown = match$4[0];
  var match$5 = React.useState(function () {
        return [];
      });
  var setChildsBoxes = match$5[1];
  var childsBoxes = match$5[0];
  var match$6 = React.useState(function () {
        
      });
  var setInitialSelection = match$6[1];
  var initialSelection = match$6[0];
  var preventDefaultAction = function (e) {
    e.preventDefault();
  };
  var handleMouseDown = function (e) {
    if (e.detail !== 1) {
      return ;
    }
    var mousePosition = getMousePosition(e);
    setEndPoint(function (param) {
          return mousePosition;
        });
    setStartPoint(function (param) {
          return mousePosition;
        });
    setDragStart(function (param) {
          return true;
        });
    setInitialSelection(function (param) {
          return getCurrentSelection();
        });
  };
  var handleMouseMove = function (e) {
    if (dragStart) {
      setIsDragging(function (param) {
            return true;
          });
      return setEndPoint(function (param) {
                  return getMousePosition(e);
                });
    }
    
  };
  var handleMouseUp = function (param) {
    setIsDragging(function (param) {
          return false;
        });
    setDragStart(function (param) {
          return false;
        });
    setEndPoint(function (param) {
          return {
                  x: 0.0,
                  y: 0.0
                };
        });
    setStartPoint(function (param) {
          return {
                  x: 0.0,
                  y: 0.0
                };
        });
    setInitialSelection(function (param) {
          
        });
  };
  var handleKeyDown = function (e) {
    setCtrlKeyDown(function (param) {
          return e.ctrlKey;
        });
  };
  var handleKeyUp = function (param) {
    setCtrlKeyDown(function (param) {
          return false;
        });
  };
  var getLassoRect = function (param) {
    return {
            x: Math.min(startPoint.x, endPoint.x),
            y: Math.min(startPoint.y, endPoint.y),
            width: Math.abs(startPoint.x - endPoint.x),
            height: Math.abs(startPoint.y - endPoint.y)
          };
  };
  var selectedByLasso = function (lassoRect) {
    return Curry._2(Prelude.$$Array.keep, childsBoxes, (function (box) {
                  var box1 = box.box;
                  return !(lassoRect.x > box1.x + box1.width || lassoRect.x + lassoRect.width < box1.x || lassoRect.y > box1.y + box1.height || lassoRect.y + lassoRect.height < box1.y);
                }));
  };
  React.useEffect((function () {
          if (isDragging) {
            var ids = Belt_SetString.fromArray(selectedByLasso(getLassoRect()).map(function (it) {
                      return it.id;
                    }));
            var selected = isCtrlKeyDown ? Belt_SetString.union(initialSelection, ids) : ids;
            onDragSelection(selected);
          }
          
        }), [
        endPoint,
        isCtrlKeyDown
      ]);
  React.useEffect((function () {
          if (isDragging) {
            var containerRect = appContainer$1.getBoundingClientRect();
            var scroll = getScroll(appContainer$1);
            if (endPoint.y >= containerRect.height - 55.0) {
              appContainer$1.scrollBy(0.0, 10.0);
              setStartPoint(function (prevPoint) {
                    return {
                            x: prevPoint.x - scroll.x,
                            y: prevPoint.y - 10.0
                          };
                  });
            }
            if (endPoint.y <= 70.0 && scroll.y > 70.0) {
              appContainer$1.scrollBy(0.0, -10.0);
              setStartPoint(function (prevPoint) {
                    return {
                            x: prevPoint.x - scroll.x,
                            y: prevPoint.y + 10.0
                          };
                  });
            }
            
          }
          
        }), [endPoint]);
  React.useEffect((function () {
          var container = ref.current;
          if (!(container == null)) {
            var itemList = itemSelector.map(function (query) {
                  return container.querySelectorAll(query);
                });
            var boxes = Curry._2(Prelude.$$Array.keepMap, Curry._2(Prelude.$$Array.keepMap, Prelude.$$Array.flatMap(itemList, (function (nl) {
                            return Array.prototype.slice.call(nl);
                          })), Webapi__Dom__Element.ofNode), (function (el) {
                    var id = el.id;
                    if (id === "") {
                      return ;
                    }
                    var rect = el.getBoundingClientRect();
                    return {
                            id: id,
                            box: {
                              x: rect.left,
                              y: rect.top,
                              width: rect.width,
                              height: rect.height
                            }
                          };
                  }));
            setChildsBoxes(function (param) {
                  return boxes;
                });
          }
          
        }), [children]);
  React.useEffect((function () {
          appContainer$1.addEventListener("dragstart", preventDefaultAction);
          appContainer$1.addEventListener("selectstart", preventDefaultAction);
          document.addEventListener("mouseleave", handleMouseUp);
          window.addEventListener("keydown", handleKeyDown);
          window.addEventListener("keyup", handleKeyUp);
          return (function () {
                    appContainer$1.removeEventListener("dragstart", preventDefaultAction);
                    appContainer$1.removeEventListener("selectstart", preventDefaultAction);
                    document.removeEventListener("mouseleave", handleMouseUp);
                    window.removeEventListener("keydown", handleKeyDown);
                    window.removeEventListener("keyup", handleKeyUp);
                  });
        }), []);
  return JsxRuntime.jsxs("div", {
              children: [
                children,
                isDragging ? JsxRuntime.jsx(DragSelection$Lasso, {
                        start: startPoint,
                        end: endPoint,
                        style: lassoStyle
                      }) : null
              ],
              ref: Caml_option.some(ref),
              className: "selectables-items-container",
              onMouseDown: handleMouseDown,
              onMouseMove: handleMouseMove,
              onMouseUp: handleMouseUp
            });
}

var make = DragSelection;

export {
  make ,
}
/* react Not a pure module */
