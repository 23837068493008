// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FolderBrowser from "./components/FolderBrowser.js";
import * as UploadManager from "./context/UploadManager.js";
import * as BundlesManager from "./context/BundlesManager.js";
import * as WithBulkEditor from "./components/WithBulkEditor.js";
import * as BulkEditContext from "./context/BulkEditContext.js";
import * as FeedbackDelayed from "../../global/components/FeedbackDelayed.js";
import * as ApplicationMaker from "../../global/apps/ApplicationMaker.js";
import * as ClipboardManager from "./context/ClipboardManager.js";
import * as NamespaceContext from "../../global/context/NamespaceContext.js";
import * as SearchAffordance from "../search/components/affordances/SearchAffordance.js";
import * as SelectionManager from "./context/SelectionManager.js";
import * as ReactRouterDom from "react-router-dom";
import * as JsxRuntime from "react/jsx-runtime";
import * as DocumentDownloader from "./context/services/DocumentDownloader.js";
import * as CatalogFreeDropzone from "./components/CatalogFreeDropzone.js";
import * as ClipboardAffordance from "./components/affordances/ClipboardAffordance.js";
import * as CreateFolderService from "./context/services/CreateFolderService.js";
import * as RemoveFolderService from "./context/services/RemoveFolderService.js";
import * as SelectionAffordance from "./components/affordances/SelectionAffordance.js";
import * as WithDocumentBrowser from "./components/WithDocumentBrowser.js";
import * as CurrentFolderManager from "./context/CurrentFolderManager.js";
import * as RemoveDocumentService from "./context/services/RemoveDocumentService.js";
import * as UpdateDocumentService from "./context/services/UpdateDocumentService.js";
import Backdrop from "@mui/material/Backdrop";
import * as CatalogBrowserManifest from "./CatalogBrowserManifest.js";
import * as CreateNewItemAffordance from "./components/affordances/CreateNewItemAffordance.js";
import * as UpdateFolderInfoService from "./context/services/UpdateFolderInfoService.js";
import * as CatalogBrowserSortContext from "./context/CatalogBrowserSortContext.js";
import * as CatalogBrowserViewContext from "./context/CatalogBrowserViewContext.js";
import * as CurrentDocumentListManager from "./context/CurrentDocumentListManager.js";
import CircularProgress from "@mui/material/CircularProgress";

function CatalogBrowserView$State(props) {
  var params = ReactRouterDom.useParams();
  var ns = NamespaceContext.useNamespace();
  var folderId = params.folderId;
  var folderId$1 = folderId !== undefined ? Caml_option.valFromOption(folderId) : ns.root_folder_id;
  return JsxRuntime.jsx(BundlesManager.make, {
              children: JsxRuntime.jsx(CatalogBrowserSortContext.make, {
                    children: JsxRuntime.jsx(CurrentDocumentListManager.make, {
                          children: JsxRuntime.jsx(BulkEditContext.make, {
                                children: JsxRuntime.jsx(CurrentFolderManager.make, {
                                      folderId: folderId$1,
                                      namespace: ns,
                                      children: JsxRuntime.jsx(UploadManager.make, {
                                            children: JsxRuntime.jsx(SelectionManager.Documents.make, {
                                                  children: JsxRuntime.jsx(SelectionManager.Folders.make, {
                                                        children: props.children
                                                      })
                                                })
                                          })
                                    })
                              })
                        })
                  })
            });
}

function CatalogBrowserView$Services(props) {
  return JsxRuntime.jsx(CatalogBrowserView$State, {
              children: JsxRuntime.jsx(CatalogBrowserViewContext.make, {
                    children: JsxRuntime.jsx(ClipboardManager.Paster.make, {
                          children: JsxRuntime.jsx(RemoveFolderService.make, {
                                children: JsxRuntime.jsx(UpdateDocumentService.make, {
                                      children: JsxRuntime.jsx(RemoveDocumentService.make, {
                                            children: JsxRuntime.jsx(UpdateFolderInfoService.make, {
                                                  children: JsxRuntime.jsx(CreateFolderService.make, {
                                                        children: JsxRuntime.jsx(DocumentDownloader.make, {
                                                              children: props.children
                                                            })
                                                      })
                                                })
                                          })
                                    })
                              })
                        })
                  })
            });
}

var Services = {
  make: CatalogBrowserView$Services
};

function CatalogBrowserView$Affordances(props) {
  var children = props.children;
  var folder = CurrentFolderManager.useCurrentFolder();
  if (folder !== undefined) {
    return JsxRuntime.jsx(SearchAffordance.make, {
                children: JsxRuntime.jsx(SelectionAffordance.make, {
                      children: JsxRuntime.jsx(CreateNewItemAffordance.make, {
                            folder: folder,
                            children: JsxRuntime.jsx(ClipboardAffordance.make, {
                                  children: children
                                })
                          })
                    })
              });
  } else {
    return children;
  }
}

var Affordances = {
  make: CatalogBrowserView$Affordances
};

function CatalogBrowserView$Browser(props) {
  var params = ReactRouterDom.useParams();
  var ns = NamespaceContext.useNamespace();
  var folder = CurrentFolderManager.useCurrentFolder();
  var subfolders = CurrentFolderManager.useCurrentSubfolders();
  var documents = CurrentDocumentListManager.useDocuments();
  var stage = CurrentFolderManager.useCurrentStage();
  var folderId = params.folderId;
  var folderId$1 = folderId !== undefined ? Caml_option.valFromOption(folderId) : ns.root_folder_id;
  var reset = Prelude.default(SelectionManager.Documents.useReset(), (function () {
          
        }));
  React.useEffect((function () {
          reset();
        }), [folderId$1]);
  var reset$1 = Prelude.default(SelectionManager.Folders.useReset(), (function () {
          
        }));
  React.useEffect((function () {
          reset$1();
        }), [folderId$1]);
  if (folder === undefined) {
    return null;
  }
  switch (stage) {
    case "LoadedCached" :
        return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                    children: [
                      JsxRuntime.jsx(FolderBrowser.make, {
                            folder: folder,
                            subfolders: subfolders,
                            documents: documents
                          }),
                      JsxRuntime.jsx(FeedbackDelayed.make, {
                            children: JsxRuntime.jsx(Backdrop, {
                                  open: true,
                                  children: Caml_option.some(JsxRuntime.jsx(CircularProgress, {}))
                                }),
                            fallback: Caml_option.some(null),
                            delay: 300
                          })
                    ]
                  });
    case "LoadedReady" :
        return JsxRuntime.jsx(CatalogFreeDropzone.make, {
                    children: Caml_option.some(JsxRuntime.jsx(FolderBrowser.make, {
                              folder: folder,
                              subfolders: subfolders,
                              documents: documents
                            }))
                  });
    case "LoadedOffline" :
        return JsxRuntime.jsx(CatalogFreeDropzone.make, {
                    children: Caml_option.some(JsxRuntime.jsx(FolderBrowser.make, {
                              folder: folder,
                              subfolders: subfolders,
                              documents: documents
                            })),
                    allowed: "forbid"
                  });
    case "Init" :
    case "Error" :
        return null;
    
  }
}

function CatalogBrowserView$Main(props) {
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(CatalogBrowserView$Browser, {}),
                JsxRuntime.jsx(WithBulkEditor.make, {}),
                JsxRuntime.jsx(WithDocumentBrowser.make, {})
              ]
            });
}

var Main = {
  make: CatalogBrowserView$Main
};

var include = ApplicationMaker.MakeStandard({
      Manifest: CatalogBrowserManifest,
      Services: Services,
      Affordances: Affordances,
      Main: Main
    });

var Manifest = include.Manifest;

var make = include.make;

export {
  Manifest ,
  make ,
}
/* include Not a pure module */
