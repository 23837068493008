// Generated by ReScript, PLEASE EDIT WITH CARE

import * as TrashBrowserView from "./TrashBrowserView.js";
import * as ReactRouterDom from "react-router-dom";
import * as JsxRuntime from "react/jsx-runtime";
import * as CatalogBrowserView from "./CatalogBrowserView.js";

function FsBrowserView(props) {
  return JsxRuntime.jsxs(ReactRouterDom.Routes, {
              children: [
                JsxRuntime.jsx(ReactRouterDom.Route, {
                      path: "/",
                      element: JsxRuntime.jsx(CatalogBrowserView.make, {})
                    }),
                JsxRuntime.jsx(ReactRouterDom.Route, {
                      path: "/catalog/",
                      element: JsxRuntime.jsx(CatalogBrowserView.make, {})
                    }),
                JsxRuntime.jsx(ReactRouterDom.Route, {
                      path: "/catalog/:folderId",
                      element: JsxRuntime.jsx(CatalogBrowserView.make, {})
                    }),
                JsxRuntime.jsx(ReactRouterDom.Route, {
                      path: "/trash/",
                      element: JsxRuntime.jsx(TrashBrowserView.make, {})
                    }),
                JsxRuntime.jsx(ReactRouterDom.Route, {
                      path: "/trash/:folderId",
                      element: JsxRuntime.jsx(TrashBrowserView.make, {})
                    })
              ]
            });
}

var make = FsBrowserView;

export {
  make ,
}
/* TrashBrowserView Not a pure module */
