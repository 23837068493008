// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as JsxRuntime from "react/jsx-runtime";
import Input from "@mui/material/Input";

function SearchWidget(props) {
  var match = ReactI18next.useTranslation();
  var match$1 = React.useState(function () {
        return false;
      });
  var setActive = match$1[1];
  return JsxRuntime.jsx(Input, {
              color: "primary",
              fullWidth: match$1[0],
              name: "search",
              onBlur: (function (param) {
                  setActive(function (param) {
                        return false;
                      });
                }),
              onFocus: (function (param) {
                  setActive(function (param) {
                        return true;
                      });
                }),
              placeholder: match.t("Search"),
              size: "medium"
            });
}

var make = SearchWidget;

export {
  make ,
}
/* react Not a pure module */
