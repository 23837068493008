// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as JsxRuntime from "react/jsx-runtime";

function add(registry, affordance) {
  console.log("ActionsManager", "register", affordance);
  return Prelude.$$Array.append(Curry._2(Prelude.$$Array.keep, registry, (function (a) {
                    return a.key !== affordance.key;
                  })), affordance);
}

var context = React.createContext([]);

var make = context.Provider;

function useGetActiveAffordances() {
  var registry = React.useContext(context);
  return React.useCallback((function () {
                return Prelude.$$Array.sort(registry, (function (param) {
                              return param.sequence;
                            }), undefined);
              }), [registry]);
}

function ActionsManager$Register(props) {
  var registry = React.useContext(context);
  var registry$1 = add(registry, {
        key: props.name,
        widget: props.widget,
        sequence: props.sequence
      });
  return JsxRuntime.jsx(make, {
              value: registry$1,
              children: props.children
            });
}

var Register = {
  make: ActionsManager$Register
};

export {
  useGetActiveAffordances ,
  Register ,
}
/* context Not a pure module */
