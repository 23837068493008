// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../../libs/i18n/ReactI18Next.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CurrentFolderManager from "../context/CurrentFolderManager.js";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

function FolderBrowserDetailsSwitcher(props) {
  var toggleFolderInfo = props.toggleFolderInfo;
  var showFolderInfo = props.showFolderInfo;
  var folder = CurrentFolderManager.useCurrentFolder();
  var showFolderInfo$1 = React.useMemo((function () {
          return Prelude.default(showFolderInfo, false);
        }), [showFolderInfo]);
  var title = React.useMemo((function () {
          if (showFolderInfo$1) {
            return JsxRuntime.jsx(ReactI18Next.Message.make, {
                        msg: {
                          NAME: "msg",
                          VAL: [
                            "catalog.browser.hide-details",
                            "Hide folder details"
                          ]
                        }
                      });
          } else {
            return JsxRuntime.jsx(ReactI18Next.Message.make, {
                        msg: {
                          NAME: "msg",
                          VAL: [
                            "catalog.browser.view-details",
                            "View folder details"
                          ]
                        }
                      });
          }
        }), [showFolderInfo$1]);
  var icon = React.useMemo((function () {
          if (showFolderInfo$1) {
            return JsxRuntime.jsx(Close, {});
          } else {
            return JsxRuntime.jsx(InfoOutlined, {});
          }
        }), [showFolderInfo$1]);
  var shown = React.useMemo((function () {
          return Curry._1(Prelude.OptionExported.$$Option.isSome, folder);
        }), [folder]);
  return React.useMemo((function () {
                if (shown) {
                  return JsxRuntime.jsx(Tooltip, {
                              children: JsxRuntime.jsx(IconButton, {
                                    children: Caml_option.some(icon),
                                    onClick: (function (param) {
                                        Prelude.OptionExported.$$Option.$$do(toggleFolderInfo, (function (fn) {
                                                fn();
                                              }));
                                      }),
                                    size: "large"
                                  }),
                              arrow: true,
                              title: title
                            });
                } else {
                  return null;
                }
              }), [
              shown,
              title,
              toggleFolderInfo,
              icon
            ]);
}

var make = FolderBrowserDetailsSwitcher;

export {
  make ,
}
/* react Not a pure module */
