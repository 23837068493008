// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CollectionId from "../../models/CollectionId.js";
import * as ReactDOMStyle from "@rescript/react/lib/es6/src/ReactDOMStyle.js";
import * as ReactI18next from "react-i18next";
import * as ActionsManager from "../../../../global/context/ActionsManager.js";
import Fab from "@mui/material/Fab";
import * as JsxRuntime from "react/jsx-runtime";
import * as CollectionsManager from "../../context/CollectionsManager.js";
import Stack from "@mui/material/Stack";
import * as ConfirmDeleteDialog from "../../../../global/components/ConfirmDeleteDialog.js";
import Tooltip from "@mui/material/Tooltip";
import * as CollectionsListService from "../../context/CollectionsListService.js";
import * as CollectionSelectionInfo from "./CollectionSelectionInfo.js";
import IconButton from "@mui/material/IconButton";
import * as CollectionsSelectionManager from "../../context/CollectionsSelectionManager.js";
import DialogContentText from "@mui/material/DialogContentText";
import CancelRounded from "@mui/icons-material/CancelRounded";
import DeleteForever from "@mui/icons-material/DeleteForever";

function CollectionsSelectionAffordance$ClearSelection(props) {
  var reset = Curry._1(Prelude.OptionExported.$$Option.getExn, CollectionsSelectionManager.useReset());
  return JsxRuntime.jsx(Tooltip, {
              children: JsxRuntime.jsx(IconButton, {
                    children: Caml_option.some(JsxRuntime.jsx(CancelRounded, {})),
                    onClick: (function (param) {
                        reset();
                      })
                  }),
              title: JsxRuntime.jsx(ReactI18next.Trans, {
                    i18nKey: "clearSelection",
                    children: "Clear collections selection"
                  })
            });
}

var ClearSelection = {
  make: CollectionsSelectionAffordance$ClearSelection
};

function CollectionsSelectionAffordance$BulkRemove$ConfirmDialog(props) {
  var count = props.items.length;
  var content = count > 0 ? JsxRuntime.jsx(ReactI18next.Trans, {
          i18nKey: "collections-manager.bulk-remove.confirmation-message",
          children: "Are you sure you want to remove {{count}} collections?",
          count: count
        }) : null;
  var content$1 = count > 1 ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx(DialogContentText, {
                  children: Caml_option.some(content)
                }),
            JsxRuntime.jsx(DialogContentText, {
                  children: Caml_option.some(JsxRuntime.jsxs(ReactI18next.Trans, {
                            i18nKey: "collections-manager.bulk-remove.enter-delete",
                            children: [
                              "To proceed you must enter the word ",
                              JsxRuntime.jsx("strong", {
                                    children: "DELETE"
                                  }),
                              " below."
                            ]
                          }))
                })
          ]
        }) : content;
  var match = ReactI18next.useTranslation();
  var confirmMessage = count > 1 ? match.t("DELETE") : undefined;
  return JsxRuntime.jsx(ConfirmDeleteDialog.make, {
              open_: true,
              content: Caml_option.some(content$1),
              onCancel: props.onCancel,
              onDelete: props.onDelete,
              confirmMessage: confirmMessage,
              fullWidth: true,
              maxWidth: "sm"
            });
}

var ConfirmDialog = {
  make: CollectionsSelectionAffordance$BulkRemove$ConfirmDialog
};

function CollectionsSelectionAffordance$BulkRemove(props) {
  var match = React.useState(function () {
        return "Idle";
      });
  var setState = match[1];
  var state = match[0];
  var getSelection = Curry._1(Prelude.OptionExported.$$Option.getExn, CollectionsSelectionManager.useGetCurrentSelection());
  var reset = Curry._1(Prelude.OptionExported.$$Option.getExn, CollectionsSelectionManager.useReset());
  var refresh = CollectionsListService.useRefresh();
  var bulkRemove = CollectionsManager.useBulkRemoveCollections();
  React.useEffect((function () {
          if (typeof state === "object" && state.TAG !== "Confirmation") {
            if (bulkRemove !== undefined) {
              Prelude.PromisedResult.map(bulkRemove(state._0), (function () {
                      reset();
                      refresh();
                      setState(function (param) {
                            return "Idle";
                          });
                    }));
            }
            
          }
          
        }), [
        state,
        bulkRemove,
        reset,
        refresh
      ]);
  var onCancel = React.useCallback((function () {
          setState(function (param) {
                return "Idle";
              });
        }), [setState]);
  var onDeleteConfirmed = React.useCallback((function () {
          if (typeof state !== "object") {
            return ;
          }
          if (state.TAG !== "Confirmation") {
            return ;
          }
          var items = state._0;
          setState(function (param) {
                return {
                        TAG: "Removing",
                        _0: items
                      };
              });
        }), [
        state,
        setState
      ]);
  var tmp;
  tmp = typeof state !== "object" || state.TAG !== "Confirmation" ? null : JsxRuntime.jsx(CollectionsSelectionAffordance$BulkRemove$ConfirmDialog, {
          items: state._0,
          onCancel: onCancel,
          onDelete: onDeleteConfirmed
        });
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Fab, {
                            children: Caml_option.some(JsxRuntime.jsx(DeleteForever, {})),
                            color: "error",
                            disabled: Curry._1(Prelude.OptionExported.$$Option.isNone, bulkRemove),
                            size: "small"
                          }),
                      onClick: (function (param) {
                          if (!Curry._1(Prelude.OptionExported.$$Option.isSome, bulkRemove)) {
                            return ;
                          }
                          var collections = CollectionId.$$Map.valuesToArray(getSelection());
                          setState(function (param) {
                                return {
                                        TAG: "Confirmation",
                                        _0: collections
                                      };
                              });
                        })
                    }),
                tmp
              ]
            });
}

var BulkRemove = {
  ConfirmDialog: ConfirmDialog,
  make: CollectionsSelectionAffordance$BulkRemove
};

function CollectionsSelectionAffordance$SelectionMenu(props) {
  var getCurrentSelectionIds = Curry._1(Prelude.OptionExported.$$Option.getExn, CollectionsSelectionManager.useGetCurrentSelectionIds());
  var count = React.useMemo((function () {
          return CollectionId.$$Set.size(getCurrentSelectionIds());
        }), [getCurrentSelectionIds]);
  if (count <= 0) {
    return null;
  }
  var style = ReactDOMStyle.unsafeAddProp({
        cursor: "default",
        userSelect: "none"
      }, "WebkitUserSelect", "none");
  return JsxRuntime.jsxs(Stack, {
              style: style,
              bgcolor: "action.selected",
              children: [
                JsxRuntime.jsx(CollectionsSelectionAffordance$ClearSelection, {}),
                JsxRuntime.jsx(CollectionSelectionInfo.make, {}),
                JsxRuntime.jsx(CollectionsSelectionAffordance$BulkRemove, {})
              ],
              direction: ["row"],
              spacing: "0.5rem",
              sx: {
                borderRadius: "9999px",
                alignItems: "center"
              }
            });
}

var SelectionMenu = {
  make: CollectionsSelectionAffordance$SelectionMenu
};

function CollectionsSelectionAffordance(props) {
  return JsxRuntime.jsx(ActionsManager.Register.make, {
              name: "collections-selection-menu",
              widget: (function (key) {
                  return JsxRuntime.jsx(CollectionsSelectionAffordance$SelectionMenu, {}, key);
                }),
              sequence: 500,
              children: props.children
            });
}

var make = CollectionsSelectionAffordance;

export {
  ClearSelection ,
  BulkRemove ,
  SelectionMenu ,
  make ,
}
/* react Not a pure module */
