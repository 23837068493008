// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "@sentry/react";

var RoutingInstrumentation = {};

var BrowserTracing = {};

var Replay = {};

function setUser(user) {
  React.setUser({
        id: user.id,
        email: user.email,
        username: user.email
      });
}

export {
  RoutingInstrumentation ,
  BrowserTracing ,
  Replay ,
  setUser ,
}
/* @sentry/react Not a pure module */
