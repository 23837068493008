// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";

var fields = {
  TAG: "Object",
  _0: [
    [
      "id",
      "Int"
    ],
    [
      "username",
      "String"
    ],
    [
      "first_name",
      {
        TAG: "Optional",
        _0: "String"
      }
    ],
    [
      "last_name",
      {
        TAG: "Optional",
        _0: "String"
      }
    ],
    [
      "email",
      {
        TAG: "Optional",
        _0: "String"
      }
    ],
    [
      "is_superuser",
      "Boolean"
    ]
  ]
};

var Deserializer = $$JSON.MakeDeserializer({
      fields: fields
    });

function fullname(user) {
  var first_name = Curry._2(Prelude.OptionExported.$$Option.map, user.first_name, (function (prim) {
          return prim.trim();
        }));
  var last_name = Curry._2(Prelude.OptionExported.$$Option.map, user.last_name, (function (prim) {
          return prim.trim();
        }));
  if (first_name === undefined) {
    if (last_name !== undefined) {
      return last_name;
    } else {
      return ;
    }
  }
  if (first_name === "" && last_name !== undefined) {
    if (last_name === "") {
      return ;
    } else {
      return last_name;
    }
  }
  if (last_name !== undefined && last_name !== "") {
    return first_name + " " + last_name;
  } else {
    return first_name;
  }
}

export {
  Deserializer ,
  fullname ,
}
/* Deserializer Not a pure module */
