// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as JsxRuntime from "react/jsx-runtime";

var context = React.createContext("MoveToTrash");

var make = context.Provider;

function use() {
  return React.useContext(context);
}

function CatalogDeleteVariantContext(props) {
  return JsxRuntime.jsx(make, {
              value: props.variant,
              children: props.children
            });
}

var make$1 = CatalogDeleteVariantContext;

export {
  use ,
  make$1 as make,
}
/* context Not a pure module */
