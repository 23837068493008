// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as JsxRuntime from "react/jsx-runtime";

var context = React.createContext(undefined);

var make = context.Provider;

function useSelection() {
  return React.useContext(context);
}

var context$1 = React.createContext(undefined);

var make$1 = context$1.Provider;

function useSelect() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context$1), (function (param) {
                return param.select;
              }));
}

function useClear() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context$1), (function (param) {
                return param.clear;
              }));
}

function BulkEditContext(props) {
  var match = React.useState(function () {
        
      });
  var setSelection = match[1];
  var select = function (documents) {
    setSelection(function (param) {
          return documents;
        });
  };
  var clear = function () {
    setSelection(function (param) {
          
        });
  };
  return JsxRuntime.jsx(make, {
              value: match[0],
              children: JsxRuntime.jsx(make$1, {
                    value: {
                      select: select,
                      clear: clear
                    },
                    children: props.children
                  })
            });
}

var $$Selection = {};

var SelectionDispatcher = {};

var make$2 = BulkEditContext;

export {
  $$Selection ,
  useSelection ,
  SelectionDispatcher ,
  useSelect ,
  useClear ,
  make$2 as make,
}
/* context Not a pure module */
