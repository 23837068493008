// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as JsxRuntime from "react/jsx-runtime";

var context = React.createContext(undefined);

var make = context.Provider;

function useCurrentApplication() {
  return React.useContext(context);
}

function useCurrentApplicationLink() {
  var app = React.useContext(context);
  if (app !== undefined) {
    return app.path;
  } else {
    return "/";
  }
}

function useCurrentApplicationName() {
  var app = React.useContext(context);
  if (app !== undefined) {
    return app.name;
  } else {
    return {
            NAME: "str",
            VAL: "Photo Cuba"
          };
  }
}

function ActiveApplicationService(props) {
  return JsxRuntime.jsx(make, {
              value: props.app,
              children: props.children
            });
}

var make$1 = ActiveApplicationService;

export {
  useCurrentApplication ,
  useCurrentApplicationLink ,
  useCurrentApplicationName ,
  make$1 as make,
}
/* context Not a pure module */
