// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Tag from "../models/Tag.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Hooks from "@mantine/hooks";
import * as JsxRuntime from "react/jsx-runtime";
import * as TagsSelectionManager from "./TagsSelectionManager.js";

var context = React.createContext({});

var make = context.Provider;

function dispatch(filters, action) {
  if (typeof action !== "object") {
    if (action === "RemoveStartsWith") {
      var newrecord = Caml_obj.obj_dup(filters);
      newrecord.startswith = undefined;
      return newrecord;
    }
    var newrecord$1 = Caml_obj.obj_dup(filters);
    newrecord$1.contains = undefined;
    return newrecord$1;
  } else {
    switch (action.TAG) {
      case "Reset" :
          return Tag.Filters.clone(action._0);
      case "StartsWith" :
          var newrecord$2 = Caml_obj.obj_dup(filters);
          newrecord$2.startswith = action._0;
          return newrecord$2;
      case "Contains" :
          var newrecord$3 = Caml_obj.obj_dup(filters);
          newrecord$3.contains = action._0;
          return newrecord$3;
      case "Approval" :
          var newrecord$4 = Caml_obj.obj_dup(filters);
          newrecord$4.approval_status = action._0;
          return newrecord$4;
      case "SetOrderColumn" :
          var newrecord$5 = Caml_obj.obj_dup(filters);
          newrecord$5.order_by = action._0;
          return newrecord$5;
      case "SetOrderDirection" :
          var newrecord$6 = Caml_obj.obj_dup(filters);
          newrecord$6.order_direction = action._0;
          return newrecord$6;
      
    }
  }
}

var context$1 = React.createContext({
      setFilters: (function (param) {
          
        }),
      setStartsWith: (function (param) {
          
        }),
      removeStartsWith: (function () {
          
        }),
      setContains: (function (param) {
          
        }),
      removeContains: (function () {
          
        }),
      setApprovalStatus: (function (param) {
          
        }),
      setOrderColumn: (function (param) {
          
        }),
      setOrderDirection: (function (param) {
          
        })
    });

var make$1 = context$1.Provider;

function useFilters() {
  return React.useContext(context);
}

function useApprovalStatus() {
  var match = React.useContext(context);
  var approvalStatus = match.approval_status;
  if (approvalStatus !== undefined) {
    return approvalStatus;
  } else {
    return "any";
  }
}

function useStartsWith() {
  var match = React.useContext(context);
  var startswith = match.startswith;
  if (startswith !== undefined) {
    return startswith;
  } else {
    return "";
  }
}

function useContains() {
  var match = React.useContext(context);
  var contains = match.contains;
  if (contains !== undefined) {
    return contains;
  } else {
    return "";
  }
}

function useOrderColumn() {
  var match = React.useContext(context);
  var orderBy = match.order_by;
  if (orderBy !== undefined) {
    return orderBy;
  } else {
    return "tokenized";
  }
}

function useOrderDirection() {
  var match = React.useContext(context);
  var orderDirection = match.order_direction;
  if (orderDirection !== undefined) {
    return orderDirection;
  } else {
    return "";
  }
}

function useSetFilters() {
  return React.useContext(context$1).setFilters;
}

function useSetStartsWith() {
  return React.useContext(context$1).setStartsWith;
}

function useSetContains() {
  return React.useContext(context$1).setContains;
}

function useRemoveStartsWith() {
  return React.useContext(context$1).removeStartsWith;
}

function useRemoveContains() {
  return React.useContext(context$1).removeContains;
}

function useSetApprovalStatus() {
  return React.useContext(context$1).setApprovalStatus;
}

function useSetOrderColumn() {
  return React.useContext(context$1).setOrderColumn;
}

function useSetOrderDirection() {
  return React.useContext(context$1).setOrderDirection;
}

function TagsFiltersContext(props) {
  var reset = TagsSelectionManager.useReset();
  var match = Hooks.useLocalStorage({
        key: "photocuba-tags-approval-status",
        defaultValue: "any",
        deserialize: Tag.ApprovalStatus.fromString,
        serialize: Tag.ApprovalStatus.toString,
        getInitialValueInEffect: false
      });
  var setApprovalStatus = match[1];
  var match$1 = Hooks.useLocalStorage({
        key: "photocuba-tags-startswith",
        getInitialValueInEffect: false
      });
  var removeStartsWith = match$1[2];
  var setStartsWith = match$1[1];
  var match$2 = Hooks.useLocalStorage({
        key: "photocuba-tags-contains",
        getInitialValueInEffect: false
      });
  var removeContains = match$2[2];
  var setContains = match$2[1];
  var match$3 = Hooks.useLocalStorage({
        key: "photocuba-tags-order-by",
        defaultValue: "tokenized",
        deserialize: Tag.Order.columnFromString,
        serialize: Tag.Order.columnToString,
        getInitialValueInEffect: false
      });
  var setOrderBy = match$3[1];
  var match$4 = Hooks.useLocalStorage({
        key: "photocuba-tags-order-direction",
        defaultValue: "",
        deserialize: Tag.Order.directionFromString,
        serialize: Tag.Order.directionToString,
        getInitialValueInEffect: false
      });
  var setOrderDirection = match$4[1];
  var match$5 = React.useReducer(dispatch, {
        startswith: match$1[0],
        contains: match$2[0],
        approval_status: match[0],
        order_by: match$3[0],
        order_direction: match$4[0]
      });
  var dispatch$1 = match$5[1];
  var filters = match$5[0];
  var setFilters = function (filters) {
    setApprovalStatus(Prelude.default(filters.approval_status, "any"));
    var s = filters.startswith;
    if (s !== undefined) {
      setStartsWith(s);
    } else {
      removeStartsWith();
    }
    var s$1 = filters.contains;
    if (s$1 !== undefined) {
      setContains(s$1);
    } else {
      removeContains();
    }
    Prelude.OptionExported.$$Option.$$do(reset, (function (fn) {
            fn();
          }));
    dispatch$1({
          TAG: "Reset",
          _0: filters
        });
  };
  var setStartsWith$1 = function (start) {
    setStartsWith(start);
    Prelude.OptionExported.$$Option.$$do(reset, (function (fn) {
            fn();
          }));
    dispatch$1({
          TAG: "StartsWith",
          _0: start
        });
  };
  var removeStartsWith$1 = function () {
    removeStartsWith();
    Prelude.OptionExported.$$Option.$$do(reset, (function (fn) {
            fn();
          }));
    dispatch$1("RemoveStartsWith");
  };
  var setContains$1 = function (value) {
    setContains(value);
    Prelude.OptionExported.$$Option.$$do(reset, (function (fn) {
            fn();
          }));
    dispatch$1({
          TAG: "Contains",
          _0: value
        });
  };
  var removeContains$1 = function () {
    removeContains();
    Prelude.OptionExported.$$Option.$$do(reset, (function (fn) {
            fn();
          }));
    dispatch$1("RemoveContains");
  };
  var setApprovalStatus$1 = function (approval) {
    setApprovalStatus(approval);
    Prelude.OptionExported.$$Option.$$do(reset, (function (fn) {
            fn();
          }));
    dispatch$1({
          TAG: "Approval",
          _0: approval
        });
  };
  var setOrderColumn = function (column) {
    setOrderBy(column);
    dispatch$1({
          TAG: "SetOrderColumn",
          _0: column
        });
  };
  var setOrderDirection$1 = function (dir) {
    setOrderDirection(dir);
    dispatch$1({
          TAG: "SetOrderDirection",
          _0: dir
        });
  };
  console.log("TagsFiltersContext", filters);
  return JsxRuntime.jsx(make$1, {
              value: {
                setFilters: setFilters,
                setStartsWith: setStartsWith$1,
                removeStartsWith: removeStartsWith$1,
                setContains: setContains$1,
                removeContains: removeContains$1,
                setApprovalStatus: setApprovalStatus$1,
                setOrderColumn: setOrderColumn,
                setOrderDirection: setOrderDirection$1
              },
              children: JsxRuntime.jsx(make, {
                    value: filters,
                    children: props.children
                  })
            });
}

var make$2 = TagsFiltersContext;

export {
  useSetFilters ,
  useSetStartsWith ,
  useSetContains ,
  useRemoveStartsWith ,
  useRemoveContains ,
  useSetApprovalStatus ,
  useSetOrderColumn ,
  useSetOrderDirection ,
  useFilters ,
  useApprovalStatus ,
  useStartsWith ,
  useContains ,
  useOrderColumn ,
  useOrderDirection ,
  make$2 as make,
}
/* context Not a pure module */
