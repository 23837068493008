// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_math from "rescript/lib/es6/js_math.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as $$Document from "../../catalog/models/Document.js";
import * as Settings from "../../../Settings.js";
import * as AuthToken from "../../../global/models/AuthToken.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SearchQuery from "../models/SearchQuery.js";
import * as UserContext from "../../../global/context/UserContext.js";
import * as ReactI18Next from "../../../../libs/i18n/ReactI18Next.js";
import * as DocumentsGrid from "../../catalog/components/DocumentsGrid.js";
import * as ReactI18next from "react-i18next";
import * as DocumentsTable from "../../catalog/components/DocumentsTable.js";
import * as NamespaceContext from "../../../global/context/NamespaceContext.js";
import * as SelectionManager from "../../catalog/context/SelectionManager.js";
import * as SearchAPIEndpoint from "../api/SearchAPIEndpoint.js";
import * as JsxRuntime from "react/jsx-runtime";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import * as SearchFiltersContext from "../context/SearchFiltersContext.js";
import Pagination from "@mui/material/Pagination";
import Typography from "@mui/material/Typography";
import * as WithCatalogLassoSelector from "../../catalog/components/WithCatalogLassoSelector.js";
import * as CatalogBrowserViewContext from "../../catalog/context/CatalogBrowserViewContext.js";
import * as CurrentDocumentListManager from "../../catalog/context/CurrentDocumentListManager.js";

function SearchResultArea$EmptyFiltersMsg(props) {
  return JsxRuntime.jsxs(Stack, {
              children: [
                JsxRuntime.jsx(Typography, {
                      variant: "h6",
                      children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                msg: {
                                  NAME: "msg",
                                  VAL: [
                                    "search.not-filters-heading",
                                    "Search for something"
                                  ]
                                }
                              }))
                    }),
                JsxRuntime.jsx(Typography, {
                      variant: "body1",
                      children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                msg: {
                                  NAME: "msg",
                                  VAL: [
                                    "search.not-filters-explanation",
                                    "You have not enter any search criteria.  You should provide at least one non-empty filter\n              for the search to be performed."
                                  ]
                                }
                              }))
                    })
              ],
              direction: ["column"],
              spacing: 4.0
            });
}

var EmptyFiltersMsg = {
  make: SearchResultArea$EmptyFiltersMsg
};

function SearchResultArea$EmptyResultsMsg(props) {
  return JsxRuntime.jsxs(Stack, {
              children: [
                JsxRuntime.jsx(Typography, {
                      variant: "h6",
                      children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                msg: {
                                  NAME: "msg",
                                  VAL: [
                                    "search.empty-heading",
                                    "Nothing found"
                                  ]
                                }
                              }))
                    }),
                JsxRuntime.jsx(Typography, {
                      variant: "body1",
                      children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                msg: {
                                  NAME: "msg",
                                  VAL: [
                                    "search.empty-explanation",
                                    "There are no images that match your current query. Please, remove some filters\n             to get some results here."
                                  ]
                                }
                              }))
                    })
              ],
              direction: ["column"],
              spacing: 4.0
            });
}

var EmptyResultsMsg = {
  make: SearchResultArea$EmptyResultsMsg
};

function SearchResultArea$SearchResultsGrid(props) {
  var label = props.label;
  var paginator = props.paginator;
  var documents = props.documents;
  var view = CatalogBrowserViewContext.useCurrentView();
  return JsxRuntime.jsx(WithCatalogLassoSelector.make, {
              documents: documents,
              children: view === "icons" ? JsxRuntime.jsx(DocumentsGrid.make, {
                      documents: documents,
                      paginator: Caml_option.some(paginator),
                      label: Caml_option.some(label)
                    }) : JsxRuntime.jsx(DocumentsTable.make, {
                      documents: documents,
                      paginator: Caml_option.some(paginator),
                      label: Caml_option.some(label)
                    })
            });
}

var SearchResultsGrid = {
  make: SearchResultArea$SearchResultsGrid
};

function SearchResultArea$ErrorMessage(props) {
  var retry = props.retry;
  return JsxRuntime.jsxs(Stack, {
              children: [
                JsxRuntime.jsx(Typography, {
                      variant: "h6",
                      children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                msg: {
                                  NAME: "msg",
                                  VAL: [
                                    "search.error-heading",
                                    "Server error"
                                  ]
                                }
                              }))
                    }),
                JsxRuntime.jsx(Typography, {
                      variant: "body1",
                      children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                msg: {
                                  NAME: "msg",
                                  VAL: [
                                    "search.error-explanation",
                                    "There was an error while contacting the server.  This can be due to connectitivy\n            issues, or other problems.  Please, wait for a couple of minutes a retry."
                                  ]
                                }
                              }))
                    }),
                JsxRuntime.jsx(Button, {
                      children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                msg: {
                                  NAME: "msg",
                                  VAL: [
                                    "buttons.retry",
                                    "Retry"
                                  ]
                                }
                              })),
                      onClick: (function (param) {
                          retry();
                        }),
                      fullWidth: false
                    })
              ],
              direction: ["column"],
              spacing: 4.0
            });
}

var ErrorMessage = {
  make: SearchResultArea$ErrorMessage
};

function SearchResultArea(props) {
  var __allowEmptyFilters = props.allowEmptyFilters;
  var allowEmptyFilters = __allowEmptyFilters !== undefined ? __allowEmptyFilters : false;
  var namespace = NamespaceContext.useNamespace();
  var token = Prelude.default(UserContext.useAccessToken(), AuthToken.Access.$$null);
  var clearSelection = Prelude.default(SelectionManager.Documents.useReset(), (function () {
          
        }));
  var filters = SearchFiltersContext.useFilters();
  var fixtures = SearchFiltersContext.useFixtures();
  var resolvedFilters = SearchFiltersContext.useResolvedFilters();
  var page = CurrentDocumentListManager.usePage();
  var setPage = CurrentDocumentListManager.useSetPage();
  var match = React.useState(function () {
        return {
                domain: undefined
              };
      });
  var setState = match[1];
  var state = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setTimer = match$1[1];
  var timer = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setResults = match$2[1];
  var results = match$2[0];
  var reload = React.useCallback((function () {
          var domain = state.domain;
          if (domain !== undefined) {
            return setState(function (param) {
                        return {
                                domain: SearchQuery.Domain.clone(domain)
                              };
                      });
          }
          
        }), [state]);
  var clearTimer = React.useCallback((function () {
          if (timer !== undefined) {
            clearTimeout(Caml_option.valFromOption(timer));
          }
          setTimer(function (param) {
                
              });
        }), [timer]);
  var activeDocument = CurrentDocumentListManager.useActiveDocument();
  var resetCurrentDocumentList = CurrentDocumentListManager.useReset();
  var performSearch = React.useCallback((function (pageSize) {
          var domain = state.domain;
          if (domain === undefined) {
            return setResults(function (param) {
                        
                      });
          }
          var previousDomain = Curry._2(Prelude.OptionExported.$$Option.map, Curry._2(Prelude.OptionExported.$$Option.flatMap, results, Prelude.Result.ok), (function (r) {
                  return r.domain;
                }));
          var sameDomain = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, previousDomain, (function (d) {
                      return Caml_obj.equal(d, domain);
                    })), false);
          var previousPage = Curry._2(Prelude.OptionExported.$$Option.map, Curry._2(Prelude.OptionExported.$$Option.flatMap, results, Prelude.Result.ok), (function (r) {
                  return r.page;
                }));
          var samePage = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, previousPage, (function (p) {
                      return p === page;
                    })), false);
          if (sameDomain && samePage) {
            return ;
          }
          var params = SearchQuery.Params.makeWithNs(namespace.id);
          var body = SearchQuery.Params.makeWithDomain(domain);
          Prelude.thenDo(SearchAPIEndpoint.Documents.$$fetch(token, params, page, pageSize, body), (function (r) {
                  if (r.TAG === "Ok") {
                    var match = r._0;
                    var count = match.count;
                    var documents = match.results.map($$Document.fromBackend);
                    clearSelection();
                    var active = Curry._2(Prelude.OptionExported.$$Option.flatMap, activeDocument, (function (param) {
                            return Curry._2(Prelude.OptionExported.$$Option.flatMap, previousPage, (function (previousPage) {
                                          if (page < previousPage) {
                                            return Prelude.$$Array.last(documents);
                                          } else {
                                            return Prelude.$$Array.first(documents);
                                          }
                                        }));
                          }));
                    resetCurrentDocumentList({
                          documents: documents,
                          active: active,
                          count: count,
                          page: page,
                          pageSize: pageSize
                        });
                    return setResults(function (param) {
                                return {
                                        TAG: "Ok",
                                        _0: {
                                          count: count,
                                          domain: domain,
                                          page: page
                                        }
                                      };
                              });
                  }
                  console.error("SearchResultArea", r._0);
                  setResults(function (param) {
                        return {
                                TAG: "Error",
                                _0: undefined
                              };
                      });
                }));
        }), [
        state,
        page,
        results,
        namespace,
        clearSelection,
        setResults,
        resetCurrentDocumentList
      ]);
  React.useEffect((function () {
          console.log("SearchResultArea", "filters changed", {
                filters: filters,
                fixtures: fixtures,
                "eq?": Caml_obj.equal(filters, fixtures),
                allowEmptyFilters: allowEmptyFilters
              });
          clearTimer();
          var timeoutId = setTimeout((function () {
                  var resolvedDomain = SearchQuery.Domain.fromFilters(resolvedFilters);
                  var fixturesDomain = Curry._2(Prelude.OptionExported.$$Option.map, fixtures, SearchQuery.Domain.fromFilters);
                  if (!(Caml_obj.notequal(resolvedDomain, fixturesDomain) || allowEmptyFilters)) {
                    return ;
                  }
                  var domain = SearchQuery.Domain.isEmpty(resolvedDomain) && !allowEmptyFilters ? undefined : resolvedDomain;
                  setState(function (param) {
                        return {
                                domain: domain
                              };
                      });
                  setPage(1);
                }), 800);
          setTimer(function (param) {
                return Caml_option.some(timeoutId);
              });
          return clearTimer;
        }), [
        resolvedFilters,
        fixtures
      ]);
  var match$3 = Settings.Pagination.SearchResults.use();
  var pageSize = match$3[0];
  React.useEffect((function () {
          performSearch(pageSize);
        }), [
        state,
        page,
        pageSize
      ]);
  var documents = CurrentDocumentListManager.useDocuments();
  var domain = Curry._2(Prelude.OptionExported.$$Option.map, filters, SearchQuery.Domain.fromFilters);
  if (Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, domain, SearchQuery.Domain.isEmpty), true) && !allowEmptyFilters) {
    return JsxRuntime.jsx(SearchResultArea$EmptyFiltersMsg, {});
  }
  var tmp;
  if (results !== undefined) {
    if (results.TAG === "Ok") {
      var count = results._0.count;
      if (documents.length !== 0 && count !== 0) {
        var pages = Js_math.ceil_int(count / pageSize);
        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(SearchResultArea$SearchResultsGrid, {
                      documents: documents,
                      paginator: JsxRuntime.jsxs(Stack, {
                            alignItems: "center",
                            children: [
                              JsxRuntime.jsx(Pagination, {
                                    count: pages,
                                    onChange: (function (param, page) {
                                        setPage(page);
                                      }),
                                    page: page,
                                    showFirstButton: false,
                                    showLastButton: false
                                  }),
                              JsxRuntime.jsx(Button, {
                                    children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                              msg: {
                                                NAME: "msg",
                                                VAL: [
                                                  "search.button-reload",
                                                  "Reload"
                                                ]
                                              }
                                            })),
                                    onClick: (function (param) {
                                        setResults(function (r) {
                                              return Curry._2(Prelude.OptionExported.$$Option.map, r, (function (r) {
                                                            return Curry._2(Prelude.Result.map, r, (function (r) {
                                                                          return {
                                                                                  count: r.count,
                                                                                  domain: r.domain,
                                                                                  page: 0
                                                                                };
                                                                        }));
                                                          }));
                                            });
                                        Prelude.thenDo(Prelude.Promises.ellapsed(0), (function () {
                                                reload();
                                              }));
                                      })
                                  })
                            ],
                            direction: ["row"],
                            spacing: 2.0
                          }),
                      label: JsxRuntime.jsx(ReactI18next.Trans, {
                            i18nKey: "search.image-count",
                            children: "Found {{count}} images",
                            count: new (Intl.NumberFormat)().format(count)
                          })
                    }),
                JsxRuntime.jsx(Stack, {
                      alignItems: "center",
                      children: Caml_option.some(JsxRuntime.jsx(Pagination, {
                                count: pages,
                                onChange: (function (param, page) {
                                    setPage(page);
                                  }),
                                page: page,
                                showFirstButton: true,
                                showLastButton: true
                              })),
                      spacing: 2.0,
                      useFlexGap: true
                    })
              ]
            });
      } else {
        tmp = JsxRuntime.jsx(SearchResultArea$EmptyResultsMsg, {});
      }
    } else {
      tmp = JsxRuntime.jsx(SearchResultArea$ErrorMessage, {
            retry: (function () {
                setPage(1);
              })
          });
    }
  } else {
    tmp = null;
  }
  return JsxRuntime.jsx(Stack, {
              children: Caml_option.some(tmp),
              direction: ["column"],
              spacing: 2.0
            });
}

var make = SearchResultArea;

export {
  EmptyFiltersMsg ,
  EmptyResultsMsg ,
  SearchResultsGrid ,
  ErrorMessage ,
  make ,
}
/* react Not a pure module */
