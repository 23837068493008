// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";
import * as Migration__0001 from "./migrations/Migration__0001.js";
import * as Migration__0002 from "./migrations/Migration__0002.js";
import * as Migration__0003 from "./migrations/Migration__0003.js";
import * as Migration__0004 from "./migrations/Migration__0004.js";
import * as ReIndexed_Transaction from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed_Transaction.js";

function migrations() {
  return [
          Migration__0001.make,
          Migration__0002.make,
          Migration__0003.make,
          Migration__0004.make
        ];
}

var Database = ReIndexed.MakeDatabase({
      migrations: migrations
    });

function makeRead() {
  return {
          documents: "NoOp",
          folders: "NoOp",
          bundlers: "NoOp"
        };
}

function makeWrite() {
  return {
          documents: [],
          folders: [],
          bundlers: []
        };
}

function makeResponse() {
  return {
          documents: [],
          folders: [],
          bundlers: []
        };
}

var QueryDef = {
  makeRead: makeRead,
  makeWrite: makeWrite,
  makeResponse: makeResponse
};

var include = ReIndexed.MakeUnboundQuery(QueryDef);

function SharedDBQuery$Using(props) {
  return null;
}

var Using = {
  make: SharedDBQuery$Using
};

var defaultDescription_removing = [];

var defaultDescription = {
  saving: 0,
  removing: defaultDescription_removing,
  clearing: false
};

function desc(actions) {
  return actions.reduce((function (param, action) {
                var clearing = param.clearing;
                var removing = param.removing;
                var saving = param.saving;
                if (typeof action !== "object") {
                  return {
                          saving: saving,
                          removing: removing,
                          clearing: true
                        };
                } else if (action.TAG === "Save") {
                  return {
                          saving: saving + 1 | 0,
                          removing: removing,
                          clearing: clearing
                        };
                } else {
                  return {
                          saving: saving,
                          removing: Prelude.$$Array.append(removing, action._0),
                          clearing: clearing
                        };
                }
              }), defaultDescription);
}

function describe(actions) {
  var actions$1 = ReIndexed_Transaction._simplifyWriteRequest(actions);
  var result = Curry._1(Prelude.Dict.fromArray, Curry._1(Prelude.Dict.entries, actions$1).map(function (param) {
            return [
                    param[0],
                    desc(param[1])
                  ];
          }));
  if (Prelude.$$Array.isEmpty(Object.keys(result))) {
    return ;
  } else {
    return result;
  }
}

function info(msg, actions) {
  console.log(msg, describe(actions));
}

function debug(msg, actions) {
  console.log(msg, actions);
}

function warn(msg, actions) {
  console.warn(msg, describe(actions));
}

function error(msg, actions) {
  console.error(msg, describe(actions));
}

var ActionLogging = {
  info: info,
  debug: debug,
  warn: warn,
  error: error
};

var makeRead$1 = include.makeRead;

var makeWrite$1 = include.makeWrite;

var makeResponse$1 = include.makeResponse;

var read = include.read;

var write = include.write;

var $$do = include.$$do;

var doEach = include.doEach;

var Unsafe = include.Unsafe;

var Safe = include.Safe;

var value = include.value;

export {
  Database ,
  QueryDef ,
  makeRead$1 as makeRead,
  makeWrite$1 as makeWrite,
  makeResponse$1 as makeResponse,
  read ,
  write ,
  $$do ,
  doEach ,
  Unsafe ,
  Safe ,
  value ,
  Using ,
  ActionLogging ,
}
/* Database Not a pure module */
