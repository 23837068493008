// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Settings from "../../../Settings.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../../libs/i18n/ReactI18Next.js";
import * as SearchMetaMaker from "../../search/utils/SearchMetaMaker.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as AccountsAPIEndpoint from "../api/AccountsAPIEndpoint.js";

function getValueName(f) {
  return f.name;
}

function getValueKey(f) {
  return String(f.id);
}

var defaultPageSize = Settings.Autocomplete.maxItems;

var notFoundMessage = Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
          msg: {
            NAME: "msg",
            VAL: [
              "accounts.group-not-found",
              "No matching groups"
            ]
          }
        }));

var $$fetch = AccountsAPIEndpoint.Groups.$$fetch;

var Impl = SearchMetaMaker.MakeAutocomplete({
      identifier: "GroupsAutocomplete",
      defaultValue: undefined,
      getValueName: getValueName,
      getValueKey: getValueKey,
      makeSolo: undefined,
      notFoundMessage: notFoundMessage,
      promptMessage: undefined,
      defaultPageSize: defaultPageSize,
      groupBy: undefined,
      renderOption: undefined,
      $$fetch: $$fetch
    });

function GroupsAutocomplete(props) {
  return JsxRuntime.jsx(Impl.make, {
              items: props.groups,
              onChange: props.onChange,
              getFilter: (function (value) {
                  return {
                          contains: value
                        };
                }),
              label: props.label,
              variant: props.variant,
              sx: props.sx,
              fullWidth: props.fullWidth,
              limitTags: props.limitTags,
              disableCloseOnSelect: props.disableCloseOnSelect,
              multiple: true,
              freeSolo: false,
              disabled: props.disabled,
              helperText: props.helperText
            });
}

var make = GroupsAutocomplete;

export {
  make ,
}
/* Impl Not a pure module */
