// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UserContext from "../../../global/context/UserContext.js";
import * as ReactI18Next from "../../../../libs/i18n/ReactI18Next.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as JsxRuntime from "react/jsx-runtime";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import * as InstalledPackagesAPIEndpoint from "../api/InstalledPackagesAPIEndpoint.js";

function sort(pks) {
  return Belt_SortArray.stableSortBy(pks, (function (pkg1, pkg2) {
                var match = pkg1.name.toLowerCase();
                var match$1 = pkg2.name.toLowerCase();
                switch (match) {
                  case "django" :
                      if (match$1 === "django") {
                        return 0;
                      }
                      break;
                  case "python" :
                      if (match$1 === "python") {
                        return 0;
                      } else {
                        return -1;
                      }
                  default:
                    
                }
                if (match$1 === "python") {
                  return 1;
                } else if (match === "django") {
                  return -1;
                } else if (match$1 === "django") {
                  return 1;
                } else if (pkg1.name === pkg2.name) {
                  return 0;
                } else if (pkg1.name < pkg2.name) {
                  return -1;
                } else {
                  return 1;
                }
              }));
}

function InstalledPackagesTable(props) {
  var token = UserContext.useAccessToken();
  var match = React.useState(function () {
        
      });
  var setPackages = match[1];
  var packages = match[0];
  Prelude.OptionExported.$$Option.$$do(token, (function (token) {
          if (packages !== undefined) {
            return ;
          } else {
            return Prelude.thenDo(Prelude.thenResolve(InstalledPackagesAPIEndpoint.read(token), sort), (function (pkgs) {
                          setPackages(function (param) {
                                return pkgs;
                              });
                        }));
          }
        }));
  if (packages !== undefined && packages.length !== 0) {
    return JsxRuntime.jsx(TableContainer, {
                children: Caml_option.some(JsxRuntime.jsxs(Table, {
                          children: [
                            JsxRuntime.jsx(TableHead, {
                                  children: Caml_option.some(JsxRuntime.jsxs(TableRow, {
                                            children: [
                                              JsxRuntime.jsx(TableCell, {
                                                    children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                              msg: {
                                                                NAME: "str",
                                                                VAL: "Installed Package"
                                                              }
                                                            }))
                                                  }),
                                              JsxRuntime.jsx(TableCell, {
                                                    children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                              msg: {
                                                                NAME: "str",
                                                                VAL: "Version"
                                                              }
                                                            }))
                                                  })
                                            ]
                                          }))
                                }),
                            JsxRuntime.jsx(TableBody, {
                                  children: Caml_option.some(packages.map(function (pkg) {
                                            return JsxRuntime.jsxs(TableRow, {
                                                        children: [
                                                          JsxRuntime.jsx(TableCell, {
                                                                children: Caml_option.some(pkg.name)
                                                              }),
                                                          JsxRuntime.jsx(TableCell, {
                                                                children: Caml_option.some(pkg.version)
                                                              })
                                                        ]
                                                      }, pkg.name);
                                          }))
                                })
                          ],
                          size: "medium"
                        }))
              });
  } else {
    return null;
  }
}

var make = InstalledPackagesTable;

export {
  make ,
}
/* react Not a pure module */
