// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../../../libs/i18n/ReactI18Next.js";
import * as ReactDOMStyle from "@rescript/react/lib/es6/src/ReactDOMStyle.js";
import * as ReactI18next from "react-i18next";
import * as ActionsManager from "../../../../global/context/ActionsManager.js";
import * as ClipboardManager from "../../context/ClipboardManager.js";
import * as JsxRuntime from "react/jsx-runtime";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import LinearProgress from "@mui/material/LinearProgress";
import DialogContentText from "@mui/material/DialogContentText";
import CancelRounded from "@mui/icons-material/CancelRounded";
import ContentPasteGoOutlined from "@mui/icons-material/ContentPasteGoOutlined";

function ClipboardAffordance$ClearClipboard(props) {
  var clear = ClipboardManager.Contents.useClear();
  var clear$1 = React.useCallback((function () {
          Prelude.OptionExported.$$Option.$$do(clear, (function (fn) {
                  fn();
                }));
        }), [clear]);
  return JsxRuntime.jsx(Tooltip, {
              children: JsxRuntime.jsx(IconButton, {
                    children: Caml_option.some(JsxRuntime.jsx(CancelRounded, {})),
                    onClick: (function (param) {
                        clear$1();
                      })
                  }),
              title: JsxRuntime.jsx(ReactI18next.Trans, {
                    i18nKey: "clearClipboard",
                    children: "Clear clipboard"
                  })
            });
}

var ClearClipboard = {
  make: ClipboardAffordance$ClearClipboard
};

function ClipboardAffordance$PasteButton(props) {
  var paster = props.paster;
  var counter = props.counter;
  var items = React.useMemo((function () {
          return counter();
        }), [counter]);
  var match = React.useState(function () {
        return false;
      });
  var setBusy = match[1];
  var busy = match[0];
  var match$1 = React.useState(function () {
        return 0;
      });
  var setProgress = match$1[1];
  var progress = match$1[0];
  var disabled = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, paster, (function (param) {
              return false;
            })), true) || !props.allowed() || busy;
  var onClick = React.useCallback((function (param) {
          setBusy(function (param) {
                return true;
              });
          Prelude.OptionExported.$$Option.$$do(paster, (function (fn) {
                  fn((function (param) {
                          setProgress(function (p) {
                                return p + 1 | 0;
                              });
                        }), (function () {
                          setBusy(function (param) {
                                return false;
                              });
                        }));
                }));
        }), [paster]);
  var dialog = React.useMemo((function () {
          if (!busy) {
            return null;
          }
          var progress$1 = Caml_int32.div(Math.imul(progress, 100), items);
          var onClose = function (param, param$1) {
            
          };
          return JsxRuntime.jsxs(Dialog, {
                      disableEscapeKeyDown: true,
                      open: true,
                      children: [
                        JsxRuntime.jsx(DialogTitle, {
                              children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                        msg: {
                                          NAME: "str",
                                          VAL: "Pasting..."
                                        }
                                      }))
                            }),
                        JsxRuntime.jsxs(DialogContent, {
                              children: [
                                JsxRuntime.jsx(DialogContentText, {
                                      children: Caml_option.some(JsxRuntime.jsx(LinearProgress, {
                                                value: progress$1,
                                                variant: "determinate"
                                              }))
                                    }),
                                JsxRuntime.jsx(DialogContentText, {
                                      children: Caml_option.some(String(progress$1) + "%"),
                                      sx: {
                                        textAlign: "center"
                                      }
                                    })
                              ]
                            })
                      ],
                      fullWidth: true,
                      maxWidth: "sm",
                      onClose: onClose
                    });
        }), [
        busy,
        progress,
        items
      ]);
  if (items > 0) {
    return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsx(Badge, {
                        badgeContent: Caml_option.some(items - progress | 0),
                        children: Caml_option.some(JsxRuntime.jsx(Button, {
                                  children: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                            msg: {
                                              NAME: "str",
                                              VAL: "Paste"
                                            }
                                          })),
                                  onClick: onClick,
                                  disabled: disabled,
                                  startIcon: Caml_option.some(JsxRuntime.jsx(ContentPasteGoOutlined, {}))
                                })),
                        color: "secondary"
                      }),
                  dialog
                ]
              });
  } else {
    return null;
  }
}

var PasteButton = {
  make: ClipboardAffordance$PasteButton
};

function ClipboardAffordance$ClipboardAffordance(props) {
  var counter = props.counter;
  var items = counter();
  if (items <= 0) {
    return null;
  }
  var style = ReactDOMStyle.unsafeAddProp({
        cursor: "default",
        userSelect: "none"
      }, "WebkitUserSelect", "none");
  return JsxRuntime.jsxs(Stack, {
              style: style,
              bgcolor: "action.selected",
              children: [
                JsxRuntime.jsx(ClipboardAffordance$ClearClipboard, {}),
                JsxRuntime.jsx(ClipboardAffordance$PasteButton, {
                      counter: counter,
                      paster: props.paster,
                      allowed: props.allowed
                    })
              ],
              direction: ["row"],
              spacing: "0.5rem",
              sx: {
                borderRadius: "9999px",
                alignItems: "center"
              }
            });
}

var ClipboardAffordance = {
  make: ClipboardAffordance$ClipboardAffordance
};

function ClipboardAffordance$1(props) {
  var counter = Prelude.default(ClipboardManager.Contents.useCount(), (function () {
          return 0;
        }));
  var paster = ClipboardManager.Paster.usePaste();
  var allowed = ClipboardManager.Paster.useAllowed();
  return JsxRuntime.jsx(ActionsManager.Register.make, {
              name: "paste-button",
              widget: (function (key) {
                  return JsxRuntime.jsx(ClipboardAffordance$ClipboardAffordance, {
                              counter: counter,
                              paster: paster,
                              allowed: allowed
                            }, key);
                }),
              sequence: 10,
              children: props.children
            });
}

var make = ClipboardAffordance$1;

export {
  ClearClipboard ,
  PasteButton ,
  ClipboardAffordance ,
  make ,
}
/* react Not a pure module */
