// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as UserContext from "../../../../global/context/UserContext.js";
import * as UserPermission from "../../../../global/models/UserPermission.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as SharedDBContext from "../../../../global/context/SharedDBContext.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CatalogAPIEndpoint from "../../api/CatalogAPIEndpoint.js";
import * as CurrentDocumentListManager from "../CurrentDocumentListManager.js";

var NotFound = /* @__PURE__ */Caml_exceptions.create("UpdateDocumentService.NotFound");

var context = React.createContext(undefined);

var make = context.Provider;

function useUpdateDocument() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.update;
              }));
}

var canChangeDocument = UserPermission.Query.compile({
      TAG: "All",
      _0: ["catalog.change_document"]
    });

function UpdateDocumentService(props) {
  var permissions = UserContext.useUserPermissionsClaim();
  var updateDocument = CurrentDocumentListManager.useUpdateOrAddDocument();
  var token = Curry._1(Prelude.OptionExported.$$Option.getExn, UserContext.useAccessToken());
  var match = SharedDBContext.useQuery();
  var write = match.write;
  var makeWrite = match.makeWrite;
  var allowed = React.useMemo((function () {
          return canChangeDocument(permissions);
        }), [permissions]);
  var update = React.useMemo((function () {
          return async function ($$document) {
            var e = await CatalogAPIEndpoint.UpdateDocument.$$do($$document, token);
            var result;
            if (e.TAG === "Ok") {
              var d = e._0;
              if (d !== undefined) {
                updateDocument(d);
                result = {
                  TAG: "Ok",
                  _0: d
                };
              } else {
                result = await Prelude.rejectWithError({
                      RE_EXN_ID: NotFound,
                      code: 404,
                      message: "Not found"
                    });
              }
            } else {
              result = {
                TAG: "Error",
                _0: e._0
              };
            }
            if (result.TAG !== "Ok") {
              return {
                      TAG: "Error",
                      _0: result._0
                    };
            }
            var $$document$1 = result._0;
            var init = makeWrite();
            await write({
                  documents: [{
                      TAG: "Save",
                      _0: $$document$1
                    }],
                  folders: init.folders,
                  bundlers: init.bundlers
                });
            return {
                    TAG: "Ok",
                    _0: $$document$1
                  };
          };
        }), [
        token,
        makeWrite,
        write,
        updateDocument
      ]);
  var value = React.useMemo((function () {
          if (allowed) {
            return {
                    update: update
                  };
          }
          
        }), [
        allowed,
        update
      ]);
  return JsxRuntime.jsx(make, {
              value: value,
              children: props.children
            });
}

var make$1 = UpdateDocumentService;

export {
  useUpdateDocument ,
  make$1 as make,
}
/* context Not a pure module */
