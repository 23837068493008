// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as UUID7 from "./UUID7.js";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Hashtbl from "rescript/lib/es6/hashtbl.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_SetInt from "rescript/lib/es6/belt_SetInt.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Belt_SortArrayString from "rescript/lib/es6/belt_SortArrayString.js";
import * as Belt_MutableSetString from "rescript/lib/es6/belt_MutableSetString.js";

function $$continue(fn) {
  return Prelude.thenResolve(Prelude.Promises.ellapsed(0), (function () {
                return fn();
              }));
}

function after(ms, fn) {
  if (ms > 0) {
    return Prelude.then(Prelude.Promises.ellapsed(ms), fn);
  } else {
    return Curry._1(fn, undefined);
  }
}

function MakeStringTypeSet(T) {
  var fromArray = function (a) {
    return Belt_SetString.fromArray(a.map(function (prim) {
                    return prim;
                  }));
  };
  var has = Belt_SetString.has;
  var make = function () {
    
  };
  var size = Belt_SetString.size;
  var toArray = Belt_SetString.toArray;
  var diff = Belt_SetString.diff;
  var union = Belt_SetString.union;
  var eq = Belt_SetString.eq;
  var intersect = Belt_SetString.intersect;
  var subset = Belt_SetString.subset;
  var isEmpty = Belt_SetString.isEmpty;
  var add = Belt_SetString.add;
  var remove = Belt_SetString.remove;
  return {
          fromArray: fromArray,
          has: has,
          make: make,
          add: add,
          remove: remove,
          size: size,
          toArray: toArray,
          diff: diff,
          eq: eq,
          intersect: intersect,
          subset: subset,
          isEmpty: isEmpty,
          union: union
        };
}

function MakeOpaqueIdentifier($star) {
  var make = function () {
    return UUID7.make();
  };
  var $$null = "";
  var $$default = function (__x) {
    return Prelude.default(__x, $$null);
  };
  var map = function (a, fn) {
    return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, a, fn), $$null);
  };
  var or = Prelude.default;
  var orElse = function (a, fn, def) {
    return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, a, (function (o) {
                      return fn(o);
                    })), def);
  };
  var $$Option = {
    $$default: $$default,
    map: map,
    or: or,
    orElse: orElse
  };
  var cmp = Caml_obj.compare;
  var Comparator = Belt_Id.MakeComparable({
        cmp: cmp
      });
  var hash = Hashtbl.hash;
  var eq = Caml_obj.equal;
  var Hasher = Belt_Id.MakeHashableU({
        hash: hash,
        eq: eq
      });
  var fromArray = function (a) {
    return Belt_SetString.fromArray(a.map(function (prim) {
                    return prim;
                  }));
  };
  var has = Belt_SetString.has;
  var make$1 = function () {
    
  };
  var size = Belt_SetString.size;
  var toArray = Belt_SetString.toArray;
  var diff = Belt_SetString.diff;
  var union = Belt_SetString.union;
  var eq$1 = Belt_SetString.eq;
  var intersect = Belt_SetString.intersect;
  var subset = Belt_SetString.subset;
  var isEmpty = Belt_SetString.isEmpty;
  var add = Belt_SetString.add;
  var remove = Belt_SetString.remove;
  var $$Set = {
    fromArray: fromArray,
    has: has,
    make: make$1,
    add: add,
    remove: remove,
    size: size,
    toArray: toArray,
    diff: diff,
    eq: eq$1,
    intersect: intersect,
    subset: subset,
    isEmpty: isEmpty,
    union: union
  };
  var add$1 = Belt_MutableSetString.add;
  var remove$1 = Belt_MutableSetString.remove;
  var removeMany = Belt_MutableSetString.removeMany;
  var fromArray$1 = function (a) {
    return Belt_MutableSetString.fromArray(a.map(function (prim) {
                    return prim;
                  }));
  };
  var has$1 = Belt_MutableSetString.has;
  var make$2 = function () {
    return Belt_MutableSetString.make();
  };
  var size$1 = Belt_MutableSetString.size;
  var toArray$1 = Belt_MutableSetString.toArray;
  var diff$1 = Belt_MutableSetString.diff;
  var MutableSet = {
    add: add$1,
    remove: remove$1,
    removeMany: removeMany,
    fromArray: fromArray$1,
    has: has$1,
    make: make$2,
    size: size$1,
    toArray: toArray$1,
    diff: diff$1
  };
  var fromArray$2 = function (items) {
    return Curry._1(Prelude.Dict.fromArray, items.map(function (param) {
                    return [
                            param[0],
                            param[1]
                          ];
                  }));
  };
  var make$3 = function () {
    return {};
  };
  var copy = function (d) {
    return Object.assign({}, d);
  };
  var remove$2 = function (d, key) {
    return Curry._1(Prelude.Dict.fromArray, Curry._2(Prelude.$$Array.keep, Curry._1(Prelude.Dict.entries, d), (function (param) {
                      return key !== param[0];
                    })));
  };
  var toArray$2 = function (d) {
    return Curry._1(Prelude.Dict.entries, d);
  };
  var forEach = function (d, fn) {
    Curry._2(Prelude.$$Array.forEach, Curry._1(Prelude.Dict.entries, d), (function (param) {
            fn(param[0], param[1]);
          }));
  };
  var get = function (d, k) {
    return Curry._2(Prelude.Dict.get, d, k);
  };
  var has$2 = function (d, k) {
    return Prelude.Dict.has(d, k);
  };
  var set = function (d, k, v) {
    var dict = Object.assign({}, d);
    dict[k] = v;
    return dict;
  };
  var keysToArray = function (d) {
    return Object.keys(d);
  };
  var valuesToArray = function (d) {
    return Curry._1(Prelude.Dict.values, d);
  };
  var isEmpty$1 = function (d) {
    return Prelude.$$Array.isEmpty(Object.keys(d));
  };
  var $$Map = {
    fromArray: fromArray$2,
    make: make$3,
    copy: copy,
    forEach: forEach,
    get: get,
    has: has$2,
    keysToArray: keysToArray,
    remove: remove$2,
    set: set,
    toArray: toArray$2,
    valuesToArray: valuesToArray,
    isEmpty: isEmpty$1
  };
  var fromArray$3 = function (items) {
    return Curry._1(Prelude.Dict.fromArray, items.map(function (param) {
                    return [
                            param[0],
                            param[1]
                          ];
                  }));
  };
  var make$4 = function () {
    return {};
  };
  var copy$1 = function (d) {
    return Object.assign({}, d);
  };
  var remove$3 = function (d, key) {
    try {
      return (delete d[key]);
    }
    catch (exn){
      return ;
    }
  };
  var toArray$3 = function (d) {
    return Curry._1(Prelude.Dict.entries, d);
  };
  var forEach$1 = function (d, fn) {
    Curry._2(Prelude.$$Array.forEach, Curry._1(Prelude.Dict.entries, d), (function (param) {
            fn(param[0], param[1]);
          }));
  };
  var clear = function (d) {
    forEach$1(d, (function (key, param) {
            remove$3(d, key);
          }));
  };
  var get$1 = function (d, k) {
    return Curry._2(Prelude.Dict.get, d, k);
  };
  var has$3 = function (d, k) {
    return Prelude.Dict.has(d, k);
  };
  var set$1 = function (d, k, v) {
    d[k] = v;
  };
  var keysToArray$1 = function (d) {
    return Object.keys(d);
  };
  var valuesToArray$1 = function (d) {
    return Curry._1(Prelude.Dict.values, d);
  };
  var isEmpty$2 = function (d) {
    return Prelude.$$Array.isEmpty(Object.keys(d));
  };
  var MutableMap = {
    fromArray: fromArray$3,
    make: make$4,
    clear: clear,
    copy: copy$1,
    forEach: forEach$1,
    get: get$1,
    has: has$3,
    keysToArray: keysToArray$1,
    remove: remove$3,
    set: set$1,
    toArray: toArray$3,
    valuesToArray: valuesToArray$1,
    isEmpty: isEmpty$2
  };
  var group = function (items, getKey) {
    var groups = {};
    Curry._2(Prelude.$$Array.forEach, items, (function (item) {
            var key = getKey(item);
            set$1(groups, key, Curry._2(Prelude.$$Array.concat, Prelude.default(Curry._2(Prelude.Dict.get, groups, key), []), [item]));
          }));
    return groups;
  };
  var getter = function (items, getKey) {
    var items$1 = group(items, (function (i) {
            return getKey(i);
          }));
    return function (key) {
      return Prelude.default(Curry._2(Prelude.Dict.get, items$1, key), []);
    };
  };
  var $$Array = {
    group: group,
    getter: getter
  };
  var strictlySortedLength = Belt_SortArrayString.strictlySortedLength;
  var isSorted = Belt_SortArrayString.isSorted;
  var stableSortInPlace = Belt_SortArrayString.stableSortInPlace;
  var stableSort = Belt_SortArrayString.stableSort;
  var binarySearch = Belt_SortArrayString.binarySearch;
  var SortArray = {
    strictlySortedLength: strictlySortedLength,
    isSorted: isSorted,
    stableSortInPlace: stableSortInPlace,
    stableSort: stableSort,
    binarySearch: binarySearch
  };
  return {
          fromString: (function (prim) {
              return prim;
            }),
          toString: (function (prim) {
              return prim;
            }),
          manyFromString: (function (prim) {
              return prim;
            }),
          manyToString: (function (prim) {
              return prim;
            }),
          make: make,
          $$null: $$null,
          zero: "00000000-0000-0000-0000-00000000000Z",
          $$Option: $$Option,
          Comparator: Comparator,
          Hasher: Hasher,
          $$Map: $$Map,
          MutableMap: MutableMap,
          $$Set: $$Set,
          MutableSet: MutableSet,
          $$Array: $$Array,
          SortArray: SortArray
        };
}

function MakeSetType(T, C) {
  var fromArray = function (items) {
    return Belt_Set.fromArray(items, C);
  };
  var has = Belt_Set.has;
  var make = function () {
    return Belt_Set.make(C);
  };
  var size = Belt_Set.size;
  var toArray = Belt_Set.toArray;
  var diff = Belt_Set.diff;
  var union = Belt_Set.union;
  var eq = Belt_Set.eq;
  var intersect = Belt_Set.intersect;
  var subset = Belt_Set.subset;
  var isEmpty = Belt_Set.isEmpty;
  var add = Belt_Set.add;
  var remove = Belt_Set.remove;
  return {
          fromArray: fromArray,
          has: has,
          make: make,
          add: add,
          remove: remove,
          size: size,
          toArray: toArray,
          diff: diff,
          eq: eq,
          intersect: intersect,
          subset: subset,
          isEmpty: isEmpty,
          union: union
        };
}

function MakeIntIdentifier($star) {
  var fromArray = function (a) {
    return Belt_SetInt.fromArray(a.map(function (prim) {
                    return prim;
                  }));
  };
  var has = Belt_SetInt.has;
  var make = function () {
    
  };
  var size = Belt_SetInt.size;
  var toArray = function (t) {
    return Belt_SetInt.toArray(t).map(function (prim) {
                return prim;
              });
  };
  var diff = Belt_SetInt.diff;
  var union = Belt_SetInt.union;
  var eq = Belt_SetInt.eq;
  var intersect = Belt_SetInt.intersect;
  var subset = Belt_SetInt.subset;
  var isEmpty = Belt_SetInt.isEmpty;
  var add = Belt_SetInt.add;
  var remove = Belt_SetInt.remove;
  var $$Set = {
    fromArray: fromArray,
    has: has,
    make: make,
    size: size,
    toArray: toArray,
    diff: diff,
    union: union,
    eq: eq,
    intersect: intersect,
    subset: subset,
    isEmpty: isEmpty,
    add: add,
    remove: remove
  };
  return {
          $$null: 0,
          zero: 0,
          $$Set: $$Set
        };
}

export {
  $$continue ,
  after ,
  MakeStringTypeSet ,
  MakeOpaqueIdentifier ,
  MakeSetType ,
  MakeIntIdentifier ,
}
/* No side effect */
