// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Belt_MapInt from "rescript/lib/es6/belt_MapInt.js";
import * as Belt_SetInt from "rescript/lib/es6/belt_SetInt.js";
import * as JsxRuntime from "react/jsx-runtime";

var context = React.createContext(undefined);

var make = context.Provider;

var context$1 = React.createContext(undefined);

var make$1 = context$1.Provider;

function useGetCurrentSelection() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.getCurrentSelection;
              }));
}

function useGetCurrentSelectionIds() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.getCurrentSelectionIds;
              }));
}

function useIsSelected() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.isSelected;
              }));
}

function useAreAllSelected() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.areAllSelected;
              }));
}

function useHasSelection() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.hasSelection;
              }));
}

function useSelect() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context$1), (function (param) {
                return param.select;
              }));
}

function useUnselect() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context$1), (function (param) {
                return param.unselect;
              }));
}

function useToggle() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context$1), (function (param) {
                return param.toggle;
              }));
}

function useReset() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context$1), (function (param) {
                return param.reset;
              }));
}

function useBegin() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context$1), (function (param) {
                return param.begin;
              }));
}

function useCommit() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context$1), (function (param) {
                return param.commit;
              }));
}

function useRollback() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context$1), (function (param) {
                return param.rollback;
              }));
}

function TagsSelectionManager(props) {
  var getItemId = function (param) {
    return param.id;
  };
  var match = React.useReducer((function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "Reset" :
                  if (state.TAG === "Realtime") {
                    return {
                            TAG: "Realtime",
                            _0: [
                              undefined,
                              false
                            ]
                          };
                  } else {
                    return {
                            TAG: "Transaction",
                            previous: state.previous,
                            working: [
                              undefined,
                              false
                            ]
                          };
                  }
              case "Begin" :
                  if (state.TAG === "Realtime") {
                    var previous = state._0;
                    return {
                            TAG: "Transaction",
                            previous: previous,
                            working: previous
                          };
                  }
                  console.error("TagsSelectionManager", "Cannot start a transaction", state);
                  return state;
              case "Commit" :
                  if (state.TAG !== "Realtime") {
                    return {
                            TAG: "Realtime",
                            _0: state.working
                          };
                  }
                  console.error("TagsSelectionManager", "Cannot commit outside a transaction", state);
                  return state;
              case "Rollback" :
                  if (state.TAG !== "Realtime") {
                    return {
                            TAG: "Realtime",
                            _0: state.previous
                          };
                  }
                  console.error("TagsSelectionManager", "Cannot rollback outside a transaction", state);
                  return state;
              
            }
          } else {
            switch (action.TAG) {
              case "Select" :
                  var instances = action._0;
                  if (state.TAG === "Realtime") {
                    var result = {
                      contents: Belt_MapInt.fromArray(Belt_MapInt.toArray(state._0[0]))
                    };
                    Curry._2(Prelude.$$Array.forEach, instances, (function (item) {
                            result.contents = Belt_MapInt.set(result.contents, getItemId(item), item);
                          }));
                    return {
                            TAG: "Realtime",
                            _0: [
                              result.contents,
                              !Belt_MapInt.isEmpty(result.contents)
                            ]
                          };
                  }
                  var result$1 = {
                    contents: Belt_MapInt.fromArray(Belt_MapInt.toArray(state.working[0]))
                  };
                  Curry._2(Prelude.$$Array.forEach, instances, (function (item) {
                          result$1.contents = Belt_MapInt.set(result$1.contents, getItemId(item), item);
                        }));
                  return {
                          TAG: "Transaction",
                          previous: state.previous,
                          working: [
                            result$1.contents,
                            !Belt_MapInt.isEmpty(result$1.contents)
                          ]
                        };
              case "Unselect" :
                  var instances$1 = action._0;
                  if (state.TAG === "Realtime") {
                    var result$2 = {
                      contents: Belt_MapInt.fromArray(Belt_MapInt.toArray(state._0[0]))
                    };
                    Curry._2(Prelude.$$Array.forEach, instances$1, (function (item) {
                            result$2.contents = Belt_MapInt.remove(result$2.contents, getItemId(item));
                          }));
                    return {
                            TAG: "Realtime",
                            _0: [
                              result$2.contents,
                              !Belt_MapInt.isEmpty(result$2.contents)
                            ]
                          };
                  }
                  var result$3 = {
                    contents: Belt_MapInt.fromArray(Belt_MapInt.toArray(state.working[0]))
                  };
                  Curry._2(Prelude.$$Array.forEach, instances$1, (function (item) {
                          result$3.contents = Belt_MapInt.remove(result$3.contents, getItemId(item));
                        }));
                  return {
                          TAG: "Transaction",
                          previous: state.previous,
                          working: [
                            result$3.contents,
                            !Belt_MapInt.isEmpty(result$3.contents)
                          ]
                        };
              case "Toggle" :
                  var instances$2 = action._0;
                  if (state.TAG === "Realtime") {
                    var result$4 = {
                      contents: Belt_MapInt.fromArray(Belt_MapInt.toArray(state._0[0]))
                    };
                    Curry._2(Prelude.$$Array.forEach, instances$2, (function (item) {
                            var key = getItemId(item);
                            if (Belt_MapInt.has(result$4.contents, key)) {
                              result$4.contents = Belt_MapInt.remove(result$4.contents, key);
                            } else {
                              result$4.contents = Belt_MapInt.set(result$4.contents, key, item);
                            }
                          }));
                    return {
                            TAG: "Realtime",
                            _0: [
                              result$4.contents,
                              !Belt_MapInt.isEmpty(result$4.contents)
                            ]
                          };
                  }
                  var result$5 = {
                    contents: Belt_MapInt.fromArray(Belt_MapInt.toArray(state.working[0]))
                  };
                  Curry._2(Prelude.$$Array.forEach, instances$2, (function (item) {
                          var key = getItemId(item);
                          if (Belt_MapInt.has(result$5.contents, key)) {
                            result$5.contents = Belt_MapInt.remove(result$5.contents, key);
                          } else {
                            result$5.contents = Belt_MapInt.set(result$5.contents, key, item);
                          }
                        }));
                  return {
                          TAG: "Transaction",
                          previous: state.previous,
                          working: [
                            result$5.contents,
                            !Belt_MapInt.isEmpty(result$5.contents)
                          ]
                        };
              
            }
          }
        }), {
        TAG: "Realtime",
        _0: [
          undefined,
          false
        ]
      });
  var dispatch = match[1];
  var state = match[0];
  var select = React.useCallback((function (items) {
          dispatch({
                TAG: "Select",
                _0: items
              });
        }), [dispatch]);
  var unselect = React.useCallback((function (items) {
          dispatch({
                TAG: "Unselect",
                _0: items
              });
        }), [dispatch]);
  var toggle = React.useCallback((function (items) {
          dispatch({
                TAG: "Toggle",
                _0: items
              });
        }), [dispatch]);
  var reset = React.useCallback((function () {
          dispatch("Reset");
        }), [dispatch]);
  var begin = React.useCallback((function () {
          dispatch("Begin");
        }), [dispatch]);
  var commit = React.useCallback((function () {
          dispatch("Commit");
        }), [dispatch]);
  var rollback = React.useCallback((function () {
          dispatch("Rollback");
        }), [dispatch]);
  var getCurrentSelection = React.useCallback((function () {
          if (state.TAG === "Realtime") {
            return state._0[0];
          } else {
            return state.previous[0];
          }
        }), [state]);
  var hasSelection = React.useMemo((function () {
          if (state.TAG === "Realtime") {
            return state._0[1];
          } else {
            return state.previous[1];
          }
        }), [state]);
  var getCurrentSelectionIds = React.useCallback((function () {
          return Belt_SetInt.fromArray(Belt_MapInt.keysToArray(getCurrentSelection()));
        }), [getCurrentSelection]);
  var isSelected = React.useCallback((function (item) {
          return Belt_MapInt.has(getCurrentSelection(), getItemId(item));
        }), [getCurrentSelection]);
  var areAllSelected = React.useCallback((function (items) {
          var ids = Belt_SetInt.fromArray(items.map(getItemId));
          return Belt_SetInt.subset(ids, getCurrentSelectionIds());
        }), [
        state,
        getCurrentSelectionIds
      ]);
  var doubled = React.useContext(context);
  if (doubled !== undefined) {
    console.warn("TagsSelectionManager", "has already setup, you might shadowing it.");
  }
  var state$1 = {
    getCurrentSelection: getCurrentSelection,
    getCurrentSelectionIds: getCurrentSelectionIds,
    isSelected: isSelected,
    areAllSelected: areAllSelected,
    hasSelection: hasSelection
  };
  var dispatchers = {
    select: select,
    unselect: unselect,
    toggle: toggle,
    reset: reset,
    begin: begin,
    commit: commit,
    rollback: rollback
  };
  return JsxRuntime.jsx(make, {
              value: state$1,
              children: JsxRuntime.jsx(make$1, {
                    value: dispatchers,
                    children: props.children
                  })
            });
}

var make$2 = TagsSelectionManager;

export {
  useGetCurrentSelection ,
  useGetCurrentSelectionIds ,
  useIsSelected ,
  useAreAllSelected ,
  useHasSelection ,
  useSelect ,
  useUnselect ,
  useToggle ,
  useReset ,
  useBegin ,
  useCommit ,
  useRollback ,
  make$2 as make,
}
/* context Not a pure module */
