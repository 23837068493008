// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as UserContext from "../../../global/context/UserContext.js";
import * as UserPermission from "../../../global/models/UserPermission.js";
import * as AccountsManager from "./AccountsManager.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as AccountsListService from "./AccountsListService.js";

var context = React.createContext(undefined);

var make = context.Provider;

function useCreateAccount() {
  return React.useContext(context);
}

var canManageAccount = UserPermission.Query.compile({
      TAG: "All",
      _0: ["auth.add_user"]
    });

function CreateAccountService(props) {
  var create = AccountsManager.useCreateAccount();
  var refresh = AccountsListService.useRefresh();
  var perms = UserContext.useUserPermissionsClaim();
  var allowed = React.useMemo((function () {
          return canManageAccount(perms);
        }), [perms]);
  var value = React.useMemo((function () {
          if (allowed) {
            return Curry._2(Prelude.OptionExported.$$Option.map, create, (function (fn) {
                          return function (account) {
                            return fn(account).finally(function () {
                                        refresh();
                                      });
                          };
                        }));
          }
          
        }), [
        create,
        allowed
      ]);
  return JsxRuntime.jsx(make, {
              value: value,
              children: props.children
            });
}

var make$1 = CreateAccountService;

export {
  useCreateAccount ,
  make$1 as make,
}
/* context Not a pure module */
