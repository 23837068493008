// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18Next from "../../../../../libs/i18n/ReactI18Next.js";
import * as ActionsManager from "../../../../global/context/ActionsManager.js";
import * as FlexGrowSpacer from "../../../../global/components/FlexGrowSpacer.js";
import * as TagsListService from "../../context/TagsListService.js";
import * as JsxRuntime from "react/jsx-runtime";
import Chip from "@mui/material/Chip";
import * as TagsFiltersContext from "../../context/TagsFiltersContext.js";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import StyleOutlined from "@mui/icons-material/StyleOutlined";

function TagsCountAffordance$TagsChip(props) {
  var approvalStatus = props.approvalStatus;
  var count = props.count;
  var label = count > 0 ? new (Intl.NumberFormat)().format(count) : JsxRuntime.jsx(ReactI18Next.Message.make, {
          msg: {
            NAME: "msg",
            VAL: [
              "tags-counter-chip.no-tags",
              "No tags"
            ]
          }
        });
  var color = approvalStatus === "approved" ? "success" : (
      approvalStatus === "unapproved" ? "warning" : "default"
    );
  return React.useMemo((function () {
                return JsxRuntime.jsx(Chip, {
                            color: color,
                            label: Caml_option.some(label)
                          });
              }), [
              color,
              label
            ]);
}

function TagsCountAffordance$ClearFilters(props) {
  var setFilters = props.setFilters;
  var filters = props.filters;
  var approvalStatus = TagsFiltersContext.useApprovalStatus();
  var hidden = filters.contains === undefined && filters.startswith === undefined;
  return React.useMemo((function () {
                if (hidden) {
                  return null;
                } else {
                  return JsxRuntime.jsx(Tooltip, {
                              children: JsxRuntime.jsx(IconButton, {
                                    children: Caml_option.some(JsxRuntime.jsx(Close, {})),
                                    onClick: (function (param) {
                                        setFilters({
                                              approval_status: approvalStatus
                                            });
                                      })
                                  }),
                              title: JsxRuntime.jsx(ReactI18Next.Message.make, {
                                    msg: {
                                      NAME: "str",
                                      VAL: "Clear filters"
                                    }
                                  })
                            });
                }
              }), [
              hidden,
              approvalStatus
            ]);
}

function TagsCountAffordance(props) {
  var filters = TagsFiltersContext.useFilters();
  var setFilters = TagsFiltersContext.useSetFilters();
  var count = TagsListService.useCount();
  var approvalStatus = TagsFiltersContext.useApprovalStatus();
  var setApprovalStatus = TagsFiltersContext.useSetApprovalStatus();
  var setCurrentPage = TagsListService.useSetCurrentPage();
  return JsxRuntime.jsx(ActionsManager.Register.make, {
              name: "unapproved-tags",
              widget: (function (key) {
                  return JsxRuntime.jsxs(React.Fragment, {
                              children: [
                                JsxRuntime.jsx(FlexGrowSpacer.make, {}),
                                JsxRuntime.jsx(TagsCountAffordance$ClearFilters, {
                                      filters: filters,
                                      setFilters: setFilters
                                    }),
                                JsxRuntime.jsx(TagsCountAffordance$TagsChip, {
                                      count: count,
                                      approvalStatus: approvalStatus
                                    }),
                                JsxRuntime.jsxs(ToggleButtonGroup, {
                                      children: [
                                        JsxRuntime.jsx(ToggleButton, {
                                              children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                                        children: JsxRuntime.jsx(StyleOutlined, {}),
                                                        title: JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                              msg: {
                                                                NAME: "msg",
                                                                VAL: [
                                                                  "tags.toggle-show-all",
                                                                  "Show all"
                                                                ]
                                                              }
                                                            })
                                                      })),
                                              value: "any"
                                            }),
                                        JsxRuntime.jsx(ToggleButton, {
                                              children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                                        children: JsxRuntime.jsx(StyleOutlined, {
                                                              color: "success"
                                                            }),
                                                        title: JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                              msg: {
                                                                NAME: "msg",
                                                                VAL: [
                                                                  "tags.toggle-approved",
                                                                  "Show approved only"
                                                                ]
                                                              }
                                                            })
                                                      })),
                                              value: "approved"
                                            }),
                                        JsxRuntime.jsx(ToggleButton, {
                                              children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                                        children: JsxRuntime.jsx(StyleOutlined, {
                                                              color: "warning"
                                                            }),
                                                        title: JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                              msg: {
                                                                NAME: "msg",
                                                                VAL: [
                                                                  "tags.toggle-unapproved",
                                                                  "Show unapproved only"
                                                                ]
                                                              }
                                                            })
                                                      })),
                                              value: "unapproved"
                                            })
                                      ],
                                      exclusive: true,
                                      onChange: (function (param, v) {
                                          setCurrentPage(1);
                                          console.log("TagsCountAffordance", "approval", v);
                                          setApprovalStatus(v);
                                        }),
                                      size: "small",
                                      value: approvalStatus
                                    })
                              ]
                            }, key);
                }),
              sequence: 5000,
              children: props.children
            });
}

var make = TagsCountAffordance;

export {
  make ,
}
/* react Not a pure module */
