// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as FolderId from "../models/FolderId.js";
import * as DocumentId from "../models/DocumentId.js";
import * as DragSelection from "./DragSelection.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as SelectionManager from "../context/SelectionManager.js";
import * as JsxRuntime from "react/jsx-runtime";

function WithCatalogLassoSelector(props) {
  var children = props.children;
  var documents = props.documents;
  var subfolders = props.subfolders;
  var subfolders$1 = React.useMemo((function () {
          return Prelude.default(subfolders, []);
        }), [subfolders]);
  var documents$1 = React.useMemo((function () {
          return Prelude.default(documents, []);
        }), [documents]);
  var documentToggle = SelectionManager.Documents.useToggle();
  var documentReset = SelectionManager.Documents.useReset();
  var getSelectedDocuments = SelectionManager.Documents.useGetCurrentSelectionIds();
  var foldersToggle = SelectionManager.Folders.useToggle();
  var foldersReset = SelectionManager.Folders.useReset();
  var getSelectedFolders = SelectionManager.Folders.useGetCurrentSelectionIds();
  var toggleSelectByID = React.useCallback((function (id) {
          Prelude.OptionExported.$$Option.$$do(documents$1.find(function (d) {
                    return DocumentId.toString(d.id) === id;
                  }), (function (obj) {
                  Prelude.OptionExported.$$Option.$$do(documentToggle, (function (fn) {
                          fn([obj]);
                        }));
                }));
          Prelude.OptionExported.$$Option.$$do(subfolders$1.find(function (d) {
                    return FolderId.toString(d.id) === id;
                  }), (function (obj) {
                  Prelude.OptionExported.$$Option.$$do(foldersToggle, (function (fn) {
                          fn([obj]);
                        }));
                }));
        }), [
        documentToggle,
        foldersToggle,
        subfolders$1,
        documents$1
      ]);
  var selectByListOfIDs = React.useCallback((function (ids) {
          Prelude.OptionExported.$$Option.$$do(documentReset, (function (fn) {
                  fn();
                }));
          Prelude.OptionExported.$$Option.$$do(foldersReset, (function (fn) {
                  fn();
                }));
          Belt_SetString.forEach(ids, (function (id) {
                  toggleSelectByID(id);
                }));
        }), [
        documentReset,
        foldersReset,
        toggleSelectByID
      ]);
  var getCurrentSelection;
  var exit = 0;
  if (getSelectedDocuments !== undefined && getSelectedFolders !== undefined) {
    getCurrentSelection = (function () {
        var docs = DocumentId.manyToString(DocumentId.$$Set.toArray(getSelectedDocuments()));
        var folders = FolderId.manyToString(FolderId.$$Set.toArray(getSelectedFolders()));
        return Belt_SetString.fromArray(Curry._1(Prelude.$$Array.concatMany, [
                        docs,
                        folders
                      ]));
      });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    getCurrentSelection = (function () {
        
      });
  }
  var enabled = Prelude.$$Array.isNotEmpty(subfolders$1) && Curry._1(Prelude.OptionExported.$$Option.isSome, foldersToggle) && Curry._1(Prelude.OptionExported.$$Option.isSome, foldersReset) || Prelude.$$Array.isNotEmpty(documents$1) && Curry._1(Prelude.OptionExported.$$Option.isSome, documentToggle) && Curry._1(Prelude.OptionExported.$$Option.isSome, documentReset);
  if (enabled) {
    return JsxRuntime.jsx(DragSelection.make, {
                children: children,
                onDragSelection: selectByListOfIDs,
                getCurrentSelection: getCurrentSelection,
                appContainer: ".selection-lasso-scroll-container",
                itemSelector: [".selectable-item[id]"]
              });
  } else {
    return children;
  }
}

var make = WithCatalogLassoSelector;

export {
  make ,
}
/* react Not a pure module */
