// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as FolderId from "../models/FolderId.js";
import * as JsxRuntime from "react/jsx-runtime";

var context = React.createContext(function (f) {
      return "/fs/catalog/" + FolderId.toString(f.id);
    });

var make = context.Provider;

function useLinkBuilder() {
  return React.useContext(context);
}

function CatalogNavigator(props) {
  var path = props.path;
  return JsxRuntime.jsx(make, {
              value: (function (f) {
                  return path + "/" + FolderId.toString(f.id);
                }),
              children: props.children
            });
}

var make$1 = CatalogNavigator;

export {
  useLinkBuilder ,
  make$1 as make,
}
/* context Not a pure module */
