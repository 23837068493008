// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as UserContext from "../global/context/UserContext.js";
import * as UserPermission from "../global/models/UserPermission.js";
import * as JsxRuntime from "react/jsx-runtime";

function ProtectedArea$Conditional(props) {
  var children = props.children;
  var requirement = props.requirement;
  var user = UserContext.useUserClaim();
  var permissions = UserContext.useUserPermissionsClaim();
  var pred = React.useMemo((function () {
          if (typeof requirement !== "object") {
            return function (param) {
              return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, user, (function (param) {
                                return param.is_superuser;
                              })), false);
            };
          }
          switch (requirement.TAG) {
            case "Perms" :
                return UserPermission.Query.compile(requirement._0);
            case "Feature" :
                var match = requirement._0;
                if (match === "allow" || match === "force") {
                  return function (param) {
                    return true;
                  };
                } else {
                  return function (param) {
                    return false;
                  };
                }
            case "Pred" :
                var cond = requirement._0;
                return function (param) {
                  return cond;
                };
            
          }
        }), [
        requirement,
        user
      ]);
  var allowed = React.useMemo((function () {
          return pred(permissions);
        }), [
        pred,
        permissions
      ]);
  return React.useMemo((function () {
                return children(allowed);
              }), [
              children,
              allowed
            ]);
}

var Conditional = {
  make: ProtectedArea$Conditional
};

function ProtectedArea(props) {
  var disallowed = props.disallowed;
  var children = props.children;
  return JsxRuntime.jsx(ProtectedArea$Conditional, {
              requirement: props.requirement,
              children: (function (allowed) {
                  if (allowed) {
                    return children;
                  } else {
                    return Prelude.default(disallowed, null);
                  }
                })
            });
}

var make = ProtectedArea;

export {
  Conditional ,
  make ,
}
/* react Not a pure module */
