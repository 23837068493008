// Generated by ReScript, PLEASE EDIT WITH CARE

import * as AboutManifest from "./AboutManifest.js";
import * as ProtectedArea from "../../utils/ProtectedArea.js";
import DarkPng from "/images/dark.png";
import * as ApplicationMaker from "../../global/apps/ApplicationMaker.js";
import LightPng from "/images/light.png";
import * as JsxRuntime from "react/jsx-runtime";
import * as PhotocubaInfoTable from "./components/PhotocubaInfoTable.js";
import Stack from "@mui/material/Stack";
import * as InstalledPackagesTable from "./components/InstalledPackagesTable.js";
import * as UserPreferredColorScheme from "../../global/context/UserPreferredColorScheme.js";

function AboutView$Main(props) {
  var scheme = UserPreferredColorScheme.useResolvedScheme();
  var imgSrc = scheme === "dark" ? DarkPng : LightPng;
  return JsxRuntime.jsxs(Stack, {
              children: [
                JsxRuntime.jsx("img", {
                      style: {
                        display: "block",
                        margin: "1rem auto",
                        width: "50%"
                      },
                      alt: "Photo Cuba",
                      src: imgSrc
                    }),
                JsxRuntime.jsx(PhotocubaInfoTable.make, {}),
                JsxRuntime.jsx(ProtectedArea.make, {
                      requirement: "SuperUser",
                      children: JsxRuntime.jsx(InstalledPackagesTable.make, {})
                    })
              ],
              direction: ["column"],
              spacing: "3rem",
              sx: {
                maxWidth: {
                  xs: "90vw",
                  lg: "50vw",
                  xl: "35vw"
                },
                margin: "0 auto"
              }
            });
}

var Main = {
  make: AboutView$Main
};

var include = ApplicationMaker.MakeServiceless({
      Manifest: AboutManifest,
      Main: Main
    });

var Manifest = include.Manifest;

var Services = include.Services;

var Affordances = include.Affordances;

var Main$1 = include.Main;

var make = include.make;

export {
  Manifest ,
  Services ,
  Affordances ,
  Main$1 as Main,
  make ,
}
/* include Not a pure module */
