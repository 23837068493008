// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as UserContext from "../../../global/context/UserContext.js";
import * as UserPermission from "../../../global/models/UserPermission.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CollectionsManager from "./CollectionsManager.js";
import * as CollectionsListService from "./CollectionsListService.js";

var context = React.createContext(undefined);

var make = context.Provider;

var Provider = {
  make: make
};

function useCreateCollection() {
  return React.useContext(context);
}

var canManageCollections = UserPermission.Query.compile({
      TAG: "All",
      _0: ["catalog.change_collection"]
    });

function CreateCollectionService(props) {
  var create = CollectionsManager.useCreateCollection();
  var refresh = CollectionsListService.useRefresh();
  var perms = UserContext.useUserPermissionsClaim();
  var allowed = React.useMemo((function () {
          return canManageCollections(perms);
        }), [perms]);
  var value = React.useMemo((function () {
          if (allowed) {
            return Curry._2(Prelude.OptionExported.$$Option.map, create, (function (create) {
                          return function (name) {
                            return create(name).finally(function () {
                                        refresh();
                                      });
                          };
                        }));
          }
          
        }), [
        create,
        allowed
      ]);
  return JsxRuntime.jsx(make, {
              value: value,
              children: props.children
            });
}

var make$1 = CreateCollectionService;

export {
  context ,
  Provider ,
  useCreateCollection ,
  canManageCollections ,
  make$1 as make,
}
/* context Not a pure module */
