// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

var LoginForm = {};

var context = React.createContext({
      login: (function (param) {
          return Promise.resolve();
        }),
      logout: (function () {
          
        })
    });

var make = context.Provider;

var Provider = {
  make: make
};

function useLogin() {
  return React.useContext(context).login;
}

function useLogout() {
  return React.useContext(context).logout;
}

function useChangePassword() {
  return React.useContext(context).changePassword;
}

export {
  LoginForm ,
  context ,
  Provider ,
  useLogin ,
  useLogout ,
  useChangePassword ,
}
/* context Not a pure module */
