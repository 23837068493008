// Generated by ReScript, PLEASE EDIT WITH CARE

import * as FlexGrowSpacer from "../../../global/components/FlexGrowSpacer.js";
import * as JsxRuntime from "react/jsx-runtime";
import Stack from "@mui/material/Stack";
import * as FolderBrowserBreadcrumbs from "./FolderBrowserBreadcrumbs.js";
import * as FolderBrowserViewSwitcher from "./FolderBrowserViewSwitcher.js";
import * as FolderBrowserDetailsSwitcher from "./FolderBrowserDetailsSwitcher.js";

function FolderBrowserCommonLayout(props) {
  return JsxRuntime.jsxs(Stack, {
              children: [
                JsxRuntime.jsxs(Stack, {
                      children: [
                        JsxRuntime.jsx(FolderBrowserBreadcrumbs.make, {
                              folder: props.folder
                            }),
                        JsxRuntime.jsx(FlexGrowSpacer.make, {}),
                        JsxRuntime.jsx(FolderBrowserViewSwitcher.make, {}),
                        JsxRuntime.jsx(FolderBrowserDetailsSwitcher.make, {
                              showFolderInfo: props.showFolderInfo,
                              toggleFolderInfo: props.toggleFolderInfo
                            })
                      ],
                      direction: ["row"]
                    }),
                props.children
              ],
              direction: ["column"]
            });
}

var make = FolderBrowserCommonLayout;

export {
  make ,
}
/* FlexGrowSpacer Not a pure module */
