// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RenameDialog from "../../../../global/components/RenameDialog.js";
import * as ReactI18next from "react-i18next";
import * as JsxRuntime from "react/jsx-runtime";
import * as UpdateCollectionService from "../../context/UpdateCollectionService.js";

function RenameCollectionDialog(props) {
  var onClose = props.onClose;
  var onDone = props.onDone;
  var collection = props.collection;
  var match = React.useState(function () {
        return {
                TAG: "Init",
                _0: collection
              };
      });
  var setState = match[1];
  var state = match[0];
  var rename = UpdateCollectionService.useUpdateCollection();
  var onChange = React.useCallback((function (name) {
          if (rename === undefined) {
            return ;
          }
          if (typeof state !== "object") {
            return ;
          }
          if (state.TAG !== "Init") {
            return ;
          }
          var collection = state._0;
          setState(function (param) {
                return {
                        TAG: "Renaming",
                        _0: collection,
                        _1: name
                      };
              });
        }), [
        state,
        rename
      ]);
  React.useEffect((function () {
          if (rename !== undefined && typeof state === "object" && state.TAG !== "Init") {
            Prelude.thenDo(rename({
                      id: state._0.id,
                      name: state._1
                    }), (function (param) {
                    onDone();
                    setState(function (param) {
                          return "Done";
                        });
                  }));
          }
          
        }), [
        rename,
        state
      ]);
  if (typeof state !== "object" || state.TAG !== "Init") {
    return null;
  } else {
    return JsxRuntime.jsx(RenameDialog.make, {
                name: state._0.name,
                onChange: onChange,
                onClose: (function () {
                    onClose();
                    setState(function (param) {
                          return "Done";
                        });
                  }),
                open_: true,
                title: {
                  NAME: "str",
                  VAL: "Rename collection"
                },
                content: Caml_option.some(JsxRuntime.jsx(ReactI18next.Trans, {
                          children: "Enter the new name of the collection"
                        })),
                label: {
                  NAME: "str",
                  VAL: "Collection"
                }
              });
  }
}

var make = RenameCollectionDialog;

export {
  make ,
}
/* react Not a pure module */
