// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import BlueimpLoadImage from "blueimp-load-image";

function toBlob(canvas, options) {
  return Prelude.thenResolve(new Promise((function (resolve, reject) {
                    var ctype = options.type;
                    var quality = options.quality;
                    canvas.toBlob((function (x) {
                            resolve(x);
                          }), ctype, quality);
                  })), (function (x) {
                return x;
              }));
}

async function _createFromFile(file, profile) {
  var bitmap = await createImageBitmap(file);
  var originalWidth = bitmap.width;
  var originalHeight = bitmap.height;
  bitmap.close();
  var newHeight = {
    contents: profile === "small" ? 400 : 1080
  };
  if (!(Math.imul(originalHeight, originalWidth) > 90000 && newHeight.contents < originalHeight)) {
    return ;
  }
  var newWidth = {
    contents: Caml_int32.div(Math.imul(originalWidth, newHeight.contents), originalHeight)
  };
  if (newWidth.contents < 300) {
    newWidth.contents = 300;
    newHeight.contents = Caml_int32.div(Math.imul(originalHeight, newWidth.contents), originalWidth);
  }
  if (newWidth.contents < 300 || newHeight.contents < 300) {
    console.error("Refusing to compress with either too small width and height", {
          width: newWidth,
          height: newHeight,
          originalWidth: originalWidth,
          originalHeight: originalHeight
        });
    return ;
  }
  var loaded = await BlueimpLoadImage(file, {
        maxWidth: newWidth.contents,
        maxHeight: newHeight.contents,
        meta: true,
        orientation: true,
        canvas: true,
        contain: true
      });
  var canvas = loaded.image;
  return Caml_option.some(await toBlob(canvas, {
                  type: "image/jpeg",
                  quality: 0.85
                }));
}

async function createFromFile(file, profile) {
  try {
    return await _createFromFile(file, profile);
  }
  catch (raw_error){
    var error = Caml_js_exceptions.internalToOCamlException(raw_error);
    console.error("Thumbnails", "failure generating thumbnail", error);
    return ;
  }
}

export {
  createFromFile ,
}
/* blueimp-load-image Not a pure module */
