// Generated by ReScript, PLEASE EDIT WITH CARE

import * as FolderId from "./FolderId.js";
import * as DocumentId from "./DocumentId.js";

function fromFolder(x) {
  return {
          NAME: "folder",
          VAL: x
        };
}

function fromDocument(x) {
  return {
          NAME: "document",
          VAL: x
        };
}

function fromFolderId(id) {
  return {
          NAME: "folder",
          VAL: id
        };
}

function fromDocumentId(id) {
  return {
          NAME: "document",
          VAL: id
        };
}

function isFolder(item) {
  return item.NAME !== "document";
}

function isDocument(item) {
  return item.NAME === "document";
}

function getDocument(item) {
  if (item.NAME === "document") {
    return item.VAL;
  }
  
}

function getFolder(item) {
  if (item.NAME === "document") {
    return ;
  } else {
    return item.VAL;
  }
}

function getIdString(item) {
  if (item.NAME === "document") {
    return "document::" + DocumentId.toString(item.VAL.id);
  } else {
    return "folder::" + FolderId.toString(item.VAL.id);
  }
}

export {
  fromFolder ,
  fromDocument ,
  fromFolderId ,
  fromDocumentId ,
  isFolder ,
  isDocument ,
  getDocument ,
  getFolder ,
  getIdString ,
}
/* FolderId Not a pure module */
