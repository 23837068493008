// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as FolderItem from "../../models/FolderItem.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ConfirmDeleteDialog from "../../../../global/components/ConfirmDeleteDialog.js";
import * as RemoveFolderService from "../../context/services/RemoveFolderService.js";
import * as RemoveDocumentService from "../../context/services/RemoveDocumentService.js";

function DeleteItemDialog(props) {
  var onClose = props.onClose;
  var onDone = props.onDone;
  var variant = props.variant;
  var item = props.item;
  var match = React.useState(function () {
        return {
                TAG: "Init",
                _0: item
              };
      });
  var setState = match[1];
  var state = match[0];
  var trashDocument = RemoveDocumentService.useTrash();
  var removeDocument = RemoveDocumentService.useRemove();
  var trashFolder = RemoveFolderService.useTrash();
  var removeFolder = RemoveFolderService.useRemove();
  var onDelete = React.useCallback((function () {
          console.log("DeleteItemDialog", "onDelete", state, variant);
          switch (variant) {
            case "MoveToTrash" :
                if (typeof state !== "object") {
                  return ;
                }
                if (state.TAG !== "Init") {
                  return ;
                }
                var folder = state._0;
                return setState(function (param) {
                            return {
                                    TAG: "Deleting",
                                    _0: folder
                                  };
                          });
            case "RemoveForever" :
                if (typeof state !== "object") {
                  return ;
                }
                if (state.TAG !== "Init") {
                  return ;
                }
                var folder$1 = state._0;
                return setState(function (param) {
                            return {
                                    TAG: "Deleting",
                                    _0: folder$1
                                  };
                          });
            case "Disallowed" :
                return ;
            
          }
        }), [
        state,
        variant
      ]);
  React.useEffect((function () {
          switch (variant) {
            case "MoveToTrash" :
                if (typeof state === "object" && state.TAG !== "Init") {
                  var match = state._0;
                  if (match.NAME === "document") {
                    var $$document = match.VAL;
                    Prelude.OptionExported.$$Option.$$do(trashDocument, (function (fn) {
                            Prelude.thenDo(fn($$document), (function (param) {
                                    onDone();
                                    setState(function (param) {
                                          return "Done";
                                        });
                                  }));
                          }));
                  } else {
                    var folder = match.VAL;
                    Prelude.OptionExported.$$Option.$$do(trashFolder, (function (fn) {
                            Prelude.thenDo(fn(folder), (function (param) {
                                    onDone();
                                    setState(function (param) {
                                          return "Done";
                                        });
                                  }));
                          }));
                  }
                }
                break;
            case "RemoveForever" :
                if (typeof state === "object" && state.TAG !== "Init") {
                  var match$1 = state._0;
                  if (match$1.NAME === "document") {
                    var $$document$1 = match$1.VAL;
                    Prelude.OptionExported.$$Option.$$do(removeDocument, (function (fn) {
                            Prelude.thenDo(fn($$document$1), (function (param) {
                                    onDone();
                                    setState(function (param) {
                                          return "Done";
                                        });
                                  }));
                          }));
                  } else {
                    var folder$1 = match$1.VAL;
                    Prelude.OptionExported.$$Option.$$do(removeFolder, (function (fn) {
                            Prelude.thenDo(fn(folder$1), (function (param) {
                                    onDone();
                                    setState(function (param) {
                                          return "Done";
                                        });
                                  }));
                          }));
                  }
                }
                break;
            case "Disallowed" :
                break;
            
          }
        }), [
        trashFolder,
        removeFolder,
        state
      ]);
  var title = React.useMemo((function () {
          switch (variant) {
            case "RemoveForever" :
                return {
                        NAME: "str",
                        VAL: "Confirm delete forever"
                      };
            case "MoveToTrash" :
            case "Disallowed" :
                return {
                        NAME: "str",
                        VAL: "Confirm move to trash"
                      };
            
          }
        }), [variant]);
  if (typeof state !== "object" || state.TAG !== "Init") {
    return null;
  } else {
    return JsxRuntime.jsx(ConfirmDeleteDialog.make, {
                open_: true,
                title: title,
                onCancel: (function () {
                    onClose();
                    setState(function (param) {
                          return "Done";
                        });
                  }),
                onDelete: onDelete
              }, FolderItem.getIdString(state._0));
  }
}

var make = DeleteItemDialog;

export {
  make ,
}
/* react Not a pure module */
