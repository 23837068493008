// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as HtmlAnchor from "../../../../../libs/HtmlAnchor.js";
import * as UserContext from "../../../../global/context/UserContext.js";
import * as UserPermission from "../../../../global/models/UserPermission.js";
import * as JsxRuntime from "react/jsx-runtime";

var context = React.createContext(undefined);

var make = context.Provider;

function useGetDocumentDownloadLink() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.getLink;
              }));
}

function useGetDocumentDownloadOnClick() {
  return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                return param.onClick;
              }));
}

function DocumentDownloader(props) {
  var permissions = UserContext.useUserPermissionsClaim();
  var downloader = React.useMemo((function () {
          var query = {
            TAG: "Any",
            _0: ["catalog.allow_full_download"]
          };
          return UserPermission.Query.matches(query, permissions);
        }), [permissions]);
  var getLink = React.useMemo((function () {
          if (downloader) {
            return function ($$document) {
              var match = $$document.attachment;
              var variant = match.NAME;
              if (variant === "cached" || variant === "remote") {
                return match.VAL.url;
              }
              
            };
          } else {
            return function ($$document) {
              var match = $$document.previews;
              if (match === undefined) {
                return ;
              }
              var match$1 = match.large;
              if (match$1 === undefined) {
                return ;
              }
              if (typeof match$1 !== "object") {
                return ;
              }
              var variant = match$1.NAME;
              if (variant === "cached" || variant === "remote") {
                return match$1.VAL.url;
              }
              
            };
          }
        }), [downloader]);
  var onClick = React.useMemo((function () {
          return function ($$document, newtab) {
            var link = getLink($$document);
            return function (param) {
              if (link !== undefined) {
                return HtmlAnchor.clickLink(link, newtab);
              }
              
            };
          };
        }), [getLink]);
  return JsxRuntime.jsx(make, {
              value: {
                getLink: getLink,
                onClick: onClick
              },
              children: props.children
            });
}

var make$1 = DocumentDownloader;

export {
  useGetDocumentDownloadLink ,
  useGetDocumentDownloadOnClick ,
  make$1 as make,
}
/* context Not a pure module */
