// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Styles from "@mui/material/styles";

var Provider = {};

var light = Styles.createTheme({
      palette: {
        mode: "light"
      },
      components: {
        MuiStack: {
          defaultProps: {
            useFlexGap: true
          }
        }
      }
    });

var dark = Styles.createTheme({
      palette: {
        mode: "dark"
      },
      components: {
        MuiStack: {
          defaultProps: {
            useFlexGap: true
          }
        }
      }
    });

var Defaults = {
  light: light,
  dark: dark
};

export {
  Provider ,
  Defaults ,
}
/* light Not a pure module */
