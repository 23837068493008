// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as CollectionId from "../models/CollectionId.js";
import * as DragSelection from "../../catalog/components/DragSelection.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CollectionsSelectionManager from "../context/CollectionsSelectionManager.js";

function WithCollectionsLassoSelector(props) {
  var children = props.children;
  var collections = props.collections;
  var collections$1 = React.useMemo((function () {
          return Prelude.default(collections, []);
        }), [collections]);
  var getCurrentSelection = CollectionsSelectionManager.useGetCurrentSelection();
  var getCurrentSelection$1 = React.useMemo((function () {
          return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, getCurrentSelection, (function (fn) {
                            return function () {
                              return Belt_SetString.fromArray(CollectionId.manyToString(CollectionId.$$Map.keysToArray(fn())));
                            };
                          })), (function () {
                        
                      }));
        }), [getCurrentSelection]);
  var toggle = CollectionsSelectionManager.useToggle();
  var reset = CollectionsSelectionManager.useReset();
  var toggleId = React.useCallback((function (id) {
          Prelude.OptionExported.$$Option.$$do(collections$1.find(function (d) {
                    return Caml_obj.equal(d.id, id);
                  }), (function (obj) {
                  Prelude.OptionExported.$$Option.$$do(toggle, (function (fn) {
                          fn([obj]);
                        }));
                }));
        }), [
        toggle,
        collections$1
      ]);
  var select = React.useCallback((function (ids) {
          Prelude.OptionExported.$$Option.$$do(reset, (function (fn) {
                  fn();
                }));
          Belt_SetString.forEach(ids, (function (id) {
                  toggleId(CollectionId.fromString(id));
                }));
        }), [
        reset,
        toggleId
      ]);
  var enabled = Prelude.$$Array.isNotEmpty(collections$1) && Curry._1(Prelude.OptionExported.$$Option.isSome, toggle);
  if (enabled) {
    return JsxRuntime.jsx(DragSelection.make, {
                children: children,
                onDragSelection: select,
                getCurrentSelection: getCurrentSelection$1,
                appContainer: ".selection-lasso-scroll-container",
                itemSelector: [".selectable-item[id]"]
              });
  } else {
    return children;
  }
}

var make = WithCollectionsLassoSelector;

export {
  make ,
}
/* react Not a pure module */
