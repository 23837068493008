// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as $$Document from "../../models/Document.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Orientation from "../../models/Orientation.js";
import Box from "@mui/material/Box";
import * as JsxRuntime from "react/jsx-runtime";
import CardMedia from "@mui/material/CardMedia";

function DocumentBrowserImage$Image(props) {
  var orientation = props.orientation;
  var style = Orientation.Ifd0.getCSSTransform(orientation, {
        height: "100%",
        width: "100%"
      });
  var sx = Orientation.Ifd0.flipsWidthHeight(orientation) ? ({
        height: "auto",
        maxHeight: {
          xs: "35vh",
          lg: "95%"
        },
        maxWidth: {
          xs: "90vw",
          lg: "95%"
        },
        width: "auto",
        margin: "auto"
      }) : ({
        height: "auto",
        maxHeight: {
          xs: "48vh",
          lg: "95vh"
        },
        maxWidth: "95%",
        width: "auto",
        margin: "auto"
      });
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(CardMedia, {
                    component: "img",
                    image: props.url,
                    sx: Caml_option.some(sx)
                  }),
              style: style
            });
}

var $$Image = {
  make: DocumentBrowserImage$Image
};

function DocumentBrowserImage(props) {
  var $$document = props.document;
  var orientation = Prelude.default($$Document.Orientation.get($$document), 1);
  var match = $$document.previews;
  var tmp;
  var exit = 0;
  if (match !== undefined) {
    var match$1 = match.large;
    if (match$1 !== undefined && typeof match$1 === "object" && match$1.NAME === "remote") {
      tmp = JsxRuntime.jsx(DocumentBrowserImage$Image, {
            url: match$1.VAL.url,
            orientation: orientation
          });
    } else {
      exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    var match$2 = $$document.attachment;
    tmp = typeof match$2 === "object" && match$2.NAME === "remote" ? JsxRuntime.jsx(DocumentBrowserImage$Image, {
            url: match$2.VAL.url,
            orientation: orientation
          }) : null;
  }
  return JsxRuntime.jsx(Box, {
              children: Caml_option.some(tmp),
              sx: {
                height: "100%",
                margin: "auto"
              }
            });
}

var make = DocumentBrowserImage;

export {
  $$Image ,
  make ,
}
/* Document Not a pure module */
