// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Settings from "../../../Settings.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactI18next from "react-i18next";
import * as Hooks from "@mantine/hooks";
import * as FolderInfoPanel from "./FolderInfoPanel.js";
import * as JsxRuntime from "react/jsx-runtime";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import * as FolderBrowserCardsView from "./FolderBrowserCardsView.js";
import * as FolderBrowserTableView from "./FolderBrowserTableView.js";
import * as WithCatalogLassoSelector from "./WithCatalogLassoSelector.js";
import * as CatalogBrowserViewContext from "../context/CatalogBrowserViewContext.js";
import * as FolderBrowserCommonLayout from "./FolderBrowserCommonLayout.js";

function FolderBrowser$FolderView(props) {
  var toggleFolderInfo = props.toggleFolderInfo;
  var showFolderInfo = props.showFolderInfo;
  var documents = props.documents;
  var subfolders = props.subfolders;
  var folder = props.folder;
  var view = CatalogBrowserViewContext.useCurrentView();
  var size = React.useMemo((function () {
          return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, documents, (function (prim) {
                            return prim.length;
                          })), 0) + Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, subfolders, (function (prim) {
                            return prim.length;
                          })), 0) | 0;
        }), [
        documents,
        subfolders
      ]);
  console.log("FolderBrowser", {
        size: size
      });
  var cutoff = Settings.Catalog.folderItemsPerfErrorCutoff;
  var error = cutoff !== undefined && cutoff < size ? Caml_option.some(JsxRuntime.jsx(Alert, {
              sx: {
                borderRadius: "0.5rem",
                maxWidth: "50%",
                margin: "1rem auto"
              },
              children: Caml_option.some(JsxRuntime.jsx(ReactI18next.Trans, {
                        i18nKey: "catalog.browser.too-many-items-error",
                        children: "This folder has too many items (more than {{count}}),\n                       which can lead to performance degradation.  Consider\n                       split it in multiple subfolders.",
                        count: cutoff
                      })),
              severity: "error"
            })) : undefined;
  var cutoff$1 = Settings.Catalog.folderItemsPerfWarningCutoff;
  var warning = cutoff$1 !== undefined && cutoff$1 < size ? Caml_option.some(JsxRuntime.jsx(Alert, {
              sx: {
                borderRadius: "0.5rem",
                maxWidth: "50%",
                margin: "1rem auto"
              },
              children: Caml_option.some(JsxRuntime.jsx(ReactI18next.Trans, {
                        i18nKey: "catalog.browser.too-many-items-warning",
                        children: "This folder has many items (more than {{count}}),\n                       which can lead to performance degradation.  Consider\n                       split it in multiple subfolders.",
                        count: cutoff$1
                      })),
              severity: "warning"
            })) : undefined;
  var alert = Prelude.default(Prelude.$$Array.first(Prelude.$$Array.keepSome([
                error,
                warning
              ])), null);
  if (view === "icons") {
    return JsxRuntime.jsxs(FolderBrowserCommonLayout.make, {
                folder: folder,
                children: [
                  alert,
                  JsxRuntime.jsx(FolderBrowserCardsView.make, {
                        subfolders: subfolders,
                        documents: documents
                      })
                ],
                showFolderInfo: showFolderInfo,
                toggleFolderInfo: toggleFolderInfo
              });
  } else {
    return JsxRuntime.jsxs(FolderBrowserCommonLayout.make, {
                folder: folder,
                children: [
                  alert,
                  JsxRuntime.jsx(FolderBrowserTableView.make, {
                        subfolders: subfolders,
                        documents: documents
                      })
                ],
                showFolderInfo: showFolderInfo,
                toggleFolderInfo: toggleFolderInfo
              });
  }
}

function FolderBrowser(props) {
  var documents = props.documents;
  var subfolders = props.subfolders;
  var folder = props.folder;
  var match = Hooks.useLocalStorage({
        key: "photocuba-tags-approval-status",
        defaultValue: false,
        deserialize: (function (v) {
            return v === "1";
          }),
        serialize: (function (v) {
            if (v) {
              return "1";
            } else {
              return "0";
            }
          }),
        getInitialValueInEffect: false
      });
  var setShowFolderInfo = match[1];
  var showFolderInfo = match[0];
  var toggleFolderInfo = React.useCallback((function () {
          setShowFolderInfo(!showFolderInfo);
        }), [
        setShowFolderInfo,
        showFolderInfo
      ]);
  var child = showFolderInfo ? JsxRuntime.jsxs(Grid, {
          children: [
            JsxRuntime.jsx(Grid, {
                  children: Caml_option.some(JsxRuntime.jsx(FolderBrowser$FolderView, {
                            folder: folder,
                            subfolders: subfolders,
                            documents: documents,
                            showFolderInfo: showFolderInfo,
                            toggleFolderInfo: toggleFolderInfo
                          })),
                  item: true,
                  lg: 9,
                  xs: 7
                }),
            JsxRuntime.jsx(Grid, {
                  children: Caml_option.some(JsxRuntime.jsx(FolderInfoPanel.make, {
                            folder: folder
                          })),
                  item: true,
                  lg: 3,
                  xs: 5
                })
          ],
          container: true,
          spacing: 3
        }) : JsxRuntime.jsx(Grid, {
          children: Caml_option.some(JsxRuntime.jsx(Grid, {
                    children: Caml_option.some(JsxRuntime.jsx(FolderBrowser$FolderView, {
                              folder: folder,
                              subfolders: subfolders,
                              documents: documents,
                              showFolderInfo: showFolderInfo,
                              toggleFolderInfo: toggleFolderInfo
                            })),
                    item: true,
                    xs: 12
                  })),
          container: true
        });
  return JsxRuntime.jsx(WithCatalogLassoSelector.make, {
              subfolders: subfolders,
              documents: documents,
              children: child
            });
}

var make = FolderBrowser;

export {
  make ,
}
/* react Not a pure module */
