// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DropdownMenu from "../../../global/components/DropdownMenu.js";
import * as ReactI18Next from "../../../../libs/i18n/ReactI18Next.js";
import * as AccountsManager from "../context/AccountsManager.js";
import * as EditAccountDialog from "./EditAccountDialog.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ConfirmDeleteDialog from "../../../global/components/ConfirmDeleteDialog.js";
import * as RemoveAccountService from "../context/RemoveAccountService.js";
import * as UpdateAccountService from "../context/UpdateAccountService.js";
import * as ToggleActivationService from "../context/ToggleActivationService.js";
import MoreVert from "@mui/icons-material/MoreVert";
import EditRounded from "@mui/icons-material/EditRounded";
import DeleteForever from "@mui/icons-material/DeleteForever";
import AccountCircleRounded from "@mui/icons-material/AccountCircleRounded";

function AccountDropdown(props) {
  var account = props.account;
  var match = React.useState(function () {
        
      });
  var setEditAccount = match[1];
  var editAccount = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setDeleteAccount = match$1[1];
  var deleteAccount = match$1[0];
  var deleter = RemoveAccountService.useRemoveAccount();
  var toggleActivation = ToggleActivationService.useToggleActivation();
  var updater = UpdateAccountService.useUpdateAccount();
  var canIDeactivate = Prelude.default(AccountsManager.useCanIDeactivate(), (function (param) {
          return false;
        }));
  var items = [
    {
      TAG: "Item",
      _0: {
        title: {
          NAME: "str",
          VAL: "Edit"
        },
        icon: Caml_option.some(JsxRuntime.jsx(EditRounded, {})),
        action: {
          TAG: "Click",
          _0: (function (param) {
              setEditAccount(function (param) {
                    return account;
                  });
            })
        },
        disabled: Curry._1(Prelude.OptionExported.$$Option.isNone, updater)
      }
    },
    {
      TAG: "Item",
      _0: {
        title: {
          NAME: "msg",
          VAL: [
            "accounts.toggle-activation-status",
            "Toggle activation"
          ]
        },
        icon: Caml_option.some(JsxRuntime.jsx(AccountCircleRounded, {
                  color: account.is_active ? "warning" : "success"
                })),
        action: {
          TAG: "Click",
          _0: (function (param) {
              Prelude.OptionExported.$$Option.$$do(toggleActivation, (function (fn) {
                      fn(account);
                    }));
            })
        },
        disabled: Curry._1(Prelude.OptionExported.$$Option.isNone, toggleActivation) || account.is_active && !canIDeactivate(account)
      }
    },
    "Separator",
    {
      TAG: "Item",
      _0: {
        title: {
          NAME: "str",
          VAL: "Remove"
        },
        icon: Caml_option.some(JsxRuntime.jsx(DeleteForever, {})),
        action: {
          TAG: "Click",
          _0: (function (param) {
              setDeleteAccount(function (param) {
                    return account;
                  });
            })
        },
        disabled: Curry._1(Prelude.OptionExported.$$Option.isNone, deleter)
      }
    }
  ];
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(DropdownMenu.make, {
                      trigger: {
                        TAG: "Icon",
                        _0: JsxRuntime.jsx(MoreVert, {})
                      },
                      items: items
                    }),
                editAccount !== undefined ? JsxRuntime.jsx(EditAccountDialog.make, {
                        account: editAccount,
                        onClose: (function () {
                            setEditAccount(function (param) {
                                  
                                });
                          }),
                        onDone: (function () {
                            setEditAccount(function (param) {
                                  
                                });
                          })
                      }) : null,
                deleteAccount !== undefined ? JsxRuntime.jsx(ConfirmDeleteDialog.make, {
                        open_: true,
                        title: {
                          NAME: "msg",
                          VAL: [
                            "accounts.remove-account-confirmation-title",
                            "Confirm deletion of account"
                          ]
                        },
                        content: Caml_option.some(JsxRuntime.jsx(ReactI18Next.Message.make, {
                                  msg: {
                                    NAME: "msg",
                                    VAL: [
                                      "accounts.remove-account-confirmation-message",
                                      "Confirm that you want to remove the account. This action cannot be reverted, and related items to the user might be removed as well."
                                    ]
                                  }
                                })),
                        onCancel: (function () {
                            setDeleteAccount(function (param) {
                                  
                                });
                          }),
                        onDelete: (function () {
                            Prelude.OptionExported.$$Option.$$do(deleter, (function (fn) {
                                    Prelude.thenDo(fn(deleteAccount), (function (param) {
                                            setDeleteAccount(function (param) {
                                                  
                                                });
                                          }));
                                  }));
                          })
                      }) : null
              ]
            });
}

var make = AccountDropdown;

export {
  make ,
}
/* react Not a pure module */
