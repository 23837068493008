// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import ManageAccountsRounded from "@mui/icons-material/ManageAccountsRounded";

var permissions = {
  TAG: "All",
  _0: [
    "auth.change_user",
    "auth.view_user",
    "auth.add_user",
    "auth.delete_user"
  ]
};

var icon = Caml_option.some(JsxRuntime.jsx(ManageAccountsRounded, {}));

var name = {
  NAME: "msg",
  VAL: [
    "accounts.title",
    "Users"
  ]
};

var path = "/users";

export {
  name ,
  path ,
  permissions ,
  icon ,
}
/* icon Not a pure module */
